import React, { useState, useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { Stack, Typography } from "@material-ui/core";
import ErrorNotifier from '../../../ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../../ToastNotifications/SuccessNotifier';
import { 
  postInteractionUrl, 
  getHeaders, 
  fetchAllInteractionsWithUser,
  fetchProgramEnrollmentsUrl
} from '../../../../apis/urls';
import InteractionList from "./InteractionList";
import AddInteraction from "./AddInteraction";
import { EnrollmentDropdown } from '../LearnerLevels'
import LoadingScreen from "../../../../pages/LoadingScreen";

export default function Interactions() {
  const currentProgram = useSelector(state => state.programs.currentProgram);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] =  useState(false);
  const [interactions, setInteractions] = useState([]);
  const [enrollmentIdFilter, setEnrollmentIdFilter] = useState("");
  const [enrollments, setEnrollments] = useState([]);

  useEffect(() => {
    if (currentProgram) {
      getEnrollments();
    }
    return () => {};
  }, []);

  const getEnrollments = () => {
    axios.get(fetchProgramEnrollmentsUrl(), {
      headers: getHeaders(currentProgram?.programId)
    })
    .then((res) => {
      const tmpEnrollments = [];
      res.data?.forEach((enrollment, index) => {
        if (enrollment?.user.role === "student") {
          tmpEnrollments.push({
            enrollmentId: enrollment.id,
            userId: enrollment?.user.id,
            name: enrollment?.user.name,
            email: enrollment?.user.email
          })
        }
      });

      setEnrollments(tmpEnrollments);
    })
    .catch((err) => {
      console.log(err);
      setHasError(true);
      setErrorMessage(err.response?.data?.message || "An error occured while fetching enrollments");
    })
  }

  const handleEnrollmentSelect = (e) => {
    const { value } = e.target;
    if (value === "") {
      setEnrollmentIdFilter("");
      setInteractions([])
      return;
    }

    setEnrollmentIdFilter(value);
    setLoading(true);
    
    axios.get(fetchAllInteractionsWithUser(value), {
      headers: getHeaders(currentProgram?.programId)
    })
    .then(res => {
      setInteractions(res?.data ?? []);
    })
    .catch(err => {
      console.log(err);
      setHasError(true);
      setErrorMessage(err.response?.data?.message || "Couldn't fetch the interactions");
    })
    .finally(() => {
      setLoading(false);
    })
  }

  const postInteraction = (newInteraction, interaction_type='cad') => {
    setLoading(true);

    const body = {
      notes: newInteraction,
      enrollment_id: enrollmentIdFilter,
      interaction_type: interaction_type
    }
    
    axios.post(postInteractionUrl(), body, {
      headers: getHeaders(currentProgram?.programId)
    })
    .then(res => {
      setInteractions([...interactions, res.data])
    })
    .catch(err => {
      console.log(err);
      setHasError(true);
      setErrorMessage(err.response?.data?.message || "Couldn't add new interaction.");
    })
    .finally(() => {
      setLoading(false);
    })
  }

  return (
    <Stack spacing={3}>
      { hasError && <ErrorNotifier {...{message: errorMessage, setHasError }} /> }
      <LoadingScreen loading={loading} />

      <EnrollmentDropdown 
        enrollmentIdFilter={enrollmentIdFilter}
        handleEnrollmentSelect={handleEnrollmentSelect}
        enrollments={enrollments}
        sx={{
          width: '35rem',
          height: 40,
        }}
      />

      <InteractionList interactions={interactions} />
      
      {enrollmentIdFilter !== "" &&
        <AddInteraction {...{ postInteraction } } />
      }

      {enrollmentIdFilter === "" && 
        <Typography 
          textAlign="center" 
          variant="body1"
          pt={5}
        >
          Select a learner to see the interactions.
        </Typography>
      }
    </Stack>
  );
}

import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import StudentSubmissions from '../../performance/Assignment/StudentSubmissions';

function StudentAssignmentSubmission() {
    const { assignmentId="", studentId="" } = useParams();

    return (
        <>
            <StudentSubmissions
                selectedAssignmentId={assignmentId}
                selectedStudentId={parseInt(studentId, 10)}
                openRow
                handleClose={() => { }}
            />
        </>
    )
}

export default StudentAssignmentSubmission
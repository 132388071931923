import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardContent, Typography, Box, Stack, Tooltip, Modal } from '@material-ui/core';
import axios from 'axios';
import { Icon } from '@iconify/react';
import { styled } from '@material-ui/core/styles';
import DeleteOutlined from '@iconify/icons-ant-design/delete-outlined';
import { format } from 'date-fns';
import ResourceType from './ResourceType';
import EditResourceForm from './EditResourceForm';
import { deleteResourcesURL, getHeaders } from '../../../apis/urls';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '0.3rem',
  p: 4
};

const DeleteButton = styled('div')(() => ({ color: 'red' }));

const MentorResourceCard = ({ 
  resource, setHasDeleted, setIsSuccess, setHasError, setErrorMessage, setSuccessMessage
}) => {
  const [open, setOpen] = useState(false);
  const currentProgram = useSelector(state => state.programs.currentProgram);

  const handleDeleteClick = (e) => {
    e.stopPropagation();
    const deleteResource = async () => {
      try {
        await axios.delete(deleteResourcesURL(resource.id), {
          headers: getHeaders(currentProgram?.programId)
        });
        setHasDeleted(true);
        setIsSuccess(true);
        setSuccessMessage("Resource successfuly deleted");
      } catch (err) {
        console.log(err);
        setHasError(true);
        setErrorMessage(err.response?.data?.message || err.message);
      }
    };
    deleteResource();
  };

  return (
    <>
      <Card sx={{width: "100rem", ml: 2}}>
        <CardContent>
          <Stack direction="column-reverse" alignItems="flex-end" sx={{ float: 'right' }}>
            <Tooltip title="Delete" aria-label="delete" style={{ float: 'right' }}>
              <DeleteButton onClick={(e) => handleDeleteClick(e)} sx={{cursor: "pointer"}}>
                <Icon icon={DeleteOutlined} width="18" height="18" />
              </DeleteButton>
            </Tooltip>

            <Typography component="p" variant="subtitle2" color="textSecondary" gutterBottom>
              {resource && 
                resource.created_at ? format(new Date(resource.created_at), 'do MMMM yyyy') : ''
              }
            </Typography>
          </Stack>
          <Typography variant="h5" component="h2">
            {resource.title}
          </Typography>
          {currentProgram &&
            <ResourceType 
              {...{ 
                type: resource.resource_type, 
                resource, 
                programId: currentProgram?.programId 
              }}
            />
          }
        </CardContent>
      </Card>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box sx={style}>
          <EditResourceForm editResources={resource} setOpen={setOpen} />
        </Box>
      </Modal>
    </>
  );
};

export default MentorResourceCard;

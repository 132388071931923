import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  MenuItem,
  InputLabel,
  Select,
  FormControl,
  Typography,
  Grid,
  Box,
} from '@mui/material';
import { formFields } from './Data';
import { getUserData } from 'src/configs/auth';

// Form Field Components
const TextFieldComponent = ({
  field,
  formData,
  handleChange,
  disabled,
  error,
}) => (
  <TextField
    name={field.name}
    type={field.type}
    value={formData[field.name]}
    onChange={handleChange}
    fullWidth
    required={field.required}
    disabled={disabled}
    placeholder={field.placeholder}
    error={!!error}
    helperText={error}
  />
);

const SelectFieldComponent = ({ field, formData, handleChange, error }) => (
  <Box sx={{ minWidth: 120 }}>
    <FormControl
      fullWidth
      required={field.required}
      error={!!error}
    >
      <InputLabel id={`${field.name}-label`}>{field.label}</InputLabel>
      <Select
        labelId={`${field.name}-label`}
        id={`${field.name}-select`}
        name={field.name}
        value={formData[field.name] || ''}
        onChange={handleChange}
        label={field.label}
      >
        <MenuItem value=''>
          <em>Select {field.label}</em>
        </MenuItem>
        {field.options.map((option, i) => (
          <MenuItem
            key={i}
            value={option}
          >
            {option}
          </MenuItem>
        ))}
      </Select>
      {error && <Typography color='error'>{error}</Typography>}
    </FormControl>
  </Box>
);

// Main Form Component
const ProfileForm = () => {
  const { email_id, name, phone } = getUserData();
  const [errors, setErrors] = useState({});

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    gender: '',
    birthYear: '',
    degree: '',
    graduationYear: '',
    postGraduationDegree: '',
    postGraduationYear: '',
    techStack: '',
    workExperience: '',
    currentOrganization: '',
    currentProfile: '',
    currentCTC: '',
    currentLocation: '',
    noticePeriod: '',
    linkedIn: '',
    leetcodeUsername: '',
  });

  useEffect(() => {
    // Pre-fill name, email, and phone number fields
    setFormData((prevData) => ({
      ...prevData,
      name: name,
      email: email_id,
      phone: phone,
    }));
  }, [name, email_id, phone]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const newErrors = {};
    formFields.forEach((field) => {
      if (field.required && !formData[field.name]) {
        newErrors[field.name] = 'This field is required';
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    //The API call has to be made here once the API endpoints ate finalise.
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid
        container
        spacing={2}
      >
        {formFields.map((field, index) => (
          <Grid
            container
            item
            key={index}
            spacing={2}
            alignItems='center'
          >
            <Grid
              item
              xs={6}
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Typography
                style={{
                  textAlign: 'left',
                  width: '100%',
                  paddingLeft: '20px',
                }}
              >
                {field.label}
                {field.required && <span style={{ color: 'red' }}>*</span>}
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                paddingRight: '16px',
              }}
            >
              {field.type === 'select' ? (
                <SelectFieldComponent
                  field={field}
                  formData={formData}
                  handleChange={handleChange}
                  error={errors[field.name]}
                />
              ) : (
                <TextFieldComponent
                  field={field}
                  formData={formData}
                  handleChange={handleChange}
                  disabled={
                    field.name === 'name' ||
                    field.name === 'email' ||
                    field.name === 'phone'
                  }
                  error={errors[field.name]}
                />
              )}
            </Grid>
          </Grid>
        ))}

        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '1%',
          }}
        >
          <Button
            type='submit'
            variant='contained'
            color='primary'
            sx={{
              padding: '10px 20px',
              fontSize: '1rem',
            }}
          >
            Save Profile Details
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default ProfileForm;

import BookIcon from '@material-ui/icons/Book';

export const languageBibleList = [
  {
    category: 'JavaScript Language Bible',
    icon: <BookIcon />,
    items: [
      {
        name: "JavaScript beginner's guide",
        link: 'https://docs.google.com/document/d/1W-6e07M6iBpxBkbtUeMNd6peHwtnufkaysreapxlB78/edit#heading=h.rfs1sjnhw1gc',
      },
      {
        name: 'Functions and Objects',
        link: 'https://docs.google.com/document/d/1tah0ikrgwPNl_0El2rP4sx7SUI9-0Dlc8kA_sR-QdIs/edit#heading=h.klb1p8t8q4gu',
      },
      {
        name: 'Object Oriented Programming',
        link: 'https://docs.google.com/document/d/15wbPCq_ofJSgOsoVGtjohjaqzH26Qb6OPRubv7r7gKQ/edit#heading=h.616wkoatusfg',
      },
      {
        name: 'Files and Exceptions',
        link: 'https://docs.google.com/document/d/1Ms_qVKCelIaeRZJqdmR6NLvrKa0IQFDj-RHuDKnoF7g/edit#heading=h.upwi7uyusy34',
      },
      {
        name: 'Popular Data Structures',
        link: 'https://docs.google.com/document/d/1oELNxs7u_yL3KN_GYyvWdyYmzmKCHdvR4b6M-_sOPN0/edit#heading=h.v1kxrnyy2ltb',
      },
    ],
  },
  {
    category: 'Python Language Bible',
    icon: <BookIcon />,
    items: [
      {
        name: "Python beginner's guide",
        link: 'https://docs.google.com/document/d/1PJv9wisrQy4-Vs-XZFMM7RP_ZcU44SdXEb_qolZOkpU/edit#heading=h.zfkcpaxi4r62',
      },
      {
        name: 'Functions',
        link: 'https://docs.google.com/document/d/1sg5qvz76aVimY4pF0U8ZOLS4LtTSTkG_SYn9TxiidPk/edit#heading=h.jydojjcv9z7t',
      },
      {
        name: 'Popular Data Structures',
        link: 'https://docs.google.com/document/d/1cVLegSCy_DSAd4vgyOAzJ56PhfuDT_ApomZy3u9UlJc/edit#heading=h.3xnqh1qfvcgf',
      },
      {
        name: 'Object Oriented Programming (OOP)',
        link: 'https://docs.google.com/document/d/1PK4I1et4O6lERjt_OVZ8jes4qLPZNyGkRlNjUUSm9do/edit#heading=h.v2eenk990fd2',
      },
      {
        name: 'Files and Exceptions',
        link: 'https://docs.google.com/document/d/1w8FRv97FHMGM2a_IxQ8egfhyZg02iOfy_An69jrkwHE/edit#heading=h.tv3jl9iv2p9m',
      },
      {
        name: 'Popular Libraries in Python',
        link: 'https://docs.google.com/document/d/1tLpv1hlfCiOSfq3RlXTHnZ_lUC4QBT7lx9qHXiXPGJ0/edit#heading=h.r6gbpx8mn6l2',
      },
    ],
  },
  {
    category: 'C++ Language Bible',
    icon: <BookIcon />,
    items: [
      {
        name: "C++ beginner's guide",
        link: 'https://docs.google.com/document/d/1NuDJ_h7tKUkeocq5atx3AC_3xdMGRlm-HKcRvwWJptY/edit#heading=h.re8row8nxva5',
      },
      {
        name: 'Pointers and Functions',
        link: 'https://docs.google.com/document/d/1D_fE7t2a-TpRoeoD1J8oDMhOF_qKkMFSvOKkv_revqQ/edit#heading=h.ieww376o54hh',
      },
      {
        name: 'Object Oriented Programming (OOP)',
        link: 'https://docs.google.com/document/d/1i6orVTbJIhofaQM_cDlvDregPmKIy3Ypq7R0T1q8mHI/edit#heading=h.iooxveteznf5',
      },
      {
        name: 'Files and Exceptions',
        link: 'https://docs.google.com/document/d/160yaqRjGSL21bJ1XvGk-6K4eP1YGWKoV-xlNDNXiT38/edit#heading=h.d9xo14npds77',
      },
      {
        name: 'Popular STL containers',
        link: 'https://docs.google.com/document/d/1pFWl9hN-F5tqA709nknPQyDAbCIq-k8CnxNa-arCwWM/edit',
      },
    ],
  },
  {
    category: 'Java Language Bible',
    icon: <BookIcon />,
    items: [
      {
        name: "Java beginner's guide",
        link: 'https://docs.google.com/document/d/1czGSrIJJYEPW9DnYyUaucmqQsKhkscRQ29P9-sw4f0U/edit#heading=h.8d3ttswu94ha',
      },
      {
        name: 'Functions',
        link: 'https://docs.google.com/document/d/1E7RxPv2-EpVjiVs8m1AGXhkCrgJEEuqR6GHLj0-G2zw/edit#heading=h.5nf8rimlh2c',
      },
      {
        name: 'Object Oriented Programming (OOP)',
        link: 'https://docs.google.com/document/d/1WiFslW8kHiEixk4dxoK5R_ttCmtWXr4P10ERcv0DWdc/edit#heading=h.wxa44ayem11p',
      },
      {
        name: 'File And Exception',
        link: 'https://docs.google.com/document/d/1yOtT-nnZ-dtZJiDZ1gHt8d_-LbVctiiPav96kMUHWS4/edit#heading=h.9zpzz86bus1o',
      },
      {
        name: 'Popular InBuilt Containers',
        link: 'https://docs.google.com/document/d/1wRxv4cW7iL_95niaDARVzVacpiqSMNRmXZNhrYHDKns/edit#heading=h.qf8pfm94i6tn',
      },
    ],
  },
];

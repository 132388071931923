// @ts-nocheck
import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Stack, Typography } from '@material-ui/core';

// https://github.com/heycoach-in/learning_project/issues/46

const KnowMoreAboutProgramFields = ({ handleKnowMore }) => {
  return (
    <Dialog
      open
      onClose={handleKnowMore}
      fullWidth
      maxWidth='md'
    >
      <DialogContent>
        <b>*Note:</b> All the dates must be in future.
        <Stack spacing={1.5}>
          <Typography
            variant='body1'
            component='p'
          >
            1. <b>Start date:</b>
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp; - From this day onwards enrollment for a
            new program starts.
          </Typography>

          <Typography
            variant='body1'
            component='p'
          >
            2. <b>End date:</b>
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp; - After this day learners lose access to
            the program.
          </Typography>

          <Typography
            variant='body1'
            component='p'
          >
            3. <b>Induction starts on</b>
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp; - Induction sessions are scheduled from
            this day. The database doesn't yet have these sessions so they'll
            need to be added from the portal.
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp; - From this day onwards till the end of the
            program, a "Daily mandatory question" is scheduled. (This feature is
            not yet live, so this usecase can be ignored for now)
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp; - From program start date till one day
            before the induction date, one "Foundation" (also called
            "Pre-course") question is scheduled every day.
          </Typography>

          <Typography
            variant='body1'
            component='p'
          >
            4. <b>Sessions start on:</b>
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp; - From this day onwards actual Super30
            sessions are scheduled.
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp; - Session assignments, Pre-reads, and
            Mandatory questions are also schdeuled from this day onwards.
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp; -{' '}
            <i>
              It's mandatory to provide teaching days information while creating
              the program in order to schedule the sessions, pre-reads and
              assignments.
            </i>
          </Typography>

          <Typography
            variant='body1'
            component='p'
          >
            5. <b>Sessions end on:</b>
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp; - No further sessions can be scheduled
            after this date.
          </Typography>
          <Typography
            variant='body1'
            component='p'
          >
            6. <b>Teaching days:</b>
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp; - These are the preferred days and timings
            for the program sessions.
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp; -{' '}
            <i>
              For Super30 programs, this information is <b>mandatory</b>
            </i>
            .
          </Typography>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default KnowMoreAboutProgramFields;

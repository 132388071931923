import React, { useState } from 'react';
import {
  Box,
  Typography,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
} from '@material-ui/core';
import LoadingButton from '@mui/lab/LoadingButton';
import VerifiedIcon from '@mui/icons-material/Verified';
import { FiberManualRecord } from '@mui/icons-material';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import CustomTabPanel from '../CustomTabPanel';
import { FindLargestPowerOfTwoConst } from '../../../utils/techniqueSagaConstant';
import TopicTitle from '../TopicTitle';
import ListRenderComponent from '../ListRenderComponent';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import FindLargestPowerTwo01 from '../../../../images/FindLargestPowerTwo01.png';
import FindLargestPowerTwo02 from '../../../../images/FindLargestPowerTwo02.png';
import FindLargestPowerTwo03 from '../../../../images/FindLargestPowerTwo03.png';
import FindLargestPowerTwo04 from '../../../../images/FindLargestPowerTwo04.png';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const FindLargestPowerOfTwo = ({
  handlePostCompletedTopic,
  hasMarkedCompleted,
  loading,
  topic_id,
  handleNext,
  handlePrevious,
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap='36px'
      paddingY={2}
      maxWidth='calc(100% - 300px)'
    >
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
      >
        <TopicTitle>
          5. Find the largest power of 2 ((most significant bit) in binary
          form), which is less than or equal to the given number N.
        </TopicTitle>
        {hasMarkedCompleted(topic_id) && (
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              color: 'green',
              fontWeight: '500',
              border: '2px solid green',
              padding: '4px 10px',
              borderRadius: '20px',
              cursor: 'default',
            }}
          >
            Completed
          </Typography>
        )}
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        gap='24px'
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            width: '34rem',
            background: '#FEF7FF',
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
          >
            <Tab
              label='Problem Statement'
              {...a11yProps(0)}
            />
            <Tab
              label='Traditional Approach'
              {...a11yProps(1)}
            />
            <Tab
              label='Bit Manipulation Approach'
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <CustomTabPanel
          value={value}
          index={0}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Typography>
              Given a number 'n' you have to determine the largest number which
              is in range of 0-n and is a power of 2.
            </Typography>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={1}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Typography>
              A simple approach would be to start from n and then keep
              decrementing until you find a number that is a power of 2.
            </Typography>

            <Box
              sx={{
                backgroundColor: 'black',
                padding: '24px',
                borderRadius: '10px',
                width: '583px',
              }}
            >
              <pre style={{ color: 'white', margin: 0 }}>
                {`int highestPowerof2(int n)
{
    int res = 0;
    for (int i = n; i >= 1; i--) {
        // If i is a power of 2
        if ((i & (i - 1)) == 0) {
            res = i;
            break;
        }
    }
    return res;
}`}
              </pre>
            </Box>

            <Typography>
              {
                'The time and space complexity for this is O(n) and O(1) respectively.'
              }
            </Typography>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={2}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Box
              display='flex'
              flexDirection='column'
              gap='8px'
            >
              <Typography sx={{ fontSize: '22px', fontWeight: 400, mb: '8px' }}>
                Idea
              </Typography>
              <List>
                <ListItem sx={{ paddingY: '2px' }}>
                  <ListItemIcon>
                    <FiberManualRecord sx={{ width: '0.5em' }} />
                  </ListItemIcon>
                  <ListItemText primary='Change all the bits which are at the right side of the most significant digit, to 1.' />
                </ListItem>
              </List>
            </Box>

            <Box
              display='flex'
              flexDirection='column'
              gap='8px'
            >
              <Typography sx={{ fontSize: '22px', fontWeight: 400, mb: '8px' }}>
                Property
              </Typography>
              <List>
                <ListItem sx={{ paddingY: '2px' }}>
                  <ListItemIcon>
                    <FiberManualRecord sx={{ width: '0.5em' }} />
                  </ListItemIcon>
                  <ListItemText primary='As we know that when all the bits of a number N are 1, then N must be equal to the 2i -1 , where i is the number of bits in N' />
                </ListItem>
              </List>
            </Box>

            <Box
              display='flex'
              flexDirection='column'
              gap='12px'
            >
              <Typography sx={{ fontSize: '22px', fontWeight: 400, mb: '8px' }}>
                Example
              </Typography>
              <Box
                display='flex'
                flexDirection='column'
                gap='20px'
              >
                <Typography>
                  {`Let's say the binary form of a N is {1111}2 which is equal to 15.`}
                </Typography>
                <Typography>
                  15 = 24-1, where 4 is the number of bits in N.
                </Typography>
              </Box>

              <Box
                display='flex'
                flexDirection='column'
                gap='30px'
              >
                <ListRenderComponent
                  data={FindLargestPowerOfTwoConst.example01}
                />
              </Box>

              <Box>
                {FindLargestPowerOfTwoConst.example02.map((text, index) => (
                  <Typography key={index}>{text}</Typography>
                ))}
              </Box>

              <Typography>
                {`Now the required answer is (Y+1)>>1 or (Y+1)/2.`}
              </Typography>

              <Typography>
                Now the question arises here is how can we change all right side
                bits of the most significant bit to 1?
              </Typography>

              <Box>
                <Typography>{`Let's take the N as a 16 bit integer and binary form of N is {1000000000000000}.`}</Typography>
                <Typography>
                  Here we have to change all the right side bits to 1.
                </Typography>
              </Box>

              <Box>
                <Typography>
                  Initially we will copy that most significant bit to its
                  adjacent right side by:
                </Typography>
                <Typography>{`N = N | (N>>1).`}</Typography>

                <img
                  src={FindLargestPowerTwo01}
                  alt=''
                  style={{ marginTop: '12px' }}
                />
              </Box>

              <Box>
                <Typography>
                  As you can see, in the above diagram, after performing the
                  operation, the rightmost bit has been copied to its adjacent
                  place.
                </Typography>
                <Typography>
                  Now we will copy the 2 rightmost set bits to their adjacent
                  right side.
                </Typography>
                <Typography>{`N = N | (N>>2).`}</Typography>

                <img
                  src={FindLargestPowerTwo02}
                  alt=''
                  style={{ marginTop: '12px' }}
                />
              </Box>

              <Box>
                <Typography>
                  Now we will copy the 4 rightmost set bits to their adjacent
                  right side.
                </Typography>
                <Typography>{`N = N | (N>>4).`}</Typography>

                <img
                  src={FindLargestPowerTwo03}
                  alt=''
                  style={{ marginTop: '12px' }}
                />
              </Box>

              <Box>
                <Typography>
                  Now we will copy these 8 rightmost set bits to their adjacent
                  right side.
                </Typography>
                <Typography>{`N = N| (N>>8).`}</Typography>

                <img
                  src={FindLargestPowerTwo04}
                  alt=''
                  style={{ marginTop: '12px' }}
                />
              </Box>

              <Box>
                <Typography>
                  Now all the right side bits of the most significant set bit
                  has been changed to 1 .
                </Typography>
                <Typography>
                  This is how we can change right side bits. This explanation is
                  for 16 bit integers, and it can be extended for 32 or 64 bit
                  integers too.
                </Typography>
              </Box>
            </Box>

            <Box
              display='flex'
              flexDirection='column'
              gap='8px'
            >
              <Typography sx={{ fontSize: '22px', fontWeight: 400, mb: '8px' }}>
                Implementation
              </Typography>
              <Box
                sx={{
                  backgroundColor: 'black',
                  padding: '24px',
                  borderRadius: '10px',
                  width: '630px',
                }}
              >
                <pre style={{ color: 'white', margin: 0 }}>
                  {`long largest_power(long N)
    {
       //changing all right side bits to 1.
        N = N| (N>>1);
        N = N| (N>>2);
        N = N| (N>>4);
        N = N| (N>>8);
  /* as now the number is 2 * x-1, where x is the required answer, 
  so adding 1 and dividing it by 2. */
        return (N+1)>>1;
 }`}
                </pre>
              </Box>
            </Box>

            <Typography>
              {FindLargestPowerOfTwoConst.BitManipulationTimeComplexity.text01}
            </Typography>

            <Box
              display='flex'
              flexDirection='column'
              gap={2}
            >
              <Typography>
                <span style={{ fontWeight: '600' }}>Time Complexity:</span>
                {' O(1)'}
              </Typography>
              <Typography>
                <span style={{ fontWeight: '600' }}>Space Complexity:</span>
                {' O(1)'}
              </Typography>
            </Box>
          </Box>
        </CustomTabPanel>
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        alignSelf='end'
        justifyContent='space-between'
        gap='12px'
      >
        {!hasMarkedCompleted(topic_id) && (
          <LoadingButton
            variant='contained'
            onClick={() => handlePostCompletedTopic(topic_id)}
            loading={loading}
            loadingPosition='center'
            children='Mark as completed'
            style={{
              width: '170px',
              // backgroundColor: 'transparent',
              borderRadius: '8px',
              border: '1px solid rgba(64, 96, 245, 0.5)',
            }}
          />
        )}

        <Box
          display='flex'
          alignItems='center'
          gap='8px'
        >
          <Button
            sx={{ gap: '4px' }}
            onClick={handlePrevious}
          >
            <WestIcon /> Previous
          </Button>

          <Button
            sx={{ gap: '4px' }}
            onClick={handleNext}
          >
            Next <EastIcon />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default FindLargestPowerOfTwo;

import { Box, Button, Card, Modal,  Typography } from '@material-ui/core';
import axios from 'axios';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ErrorNotifier from '../../ToastNotifications/ErrorNotifier';
import { fetchImageURL, getHeaders } from '../../../apis/urls';

const ViewSubmission = ({ open, handleClose, ActiveData }) => {
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Card
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography 
                        id="modal-modal-title"
                        variant="h4"
                        component="h2"
                    >
                        View Your Submission
                    </Typography>
                    <SubmissionDetailed ActiveData={ActiveData} />
                </Card>
            </Modal>
        </div>
    );
};

export default ViewSubmission;

function SubmissionDetailed({ ActiveData }) {
    const { feedback, status } = ActiveData && ActiveData;
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const currentProgram = useSelector(state => state.programs.currentProgram);

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    };

    const onClickUrl = () => {
        axios.get(fetchImageURL(ActiveData?.id), { 
            headers: getHeaders(currentProgram.programId)
        })
        .then((res) => {
            return res.data;
        })
        .then((data) => {
            openInNewTab(data?.file);
        })
        .catch((err) => {
            setHasError(true);
            setErrorMessage(err.response?.data?.message || err.message);
        });
    };

    return (
        <>
            {hasError
            ? <ErrorNotifier message={errorMessage} setHasError={setHasError}/>
            : ""
            }
            <Box component="div">
                <Box
                    component="div"
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        padding: 10,
                        justifyContent: 'space-between'
                    }}
                >
                    <Typography component="div" variant="body2">
                        remark : {feedback ? `${feedback}` : 'N/A'}
                    </Typography>
                    <Button variant="contained" onClick={onClickUrl}>
                        View File
                    </Button>
                </Box>
            </Box>
        
        </>
    );
}

// @ts-nocheck
import {
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import React, { useEffect, useState } from 'react';
import { useFastrackPipeline } from 'src/layouts/EfPipelineContext/FastrackPipelineContex';

const KeyModulesIntro = ({ setFlowData, flowData, saveFlowData }) => {
  const { screenSource, setScreenSource, trackButtonClick } =
    useFastrackPipeline();
  const theme = useTheme();
  const isBelowMedium = useMediaQuery(theme.breakpoints.down('md'));

  const handleContinue = () => {
    trackButtonClick({
      source: 'ps_effectiveness_1',
      eventName: 'continue',
    });
    saveFlowData({
      screen: 'ps_effectiveness_1',
      response: { continue: true },
    });
    setScreenSource('ps_effectiveness_2');
  };

  return (
    <Stack
      spacing={4}
      paddingTop='1rem'
      alignItems='center'
      justifyContent='space-evenly'
      minHeight='35vh'
      width='100%'
    >
      <Typography
        variant='h3'
        component='h3'
        sx={{
          color: '#1976d2',
          fontFamily: 'Inter',
          fontWeight: 'bolder',
          fontSize: {
            xs: '1.7rem',
            sm: '1.8rem',
            md: '1.8rem',
            lg: '1.8rem',
          },
          width: 'fit-content',
          textAlign: 'center',
          textWrap: 'stable',
        }}
      >
        Understood!
      </Typography>

      <Stack
        alignItems='center'
        sx={{
          width: {
            xs: '100%',
            sm: '100%',
            md: '85%',
            lg: '80%',
          },
        }}
        textAlign='center'
      >
        <Typography
          variant='body1'
          component='p'
          sx={{
            textAlign: 'center',
            fontFamily: 'Inter',
            fontWeight: 'normal',
            fontSize: {
              xs: '1.1rem',
              sm: '1.2rem',
              md: '1.2rem',
              lg: '1.3rem',
            },
            textWrap: 'pretty',
          }}
        >
          To boost your confidence in our placement support,{' '}
          <b>
            let’s walk you through how the key modules of our placement
            assistance
          </b>{' '}
          can help you maximise the chances of getting placed at top tech
          companies. Shall we?
        </Typography>
      </Stack>

      <Button
        sx={{
          width: 'unset',
          padding: '0.5rem 1.5rem',
          textTransform: 'none',
          fontFamily: 'Inter',
          fontSize: '1rem',
          fontWeight: 650,
          '&:hover': {
            color: 'white',
            backgroundColor: '#1976d2',
          },
          color: 'white',
          backgroundColor: '#1976d2',
        }}
        onClick={handleContinue}
        endIcon={<EastIcon />}
      >
        Continue
      </Button>
    </Stack>
  );
};

export default KeyModulesIntro;

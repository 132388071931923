import { useState } from 'react';
import { styled } from '@material-ui/core/styles';
import { TextField, Button, Typography, Box } from '@mui/material';
import { Stack, Checkbox, FormControlLabel } from '@material-ui/core';
import { Icon } from '@iconify/react';
import CloseOutlined from '@iconify/icons-ant-design/close-outlined';
import { LoadingButton } from '@material-ui/lab';
import axios from 'axios';
import { getUserId } from '../../configs/auth';
import { getExperience, getHeaders } from '../../apis/urls';
import SelectFieldComponent from 'src/layouts/shared/SelectFieldComponent';
import moment from 'moment';

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  maxHeight: 850,
  margin: 'auto',
  marginTop: 50,
  borderRadius: 15,
  backgroundColor: 'white',
  justifyContent: 'center',
  padding: 32,
}));

const AddExperience = (props) => {
  const [title, setTitle] = useState('');
  const [employment, setEmployment] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [company, setCompany] = useState('');
  const [currentlyWorking, setCurrentlyWorking] = useState(false);
  const [formData, setFormData] = useState({
    employment: '',
  });
  const [errors, setErrors] = useState({
    title: false,
    employment: false,
    startDate: false,
    company: false,
    endDate: false,
  });

  function handleSubmit() {
    let hasError = false;

    if (!title.trim()) {
      setErrors((prevErrors) => ({ ...prevErrors, title: true }));
      hasError = true;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, title: false }));
    }

    if (!employment.trim()) {
      setErrors((prevErrors) => ({ ...prevErrors, employment: true }));
      hasError = true;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, employment: false }));
    }

    if (!startDate.trim()) {
      setErrors((prevErrors) => ({ ...prevErrors, startDate: true }));
      hasError = true;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, startDate: false }));
    }

    if (!company.trim()) {
      setErrors((prevErrors) => ({ ...prevErrors, company: true }));
      hasError = true;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, company: false }));
    }

    if (!currentlyWorking && !endDate.trim()) {
      setErrors((prevErrors) => ({ ...prevErrors, endDate: true }));
      hasError = true;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, endDate: false }));
    }

    if (!hasError) {
      const body = {
        user_id: getUserId(),
        title,
        employment_type: employment,
        company_name: company,
        start_date: startDate,
        end_date: currentlyWorking ? null : endDate,
      };
      axios
        .post(getExperience(), body, {
          headers: getHeaders(),
        })
        .then((res) => {
          props.handleCloseExpModel(false);
          props.setSuccess(true);
          props.getExperienceData();
        })
        .catch((err) => {
          console.log(err);
          props.handleCloseExpModel(false);
        });
    }
  }

  const handleCurrentlyWorkingChange = (event) => {
    setCurrentlyWorking(event.target.checked);
    if (event.target.checked) {
      setEndDate('');
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (name === 'employment') {
      setEmployment(value);
    }
  };

  const employmentField = {
    name: 'employment',
    label: 'Employment type',
    required: true,
    options: [
      'Full-time',
      'Part-time',
      'Self-employed',
      'Freelance',
      'Internship',
      'Trainee',
    ],
  };

  return (
    <div>
      <ContentStyle width={600}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            mb: 4,
          }}
        >
          <Typography variant='h4'>Add Experience</Typography>
          <Button onClick={() => props.handleCloseExpModel(false)}>
            <Icon
              icon={CloseOutlined}
              width='18'
              height='18'
            />
          </Button>
        </Box>
        <form
          autoComplete='off'
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <Stack spacing={2}>
            <Stack spacing={2}>
              <Box>
                <TextField
                  fullWidth
                  label='Title'
                  placeholder='E.g. Software Developer'
                  onChange={(e) => setTitle(e.target.value)}
                  required
                  error={errors.title}
                  helperText={errors.title && 'Title is required'}
                  sx={{
                    marginTop: '0px',
                  }}
                />
              </Box>
              <Box>
                <SelectFieldComponent
                  field={employmentField}
                  formData={formData}
                  handleChange={handleChange}
                  error={errors.employment ? 'Employment type is required' : ''}
                />
              </Box>
              <Box>
                <TextField
                  fullWidth
                  label='Company name'
                  placeholder='E.g. Microsoft'
                  onChange={(e) => setCompany(e.target.value)}
                  error={errors.company}
                  helperText={errors.company && 'Company name is required'}
                />
              </Box>
              <Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={currentlyWorking}
                      onChange={handleCurrentlyWorkingChange}
                    />
                  }
                  label='I am currently working in this role'
                />
              </Box>
              <Box>
                <Typography
                  variant='caption'
                  gutterBottom
                  sx={{
                    fontSize: '15px',
                    marginBottom: '0px',
                    display: 'block',
                  }}
                >
                  Start date <span style={{ color: 'red' }}>*</span>
                </Typography>
                <TextField
                  fullWidth
                  type='date'
                  id='start_date'
                  placeholder='Start date'
                  InputProps={{
                    inputProps: {
                      max: moment().format('YYYY-MM-DD'),
                    },
                  }}
                  onChange={(e) => setStartDate(e.target.value)}
                  error={errors.startDate}
                  helperText={errors.startDate && 'Start date is required'}
                />
              </Box>
              <Box>
                <Typography
                  variant='caption'
                  gutterBottom
                  sx={{
                    fontSize: '15px',
                    marginBottom: '0px',
                    display: 'block',
                  }}
                >
                  End date{' '}
                  {!currentlyWorking && <span style={{ color: 'red' }}>*</span>}
                </Typography>
                <TextField
                  fullWidth
                  type='date'
                  placeholder='End date'
                  InputProps={{
                    inputProps: {
                      max: moment().format('YYYY-MM-DD'),
                    },
                  }}
                  onChange={(e) => setEndDate(e.target.value)}
                  disabled={currentlyWorking}
                  error={!currentlyWorking && errors.endDate}
                  helperText={
                    !currentlyWorking &&
                    errors.endDate &&
                    'End date is required'
                  }
                />
              </Box>
            </Stack>
            <LoadingButton
              fullWidth
              size='large'
              type='submit'
              variant='contained'
              color='secondary'
            >
              Add Experience
            </LoadingButton>
          </Stack>
        </form>
      </ContentStyle>
    </div>
  );
};

export default AddExperience;

import { useEffect, useState } from 'react';
import axios from 'axios';
// material
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Container,
    Typography,
    Stack,
    Grid,
    Button,
    Divider,
    Alert
} from '@material-ui/core';
import PeopleOutline from '@iconify/icons-eva/email-outline';
import LinkedinIcon from '@iconify/icons-eva/linkedin-fill';
import GithubIcon from '@iconify/icons-eva/github-fill';
import { Icon } from '@iconify/react';
import { styled } from '@material-ui/core/styles';
import { fetchUserPublicProfile, fetchUserEnrollmentsUrl } from '../apis/urls';
import { getAccessToken, getUserId, getUserImage, getUserMail, getUserName } from '../configs/auth';
import Page from '../components/Page';

const objectiveStyle = {
    fontSize: '14px'
};

const linkStyle = {
    textDecoration: 'none',
    color: '#fff'
};

const tagStyle = {
    backgroundColor: '#eee',
    marginBottom: '5px',
    marginRight: '8px',
    padding: '6px',
    textAlign: 'center',
    borderRadius: '15px',
    fontSize: '13px'
};

const iconContainer = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    columnGap: '0.875rem'
};

const linkedinStyle = {
    height: '25px',
    width: '25px',
    color: '#0A66C2'
};

const githubStyle = {
    height: '25px',
    width: '25px',
    color: '#000'
};

const tagContainer = {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start'
};
const iconStyle = {
    width: '40px',
    height: '40px'
};
const StyledIconDiv = styled('div')(({ theme }) => ({
    display: 'flex',
    width: 'auto',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: '1px solid lightgrey',
    borderRadius: '25px',
    padding: '2px 10px'
}));

export default function UserProfile() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [user, setUser] = useState([]);
    const [programs, setPrograms] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [errors, setErrors] = useState(false);

    useEffect(() => {
        axios
            .get(fetchUserPublicProfile(getUserName()), {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    'Access-Token': getAccessToken()
                }
            })
            .then((res) => {
                const userData = res.data;
                setUser(userData);
                setErrorMessage('');
                setErrors(false);
            })
            .catch((e) => {
                const connection_error = JSON.parse(JSON.stringify(e));
                if (connection_error.message === 'Network Error') {
                    setErrors(true);
                    setErrorMessage("Can't connect to the servers right now");
                } else if (e.response && e.response.data) {
                    const { data } = e.response;
                    setErrors(true);
                    setErrorMessage(data.message);
                }
            });
    }, []);

    const closeError = () => {
        setErrors(false);
    };

    if (errors) {
        return (
            <Alert onClose={closeError} severity="error">
                {!errorMessage
                    ? "Can't fetch user from the server. Please Try Again"
                    : errorMessage}
            </Alert>
        );
    }

    return (
        <Page title="Learning - HeyCoach">
            <Container>
                <Typography variant="h4" gutterBottom>
                    My Profile
                </Typography>

                <Box>
                    <Card>
                        <CardContent sx={{ paddingTop: '0px', paddingBottom: '0px' }}>
                            <Stack
                                direction={{ xs: 'column', sm: 'row' }}
                                alignItems="flex-start"
                                justifyContent="center"
                                divider={<Divider orientation="vertical" flexItem />}
                                spacing={8}
                            >
                                <Box sx={{ padding: '24px' }}>
                                    <Avatar
                                        src={getUserImage()}
                                        style={{ height: '200px', width: '200px' }}
                                    />
                                    <Grid item>
                                        <Typography
                                            mt={2}
                                            color="textSecondary"
                                            variant="h5"
                                            component="h5"
                                            style={{ textAlign: 'center' }}
                                        >
                                            <Button
                                                startIcon={<Icon icon={PeopleOutline} />}
                                                variant="contained"
                                                color="secondary"
                                            >
                                                <a style={linkStyle} href={`mailto:${user.email}`}>
                                                    Contact
                                                </a>
                                            </Button>
                                        </Typography>
                                    </Grid>
                                </Box>
                                <Box
                                    sx={{ paddingTop: '24px' }}
                                    direction="column"
                                    alignItems="flex-start"
                                    m={4}
                                >
                                    <Grid
                                        container
                                        spacing={{ xs: 1 }}
                                        direction="column"
                                        justifyContent={{ xs: 'center', sm: 'flex-start' }}
                                        alignItems={{ sm: 'flex-start' }}
                                    >
                                        <Grid item>
                                            <Typography
                                                color="textSecondary"
                                                variant="h2"
                                                component="h2"
                                            >
                                                <p style={{ textAlign: 'center' }}>{user.name}</p>
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                variant="subtitle1"
                                                color="textSecondary"
                                                component="h6"
                                                alignSelf="start"
                                            >
                                                <p style={{ textAlign: 'center' }}>
                                                    Software Engineer
                                                </p>
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between'
                                            }}
                                        >
                                            {user?.gender && (
                                                <StyledIconDiv>
                                                    <Typography
                                                        sx={{ fontWeight: 'bold' }}
                                                        component="p"
                                                    >
                                                        Gender:
                                                    </Typography>
                                                    <Typography
                                                        sx={{ marginLeft: '10px' }}
                                                        component="p"
                                                    >
                                                        {user?.gender}
                                                    </Typography>
                                                </StyledIconDiv>
                                            )}

                                            {user?.phone && (
                                                <StyledIconDiv sx={{ marginLeft: '20px' }}>
                                                    <Typography
                                                        sx={{ fontWeight: 'bold' }}
                                                        component="p"
                                                    >
                                                        Phone:
                                                    </Typography>
                                                    <Typography
                                                        sx={{ marginLeft: '10px' }}
                                                        component="p"
                                                    >
                                                        {user?.phone}
                                                    </Typography>
                                                </StyledIconDiv>
                                            )}
                                        </Grid>
                                        <Grid item>
                                            {user?.email && (
                                                <StyledIconDiv>
                                                    <Typography
                                                        sx={{ fontWeight: 'bold' }}
                                                        component="p"
                                                    >
                                                        Email:
                                                    </Typography>
                                                    <Typography
                                                        sx={{ marginLeft: '10px' }}
                                                        component="p"
                                                    >
                                                        {user?.email}
                                                    </Typography>
                                                </StyledIconDiv>
                                            )}
                                        </Grid>
                                        <Grid item>
                                            {user?.profile && user.profile[0]?.objective && (
                                                <div>
                                                    <p style={objectiveStyle}>
                                                        {user.profile[0].objective &&
                                                            user?.profile[0]?.objective}
                                                    </p>
                                                </div>
                                            )}
                                        </Grid>
                                        <Grid item>
                                            <div style={tagContainer}>
                                                {user?.tags &&
                                                    user.tags.map((tag) => {
                                                        return <p style={tagStyle}>{tag.name}</p>;
                                                    })}
                                            </div>
                                        </Grid>
                                        <Grid item>
                                            <div style={iconContainer}>
                                                {user?.social_media_links &&
                                                    user.social_media_links.map((link) => {
                                                        if (link.title === 'Linkedin') {
                                                            return (
                                                                <Icon
                                                                    style={linkedinStyle}
                                                                    icon={LinkedinIcon}
                                                                />
                                                            );
                                                        }
                                                        if (link.title === 'Github') {
                                                            return (
                                                                <Icon
                                                                    style={githubStyle}
                                                                    icon={GithubIcon}
                                                                />
                                                            );
                                                        }
                                                        return null;
                                                    })}
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Stack>
                        </CardContent>
                    </Card>
                </Box>
            </Container>
        </Page>
    );
}

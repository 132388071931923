export const validateQuestion = (questionData) => {
  const hasAnswer = questionData.quiz_question_options.some(
    (option) => option.answer === true
  );
  const isSingle = questionData.question_type === 'single';
  const hasOneCorrectAnswer = () => {
    if (!isSingle) {
      return true;
    }
    const correctAnswers = questionData.quiz_question_options.filter(
      (option) => option.answer === true
    ).length;
    return correctAnswers === 1;
  };
  if (!hasAnswer) {
    return 'Please select at least one option as the answer';
  }
  if (isSingle && !hasOneCorrectAnswer()) {
    return 'Single choice question cannot have more than one correct answers';
  }
  return null;
};

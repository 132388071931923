import React from 'react';
import { Card, CardContent, Typography } from "@material-ui/core";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export default function CodingTestProblemList({
  allContestProblems,
  handleProblemSelect
}) {

  return (
    allContestProblems.map((problem, index) => (
      <Card 
        sx={{
          margin: 2, 
          cursor: 'pointer' 
        }} 
        onClick={(event) => handleProblemSelect(event, problem)}
      >
        <CardContent>
          <div 
            style={{
              display: "flex", 
              justifyContent:"space-between"
            }}
          >
            <Typography variant="h5">
              {problem.title || "Problem name not available"}
            </Typography>
            <ArrowForwardIcon 
              fontSize='medium' 
              color="primary"
            />
          </div>
        </CardContent>
      </Card>
    ))
  );
}
import React from 'react';
import { Typography } from '@mui/material';
import { Stack } from '@material-ui/core';

export default function BatchChangePolicy() {
  return (
    <Stack
      m='1%'
      spacing={2}
    >
      <Typography
        textAlign='center'
        variant='h3'
      >
        Hey Coach Batch Change Policy
      </Typography>
      <Typography variant='body1'>
        HeyCoach strives to deliver the best quality education. Hence, we only
        onboard quality instructors who have cleared MAANG interviews and
        actively participate in conducting interviews in their current
        organisation. We deliver good quality in terms of personalisation to
        each learner by onboarding a highly limited number of learners in each
        cohort. This also means that once we lock the cohort finances, it
        becomes operationally and financially difficult for us to entertain
        changes in the batches. For reasons which are not in our control, we
        have a policy in place to better structure batch change requests.
      </Typography>

      <Typography variant='h6'>Who is eligible for a batch change?</Typography>
      <Typography
        component='ol'
        pl='2%'
      >
        <Typography component='li'>
          Active learners who have paid the seat blocking amount and the entire
          course fees.
        </Typography>
        <Typography component='li'>
          Learners whose cohorts have begun and at least their first
          class/Induction session is completed.
        </Typography>
        <Typography component='li'>
          Learners who have a Genuine reason to move to a future cohort. Some of
          the genuine reasons may include, but are not restricted to the
          following:
        </Typography>
        <Typography
          component='ul'
          pl='1%'
        >
          <li>Personal/Family Medical conditions</li>
          <li>Financial constraints</li>
          <li>Increase in the work pressure at the office</li>
          <li>Unable to cope with the speed of the cohort</li>
        </Typography>
      </Typography>

      <Typography variant='h6'>How to request a batch change?</Typography>
      <Typography
        component='ol'
        pl='2%'
      >
        <Typography component='li'>
          Talk to the Program management team, by messaging your POC on chat
          about your concerns and give them a chance to resolve your issue.
        </Typography>
        <Typography component='li'>
          If the POC is unable to resolve the issue within the mentioned time
          frame, write an email to{' '}
          <a href='mailto:support@heycoach.co.in'>support@heycoach.co.in</a> and
          request a change in your batch. Be sure to mention your reason in the
          email.
        </Typography>
        <Typography component='li'>
          You shall receive an acknowledgement within 24 hours for the same.
        </Typography>
        <Typography component='li'>
          Once such batch change is approved by email, complete the payment of
          your batch change fees and get onboarded to the future batch in
          furtherance to your request.
        </Typography>
      </Typography>

      <Typography variant='h6'>
        Calculation of the batch change fees:
      </Typography>
      <Typography
        component='ol'
        pl='2%'
      >
        <Stack spacing={1}>
          <Typography
            component='li'
            variant='subtitle1'
          >
            Within 30 days of first class
          </Typography>
          <Typography
            component='ul'
            pl='1%'
          >
            <li>Premium learning portal access change fees: INR 8,000.</li>
            <li>Seat blocking charges: INR 4,000.</li>
            <li>LEP charges for transition duration: INR 8,000.</li>
            <li>Teaching staff fees: INR 12,000</li>
          </Typography>

          <Typography
            component='li'
            variant='subtitle1'
          >
            Between 31 and 90 days of first class
          </Typography>
          <Typography
            component='ul'
            pl='1%'
          >
            <li>Premium learning portal access change fees: INR 8,000.</li>
            <li>Seat blocking charges: INR 4,000.</li>
            <li>LEP charges for transition duration: INR 8,000.</li>
            <li>Teaching staff fees: INR 34,000.</li>
          </Typography>

          <Typography
            component='li'
            variant='subtitle1'
          >
            After 90 days of first class
          </Typography>
          <Typography
            component='ul'
            pl='1%'
          >
            <li>80% of the total course fees.</li>
          </Typography>
        </Stack>
      </Typography>
    </Stack>
  );
}

import React from 'react';
import { Box, Button, Card, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getUserName } from '../../configs/auth';

const ResumeLearningCard = () => {
  const userName = getUserName();
  const navigate = useNavigate();
  const currentProgram = useSelector((state) => state.programs?.currentProgram);
  const programName = currentProgram?.programSlug;

  const resumeLearningClickHandler = () => {
    navigate(`/student/${programName}/sessions`);
  };

  return (
    <Card
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        p: 2,
        background: 'linear-gradient(90deg, #80d0c7, #13547a)',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography
          variant='h5'
          color='white'
        >
          Welcome {userName}!
        </Typography>
        <Typography color='white'>
          Ready to start your learning today?
        </Typography>
      </Box>
      <Button
        endIcon={<ArrowForwardIcon />}
        sx={{
          borderRadius: '4px',
          background: 'white',
          cursor: 'pointer',
          color: 'black',
          fontWeight: 'light',
          p: '8px 18px',
          '&:hover': {
            backgroundColor: 'white',
            '& .MuiSvgIcon-root': {
              transform: 'translateX(7px)',
              transition: 'transform 0.3s ease',
            },
          },
        }}
        onClick={resumeLearningClickHandler}
      >
        Resume Learning
      </Button>
    </Card>
  );
};

export default ResumeLearningCard;

// @ts-nocheck
// scroll bar
import 'simplebar/src/simplebar.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import axios from 'axios';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { GoogleOAuthProvider, googleLogout } from '@react-oauth/google';
import App from './App';
import { store } from './components/redux-store/store';
import consumer from './cable';
import { setUserLoggedOut } from './configs/auth';
import './index.css';

axios.defaults.timeout = 60 * 1000; // in milliseconds

export const persistor = persistStore(store);

export const clearReduxPersistState = () => {
  persistor.pause();
  persistor.flush().then(() => {
    return persistor.purge();
  });
};

const logoutUser = () => {
  setUserLoggedOut();
  clearReduxPersistState();
  googleLogout();
  window.location.href = '/';
};

axios.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      // unauthorized request
      logoutUser();
    }
    return Promise.reject(error);
  }
);

ReactDOM.render(
  <HelmetProvider>
    <Provider store={store}>
      <GoogleOAuthProvider clientId='60670340974-jmh7mg9gkg68hk3jfogv22583k5270ua.apps.googleusercontent.com'>
        <PersistGate
          loading={null}
          persistor={persistor}
        >
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </PersistGate>
      </GoogleOAuthProvider>
    </Provider>
  </HelmetProvider>,
  document.getElementById('root')
);

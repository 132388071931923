import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
// material
import axios from 'axios';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import Footer from '../layouts/studentDashboard/Footer';
import DashboardNavbar from '../layouts/shared/DashboardNavbar';
import DashboardNavbarV2 from 'src/layouts/shared/DashboardNavbarV2';
import { StudentProgramProvider } from '../configs/StudentProgramContext';
import { SessionsListProvider } from '../configs/SessionsListContext';

import { getUserType } from '../configs/auth';
import {
  fetchProgramSessionsUrl,
  getHeaders,
  fetchSessionTests,
  fetchProgramResourcesURL,
  fetchProgramCodingAssignmentsUrl,
} from '../apis/urls';
import { setSessions } from '../components/redux-store/slices/sessionSlice';
import ErrorNotifier from '../components/ToastNotifications/ErrorNotifier';
import { shouldShowNewSessionsUI } from 'src/components/utils/common';
import LogoV2 from 'src/components/LogoV2';

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

// const RootStyle = styled('div')({
//   display: 'flex',
//   minHeight: '100%',
//   overflow: 'hidden',
//   backgroundColor: 'rgb(229, 245, 253)',
//   position: 'fixed',
// });

const RootStyle = styled('div')(({ isProfilePage }) => ({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
  backgroundColor: 'rgb(229, 245, 253)',
  position: isProfilePage ? 'static' : 'fixed',
}));

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  // paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    // paddingTop: APP_BAR_DESKTOP + 24,
    // paddingTop: APP_BAR_DESKTOP,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export default function StudentScreenV2() {
  const dispatch = useDispatch();
  const userType = getUserType();
  const currentProgram = useSelector((state) => state.programs.currentProgram);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [open, setOpen] = useState(false);
  const location = useLocation(); // Get the current location
  const isProfilePage = location.pathname === '/student/user/settings';

  useEffect(() => {
    if (
      userType !== 'finance' &&
      currentProgram &&
      currentProgram?.hasProgramStarted
    ) {
      fetchSessions();
    }
    return () => {};
  }, [currentProgram]);

  const fetchSessions = async () => {
    dispatch(
      setSessions({
        sessions: [],
        pre_reads: [],
        coding_assignments: [],
        session_tests: [],
        loading: true,
      })
    );

    try {
      let session_tests_res;
      let resource_res;
      let coding_assignment_res;

      const sessions_res = await axios.get(fetchProgramSessionsUrl(), {
        headers: getHeaders(currentProgram?.programId),
      });

      if (shouldShowNewSessionsUI(currentProgram?.programId)) {
        session_tests_res = await axios.get(fetchSessionTests(), {
          headers: getHeaders(currentProgram?.programId),
        });

        resource_res = await axios.get(fetchProgramResourcesURL(), {
          headers: getHeaders(currentProgram?.programId),
        });

        coding_assignment_res = await axios.get(
          fetchProgramCodingAssignmentsUrl(),
          {
            headers: getHeaders(currentProgram?.programId),
          }
        );
      }

      dispatch(
        setSessions({
          sessions: sessions_res?.data ?? [],
          pre_reads: resource_res?.data ?? [],
          coding_assignments: coding_assignment_res?.data ?? [],
          session_tests: session_tests_res?.data,
          loading: false,
        })
      );
    } catch (err) {
      dispatch(
        setSessions({
          sessions: [],
          pre_reads: [],
          coding_assignments: [],
          session_tests: [],
          loading: false,
        })
      );
      console.log(err);
      setHasError(true);
      setErrorMessage(err.response?.data?.message || err.message);
    }
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      <RootStyle isProfilePage={isProfilePage}>
        <MainStyle>
          <LogoV2 />
          <Outlet />
        </MainStyle>
      </RootStyle>
      {/* <Footer /> */}
    </>
  );
}

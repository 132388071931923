import React, { forwardRef, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import ChatIcon from '@mui/icons-material/Chat';
import Slide from '@mui/material/Slide';
import axios from 'axios';
import { fetchTaskCommentsURL, getHeaders, postTaskCommentURL } from '../../../../apis/urls';
import Comments from '../../../Comments/Comments';

const Transition = forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AdminDetailedTaskDialog({ task, openDetailedTask, setOpenDetailedTask, programId, setErrorMessage, setHasError }) {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getComments();
    return () => {};
  }, []);

  function getComments() {
    axios.get(fetchTaskCommentsURL(task.id), {
      headers: getHeaders(programId)
    })
    .then((res) => {
      setComments(res.data);
      setLoading(false);
    })
    .catch((err) => {
      console.log(err);
      setHasError(true);
      setErrorMessage(err?.response?.data?.message || err.message);
    })
  }

  const postComment = () => {
    axios.post(postTaskCommentURL(), {
      task_id: task.id,
      note: newComment
    }, {
      headers: getHeaders(programId)
    })
    .then((res) => {
      setNewComment("");
      setComments([...comments, res.data]);
    })
    .catch((err) => {
      console.log(err);
      setHasError(true);
      setErrorMessage(err?.response?.data?.message || err.message);
    })
  }

  return (
    <div>
      <Dialog
        fullScreen
        open={openDetailedTask}
        onClose={() => setOpenDetailedTask(false)}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton edge="start" color="inherit">
              <ChatIcon />
            </IconButton>
            <Typography sx={{ ml: 1, flex: 1 }} variant="h6" component="div">
              {task.title} discussion
            </Typography>
            <IconButton edge="end" color="inherit" onClick={() => setOpenDetailedTask(false)}>
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Comments {...{ comments, postComment, newComment, setNewComment, loading, setLoading }} />
      </Dialog>
    </div>
  );
}

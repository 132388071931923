import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Button, Typography, Box, Modal, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  createQuizAttempt,
  fetchProgramEnrollmentsUrl,
  getHeaders,
  getQuizzes,
} from '../../../../apis/urls';
import LearnerDropdown from './LearnerDropdown';
import QuizDropdown from './QuizzesDropdown';
import QuizAttemptDetails from './QuizAttemptDetails';

const initialState = {
  opens_at: '',
  closes_at: '',
  duration: '',
  quiz_type: 'practice',
  show_scores: false,
};

export default function SendQuizModal({
  open,
  onClose,
  setHasError,
  setErrorMessage,
  setIsSuccess,
  setSuccessMessage,
}) {
  const [formState, setFormState] = useState(initialState);
  const [learners, setLearners] = useState([]);
  const [selectedQuizId, setSelectedQuizId] = useState('');
  const [selectedLearners, setSelectedLearners] = useState([]);
  const [quizzes, setQuizzes] = useState([]);
  const currentProgram = useSelector((state) => state.programs.currentProgram);

  useEffect(() => {
    if (currentProgram) {
      fetchQuizzes();
      fetchEnrollmentsData();
    }
    return () => {};
  }, [currentProgram]);

  function fetchQuizzes() {
    axios
      .get(getQuizzes(), {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((res) => {
        setQuizzes(res?.data?.data);
      })
      .catch((e) => {
        console.log(e);
        setHasError(true);
        setErrorMessage(
          e.response?.data?.message ||
            'An error occurred while fetching the quizzes'
        );
      });
    return () => {};
  }

  function fetchEnrollmentsData() {
    axios
      .get(fetchProgramEnrollmentsUrl(), {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((res) => {
        const allLearners = res?.data.map((element) => ({
          id: element.id,
          name: element.user.name,
          email: element.user.email,
        }));
        setLearners(allLearners);
      })
      .catch((e) => {
        console.log(e);
        setHasError(true);
        setErrorMessage(
          e.response?.data?.message ||
            'An error occurred while fetching the learners'
        );
      });
  }

  const handleSendQuiz = () => {
    const validationError = validateForm();
    if (validationError) {
      setHasError(true);
      setErrorMessage(validationError);
      return;
    }

    const selectedEnrollmentIds =
      selectedLearners.length > 0
        ? selectedLearners
        : learners.map((learner) => learner.id);

    const body = {
      quiz_id: selectedQuizId,
      enrollment_ids: selectedEnrollmentIds,
      ...formState,
    };

    if (formState.quiz_type === 'exam') {
      const opensAt = moment(body.opens_at);
      const closesAt = moment(body.closes_at);
      const duration = closesAt.diff(opensAt, 'minutes');
      body.duration = duration;
    }

    body.opens_at = moment(body.opens_at).utc();
    body.closes_at = moment(body.closes_at).utc();

    axios
      .post(createQuizAttempt(), body, {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((res) => {
        setIsSuccess(true);
        setSuccessMessage(res?.data?.message);
      })
      .catch((e) => {
        console.log(e);
        setHasError(true);
        setErrorMessage(
          e.response?.data?.message ||
            'An error occurred while sending the quiz'
        );
      });
    handleClose();
  };

  const handleClose = () => {
    setSelectedLearners([]);
    setSelectedQuizId('');
    setFormState(initialState);
    onClose();
  };

  function validateForm() {
    if (
      !formState.opens_at ||
      !formState.closes_at ||
      (formState.quiz_type === 'practice' && !formState.duration)
    ) {
      return 'All Fields are required.';
    }

    const opensAt = moment(formState.opens_at, 'YYYY-MM-DD HH:mm');
    const closesAt = moment(formState.closes_at, 'YYYY-MM-DD HH:mm');

    if (closesAt.isBefore(opensAt)) {
      return 'Closing time cannot be before opening time.';
    }
    if (formState.quiz_type === 'practice') {
      const duration = moment.duration(closesAt.diff(opensAt, 'minutes'));
      if (duration < formState.duration) {
        return 'Invalid duration for practice quiz.';
      }
    }
    return null;
  }

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxWidth: 650,
            maxHeight: '90%',
            bgcolor: 'background.paper',
            p: 4,
            overflowY: 'auto',
          }}
        >
          <IconButton
            aria-label='Close'
            sx={{
              position: 'absolute',
              top: 8,
              right: 4,
            }}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
          <Box mt={2}>
            <QuizDropdown
              quizzes={quizzes}
              setSelectedQuizId={setSelectedQuizId}
            />
            <QuizAttemptDetails
              formState={formState}
              setFormState={setFormState}
            />
            <Box
              sx={{
                textAlign: 'center',
                mt: 2,
              }}
            >
              <Button
                disabled={!selectedQuizId || selectedLearners.length > 0}
                variant='contained'
                onClick={handleSendQuiz}
              >
                Send to All Program Learners
              </Button>
              <Typography mt={1}>OR</Typography>
              <LearnerDropdown
                learners={learners}
                selectedLearners={selectedLearners}
                setSelectedLearners={setSelectedLearners}
              />
              <Button
                disabled={!selectedQuizId || selectedLearners.length === 0}
                variant='contained'
                onClick={handleSendQuiz}
                sx={{ mt: 2 }}
              >
                Send Quiz to Selected Learners
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

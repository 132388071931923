// @ts-nocheck
import { Stack, Typography, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import QuestionDescription from './QuestionDescription';

const options = [
  'Live Sessions',
  'Doubt Solving',
  'Interview Preparation',
  'Tools for practicing DSA',
  'Structured DSA & System Design Curriculum',
  'Guidance from top mentor',
];

const ProfileEnhancementProgramAspects = ({
  id,
  response,
  setResponse,
  setShouldAllowNext,
}) => {
  const theme = useTheme();

  useEffect(() => {
    if (!(id in response) || response[id].length === 0) {
      setShouldAllowNext(false);
    }
    return () => {};
  }, [response]);

  const handleClick = (value) => {
    if (!(id in response)) {
      setResponse((prev) => ({ ...prev, [id]: [value] }));
    } else if (response[id].includes(value)) {
      setResponse((prev) => {
        const np = prev[id].filter((p) => p !== value);
        return {
          ...prev,
          [id]: np,
        };
      });
    } else {
      if ((response[id] ?? []).length === 3) {
        return;
      }

      setResponse((prev) => {
        const x = { ...prev };
        x[id].push(value);
        return x;
      });
    }
    setShouldAllowNext(true);
  };

  const shouldHighlight = (val) => {
    return response[id] && response[id].includes(val);
  };

  return (
    <Stack spacing={2}>
      <>
        <QuestionDescription
          title='Recognizing these challenges is the first step toward improvement.'
          subTitle='Which aspects of our program do you believe will most significantly enhance your profile?'
        />
        <Typography
          variant='body1'
          component='p'
          sx={{
            fontWeight: 400,
            fontFamily: 'Inter',
          }}
        >
          <i>(select upto 3)</i>
        </Typography>
      </>

      <Stack spacing={1}>
        {options.map((opt, index) => {
          return (
            <Typography
              variant='body1'
              component='p'
              sx={{
                borderRadius: '10px',
                padding: '5px',
                background: 'white',
                width: 'fit-content',
                cursor: 'pointer',
                fontSize: '1.2rem',
                fontFamily: 'Inter',
                ...(shouldHighlight(opt) && {
                  border: '2px solid blue',
                }),
                [theme.breakpoints.down('sm')]: {
                  fontSize: '0.9rem',
                },
              }}
              onClick={() => handleClick(opt)}
            >
              {String.fromCharCode(65 + index)}. &nbsp; {opt}
            </Typography>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default ProfileEnhancementProgramAspects;

import moment from 'moment';

export function getOfferRate(coupon) {
  if (coupon.rate_type === 'amount') {
    return `₹${coupon.rate}`;
  } else if (coupon.rate_type === 'percentage-upto') {
    return `Upto ${coupon.rate}% off`;
  } else if (coupon.rate_type === 'special-sale') {
    return `Special-sale`;
  } else {
    return `Flat ${coupon.rate}% off`;
  }
}

export function formatTimestamp(timestamp) {
  const date = new Date(timestamp);

  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'long' });
  const year = date.getFullYear();

  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  const daySuffix = getDaySuffix(day);

  const formattedDate = `${day}${daySuffix} ${month}, ${year}, ${hours}:${
    minutes < 10 ? '0' : ''
  }${minutes}${ampm}`;
  return formattedDate;
}

function getDaySuffix(day) {
  if (day > 3 && day < 21) return 'th';
  switch (day % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
}

export const isExpired = (coupon) => {
  return moment(coupon.valid_till).isBefore(moment());
};

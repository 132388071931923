import React from 'react';
import { AdminProgramProvider } from '../../../configs/AdminProgramContext';
import { AssignmentProvider } from '../../../configs/AssignmentContext';
import Assignments from '../../../views/Assignments';


const AssignmentsViewForCoaches = () => {
    return (
        <AdminProgramProvider>
            <AssignmentProvider>
                <Assignments />
            </AssignmentProvider>
        </AdminProgramProvider>
    );
};

export default AssignmentsViewForCoaches;

import React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@material-ui/core';
import ProgressBar from '../Atoms/ProgressBar';
import LeftArrowCircle from '../Atoms/LeftArrowCircle';
import RightArrowCircle from '../Atoms/RightArrowCircle';

const ProblemProgress = ({
  progress,
  width,
  height,
  rotateCircles,
  totalProblemCounts,
  solvedProblemsCount,
  lockedTopic,
  lastUnlockedTopic,
  prevTopicName,
}) => {
  return (
    <>
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        position='relative'
        bottom='30px'
        marginTop='200px'
        width='100%'
        ml={50}
      >
        {lockedTopic ? (
          <Box ml={2}>
            <Typography
              sx={{
                textWrap: 'wrap',
                textAlign: 'center',
                width: '200px',
              }}
            >
              Complete {prevTopicName}
            </Typography>
          </Box>
        ) : (
          <>
            <Box ml={4}>
              <Typography>{solvedProblemsCount} problems attended</Typography>
            </Box>
            <ProgressBar
              progress={progress}
              width={20}
              height={10}
            />
          </>
        )}
      </Box>
      <Box>
        <LeftArrowCircle rotateCircles={rotateCircles} />
        <RightArrowCircle rotateCircles={rotateCircles} />
      </Box>
    </>
  );
};

export default ProblemProgress;

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Chip, Typography } from '@material-ui/core';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@material-ui/lab';
import axios from 'axios';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import ScheduleIcon from '@mui/icons-material/Schedule';
import moment from 'moment';
import { fetchResourcesUrl, getHeaders } from '../../apis/urls';
import ProgramNotStarted from '../ProgramNotStarted';
import { session_status } from '../utils/constants';
import { Modules } from './Modules';
import { SessionDetails } from './SessionDetails';
import { useSessionContext } from '../sessions/SessionContext';
const { CONDUCTED, VERIFIED, PAYMENT_PROCESSED } = session_status;
const sessionCompletedStatuses = [CONDUCTED, VERIFIED, PAYMENT_PROCESSED];

function getPropertiesBasedOnSchedule(date, sessionDurationInMinutes, status) {
  if (!date) {
    return {
      color: 'text.disabled',
      status: 'unscheduled',
    };
  }
  const today = moment();
  const start = moment(date);
  const sessionHour = Math.floor(sessionDurationInMinutes / 60);
  const sessionMinute = sessionDurationInMinutes % 60;
  const properties = {};

  if (
    today.date() === start.date() &&
    today.month() === start.month() &&
    today.year() === start.year()
  ) {
    if (sessionCompletedStatuses.includes(status)) {
      properties.color = '#388e3c';
      properties.status = 'done';
    } else {
      properties.color = '#ffa726'; // orange
      properties.status = 'today';
    }
  } else if (start.isAfter(today)) {
    properties.color = 'grey';
    properties.status = 'pending';
  } else {
    switch (true) {
      case sessionCompletedStatuses.includes(status):
        properties.color = '#388e3c';
        properties.status = 'done';
        break;
      default:
        properties.color = '#FF0000';
        properties.status = 'not_marked_conducted';
        break;
    }
  }
  return properties;
}

function getGroupProperties(sessions) {
  /**
   * returns 'done' when all the sessions of the group have been conducted.
   * returns 'today' if there's atleast one session scheduled today in the group.
   * returns 'pending' for future sessions having date, or no date assigned.
   */
  const today = moment();
  const properties = {
    color: '#388e3c',
    status: 'done',
  };

  let allDone = true;
  let scheduledToday = false;
  let hasUnMarkedSession = false;
  sessions.forEach((session, idx) => {
    if (!session.start) {
      // We haven't yet assigned a date to this session. Hence, the session is still "unscheduled".
      allDone = false;
      return;
    }
    const date = moment(session.start);
    const sessionHour = Math.floor(session?.session_type?.duration / 60);
    const sessionMinute = session?.session_type?.duration % 60;

    if (
      today.date() === date.date() &&
      today.month() === date.month() &&
      today.year() === date.year()
    ) {
      const laterToday = moment(session.start);
      laterToday.add(sessionHour, 'hours').add(sessionMinute, 'minutes');

      if (!sessionCompletedStatuses.includes(session.status)) {
        allDone = false;
        scheduledToday = true;
      }
    } else if (date.isAfter(today)) {
      allDone = false;
    } else if (!sessionCompletedStatuses.includes(session.status)) {
      allDone = false;
      hasUnMarkedSession = true;
    }
  });

  if (scheduledToday) {
    properties.color = '#ffa726';
    properties.status = 'today';
  } else if (!allDone) {
    if (hasUnMarkedSession) {
      properties.color = '#FF0000';
      properties.status = 'not_marked_conducted';
    } else {
      properties.color = 'grey';
      properties.status = 'pending';
    }
  }
  return properties;
}

function SessionStatusIcon({ color, status }) {
  switch (status) {
    case 'done':
      return (
        <CheckCircleIcon
          fontSize='small'
          sx={{ color }}
        />
      );
    case 'today':
      return (
        <ScheduleIcon
          fontSize='small'
          sx={{ color }}
        />
      );
    case 'pending':
      return (
        <PendingOutlinedIcon
          fontSize='small'
          sx={{ color }}
        />
      );
    default:
      return (
        <PendingOutlinedIcon
          fontSize='small'
          sx={{ color }}
        />
      );
  }
}

const isCompleted = (details, type) => {
  return (
    (type === 'assignment' && details?.has_completed) ||
    (type === 'session' &&
      ['conducted', 'verified', 'payment_processed'].includes(
        details.status
      )) ||
    (type === 'preRead' && details?.completed)
  );
};

export const NewSessions = () => {
  const [selectedSessionId, setSelectedSessionId] = useState('');
  const [singleSessionData, setSingleSessionData] = useState({});
  const { setMaterialResource } = useSessionContext();
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [groupToSessions, setGroupToSessions] = useState({});
  const currentProgram = useSelector((state) => state.programs.currentProgram);
  const sessionsDetails = useSelector((state) => state.sessions);
  const [sessionChanged, setSessionChanged] = useState(false);
  const [selectedSessionTopic, setSelectedSessionTopic] = useState('');
  const [hideModules, setHideModules] = useState(false);
  useEffect(() => {
    if (sessionsDetails?.sessions.length > 0) {
      const tmpGroups = {};
      const { sessions } = sessionsDetails;
      Object.keys(sessions).forEach((sessId, idx) => {
        const session = sessions[sessId];
        if (!session?.group_name) {
          return;
        }

        const { group_name } = session;
        if (tmpGroups[group_name.trim()]) {
          tmpGroups[group_name.trim()].sessions.push({
            ...session,
            type: 'session',
            completed: isCompleted(session, 'session'),
          });
          return;
        }

        tmpGroups[group_name.trim()] = {
          sessions: [
            {
              ...session,
              type: 'session',
              completed: isCompleted(session, 'session'),
            },
          ],
          coding_assignments: [],
          pre_reads: [],
        };
      });

      Object.keys(tmpGroups).forEach((key, index) => {
        tmpGroups[key].groupId = index + 1;
      });

      sessionsDetails?.coding_assignments?.forEach((assignment) => {
        const { session_id } = assignment;
        const session = sessions.find((session) => session.id === session_id);
        if (session) {
          const { group_name } = session;
          tmpGroups[group_name.trim()]?.coding_assignments.push({
            ...assignment,
            type: 'assignment',
            completed: isCompleted(assignment, 'assignment'),
          });
        }
      });

      sessionsDetails?.pre_reads?.forEach((preRead) => {
        const { session_id } = preRead;
        const session = sessions.find((session) => session.id === session_id);
        if (session) {
          const { group_name } = session;
          tmpGroups[group_name.trim()]?.pre_reads.push({
            ...preRead,
            type: 'preRead',
            completed: isCompleted(preRead, 'preRead'),
          });
        }
      });

      setGroupToSessions(tmpGroups);
      const lastVisitedSession = JSON.parse(
        localStorage.getItem('lastVisitedSession')
      );
      if (lastVisitedSession) {
        setSelectedSessionTopic(lastVisitedSession);
      } else {
        setSelectedSessionTopic(Object.keys(tmpGroups)[0]);
      }
      setSingleSessionData(sessions[0]);
      setSelectedSessionId(sessions[0].id);
    }
    return () => {};
  }, [sessionsDetails]);

  useEffect(() => {
    if (
      Object.keys(singleSessionData)?.length > 0 &&
      currentProgram?.hasProgramStarted
    ) {
      getResources();
    }
    return () => {
      setMaterialResource([]);
    };
  }, [singleSessionData]);

  function TimelineSessionNameItem({ session }) {
    const { color, status } = getPropertiesBasedOnSchedule(
      session.start,
      session.session_type.duration,
      session.status
    );

    function getLabel() {
      if (status === 'today') {
        return `Today, ${moment(session.start).format('h:mm A')}`;
      }
      if (status === 'unscheduled') {
        return 'Unscheduled';
      }
      return moment(session.start).format('MMM DD, h:mm A');
    }

    return (
      <TimelineItem
        key={session.id}
        sx={{ minHeight: 50, cursor: 'pointer' }}
        onClick={(e) => handleSessionSelect(session)}
      >
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot
            variant='outlined'
            sx={{ alignSelf: 'center', borderStyle: 'none', margin: 0 }}
          >
            <SessionStatusIcon
              color={color}
              status={status}
            />
          </TimelineDot>
        </TimelineSeparator>
        <TimelineContent
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            color,
          }}
        >
          <Typography
            variant={selectedSessionId === session.id ? 'h6' : 'body1'}
          >
            {session.title}
          </Typography>
          <Chip
            label={getLabel()}
            variant='outlined'
            sx={{ color, fontWeight: 'bold' }}
          />
        </TimelineContent>
      </TimelineItem>
    );
  }

  function TimelineSessionGroupNameItem({ groupName, groupId }) {
    const { color, status } = getGroupProperties(
      groupToSessions[groupName]?.sessions
    );

    return (
      <TimelineItem
        key={groupId}
        sx={{ minHeight: 0 }}
      >
        <TimelineDot
          variant='outlined'
          sx={{ alignSelf: 'center', borderStyle: 'none', margin: 0 }}
        >
          <SessionStatusIcon
            color={color}
            status={status}
          />
        </TimelineDot>
        <TimelineContent sx={{ alignSelf: 'center', color }}>
          {groupName.split('_')[0]}
        </TimelineContent>
      </TimelineItem>
    );
  }

  const handleSessionSelect = (session) => {
    setSelectedSessionId(session?.id);
    setSessionChanged(true);
    setSingleSessionData(session);
  };

  function getResources() {
    const topic_ids = [singleSessionData?.id];
    axios
      .get(
        `${fetchResourcesUrl()}/get_by_topics/?topic_ids[]=${topic_ids.join(
          ','
        )}`,
        {
          headers: getHeaders(currentProgram?.programId),
        }
      )
      .then((res) => {
        setMaterialResource(res.data);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(err.response?.data?.message || err.message);
      });
  }

  if (!currentProgram?.hasProgramStarted) {
    return <ProgramNotStarted />;
  }

  return (
    <Box sx={{ margin: '10px', display: 'flex' }}>
      {!hideModules && (
        <Modules
          groupToSessions={groupToSessions}
          selectedSessionTopic={selectedSessionTopic}
          setSelectedSessionTopic={setSelectedSessionTopic}
        />
      )}

      <SessionDetails
        setHideModules={setHideModules}
        selectedSessionTopic={selectedSessionTopic}
        groupToSessions={groupToSessions}
      />
    </Box>
  );
};

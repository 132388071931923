import { Box, Stack, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { fetchLearnerProgressURL, getHeaders } from '../../../apis/urls';
import { getUserId } from '../../../configs/auth';
import ErrorNotifier from '../../ToastNotifications/ErrorNotifier';
import PieChart from './PieChart';
import LoadingScreen from '../../../pages/LoadingScreen';

const GrowthChart = () => {
  const currentProgram = useSelector((state) => state.programs.currentProgram);
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const chartRef = useRef(null);
  const [GrowthGraphData, setGrowthGraphData] = useState({});

  useEffect(() => {
    if (currentProgram) {
      fetchProgress();
    }
    return () => {};
  }, [currentProgram]);

  function fetchProgress() {
    axios
      .get(fetchLearnerProgressURL(getUserId()), {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((res) => {
        setGrowthGraphData(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(err.response?.data?.message || err?.message);
        setLoading(false);
      });
  }

  return (
    <Stack>
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      <Stack
        spacing={1}
        sx={{
          margin: '10px 20px',
        }}
      >
        <Box ref={chartRef}>
          <Typography
            variant='h6'
            sx={{ paddingTop: '10px' }}
          >
            Welcome to Growth Graph!
          </Typography>
          <Typography variant='body1'>
            Here is a visual tracker designed to reflect your dedication and
            progress throughout the learning journey with HeyCoach. This graph
            isn't just a measure of your growth; it's a roadmap to success. As
            you progress, each segment will update to reflect your advancements,
            offering a clear visual Of how close you are to achieving your
            goals.
          </Typography>
          <Typography
            sx={{
              background:
                'linear-gradient(45deg, #a1cdf9b7, #f3f3c8c5, #f5ebebd1)',
              display: 'inline',
            }}
          >
            Once all segments turn blue, it means you've hit the goal point and
            the Placements channel will unlock for you.
          </Typography>
        </Box>
        {loading ? (
          <LoadingScreen loading={loading} />
        ) : (
          <PieChart GrowthGraphData={GrowthGraphData} />
        )}
      </Stack>{' '}
    </Stack>
  );
};

export default GrowthChart;

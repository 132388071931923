import React from 'react';
import {
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Box,
  IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

export default function NewQuestionOptions({ questionData, setQuestionData }) {
  const handleAddOption = () => {
    setQuestionData((prevState) => ({
      ...prevState,
      quiz_question_options: [
        ...prevState.quiz_question_options,
        { option: '', answer: false },
      ],
    }));
  };

  const handleDeleteOption = (index) => {
    const updatedOptions = questionData.quiz_question_options.filter(
      (_, i) => i !== index
    );
    setQuestionData((prevState) => ({
      ...prevState,
      quiz_question_options: updatedOptions,
    }));
  };

  const handleOptionChange = (index, field, value) => {
    const updatedOptions = [...questionData.quiz_question_options];
    updatedOptions[index][field] = value;
    setQuestionData((prevState) => ({
      ...prevState,
      quiz_question_options: updatedOptions,
    }));
  };

  return (
    <>
      {questionData.quiz_question_options.map((opt, index) => (
        <Box
          mt={1}
          key={index}
        >
          <TextField
            label={`Option ${index + 1}`}
            value={opt.option}
            onChange={(e) =>
              handleOptionChange(index, 'option', e.target.value)
            }
            sx={{ width: '60%' }}
            required
          />
          <FormControlLabel
            sx={{ m: 1 }}
            control={
              <Checkbox
                checked={opt.answer || false}
                onChange={(e) =>
                  handleOptionChange(index, 'answer', e.target.checked)
                }
              />
            }
            label='Correct'
          />
          <IconButton
            aria-label='Delete'
            onClick={() => handleDeleteOption(index)}
          >
            <DeleteIcon color='error' />
          </IconButton>
        </Box>
      ))}
      <Button
        onClick={handleAddOption}
        color='primary'
        variant='outlined'
        sx={{ mt: 2 }}
      >
        Add Option
      </Button>
    </>
  );
}

/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
// @ts-nocheck
import React, { useEffect, useRef, useState } from 'react';
import { googleLogout } from '@react-oauth/google';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import {
  Button,
  Box,
  Avatar,
  IconButton,
  Typography,
  Grid,
} from '@material-ui/core';
// components
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  setUserLoggedOut,
  getUserImage,
  getUserName,
  getUserType,
  getUserTncStatus,
  getUserMail,
} from '../../configs/auth';
import MenuPopover from '../../components/MenuPopover';
import { unsetPrograms } from '../../components/redux-store/slices/programSlice';
import { unsetTopics } from '../../components/redux-store/slices/topicSlice';
import { unsetSessions } from '../../components/redux-store/slices/sessionSlice';
import { clearReduxPersistState } from '../../index';
import axios from 'axios';
import { getCoupons, getHeaders } from 'src/apis/urls';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Divider } from '@mui/material';
import profileSettingIcon from './account-icons/account_circle.png';
import couponsIcon from './account-icons/coupons.png';
import logoutIcon from './account-icons/logout.png';

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1, 2),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[200],
}));
const buttonStyle = { borderRadius: '0px' };

export default function AccountPopover() {
  const dispatch = useDispatch();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const userType = getUserType();
  const terms_and_conditions_agreed = getUserTncStatus();
  const [showCoupons, setShowCoupons] = useState(false);
  const currentProgram = useSelector((state) => state.programs.currentProgram);

  useEffect(() => {
    if (getUserType() === 'student') {
      return;
    }

    axios.get(getCoupons(), { headers: getHeaders() }).then((res) => {
      setShowCoupons(res?.data?.length > 0);
    });
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const logoutUser = () => {
    dispatch(unsetPrograms());
    dispatch(unsetTopics());
    dispatch(unsetSessions());
    setUserLoggedOut();
    clearReduxPersistState();
    googleLogout();
    navigate('/');
  };
  const goToAccountSetting = () => {
    if (location.pathname.includes('mentor')) {
      navigate('/mentor/user/settings');
    } else {
      navigate('/student/user/settings');
    }
  };
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  function handleCouponsNavigation() {
    const userType = getUserType();
    if (userType === 'visitor') {
      navigate('/coupons');
    } else {
      navigate(`/student/${currentProgram?.programSlug}/coupons`);
    }
  }

  return (
    <>
      <Box
        ref={anchorRef}
        onClick={handleOpen}
        style={{ cursor: 'pointer' }}
      >
        <AccountStyle>
          <Avatar
            src={getUserImage()}
            alt='photoURL'
          />
          <Box sx={{ ml: 2 }}>
            <Typography
              variant='subtitle2'
              sx={{
                fontStyle: 'unset',
                padding: 'unset',
                color: 'text.primary',
              }}
            >
              {getUserName()}
            </Typography>
            {/* {userType === 'student' &&
              terms_and_conditions_agreed === 'agreed' && (
                <Typography
                  variant='caption'
                  sx={{ color: '#66bb6a' }}
                >
                  Terms And Conditions Agreed
                </Typography>
              )} */}
          </Box>
          <ArrowDropDownIcon
            htmlColor='black'
            style={{ transform: open ? 'rotate(180deg)' : '' }}
          />
        </AccountStyle>
      </Box>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 270 }}
      >
        <Box
          sx={{
            padding: '10px 15px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography sx={{ fontSize: '18px', fontWeight: '850px' }}>
            {getUserName()}
          </Typography>
          <Typography
            variant='caption'
            sx={{ color: 'gray' }}
          >
            {getUserMail()}
          </Typography>
        </Box>
        <Divider sx={{ marginX: '5px', color: 'black' }} />
        <Box sx={{ p: 2, pt: 1.5 }}>
          {['coach'].includes(getUserType()) && (
            <Button
              sx={{ marginTop: '4px' }}
              fullWidth
              variant='contained'
              value='my-courses'
              onClick={(event) => navigate(`/student/${event.target.value}`)}
              color='primary'
              style={buttonStyle}
            >
              My Courses
            </Button>
          )}

          {location.pathname.includes('student') && (
            <Button
              fullWidth
              color='inherit'
              sx={{
                marginTop: '4px',
                display: 'flex',
                justifyContent: 'flex-start',
              }}
              onClick={goToAccountSetting}
              style={buttonStyle}
            >
              <img
                src={profileSettingIcon}
                style={{ width: '25px', flexBasis: '1', marginRight: '20px' }}
              />
              <Typography sx={{ fontSize: '14px', fontWeight: '800px' }}>
                Profile Settings
              </Typography>
            </Button>
          )}

          {showCoupons && (
            <Button
              sx={{
                marginTop: '4px',
                display: 'flex',
                justifyContent: 'flex-start',
              }}
              fullWidth
              color='inherit'
              onClick={handleCouponsNavigation}
              style={buttonStyle}
            >
              <img
                src={couponsIcon}
                style={{ width: '25px', flexBasis: '1', marginRight: '20px' }}
              />
              <Typography sx={{ fontSize: '14px', fontWeight: '800px' }}>
                My Coupons
              </Typography>
            </Button>
          )}
          <Button
            sx={{
              marginTop: '4px',
              display: 'flex',
              justifyContent: 'flex-start',
            }}
            fullWidth
            color='inherit'
            onClick={logoutUser}
            style={buttonStyle}
          >
            <img
              src={logoutIcon}
              style={{ width: '25px', flexBasis: '1', marginRight: '20px' }}
            />
            <Typography sx={{ fontSize: '14px', fontWeight: '800px' }}>
              Log out
            </Typography>
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}

import React, { useEffect, useState } from 'react';
import CircleIcon from '@mui/icons-material/Circle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box } from '@material-ui/system';
import { Typography } from '@material-ui/core';
import { useNavigate, useParams } from 'react-router-dom';

const SideNavigation = ({
  subTopics,
  selectedSubTopic,
  result,
  subTopic,
  allSubTopics,
}) => {
  const navigate = useNavigate();
  const { topic } = useParams();

  return (
    <Box
      display='flex'
      flexDirection='column'
      maxHeight='100%'
      paddingLeft={1}
    >
      {Object.entries(allSubTopics).map(([topics, problems], index) => {
        const topicLength = problems.length;
        const solvedProblemNumber =
          result?.data?.sub_topics[topics]?.solved_problems;
        return (
          <Box
            display='flex'
            mb={1}
          >
            <Box
              style={{ paddingTop: '2px' }}
              pr={1}
            >
              {solvedProblemNumber === topicLength ? (
                <CheckCircleIcon
                  style={{
                    fontSize: '15px',
                    color: 'green',
                  }}
                />
              ) : (
                <CircleIcon
                  style={{
                    fontSize: '14px',
                    color: subTopic === topics ? '#1976D2' : '#D9D9D9',
                  }}
                />
              )}
            </Box>
            <Typography
              key={index}
              variant='body1'
              style={{
                fontSize: '16px',
                color: subTopic === topics && '#1976D2',
                cursor: 'pointer',
                textTransform: 'capitalize',
              }}
              onClick={() =>
                navigate(`signature-frameworks/mindmap/${topic}/${topics}`)
              }
            >
              {topics} &nbsp;({solvedProblemNumber}/{topicLength})
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};

export default SideNavigation;

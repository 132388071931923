import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CopyIcon } from '../../utils/common';
import Interactions from './Interactions';
import DetailedTableView from '../Atoms/DetailedTableView';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const FocusScreen = ({
  user,
  handleLearnerClose,
  handleClickNext,
  fetchLearners,
}) => {
  const LeadIdCopyIcon = CopyIcon(user.learnerId);

  const basicInfoRows = [
    { field: 'Email id', value: user.email, isCopied: true },
    { field: 'Phone', value: user.mobileNumber, isCopied: true },
    { field: 'Leetcode username', value: user.leetCodeId, isCopied: true },
    { field: 'DOB', value: user.dob },
    { field: 'Profession', value: user.profession },
    { field: 'College', value: user.college },
  ];

  const attendanceRows = [
    { field: 'Attended', value: user.attended },
    { field: 'Total Scheduled', value: user.scheduled },
    { field: 'Percentage', value: user.attendance },
  ];

  const assignmentRows = [
    { field: 'Completed', value: user.assignmetCompleted },
    { field: 'Total', value: user.assignmetTotal },
    { field: 'Percentage', value: user.assignment },
  ];

  const mindmapRows = [
    { field: 'Completed', value: user.mindmapCompleted },
    { field: 'Total', value: user.mindmapTotal },
    {
      field: 'Percentage',
      value: ((user.mindmapCompleted / user.mindmapTotal) * 100).toFixed(2),
    },
  ];

  const tgpRows = [
    { field: 'Completed', value: user.tgpCompleted },
    { field: 'Total', value: user.tgpTotal },
    { field: 'Percentage', value: user.tgpPercentage },
  ];

  return (
    <>
      <Box
        sx={{
          padding: '10px 16px',
          border: '1px solid #C1C1C1',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          background: 'white',
        }}
      >
        <Box
          display='flex'
          alignItems='center'
          gap={2}
        >
          <FiberManualRecordIcon sx={{ color: 'green' }} />
          <Typography>
            Focus Mode! You're wired in, avoid all distractions!
          </Typography>
        </Box>

        <CloseIcon
          sx={{ cursor: 'pointer', fontSize: '26px' }}
          onClick={handleLearnerClose}
        />
      </Box>
      <Box
        sx={{
          padding: '0px 10px',
          border: '1px solid #C1C1C1',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          background: 'white',
        }}
      >
        <Box>
          <Box
            display='flex'
            alignItems='center'
            gap={2}
          >
            <Box
              display='flex'
              alignItems='center'
            >
              <Typography
                sx={{
                  fontSize: '36px',
                  fontWeight: 'bold',
                  marginRight: '6px',
                }}
              >
                {user.name}
              </Typography>
              <Typography
                sx={{
                  fontSize: '22px',
                  fontWeight: '400',
                  color: '#000',
                }}
              >
                ${user.learnerId}
              </Typography>
              {LeadIdCopyIcon}
            </Box>

            <Typography
              sx={{
                fontSize: '18px',
                fontWeight: '500',
                lineHeight: '22px',
              }}
            >
              {user.programName}
            </Typography>
          </Box>
        </Box>

        <Box
          display='flex'
          gap={1}
          alignItems='center'
        >
          <Typography
            sx={{
              fontSize: '18px',
              fontWeight: '700',
              color: '#FF0000',
            }}
          >
            Last Called: {user.lastCalled}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: '42px',
          background: 'white',
          height: '90%',
        }}
      >
        <Box
          sx={{
            border: '1px solid #ddd',
            width: '575px',
            overflow: 'auto',
            marginBottom: '30px',
          }}
        >
          <Accordion
            sx={{ margin: '0 !important' }}
            defaultExpanded
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1-content'
              id='panel1-header'
              sx={{
                borderTop: '1px solid #ddd',
                borderBottom: '1px solid #ddd',
                maxHeight: '45px',
                minHeight: 'unset !important',
                fontSize: '18px',
                fontWeight: '500',
              }}
            >
              Basic Details
            </AccordionSummary>
            <AccordionDetails sx={{ padding: '0' }}>
              <DetailedTableView rows={basicInfoRows} />
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{ margin: '0 !important' }}
            defaultExpanded
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1-content'
              id='panel1-header'
              sx={{
                borderTop: '1px solid #ddd',
                borderBottom: '1px solid #ddd',
                maxHeight: '45px',
                background: '#1976D21A',
                minHeight: 'unset !important',
                fontSize: '18px',
                fontWeight: '500',
              }}
            >
              Attendance
            </AccordionSummary>
            <AccordionDetails sx={{ padding: '0' }}>
              <DetailedTableView rows={attendanceRows} />
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{ margin: '0 !important' }}
            defaultExpanded
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1-content'
              id='panel1-header'
              sx={{
                borderTop: '1px solid #ddd',
                borderBottom: '1px solid #ddd',
                maxHeight: '45px',
                background: '#1976D21A',
                minHeight: 'unset !important',
                fontSize: '18px',
                fontWeight: '500',
              }}
            >
              Assignments
            </AccordionSummary>
            <AccordionDetails sx={{ padding: '0' }}>
              <DetailedTableView rows={assignmentRows} />
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{ margin: '0 !important' }}
            defaultExpanded
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1-content'
              id='panel1-header'
              sx={{
                borderTop: '1px solid #ddd',
                borderBottom: '1px solid #ddd',
                maxHeight: '45px',
                background: '#1976D21A',
                minHeight: 'unset !important',
                fontSize: '18px',
                fontWeight: '500',
              }}
            >
              Mindmap
            </AccordionSummary>
            <AccordionDetails sx={{ padding: '0' }}>
              <DetailedTableView rows={mindmapRows} />
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{ margin: '0 !important' }}
            defaultExpanded
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1-content'
              id='panel1-header'
              sx={{
                borderTop: '1px solid #ddd',
                borderBottom: '1px solid #ddd',
                maxHeight: '45px',
                background: '#1976D21A',
                minHeight: 'unset !important',
                fontSize: '18px',
                fontWeight: '500',
              }}
            >
              TGP Tests
            </AccordionSummary>
            <AccordionDetails sx={{ padding: '0' }}>
              <DetailedTableView rows={tgpRows} />
            </AccordionDetails>
          </Accordion>
        </Box>

        <Box
          paddingRight={3}
          width='100%'
        >
          <Interactions
            enrollmentId={user.enrollmentId}
            programId={user.programId}
            isFocusMode={true}
            nextLearner={handleClickNext}
          />
          <Button
            variant='contained'
            sx={{
              padding: '8px 20px',
              paddingRight: '40px',
              float: 'right',
              marginTop: '10px',
            }}
            onClick={handleClickNext}
          >
            Next Learner
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default FocusScreen;

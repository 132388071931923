// @ts-nocheck
import { Stack, Typography, useTheme } from '@mui/material';
import React from 'react';

const ScreeningInterviewPreferanceScreen = () => {
  const theme = useTheme();
  return (
    <Stack
      spacing={3}
      alignItems='center'
      justifyContent='center'
      sx={{
        marginTop: '2.5rem',
        backgroundColor: 'white',
        borderRadius: '8px',
        height: '45vh',
        width: '60%',
        padding: '1rem',
        [theme.breakpoints.down('md')]: {
          width: '90%',
          height: '60vh',
        },
        [theme.breakpoints.down('sm')]: {
          padding: '1rem',
        },
        '@media (min-height: 1024px)': {
          height: '45vh',
        },
      }}
    >
      <Typography
        variant='h2'
        component='h2'
        sx={{
          textAlign: 'center',
          fontFamily: 'Inter',
          color: '#1976d2',
        }}
      >
        Thank You!
      </Typography>

      <Typography
        variant='h4'
        component='h4'
        sx={{
          textAlign: 'center',
          textWrap: 'balance',
          fontFamily: 'Inter',
          fontWeight: 500,
        }}
      >
        Please get in touch with your counselor for further process.
      </Typography>
    </Stack>
  );
};

export default ScreeningInterviewPreferanceScreen;

// @ts-nocheck
import {
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  Box,
  Grid,
} from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import React, { useEffect, useState } from 'react';
import BeforeHeyCoach from '../../../../assets/group_195703.png';
import AfterHeyCoach from '../../../../assets/group_195704.png';
import logoImage from '../../../../assets/full-logo.png';
import { useFastrackPipeline } from 'src/layouts/EfPipelineContext/FastrackPipelineContex';

const HeycoachIcon = ({ isMobile }) => {
  return (
    <img
      style={{
        height: isMobile ? '20px' : '28px',
      }}
      src={logoImage}
      alt='heycoach-logo'
    />
  );
};

const OneToOneInterviewComparison = ({
  setFlowData,
  flowData,
  saveFlowData,
  handleReadyToEnroll,
  setShowTerminalScreen,
}) => {
  const { screenSource, setScreenSource, trackButtonClick } =
    useFastrackPipeline();
  const theme = useTheme();
  const isBelow800 = useMediaQuery('(max-width: 800px)');
  const isBelowMedium = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleDiscussWithCounsellor = () => {
    trackButtonClick({
      source: 'clearing_interview_4',
      eventName: 'discuss_with_counsellor',
    });
    saveFlowData({
      screen: 'clearing_interview_4',
      response: { is_ready_to_enroll: false },
    });
    setScreenSource('screen_2b');
  };

  return (
    <Stack
      spacing={5}
      alignItems='center'
      textAlign='center'
      width='inherit'
    >
      <Stack
        direction={!isBelowMedium ? 'row' : 'column'}
        spacing={2}
        width='100%'
        justifyContent='space-evenly'
        alignItems='center'
      >
        <Stack alignItems='center'>
          <Box
            display='flex'
            gap={2}
          >
            <Typography
              variant='body1'
              component='p'
              sx={{
                textAlign: 'center',
                fontFamily: 'Inter',
                fontWeight: 'normal',
                fontSize: {
                  xs: '1rem',
                  sm: '1.1rem',
                  md: '1.2rem',
                  lg: '1.3rem',
                },
                textWrap: 'stable',
              }}
            >
              <b>Before</b>
            </Typography>

            <HeycoachIcon isMobile={isMobile} />
          </Box>

          <Box
            sx={{
              maxWidth: isBelowMedium ? '25rem' : '30rem',
            }}
          >
            <img src={BeforeHeyCoach} />
          </Box>
        </Stack>

        <Stack alignItems='center'>
          <Box
            display='flex'
            gap={2}
          >
            <Typography
              variant='body1'
              component='p'
              sx={{
                textAlign: 'center',
                fontFamily: 'Inter',
                fontWeight: 'normal',
                fontSize: {
                  xs: '1rem',
                  sm: '1.1rem',
                  md: '1.2rem',
                  lg: '1.3rem',
                },
                textWrap: 'stable',
              }}
            >
              <b>After</b>
            </Typography>
            <HeycoachIcon isMobile={isMobile} />
          </Box>

          <Box
            sx={{
              maxWidth: isBelowMedium ? '25rem' : '30rem',
            }}
          >
            <img src={AfterHeyCoach} />
          </Box>
        </Stack>
      </Stack>

      <Typography
        variant='body1'
        component='p'
        sx={{
          textAlign: 'center',
          fontFamily: 'Inter',
          fontWeight: 'normal',
          fontSize: {
            xs: '1rem',
            sm: '1.1rem',
            md: '1.2rem',
            lg: '1.3rem',
          },
          width: {
            xs: '100%',
            sm: '100%',
            md: '100%',
            lg: '80%',
          },
          textWrap: 'stable',
          paddingBottom: '1rem',
        }}
      >
        And this was just a glimpse—the mock sessions helped him build
        confidence through real interview practice while improving his ability
        to stay calm under pressure, leading to clearer and more structured
        communication during his interviews
      </Typography>
      <Stack
        direction={isBelow800 ? 'column-reverse' : 'row'}
        paddingTop={isBelow800 ? '1.5rem' : '3rem'}
        sx={{
          '@media (max-width: 800px)': {
            alignItems: 'center',
          },
        }}
        spacing={2}
        justifyContent='space-between'
        width={{
          xs: '100%',
          sm: '100%',
          md: '80%',
          lg: '60%',
          xl: '50%',
        }}
      >
        <Button
          endIcon={<EastIcon />}
          variant='outlined'
          sx={{
            fontFamily: 'Inter',
            textTransform: 'none',
            padding: '0.6rem 1rem',
            color: '#1976d2',
            '@media (max-width: 800px)': {
              width: 'fit-content',
            },
            borderColor: '#1976d2',
            '&:hover': {
              color: '#1976d2',
              borderColor: '#1976d2',
            },
          }}
          onClick={handleDiscussWithCounsellor}
        >
          I'd like to discuss with counsellor
        </Button>

        <Button
          variant='contained'
          sx={{
            fontFamily: 'Inter',
            textTransform: 'none',
            padding: '0.6rem 1.8rem',
            '@media (max-width: 800px)': {
              padding: '0.6rem 3rem',
            },
            '@media (max-width: 400px)': {
              padding: '0.6rem 1.8rem',
            },
            background:
              'linear-gradient(to right, rgb(0, 154, 240), rgb(251, 199, 4))',
          }}
          onClick={(e) => {
            saveFlowData({
              screen: 'clearing_interview_4',
              response: { is_ready_to_enroll: true },
            });
            handleReadyToEnroll({ source: screenSource });
          }}
          endIcon={<EastIcon />}
        >
          Okay, I'm ready to enroll
        </Button>
      </Stack>
    </Stack>
  );
};

export default OneToOneInterviewComparison;

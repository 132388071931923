import React from 'react';
import { Box } from '@material-ui/system';
import { Stack, Typography, Button } from '@material-ui/core';
import { Paper, InputBase, IconButton } from '@mui/material';
import CloseIcon from '@material-ui/icons/Close';

const Search = ({
  searchTerm,
  handleSearchChange,
  findProblemTopics,
  handleReset,
  result,
}) => {
  return (
    <Box>
      <Box
        display='flex'
        justifyContent='center'
      >
        <Typography
          variant='h4'
          component='h4'
          style={{ color: '#1976D2', fontSize: '44px' }}
        >
          Mindmap
        </Typography>
      </Box>

      <Box
        display='flex'
        justifyContent='center'
        mt={2}
      >
        <Paper
          component='form'
          sx={{
            p: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            width: '55%',
            border: '1px solid #e0e0e0',
            borderRadius: 0,
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1, '&::placeholder': { fontSize: '0.5rem' } }}
            placeholder='Search by problem link (for e.g. https://leetcode.com/problems/maximum-binary-tree'
            inputProps={{
              'aria-label': 'search',
            }}
            value={searchTerm}
            onChange={handleSearchChange}
            endAdornment={
              result.length > 0 && (
                <IconButton
                  onClick={handleReset}
                  size='small'
                >
                  <CloseIcon />
                </IconButton>
              )
            }
          />
        </Paper>
        <Box ml={3}>
          <Button
            variant='contained'
            color='primary'
            sx={{
              borderRadius: 0,
              width: 140,
              height: 40,
            }}
            onClick={() => findProblemTopics()}
          >
            Search
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Search;

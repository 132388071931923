// @ts-nocheck
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { makeStyles } from '@material-ui/styles';
import ErrorNotifier from '../../../ToastNotifications/ErrorNotifier';
import { fetchAssignmentReports, getHeaders } from '../../../../apis/urls';
import LoadingScreen from '../../../../pages/LoadingScreen';

export const useStyles = makeStyles({
  sticky: {
    position: 'sticky',
    left: 0,
    background: 'white',
    zIndex: 3,
  },
});

export default function AssignmentsReport() {
  const currentProgram = useSelector((state) => state.programs.currentProgram);
  const [reportData, setReportData] = useState({});
  const [learners, setLearners] = useState({});
  const [idToAssignmentName, setIdToAssignmentName] = useState({});
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    category: 'course',
  });

  useEffect(() => {
    if (currentProgram) {
      fetchReportData();
    }
    return () => {};
  }, [filters]);

  function fetchReportData() {
    setLoading(true);

    const params = [];
    Object.keys(filters).forEach((k) => {
      params.push(`${k}=${filters[k]}`);
    });

    axios
      .get(fetchAssignmentReports(params.join('&')), {
        headers: getHeaders(currentProgram.programId),
      })
      .then((res) => {
        setIdToAssignmentName(res?.data?.id_to_assignment_name);
        setReportData(res?.data?.report_data);
        setLearners(res?.data?.learners);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(err.response?.data?.message || err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <>
      {loading && <LoadingScreen loading={loading} />}
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}

      <Stack
        direction='row'
        justifyContent='flex-end'
        mb={2}
      >
        <FormControl
          size='small'
          sx={{
            width: '10rem',
            ml: '0.3rem',
            mr: '0.3rem',
          }}
        >
          <InputLabel id='assignment-type-label'>Assignment type</InputLabel>
          <Select
            labelId='assignment-type-label'
            id='assignment-type'
            value={filters.category}
            label='Assignment type'
            name='category'
            onChange={(e) => {
              const { name, value } = e.target;
              setFilters((p) => ({ ...p, [name]: value }));
            }}
          >
            <MenuItem value='course'>Course</MenuItem>
            <MenuItem value='pre_course'>Pre course</MenuItem>
          </Select>
        </FormControl>
      </Stack>

      {Object.keys(idToAssignmentName)?.length > 0 &&
        Object.keys(learners)?.length > 0 &&
        Object.keys(reportData)?.length > 0 && (
          <ReportDisplayStructure
            assignmentIdMap={idToAssignmentName}
            reportData={reportData}
            learners={learners}
          />
        )}
      {(Object.keys(idToAssignmentName).length === 0 ||
        Object.keys(learners).length === 0 ||
        Object.keys(reportData).length === 0) && (
        <Typography
          variant='body1'
          align='center'
          sx={{ mt: 2 }}
        >
          No assignments and submissions found.
        </Typography>
      )}
    </>
  );
}

function ReportDisplayStructure({ assignmentIdMap, reportData, learners }) {
  const classes = useStyles();
  const loc = useLocation();

  return (
    <Grid container>
      <Grid
        item
        xs={12}
      >
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 650 }}
            size='small'
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <TableCell
                  className={classes.sticky}
                  sx={{ borderRight: 1, borderBottom: 1 }}
                >
                  Name
                </TableCell>
                <TableCell sx={{ borderRight: 1, borderBottom: 1 }}>
                  Performance
                </TableCell>
                {Object.keys(assignmentIdMap).map((aid, index) => (
                  <TableCell
                    align='center'
                    sx={{ borderRight: 1, borderBottom: 1 }}
                  >
                    {assignmentIdMap[aid]}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {Object.keys(learners).map((sid, index1) => (
                <TableRow key={index1}>
                  <TableCell
                    className={classes.sticky}
                    sx={{ borderRight: 1, borderBottom: 1 }}
                  >
                    {learners[sid].name}
                  </TableCell>
                  <TableCell sx={{ borderRight: 1, borderBottom: 1 }}>
                    {`${learners[sid].performance} %`}
                  </TableCell>

                  {Object.keys(assignmentIdMap).map((aid, index2) => (
                    <TableCell
                      align='center'
                      sx={{ borderRight: 1, borderBottom: 1 }}
                    >
                      <Box
                        component={Link}
                        to={`${loc.pathname}/assignments/${aid}/students/${sid}/submissions`}
                        target='_blank'
                        style={{ textDecoration: 'none', color: 'inherit' }}
                      >
                        {!reportData[aid] || !reportData[aid][sid] ? (
                          <Typography
                            variant='body2'
                            color='error'
                            fontSize='medium'
                          >
                            Missing
                          </Typography>
                        ) : (
                          <Cell
                            totalGradedSubmissions={
                              reportData[aid][sid].total_graded_submissions
                            }
                            totalSubmissions={
                              reportData[aid][sid].total_submissions
                            }
                            maxScore={reportData[aid][sid].max_score}
                          />
                        )}
                      </Box>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

function Cell({ totalGradedSubmissions, totalSubmissions, maxScore }) {
  return (
    <>
      {(() => {
        switch (true) {
          case totalGradedSubmissions === 0:
            return (
              <Typography
                variant='body2'
                fontSize='large'
                display='flex'
                justifyContent='center'
              >
                <ModeEditIcon
                  fontSize='small'
                  sx={{ mr: '0.2rem' }}
                />
                {totalSubmissions}
              </Typography>
            );
          default:
            return (
              <CellDetails
                pendingUngraded={totalSubmissions - totalGradedSubmissions}
                maxScore={maxScore}
              />
            );
        }
      })()}
    </>
  );
}

function CellDetails({ pendingUngraded, maxScore }) {
  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
    >
      <Typography
        variant='body2'
        fontSize='medium'
        color={maxScore === 100 ? 'green' : 'primary'}
      >
        {maxScore}
      </Typography>
      {pendingUngraded > 0 && (
        <Typography
          variant='body2'
          fontSize='large'
          display='flex'
          justifyContent='center'
        >
          <ModeEditIcon
            fontSize='small'
            sx={{ mr: '0.2rem' }}
          />
          {pendingUngraded}
        </Typography>
      )}
    </Box>
  );
}

import React, { useState } from 'react';
import {
  Modal,
  Box,
  Typography,
  Button,
  TextField,
  Chip,
  Stack,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { escalationURL, getHeaders } from 'src/apis/urls';
import axios from 'axios';
import Success from './Success';
import LensOutlinedIcon from '@mui/icons-material/LensOutlined';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const FirstFormModal = ({
  showFirstForm,
  setshowFirstForm,
  setShowFeedbackButton,
  setShowFeedbackState,
  currentProgram,
}) => {
  const [response, setResponse] = useState('');
  const [showAdditionalFields, setShowAdditionalFields] = useState('');
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [feedback, setFeedback] = useState('');
  const [receivedCall, setReceivedCall] = useState('');
  const [resolution, setResolution] = useState('');
  const [requestCallback, setRequestCallback] = useState(false);
  const [details, setDetails] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);

  const handleResponseChange = (value) => {
    setResponse(value);
    setShowAdditionalFields(value);
  };

  const handleAreaToggle = (area) => {
    setSelectedAreas((prev) =>
      prev.includes(area) ? prev.filter((a) => a !== area) : [...prev, area]
    );
  };

  const handleSubmit = async () => {
    let formData;

    if (response === 'Yes') {
      formData = {
        previous_concerns_addressed: response,
        areas: selectedAreas,
        feedback: feedback,
      };
    } else if (response === 'No') {
      formData = {
        previous_concerns_addressed: response,
        received_call: receivedCall,
        resolutions: resolution,
        unresolved_details: details,
        request_callback: requestCallback,
      };
    } else {
      console.error('Invalid response');
      return;
    }

    const body = {
      escalation: {
        form_data: JSON.stringify(formData),
        form_type: 1,
      },
    };
    try {
      const response = await axios.post(escalationURL(), JSON.stringify(body), {
        headers: getHeaders(currentProgram?.programId),
      });
      setshowFirstForm(false);
      setShowFeedbackButton(false);
      setShowSuccess(true);
    } catch (error) {
      console.error('-------------', error);
    }
  };

  const handleSkip = () => {
    setshowFirstForm(false);
    setShowFeedbackButton(true);
    setShowFeedbackState('FirstForm');
  };

  const handleSuccessClose = () => {
    setShowSuccess(false);
  };

  return (
    <>
      <Modal open={showFirstForm}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '502px',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography
            variant='h6'
            component='h2'
          >
            It seems you've had ongoing concerns.
          </Typography>
          <Typography sx={{ mt: 2 }}>
            Have your previous concerns been addressed?
          </Typography>
          <Stack
            direction='row'
            spacing={1}
            sx={{ mt: 2 }}
          >
            {['Yes', 'No'].map((option) => (
              <Chip
                icon={<LensOutlinedIcon style={{ fontSize: '16px' }} />}
                key={option}
                label={option}
                onClick={() => handleResponseChange(option)}
                color={response === option ? 'primary' : 'default'}
                variant={response === option ? 'filled' : 'outlined'}
                sx={{
                  fontSize: '13px',
                  width: '65px',
                  justifyContent: 'flex-start',
                }}
              />
            ))}
          </Stack>

          {showAdditionalFields === 'Yes' && (
            <>
              <Typography
                variant='h6'
                component='h2'
                sx={{ mt: 3 }}
              >
                Your satisfaction is important to us.
              </Typography>
              <Typography sx={{ mt: 1 }}>
                We're glad to hear that your concerns have been addressed. If
                you have any more feedback or need further assistance, feel free
                to reach out to us anytime.
              </Typography>
              <Typography
                variant='h6'
                component='h2'
                sx={{ mt: 2 }}
              >
                Submit a new concern
              </Typography>
              <Typography sx={{ mt: 1 }}>
                Select the areas where you're experiencing problems:
              </Typography>
              <Box
                direction='row'
                spacing={1}
                mt={2}
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: '8px',
                  maxWidth: '100%',
                }}
              >
                {[
                  'Learning portal',
                  'Course content',
                  'Support',
                  'Coach',
                  'Other issue',
                ].map((area) => (
                  <Chip
                    key={area}
                    label={area}
                    onClick={() => handleAreaToggle(area)}
                    color={selectedAreas.includes(area) ? 'primary' : 'default'}
                    variant={
                      selectedAreas.includes(area) ? 'filled' : 'outlined'
                    }
                    sx={{ mb: 1 }}
                  />
                ))}
              </Box>
              <Typography sx={{ mt: 2 }}>Tell us what went wrong*</Typography>
              <TextField
                fullWidth
                multiline
                rows={4}
                placeholder='Type here...'
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                sx={{ mt: 1 }}
              />
            </>
          )}

          {showAdditionalFields === 'No' && (
            <>
              <Typography sx={{ mt: 2 }}>Did you receive a call?</Typography>
              <Stack
                direction='row'
                spacing={1}
                sx={{ mt: 1 }}
              >
                {['Yes', 'No'].map((option) => (
                  <Chip
                    icon={<LensOutlinedIcon style={{ fontSize: '16px' }} />}
                    key={option}
                    label={option}
                    onClick={() => setReceivedCall(option)}
                    color={receivedCall === option ? 'primary' : 'default'}
                    variant={receivedCall === option ? 'filled' : 'outlined'}
                    sx={{
                      fontSize: '13px',
                      width: '65px',
                      justifyContent: 'flex-start',
                    }}
                  />
                ))}
              </Stack>

              <Typography sx={{ mt: 2 }}>
                What resolutions were given for your concerns?
              </Typography>
              <TextField
                fullWidth
                multiline
                rows={4}
                placeholder='Type here...'
                value={resolution}
                onChange={(e) => setResolution(e.target.value)}
                sx={{ mt: 1 }}
              />

              <Typography sx={{ mt: 2 }}>
                We're sorry to hear that your concerns haven't been fully
                addressed. Please provide more details about the problems that
                haven't been resolved.
              </Typography>
              <TextField
                fullWidth
                multiline
                rows={4}
                placeholder='Type here...'
                value={details}
                onChange={(e) => setDetails(e.target.value)}
                sx={{ mt: 1 }}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={requestCallback}
                    onChange={(e) => setRequestCallback(e.target.checked)}
                  />
                }
                label='Request a callback from support team'
                sx={{ mt: 2 }}
              />
            </>
          )}
          <Box
            sx={{
              mt: 3,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Button
              variant='text'
              color='primary'
              onClick={handleSkip}
            >
              I would like to skip
            </Button>
            <Button
              variant='contained'
              color='primary'
              disabled={
                !(
                  (selectedAreas.length > 0 && feedback.trim() !== '') ||
                  (resolution.trim() !== '' && details.trim() !== '')
                )
              }
              onClick={handleSubmit}
              sx={{
                backgroundColor: !(
                  (selectedAreas.length > 0 && feedback.trim() !== '') ||
                  (resolution.trim() !== '' && details.trim() !== '')
                )
                  ? '#fff'
                  : '#1976d2',
                color: !(
                  (selectedAreas.length > 0 && feedback.trim() !== '') ||
                  (resolution.trim() !== '' && details.trim() !== '')
                )
                  ? '#000'
                  : '#fff',
                '&:hover': {
                  backgroundColor: !(
                    (selectedAreas.length > 0 && feedback.trim() !== '') ||
                    (resolution.trim() !== '' && details.trim() !== '')
                  )
                    ? '#fff'
                    : '#1565c0',
                },
              }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Modal>
      <Success
        open={showSuccess}
        onClose={handleSuccessClose}
      />
    </>
  );
};

export default FirstFormModal;

import React from 'react';
import { Box, Typography, Stack } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';

const ProgramNotStarted = () => {

  return (
    <Box 
      display="flex"
      justifyContent="center"
      marginTop="10rem"
    >
      <Stack display="flex" alignItems="center" justifyContent="center">
        <LockIcon fontSize='large'/>
        <br />
        <Typography variant="h5" component="h2" textAlign="center">
          Program has not yet started. 
          <br />
        </Typography>
      </Stack>
    </Box>
  );
};

export default ProgramNotStarted;

import React, { useEffect, useState } from 'react';
import { Box, Typography, Modal } from '@mui/material';
import IndividualLearnerView from '../Modules/IndividualLearnerView';
import { transformLearnerData } from '../LearnerModuleUtil';
import { focusModeLearners, getHeaders } from 'src/apis/urls';
import axios from 'axios';
import FocusScreen from '../Modules/FocusScreen';
import LoadingScreen from 'src/pages/LoadingScreen';
import { useLocation } from 'react-router-dom';
import { getUserType } from 'src/configs/auth';

function FocusModeSwitch() {
  const [isToggled, setIsToggled] = useState(false);
  const [isFocusEnabled, setIsFocusEnabled] = useState(false);
  const [currentLearnerIndex, setCurrentLearnerIndex] = useState(0);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const userRole = getUserType();
  const location = useLocation();
  const shouldRenderFocusModeSwitch =
    userRole === 'program_manager' &&
    location.pathname.includes('learner_search');

  useEffect(() => {
    fetchLearners();
    // Set up the interval to fetch data every 15 minutes
    // const intervalId = setInterval(fetchLearners, 10 * 1000);
    // return () => clearInterval(intervalId);
  }, []);

  const fetchLearners = () => {
    setLoading(true);
    axios
      .get(focusModeLearners(), {
        headers: getHeaders(),
      })
      .then((res) => {
        const transformedData = transformLearnerData(res.data);
        setData(transformedData);
        // if (transformedData.length > 0) {
        //   setIsFocusEnabled(true);
        // }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClickNext = () => {
    setCurrentLearnerIndex((currentLearnerIndex + 1) % data.length);
  };

  const handleToggle = () => {
    setIsFocusEnabled(!isFocusEnabled);
    setIsToggled(!isToggled);
  };

  const handleFocusModeClose = () => {
    setIsFocusEnabled(false);
    setIsToggled(false);
  };

  const styles = {
    switch: {
      width: '52px',
      height: '24px',
      position: 'relative',
      display: 'inline-block',
      verticalAlign: 'middle',
    },
    slider: {
      position: 'absolute',
      cursor: 'pointer',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      backgroundColor: '#ccc',
      transition: '.4s',
      borderRadius: '34px',
      height: '23.5px',
      width: '50px',
    },
    sliderBefore: {
      position: 'absolute',
      content: '""',
      height: '16px',
      width: '16px',
      left: '4px',
      bottom: '4px',
      backgroundColor: 'white',
      transition: '.4s',
      borderRadius: '50%',
    },
    checkbox: {
      display: 'none',
    },
    sliderBeforeChecked: {
      transform: 'translateX(26px)',
    },
  };

  return (
    <Box>
      {loading && <LoadingScreen />}
      {isFocusEnabled && data?.length > 0 ? (
        <Modal
          open={isFocusEnabled}
          closeAfterTransition
        >
          <FocusScreen
            user={data[currentLearnerIndex]}
            handleLearnerClose={handleFocusModeClose}
            handleClickNext={handleClickNext}
            fetchLearners={fetchLearners}
          />
        </Modal>
      ) : (
        <Box
          display={shouldRenderFocusModeSwitch ? 'flex' : 'none'}
          gap='4px'
          alignItems='center'
        >
          <label style={styles.switch}>
            <input
              type='checkbox'
              style={styles.checkbox}
              checked={isToggled}
              onChange={handleToggle}
            />
            <span style={styles.slider}>
              <span
                style={{
                  ...styles.sliderBefore,
                  ...(isToggled ? styles.sliderBeforeChecked : {}),
                }}
              ></span>
            </span>
          </label>
          <Typography
            sx={{
              fontSize: '19px',
              fontWeight: '500',
              color: 'black',
            }}
          >
            Focus Mode
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default FocusModeSwitch;

import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
    TextField,
    FormControl,
    Select,
    Box,
    MenuItem,
    InputLabel,
    Stack,
    Button,
    Chip,
    Input
} from '@material-ui/core';
import axios from 'axios';
import tw from 'twin.macro';
import { fetchResourcesUrl, getHeaders } from '../../../apis/urls';

const H5 = tw.h5`text-sm text-black ml-5`;

function EditResourceForm({ editResources, setOpen }) {
    const topics = useSelector(state => state.topics.topics);
    const [title, setTitle] = useState(editResources.title);
    const [selectTopics, setSelectTopics] = useState('');
    const [type, setType] = useState(editResources.resource_type);
    const [multipleTopics, setMultipleTopics] = useState([]);
    const fileRef = useRef();
    const [link, setLink] = useState(editResources.link && editResources.link);

    const handleChange = (e) => {
        setSelectTopics(e.target.value);
        setMultipleTopics((prev) => [...prev, e.target.value]);
    };

    const handleDelete = (id) => {
        setMultipleTopics((prev) => prev.filter((val) => val !== id));
    };

    const submitHandler = async (e) => {
        e.preventDefault();
        const resource = {
            title,
            topic_ids: [...multipleTopics],
            resource_type: type,
            resource_link: link
        };

        await axios.put(fetchResourcesUrl(), resource, { headers: getHeaders() });
        setOpen(false);
    };
    return (
        <>
            <form onSubmit={submitHandler}>
                <TextField
                    fullWidth
                    id="title"
                    label="Title"
                    variant="outlined"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    required
                />
                <Stack
                    direction="row"
                    marginTop="1rem"
                    border={multipleTopics.length > 0 ? '1px solid lightgray' : 'none'}
                    borderRadius="0.5rem"
                    flexWrap="wrap"
                >
                    {multipleTopics &&
                        multipleTopics.map((id) => {
                            return (
                                <Chip
                                    sx={{ margin: '3px' }}
                                    id={id}
                                    label={topics[id].name}
                                    onDelete={() => handleDelete(id)}
                                />
                            );
                        })}
                </Stack>
                <FormControl fullWidth>
                    <Select
                        sx={{ marginTop: '1rem' }}
                        id="topic-select"
                        onChange={handleChange}
                        label="Topics"
                        value={selectTopics}
                        required
                    >
                        {topics &&
                            topics.map((topic, i) => {
                                return (
                                    <MenuItem key={topic.id} value={i}>
                                        {topic.name}
                                    </MenuItem>
                                );
                            })}
                    </Select>
                </FormControl>

                <Box display="flex" alignItems="center" marginTop="1rem">
                    <FormControl sx={{ width: 150, marginRight: '1rem' }}>
                        <Select
                            value={type}
                            onChange={(e) => setType(e.target.value)}
                            id="type"
                            label="Type"
                            placeholder="Type"
                        >
                            <MenuItem value="file">File</MenuItem>
                            <MenuItem value="link">Link</MenuItem>
                        </Select>
                    </FormControl>
                    {type === 'file' ? (
                        <>
                            <input placeholder="File" type="file" ref={fileRef} />
                        </>
                    ) : (
                        <>
                            <TextField
                                type="text"
                                name="link"
                                placeholder="Add your Link"
                                value={link}
                                onChange={(e) => {
                                    setLink(e.target.value);
                                }}
                            />
                        </>
                    )}
                </Box>

                <Button
                    fullWidth
                    sx={{ marginTop: '1rem' }}
                    type="submit"
                    variant="contained"
                    color="secondary"
                >
                    Submit
                </Button>
            </form>
        </>
    );
}

export default EditResourceForm;

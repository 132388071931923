import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

export default function SendMessageConfirmation({
  openConfirmationDialog,
  setOpenConfirmationDialog,
  sendMessage,
}) {
  return (
    <Dialog
      open={openConfirmationDialog}
      onClose={() => setOpenConfirmationDialog(false)}
    >
      <DialogTitle>Send Message</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to send the message?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenConfirmationDialog(false)}>Cancel</Button>
        <Button
          onClick={sendMessage}
          autoFocus
        >
          Yes, I am sure
        </Button>
      </DialogActions>
    </Dialog>
  );
}

// @ts-nocheck
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Typography,
  TextField,
  Stack,
  Button,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { LoadingButton } from '@material-ui/lab';
import axios from 'axios';
import moment from 'moment';
import { getHeaders, postProgramFeedback } from '../../apis/urls';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../ToastNotifications/SuccessNotifier';
import { getRecommendationScaleQuestion } from './feedbackQuestions';

const initialState = {
  recommendation_scale: '',
  how_can_we_improve: '',
};

export default function NPSFormPCModal({
  onClose,
  lastSubmittedFormResponse,
  setLastSubmittedFormResponse,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const program = useSelector((state) => state.programs?.currentProgram);
  const today = moment();
  const lastSubmissionDate = moment(lastSubmittedFormResponse);
  const differenceInDays = today.diff(lastSubmissionDate, 'days');

  const [formValues, setFormValues] = useState(initialState);

  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSkip = () => {
    onClose();
    const today = moment();
    localStorage.setItem('formSkipDate', today.format('YYYY-MM-DD'));
  };

  const shouldAllowSubmit = () => {
    return (
      formValues.how_can_we_improve !== '' &&
      formValues.recommendation_scale !== ''
    );
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    const body = {
      response: JSON.stringify(formValues),
    };
    axios
      .post(postProgramFeedback(), body, {
        headers: getHeaders(program?.programId),
      })
      .then((res) => {
        setIsSuccess(true);
        setSuccessMessage('Feedback Submitted Successfully');
        setLastSubmittedFormResponse({
          last_submitted_at: res.data?.submitted_at,
          show_form: false,
          show_referal: res.data?.show_referal,
          show_frustration_modal: res.data?.show_frustration_modal,
          show_first_form: res.data?.show_first_form,
        });
        setFormValues(initialState);
      })
      .catch((e) => {
        console.log(e);
        setHasError(true);
        setErrorMessage(
          e.response?.data?.message ||
            'An error occurred while submitting the Feedback'
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      {isSuccess && (
        <SuccessNotifier
          message={successMessage}
          setIsSuccess={setIsSuccess}
        />
      )}

      <Dialog open>
        <DialogTitle>
          <Typography
            variant='h5'
            color='black'
          >
            We need your valuable feedback
          </Typography>
        </DialogTitle>

        <DialogContent>
          <Stack spacing={4}>
            {getRecommendationScaleQuestion(
              formValues.recommendation_scale,
              handleChange,
              isMobile
            )}

            <Stack spacing={0.5}>
              <Typography>How can we improve?</Typography>
              <TextField
                value={formValues.how_can_we_improve}
                onChange={handleChange}
                multiline
                name='how_can_we_improve'
                rows={3}
                fullWidth
                margin='normal'
                placeholder='Type here...'
                required
              />
            </Stack>

            <Stack
              direction='row-reverse'
              width='inherit'
              spacing={1}
            >
              <LoadingButton
                variant='contained'
                onClick={handleSubmit}
                loading={loading}
                size='small'
                sx={{
                  boxShadow: 'none',
                  borderRadius: '0.3rem',
                  background: '#1976d2',
                  ':hover': {
                    background: '#1976d2',
                  },
                }}
                disableElevation
                disabled={!shouldAllowSubmit()}
              >
                Submit
              </LoadingButton>
              {/* {differenceInDays < 15 && (
                <Button
                  variant='outlined'
                  size='small'
                  onClick={handleSkip}
                  sx={{
                    boxShadow: 'none',
                    borderRadius: '0.3rem',
                    color: '#1976d2',
                    ':hover': {
                      color: '#1976d2',
                    },
                  }}
                  disableElevation
                >
                  Remind me later
                </Button>
              )} */}
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
}

// @ts-nocheck
import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useEfPipeline } from '../../layouts/EfPipelineContext/EfPipelineContext';
import EfConversationFlow from './EfConversationFlow';
import { BASE_URL, getHeaders } from '../../apis/urls';

const EfConversationFlowLayout = () => {
  const {
    loading,
    setLoading,
    formId,
    setFormId,
    setMessage,
    setShowConversationFlow,
  } = useEfPipeline();

  const [prevResponse, setPrevResponse] = useState({});

  useEffect(() => {
    getSavedEfConversationFlow();
    return () => {};
  }, []);

  const getSavedEfConversationFlow = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `${BASE_URL}/api/eligibility-forms/${formId}/conversation-flow`,
        {
          headers: getHeaders(),
        }
      );

      const { message, response } = res.data;
      if (message) {
        setFormId(null);
        setMessage(message);
        setShowConversationFlow(false);
        return;
      }

      try {
        setPrevResponse(JSON.parse(response ?? '{}'));
      } catch (err) {
        console.log('Failed to parse previous response');
      }
    } catch (err) {
      console.log(err);
      // This hides 5xx errors.
      setFormId(null);
      setMessage(
        err.response?.data?.message ?? 'This form is no longer active'
      );
      setShowConversationFlow(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box
        alignSelf='center'
        sx={{
          width: '100%',
          height: '100%',
          borderRadius: '8px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {!loading && <EfConversationFlow prevResponse={prevResponse} />}
      </Box>
    </>
  );
};

export default EfConversationFlowLayout;

import React, { useEffect, useState } from 'react';
import {
  Button,
  Typography,
  Modal,
  Box,
  FormGroup,
  Checkbox,
  CircularProgress,
} from '@mui/material';
import axios from 'axios';
import {
  getHeaders,
  getChatLabels,
  updateChatLabels,
} from '../../../apis/urls';
import AddNewLabel from './AddNewLabel';

export default function UpdateChatLabelModal({
  open,
  onClose,
  chatId,
  setChatList,
  handleClosePopover,
  setHasError,
  setErrorMessage,
  setIsSuccess,
  setSuccessMessage,
}) {
  const [formState, setFormState] = useState([]);
  const [selectedLabels, setSelectedLabels] = useState({});
  const [fetchLabelsLoader, setFetchLabelsLoader] = useState(false);

  useEffect(() => {
    fetchLabels();
  }, []);

  useEffect(() => {
    const initialSelectedLabels = {};
    formState.forEach((label) => {
      initialSelectedLabels[label.id] = label.selected;
    });
    setSelectedLabels(initialSelectedLabels);
  }, [formState]);

  const fetchLabels = () => {
    setFetchLabelsLoader(true);
    axios
      .get(getChatLabels(chatId), {
        headers: getHeaders(),
      })
      .then((res) => {
        setFormState(res?.data);
      })
      .catch((e) => {
        console.log(e);
        setHasError(true);
        setErrorMessage(
          e.response?.data?.message ||
            'An error occurred while fetching the labels'
        );
      })
      .finally(() => {
        setFetchLabelsLoader(false);
      });
  };

  const handleCheckboxChange = (event, labelId) => {
    setSelectedLabels((prevSelectedLabels) => ({
      ...prevSelectedLabels,
      [labelId]: event.target.checked,
    }));
  };

  const handleSave = () => {
    setFetchLabelsLoader(true);
    const selectedLabelIds = Object.entries(selectedLabels)
      .filter(([_, isSelected]) => isSelected)
      .map(([labelId]) => parseInt(labelId, 10));

    const body = {
      label_ids: selectedLabelIds,
    };
    axios
      .post(updateChatLabels(chatId), body, {
        headers: getHeaders(),
      })
      .then((res) => {
        const updatedChatLabels = res?.data?.map((ele) => ele.label);
        setChatList((prevChatList) => {
          return prevChatList.map((chat) => {
            if (chat.id === chatId) {
              return {
                ...chat,
                labels: updatedChatLabels,
              };
            }
            return chat;
          });
        });
        setFetchLabelsLoader(false);
        onClose();
        handleClosePopover();
        setIsSuccess(true);
        setSuccessMessage('Chat Labels updated successfully');
      })
      .catch((e) => {
        console.log(e);
        setHasError(true);
        setErrorMessage(
          e.response?.data?.message ||
            'An error occurred while updating the labels'
        );
        setFetchLabelsLoader(false);
      });
  };

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: 700,
            backgroundColor: '#f0f0f0',
            borderRadius: '20px',
            p: 4,
            overflowY: 'auto',
          }}
        >
          <Box textAlign='center'>
            <Typography variant='h5'>Chat Labels</Typography>
          </Box>

          <AddNewLabel
            setFormState={setFormState}
            setHasError={setHasError}
            setErrorMessage={setErrorMessage}
            setIsSuccess={setIsSuccess}
            setSuccessMessage={setSuccessMessage}
            setFetchLabelsLoader={setFetchLabelsLoader}
          />

          <Box sx={{ height: '16rem', overflowY: 'auto', marginTop: '20px' }}>
            {fetchLabelsLoader && (
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                }}
              >
                <CircularProgress />
              </Box>
            )}
            <FormGroup>
              {formState.map((label) => (
                <div
                  key={label.id}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <Checkbox
                    checked={selectedLabels[label.id] ?? false}
                    onChange={(e) => handleCheckboxChange(e, label.id)}
                  />
                  <span
                    style={{
                      backgroundColor: label.bgcolor,
                      color: label.fontcolor,
                      padding: '5px 10px',
                      borderRadius: '4px',
                      marginRight: '10px',
                    }}
                  >
                    {label.name}
                  </span>
                </div>
              ))}
            </FormGroup>
          </Box>

          <Box textAlign='center'>
            <Button
              onClick={handleSave}
              variant='contained'
              color='primary'
              sx={{ mt: 2 }}
            >
              Update Labels
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

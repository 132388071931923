// @ts-nocheck
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import axios from 'axios';
import { fetchCodingTestJoinNowURL, getHeaders } from '../../../apis/urls';

const headers = [
  'Name',
  'Start Time',
  'End Time',
  'Duration (minutes)',
  'Action',
];

function NewSessionTestTable({ allTests, selectedTest, setSelectedTest }) {
  const currentProgram = useSelector((state) => state.programs.currentProgram);

  const joinTest = (test, index) => {
    if (test.hasJoined) {
      setSelectedTest(test);
      return;
    }

    axios
      .post(
        fetchCodingTestJoinNowURL(test.testId),
        { id: test.testId },
        {
          headers: getHeaders(currentProgram?.programId),
        }
      )
      .then((res) => {
        setSelectedTest({
          ...test,
          hasJoined: true,
          startedAt: moment(),
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getTestButtonProperties = (test) => {
    const currentTime = moment();
    const options = {
      text: 'Expired',
      disabled: true,
    };

    if (test.startTime.isAfter(currentTime)) {
      options.text = 'Scheduled';
    } else if (currentTime.isBetween(test.startTime, test.endTime)) {
      if (test.hasSubmitted) {
        options.text = 'Submitted';
      } else if (!test.hasJoined) {
        options.text = 'Start now';
        options.disabled = false;
      } else {
        const attemptEndsAt = moment(test.startedAt);
        attemptEndsAt.add(test.duration, 'minutes');

        if (currentTime.isAfter(attemptEndsAt)) {
          options.text = 'Submitted';
        } else {
          options.disabled = false;
          options.text = 'Resume';
        }
      }
    } else if (test.hasJoined) {
      /**
       * Case 1: Late started and test isn't over.
       * Case 2: Late started and test is over.
       * Case 3: Not started late and test is over.
       * Case 4: Test submitted.
       */
      const attemptEndsAt = moment(test.startedAt);
      attemptEndsAt.add(test.duration, 'minutes');

      const isStartedEarly = test.startedAt.isBefore(test.endTime);
      if (currentTime.isSameOrAfter(attemptEndsAt) || test.hasSubmitted) {
        options.text = isStartedEarly ? 'Submitted' : 'Late Submitted';
      } else {
        options.text = 'Resume';
        options.disabled = false;
      }
    } else {
      options.text = 'Start late attempt';
      options.disabled = false;
    }
    return options;
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ width: '100%' }}>
        <TableHead>
          <TableRow>
            {headers.map((header, index) => {
              return <TableCell key={index}>{header}</TableCell>;
            })}
          </TableRow>
        </TableHead>

        <TableBody>
          {allTests.map((test, index) => {
            const options = getTestButtonProperties(test);
            return (
              <TableRow
                key={index}
                sx={{
                  '&:last-child td, &:last-child th': {
                    border: 0,
                  },
                }}
              >
                <TableCell
                  component='th'
                  scope='row'
                >
                  {test.name}
                </TableCell>

                <TableCell>
                  {test.startTime.format('MMM Do YYYY, h:mm A')}
                </TableCell>

                <TableCell>
                  {test.endTime.format('MMM Do YYYY, h:mm A')}
                </TableCell>

                <TableCell>{test.duration}</TableCell>

                <TableCell>
                  <Button
                    variant='contained'
                    color='success'
                    onClick={(e) => joinTest(test, index)}
                    {...(options.disabled && {
                      disabled: true,
                    })}
                  >
                    {options.text}
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default NewSessionTestTable;

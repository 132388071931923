// @ts-nocheck
import {
  Box,
  Slide,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { LoadingButton } from '@material-ui/lab';
import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import axios from 'axios';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';
import conversationFlowQuestions from './ConversationFlowQuestions';
import ConversationFlowFinalScreen from './ConversationFlowFinalScreen';
import ConversationFlowWelcomeScreen from './ConversationFlowWelcomeScreen';
import { BASE_URL, getHeaders } from '../../apis/urls';
import { useEfPipeline } from '../../layouts/EfPipelineContext/EfPipelineContext';
import ConversationFlowExitPopup from '../ExitIntentPopups/ConversationFlowExitPopup';

const Stepper = styled(MobileStepper)({
  alignSelf: 'center',
  position: 'absolute',
  top: 0,
  padding: 0,
  width: '100vw',
  '& .MuiMobileStepper-root': {
    padding: 0,
  },
  '& .MuiMobileStepper-progress': {
    padding: 0,
    height: '6px',
    width: '100%',
  },
});

const EfConversationFlow = ({ prevResponse }) => {
  const {
    formId,
    setFormId,
    setShowConversationFlow,
    setMessage,
    setShowScreeningTest,
  } = useEfPipeline();

  const theme = useTheme();
  const isBelowTab = useMediaQuery(theme.breakpoints.down('md'));
  const containerRef = useRef(null);
  const [currentQuestionNumber, setCurrentQuestionNumber] = useState(1);
  const [response, setResponse] = useState({ ...prevResponse });
  const [autoSaving, setAutoSaving] = useState(false);
  const [hasFlowEnded, setHasFlowEnded] = useState(false);
  const [exclusiveToken, setExclusiveToken] = useState(null);
  const [shouldAllowNext, setShouldAllowNext] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    let maxQuestionNumber = 0;
    conversationFlowQuestions.forEach((q, index) => {
      const { key } = q;
      if (key in response) {
        maxQuestionNumber = index + 1;
      }
    });

    setCurrentQuestionNumber(maxQuestionNumber + 1);
    return () => {};
  }, []);

  const goToNextQuestion = async () => {
    const len = conversationFlowQuestions.length;
    const actionType = currentQuestionNumber === len ? 'submit' : 'auto_save';
    await submitResponse(actionType);
  };

  const getNextButtonText = () => {
    const len = conversationFlowQuestions.length;
    return currentQuestionNumber === len ? 'Submit' : 'Next';
  };

  const submitResponse = async (action_type) => {
    try {
      setAutoSaving(true);
      const res = await axios.post(
        `${BASE_URL}/api/eligibility-forms/${formId}/conversation-flow`,
        {
          response: JSON.stringify(response),
          action_type,
        },
        {
          headers: getHeaders(),
        }
      );

      const { message, show_screening_test = false, token = null } = res.data;

      setAutoSaving(false);
      setCurrentQuestionNumber((prev) => prev + 1);
      setShouldAllowNext(false);

      if (message && message.toLowerCase() !== 'ok') {
        const congratulatoryMessage =
          'Congratulations! Your response has been received.';

        if (message.toLowerCase() === congratulatoryMessage.toLowerCase()) {
          setShowScreeningTest(show_screening_test);
          setExclusiveToken(token);
        } else {
          setFormId(null);
          setMessage(message);
        }
        setHasFlowEnded(token !== null); // on receiving token, flow ends.
        setShowConversationFlow(token !== null); // on receiving token, we don't want to hide conversation flow because we want to show the token.
        return;
      }
    } catch (err) {
      console.log(err);
      setHasError(true);
      setErrorMessage(err.response?.data?.message ?? 'Something went wrong.');
    }
  };

  const trackButtonClick = async ({ source, eventName }) => {
    const body = {
      source,
      event_name: eventName,
      type: 'conversation_flow', // also known by career goals questions
    };

    try {
      axios.post(
        `${BASE_URL}/api/eligibility-forms/${formId}/activities`,
        body,
        {
          headers: getHeaders(),
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {!hasFlowEnded && currentQuestionNumber > 0 && (
        <ConversationFlowExitPopup
          currentQuestionNumber={currentQuestionNumber}
          trackButtonClick={trackButtonClick}
        />
      )}

      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      <Stack
        spacing={2}
        width={isBelowTab ? '100vw' : '60vw'}
        pt='5rem'
        ref={containerRef}
        sx={{
          position: 'relative',
          paddingTop: '5rem',
          [theme.breakpoints.down('sm')]: {
            paddingTop: '2rem',
          },
        }}
      >
        {/* {currentQuestionNumber === 0 && (
          <ConversationFlowWelcomeScreen
            setCurrentQuestionNumber={setCurrentQuestionNumber}
          />
        )} */}

        {currentQuestionNumber !== 0 && !hasFlowEnded && (
          <Stepper
            variant='progress'
            steps={8}
            position='static'
            activeStep={currentQuestionNumber}
            sx={{
              bgcolor: 'transparent',
            }}
          />
        )}

        {conversationFlowQuestions.map((question, index) => {
          if (currentQuestionNumber !== index + 1) {
            return null;
          }
          return (
            <Slide
              direction='left'
              in
              mountOnEnter
              unmountOnExit
              easing={{
                enter: theme.transitions.easing.easeInOut,
              }}
              timeout={{
                enter: 450,
              }}
              container={containerRef.current}
            >
              <Box
                sx={{
                  width: 'inherit',
                  padding: '1rem',
                }}
              >
                <Box
                  sx={{
                    float: 'left',
                    padding: '1rem',
                    paddingTop: 0,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    variant='h4'
                    component='h4'
                    sx={{
                      fontFamily: 'Inter',
                      [theme.breakpoints.down('sm')]: {
                        fontSize: '1rem',
                      },
                    }}
                  >
                    {index + 1}
                  </Typography>
                  &nbsp;&nbsp;{' '}
                  <ArrowForwardIcon
                    {...{
                      [theme.breakpoints.down('sm')]: {
                        fontSize: 'small',
                      },
                    }}
                  />
                </Box>

                <Stack spacing={3}>
                  {question.getComponent({
                    id: question.key,
                    response,
                    setResponse,
                    setShouldAllowNext,
                  })}

                  <LoadingButton
                    autoSaving={autoSaving}
                    variant='contained'
                    onClick={goToNextQuestion}
                    endIcon={<ArrowRightAltIcon />}
                    sx={{
                      width: '7rem',
                      fontFamily: 'Inter',
                      boxShadow: 'none',
                    }}
                    disableElevation
                    disabled={!shouldAllowNext}
                  >
                    {getNextButtonText()}
                  </LoadingButton>
                </Stack>
              </Box>
            </Slide>
          );
        })}

        {hasFlowEnded && <ConversationFlowFinalScreen token={exclusiveToken} />}
      </Stack>
    </>
  );
};

export default EfConversationFlow;

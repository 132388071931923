// @ts-nocheck
import {
  Box,
  Button,
  Chip,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import TimerImg from '../../../assets/timer.png';
import React, { useEffect } from 'react';
import { useFastrackPipeline } from 'src/layouts/EfPipelineContext/FastrackPipelineContex';

const NotYetReadyCard = () => {
  const { trackButtonClick, screenSource, setScreenSource } =
    useFastrackPipeline();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleProceed = () => {
    trackButtonClick({ source: screenSource, eventName: 'proceed' });
    setScreenSource('screen_2');
  };

  return (
    <Stack
      justifyContent='space-between'
      alignItems='center'
      sx={{
        width: {
          xs: '19rem',
          sm: '20rem',
          md: '21rem',
          lg: '23rem',
          xl: '28%',
        },
        height: '24rem',
        border: '3px solid #e3f2fd',
        borderRadius: '0.8rem',
        padding: '1rem',
        display: 'flex',
        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 1px 5px 0px',
      }}
    >
      <img
        style={{
          width: '90px',
        }}
        src={TimerImg}
        alt='timer-logo'
      />

      <Typography
        variant='h4'
        component='h4'
        sx={{
          fontFamily: 'Inter',
          fontWeight: 'bolder',
          paddingTop: 0,
          lineHeight: 'normal',
          textAlign: 'center',
        }}
      >
        I'm not ready yet.
      </Typography>

      <Typography
        variant='body1'
        component='p'
        sx={{
          fontFamily: 'Inter',
          fontWeight: 500,
          fontSize: '1.1rem',
          textAlign: 'center',
        }}
      >
        It's okay! Let's figure out your concern together.
      </Typography>

      <Button
        variant='contained'
        endIcon={<ArrowRightAltIcon fontSize='large' />}
        sx={{
          background: '#1976d2',
          color: 'white',
          fontFamily: 'Inter',
          '&:hover': {
            background: '#1976d2',
          },
          padding: '0.7rem 0.5rem',
          width: '80%',
          alignSelf: 'center',
          boxShadow: 'none',
        }}
        onClick={handleProceed}
      >
        Proceed
      </Button>
    </Stack>
  );
};

export default NotYetReadyCard;

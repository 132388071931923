import { Button, Dialog, DialogActions, DialogContent, Stack, TextField, Typography, } from '@material-ui/core'
import Checkbox from '@mui/material/Checkbox';
import React, { useState } from 'react';

function PaymentModal({ openPaymentModal, coach, setOpenPaymentModal, addPayment }) {
  const [transactionId, setTransactionId] = useState("");
  const [sendEmailChecked, setSendEmailChecked] = useState(false);

  return (
    <Dialog open={openPaymentModal} onClose={() => setOpenPaymentModal(false)} fullWidth maxWidth="sm">
      <DialogContent>
        <Stack spacing={1}>
          <TextField
            fullWidth
            id="transaction-id"
            name="transaction_id"
            // InputLabelProps={{ shrink: true }}
            label="Transaction id"
            value={transactionId}
            onChange={(e) => setTransactionId(e.target.value)}
            required
          />
          <Stack direction="row" spacing={1} alignItems="center">
            <Checkbox
              checked={sendEmailChecked}
              onChange={(e) => setSendEmailChecked(e.target.checked)}
            />
            <Typography>
              Notify coach
            </Typography>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions sx={{justifyContent: "center"}}>
        <Button variant='contained' onClick={(e) => addPayment(transactionId, sendEmailChecked, coach)}
          disabled={!transactionId || transactionId === ""}
        >
          Add coach payment 
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PaymentModal
import React from 'react';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
} from '@material-ui/core';
import LoadingButton from '@mui/lab/LoadingButton';
import { FiberManualRecord } from '@mui/icons-material';
import EastIcon from '@mui/icons-material/East';
import ListRenderComponent from './ListRenderComponent';
import { TricksWithBitsConst } from '../../utils/techniqueSagaConstant';
import ParagraphBox from './ParagraphBox';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

// components
import CustomButton from '../../practice/MindMaps/Atoms/CustomButton';
import Representation from '../../../images/Representation.png';
import BitwiseOperators from '../../../images/BitwiseOperators.png';

import { bitwisOperators } from '../../utils/techniqueSagaConstant';

const Introduction = ({
  handlePostCompletedTopic,
  hasMarkedCompleted,
  loading,
  topic_id,
  handleNext,
}) => {
  return (
    <Box
      display='flex'
      flexDirection='column'
      gap='20px'
      paddingY={2}
      maxWidth='calc(100% - 300px)'
    >
      <Box>
        <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
          TECHNIQUE 1
        </Typography>

        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='center'
        >
          <Typography
            sx={{
              fontSize: '48px',
              fontWeight: 600,
              lineHeight: '58px',
              letterSpacing: '0.01em',
              textAlign: 'left',
            }}
          >
            Bit Manipulation
          </Typography>
          {hasMarkedCompleted(topic_id) && (
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '4px',
                color: 'green',
                fontWeight: '500',
                border: '2px solid green',
                padding: '4px 10px',
                borderRadius: '20px',
                cursor: 'default',
              }}
            >
              Completed
            </Typography>
          )}
        </Box>
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        gap='24px'
      >
        <Typography sx={{ fontSize: '22px', fontWeight: 400 }}>
          Introduction
        </Typography>
        <ParagraphBox>
          <Typography sx={{ fontSize: '20px', fontWeight: 400 }}>
            Bit Manipulation
          </Typography>
          <Typography>
            Bit manipulation involves algorithmically manipulating bits or
            smaller units of data within a word. It is used in low-level tasks
            such as device control, error detection, compression, encryption,
            and optimization. Bitwise operations like AND, OR, XOR, NOT, and bit
            shifts are fundamental in this process. While modern programming
            languages often work with higher-level abstractions, bit
            manipulation is crucial for specific tasks that benefit from
            parallel processing. It can lead to significant speed-ups but may
            result in more complex code.
          </Typography>
        </ParagraphBox>
        <ParagraphBox>
          <Typography sx={{ fontSize: '20px', fontWeight: 400 }}>
            Why Bit Manipulation?
          </Typography>
          <Typography>
            Working with bytes or data types is common, but sometimes
            programmers need to dive deeper into bits. Bit operations play a key
            role in tasks like data compression and exclusive-or encryption.
            These operations allow for efficient encoding, decoding, and
            compression of files by working at the bit level. Bitwise operations
            are faster, closer to the system, and can optimize programs
            effectively, providing a balance between performance and complexity.
          </Typography>
        </ParagraphBox>
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        gap='20px'
      >
        <Typography sx={{ fontSize: '22px', fontWeight: 400 }}>
          Representation
        </Typography>
        <img
          src={Representation}
          alt=''
          style={{ height: '150px', width: '140px' }}
        />
      </Box>

      <ParagraphBox>
        <Typography sx={{ fontSize: '22px', fontWeight: 400 }}>
          Bitwise Operators
        </Typography>
        <Box
          display='flex'
          flexDirection='column'
          gap='10px'
        >
          <List>
            {bitwisOperators.map((operator, index) => (
              <ListItem key={index}>
                <ListItemIcon>
                  <FiberManualRecord fontSize='8px' />
                </ListItemIcon>
                <ListItemText primary={operator} />
              </ListItem>
            ))}
          </List>
        </Box>
        <img
          src={BitwiseOperators}
          alt=''
          style={{ height: '150px', width: '230px' }}
        />
      </ParagraphBox>

      <Typography
        sx={{
          fontSize: '22px',
          fontWeight: '600',
        }}
      >
        Tricks with Bits
      </Typography>
      <Box
        display='flex'
        flexDirection='column'
        gap='22px'
      >
        {TricksWithBitsConst.map((data, index) => (
          <Box key={index}>
            <Typography>{data.title}</Typography>

            <ListRenderComponent data={data.pointer} />
          </Box>
        ))}
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        alignSelf='end'
        justifyContent='space-between'
        gap='12px'
      >
        {!hasMarkedCompleted(topic_id) && (
          <LoadingButton
            variant='contained'
            onClick={() => handlePostCompletedTopic(topic_id)}
            loading={loading}
            loadingPosition='center'
            children='Mark as completed'
            style={{
              width: '170px',
              // backgroundColor: 'transparent',
              borderRadius: '8px',
              border: '1px solid rgba(64, 96, 245, 0.5)',
            }}
          />
        )}

        <Box
          display='flex'
          alignItems='center'
          gap='8px'
        >
          <Button
            sx={{ gap: '4px' }}
            onClick={handleNext}
          >
            Next <EastIcon />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Introduction;

// @ts-nocheck
import React from 'react';
import { Slider, Stack, useTheme, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import QuestionDescription from './QuestionDescription';
import Zoho from '../../../assets/Zoho.webp';
import Freshworks from '../../../assets/freshworks.webp';
import Mindtree from '../../../assets/mindtree.webp';
import Mphasis from '../../../assets/mphasis.webp';
import Adobe from '../../../assets/adobe.webp';
import Flipkart from '../../../assets/flipkart.webp';
import Oracle from '../../../assets/oracle.webp';
import Intuit from '../../../assets/intuit.webp';
import Amazon from '../../../assets/amazon.webp';
import Microsoft from '../../../assets/microsoft.webp';
import VMware from '../../../assets/vmware.webp';
import LinkedIn from '../../../assets/linkedin.webp';
import Salesforce from '../../../assets/salesforce_1.webp';
import Uber from '../../../assets/uber.webp';
import Atlassian from '../../../assets/atlassian.webp';
import Netflix from '../../../assets/netflix.webp';
import Google from '../../../assets/google.webp';
import Meta from '../../../assets/meta.webp';

const PrettoSlider = styled(Slider)({
  color: '#52af77',
  height: 8,
  '& .MuiSlider-markLabel': {
    fontSize: 23,
    '@media (max-width: 600px)': {
      fontSize: 18,
    },
  },
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&::before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#52af77',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&::before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});

const sliderMarks = [
  {
    value: 10,
    label: '10 L',
  },
  {
    value: 100,
    label: '1 Cr',
  },
];

const groups = {
  group_1: [
    {
      component: Zoho,
      style: {
        width: 'auto',
        height: 'clamp(20px, 6vw, 38px)',
      },
    },
    {
      component: Freshworks,
      style: {
        width: 'auto',
        height: 'clamp(20px, 6vw, 38px)',
      },
    },
    {
      component: Mindtree,
      style: {
        width: 'auto',
        height: 'clamp(20px, 6vw, 38px)',
      },
    },
    {
      component: Mphasis,
      style: {
        width: 'auto',
        height: 'clamp(20px, 6vw, 38px)',
      },
    },
  ],
  group_2: [
    {
      component: Adobe,
      style: {
        width: 'auto',
        height: 'clamp(20px, 35px, 38px)',
        objectFit: 'cover',
      },
    },
    {
      component: Flipkart,
      style: {
        width: 'auto',
        height: 'clamp(20px, 36px, 38px)',
        objectFit: 'cover',
      },
    },
    {
      component: Oracle,
      style: {
        width: 'auto',
        height: 'clamp(20px, 26px, 38px)',
        alignSelf: 'end',
      },
    },
    {
      component: Intuit,
      style: {
        width: 'auto',
        height: 'clamp(20px, 35px, 38px)',
        objectFit: 'cover',
      },
    },
  ],
  group_3: [
    {
      component: Amazon,
      style: {
        width: 'auto',
        height: 'clamp(20px, 6vw, 38px)',
      },
    },
    {
      component: Microsoft,
      style: {
        width: 'auto',
        height: 'clamp(20px, 6vw, 38px)',
      },
    },
    {
      component: VMware,
      style: {
        width: 'auto',
        height: 'clamp(20px, 30px, 38px)',
      },
    },
    {
      component: Salesforce,
      style: {
        width: 'clamp(40px, 100px, 120px)',
        height: 'auto',
        objectFit: 'cover',
      },
    },
  ],
  group_4: [
    {
      component: LinkedIn,
      style: {
        width: 'auto',
        height: 'clamp(20px, 6vw, 38px)',
      },
    },
    {
      component: Salesforce,
      style: {
        width: 'clamp(40px, 100px, 120px)',
        height: 'auto',
        objectFit: 'cover',
      },
    },
    {
      component: Uber,
      style: {
        width: 'auto',
        height: 'clamp(20px, 6vw, 38px)',
      },
    },
    {
      component: Atlassian,
      style: {
        width: 'auto',
        height: 'clamp(20px, 30px, 38px)',
      },
    },
  ],
  group_5: [
    {
      component: Netflix,
      style: {
        width: 'auto',
        height: 'clamp(20px, 30px, 38px)',
      },
    },
    {
      component: Google,
      style: {
        width: 'auto',
        height: 'clamp(30px, 38px, 40px)',
        objectFit: 'cover',
      },
    },
    {
      component: Meta,
      style: {
        width: 'auto',
        height: 'clamp(20px, 30px, 38px)',
      },
    },
  ],
};

const DesiredSalaryRange = ({
  id,
  response,
  setResponse,
  setShouldAllowNext,
}) => {
  const theme = useTheme();
  const isBelowTab = useMediaQuery(theme.breakpoints.down('md'));

  const handleChange = (e, value) => {
    setResponse((prev) => ({ ...prev, [id]: value }));
    setShouldAllowNext(true);
  };

  const getGroup = () => {
    const val = response[id];
    if (!val || val <= 20) {
      return 'group_1';
    }
    if (val <= 40) {
      return 'group_2';
    }
    if (val <= 60) {
      return 'group_3';
    }
    if (val <= 80) {
      return 'group_4';
    }
    return 'group_5';
  };

  const group = getGroup();

  return (
    <Stack
      spacing={6}
      mb={4}
    >
      <QuestionDescription
        title="Great choice! Knowing where you aim to go is the first step. Now, let's talk numbers to shape your journey further."
        subTitle="What's your desired salary range?"
      />

      <PrettoSlider
        valueLabelDisplay='auto'
        marks={sliderMarks}
        min={10}
        max={100}
        onChangeCommitted={handleChange}
      />

      <QuestionDescription title='Based on your chosen salary range, here are the companies you might consider targeting.' />
      <Stack
        direction='row'
        flexWrap='wrap'
        gap={isBelowTab ? 5 : 10}
        mt={3}
        sx={{
          alignItems: 'center',
        }}
      >
        {groups[group].map((ig) => {
          return (
            <img
              src={ig.component}
              alt='company-logo'
              style={{
                ...ig.style,
              }}
              loading='lazy'
            />
          );
        })}
      </Stack>
    </Stack>
  );
};

export default DesiredSalaryRange;

import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Modal, Select, Stack, TextField } from '@material-ui/core';
import { Box } from '@material-ui/system';
import Radio from '@mui/material/Radio';
import axios from 'axios';
import moment from 'moment';
import React, { useState } from 'react';
import { getHeaders, postTaskURL } from '../../../../apis/urls';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function NewTaskModal({ learners, openNewTaskModal, setOpenNewTaskModal, setHasError, setErrorMessage, setIsSuccess, setSuccessMessage, programId, tasks, setTasks, coaches }) {
  const [modalData, setModalData] = useState({ 
    title: "",
    assignee_id: "",
    deadline: null
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    const body = {...modalData};
    body.deadline = moment(body.deadline).utc();

    axios.post(postTaskURL(), body, {
      headers: getHeaders(programId)
    })
    .then((res) => {
      setTasks([...tasks, res.data]);
      setIsSuccess(true);
      setSuccessMessage("Task successfully created.");
      setOpenNewTaskModal(false);
    })
    .catch((err) => {
      console.log(err);
      setHasError(true);
      setErrorMessage(err?.response?.data?.message || err.message);
      setOpenNewTaskModal(false);
    })
  }

  return (
    <Modal open={openNewTaskModal} onClose={() => setOpenNewTaskModal(false)} style={{ overflow: "scroll" }}>
      <form onSubmit={handleSubmit}>
        <Box sx={style}>
          <Stack spacing={3}>
            <TextField 
              fullWidth
              id="title"
              label="title"
              name="title"
              variant="outlined"
              value={modalData.title}
              onChange={(e) => setModalData({...modalData, title: e.target.value})}
              required
            />
            <EnrolledUsersDropdown 
              sx={{ width: '100%', height: 40, mr: 1 }}
              modalData={modalData}
              setModalData={setModalData}
              learners={learners}
              coaches={coaches}
            />
            <TextField 
              fullWidth
              id="deadline"
              type="datetime-local"
              name="deadline"
              InputLabelProps={{ shrink: true }}
              label="deadline"
              value={modalData.deadline}
              onChange={(e) => setModalData({...modalData, deadline: e.target.value})}
              required
            />
            <Button variant='contained' type="submit">
              Add task
            </Button>
          </Stack>
        </Box>
      </form>
    </Modal>
  );
}

function EnrolledUsersDropdown({ modalData, setModalData, sx, learners, coaches }) {
  const [userSelection, setUserSelection] = useState("learner");

  return (
    <>
      <FormGroup row>
        <FormControlLabel 
          control={ <Radio checked={userSelection === "learner"} onChange={(e) => setUserSelection("learner")} size="small"/>} 
          label="Assign to a learner" 
        />
        <FormControlLabel 
          control={ <Radio checked={userSelection === "coach"} onChange={(e) => setUserSelection("coach")} size="small"/> } 
          label="Assign to a coach" 
        />
      </FormGroup>
      <FormControl sx={sx}>
        <InputLabel id="user">Select Assignee</InputLabel>
        <Select
          required
          labelId="user"
          id="user"
          value={modalData.assignee_id}
          label="Select user"
          onChange={(e) => setModalData({...modalData, assignee_id: e.target.value})}
        >
          <MenuItem value=""><em>None</em></MenuItem>
          {
            (userSelection === "learner" ? learners : coaches)?.map((user, index) => (
              <MenuItem value={user.userId}>{user.name}{` (${user.email})`}</MenuItem>
            ))
          }
        </Select>
      </FormControl>
    </>
  );
}
import React from 'react';
import { Box, Typography } from '@material-ui/core';

const topicTitle = ({ children }) => {
  return (
    <Box>
      <Typography
        sx={{
          fontSize: '28px',
          fontWeight: 600,
          lineHeight: '44px',
          letterSpacing: '0.01em',
          textAlign: 'left',
        }}
      >
        {children}
      </Typography>
    </Box>
  );
};

export default topicTitle;

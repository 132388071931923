import { useEffect, useState } from 'react';
import { styled } from '@material-ui/core/styles';
import {
  Stack,
  TextField,
  Typography,
  Button,
  FormControl,
  Box,
} from '@material-ui/core';
import { Icon } from '@iconify/react';
import CloseOutlined from '@iconify/icons-ant-design/close-outlined';
import { LoadingButton } from '@material-ui/lab';
import axios from 'axios';
import { getUserId } from '../../configs/auth';
import { getHeaders, deleteEducation } from '../../apis/urls';
import moment from 'moment';
// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  maxHeight: 750,
  margin: 'auto',
  marginTop: 50,
  borderRadius: 15,
  backgroundColor: 'white',
  justifyContent: 'center',
  padding: 32,
}));

const EditEducation = (props) => {
  const [school, setSchool] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [fieldOfStudy, setFieldOfStudy] = useState('');
  const [errors, setErrors] = useState({
    school: false,
    fieldOfStudy: false,
    startDate: false,
    endDate: false,
  });

  useEffect(() => {
    props.education.forEach((res) => {
      if (res.id === props.id) {
        setSchool(res.school);
        setFieldOfStudy(res.field_of_study);
        setStartDate(res.start_date);
        setEndDate(res.end_date);
      }
    });
  }, []);

  function handleSubmit() {
    let hasError = false;

    if (!school.trim()) {
      setErrors((prevErrors) => ({ ...prevErrors, school: true }));
      hasError = true;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, school: false }));
    }

    if (!fieldOfStudy.trim()) {
      setErrors((prevErrors) => ({ ...prevErrors, fieldOfStudy: true }));
      hasError = true;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, fieldOfStudy: false }));
    }

    if (!startDate.trim()) {
      setErrors((prevErrors) => ({ ...prevErrors, startDate: true }));
      hasError = true;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, startDate: false }));
    }

    if (!endDate.trim()) {
      setErrors((prevErrors) => ({ ...prevErrors, endDate: true }));
      hasError = true;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, endDate: false }));
    }
    if (!hasError) {
      const body = {
        user_id: getUserId(),
        school,
        field_of_study: fieldOfStudy,
        start_date: startDate,
        end_date: endDate,
      };
      axios
        .put(deleteEducation(props.id), body, {
          headers: getHeaders(),
        })
        .then((res) => {
          props.setEditEducationModel(false);
          props.setSuccess(true);
          props.getEducationdata();
        })
        .catch((err) => {
          console.log(err);
          props.setEditEducationModel(false);
        });
    }
  }

  return (
    <div>
      <ContentStyle width={600}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            width: '100%',
            mb: 4,
          }}
        >
          <Typography variant='h4'>Edit Education</Typography>
          <Button onClick={() => props.setEditEducationModel(false)}>
            <Icon
              icon={CloseOutlined}
              width='18'
              height='18'
            />
          </Button>
        </Box>
        <form
          noValidate
          autoComplete='off'
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <Stack spacing={2}>
            <Stack spacing={2}>
              <Box>
                <TextField
                  fullWidth
                  value={school}
                  error={errors.school}
                  helperText={errors.school && 'School is required'}
                  label='School'
                  placeholder='Name of your school or institute'
                  onChange={(e) => setSchool(e.target.value)}
                  required
                />
              </Box>
              <Box>
                <TextField
                  fullWidth
                  label='Field of study'
                  placeholder='E.g. Technology'
                  value={fieldOfStudy}
                  error={errors.fieldOfStudy}
                  helperText={
                    errors.fieldOfStudy && 'Field of study is required'
                  }
                  onChange={(e) => setFieldOfStudy(e.target.value)}
                  required
                />
              </Box>

              <Box>
                <Typography
                  variant='caption'
                  gutterBottom
                  sx={{
                    fontSize: '15px',
                    marginBottom: '0px',
                    display: 'block',
                  }}
                >
                  Start date <span style={{ color: 'red' }}>*</span>
                </Typography>
                <FormControl fullWidth>
                  <TextField
                    type='date'
                    id='start_date'
                    placeholder='Start date'
                    InputProps={{
                      inputProps: {
                        max: moment().format('YYYY-MM-DD'),
                      },
                    }}
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    required
                    error={errors.startDate}
                    helperText={errors.startDate && 'Start date is required'}
                  />
                </FormControl>
              </Box>
              <Box>
                <Typography
                  variant='caption'
                  gutterBottom
                  sx={{
                    fontSize: '15px',
                    marginBottom: '0px',
                    display: 'block',
                  }}
                >
                  End date <span style={{ color: 'red' }}>*</span>
                </Typography>
                <FormControl fullWidth>
                  <TextField
                    type='date'
                    value={endDate}
                    placeholder='End date'
                    InputProps={{
                      inputProps: {
                        max: moment().format('YYYY-MM-DD'),
                      },
                    }}
                    onChange={(e) => setEndDate(e.target.value)}
                    required
                    error={errors.endDate}
                    helperText={errors.endDate && 'End date is required'}
                  />
                </FormControl>
              </Box>
            </Stack>
            <LoadingButton
              fullWidth
              size='large'
              type='submit'
              variant='contained'
              color='secondary'
            >
              Edit Education
            </LoadingButton>
          </Stack>
        </form>
      </ContentStyle>
    </div>
  );
};
export default EditEducation;

import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import './Style.css';
import {
  Box,
  Button,
  Chip,
  Divider,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'axios';
import {
  getHeaders,
  updateTicketInteraction,
  updateTicketStatus,
} from 'src/apis/urls';
import { useDispatch } from 'react-redux';
import {
  updateInteraction,
  updateStatus,
} from 'src/components/redux-store/slices/ticketSlice';
import { formatDate, getStatusColor } from '../TicketingSystemUtil';

const TicketDetailView = ({ ticket, handleClose }) => {
  const dispatch = useDispatch();
  // Define the keys you want to display in the Typography components
  // Define a state for the comment
  const [comment, setComment] = useState('');
  const [isCommentSubmitted, setIsCommentSubmitted] = useState(
    ticket?.ticket_interactions !== ''
  );
  const [status, setStatus] = useState(ticket?.status);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [statusUpdating, setStatusUpdating] = useState(false);
  const [emptySubmitAttempt, setEmptySubmitAttempt] = useState(false);
  const [interactions, setInteractions] = useState(
    ticket?.ticket_interactions || []
  );

  // Function to handle comment changes
  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  // Function to handle comment submission
  const handleCommentSubmit = async () => {
    if (!comment.trim()) {
      setEmptySubmitAttempt(true);
      return;
    }
    setEmptySubmitAttempt(false);
    setIsSubmitting(true);
    // TODO: Make a request to submit the comment here
    const body = { interaction: comment };
    const res = await axios.patch(updateTicketInteraction(ticket.id), body, {
      headers: getHeaders(),
    });

    if (comment) {
      setIsCommentSubmitted(true);
      // Send interaction with the request
      setComment('');
    }

    setInteractions([...interactions, res.data]);
    dispatch(
      updateInteraction({ ticket_id: ticket.id, interaction: res.data })
    );
    setIsSubmitting(false);
  };

  // Function to handle ticket closing
  const handleTicketClose = async () => {
    // Make a request to close the ticket here
    setStatusUpdating(true);
    const body = {
      status: status === 'open' ? 'closed' : 'open',
    };
    try {
      if (isCommentSubmitted) {
        const res = await axios.patch(updateTicketStatus(ticket.id), body, {
          headers: getHeaders(),
        });
        setStatus(res.data?.status);
        dispatch(
          updateStatus({
            ticket_id: ticket.id,
            status: res.data?.status,
          })
        );
      }
    } catch (error) {
      console.error('Error updating ticket status:', error);
    }
    setStatusUpdating(false);
  };

  if (!ticket) return <></>;

  return (
    <>
      <Box className='ticket-ui'>
        <Box>
          <header>
            <Typography
              sx={{ flexGrow: 1, fontSize: '20px' }}
              fontWeight={600}
            >
              {ticket.learner.id_name}
              <span>&nbsp; ${ticket.learner.id}</span>
            </Typography>
            &nbsp;
            <Box sx={{ flexGrow: 8 }}>
              <Chip
                label={status}
                sx={{
                  color: 'white',
                  backgroundColor: getStatusColor(status),
                  float: 'left',
                  paddingX: '10px',
                }}
              />
            </Box>
            <Button
              disabled={!isCommentSubmitted && !isSubmitting}
              onClick={handleTicketClose}
              variant='contained'
              color='success'
              sx={{
                borderRadius: '3px',
                fontWeight: 100,
              }}
            >
              {statusUpdating ? (
                'Updating...'
              ) : (
                <>{status === 'open' ? 'Close' : 'Open'} the ticket</>
              )}
            </Button>
            <CloseIcon
              sx={{ cursor: 'pointer', fontSize: '30px', flexGrow: 1 }}
              onClick={handleClose}
            />
          </header>
          <Divider />
          <section
            className='ticket-details'
            style={{ marginTop: '10px', gap: '10px' }}
          >
            {ticket.category === 'detractor' && (
              <Box
                sx={{
                  backgroundColor: '#FFE2C0',
                  border: '1px solid',
                  borderRadius: '8px',
                  borderColor: '#FF0000',
                  padding: '10px',
                  marginBottom: '10px',
                }}
              >
                <Typography style={{ fontSize: '14px' }}>
                  <span style={{ fontWeight: 'bold', color: '#FF0000' }}>
                    Detractor Ticket
                  </span>
                </Typography>
                <Typography style={{ fontSize: '14px', color: 'black' }}>
                  This ticket is from detractor and hence requires immediate
                  attention.
                </Typography>
              </Box>
            )}

            <Typography style={{ fontSize: '14px' }}>
              <span style={{ fontWeight: 'bold' }}>Category:&nbsp;</span>
              {getCategory(ticket?.category)}
            </Typography>
            <Typography style={{ fontSize: '14px' }}>
              <span style={{ fontWeight: 'bold' }}>Created At:&nbsp;</span>
              {formatDate(ticket?.created_at)}
            </Typography>
            <Typography style={{ fontSize: '14px' }}>
              <span style={{ fontWeight: 'bold' }}>Program Name:&nbsp;</span>
              {ticket?.program?.name}
            </Typography>
            <Typography style={{ fontSize: '14px' }}>
              <span style={{ fontWeight: 'bold' }}>Mobile Number:&nbsp;</span>
              {ticket?.learner?.mobile_number}
            </Typography>
            <Typography style={{ fontSize: '14px' }}>
              <span style={{ fontWeight: 'bold' }}>Email:&nbsp;</span>
              {ticket?.learner?.email}
            </Typography>
          </section>

          <Box
            style={{
              height: ticket.category === 'detractor' ? '300px' : '390px',
              overflow: 'auto',
              marginTop: '10px',
            }}
          >
            <Box style={{ marginTop: '10px' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  margin: '0px 10px',
                }}
              >
                <Typography style={{ fontSize: '14px', fontWeight: 600 }}>
                  {ticket.learner.id_name}
                </Typography>
                <Typography style={{ fontSize: '14px' }}>
                  {formatDate(ticket.created_at)}
                </Typography>
              </Box>
              <Box
                sx={{
                  backgroundColor: '#e8f1fb',
                  borderRadius: '10px',
                  padding: '10px',
                }}
              >
                <Typography style={{ fontSize: '14px' }}>
                  {ticket.query}
                </Typography>
              </Box>
            </Box>

            {/* Interactions */}
            <Box>
              {interactions?.map((interaction) => {
                return (
                  <>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        margin: '0px 10px',
                        marginTop: '10px',
                      }}
                    >
                      <Typography style={{ fontSize: '14px', fontWeight: 600 }}>
                        {interaction?.name}
                      </Typography>
                      <Typography style={{ fontSize: '14px' }}>
                        {formatDate(interaction.created_at)}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        backgroundColor: '#e8f1fb',
                        borderRadius: '10px',
                        padding: '10px',
                      }}
                    >
                      <Typography style={{ fontSize: '14px' }}>
                        {interaction.message}
                      </Typography>
                    </Box>
                  </>
                );
              })}
            </Box>
          </Box>
        </Box>
        <Box style={{ marginTop: '10px', position: 'relative' }}>
          <Box className='comment-section'>
            <TextField
              fullWidth
              multiline
              rows={2}
              variant='outlined'
              placeholder='Enter your text here...'
              value={comment}
              onChange={handleCommentChange}
              error={emptySubmitAttempt}
              helperText={emptySubmitAttempt ? 'Comment cannot be empty' : ''}
            />
          </Box>

          <Box
            sx={{
              marginTop: '5px',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              onClick={handleCommentSubmit}
              variant='contained'
              sx={{
                backgroundColor: '#007bff',
                marginRight: '0px',
                borderRadius: '3px',
                fontWeight: 100,
              }}
            >
              {isSubmitting ? 'Submitting...' : 'Submit interaction'}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

function getCategory(category) {
  switch (category) {
    case 'request_callback':
      return 'Request Callback';
    case 'doubt_solving_assistance':
      return 'Doubt Solving Assistance';
    case 'detractor':
      return 'Detractor';
    default:
      return 'Others';
  }
}

export default TicketDetailView;

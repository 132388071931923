export const mindmapTopics = [
  {
    name: 'queue',
    displayName: 'Queues',
    categoryId: 3,
    description:
      'A queue is a First-In-First-Out (FIFO) data structure that allows operations at both ends, enabling sequential processing of elements.',
    color: '#ba68c8',
    initialStatus: 'locked',
  },
  {
    name: 'stack',
    displayName: 'Stacks',
    categoryId: 2,
    description:
      'A stack is a Last-In-First-Out (LIFO) data structure that allows operations only at the top of the stack.',
    color: '#f06292',
    initialStatus: 'locked',
  },
  {
    name: 'linked list',
    displayName: 'Linked Lists',
    categoryId: 1,
    description:
      'A linked list is a linear data structure where elements are connected via pointers, allowing dynamic memory allocation and efficient element insertion/deletion.',
    color: '#e57373',
    initialStatus: 'locked',
  },
  {
    name: 'divide and conquer',
    displayName: 'Divide and Conquer',
    categoryId: 12,
    description:
      'Divide and conquer is a problem-solving paradigm that involves breaking down a problem into smaller subproblems, solving them independently, and combining the results to solve the original problem.',
    color: '#dce775',
    initialStatus: 'locked',
  },
  {
    name: 'searching and sorting',
    displayName: 'Searching and Sorting',
    categoryId: 11,
    description:
      'Searching and sorting algorithms are methods for finding specific elements or arranging elements in a specific order within a data structure, respectively.',
    color: '#aed581',
    initialStatus: 'locked',
  },
  {
    name: 'math',
    displayName: 'Math',
    categoryId: 16,
    description:
      'Mathematics plays a fundamental role in many algorithms and data structures, providing the foundation for various computations and operations.',
    color: '#ff8a65',
    initialStatus: 'unlocked',
  },
  {
    name: 'string',
    displayName: 'Strings',
    categoryId: 5,
    description:
      'A string is an immutable sequence of characters, often used for representing textual data and manipulating text in data structure and algorithm applications',
    color: '#7986cb',
    initialStatus: 'unlocked',
  },
  {
    name: 'array',
    displayName: 'Arrays',
    categoryId: 4,
    description:
      'An array is a contiguous block of memory that stores a fixed-size collection of elements of the same type',
    color: '#9575cd',
    initialStatus: 'unlocked',
  },
  {
    name: 'range query',
    displayName: 'Range Query',
    categoryId: 18,
    description:
      'A range query refers to an operation that retrieves or performs computations on a range of elements within a data structure, based on specified criteria or conditions.',
    color: '#e0e0e0',
    initialStatus: 'locked',
  },
  {
    name: 'dynamic programming',
    displayName: 'Dynammic Programming',
    categoryId: 19,
    description:
      'Dynamic programming is a method for solving complex problems by breaking them down into overlapping subproblems, solving each subproblem only once, and storing the results for future use to optimise performance.',
    color: '#90a4ae',
    initialStatus: 'locked',
  },
  {
    name: 'backtracking',
    displayName: 'Backtracking',
    categoryId: 14,
    description:
      'Backtracking is a technique for finding solutions by exploring all possible candidates incrementally and backtracking when a solution is found to be infeasible.',
    color: '#ffd54f',
    initialStatus: 'locked',
  },
  {
    name: 'greedy',
    displayName: 'Greedy',
    categoryId: 13,
    description:
      'Greedy is an algorithmic paradigm where the best locally optimal choice is made at each step, aiming to find an optimal solution overall.',
    color: '#fff176',
    initialStatus: 'locked',
  },
  {
    name: 'trie',
    displayName: 'Tries',
    categoryId: 10,
    description:
      'A trie is a tree-like data structure used for efficient retrieval of keys in a set or associative array, with each node representing a prefix or a complete key.',
    color: '#81c784',
    initialStatus: 'locked',
  },
  {
    name: 'graphs',
    displayName: 'Graphs',
    categoryId: 9,
    description:
      'A graph is a non-linear data structure consisting of nodes (vertices) and edges that represent connections or relationships between the nodes.',
    color: '#4db6ac',
    initialStatus: 'locked',
  },
  {
    name: 'heap',
    displayName: 'Heaps',
    categoryId: 8,
    description:
      'A heap is a complete binary tree-based data structure where each parent node is either greater than or equal to (in a max heap) or less than or equal to (in a min heap) its child nodes.',
    color: '#4dd0e1',
    initialStatus: 'locked',
  },
  {
    name: 'trees',
    displayName: 'Trees',
    categoryId: 6,
    description:
      'A tree is a hierarchical data structure composed of nodes that are connected by edges, with a single root node and branching structures, commonly used for organising and representing hierarchical relationships in data.',
    color: '#64b5f6',
    initialStatus: 'locked',
  },
  {
    name: 'recursion',
    displayName: 'Recursion',
    categoryId: 17,
    description:
      'Recursion is a programming technique where a function calls itself during its execution, allowing iterative problem-solving and handling repetitive or nested structures.',
    color: '#a1887f',
    initialStatus: 'locked',
  },
  {
    name: 'bit manipulation',
    displayName: 'Bit Manipulation',
    categoryId: 15,
    description:
      'Bit manipulation involves performing operations at the bit-level of binary representations, offering efficient handling and manipulation of individual bits within data.',
    color: '#ffb74d',
    initialStatus: 'locked',
  },
  {
    name: 'hash table',
    displayName: 'Hash Tables',
    categoryId: 7,
    description:
      'A hash table is a data structure that implements an associative array abstract data type, providing efficient insertion, deletion, and retrieval operations based on key-value pairs.',
    color: '#4fc3f7',
    initialStatus: 'locked',
  },
];

// @ts-nocheck
import React from 'react';
import { Box, Tab } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import ProblemDescription from '../../practice/CodeProblems/DetailedProblem/ProblemDescription';
import CodingAssignmentSubmissions from './CodingAssignmentSubmissions';

export function NewProblemSubTabs({ selectedProblem, assignmentId }) {
  const [currSubTab, setCurrSubTab] = React.useState('1');

  const handleChange = (event, newSubTab) => {
    setCurrSubTab(newSubTab);
  };

  return (
    <Box
      sx={{
        typography: 'body1',
        border: '1px solid #e5e8eb',
      }}
    >
      <TabContext value={currSubTab}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
          }}
        >
          <TabList onChange={handleChange}>
            <Tab
              label='Description'
              value='1'
            />
            <Tab
              label='Submissions'
              value='2'
            />
          </TabList>
        </Box>

        <TabPanel
          value='1'
          sx={{ padding: '10px' }}
        >
          <ProblemDescription selectedProblem={selectedProblem} />
        </TabPanel>
        <TabPanel
          value='2'
          sx={{ padding: '10px' }}
        >
          <CodingAssignmentSubmissions assignmentId={assignmentId} />
        </TabPanel>
      </TabContext>
    </Box>
  );
}

// @ts-nocheck
import { Stack, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

const ConversationFlowFinalScreen = ({ token }) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 600);
    }

    return () => {};
  }, [copied]);

  const copyToken = () => {
    navigator.clipboard.writeText(token);
    setCopied(true);
  };

  return (
    <Stack
      spacing={4}
      pt='5rem'
      height='100%'
      pl='1rem'
      pr='1rem'
    >
      <Typography
        variant='h2'
        component='h2'
        sx={{
          textAlign: 'center',
          fontFamily: 'Inter',
        }}
      >
        Congratulations!
      </Typography>

      <Typography
        variant='h4'
        component='h4'
        sx={{
          textAlign: 'center',
          textWrap: 'pretty',
          fontFamily: 'Inter',
          fontWeight: 500,
        }}
      >
        Fantastic job on making it this far! We're excited to connect with you
        shortly.
      </Typography>

      <Typography
        variant='h4'
        component='h4'
        sx={{
          textAlign: 'center',
          textWrap: 'pretty',
          fontFamily: 'Inter',
          fontWeight: 500,
        }}
      >
        As a token of your achievement, here's your exclusive code:{' '}
      </Typography>

      <Typography
        variant='h2'
        component='h2'
        sx={{
          textAlign: 'center',
          textWrap: 'pretty',
          fontFamily: 'Inter',
          color: 'blue',
        }}
      >
        <Tooltip
          title={copied ? 'Copied' : 'Click to copy'}
          onClick={copyToken}
        >
          <span
            style={{
              cursor: 'pointer',
            }}
          >
            {token}
          </span>
        </Tooltip>
      </Typography>

      <Typography
        variant='h5'
        component='h5'
        sx={{
          textAlign: 'center',
          fontFamily: 'Inter',
          fontWeight: 400,
        }}
      >
        Remember to mention this code to your counselor at the screening
        interview to accelerate your process.
      </Typography>
    </Stack>
  );
};

export default ConversationFlowFinalScreen;

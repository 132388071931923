// @ts-nocheck
import React, { useState } from 'react';
import {
  Avatar,
  Badge,
  Box,
  Divider,
  IconButton,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CircleIcon from '@mui/icons-material/Circle';
import { formatDateLabelChatList } from '../../utils/formatTime';
import ChatLabels from './Labels/ChatLabels';
import { ChatListItemOptions } from './ChatListItemOptions';
import { isAdmin, isProgramManager } from './ChatDashboard';
import { HighlightedText } from './HighlightedText';

export const ChatListItem = ({
  chatItem,
  handleSelectChat,
  setChatList,
  updateLoaderForChatList,
  searchQuery,
  searchType,
  filters,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleIconClick = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <ListItemButton
        onClick={() => handleSelectChat(chatItem)}
        sx={{
          pb: 1,
          position: 'relative',
          display: 'grid',
          cursor: 'pointer',
          transition: 'background 0.3s',
          '&:hover': {
            background: '#e0e0e0',
          },
          '&:hover .icon-button': {
            opacity: 1,
            visibility: 'visible',
          },
        }}
      >
        <Box
          display='flex'
          justifyContent='space-between'
          height='fit-content'
        >
          <Avatar
            alt={chatItem.name}
            src={chatItem.avatarUrl}
            sx={{ mr: 2 }}
          />
          <Box>
            <ListItemText
              primary={chatItem.user_name ? chatItem.user_name : 'Unknown User'}
            />
            {searchType === 'Chats' ? (
              <Typography
                variant='body2'
                sx={{ color: 'gray' }}
              >
                {messageText(chatItem)}
              </Typography>
            ) : (
              <HighlightedText
                text={messageText(chatItem)}
                search={searchQuery}
              />
            )}
          </Box>
          <Box
            sx={{
              position: 'absolute',
              right: '10px',
            }}
          >
            {chatItem.is_archived && getArchiveLabel()}
            {getLastMessageTimestamp(chatItem)}
            {getMessageCount(chatItem)}

            <IconButton
              onClick={handleIconClick}
              color='default'
              className='icon-button'
              sx={{
                width: '20px',
                height: '20px',
                opacity: 0,
                position: 'absolute',
                right: '0px',
                visibility: 'hidden',
                transition: 'opacity 0.3s, visibility 0.3s',
              }}
            >
              <KeyboardArrowDownIcon size='small' />
            </IconButton>
          </Box>
        </Box>

        {(isAdmin || isProgramManager) && (
          <ChatLabels labels={chatItem.labels} />
        )}
      </ListItemButton>

      <Divider />

      <ChatListItemOptions
        filters={filters}
        chatItem={chatItem}
        anchorEl={anchorEl}
        handleClosePopover={handleClosePopover}
        updateLoaderForChatList={updateLoaderForChatList}
        setChatList={setChatList}
      />
    </>
  );
};

const htmlEntitiesDecode = (str) => {
  return str.replace(/&#(\d+);/g, (match, dec) => {
    return String.fromCharCode(dec);
  });
};

const messageText = (chatItem) => {
  let lastMessage = chatItem?.last_message;

  if (!lastMessage) return '';

  lastMessage = htmlEntitiesDecode(lastMessage);

  const formattedMessage = lastMessage
    .replace(/\s+/g, ' ')
    .replace(/\\n/g, '\n')
    .trim();

  if (formattedMessage.length > 20) {
    return `${formattedMessage.slice(0, 20)}...`;
  }

  if (formattedMessage.includes('\n')) {
    return `${formattedMessage.split('\n')[0]}...`;
  }

  return formattedMessage;
};

const getMessageCount = (chatItem) => {
  return (
    <>
      {chatItem.unread_count > 0 && (
        <Badge
          badgeContent={chatItem.unread_count}
          color='primary'
          sx={{ mr: 1 }}
        />
      )}
      {chatItem.unread_count === 0 && chatItem.marked_unread && (
        <CircleIcon
          color='primary'
          size='small'
          sx={{ mt: '-10px' }}
        />
      )}
    </>
  );
};

const getLastMessageTimestamp = (chatItem) => {
  return (
    <Typography
      fontSize={10}
      color='textSecondary'
    >
      {formatDateLabelChatList(chatItem.last_message_created_at)}
    </Typography>
  );
};

const getArchiveLabel = () => {
  return (
    <Box
      sx={{ background: '#DEDEDE' }}
      borderRadius='4px'
      padding='2px 4px'
      display='inline-block'
    >
      <Typography
        fontSize={10}
        color='textSecondary'
      >
        Archived
      </Typography>
    </Box>
  );
};

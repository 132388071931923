// @ts-nocheck
import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useFastrackPipeline } from 'src/layouts/EfPipelineContext/FastrackPipelineContex';

const FiguredOut = ({ flowData, setFlowData, saveFlowData }) => {
  const { setScreenSource, trackButtonClick } = useFastrackPipeline();

  const { dailyCommitments, sleepHours, workHours } = flowData;
  const hoursPerDay = 24;

  const totalOccupiedHoursPerDay = workHours + sleepHours + dailyCommitments;
  let freeHoursPerDay = hoursPerDay - totalOccupiedHoursPerDay;

  freeHoursPerDay = freeHoursPerDay < 0 ? 0 : freeHoursPerDay;

  const handleContinue = () => {
    trackButtonClick({ source: 'screen_3f', eventName: 'continue' });
    saveFlowData({ screen: 'screen_3f' });
    freeHoursPerDay >= 2
      ? setScreenSource('screen_3g')
      : setScreenSource('screen_3h');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        minHeight: '60vh',
        margin: '0 auto',
        padding: '0 20px',
      }}
    >
      <Typography
        component='h1'
        sx={{
          color: '#1976d2',
          fontWeight: 'bold',
          fontSize: {
            xs: '2rem',
            sm: '2.4rem',
          },
          marginBottom: '10px',
        }}
      >
        Nice! You've got it all figured out!
      </Typography>

      <Typography
        variant='body1'
        sx={{
          fontSize: '1.2rem',
          color: 'black',
        }}
      >
        We’re here to make sure your learning journey fits perfectly into your
        busy life.
      </Typography>

      <Typography
        variant='body1'
        sx={{
          fontSize: '1.2rem',
          color: 'black',
          marginBottom: '20px',
        }}
      >
        Let’s show you how we’ve got your back, and get that time working for
        you!
      </Typography>

      <Button
        variant='contained'
        sx={{
          backgroundColor: '#1976d2',
          color: '#fff',
          padding: '8px 30px',
          marginTop: '20px',
        }}
        onClick={handleContinue}
      >
        Continue →
      </Button>
    </Box>
  );
};

export default FiguredOut;

import Paypal from '../../../assets/paypal.png';
import Uber from '../../../assets/uber.webp';
import Google from '../../../assets/google.webp';
import Meta from '../../../assets/meta.webp';
import Amazon from '../../../assets/amazon.webp';
import Linkedin from '../../../assets/linkedin.webp';
import Flipkart from '../../../assets/flipkart.webp';
import Microsoft from '../../../assets/microsoft.webp';
import Atlassian from '../../../assets/atlassian.webp';

export const companyData = {
  meta: {
    logo: Meta,
    style: {
      width: 'auto',
      height: '28px',
    },
    roles: [
      {
        name: 'E3 - Entry Level',
        minSalary: 2000000,
        maxSalary: 3000000,
      },
      {
        name: 'E4 - Software Engineer III',
        minSalary: 3500000,
        maxSalary: 5000000,
      },
      {
        name: 'E5 - Senior Software Engineer',
        minSalary: 5000000,
        maxSalary: 7000000,
      },
      {
        name: 'E6 - Staff Software Engineer',
        minSalary: 7000000,
        maxSalary: 9000000,
      },
      {
        name: 'E7 - Senior Staff Software Engineer',
        minSalary: 9000000,
        maxSalary: 12000000,
      },
      {
        name: 'E8 - Principal Engineer',
        minSalary: 12000000,
        maxSalary: 15000000,
      },
      {
        name: 'E9 - Distinguished Engineer',
        minSalary: 15000000,
        maxSalary: 20000000,
      },
    ],
  },
  google: {
    logo: Google,
    style: {},
    roles: [
      {
        name: 'L3 - Software Engineer I (Entry-Level)',
        minSalary: 2500000,
        maxSalary: 3500000,
      },
      {
        name: 'L4 - Software Engineer II (Mid-Level)',
        minSalary: 4000000,
        maxSalary: 5500000,
      },
      {
        name: 'L5 - Software Engineer III (Senior)',
        minSalary: 6000000,
        maxSalary: 8000000,
      },
      {
        name: 'L6 - Software Engineer IV (Staff)',
        minSalary: 8000000,
        maxSalary: 11000000,
      },
      {
        name: 'L7 - Software Engineer V (Senior Staff)',
        minSalary: 11000000,
        maxSalary: 15000000,
      },
      {
        name: 'L8 - Software Engineer VI (Principal)',
        minSalary: 15000000,
        maxSalary: 20000000,
      },
    ],
  },
  amazon: {
    logo: Amazon,
    style: {},
    roles: [
      {
        name: 'SDE I',
        minSalary: 1500000,
        maxSalary: 2500000,
      },
      {
        name: 'SDE II',
        minSalary: 2500000,
        maxSalary: 4000000,
      },
      {
        name: 'SDE III',
        minSalary: 4000000,
        maxSalary: 6000000,
      },
      {
        name: 'Principal SDE',
        minSalary: 6000000,
        maxSalary: 8500000,
      },
      {
        name: 'Senior Principal SDE',
        minSalary: 8500000,
        maxSalary: 12000000,
      },
    ],
  },
  linkedin: {
    logo: Linkedin,
    style: {},
    roles: [
      {
        name: 'L3 - Software Engineer (Entry-Level)',
        minSalary: 2000000,
        maxSalary: 3000000,
      },
      {
        name: 'L4 - Software Engineer (Mid-Level)',
        minSalary: 3500000,
        maxSalary: 5000000,
      },
      {
        name: 'L5 - Senior Software Engineer',
        minSalary: 5000000,
        maxSalary: 7000000,
      },
      {
        name: 'L6 - Staff Software Engineer',
        minSalary: 7000000,
        maxSalary: 9000000,
      },
      {
        name: 'L7 - Senior Staff Software Engineer',
        minSalary: 9000000,
        maxSalary: 12000000,
      },
      {
        name: 'L8 - Principal Software Engineer',
        minSalary: 12000000,
        maxSalary: 15000000,
      },
      {
        name: 'L9 - Distinguished Engineer',
        minSalary: 15000000,
        maxSalary: 20000000,
      },
      {
        name: 'L10 - LinkedIn Fellow',
        minSalary: 20000000,
        maxSalary: 25000000,
      },
    ],
  },
  flipkart: {
    logo: Flipkart,
    style: {},
    roles: [
      {
        name: 'SDE I',
        minSalary: 1500000,
        maxSalary: 2200000,
      },
      {
        name: 'SDE II',
        minSalary: 2500000,
        maxSalary: 3500000,
      },
      {
        name: 'SDE III',
        minSalary: 3500000,
        maxSalary: 5000000,
      },
      {
        name: 'Lead SDE',
        minSalary: 5000000,
        maxSalary: 7000000,
      },
      {
        name: 'Staff SDE',
        minSalary: 7000000,
        maxSalary: 9000000,
      },
      {
        name: 'Principal SDE',
        minSalary: 9000000,
        maxSalary: 12000000,
      },
    ],
  },
  uber: {
    logo: Uber,
    style: {},
    roles: [
      {
        name: 'L3 - Software Engineer (Entry-Level)',
        minSalary: 1500000,
        maxSalary: 2000000,
      },
      {
        name: 'L4 - Software Engineer (Mid-Level)',
        minSalary: 2500000,
        maxSalary: 3500000,
      },
      {
        name: 'L5 - Senior Software Engineer',
        minSalary: 4000000,
        maxSalary: 5500000,
      },
      {
        name: 'L6 - Staff Software Engineer',
        minSalary: 5500000,
        maxSalary: 7500000,
      },
      {
        name: 'L7 - Senior Staff Software Engineer',
        minSalary: 7500000,
        maxSalary: 9500000,
      },
      {
        name: 'L8 - Principal Software Engineer',
        minSalary: 9500000,
        maxSalary: 12000000,
      },
      {
        name: 'L9 - Distinguished Engineer',
        minSalary: 12000000,
        maxSalary: 15000000,
      },
    ],
  },
  microsoft: {
    logo: Microsoft,
    style: {},
    roles: [
      {
        name: 'SDE I',
        minSalary: 2500000,
        maxSalary: 4000000,
      },
      {
        name: 'SDE II',
        minSalary: 4000000,
        maxSalary: 6000000,
      },
      {
        name: 'SDE III',
        minSalary: 6000000,
        maxSalary: 8500000,
      },
      {
        name: 'Principal SDE',
        minSalary: 8500000,
        maxSalary: 12000000,
      },
      {
        name: 'Partner SDE',
        minSalary: 10000000,
        maxSalary: 15000000,
      },
    ],
  },
  paypal: {
    logo: Paypal,
    style: {},
    roles: [
      {
        name: 'Software Engineer I (Entry-Level)',
        minSalary: 2200000,
        maxSalary: 3500000,
      },
      {
        name: 'Software Engineer II (Mid-Level)',
        minSalary: 3500000,
        maxSalary: 5000000,
      },
      {
        name: 'Senior Software Engineer',
        minSalary: 5000000,
        maxSalary: 7000000,
      },
      {
        name: 'Staff Software Engineer',
        minSalary: 10000000,
        maxSalary: 15000000,
      },
    ],
  },
  atlassian: {
    logo: Atlassian,
    style: {},
    roles: [
      {
        name: 'Entry-Level Software Engineer',
        minSalary: 2200000,
        maxSalary: 3500000,
      },
      {
        name: 'Mid-Level Software Engineer',
        minSalary: 3500000,
        maxSalary: 5000000,
      },
      {
        name: 'Senior Software Engineer',
        minSalary: 5000000,
        maxSalary: 7000000,
      },
      {
        name: 'Staff Software Engineer',
        minSalary: 7000000,
        maxSalary: 9500000,
      },
      {
        name: 'Principal Software Engineer',
        minSalary: 10000000,
        maxSalary: 12000000,
      },
    ],
  },
};

/**
 * Note: This logic could change if the screens and terminal conditions change.
 * There are two types of terminal screens.
 * Type 1:
 *  This terminal screen can be reached from two screens:
 *    - From 'screen_2' by selecting 'other' as the concern.
 *    - From 'screen_8' by selecing "I'd like to discuss with counsellor".
 *  In this case, terminal screen is 'screen_2b'.
 *
 * Type 2:
 *  This terminal screen can be reached from any screen even from 'screen_2' and 'screen_8'.
 *  In this case, "Release offer letter" screen is the terminal screen (basically, v1 of the fastrack offer letter).
 *
 * We're referring them as terminal screens because from these screens, we can't continue with v4 of the fastrack offer letter.
 *
 * When a user reaches 'screen_2b' and later login again, the user should be kept at 'screen_2b' only (if the offer letter hasn't expired).
 * When a user reached v1 of fastrack offer letter, then user should never be shown v4 again.
 *
 * For now, terminal conditions can be checked by checking the last screen that the user completed.
 *
 * In figma design: 'v1' is also being referred as 'screen_3'.
 * https://www.figma.com/design/kQ0oB7BFAdbmKK1yUzNc1J/APP-TO-TAP-2.0?node-id=0-1&node-type=canvas&t=Jt7yOBQaA92KcskH-0
 */
export const getNextScreen = ({ lastScreen, freeHoursPerDay }) => {
  switch (lastScreen) {
    case 'pcf_welcome_screen':
      return 'ps_effectiveness_1';

    case 'ps_effectiveness_1':
      return 'ps_effectiveness_2';

    case 'ps_effectiveness_2':
      return 'clearing_interview_1';

    case 'clearing_interview_1':
      return 'clearing_interview_2';

    case 'clearing_interview_2':
      return 'clearing_interview_3';

    case 'clearing_interview_3':
      return 'clearing_interview_4';

    case 'clearing_interview_4':
      return 'clearing_interview_4';

    case 'screen_2b':
      // keep the user on the same screen.
      return 'screen_2b';

    case 'screen_8':
      return 'screen_8';

    case 'screen_2':
      return 'screen_4';

    case 'screen_3':
      return 'screen_3a';

    case 'screen_3a':
      return 'screen_3b';

    case 'screen_3b':
      return 'screen_3c';

    case 'screen_3c':
      return 'screen_3d';

    case 'screen_3d':
      return freeHoursPerDay >= 2 ? 'screen_3e' : 'screen_3k';

    case 'screen_3e':
      return 'screen_3f';

    case 'screen_3f':
      return 'screen_3g';

    case 'screen_3g':
      return freeHoursPerDay >= 2 ? 'screen_3h' : 'screen_3n';

    case 'screen_3h':
      return 'screen_3i';

    case 'screen_3i':
      return 'screen_3j';

    case 'screen_3k':
      return 'screen_3l';

    case 'screen_3l':
      return 'screen_3m';

    case 'screen_3m':
      return 'screen_3g';

    case 'screen_3n':
      return 'screen_3o';

    case 'screen_4':
      return 'screen_6';

    case 'screen_6':
      return 'screen_7';

    case 'screen_7':
      return 'screen_8';

    default:
      return 'screen_1';
  }
};

export const Concerns = {
  TIME_MANAGEMENT_ISSUE: 'Time management issues',
  OTHER: 'other',
  FEES_ARE_HIGH: 'Fees Are High',
  WORRIED_WONT_LAND_A_JOB: 'Worried about job after course',
  STILL_HAVE_DOUBT: 'Still have doubts?',
};

export const hiddenSteps = [
  'pcf_welcome_screen',
  'ps_effectiveness_1',
  'ps_effectiveness_2',
  'clearing_interview_1',
  'clearing_interview_2',
  'clearing_interview_3',
  'clearing_interview_4',
  'screen_1',
  'screen_2',
  'screen_2a',
  'screen_2b',
  'screen_3',
  'screen_3a',
  'screen_3b',
  'screen_3c',
  'screen_3d',
  'screen_3e',
  'screen_3f',
  'screen_3g',
  'screen_3h',
  'screen_3i',
  'screen_3j',
  'screen_3k',
  'screen_3l',
  'screen_3m',
  'screen_3n',
  'screen_3o',
];
export const SmallChangesList = [
  {
    id: 1,
    title: 'Cut down on your screen time 📱',
    description:
      'She reduced social media and unnecessary distractions by 30 minutes each day, reclaiming precious time for learning.',
  },
  {
    id: 2,
    title: 'Blocked time for learning 📅',
    description:
      'She used a time-blocking method, scheduling her learning hours. She also reserved longer weekend sessions when her schedule allowed, ensuring uninterrupted focus and steady progress.',
  },
  {
    id: 3,
    title: 'Combined different modes of learning 💡',
    description:
      'She maximized her learning by using multiple formats—audio lessons during commutes, video tutorials during downtime, and written content for in-depth study sessions, keeping her learning diverse and efficient.',
  },
  {
    id: 4,
    title: 'Focused on high-impact learning areas 🎯',
    description:
      'She prioritized the most crucial parts of the curriculum, spending her time on topics that aligned with her career goals and gave the highest return on investment.',
  },
];

export const options = [
  'Recorded sessions to catch up',
  'Tools to simplify complex topics',
  'Progress check-ins from the team',
  'Mock tests to track progress',
];

export const PressedForTimeoptions = [
  'Recorded sessions to catch up on missed classes',
  'Tools to simplify complex topics into manageable parts',
  'Regular Progress check-ins from growth team',
  'Mock tests to track my improvement',
];

export const ALLOWED_ENROLLMENT_SCREENS = [
  'screen_2',
  'screen_2a',
  'screen_4',
  'screen_5',
  'screen_6',
  'screen_7',
  'pcf_welcome_screen',
  'ps_effectiveness_1',
  'ps_effectiveness_2',
  'clearing_interview_1',
  'clearing_interview_2',
  'clearing_interview_3',
  'screen_3',
  'screen_3a',
  'screen_3b',
  'screen_3c',
  'screen_3d',
  'screen_3e',
  'screen_3f',
  'screen_3g',
  'screen_3h',
  'screen_3i',
  'screen_3j',
  'screen_3k',
  'screen_3l',
  'screen_3m',
  'screen_3n',
  'screen_3o',
];

export const tooltipTitle =
  "Once the offer letter is released, you'll need to secure your seat by paying a token amount of ₹10,000, which will be adjusted in the total program fees";

// @ts-nocheck
import axios from 'axios';
import React, { useState, createContext, useContext, useEffect } from 'react';
import { BASE_URL, getHeaders } from 'src/apis/urls';
import ErrorNotifier from 'src/components/ToastNotifications/ErrorNotifier';

const FastrackPipelineContext = createContext();
export const useFastrackPipeline = () => useContext(FastrackPipelineContext);

export const FastrackPipelineProvider = (props) => {
  const [screenSource, setScreenSource] = useState(''); // relevant only for tracking.
  const [errorMessage, setErrorMessage] = useState('');
  const [hasError, setHasError] = useState(false);
  const [screenType, setScreenType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [olMessage, setOLMessage] = useState('');
  const [activeOLStatus, setActiveOLStatus] = useState('pending');
  const [offerLetter, setOfferLetter] = useState({});
  const [extensionRequestCount, setExtensionRequestCount] = useState(0);
  const [showConnectingScreen, setShowConnectingScreen] = useState(false);
  const [expiryExtensionMessage, setExpiryExtensionMessage] = useState('');
  const [shouldShowOfferLetterInfoPopup, setShowOfferLetterInfoPopup] =
    useState(false);
  const [hasJoinedWaitlist, setHasJoinedWaitlist] = useState(false);
  const releaseOfferLetter = async ({ source }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/api/offer-letters/${offerLetter.olId}/release`,
        {
          source,
        },
        {
          headers: getHeaders(),
        }
      );

      setScreenType(response.data?.screen_type ?? 'v1');
    } catch (error) {
      console.log(error);
      setHasError(true);
      setErrorMessage(
        error?.response?.data?.message ?? 'Something went wrong.'
      );
    }
  };

  const trackButtonClick = async ({ source, eventName }) => {
    if (!offerLetter) {
      return;
    }

    const body = {
      source,
      event_name: eventName,
      type: 'offer_letter',
    };

    try {
      await axios.post(
        `${BASE_URL}/api/eligibility-forms/${offerLetter?.olId}/activities`,
        body,
        {
          headers: getHeaders(),
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <FastrackPipelineContext.Provider
      value={{
        screenType,
        setScreenType,
        loading,
        setLoading,
        olMessage,
        setOLMessage,
        activeOLStatus,
        setActiveOLStatus,
        offerLetter,
        setOfferLetter,
        extensionRequestCount,
        setExtensionRequestCount,
        showConnectingScreen,
        setShowConnectingScreen,
        expiryExtensionMessage,
        setExpiryExtensionMessage,
        shouldShowOfferLetterInfoPopup,
        setShowOfferLetterInfoPopup,
        screenSource,
        setScreenSource,
        setErrorMessage,
        setHasError,
        trackButtonClick,
        hasJoinedWaitlist,
        releaseOfferLetter,
        setHasJoinedWaitlist,
      }}
    >
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      {props.children}
    </FastrackPipelineContext.Provider>
  );
};

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Typography,
  Container,
  Card,
  CardContent,
  Box,
  Button,
} from '@mui/material';
import { Stack } from '@material-ui/core';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import EditQuestionModal from './EditQuestionModal';
import ErrorNotifier from '../../../ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../../ToastNotifications/SuccessNotifier';

export default function QuizQuestions({ formState, updateQuizQuestion }) {
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [open, setOpen] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const handleEdit = (question) => {
    setSelectedQuestion(question);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedQuestion(null);
    setOpen(false);
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      {isSuccess && (
        <SuccessNotifier
          message={successMessage}
          setIsSuccess={setIsSuccess}
        />
      )}

      <Stack spacing={1}>
        {formState.quiz_questions.map((question, index) => (
          <Card key={index}>
            <CardContent>
              <Typography variant='body1'>
                Question Type: <span>{question.question_type} correct</span>
              </Typography>
              <Typography
                variant='subtitle1'
                mt={1}
              >
                {question.question}
              </Typography>
              {question.quiz_question_options.map((option, optionIndex) => (
                <Box
                  key={optionIndex}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    maxWidth: '20%',
                  }}
                >
                  {option.answer ? (
                    <>
                      <Typography color='#54D62C'>{option.option}</Typography>
                      <CheckIcon color='success' />
                    </>
                  ) : (
                    <>
                      <Typography color='error'>{option.option}</Typography>
                      <CloseIcon color='error' />
                    </>
                  )}
                </Box>
              ))}
              <Button
                sx={{ mt: 1 }}
                variant='outlined'
                onClick={() => handleEdit(question)}
              >
                Edit
              </Button>
            </CardContent>
          </Card>
        ))}
        {selectedQuestion && (
          <EditQuestionModal
            question={selectedQuestion}
            open={open}
            onClose={handleClose}
            updateQuizQuestion={updateQuizQuestion}
            setHasError={setHasError}
            setErrorMessage={setErrorMessage}
            setIsSuccess={setIsSuccess}
            setSuccessMessage={setSuccessMessage}
          />
        )}
      </Stack>
    </>
  );
}

import React from 'react';
import { DSATopic } from '../DSATopic';
import Card from '../Molecules/Card';

const gridStyle = {
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  columnGap: '10px',
  rowGap: '10px',
  padding: '50px',
  justifyContent: 'center',
  alignItems: 'center',
};

const cardStyle = {
  border: '1px solid #ccc', // Example border style
  borderRadius: '20px', // Makes corners more rounded
  backgroundColor: '#1976D2',
};

const CardList = () => {
  return (
    <div style={gridStyle}>
      {DSATopic.map((topic, index) => (
        <Card
          key={index}
          gif={topic.gif}
          image_count={topic.image_count}
          name={topic.displayName}
          description={topic?.description}
          category={topic?.displayName}
          style={cardStyle} // Passing border style to each Card
        />
      ))}
    </div>
  );
};

export default CardList;

import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { accordionData } from './Data';
import DividerComponent from 'src/components/AtomicComponents/DividerComponent';

const CustomAccordion = ({ icon: Icon, title, children }) => (
  <Accordion
    sx={{
      border: '1px solid black',
      borderRadius: '8px',
      marginBottom: '16px',
    }}
  >
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls={`${title.replace(' ', '').toLowerCase()}-content`}
      id={`${title.replace(' ', '').toLowerCase()}-header`}
    >
      <Icon sx={{ marginRight: '12px' }} />
      <Typography>{title}</Typography>
    </AccordionSummary>
    <DividerComponent />
    <AccordionDetails style={{ marginTop: '1em' }}>
      <Typography>{children}</Typography>
    </AccordionDetails>
  </Accordion>
);

const Admission = () => {
  return (
    <div>
      {accordionData.map((item, index) => (
        <CustomAccordion
          key={index}
          icon={item.icon}
          title={item.title}
        >
          {item.content}
        </CustomAccordion>
      ))}
    </div>
  );
};

export default Admission;

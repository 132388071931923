import {
  Typography,
  Box,
  TextField,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from '@mui/material';

const TextFieldComponent = ({
  value,
  handleChange
}) => {
  return (
    <TextField
      variant='outlined'
      fullWidth
      value={value}
      onChange={handleChange}
      required
    />
  );
};

const TextAreaComponent = ({
  value,
  handleChange
}) => {
  return (
    <TextField
      variant='outlined'
      fullWidth
      value={value}
      onChange={handleChange}
      required
      multiline
      minRows={1}
      maxRows={6}
    />
  );
};

const RadioComponent = ({
  value,
  question,
  handleChange
}) => {
  return (
    <FormControl
      component='fieldset'
      margin='normal'
      sx={{
        mb: 2,
      }}
    >
      <RadioGroup
        value={value}
        onChange={handleChange}
        name={`question_${question.id}`}
        row
      >
        {question.options.map((val, index) => (
          <FormControlLabel
            key={index}
            value={val}
            control={<Radio required />}
            label={val}
            labelPlacement='bottom'
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

const CheckboxComponent = ({
  question,
  selectedOptions,
  handleOptionChange,
}) => {
  return (
    <FormControl
      component='fieldset'
      margin='normal'
      sx={{
        mb: 2,
      }}
    >
      <FormGroup>
        {question.options.map((option) => (
          <FormControlLabel
            key={option}
            control={
              <Checkbox
                checked={selectedOptions.includes(option)}
                onChange={handleOptionChange}
                value={option}
              />
            }
            label={option}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
};

export const QuestionElement = ({
  question,
  onChange,
  value,
  selectedOptions,
  setSelectedOptions,
}) => {
  const handleOptionChange = (event) => {
    const { value } = event.target;
    const updatedSelectedOptions = selectedOptions.includes(value)
      ? selectedOptions.filter((option) => option !== value)
      : [...selectedOptions, value];

    setSelectedOptions(updatedSelectedOptions);
    onChange(question.id, updatedSelectedOptions);
  };

  const handleChange = (event) => {
    const answer = event.target.value;
    onChange(question.id, answer);
  };

  return (
    <Box sx={{ mb: 2 }}>
      <Typography
        variant='body1'
        sx={{
          whiteSpace: 'pre-line',
        }}
      >
        {question.title} *
      </Typography>
      {question.type === 'text' && (
        <TextFieldComponent
          value={value}
          handleChange={handleChange}
        />
      )}
      {question.type === 'textarea' && (
        <TextAreaComponent
          value={value}
          handleChange={handleChange}
        />
      )}
      {question.type === 'radio' && (
        <RadioComponent
          value={value}
          question={question}
          handleChange={handleChange}
        />
      )}
      {question.type === 'checkbox' && (
        <CheckboxComponent
          selectedOptions={selectedOptions}
          question={question}
          handleOptionChange={handleOptionChange}
        />
      )}
    </Box>
  );
};

import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import CircularProgress from '@mui/material/CircularProgress';

const CustomButton = ({
  problemId,
  markAsCompleted,
  hasMarkedCompleted,
  loading,
  disabled,
}) => {
  return (
    <Box ml={3}>
      <LoadingButton
        onClick={() => markAsCompleted(problemId)}
        loading={loading}
        disabled={disabled || hasMarkedCompleted(problemId)}
        loadingPosition='center'
        children={
          hasMarkedCompleted(problemId) ? 'Completed' : 'Mark as completed'
        }
        style={
          hasMarkedCompleted(problemId)
            ? {
                width: '170px',
                background: '#4060f5',
                color: 'white',
                borderRadius: '8px',
              }
            : {
                width: '170px',
                backgroundColor: 'transparent',
                borderRadius: '8px',
                border: '1px solid rgba(64, 96, 245, 0.5)',
              }
        }
      />
    </Box>
  );
};

export default CustomButton;

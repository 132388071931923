import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import { Typography } from '@material-ui/core';

export default function QuizAttemptsTable({ quizResponses }) {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Learner Name</TableCell>
            <TableCell>Quiz Name</TableCell>
            <TableCell>Score</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {quizResponses.map((result, index) => (
            <TableRow key={index}>
              <TableCell>{result.learner_name}</TableCell>
              <TableCell>{result.quiz_name}</TableCell>
              <TableCell>{result.score ?? '--'}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {quizResponses?.length === 0 && (
        <Typography
          textAlign='center'
          variant='body1'
          p={2}
        >
          No attempts found.
        </Typography>
      )}
    </TableContainer>
  );
}

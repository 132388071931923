import React, { useState } from 'react';
import { Box } from '@material-ui/system';
import { Typography, Button } from '@material-ui/core';
import CustomButton from '../Atoms/CustomButton';

const IndividualProblem = ({
  index,
  problem,
  markAsCompleted,
  hasMarkedCompleted,
  disabled,
}) => {
  const [loading, setLoading] = useState(false);

  const extractProblemSlug = (url) => {
    return url.split('/').pop();
  };

  const handleViewProblem = (url) => {
    window.open(url, '_blank');
  };

  const handleMarkAsCompleted = async (
    problemId,
    problemLevel,
    problemSlug
  ) => {
    setLoading(true);
    await markAsCompleted(problemId, problemLevel, problemSlug);
    setLoading(false);
  };

  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='space-between'
      key={index}
      mb={2}
      p={1}
      paddingX={2}
      style={{
        backgroundColor: '#E5F5FD',
        cursor: 'pointer',
        borderRadius: '12px',
      }}
    >
      <Typography>
        {index + 1}.&nbsp;
        {problem?.link
          .split('problems/')[1]
          .split('-')
          .map((word, index) =>
            index > 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)
          )
          .join(' ')}
      </Typography>
      <Box
        display='flex'
        justifyContent='space-between'
      >
        <Button
          variant='contained'
          color='primary'
          onClick={() => handleViewProblem(problem?.link)}
        >
          View
        </Button>
        <CustomButton
          problemId={problem?.problem_id}
          markAsCompleted={() =>
            handleMarkAsCompleted(
              problem?.problem_id,
              problem?.level,
              extractProblemSlug(problem?.link)
            )
          }
          hasMarkedCompleted={hasMarkedCompleted}
          loading={loading}
          disabled={disabled}
        />
      </Box>
    </Box>
  );
};

export default IndividualProblem;

import React, { useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { editMessage, getHeaders } from '../../apis/urls';

const styles = {
  negativeCount: {
    color: 'red',
  },
};

export default function MessageEditingBox({
  message,
  setIsEditing,
  setUpdateMessageStatus,
}) {
  const cleaningLink = (message) => {
    const urlRegex = /<a[^>]*href="([^"]*)"[^>]*>([^<]*)<\/a>/;
    const matches = message.match(urlRegex);

    if (matches && matches.length >= 3) {
      const cleanedUrl = matches[2];
      const cleanedMessage = message.replace(matches[0], cleanedUrl);
      return `${cleanedMessage}`;
    }
    return message;
  };

  const [editedText, setEditedText] = useState(cleaningLink(message.message));
  const CHARACTER_LIMIT = 2000;
  const CHARACTER_WARNING_LIMIT = 1800;
  const characterCount = editedText.length;
  const isCharacterLimitExceeded = characterCount > CHARACTER_LIMIT;

  const handleEditMessage = (messageId, newText) => {
    setUpdateMessageStatus('Updating...');
    const body = {
      message: newText,
    };

    axios
      .patch(editMessage(messageId), body, {
        headers: getHeaders(),
      })
      .then(() => {
        setUpdateMessageStatus('Updated');
        setTimeout(() => {
          setUpdateMessageStatus('');
        }, 1000);
      })
      .catch((e) => {
        console.log(e);
        setUpdateMessageStatus('Error');
      });
  };

  const handleSaveEdit = () => {
    const sanitizedText = editedText.replace(/\n/g, '\\n');
    handleEditMessage(message.id, sanitizedText);
    setIsEditing(false);
  };

  return (
    <div style={{ width: '100%', position: 'relative' }}>
      <TextField
        minRows={3}
        value={editedText}
        onChange={(e) => setEditedText(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && e.shiftKey) {
            e.preventDefault();
            setEditedText(`${editedText}\n`);
          } else if (e.key === 'Enter') {
            e.preventDefault();
            if (!isCharacterLimitExceeded) {
              handleSaveEdit();
            }
          }
        }}
        inputProps={{
          style: {
            wordBreak: 'break-word',
            overflow: 'auto',
            padding: '8px',
            borderRadius: '4px',
            resize: 'vertical',
            backgroundColor: 'white',
          },
        }}
        multiline
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          textAlign: 'center',
          gap: '20px',
          marginTop: '8px',
        }}
      >
        <Button
          onClick={handleSaveEdit}
          variant='contained'
          color='primary'
          style={{
            cursor: isCharacterLimitExceeded ? 'not-allowed' : 'pointer',
          }}
          disabled={isCharacterLimitExceeded}
        >
          Save
        </Button>
        {characterCount >= CHARACTER_WARNING_LIMIT && (
          <Typography
            variant='body2'
            style={
              characterCount > CHARACTER_LIMIT
                ? { ...styles.negativeCount }
                : styles.characterCounter
            }
          >
            {CHARACTER_LIMIT - characterCount}
          </Typography>
        )}
      </Box>
    </div>
  );
}

// @ts-nocheck
import {
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import SummaryImage from '../../../../assets/group_195717.png';
import React, { useEffect, useState } from 'react';
import { useFastrackPipeline } from 'src/layouts/EfPipelineContext/FastrackPipelineContex';

const OneToOneInterviewsCaseStudy = ({
  setFlowData,
  flowData,
  saveFlowData,
}) => {
  const { screenSource, setScreenSource, trackButtonClick } =
    useFastrackPipeline();
  const theme = useTheme();
  const isBelowMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleNext = () => {
    trackButtonClick({
      source: 'clearing_interview_1',
      eventName: 'next',
    });
    saveFlowData({
      screen: 'clearing_interview_1',
      response: { next: true },
    });
    setScreenSource('clearing_interview_2');
  };

  return (
    <Stack
      spacing={5}
      alignItems='center'
      textAlign='center'
      width='100%'
    >
      <Typography
        variant='h3'
        component='h3'
        sx={{
          color: '#1976d2',
          fontFamily: 'Inter',
          fontWeight: 'bolder',
          fontSize: {
            xs: '1.2rem',
            sm: '1.5rem',
            md: '1.6rem',
            lg: '1.75rem',
          },
          textAlign: 'center',
          textWrap: 'stable',
        }}
      >
        Got it. Let’s meet Arun Kumar who faced a similar challenge.
      </Typography>

      <Stack
        spacing={-1.5}
        width='inherit'
        alignItems='center'
        textAlign='center'
      >
        <Typography
          variant='body1'
          component='p'
          sx={{
            textAlign: 'center',
            fontFamily: 'Inter',
            fontWeight: 'normal',
            width: '83%',
            fontSize: {
              xs: '1rem',
              sm: '1.1rem',
              md: '1.2rem',
              lg: '1.3rem',
            },
            textWrap: 'stable',
            paddingBottom: '1rem',
          }}
        >
          Despite his technical skills getting his resume shortlisted, he faced
          {` `}
          <b>
            rejection in 90% of face-to-face interview rounds due to a lack of
            confidence and interview nervousness.
          </b>{' '}
          We collected his interview performance stats prior to joining
          HeyCoach.
        </Typography>
      </Stack>
      <Stack
        spacing={5}
        direction={isBelowMobile ? 'column' : 'row'}
        justifyContent='space-between'
        sx={{
          width: {
            xs: '100%',
            sm: '100%',
            md: '100%',
            lg: '80%',
          },
        }}
      >
        <Box
          style={{
            maxWidth: '45rem',
          }}
        >
          <img
            style={{
              width: '45rem',
            }}
            src={SummaryImage}
            alt='summary'
          />
        </Box>

        <Stack
          spacing={2}
          width='100%'
        >
          <Typography
            component='p'
            sx={{
              fontFamily: 'Inter',
              fontWeight: 'normal',
              fontSize: {
                xs: '1rem',
                sm: '1.1rem',
                md: '1.2rem',
                lg: '1.2rem',
              },
              textAlign: 'left',
              paddingBottom: '1rem',
              ...(isBelowMobile && {
                textAlign: 'center',
                textWrap: 'balanced',
              }),
            }}
          >
            Now, let's explore how HeyCoach's placement support team used these
            stats to enhance Arun's success rate and secure him a top job
          </Typography>

          <Button
            sx={{
              width: 'fit-content',
              padding: '0.3rem 1.5rem',
              textTransform: 'none',
              fontFamily: 'Inter',
              fontSize: '1rem',
              fontWeight: 650,
              '&:hover': {
                color: 'white',
                backgroundColor: '#1976d2',
              },
              color: 'white',
              backgroundColor: '#1976d2',
              ...(isBelowMobile && {
                justifySelf: 'center',
                alignSelf: 'center',
              }),
            }}
            onClick={handleNext}
            endIcon={<EastIcon />}
          >
            Next
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default OneToOneInterviewsCaseStudy;

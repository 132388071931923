import React, { useEffect, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import axios from 'axios';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { getUserId } from '../../configs/auth';
import { fetchLearnerSessionDetailsURL, getHeaders } from '../../apis/urls';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';
import { capitalize, formatSessionTitle } from '../utils/common';

const UpcomingSessionsDetails = () => {
  const [sessions, setSessions] = useState([]);
  const [displayedSessions, setDisplayedSessions] = useState([]);
  const currentProgram = useSelector((state) => state.programs.currentProgram);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    axios
      .get(fetchLearnerSessionDetailsURL(getUserId()), {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((res) => {
        setSessions(res.data?.session_details?.sessions);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(err.response?.data?.message || err?.message);
      });

    return () => {};
  }, []);

  const getLatestSessions = (sessions) => {
    const MAX_SESSIONS_TO_SHOW = 3;
    const latestSessions = [];
    let index = 0;

    const checkAndAddSessions = (startIndex) => {
      if (startIndex >= sessions.length) {
        return;
      }

      const currentSessions = sessions.slice(
        startIndex,
        startIndex + MAX_SESSIONS_TO_SHOW
      );

      if (currentSessions.every((session) => session.status === 'conducted')) {
        index += MAX_SESSIONS_TO_SHOW;
        checkAndAddSessions(index);
      } else {
        latestSessions.push(...currentSessions);
      }
    };

    checkAndAddSessions(index);
    setDisplayedSessions(latestSessions);
  };

  useEffect(() => {
    getLatestSessions(sessions);
  }, [sessions]);

  const allSessionsConducted =
    displayedSessions.length > 0 &&
    displayedSessions.every((session) => session?.status === 'conducted');

  return (
    <Box>
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      {displayedSessions.length === 0 || allSessionsConducted ? (
        <Box
          sx={{
            width: '100%',
            border: '1px solid gray',
            borderRadius: '14px',
            height: '130px',
            textAlign: 'center',
            padding: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {allSessionsConducted
            ? 'All sessions have been conducted.'
            : 'Upcoming sessions are currently being planned and will be scheduled shortly. Please stay tuned for updates.'}
        </Box>
      ) : (
        <Stack
          spacing={2}
          sx={{
            overflowX: 'auto',
            width: '100%',
            height: '170px',
            backgroundColor: 'white',
          }}
        >
          {displayedSessions.map((session, index) => (
            <Box
              key={index}
              sx={{
                backgroundColor:
                  session.status === 'scheduled' ? '#fffacf' : '',
                padding: '10px',
                borderRadius: '6px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                border: '1px solid #eeecec',
                textAlign: 'center',
              }}
            >
              <Typography
                variant='body2'
                textAlign='start'
                sx={{
                  width: '214px',
                  overflow: 'hidden',
                }}
              >
                {`Session ${index + 1}: ${formatSessionTitle(session.title)}`}
              </Typography>
              <Typography variant='body2'>{session?.mentor}</Typography>
              <Typography variant='body2'>{`${capitalize(
                session?.status
              )}`}</Typography>
              <Stack
                direction='row'
                spacing={2}
              >
                <EventIcon />
                <Typography variant='body2'>
                  {moment(session?.start_time).format('DD MMM, h:mmA')}
                </Typography>
              </Stack>
            </Box>
          ))}
        </Stack>
      )}
    </Box>
  );
};

export default UpcomingSessionsDetails;

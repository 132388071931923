import Button from '@material-ui/core/Button';
import React from 'react';
import ModalCreateAssignment from '../_dashboard/assignments/ModalCreateAssignment';

const ModalButton = ({ label, buttonCss, modalType }) => {
    const [modalOpen, setModalOpen] = React.useState(false);

    return (
        <>
            <Button
                variant="outlined"
                style={buttonCss}
                name={label}
                onClick={() => {
                    setModalOpen(!modalOpen);
                }}
            >
                {label}
            </Button>
            {modalType === 'create_assignment' && (
                <ModalCreateAssignment
                    open={modalOpen}
                    handleClose={setModalOpen}
                    index={-1}
                    operationType="create"
                    titleLabel="Create Assignment"
                    buttonLabel="Create Assignment"
                />
            )}
        </>
    );
};

export default ModalButton;

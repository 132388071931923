import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import {
  Box,
  Link,
  Drawer,
  Typography,
  Avatar,
  Chip,
  Stack,
} from '@material-ui/core';
// components
import AssessmentIcon from '@mui/icons-material/Assessment';
import ChatIcon from '@mui/icons-material/Chat';
import AssignmentIcon from '@mui/icons-material/Assignment';
import StarsIcon from '@mui/icons-material/Stars';
import PeopleIcon from '@mui/icons-material/People';
import EditIcon from '@mui/icons-material/Edit';
import SettingsIcon from '@mui/icons-material/Settings';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import calendarOutline from '@iconify/icons-ant-design/calendar-outline';
import appstoreAddOutlined from '@iconify/icons-ant-design/appstore-add-outlined';
import containerFilled from '@iconify/icons-ant-design/container-filled';
import TaskIcon from '@mui/icons-material/Task';
import ClassIcon from '@mui/icons-material/Class';
import { Icon } from '@iconify/react';
import PreviousPage from '../../../pages/PreviousPage';
import Logo from '../../Logo';
import Scrollbar from '../../Scrollbar';
import NavSection from '../../NavSection';
import { MHidden } from '../../@material-extend';
//
import { getUserImage, getUserName, getUserType } from '../../../configs/auth';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[200],
}));

// ----------------------------------------------------------------------

ProgramsSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
  programName: PropTypes.string,
};

export default function ProgramsSidebar({
  isOpenSidebar,
  onCloseSidebar,
  programName,
}) {
  const { pathname } = useLocation();
  const profileName = String(getUserName()).split(' ').join('-');
  const navigate = useNavigate();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  function updateSidebarConfigs() {
    sidebarConfigs.forEach((config, index) => {
      config.path = `/mentor/programs/${programName}/${config.title
        .toLowerCase()
        .split(' ')
        .join('_')}`;
    });
    return sidebarConfigs;
  }

  const userRole = getUserType();
  let navigateTo;
  if (userRole === 'coach') navigateTo = `/profile/${profileName}`;
  else navigateTo = '/student/profile';

  const renderContent = (
    <Scrollbar
      sx={{
        height: '100%',
        '& .simplebar-content': {
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Box sx={{ px: 2.5, py: 3 }}>
        <Box
          component={RouterLink}
          to='/mentor'
          sx={{ display: 'inline-flex' }}
        >
          <Logo />
        </Box>
      </Box>

      <Box
        display='flex'
        sx={{ ml: 1, mb: 1 }}
      >
        <PreviousPage
          handlePrevPageClick={() => navigate('/mentor/programs')}
        />
      </Box>

      <Box
        display='flex'
        justifyContent='center'
        sx={{ mb: 2 }}
      >
        <Chip
          label={programName.split('-').join(' ')}
          size='medium'
          sx={{ width: 200 }}
        />
      </Box>

      <NavSection navConfig={updateSidebarConfigs()} />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <RootStyle>
      <MHidden width='lgUp'>
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width='lgDown'>
        <Drawer
          open
          variant='persistent'
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
            },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}

const getIcon = (name) => name;

const sidebarConfigs = [
  {
    title: 'Enrollments',
    path: '/mentor/programs',
    icon: getIcon(<PeopleIcon />),
    isFor: ['admin', 'program_manager'],
  },
  {
    title: 'Learn',
    path: '/mentor/programs',
    icon: (
      <Icon
        icon={containerFilled}
        width={22}
        height={22}
      />
    ),
    isFor: ['admin', 'program_manager'],
  },
  {
    title: 'Practice',
    path: '/mentor/programs',
    icon: getIcon(<EditIcon />),
    isFor: ['admin', 'program_manager'],
  },
  {
    title: 'Evaluate',
    path: '/mentor/programs',
    icon: getIcon(<AssignmentIcon />),
    isFor: ['admin', 'program_manager'],
  },
  {
    title: 'Reports',
    path: '/mentor/programs',
    icon: getIcon(<AssessmentIcon />),
    isFor: ['admin', 'program_manager', 'teaching_assistant'],
  },
  {
    title: 'Interactions',
    path: '/mentor/programs',
    icon: getIcon(<ChatIcon />),
    isFor: ['admin', 'program_manager'],
  },
  {
    title: 'Settings',
    path: '/mentor/programs',
    icon: getIcon(<SettingsIcon />),
    isFor: ['admin', 'program_manager'],
  },
  // {
  //   title: 'Schedule',
  //   path: `/mentor/programs`,
  //   icon: <Icon icon={calendarOutline} width={22} height={22} />
  // },
];

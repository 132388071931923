// @ts-nocheck
import React, { useEffect, useState } from 'react';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from '@mui/lab';
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import { Typography } from '@material-ui/core';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Stack, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 0.2,
    padding: 0,
    margin: 0,
    width: '60%',
    [theme.breakpoints.down('md')]: {
      width: '90%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      width: '80%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '60%',
    },
    display: 'flex',
    alignSelf: 'center',
  },
  timelineItem: {
    padding: 0,
    margin: 0,
    '&:before': {
      display: 'none',
    },
  },
  timelineDot: {
    padding: 0,
    borderStyle: 'none',
    margin: 0,
    backgroundColor: 'initial',
    boxShadow: 'none',
  },
  timelineDotProcessing: {},
  content: {
    padding: 0,
    margin: 0,
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const responsesSubtext = [
  {
    active: 'Your application is under review, please wait',
    inactive: 'Your application form has been reviewed.',
  },
  {
    active: 'Your career goals and aspirations are under review, please wait',
    inactive: "We've assessed your career goals and aspirations.",
  },
  {
    active:
      'Your performance on the fastrack screening test is under evaluation, please wait',
    inactive:
      'Your performance on the Fastrack screening test has been evaluated.',
  },
  {
    active:
      "Your counselor's feedback is being taken into account, please wait",
    inactive: "Your counselor's feedback has been taken into account.",
  },
];

const MAX_STEPS = 4;
const AnalyzingResponseAnimation = ({ report }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const [currentStep, setCurrentStep] = useState(0);
  const [steps, setSteps] = useState([
    report.eligibilityForm,
    report.conversationFlow,
    report.screeningTest,
  ]);

  // This handles the animation for each timeline item.
  useEffect(() => {
    if (currentStep < MAX_STEPS) {
      const timer = setTimeout(() => {
        setCurrentStep((prevStep) => prevStep + 1);
      }, 5500); // (5.5 seconds)

      return () => clearTimeout(timer);
    }
  }, [currentStep, steps]);

  const getStepHeading = (step) => {
    if (step === 0) {
      return 'Application form';
    }
    if (step === 1) {
      return 'Career aspirations';
    }
    if (step === 2) {
      return 'Fastrack screening test';
    }
    return 'Feedback from counsellor';
  };

  const getTimelineItems = () => {
    const items = [];
    let index = 0;

    while (index <= currentStep && index < MAX_STEPS) {
      const state = index == currentStep ? 'active' : 'inactive';

      items.push(
        <TimelineItem className={classes.timelineItem}>
          <TimelineSeparator>
            <TimelineDot className={classes.timelineDot}>
              {index >= currentStep ? (
                <Typography
                  sx={{
                    borderRadius: '50%',
                    border: '1px solid #1976d2',
                    padding: '0.3rem 0.8rem',
                    color: '#1976d2',
                  }}
                >
                  {index + 1}
                </Typography>
              ) : (
                <CheckCircleIcon
                  color='success'
                  fontSize='large'
                />
              )}
            </TimelineDot>
            <TimelineConnector
              sx={{
                bgcolor: index < currentStep ? '#54D62C' : '#bdbdbd',
                display: index + 1 === MAX_STEPS && 'none',
              }}
            />
          </TimelineSeparator>
          <TimelineContent
            style={{
              paddingTop: 0,
              paddingBottom: '1rem',
            }}
          >
            <Stack spacing={1.8}>
              <Typography
                variant='caption'
                component='p'
                sx={{
                  fontWeight: 'bolder',
                  fontSize: {
                    xs: '1.1rem',
                    sm: '1.2rem',
                    md: '1.4rem',
                  },
                  fontFamily: 'Inter',
                }}
              >
                {getStepHeading(index)}
              </Typography>

              {index === currentStep && index !== 3 && (
                <Roller responses={steps[index]} />
              )}

              <Typography
                variant={isMobile ? 'body2' : 'body1'}
                component='span'
                sx={{
                  fontFamily: 'Inter',
                }}
              >
                {responsesSubtext[index][state]}{' '}
                {state === 'active' && <DotConnectingLoader />}
              </Typography>
            </Stack>
          </TimelineContent>
        </TimelineItem>
      );

      index++;
    }

    return items;
  };

  return <Timeline className={classes.root}>{getTimelineItems()}</Timeline>;
};

export default AnalyzingResponseAnimation;

const DotConnectingLoader = () => {
  return (
    <>
      <span className='dot-flashing'></span>
      <span className='dot-flashing'></span>
      <span className='dot-flashing'></span>

      <style>
        {`
        @keyframes dotFlashing {
          0% {
            opacity: 1;
          }
          50%,
          100% {
            opacity: 0;
          }
        }

        .dot-flashing {
          margin-left: 2px;
          margin-right: 2px;
          width: 3px;
          height: 3px;
          background-color: black;
          border-radius: 50%;
          display: inline-block;
          animation: dotFlashing 1s infinite linear alternate;
        }

        .dot-flashing:nth-child(2) {
          animation-delay: 0.2s;
        }
        .dot-flashing:nth-child(3) {
          animation-delay: 0.4s;
        }

        `}
      </style>
    </>
  );
};

const Roller = ({ responses }) => {
  return (
    <>
      <Box className='wrapper'>
        <Box className='words'>
          {responses.map((response, index) => (
            <Typography
              variant='body1'
              component='p'
              key={index}
              className='span'
            >
              {`${response}`.substring(0, 50)}
            </Typography>
          ))}
        </Box>
      </Box>

      <style>
        {`
          .wrapper{
            overflow: hidden;
            height: 1.5em; /* Adjust based on your font size */
            display: inline-block;
            position: relative;
          }
          .words{
              overflow: hidden;
          }
          .span{
              display: block;
              height: 100%;
              font-style: 'Inter';
              font-weight: 500;
              color: #aa00ff;
              animation: spin_words 8s infinite;
          }
          @keyframes spin_words{
              10%{
                  transform: translateY(-112%);
              }
              25%{
                  transform: translateY(-100%);
              }
              35%{
                  transform: translateY(-212%);
              }
              50%{
                  transform: translateY(-200%);
              }
              60%{
                  transform: translateY(-312%);
              }
              75%{
                  transform: translateY(-300%);
              }
              85%{
                  transform: translateY(-412%);
              }
              100%{
                  transform: translateY(400%);
              }
          }
        `}
      </style>
    </>
  );
};

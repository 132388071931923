import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import './Topics.css';
import {
  fetchMindMapCompletedProblems,
  fetchMindMapTopicData,
  getHeaders,
} from '../../../../apis/urls';
import { mindmapTopics } from '../mindmapTopics';
import LoadingScreen from '../../../../pages/LoadingScreen';
import Circle from '../Atoms/Circle';
import TopicProgress from '../Molecules/TopicProgress';
import ProblemProgress from '../Molecules/ProblemProgress';

const Topics = ({ setSelectedTopic, handleFetchTopicDatas }) => {
  const [progress, setProgress] = useState(30);
  const [unlockedTopic, setUnlockedTopic] = useState(3);
  const [totalTopic, setTotalTopic] = useState(19);
  const [rotationAngle, setRotationAngle] = useState(24);
  const [loading, setLoading] = useState(true);
  const [topicStatusMappping, setTopicStatusMappping] = useState([]);
  const [completedProblemList, setCompletedProblemList] = useState([]);
  const [centeredTopic, setCenteredTopic] = useState(mindmapTopics[6]?.name);
  const currentProgram = useSelector((state) => state?.programs.currentProgram);
  const [totalProblemCounts, setTotalProblemCounts] = useState(0);
  const [solvedProblemsCount, setSolvedProblemsCount] = useState(0);
  const [solvedProblemsPercentage, setSolvedProblemsPercentage] = useState(0);
  const [lockedTopic, setLockedTopic] = useState(false);
  const [lastUnlockedTopic, setLastUnlockedTopic] = useState('');
  const [prevTopicName, setPrevTopicName] = useState('');
  const navigate = useNavigate();

  function checkProgramRange(currentProgram) {
    if (currentProgram >= 1 && currentProgram < 100) {
      return false;
    }

    return true;
  }

  const goToNextTopic = () => {
    const currentIndex = mindmapTopics.findIndex(
      (topic) => topic.name === centeredTopic
    );
    if (currentIndex < mindmapTopics.length - 2) {
      setPrevTopicName(mindmapTopics[currentIndex + 2].displayName);
    }
    const nextIndex = (currentIndex + 1) % mindmapTopics.length;
    setCenteredTopic(mindmapTopics[nextIndex].name);
  };

  const goToPreviousTopic = () => {
    const currentIndex = mindmapTopics.findIndex(
      (topic) => topic.name === centeredTopic
    );
    setPrevTopicName(mindmapTopics[currentIndex].displayName);
    const previousIndex =
      (currentIndex - 1 + mindmapTopics.length) % mindmapTopics.length;
    setCenteredTopic(mindmapTopics[previousIndex].name);
  };

  const rotateCircles = (direction) => {
    const increment = direction === 'right' ? -18.94 : 18.94;
    const centeredTopic =
      direction === 'right' ? goToPreviousTopic() : goToNextTopic();
    setRotationAngle((prevAngle) => prevAngle + increment);
  };

  const handleIncrement = () => {
    setProgress((prevProgress) =>
      prevProgress < 100 ? prevProgress + 10 : prevProgress
    );
  };

  const handleDecrement = () => {
    setProgress((prevProgress) =>
      prevProgress > 0 ? prevProgress - 10 : prevProgress
    );
  };

  useEffect(() => {
    let isMounted = true;

    const fetchCompletedProblems = async () => {
      const topicNamesArray = [
        'array',
        'string',
        'math',
        'searching and sorting',
        'divide and conquer',
        'linked list',
        'stack',
        'queue',
        'hash table',
        'bit manipulation',
        'recursion',
        'trees',
        'heap',
        'graphs',
        'trie',
        'greedy',
        'backtracking',
        'dynamic programming',
        'range query',
      ];

      try {
        const response = await axios.get(fetchMindMapCompletedProblems(), {
          headers: getHeaders(currentProgram.programId),
          params: { topics: topicNamesArray },
        });

        if (isMounted) {
          const lockedCount = response.data.filter(
            (item) => !item.is_locked
          ).length;
          const totalCount = response.data.length;
          const percentageLocked = (lockedCount / totalCount) * 100;

          setCompletedProblemList(response.data);
          setProgress(percentageLocked);
          setTotalTopic(totalCount);
          setUnlockedTopic(lockedCount);
          setLoading(false);
        }
      } catch (error) {
        console.error('Error:', error);
        setLoading(false);
      }
    };

    fetchCompletedProblems();

    return () => {
      isMounted = false;
    };
  }, [currentProgram.programId]);

  useEffect(() => {
    const completedTopic = completedProblemList.find(
      (item) => item.topic === centeredTopic
    );
    const isValid = checkProgramRange(currentProgram.programId);

    if (completedTopic) {
      if (!completedTopic.is_locked || !isValid) {
        setSolvedProblemsCount(completedTopic.solved_problems_count);
        setTotalProblemCounts(completedTopic.total_problems_count);
        const percentage =
          (completedTopic.solved_problems_count /
            completedTopic.total_problems_count) *
          100;
        setSolvedProblemsPercentage(percentage);
        setLockedTopic(false);
      } else {
        setLockedTopic(true);
      }

      const lastUnlocked = completedProblemList
        .filter((item) => !item.is_locked)
        .pop();
      if (lastUnlocked) {
        setLastUnlockedTopic(lastUnlocked.topic);
      }
    }
  }, [centeredTopic, completedProblemList]);

  const handleSelectedTopic = (topic) => {
    setSelectedTopic(topic);
    navigate(`mindmap/${topic}`);
  };

  const handleCircleBorderColor = (finalStatus, centeredTopic, topic) => {
    let borderStyle;
    if (centeredTopic === topic?.name) {
      if (finalStatus === true) {
        borderStyle = '6px solid #CFD0D4';
      } else {
        borderStyle = '6px solid #cce6f8';
        borderStyle = '6px solid #cce6f8';
      }
    }
    return borderStyle;
  };

  return (
    <div className='topics-container'>
      <LoadingScreen loading={loading} />
      <TopicProgress
        progress={progress}
        totalTopic={totalTopic}
        unlockedTopic={unlockedTopic}
        width={80}
        height={15}
      />
      <div className='rectangle-container'>
        {/* <div className='circle-container'> */}
        {mindmapTopics.map((topic, index) => {
          const topicStatus = completedProblemList.find(
            (status) => status.topic === topic.name
          )?.is_locked;

          const isValid = checkProgramRange(currentProgram.programId);

          const finalStatus = topicStatus && isValid;

          const lastTopic =
            index + 1 < mindmapTopics.length
              ? mindmapTopics[index + 1]?.displayName
              : '';
          return (
            <div
              className={`${
                finalStatus === true
                  ? 'circle-container-locked'
                  : 'circle-container'
              } `}
            >
              <Circle
                index={index}
                topic={topic}
                lastTopic={lastTopic}
                // handleClick={handleSelectTopic}
                handleClick={handleFetchTopicDatas}
                topicStatus={finalStatus}
                style={{
                  width: '150px',
                  height: '150px',
                  borderRadius: '50%',
                  position: 'absolute',
                  top: '180%',
                  left: '50%',
                  padding: '10px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'white',
                  fontSize: '14px',
                  border: handleCircleBorderColor(
                    finalStatus,
                    centeredTopic,
                    topic
                  ),
                  transform: `translate(-50%, -50%) rotate(${
                    rotationAngle + index * -18.94
                  }deg) translate(500px) rotate(${
                    -rotationAngle - index * -18.94
                  }deg)`,
                  textAlign: 'center',
                }}
              />
            </div>
          );
        })}
        {/* </div> */}
        <ProblemProgress
          progress={solvedProblemsPercentage}
          width={20}
          height={10}
          rotateCircles={rotateCircles}
          totalProblemCounts={totalProblemCounts}
          solvedProblemsCount={solvedProblemsCount}
          lockedTopic={lockedTopic}
          lastUnlockedTopic={lastUnlockedTopic}
          prevTopicName={prevTopicName}
        />
      </div>
    </div>
  );
};

export default Topics;

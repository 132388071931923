// @ts-nocheck
import {
  Box,
  Button,
  Chip,
  Divider,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import TargetLogo from '../../../assets/target.png';
import React from 'react';
import { useFastrackPipeline } from 'src/layouts/EfPipelineContext/FastrackPipelineContex';
import { tooltipTitle } from '.';

const GreatFitForCourse = ({ time, handleReadyToEnroll }) => {
  const { screenSource } = useFastrackPipeline();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClick = () => {
    handleReadyToEnroll({ source: screenSource });
  };

  return (
    <Stack
      alignItems='center'
      justifyContent='space-between'
      sx={{
        width: {
          xs: '19rem',
          sm: '20rem',
          md: '21rem',
          lg: '23rem',
          xl: '28%',
        },
        height: '24rem',
        border: '3px solid #e3f2fd',
        borderRadius: '0.8rem',
        padding: '1rem',
        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 1px 5px 0px',
        background: 'linear-gradient(#D0F2F4,#FFFFDD)',
      }}
    >
      <img
        style={{
          width: '85px',
        }}
        src={TargetLogo}
        alt='target-logo'
      />

      <Typography
        variant='h4'
        component='h4'
        sx={{
          fontFamily: 'Inter',
          fontWeight: 'bolder',
          paddingTop: 0,
          lineHeight: 'normal',
          textAlign: 'center',
        }}
      >
        I'm ready to enroll
      </Typography>

      <Typography
        variant='body1'
        component='p'
        sx={{
          fontFamily: 'Inter',
          fontWeight: 500,
          fontSize: '1.1rem',
          textAlign: 'center',
        }}
      >
        Reserve your seat now with a token payment before it's taken
      </Typography>

      <Tooltip
        title={tooltipTitle}
        arrow
        placement='bottom'
        componentsProps={{
          tooltip: {
            sx: {
              backgroundColor: '#FFF1C2',
              color: '#333',
              fontFamily: 'Inter',
              fontSize: '0.9rem',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
              padding: '0.5rem',
              borderRadius: '8px',
              textAlign: 'center',
            },
          },
          arrow: {
            sx: {
              color: '#FFF1C2',
            },
          },
        }}
      >
        <Button
          variant='contained'
          endIcon={<ArrowRightAltIcon fontSize='large' />}
          sx={{
            background: 'linear-gradient(90deg, #d16000, #ffb347)',
            color: 'white',
            fontFamily: 'Inter',
            '&:hover': {
              background: 'linear-gradient(90deg, #d16000, #ffb347)',
            },
            padding: '0.7rem 0.5rem',
            width: '80%',
            alignSelf: 'center',
            boxShadow: 'none',
          }}
          onClick={handleClick}
        >
          Release Offer Letter
        </Button>
      </Tooltip>
    </Stack>
  );
};

export default GreatFitForCourse;

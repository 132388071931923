// @ts-nocheck
import {
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import React, { useEffect, useState } from 'react';
import { useFastrackPipeline } from 'src/layouts/EfPipelineContext/FastrackPipelineContex';

const StrugglingAreasInPlacement = ({
  setFlowData,
  flowData,
  saveFlowData,
}) => {
  const { screenSource, setScreenSource, trackButtonClick } =
    useFastrackPipeline();

  const handleStrugglingAreasClick = (strugglingArea) => {
    setFlowData((prev) => {
      let tmp = [...(prev?.struggling_areas ?? [])];
      const entries = [];
      let found = false;
      tmp.forEach((t) => {
        if (t !== strugglingArea) {
          entries.push(t);
        } else {
          found = true;
        }
      });

      if (!found) {
        entries.push(strugglingArea);
      }
      return { ...prev, struggling_areas: entries };
    });
  };

  const isSelected = (strugglingArea) => {
    const strugglingAreas = flowData?.struggling_areas ?? [];
    return strugglingAreas.includes(strugglingArea);
  };

  const shouldShowNext = () => {
    const strugglingAreas = flowData?.struggling_areas ?? [];
    return strugglingAreas.length > 0;
  };

  const handleNext = () => {
    trackButtonClick({
      source: 'ps_effectiveness_2',
      eventName: 'next',
    });
    saveFlowData({
      screen: 'ps_effectiveness_2',
      response: { struggling_areas: flowData.struggling_areas },
    });
    setScreenSource('clearing_interview_1');
  };

  return (
    <Stack
      spacing={5}
      alignItems='center'
      textAlign='center'
      width='100%'
    >
      <Typography
        variant='h3'
        component='h3'
        sx={{
          color: '#1976d2',
          fontFamily: 'Inter',
          fontWeight: 'bolder',
          fontSize: {
            xs: '1.2rem',
            sm: '1.5rem',
            md: '1.6rem',
            lg: '1.75rem',
          },
          textAlign: 'center',
          textWrap: 'stable',
        }}
      >
        When it comes to preparing for placements, which areas are you
        struggling the most?
      </Typography>

      <Typography
        variant='body1'
        component='p'
        sx={{
          textAlign: 'center',
          fontFamily: 'Inter',
          fontWeight: 'normal',
          fontSize: {
            xs: '1rem',
            sm: '1.1rem',
            md: '1.2rem',
            lg: '1.3rem',
          },
          textWrap: 'stable',
          color: '#767676',
          paddingBottom: '1rem',
        }}
      >
        select the most relevant option—you can always come back to this screen
        later
      </Typography>

      <Stack
        spacing={5}
        sx={{
          maxWidth: '25rem',
          width: {
            xs: '100%',
            sm: '70%',
            md: '50%',
            lg: '50%',
          },
          paddingBottom: '1rem',
        }}
      >
        <Button
          sx={{
            color: '#1976d2',
            width: '100%',
            padding: '0.5rem 1rem',
            textTransform: 'none',
            fontFamily: 'Inter',
            fontSize: {
              xs: '1rem',
              sm: '1rem',
              md: '1.2rem',
              lg: '1.2rem',
            },
            fontWeight: 650,
            borderColor: '#1976d2',
            ...(isSelected('Clearing interview rounds') && {
              backgroundColor: '#1976d2',
              color: 'white',
              '&:hover': {
                backgroundColor: '#1976d2',
                color: 'white',
              },
            }),
          }}
          onClick={() =>
            handleStrugglingAreasClick('Clearing interview rounds')
          }
          variant='outlined'
        >
          Clearing interview rounds
        </Button>

        {/* Note: Commented out until the flow is ready. */}
        {/* <Button
          sx={{
            color: '#1976d2',
            width: '100%',
            padding: '0.5rem 1rem',
            textTransform: 'none',
            fontFamily: 'Inter',
            fontSize: {
              xs: '1rem',
              sm: '1rem',
              md: '1.2rem',
              lg: '1.2rem',
            },
            fontWeight: 650,
            borderColor: '#1976d2',
          }}
          onClick={() => handleStrugglingAreasClick('Low Recruiter Visibility')}
          variant='outlined'
        >
          Low Recruiter Visibility
        </Button> */}
      </Stack>

      <Button
        sx={{
          width: 'unset',
          padding: '0.5rem 1.5rem',
          textTransform: 'none',
          fontFamily: 'Inter',
          fontSize: '1rem',
          fontWeight: 650,
          '&:hover': {
            color: 'white',
            backgroundColor: '#1976d2',
          },
          color: 'white',
          backgroundColor: '#1976d2',
        }}
        onClick={handleNext}
        disabled={!shouldShowNext}
        endIcon={<EastIcon />}
      >
        Next
      </Button>
    </Stack>
  );
};

export default StrugglingAreasInPlacement;

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { getHeaders, ticketsOverview } from 'src/apis/urls';
import './TicketOverview.css'; // Make sure to create and include this CSS file

function TicketOverview() {
  const [openTickets, setOpenTickets] = useState(1);

  useEffect(() => {
    axios
      .get(ticketsOverview(), { headers: getHeaders() })
      .then((res) => {
        setOpenTickets(res?.data?.count);
      })
      .catch((error) => {
        console.error('There was an error fetching the ticket data!', error);
      });
  }, []);

  return (
    <div className='ticket-overview-container'>
      {openTickets > 0 ? (
        <div className='ticket-alert'>
          <span className='ticket-count'>{openTickets}&nbsp;</span> Tickets are
          open
        </div>
      ) : (
        <div className='congratulations'>
          <div className='message'>
            Congratulations! You've resolved all tickets. 🎉🎉🎉
          </div>
        </div>
      )}
    </div>
  );
}

export default TicketOverview;

import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import axios from 'axios';
import { deleteMessage, getHeaders } from '../../apis/urls';

export default function DeleteMessageConfirmation({
  message,
  openConfirmationDialog,
  setOpenConfirmationDialog,
  setDeleteMessageStatus,
}) {
  const handleDeleteMessage = () => {
    setOpenConfirmationDialog(false);
    setDeleteMessageStatus('Deleting...');
    axios
      .delete(deleteMessage(message.id), {
        headers: getHeaders(),
      })
      .then(() => {
        setDeleteMessageStatus('Deleted');
        setTimeout(() => {
          setDeleteMessageStatus('');
        }, 1000);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <Dialog
      open={openConfirmationDialog}
      onClose={() => setOpenConfirmationDialog(false)}
    >
      <DialogTitle>Delete Message</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete the message?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenConfirmationDialog(false)}>Cancel</Button>
        <Button
          onClick={handleDeleteMessage}
          autoFocus
        >
          Yes, I am sure
        </Button>
      </DialogActions>
    </Dialog>
  );
}

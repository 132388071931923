import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
    Box,
    Card,
    CardContent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@material-ui/core';
import axios from 'axios';
import { ArrowBack, BookOutlined } from '@mui/icons-material';
import Paper from '@mui/material/Paper';
import { DirectUpload } from 'activestorage';
import EventIcon from '@mui/icons-material/Event';
import ErrorNotifier from '../../ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../ToastNotifications/SuccessNotifier';
import {
    fetchActiveStorageUrl,
    getHeaders,
    submitAssignmentUrl,
    fetchAssignmentSubmissions
} from '../../../apis/urls';
import SubmitModal from './SubmitModal';
import ViewSubmission from './ViewSubmission';


const DetailedAssign = ({ setDetailed, Detailed, AssignData }) => {
    const [Submissions, setSubmissions] = useState();
    const currentProgram = useSelector(state => state.programs.currentProgram);
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [hasNewSubmission, setHasNewSubmission] = useState(false);
    
    useEffect(() => {
        fetchAssignmentDetails();
        return () => {};
    }, [hasNewSubmission]);

    const fetchAssignmentDetails = () => {
        axios.get(fetchAssignmentSubmissions(AssignData?.id), {
            headers: getHeaders(currentProgram.programId)
        })
            .then((res) => res.data)
            .then((data) => {
                setSubmissions(data?.assignment_submissions);
            })
            .catch((err) => {
                console.log(err);
                setHasError(true);
                setErrorMessage(err.response?.data?.message || "Could not fetch the submissions");
            })
    };

    return (
        <>
            {hasError
            ? <ErrorNotifier message={errorMessage} setHasError={setHasError}/>
            : ""
            }
            <Card sx={{ display: 'flex', margin: 2 }}>
                <Box
                    style={{
                        width: '100%'
                    }}
                >
                    <CardContent>
                        <Box
                            style={{
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                            component="div"
                        >
                            <Box
                                style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <ArrowBack
                                    style={{ padding: 2, color: 'blue', cursor: 'pointer' }}
                                    onClick={() => setDetailed(!Detailed)}
                                />
                                <Typography
                                    component="div"
                                    variant="body2"
                                    color="blue"
                                    onClick={() => setDetailed(!Detailed)}
                                    style={{
                                        cursor: 'pointer'
                                    }}
                                >
                                    Back to All Assignments
                                </Typography>
                            </Box>
                            {AssignData && (
                                <DescriptiveAssign 
                                    submission={Submissions}
                                    AssignData={AssignData}
                                    setHasNewSubmission={setHasNewSubmission}
                                />
                            )}
                        </Box>
                    </CardContent>
                </Box>
            </Card>
        </>
    );
};

export default DetailedAssign;

export function DisplayLine({ line, index }) {
    const alnum = /^[0-9a-zA-Z]+$/;
    const hyperLink = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/; // eslint-disable-line
    const splittedLine = line.split(" ");
    
    function formatLine(str) {
        if (hyperLink.test(str)) {
            return (<a href={str} target="_blank" rel="noreferrer noopener">{` ${str}`}</a>);
        }
        return <span>{` ${str}`}</span>
    }

    return (
        <Typography key={index} variant="p" component="p">
            {
                splittedLine.map((str, index) => {
                    return formatLine(str)
                })
            }
        </Typography>
    );
}

function DescriptiveAssign({ AssignData, submission, setHasNewSubmission }) {
    const [open, setopen] = useState(false);
    const [viewOpen, setviewOpen] = useState(false);
    const [file, setfile] = useState();
    const handleOpen = (setopen) => setopen(true);
    const handleClose = (setopen) => setopen(false);
    const { title, deadline, description } = AssignData;
    const date = new Date(deadline).toDateString();
    const [Active, setActive] = useState();
    const [score, setscore] = useState();
    const [StatusCnt, setStatusCnt] = useState(0);
    const [isSuccess, setIsSuccess] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const currentProgram = useSelector(state => state.programs.currentProgram);

    async function GetHighestScore() {
        if (submission) {
            let count = 0;
            const highest = Math.max(
                ...submission?.map((o) => {
                    if (o?.score) {
                        count += 1;
                    }
                    return o?.score;
                })
            );
            setStatusCnt(count);
            const highestdataObj = submission.filter((i) => {
                return i?.score === highest;
            });

            setscore(highestdataObj[0]?.score);
        }
    }

    useEffect(() => {
        GetHighestScore();
        return () => {};
    }, [submission, open]);

    const handleFormSubmit = (e) => {
        e.preventDefault();
        if (!file) {
            setHasError(true);
            setErrorMessage("Please upload a file first.");
            return;
        }
        const upload = new DirectUpload(file, fetchActiveStorageUrl());
        upload.create((error, blob) => {
            if (error) {
                setHasError(true);
                setErrorMessage("something went wrong while submitting the assignment");
            }
            else {
                const uploadbody = { submission: blob?.signed_id };
                fetch(submitAssignmentUrl(AssignData?.id), {
                    method: 'POST',
                    headers: getHeaders(currentProgram.programId),
                    body: JSON.stringify(uploadbody)
                })
                .then((res) => {
                    setIsSuccess(true);
                    setHasNewSubmission(true);
                    setopen(false);
                    setfile();
                })
                .catch((error) => {
                    console.log(error);
                    setopen(false);
                    setHasError(true);
                    setErrorMessage(error.message);
                });
            }
        });
    };

    return (
        <Box
            style={{
                display: 'flex',
                flexDirection: 'column',
                padding: 10
            }}
            component="div"
        >
            {isSuccess
            ? <SuccessNotifier message="submitted successfully" setIsSuccess={setIsSuccess}/>
            : ""
            }
            {hasError
            ? <ErrorNotifier message={errorMessage} setHasError={setHasError}/>
            : ""
            }
            <SubmitModal
                open={open}
                handleClose={() => handleClose(setopen)}
                file={file}
                submit={handleFormSubmit}
                setfile={setfile}
            />
            <ViewSubmission
                open={viewOpen}
                ActiveData={Active}
                handleClose={() => {
                    handleClose(setviewOpen);
                }}
            />
            <Box
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    paddingBottom: 5
                }}
                component="div"
            >
                <Typography component="div" variant="h5">
                    {title}
                </Typography>
                <Typography
                    component="div"
                    variant="body2"
                    style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <EventIcon
                        style={{
                            marginRight: 2
                        }}
                    />
                    {date}
                </Typography>
            </Box>
            <CardContent
                style={{
                    backgroundColor: '#d5e1f7',
                    borderRadius: 5,
                    paddingBottom: 10
                }}
            >
                <Box
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}
                >
                    <Box component="div">
                        <Typography component="div" variant="body2">
                            {submission?.length > 0 && StatusCnt
                                ? `${StatusCnt} out of ${submission?.length} are graded`
                                : ''}
                        </Typography>
                        <Typography
                            component="div"
                            variant="body1"
                            color="blue"
                            style={{cursor: "pointer"}}
                            onClick={() => handleOpen(setopen)}
                        >
                          Upload Your Assignment
                        </Typography>
                    </Box>
                    <Box
                        component="div"
                        style={{
                            paddingTop: 2
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-around',
                                borderWidth: 4,
                                padding: 5
                            }}
                        >
                            {score ? `${score} / 100` : ''}
                        </div>
                    </Box>
                </Box>
            </CardContent>
            <Box
                style={{
                    padding: 2
                }}
            >
                <Typography
                    component="div"
                    variant="body2"
                    style={{
                        paddingTop: 10,
                        paddingBottom: 10
                    }}
                >
                    {description.split("\n").map((str, index) => {
                        return <DisplayLine key={index+1} line={str} index={index}/> 
                    })}
                </Typography>
                {submission && submission?.length > 0 ? (
                    <TableForSubmission
                        submissions={submission}
                        handleOpen={(INDEX) => {
                            handleOpen(setviewOpen);
                            setActive(submission[INDEX]);
                        }}
                    />
                ) : (
                    <Box
                        style={{
                            padding: 10,
                            fontWeight:"bold",
                            textAlign:"center"
                        }}
                    >
                        No Submissions Yet
                    </Box>
                )}
            </Box>
        </Box>
    );
}

function TableForSubmission({ submissions, handleOpen }) {
  const [tableColumns, setTableColumns] = useState([
    "Submitted On",
    "Status"
  ]);
  const [hasScoredSubmission, setHasScoredSubmission] = useState(false);

  useEffect(() => {
    let tmpHasScoredSubmission = false;
    submissions.forEach(submission => {
      if (submission.score) {
        tmpHasScoredSubmission = true;
      }
    });

    if (tmpHasScoredSubmission) {
      setTableColumns(prev => [
        ...prev, 
        "Score",
        "Remark"
      ]);
    }
    setHasScoredSubmission(tmpHasScoredSubmission);

    return () => {};
  }, []);

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Sno.</TableCell>
                        <TableCell>View</TableCell>
                        {tableColumns.map(column => {
                          return (
                            <TableCell 
                              align="right"
                            >
                              {column}
                            </TableCell>
                          )
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {submissions?.map((row, index) => {
                        const date = new Date(row?.created_at).toDateString();
                        return (
                            <TableRow
                                key={index}
                                sx={{ 
                                  '&:last-child td, &:last-child th': { 
                                    border: 0 
                                  } 
                                }}
                            >
                                <TableCell>{index + 1}</TableCell>
                                <TableCell
                                    component="th"
                                    scope="row"
                                    style={{
                                        color: 'blue',
                                        cursor: "pointer"
                                    }}
                                    onClick={(e) => handleOpen(index)}
                                >
                                    View Submission
                                </TableCell>
                                <TableCell align="right">{date}</TableCell>
                                <TableCell align="right">
                                    {' '}
                                    {row?.score ? 'Graded' : 'Submitted'}{' '}
                                </TableCell>

                                {hasScoredSubmission && 
                                  <>
                                    <TableCell align="right">
                                        {' '}
                                        {row?.score ? ` ${row?.score}` : 'N/A'}
                                    </TableCell>
                                    <TableCell align="right">
                                        {row?.feedback ? row?.feedback : 'N/A'}{' '}
                                    </TableCell>    
                                  </>
                                }
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

import React, { useState, useEffect } from 'react';
import { Box, MenuItem, Select, TextField } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import LoadingButton from '@mui/lab/LoadingButton';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { getHeaders, updatePlacementRequestStatus } from '../../../apis/urls';

const columns = [
  {
    field: 'name',
    headerName: 'Learner Name',
    width: 160,
    align: 'center',
    headerAlign: 'center',
    type: 'number',
    paddingLeft: '25px',
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 160,
    align: 'center',
    headerAlign: 'center',
    paddingLeft: '25px',
  },
  {
    field: 'program',
    headerName: 'Program',
    width: 160,
    align: 'center',
    headerAlign: 'center',
  },
  {
    field: 'role',
    headerName: 'Role',
    width: 160,
    align: 'center',
    headerAlign: 'center',
    type: 'singleSelect',
    editable: true,
  },
  {
    field: 'company',
    headerName: 'Company',
    width: 160,
    align: 'center',
    headerAlign: 'center',
  },
];

const ContactRequests = ({
  SuccessNotifier,
  ErrorNotifier,
  placementRequestsData,
  loading,
}) => {
  const [editedRows, setEditedRows] = useState([]);
  const [updatedStatus, setUpdatedStatus] = useState({});
  const [perPage, setPerPage] = useState(50);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [showLoaderInButton, setShowLoaderInButton] = useState(false);
  const currentProgram = useSelector(
    (state) => state?.programs?.currentProgram
  );
  const allowedStatusTransitions = {
    requested: ['approved', 'rejected'],
    rejected: ['approved'],
    approved: ['contact_viewed'],
  };

  const handleStatusAndRejectionReasonUpdate = (row, field, value) => {
    if (field === 'status') {
      const allowedTransitions = allowedStatusTransitions[row.status];

      if (!allowedTransitions || !allowedTransitions.includes(value)) {
        setHasError(true);
        setErrorMessage('Invalid status transition');
        return;
      }
    }
    setUpdatedStatus((prev) => {
      const placementRequestId = placementRequestsData[row.id - 1]?.id;

      const updatedData = {
        id: placementRequestId,
        [field]: value,
      };

      if (!Array.isArray(prev)) {
        prev = [];
      }

      let existingIndex = -1;

      for (let i = 0; i < prev.length; i += 1) {
        if (prev[i].id === placementRequestId) {
          existingIndex = i;
          break;
        }
      }

      if (existingIndex !== -1) {
        prev[existingIndex] = {
          ...prev[existingIndex],
          [field]: value,
        };
      } else {
        prev.push(updatedData);
      }

      return [...prev];
    });

    row[field] = value;
  };

  const handleSave = async () => {
    setShowLoaderInButton(true);
    try {
      await axios.patch(updatePlacementRequestStatus(), updatedStatus, {
        headers: getHeaders(currentProgram?.programId),
      });
      setIsSuccess(true);
      setSuccessMessage('Action status updated successfully');
      setEditedRows([]);
      setUpdatedStatus({});
      setShowLoaderInButton(false);
    } catch (error) {
      setHasError(true);
      setErrorMessage('Error updating action status');
      console.error(error);
      setShowLoaderInButton(false);
    }
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      {isSuccess && (
        <SuccessNotifier
          message={successMessage}
          setIsSuccess={setIsSuccess}
        />
      )}
      <Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              padding: '0 0 10px 0',
            }}
          >
            <LoadingButton
              loading={showLoaderInButton}
              variant='contained'
              onClick={handleSave}
              disabled={Object.keys(updatedStatus).length === 0}
            >
              Save
            </LoadingButton>
          </Box>
        </Box>

        <Box>
          <DataGrid
            rows={placementRequestsData}
            columns={[
              ...columns,
              {
                field: 'status',
                headerName: 'Status',
                width: 200,
                align: 'center',
                headerAlign: 'center',

                renderCell: (params) => {
                  return (
                    <Select
                      value={params.value}
                      sx={{
                        height: '40px',
                        width: '160px',
                      }}
                      onChange={(e) =>
                        handleStatusAndRejectionReasonUpdate(
                          params.row,
                          'status',
                          e.target.value
                        )
                      }
                    >
                      <MenuItem value='requested'>requested</MenuItem>
                      <MenuItem value='approved'>approved</MenuItem>
                      <MenuItem value='rejected'>rejected</MenuItem>
                      <MenuItem value='contact_viewed'>contact viewed</MenuItem>
                    </Select>
                  );
                },
              },
              {
                field: 'rejection_reason',
                headerName: 'Internal Rejection Reason',
                width: 200,
                align: 'center',
                headerAlign: 'center',
                renderCell: (params) => {
                  return (
                    <TextField
                      value={params.value}
                      onChange={(e) =>
                        handleStatusAndRejectionReasonUpdate(
                          params.row,
                          'rejection_reason',
                          e.target.value
                        )
                      }
                      onKeyDown={(e) => {
                        if (e.key === ' ') {
                          e.preventDefault();
                        }
                      }}
                    />
                  );
                },
              },
            ]}
            checkboxSelection
            isRowSelectable={(params) => params.row.quantity > 50000}
            rowsPerPageOptions={[50, 100]}
            onPageSizeChange={(page) => setPerPage(page)}
            pagination
            loading={loading}
            autoHeight
            disableSelectionOnClick
          />
        </Box>
      </Box>
    </>
  );
};

export default ContactRequests;

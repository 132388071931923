// @ts-nocheck
import { Circle } from '@mui/icons-material';
import { Box, IconButton, Popover } from '@mui/material';
import React, { useState } from 'react';
import { CirclePicker } from 'react-color';

function ColorPicker({ handleChange, color, colorsArray }) {
  const [showPicker, setShowPicker] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePickerClick = (event) => {
    setShowPicker(!showPicker);
    setAnchorEl(event.currentTarget);
  };

  const handleClosePicker = (e) => {
    e.stopPropagation();
    setShowPicker(false);
  };

  return (
    <Box>
      <IconButton onClick={handlePickerClick}>
        <Circle sx={{ color }} />
      </IconButton>
      <Popover
        open={showPicker}
        anchorEl={anchorEl}
        onClose={handleClosePicker}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div style={{ padding: '10px', backgroundColor: 'lightgray' }}>
          <CirclePicker
            color={color}
            onChange={handleChange}
            colors={colorsArray ?? undefined}
          />
        </div>
      </Popover>
    </Box>
  );
}

export default ColorPicker;

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Collapse, Tab } from '@material-ui/core';
import { Box } from '@mui/material';
import { TabList } from '@material-ui/lab';
import { useNavigate } from 'react-router-dom';
import { slugify } from '../utils/common';
import './TabLayout.css';
import { topBarContents } from './StudentNavbarData';

import {
  shouldShowNewStudentLayout,
  shouldShowNewSessionsUI,
} from '../utils/common';

const ChildTabLayout = ({
  index,
  subTabValue,
  setSubTabValue,
  children,
  open,
  value,
}) => {
  const [showNewLayout, setShowNewLayout] = useState(null);
  const navigate = useNavigate();
  const currentProgram = useSelector((state) => state.programs?.currentProgram);
  const programName = currentProgram?.programSlug;
  const programId = currentProgram?.programId;

  useEffect(() => {
    const newLayoutFlag = shouldShowNewStudentLayout(currentProgram.programId);
    setShowNewLayout(newLayoutFlag);
  }, [currentProgram]);

  const [shouldShowOldAssignmentsTab, setShouldShowOldAssignmentsTab] =
    useState(programId >= 85 && programId !== 89);

  const handleSelectedSubTab = (subTabIndex) => {
    setSubTabValue(subTabIndex);
    navigate(
      `/student/${programName}/${slugify(topBarContents[value].key)}/${slugify(
        topBarContents[value].children[subTabIndex].key
      )}`
    );
  };

  return (
    <>
      <Collapse
        in={open[index]}
        timeout='auto'
        unmountOnExit
      >
        <TabList
          orientation='vertical'
          value={subTabValue}
          indicatorColor='none'
          textColor='none'
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              height: '100%',
              gap: '5px',
            }}
          >
            {children.map((subTab, subIndex) => {
              if (!subTab) {
                return null;
              }
              if (
                subTab.key === 'session-tests' ||
                subTab.key === 'coding-assignments'
              ) {
                if (shouldShowNewSessionsUI(programId)) {
                  return null;
                }
              }
              if (subTab.key === 'assignments' && shouldShowOldAssignmentsTab) {
                return null;
              }
              // Check if the label is 'Technique Saga' then only display for given batch
              /**
               * conditionally render the new styling of the child tabs for only the demo
               * For rest of the prorams, it stays the same.
               */
              return showNewLayout ? (
                <Tab
                  key={subIndex}
                  label={subTab?.label}
                  value={subIndex}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    fontWeight: '500',
                    alignItems: 'start',
                    color: 'black',
                    borderRadius: '10px',
                    backgroundColor:
                      subIndex.toString() === subTabValue ||
                      subIndex === subTabValue
                        ? '#fff'
                        : 'tranaparent',
                  }}
                  onClick={() => handleSelectedSubTab(subIndex)}
                />
              ) : (
                <Tab
                  key={subIndex}
                  label={subTab?.label}
                  value={subIndex}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    fontWeight: '500',
                    alignItems: 'start',
                    color: 'black',
                    borderRadius: '10px',
                    backgroundColor:
                      subIndex.toString() === subTabValue ||
                      subIndex === subTabValue
                        ? '#ABE1FF'
                        : '#fff',
                    '&:hover': {
                      backgroundColor: '#ABE1FF',
                    },
                  }}
                  onClick={() => handleSelectedSubTab(subIndex)}
                />
              );
            })}
          </Box>
        </TabList>
      </Collapse>
    </>
  );
};

export default ChildTabLayout;

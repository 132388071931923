import React, { useState } from 'react';
import { Box, Button, Tabs, Tab, Typography } from '@material-ui/core';
import CustomTabPanel from '../TechniqueSaga/BitManipulation/CustomTabPanel';
import { NewBooksResourcesContent } from './ResourcesContent';
import TabsComponent from './TabsComponent ';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const NewResourcesTab = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleBookClick = (link) => {
    window.open(link, '_blank');
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      gap={4}
    >
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='basic tabs example'
          sx={{
            '& .MuiTabs-indicator': { height: '0' },
            '& .Mui-selected': { backgroundColor: 'white', color: '#367aff ' },
            background: '#ABE1FF',
            borderRadius: '32px',
            padding: '5px',
            minHeight: '0',
          }}
        >
          <Tab
            sx={{
              borderRadius: '32px',
              padding: '10px',
              minHeight: '0',
            }}
            label='Books'
            {...a11yProps(0)}
          />
          <Tab
            sx={{
              borderRadius: '32px',
              padding: '0',
              minHeight: '0 !important',
            }}
            label='Blogs'
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>

      <CustomTabPanel
        value={value}
        index={0}
        width='100%'
      >
        <Box
          display='flex'
          flexWrap='wrap'
          gap='30px'
          paddingX='30px'
        >
          {NewBooksResourcesContent.map((content, index) => (
            <Box
              key={index}
              sx={{
                borderRadius: '10px',
                border: '1px solid black',
                width: 'calc((100% - 90px)/4)',
                padding: '10px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '20px',
                cursor: 'pointer',
              }}
              onClick={() => handleBookClick(content.link)}
            >
              <img
                src={content.image}
                alt='book_image'
                style={{ width: '175px', height: '250px' }}
              />

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '18px',
                    lineHeight: '22px',
                    fontWeight: '700',
                    textAlign: 'center',
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 2,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {content.name}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: '500',
                    textAlign: 'center',
                  }}
                >
                  {content.author}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </CustomTabPanel>

      <CustomTabPanel
        value={value}
        index={1}
        width='100%'
      >
        <Box
          display='flex'
          flexDirection='column'
          gap='30px'
          width='100%'
        >
          <TabsComponent />
        </Box>
      </CustomTabPanel>
    </Box>
  );
};

export default NewResourcesTab;

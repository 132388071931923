export default {
  'Building blocks and basic algorithms':
    'Explore the foundational elements of computer science, including essential algorithms and sorting techniques that are crucial for tech interviews.',
  'Basic data structures':
    'Gain proficiency in using various data structures to solve programming challenges effectively and efficiently.',
  'Dynamic programming':
    'Unlock the strategies of dynamic programming to solve complex problems more efficiently by breaking them down into simpler subproblems.',
  'Advanced data structures':
    'Delve deeper into complex data structures like trees, heaps, and graphs to manage and manipulate high-level data.',
  Miscellaneous:
    'This category includes various topics that enrich the learning experience with insights into the real world and interview processes.',
  'Hiring manager round':
    'This module focuses on preparing candidates for the crucial hiring manager round of interviews, emphasizing strategic communication, problem-solving, and decision-making skills that align with managerial expectations.',
  'Graph algorithms':
    'Master the essential graph algorithms to navigate complex network problems, including traversal methods and optimization strategies.',
  'Low Level Design':
    'Develop a solid foundation in low-level design principles to craft scalable and efficient software architectures.',
  'High level design concepts':
    'Broaden your understanding of high-level design concepts critical for building scalable and robust systems.',
  'High level system design interviews':
    'Prepare for high-level system design interviews with comprehensive strategies and real-world problem-solving techniques.',
  'MAANG system design interviews':
    'Focus on system design interview preparations tailored for top tech companies like MAANG, emphasizing complex scenarios and critical thinking.',
  'Advanced concepts for eligible learners':
    'Explore specialized topics designed for advanced learners aiming to deepen their knowledge in competitive programming and complex algorithms.',
  'Profile Presentation':
    'Enhance your professional presentation skills, preparing for interviews and crafting engaging profiles.',
};

import React from 'react';
import './CouponPopover.css'; // Import the CSS file for styling
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const CouponPopover = () => {
  const navigate = useNavigate();
  const currentProgram = useSelector((state) => state.programs.currentProgram);
  localStorage.setItem('showedCouponPopover', 'true');

  return (
    <div className='coupon-card'>
      <div className='coupon-card-image'>
        <img
          src='/static/offers.png'
          alt='Gift Box'
        />
      </div>
      <h1>Congratulations! You've won exclusive coupons 🎉</h1>
      <p>Special discounts and offers from our partner companies.</p>
      <p>You can find your coupons in the profile section.</p>
      <button
        className='coupon-card-button'
        style={{ marginTop: '10px' }}
        onClick={() => {
          navigate(`/student/${currentProgram?.programSlug}/coupons`);
        }}
      >
        View my coupons
      </button>
    </div>
  );
};

export default CouponPopover;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { TabContext } from '@material-ui/lab';
import { Box } from '@material-ui/system';
import { Outlet, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { getUserType, getUserId } from '../../configs/auth';
import { slugify } from '../utils/common';
import {
  fetchProgramSessionsUrl,
  getHeaders,
  getLastResponseTime,
} from '../../apis/urls';
import FeedbackForm from '../feedback/FeedbackForm';
import SignatureFrameworkUnlocked from '../modal/SignatureFrameworkUnlocked';
import './TabLayout.css';
import { topBarContents } from './StudentNavbarData';
import DividerComponent from './DividerComponent';
import MainTabLayoutV2 from './MainTabLayoutV2';
import TabRenderV2 from './TabRenderV2';
import ReferalModal from '../feedback/ReferalModal';
import FrustrationLevelModal from '../feedback/FrustrationLevelModal';
import FeedbackButton from '../feedback/FeedbackButton';
import FirstFormModal from '../feedback/FirstFormModal';

const TabLayoutV2 = () => {
  const userType = getUserType();
  const navigate = useNavigate();
  const [value, setValue] = useState('0');
  const [subTabValue, setSubTabValue] = useState('0');
  const currentProgram = useSelector((state) => state.programs?.currentProgram);
  const programName = currentProgram?.programSlug;
  const [programSessions, setProgramSessions] = useState([]);
  const [lastSubmittedFormResponse, setLastSubmittedFormResponse] =
    useState(null);
  const [open, setOpen] = useState(false);
  const onClose = () => setOpen(false);
  const dispatch = useDispatch();
  const hasIntroducedSignatureFramework = localStorage.getItem(
    'hasIntroducedSignatureFramework'
  );
  const [showSignatureFramework, setShowSignatureFramework] = useState(
    !(hasIntroducedSignatureFramework ?? false)
  );
  const [isModalOpen, setIsModalOpen] = useState(true);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const [showFrustrationForm, setShowFrustrationForm] = useState(false);
  const [showFirstForm, setShowFirstForm] = useState(false);
  const [showFeedbackButton, setShowFeedbackButton] = useState(false);
  const [showFeedbackState, setShowFeedbackState] = useState('');

  useEffect(() => {
    getLastResponse();
  }, [currentProgram]);

  useEffect(() => {
    if (programName)
      navigate(`/student/${programName}/${slugify(topBarContents[0].key)}`);
  }, [programName]);

  useEffect(() => {
    if (lastSubmittedFormResponse) {
      setShowFrustrationForm(lastSubmittedFormResponse?.show_frustration_modal);
      setShowFirstForm(lastSubmittedFormResponse?.show_first_form);
    }
  }, [lastSubmittedFormResponse]);

  const getLastResponse = () => {
    // API call for getting last response time
    axios
      .get(getLastResponseTime(), {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((res) => {
        setLastSubmittedFormResponse(res?.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleFeedbackButtonClick = () => {
    if (showFeedbackState === 'FirstForm') {
      setShowFirstForm(true);
    }
    if (showFeedbackState === 'FrustrationModal') {
      setShowFrustrationForm(true);
    }
  };

  if (showSignatureFramework && userType === 'student') {
    return (
      <>
        <SignatureFrameworkUnlocked
          setShowSignatureFramework={setShowSignatureFramework}
          showSignatureFramework={showSignatureFramework}
        />
      </>
    );
  }

  return (
    <>
      {lastSubmittedFormResponse?.show_referal && (
        <ReferalModal
          open={isModalOpen}
          onClose={handleCloseModal}
          currentProgram={currentProgram}
        />
      )}

      {lastSubmittedFormResponse?.show_form && (
        <FeedbackForm
          open={open}
          onClose={onClose}
          lastSubmittedFormResponse={lastSubmittedFormResponse}
          setLastSubmittedFormResponse={setLastSubmittedFormResponse}
        />
      )}

      {lastSubmittedFormResponse?.show_frustration_modal && (
        <FrustrationLevelModal
          showFrustrationForm={showFrustrationForm}
          setShowFrustrationForm={setShowFrustrationForm}
          lastSubmittedFormResponse={lastSubmittedFormResponse}
          setLastSubmittedFormResponse={setLastSubmittedFormResponse}
          setShowFeedbackButton={setShowFeedbackButton}
          setShowFeedbackState={setShowFeedbackState}
          currentProgram={currentProgram}
        />
      )}
      {lastSubmittedFormResponse?.show_first_form && (
        <FirstFormModal
          showFirstForm={showFirstForm}
          setshowFirstForm={setShowFirstForm}
          setShowFeedbackButton={setShowFeedbackButton}
          setShowFeedbackState={setShowFeedbackState}
          currentProgram={currentProgram}
        />
      )}
      <TabContext value={value}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            height: '100vh',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              height: '100%',
              marginLeft: '245px',
            }}
          >
            <MainTabLayoutV2
              open={open}
              setOpen={setOpen}
              value={value}
              setValue={setValue}
              subTabValue={subTabValue}
              setSubTabValue={setSubTabValue}
            />
            {showFeedbackButton && (
              <FeedbackButton onClick={handleFeedbackButtonClick} />
            )}
          </Box>
          <TabRenderV2
            value={value}
            subTabValue={subTabValue}
          />
        </Box>
      </TabContext>
    </>
  );
};

export default TabLayoutV2;

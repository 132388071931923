import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/system';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Stack, Typography } from '@material-ui/core';
import axios from 'axios';
import {
  fetchCoachSessionsURL,
  getHeaders,
  mentorSessionsURL,
} from 'src/apis/urls';
import { useSelector } from 'react-redux';
import moment from 'moment';
import MentorSessionCoachModal from './MentorSessionCoachModal';
import ErrorNotifier from 'src/components/ToastNotifications/ErrorNotifier';
import SuccessNotifier from 'src/components/ToastNotifications/SuccessNotifier';
import { getUserId } from 'src/configs/auth';

export default function MentorSessionCoach() {
  const [data, setData] = useState([]);
  const [openMentorSessionCoachModal, setOpenMentorSessionCoachModal] =
    useState(false);
  const currentProgram = useSelector((state) => state.programs.currentProgram);
  const [selectedMentorSession, setSelectedMentorSession] = useState({});
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const timeFormat = 'MMM DD, YYYY, hh:mm A';
  const userId = getUserId();
  useEffect(() => {
    fetchMentorSessionData();
    return () => {};
  }, []);

  useEffect(() => {
    if (isSuccess) {
      fetchMentorSessionData();
    }
    return () => {};
  }, [isSuccess]);

  function fetchMentorSessionData() {
    const MentorSession = [];
    axios
      .get(fetchCoachSessionsURL(userId), {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((res) => {
        res.data.mentor_sessions?.forEach((res) => {
          MentorSession.push({
            id: res.id,
            program: res.program,
            title: res.title,
            learner_name: res.learner,
            status: res.status,
            start_time: res.start_time,
          });
        });
        setData(MentorSession);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function getEditRowButtonText(session) {
    let defaultText = 'Mark as conducted';
    if (session.status === 'conducted') {
      defaultText = 'Update session';
    }
    if (
      session.status === 'verified' ||
      session.status === 'payment_processed'
    ) {
      defaultText = 'View session';
    }
    if (
      openMentorSessionCoachModal &&
      session.id === selectedMentorSession.id
    ) {
      return 'Opened';
    }
    return defaultText;
  }

  const columns = [
    {
      field: 'id',
      headerName: 'Id',
      type: 'number',
      width: 70,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'program',
      headerName: 'Program name',
      width: 240,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'title',
      headerName: 'Title',
      width: 300,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'learner_name',
      headerName: 'Learner name',
      width: 200,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => params.row?.learner_name?.name,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 300,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => {
        if (params.row.status === 'created') {
          return 'Created';
        }
        if (params.row.status === 'approved') {
          return 'Approved';
        }
        if (params.row.status === 'scheduled') {
          return 'Scheduled';
        }
        if (params.row.status === 'conducted') {
          return 'Conducted';
        }
        if (params.row.status === 'verified') {
          return 'Verified';
        }
        return 'Payment processed';
      },
    },
    {
      field: 'start_time',
      headerName: 'Start time',
      width: 250,
      align: 'center',
      type: 'dateTime',
      headerAlign: 'center',
      valueGetter: (params) =>
        params.row?.start_time
          ? moment(params.row.start_time).format(timeFormat)
          : 'NA',
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      width: 200,
      align: 'center',
      headerAlign: 'center',
      cellClassName: 'actions',
      getActions: ({ id, row }) => {
        // Need To Figure Out What Needs TO Be Ignored
        // if (row.status !== 'created' && row.status !== 'scheduled') {
        //   return [];
        // }
        return [
          <Button
            variant={
              row.status === 'verified' || row.status === 'payment_processed'
                ? 'text'
                : 'contained'
            }
            size='small'
            onClick={(e) => {
              setSelectedMentorSession(row);
              setOpenMentorSessionCoachModal(true);
            }}
          >
            {getEditRowButtonText(row)}
          </Button>,
        ];
      },
    },
  ];

  return (
    <Stack>
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      {isSuccess && (
        <SuccessNotifier
          message={successMessage}
          setIsSuccess={setIsSuccess}
        />
      )}
      <Box
        sx={{
          width: 1550,
          height: '100%',
          mt: 5,
          alignSelf: 'center',
        }}
      >
        <DataGrid
          rows={data}
          columns={columns}
          pageSize={100}
          rowsPerPageOptions={[100]}
          autoHeight
          disableSelectionOnClick
        />
      </Box>
      {openMentorSessionCoachModal && (
        <MentorSessionCoachModal
          setOpenMentorSessionCoachModal={setOpenMentorSessionCoachModal}
          openMentorSessionCoachModal={openMentorSessionCoachModal}
          selectedMentorSession={selectedMentorSession}
          setIsSuccess={setIsSuccess}
          setSuccessMessage={setSuccessMessage}
          setErrorMessage={setErrorMessage}
          setHasError={setHasError}
          currentProgram={currentProgram}
        />
      )}
    </Stack>
  );
}

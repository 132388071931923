// @ts-nocheck
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  Stack,
  TextField,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
} from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import { Box } from '@material-ui/system';
import TopicsDropdown from '../../../pages/TopicsDropdown';
import ErrorNotifier from '../../ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../ToastNotifications/SuccessNotifier';
import {
  BASE_URL,
  fetchCodingProblemsURL,
  getHeaders,
  postCodeProblemURL,
} from '../../../apis/urls';
import DetailedProblemAdminView from './DetailedProblemAdminView';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const initialState = {
  title: '',
  description: '',
};

const filterState = {
  id: '',
  name: '',
};

export default function ProblemsLibrary() {
  const [allProblems, setAllProblems] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [openAddProblem, setOpenAddProblem] = useState(false);
  const [selectedProblem, setSelectedProblem] = useState(null);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(50);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState(filterState);

  useEffect(() => {
    fetchCodeProblems();
    return () => {};
  }, [perPage, page, filter]);

  function fetchCodeProblems() {
    setLoading(true);
    const offset = Math.max(0, page * perPage - perPage);
    const queryParams = [`limit=${perPage}`, `offset=${offset}`];

    if (filter.id) {
      queryParams.push(`id=${filter.id}`);
    }
    if (filter.name) {
      queryParams.push(`name=${filter.name}`);
    }

    axios
      .get(fetchCodingProblemsURL(queryParams), {
        headers: getHeaders(),
      })
      .then((res) => {
        setAllProblems(res?.data?.data ?? []);
        setTotalRows(res?.data?.total);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(err.response?.data?.message || err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleFilterUpdate(e) {
    const { name, value } = e.target;
    setFilter((prevFilter) => ({
      ...prevFilter,
      [name]: value,
    }));
  }

  const handleProblemSelection = (params, event) => {
    setSelectedProblem(params.row);
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePageSizeChange = (size) => {
    setPerPage(size);
  };

  const handleNewProblemSubmit = (body) => {
    axios
      .post(postCodeProblemURL(), body, {
        headers: getHeaders(),
      })
      .then((res) => {
        setIsSuccess(true);
        setSuccessMessage('Problem successfully created.');
        setOpenAddProblem(false);
        setAllProblems([res.data, ...allProblems]);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(err.response?.data?.message || err.message);
      });
  };

  const updateCodeProblem = (id, body) => {
    setLoading(true);
    axios
      .patch(`${BASE_URL}/api/code_problems/${id}`, body, {
        headers: getHeaders(),
      })
      .then((res) => {
        console.log(res.data);
        setAllProblems((prev) => {
          const index = prev.findIndex((p) => p.id === id);
          if (index === -1) {
            return prev;
          }

          const tmp = [...prev];
          tmp[index] = {
            ...tmp[index],
            ...body,
          };
          return tmp;
        });
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message ||
            err.message ||
            'An error occured while updating the problem'
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns = [
    {
      field: 'id',
      headerName: 'Problem id',
      flex: 1,
      maxWidth: 120,
      align: 'center',
      headerAlign: 'center',
      type: 'number',
      sortable: false,
      renderCell: (params) => {
        if (params.row.id) {
          return <Typography variant='body2'>{params.row.id}</Typography>;
        }
      },
    },
    {
      field: 'title',
      headerName: 'Problem name',
      flex: 1,
      maxWidth: 500,
      align: 'center',
      headerAlign: 'center',
      type: 'string',
      sortable: false,
      renderCell: (params) => {
        if (params.row.title) {
          return <Typography variant='body2'>{params.row.title}</Typography>;
        }
      },
    },
    {
      field: 'problem_type',
      headerName: 'Problem type',
      type: 'actions',
      width: 500,
      align: 'center',
      headerAlign: 'center',
      cellClassName: 'actions',
      renderCell: (params) => {
        return (
          <ToggleButtonGroup
            color='primary'
            value={params.row.problem_type}
            exclusive
            onChange={(e) => {
              if (params.row.problem_type === e.target.value) {
                return;
              }
              if (params.row.problem_type === 'level_test') {
                setHasError(true);
                setErrorMessage(
                  'A TGP problem cannot be moved to another category.'
                );
                return;
              }
              if (e.target.value === 'level_test') {
                const result = window.confirm(
                  'This is an irreversible change. Are you sure you want to move this problem to TGP category ?'
                );
                if (!result) {
                  return;
                }
              }
              const body = {
                problem_type: e.target.value,
              };
              updateCodeProblem(params.row.id, body);
            }}
          >
            <ToggleButton
              size='small'
              value='practice'
            >
              Practice
            </ToggleButton>
            <ToggleButton
              size='small'
              value='level_test'
            >
              TGP Test
            </ToggleButton>
            <ToggleButton
              size='small'
              value='session_assignment'
            >
              Session Assignment
            </ToggleButton>
          </ToggleButtonGroup>
        );
      },
    },
  ];

  return (
    <Stack
      spacing={2}
      sx={{ m: 1, mt: 2 }}
    >
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      {isSuccess && (
        <SuccessNotifier
          message={successMessage}
          setIsSuccess={setIsSuccess}
        />
      )}
      {!selectedProblem ? (
        <>
          <Box
            display='flex'
            justifyContent='flex-end'
            alignItems='center'
          >
            <TextField
              type='number'
              label='Search by Problem ID'
              name='id'
              value={filter.id}
              onChange={(e) => handleFilterUpdate(e)}
              sx={{ mr: 2 }}
              size='small'
            />
            <TextField
              label='Search by Problem Name'
              name='name'
              value={filter.name}
              onChange={(e) => handleFilterUpdate(e)}
              sx={{ mr: 2 }}
              size='small'
            />
            <Button
              variant='contained'
              sx={{ width: '8rem', height: 40 }}
              onClick={(e) => setOpenAddProblem(true)}
            >
              Add Problem
            </Button>
          </Box>
          <DataGrid
            rows={allProblems}
            columns={columns}
            rowsPerPageOptions={[50, 100]} // 100 is the max page size in non-pro version
            page={page - 1}
            pageSize={perPage}
            onPageChange={(newPage) => handlePageChange(newPage + 1)}
            onPageSizeChange={(page) => handlePageSizeChange(page)}
            rowCount={totalRows}
            pagination
            paginationMode='server'
            autoHeight
            disableSelectionOnClick
            onRowClick={handleProblemSelection}
            sx={{
              cursor: 'pointer',
              '& .MuiDataGrid-row:hover': {
                backgroundColor: '#e3f2fd',
              },
              '& .MuiDataGrid-cell': {
                paddingLeft: '25px',
              },
              '& .MuiDataGrid-columnHeaderTitleContainer': {
                paddingLeft: '15px',
              },
            }}
            loading={loading}
          />
          {allProblems?.length === 0 && (
            <Typography
              variant='h5'
              align='center'
            >
              No problems have been added.
            </Typography>
          )}
          {openAddProblem && (
            <AddCodingProblem
              {...{
                openAddProblem,
                setOpenAddProblem,
                handleNewProblemSubmit,
                setErrorMessage,
                setHasError,
              }}
            />
          )}
        </>
      ) : (
        <DetailedProblemAdminView
          selectedProblem={selectedProblem}
          setSelectedProblem={setSelectedProblem}
        />
      )}
    </Stack>
  );
}

function AddCodingProblem({
  openAddProblem,
  setOpenAddProblem,
  handleNewProblemSubmit,
  setHasError,
  setErrorMessage,
}) {
  const [newProblemDetails, setNewProblemDetails] = useState(initialState);
  const [selectedTopicIds, setSelectedTopicIds] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedTopicIds.length === 0) {
      setHasError(true);
      setErrorMessage('Please select atleast one topic for the problem');
      return;
    }
    const body = { ...newProblemDetails };
    body.topic_ids = selectedTopicIds;
    handleNewProblemSubmit(body);
  };

  const handleChange = (e) => {
    setNewProblemDetails({
      ...newProblemDetails,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <Modal
        open={openAddProblem}
        onClose={() => setOpenAddProblem(false)}
      >
        <form onSubmit={handleSubmit}>
          <Box sx={style}>
            <Stack spacing={3}>
              <TextField
                fullWidth
                id='title'
                label='Title'
                name='title'
                variant='outlined'
                value={newProblemDetails.title}
                onChange={handleChange}
                required
              />
              <TextField
                multiline
                fullWidth
                id='description'
                type='text'
                name='description'
                label='Description'
                value={newProblemDetails.description}
                onChange={handleChange}
                rows={15}
                required
              />

              <TopicsDropdown
                {...{
                  selectedTopicIds,
                  setSelectedTopicIds,
                  setErrorMessage,
                  setHasError,
                  sx: { m: 1 },
                }}
              />

              <Button
                size='large'
                type='submit'
                variant='contained'
                color='secondary'
              >
                Add problem
              </Button>
            </Stack>
          </Box>
        </form>
      </Modal>
    </>
  );
}

// @ts-nocheck

import SingleCorrectQuestion from './SingleCorrectQuestion';
import TextResponse from './TextResponse';

// Question at index 0 will be shown if the user gave incorrect response for the previous question and index 1 if the user gave correct response,
export const questionTree = {
  1: ['2b', '2a'],
  '2a': ['3b', '3a'],
  '2b': ['3d', '3c'],
  '3a': ['4b', '4a'],
  '3b': ['4d', '4c'],
  '3c': ['4f', '4e'],
  '3d': ['4h', '4g'],
  '4a': ['5', '5'],
  '4b': ['5', '5'],
  '4c': ['5', '5'],
  '4d': ['5', '5'],
  '4e': ['5', '5'],
  '4f': ['5', '5'],
  '4g': ['5', '5'],
  '4h': ['5', '5'],
  5: [],
};

// https://stackoverflow.com/a/60610458/11630586
// Root node is assumed to be at height 0.
export const getNodeDepth = (node) => {
  return parseInt(`${node}`) - 1;
};

export const getScreeningQuestions = ({ version }) => {
  switch (version) {
    case 'v1':
      return screeningQuestionsV1;
    case 'v2':
      return screeningQuestionsV2;
    default:
      return screeningQuestionsV1;
  }
};

const screeningQuestionsV2 = {
  1: {
    description:
      'Given a directed graph where edges represent dependencies between tasks, which algorithm would you use to detect if the graph contains a cycle, indicating a circular dependency?',
    options: [
      {
        option: 'Dijkstra’s Algorithm',
        isCorrect: false,
      },
      {
        option: 'Depth-First Search (DFS)',
        isCorrect: false,
      },
      {
        option: 'Breadth-First Search (BFS)',
        isCorrect: false,
      },
      {
        option: 'Topological Sort',
        isCorrect: true,
      },
    ],
    getComponent: (args) => <SingleCorrectQuestion {...args} />,
    type: 'single_correct',
  },
  '3c': {
    description:
      'You are given a string s and an integer k. What is the efficient time complexity of an algorithm that finds the longest substring of s with at most k distinct characters?',
    options: [
      {
        option: 'O(nlogk)',
        isCorrect: false,
      },
      {
        option: 'O(k^2)',
        isCorrect: false,
      },
      {
        option: 'O(n)',
        isCorrect: true,
      },
      {
        option: 'O(n^2)',
        isCorrect: false,
      },
    ],
    getComponent: (args) => <SingleCorrectQuestion {...args} />,
    type: 'single_correct',
  },
  '2b': {
    description:
      'In a system using a Least Recently Used (LRU) cache with a capacity of k, which data structure is commonly used to achieve O(1) time complexity for both insert and delete operations?',
    options: [
      {
        option: 'Stack',
        isCorrect: false,
      },
      {
        option: 'Queue',
        isCorrect: false,
      },
      {
        option: 'HashMap + Doubly Linked List',
        isCorrect: true,
      },
      {
        option: 'Binary Search Tree',
        isCorrect: false,
      },
    ],
    getComponent: (args) => <SingleCorrectQuestion {...args} />,
    type: 'single_correct',
  },
  '3a': {
    description:
      'Which of the following operations is not typically supported by a segment tree?',
    options: [
      {
        option: 'Point update',
        isCorrect: false,
      },
      {
        option: 'Range update',
        isCorrect: false,
      },
      {
        option: 'Range minimum query',
        isCorrect: false,
      },
      {
        option: 'Binary search',
        isCorrect: true,
      },
    ],
    getComponent: (args) => <SingleCorrectQuestion {...args} />,
    type: 'single_correct',
  },
  '3b': {
    description:
      'What is the worst-case time complexity of the quicksort algorithm?',
    options: [
      {
        option: 'O(n log n)',
        isCorrect: false,
      },
      {
        option: 'O(n^2)',
        isCorrect: true,
      },
      {
        option: 'O(n)',
        isCorrect: false,
      },
      {
        option: 'O(log n)',
        isCorrect: false,
      },
    ],
    getComponent: (args) => <SingleCorrectQuestion {...args} />,
    type: 'single_correct',
  },
  '3d': {
    description:
      'What is the time complexity of inserting an element into a balanced binary search tree?',
    options: [
      {
        option: 'O(1)',
        isCorrect: false,
      },
      {
        option: 'O(log n)',
        isCorrect: true,
      },
      {
        option: 'O(n)',
        isCorrect: false,
      },
      {
        option: 'O(n log n)',
        isCorrect: false,
      },
    ],
    getComponent: (args) => <SingleCorrectQuestion {...args} />,
    type: 'single_correct',
  },
  '2a': {
    description:
      'In a distributed system, which of the following is the best consistency model for real-time bidding in an ad exchange system?',
    options: [
      {
        option: 'Eventual Consistency',
        isCorrect: false,
      },
      {
        option: 'Strong Consistency',
        isCorrect: true,
      },
      {
        option: 'Causal Consistency',
        isCorrect: false,
      },
      {
        option: 'Linearizability',
        isCorrect: false,
      },
    ],
    getComponent: (args) => <SingleCorrectQuestion {...args} />,
    type: 'single_correct',
  },
  '4b': {
    description:
      'In modern operating systems, what is the primary benefit of using a multi-level page table over a single-level page table?',
    options: [
      {
        option: 'It reduces the size of page frames',
        isCorrect: false,
      },
      {
        option: 'It reduces the number of page faults',
        isCorrect: false,
      },
      {
        option: 'It reduces the memory required to store the page table',
        isCorrect: true,
      },
      {
        option: 'It improves the execution speed of processes',
        isCorrect: false,
      },
    ],
    getComponent: (args) => <SingleCorrectQuestion {...args} />,
    type: 'single_correct',
  },
  '4a': {
    description:
      'Which of the following is an advantage of using a bloom filter?',
    options: [
      {
        option: 'It always provides exact search results',
        isCorrect: false,
      },
      {
        option: 'It can answer queries about set membership with certainty',
        isCorrect: false,
      },
      {
        option: 'It is space-efficient and allows for false positives',
        isCorrect: true,
      },
      {
        option: 'It allows both false positives and false negatives',
        isCorrect: false,
      },
    ],
    getComponent: (args) => <SingleCorrectQuestion {...args} />,
    type: 'single_correct',
  },
  '4c': {
    description:
      'What is the main advantage of using a B-tree data structure in database indexing?',
    options: [
      {
        option: 'It provides constant-time search operations',
        isCorrect: false,
      },
      {
        option: 'It minimizes the number of disk I/O operations',
        isCorrect: true,
      },
      {
        option: 'It ensures perfect balance at all times',
        isCorrect: false,
      },
      {
        option: 'It allows for faster insertion than other tree structures',
        isCorrect: false,
      },
    ],
    getComponent: (args) => <SingleCorrectQuestion {...args} />,
    type: 'single_correct',
  },
  '4d': {
    description:
      'Which of the following algorithms is most suitable for finding the shortest path in a graph with negative edge weights?',
    options: [
      {
        option: "Dijkstra's algorithm",
        isCorrect: false,
      },
      {
        option: 'Bellman-Ford algorithm',
        isCorrect: true,
      },
      {
        option: 'A* search algorithm',
        isCorrect: false,
      },
      {
        option: "Kruskal's algorithm",
        isCorrect: false,
      },
    ],
    getComponent: (args) => <SingleCorrectQuestion {...args} />,
    type: 'single_correct',
  },
  '4f': {
    description:
      'In the context of system design, what is the primary purpose of a message queue?',
    options: [
      {
        option: 'To encrypt messages between services',
        isCorrect: false,
      },
      {
        option: 'To ensure synchronous communication',
        isCorrect: false,
      },
      {
        option: 'To decouple services and handle asynchronous processing',
        isCorrect: true,
      },
      {
        option: 'To compress data for efficient storage',
        isCorrect: false,
      },
    ],
    getComponent: (args) => <SingleCorrectQuestion {...args} />,
    type: 'single_correct',
  },
  '4e': {
    description:
      'In the context of distributed systems, which of the following is NOT a property guaranteed by the CAP theorem?',
    options: [
      {
        option: 'Consistency',
        isCorrect: false,
      },
      {
        option: 'Availability',
        isCorrect: false,
      },
      {
        option: 'Partition tolerance',
        isCorrect: false,
      },
      {
        option: 'Durability',
        isCorrect: true,
      },
    ],
    getComponent: (args) => <SingleCorrectQuestion {...args} />,
    type: 'single_correct',
  },
  '4h': {
    description:
      'In the context of REST API design, what does the term "idempotent" mean?',
    options: [
      {
        option: 'The API always returns the same response for the same input',
        isCorrect: false,
      },
      {
        option: 'The API can process multiple requests simultaneously',
        isCorrect: false,
      },
      {
        option:
          'Multiple identical requests have the same effect as a single request',
        isCorrect: true,
      },
      {
        option: 'The API uses secure encryption for all communications',
        isCorrect: false,
      },
    ],
    getComponent: (args) => <SingleCorrectQuestion {...args} />,
    type: 'single_correct',
  },
  '4g': {
    description:
      'What is the purpose of chaining in a hash table implementation? ',
    options: [
      {
        option: 'To reduce collisions',
        isCorrect: true,
      },
      {
        option: 'To increase the size of the hash table',
        isCorrect: false,
      },
      {
        option: 'To improve search efficiency',
        isCorrect: false,
      },
      {
        option: 'To handle overflow',
        isCorrect: false,
      },
    ],
    getComponent: (args) => <SingleCorrectQuestion {...args} />,
    type: 'single_correct',
  },
  5: {
    description:
      'Describe a time when you had to learn a new technology or concept on your own to complete a project. What resources did you use, and how did you ensure you understood it thoroughly?',
    maxCharacters: 1000,
    getComponent: (args) => <TextResponse {...args} />,
    type: 'text',
  },
};

const screeningQuestionsV1 = {
  1: {
    description:
      'Which of the following best describes the behavior of a stack?',
    options: [
      {
        option: 'First In Last Out',
        isCorrect: false,
      },
      {
        option: 'Last In First Out',
        isCorrect: false,
      },
      {
        option: 'Both (A) & (B)',
        isCorrect: true,
      },
      {
        option: 'Last In Last Out',
        isCorrect: false,
      },
    ],
    getComponent: (args) => <SingleCorrectQuestion {...args} />,
    type: 'single_correct',
  },
  '2a': {
    description:
      'What would be the result after performing the following sequence of stack operations: push(1), push(2), pop(), push(3), pop()?',
    options: [
      {
        option: '1 remains on the top of the stack',
        isCorrect: true,
      },
      {
        option: '3 remains on the top of the stack',
        isCorrect: false,
      },
      {
        option: '2 remains on the top of the stack',
        isCorrect: false,
      },
      {
        option: 'The stack is empty',
        isCorrect: false,
      },
    ],
    getComponent: (args) => <SingleCorrectQuestion {...args} />,
    type: 'single_correct',
  },
  '2b': {
    description:
      'Which of the following is not a valid variable name in most programming languages?',
    options: [
      {
        option: 'var_name',
        isCorrect: false,
      },
      {
        option: '2variable',
        isCorrect: true,
      },
      {
        option: 'variable2',
        isCorrect: false,
      },
      {
        option: '_var',
        isCorrect: false,
      },
    ],
    getComponent: (args) => <SingleCorrectQuestion {...args} />,
    type: 'single_correct',
  },
  '3a': {
    description:
      'For managing hierarchical content in a CMS, which data structure is best suited? ',
    options: [
      {
        option: 'Queue',
        isCorrect: false,
      },
      {
        option: 'Stack',
        isCorrect: false,
      },
      {
        option: 'Tree',
        isCorrect: true,
      },
      {
        option: 'Hash Map',
        isCorrect: false,
      },
    ],
    getComponent: (args) => <SingleCorrectQuestion {...args} />,
    type: 'single_correct',
  },
  '3b': {
    description:
      'What is the advantage of using a graph database over a relational database?',
    options: [
      {
        option: 'Faster joins',
        isCorrect: false,
      },
      {
        option: 'Better at handling structured data',
        isCorrect: false,
      },
      {
        option: 'Improved transactional integrity',
        isCorrect: false,
      },
      {
        option: 'Efficient representation of interconnections',
        isCorrect: true,
      },
    ],
    getComponent: (args) => <SingleCorrectQuestion {...args} />,
    type: 'single_correct',
  },
  '3d': {
    description:
      "An investor receives an annual interest payout from a bond which is 5% of the bond's face value. If the annual interest received is $600, what is the face value of the bond?",
    options: [
      {
        option: '$10,000',
        isCorrect: false,
      },
      {
        option: '$12,000',
        isCorrect: true,
      },
      {
        option: '$15,000',
        isCorrect: false,
      },
      {
        option: '$20,000',
        isCorrect: false,
      },
    ],
    getComponent: (args) => <SingleCorrectQuestion {...args} />,
    type: 'single_correct',
  },
  '3c': {
    description: 'What is a function that calls itself called?',
    options: [
      {
        option: 'Loop Function',
        isCorrect: false,
      },
      {
        option: 'Recursion',
        isCorrect: true,
      },
      {
        option: 'Callback Function',
        isCorrect: false,
      },
      {
        option: 'Inline Function',
        isCorrect: false,
      },
    ],
    getComponent: (args) => <SingleCorrectQuestion {...args} />,
    type: 'single_correct',
  },
  '4b': {
    description:
      'Which sorting algorithm is generally considered the fastest when dealing with large datasets?',
    options: [
      {
        option: 'Bubble Sort',
        isCorrect: false,
      },
      {
        option: 'Quick Sort',
        isCorrect: true,
      },
      {
        option: 'Insertion Sort',
        isCorrect: false,
      },
      {
        option: 'Merge Sort',
        isCorrect: false,
      },
    ],
    getComponent: (args) => <SingleCorrectQuestion {...args} />,
    type: 'single_correct',
  },
  '4a': {
    description:
      'To implement a file system with quick file access, immediate insertion, and frequent size checks of the largest file, which data structure is suited?',
    options: [
      {
        option: 'B-tree',
        isCorrect: true,
      },
      {
        option: 'Heap',
        isCorrect: false,
      },
      {
        option: 'Hash table',
        isCorrect: false,
      },
      {
        option: 'Binary search tree',
        isCorrect: false,
      },
    ],
    getComponent: (args) => <SingleCorrectQuestion {...args} />,
    type: 'single_correct',
  },
  '4d': {
    description:
      'Which concept is typically used to repeat a block of code multiple times until a certain condition is met?',
    options: [
      {
        option: 'Inheritance',
        isCorrect: false,
      },
      {
        option: 'Abstraction',
        isCorrect: false,
      },
      {
        option: 'Loop',
        isCorrect: true,
      },
      {
        option: 'Encapsulation',
        isCorrect: false,
      },
    ],
    getComponent: (args) => <SingleCorrectQuestion {...args} />,
    type: 'single_correct',
  },
  '4c': {
    description: 'Which of these is not a programming paradigm?',
    options: [
      {
        option: 'Object-oriented',
        isCorrect: false,
      },
      {
        option: 'Functional',
        isCorrect: false,
      },
      {
        option: 'Procedural',
        isCorrect: false,
      },
      {
        option: 'Quantum',
        isCorrect: true,
      },
    ],
    getComponent: (args) => <SingleCorrectQuestion {...args} />,
    type: 'single_correct',
  },
  '4f': {
    description:
      'Which SQL clause is used to filter the results of a query based on a specified condition?',
    options: [
      {
        option: 'SELECT',
        isCorrect: false,
      },
      {
        option: 'FROM',
        isCorrect: false,
      },
      {
        option: 'WHERE',
        isCorrect: true,
      },
      {
        option: 'ORDER BY',
        isCorrect: false,
      },
    ],
    getComponent: (args) => <SingleCorrectQuestion {...args} />,
    type: 'single_correct',
  },
  '4e': {
    description:
      'Which operation would you use to combine two strings into one?',
    options: [
      {
        option: 'Summation',
        isCorrect: false,
      },
      {
        option: 'Concatenation',
        isCorrect: true,
      },
      {
        option: 'Multiplication',
        isCorrect: false,
      },
      {
        option: 'Compression',
        isCorrect: false,
      },
    ],
    getComponent: (args) => <SingleCorrectQuestion {...args} />,
    type: 'single_correct',
  },
  '4h': {
    description: 'What is the result of the operation 3%2?',
    options: [
      {
        option: '3',
        isCorrect: false,
      },
      {
        option: '0',
        isCorrect: false,
      },
      {
        option: '1',
        isCorrect: true,
      },
      {
        option: '2',
        isCorrect: false,
      },
    ],
    getComponent: (args) => <SingleCorrectQuestion {...args} />,
    type: 'single_correct',
  },
  '4g': {
    description:
      'A project was estimated to take 10 hours but was completed in 8. By what percent did the efficiency increase?',
    options: [
      {
        option: '20%',
        isCorrect: true,
      },
      {
        option: '25%',
        isCorrect: false,
      },
      {
        option: '50%',
        isCorrect: false,
      },
      {
        option: '80%',
        isCorrect: false,
      },
    ],
    getComponent: (args) => <SingleCorrectQuestion {...args} />,
    type: 'single_correct',
  },
  5: {
    description:
      'Describe a time when you had to learn a new technology or concept on your own to complete a project. What resources did you use, and how did you ensure you understood it thoroughly?',
    maxCharacters: 1000,
    getComponent: (args) => <TextResponse {...args} />,
    type: 'text',
  },
};
export const rescheduleTime = [
  { label: '30 minutes later', minutes: 30 },
  { label: '45 minutes later', minutes: 45 },
  { label: '1 hour later', minutes: 60 },
  { label: '2 hours later', minutes: 120 },
];

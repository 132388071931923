import React, { useState, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { styled } from '@mui/system';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const Alert = React.forwardRef((props, ref) => (
  <MuiAlert
    elevation={6}
    ref={ref}
    variant='filled'
    icon={false}
    {...props}
  />
));

const ScrollableDiv = styled('div')({
  maxHeight: '400px', // Adjust the max height as needed
  overflowY: 'auto',
  padding: '8px',
  width: '100%',
});

const CustomAlert = styled(Alert)({
  backgroundColor: 'white',
  color: 'black',
  fontFamily: 'Arial, sans-serif',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
  borderRadius: '8px',
  width: '400px', // Adjust the width as needed
});

export default function LearnerErrorNotifier({
  messages,
  setHasError,
  duration = 6000,
}) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (messages.length > 0) {
      setOpen(true);
    }
  }, [messages]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setHasError(false);
    setOpen(false);
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
      open={open}
      autoHideDuration={duration}
      onClose={handleClose}
      disableWindowBlurListener
      sx={{ width: '100%', top: '10px' }}
    >
      <CustomAlert severity='error'>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '8px',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ErrorOutlineIcon style={{ marginRight: '8px' }} />
            <span style={{ fontWeight: 'bold' }}>Mismatched Entries</span>
          </div>
          <IconButton
            size='small'
            onClick={handleClose}
          >
            <CloseIcon fontSize='small' />
          </IconButton>
        </div>
        <div style={{ padding: '0 16px 10px 8px' }}>
          Following learners doesn't belong to this program:
        </div>
        <ScrollableDiv>
          {messages.map((message, index) => (
            <div
              key={index}
              style={{ marginBottom: '4px' }}
            >
              {message}
            </div>
          ))}
        </ScrollableDiv>
      </CustomAlert>
    </Snackbar>
  );
}

import React from 'react';
import Box from '@mui/material/Box';
import LockIcon from '@mui/icons-material/Lock';
import { IconButton, Tooltip } from '@material-ui/core';

const Circle = ({
  index,
  topic,
  lastTopic,
  handleClick,
  topicStatus,
  style,
}) => {
  return (
    <Tooltip
      title={
        topicStatus
          ? `Completed 70% of ${lastTopic} to unlock ${topic.displayName}`
          : ''
      }
    >
      <Box
        key={index}
        style={{ ...style, zIndex: 3 }}
        role='button'
        tabIndex={0}
        onClick={() => {
          handleClick(topic.name);
        }}
        onKeyDown={(event) => {
          if (event.key === 'Enter' || event.key === ' ') {
            console.log('selectedTopic: ', topic.name);
            handleClick(topic.name);
          }
        }}
      >
        {topicStatus === true && (
          <LockIcon
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              padding: '45px',
              background: '#4242424D',
            }}
          />
        )}
        <h3 style={{ fontSize: '20px', fontWeight: '500' }}>
          {topic.displayName}
        </h3>
      </Box>
    </Tooltip>
  );
};

export default Circle;

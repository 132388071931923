import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Button, Modal, Box, Stack, Typography } from '@material-ui/core';
import Page from '../../Page';
import MentorResourceCard from './MentorResourcesCard';
import AddResourceForm from './AddResourceForm';
import { fetchProgramResourcesURL, getHeaders } from '../../../apis/urls';
import ErrorNotifier from '../../ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../ToastNotifications/SuccessNotifier';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '0.3rem',
  p: 4,
};

function MentorResource() {
  const [open, setOpen] = useState(false);
  const [resources, setResources] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [hasDeleted, setHasDeleted] = useState(false);
  const currentProgram = useSelector((state) => state.programs.currentProgram);

  useEffect(() => {
    if (currentProgram) {
      getAllResources();
    }
    if (hasDeleted) {
      setHasDeleted(false);
    }
    return () => {};
  }, [open, hasDeleted, currentProgram]);

  function getAllResources() {
    axios
      .get(fetchProgramResourcesURL(), {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((res) => {
        setResources(res.data);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(err.response?.data?.message || err.message);
      });
  }

  return (
    <Page>
      {isSuccess && (
        <SuccessNotifier
          message={successMessage}
          setIsSuccess={setIsSuccess}
        />
      )}
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      <Stack
        spacing={2}
        sx={{ m: 2 }}
      >
        <Box>
          <Button
            sx={{ float: 'right', marginRight: '2rem' }}
            variant='outlined'
            color='secondary'
            onClick={() => setOpen(true)}
          >
            Add Resource
          </Button>
        </Box>
        {resources.length !== 0 ? (
          resources.map((res) => {
            return (
              <MentorResourceCard
                key={res.id}
                resource={res}
                setHasDeleted={setHasDeleted}
                setIsSuccess={setIsSuccess}
                setHasError={setHasError}
                setSuccessMessage={setSuccessMessage}
                setErrorMessage={setErrorMessage}
              />
            );
          })
        ) : (
          <Typography
            variant='body2'
            textAlign='center'
            sx={{ mt: 2 }}
          >
            Resources not found
          </Typography>
        )}
      </Stack>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
      >
        <Box sx={style}>
          <AddResourceForm
            setResources={setResources}
            setOpen={setOpen}
            setIsSuccess={setIsSuccess}
            setHasError={setHasError}
            setSuccessMessage={setSuccessMessage}
            setErrorMessage={setErrorMessage}
          />
        </Box>
      </Modal>
    </Page>
  );
}

export default MentorResource;

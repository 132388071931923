import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  topics: [],
}

export const topicSlice = createSlice({
  name: 'topics',
  initialState,
  reducers: {
    unsetTopics: (state) => {
      state.topics = [];
    },

    setTopics: (state, action) => {
      state.topics = action.payload;
    },

    // this should only be used to add new topic.
    addTopic: (state, action) => {
      state.topics = [
        ...state.topics,
        action.payload
      ];
    },
  },
})

export const { setTopics, unsetTopics } = topicSlice.actions;

export default topicSlice.reducer;
import React from 'react';
import Box from '@mui/material/Box';

const ProgressBar = ({ progress, width, height }) => {
  return (
    <Box
      style={{
        width: `${width}%`,
        height: `${height}px`,
        backgroundColor: '#f0f0f0',
        borderRadius: '10px',
        marginTop: '4px',
        overflow: 'hidden',
      }}
    >
      <Box
        style={{
          width: `${progress}%`,
          height: '100%',
          backgroundColor: '#1976d2',
          borderRadius: '10px',
        }}
      />
    </Box>
  );
};

export default ProgressBar;

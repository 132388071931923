import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import ErrorNotifier from '../../ToastNotifications/ErrorNotifier';
import { fetchProgramAssignmentsUrl, getHeaders } from '../../../apis/urls';
import AssignmentCard from './AssignmentCard';
import DetailedAssign from './DetailedAssign';

export default function AssignmentsViewForStudents({ category, Detailed, setDetailed }) {
    const [ActiveAssign, setActiveAssign] = useState();
    const [AssignData, setAssignData] = useState([]);
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [assignmentStatus, setAssignmentStatus] = useState("pending");
    const currentProgram = useSelector(state => state.programs.currentProgram);

    useEffect(() => {
      if (currentProgram) { 
        fetchAssignments();
      }
      return () => {};
    }, [assignmentStatus, currentProgram]);

    const fetchAssignments = async () => {
        try {
            const query = (assignmentStatus !== 'all' 
              ? `?status=${assignmentStatus}&category=${category}`
              : `?category=${category}`
            )

            const response = await axios.get(`${fetchProgramAssignmentsUrl()}${query}`, {
                headers: getHeaders(currentProgram.programId)
            });
            if (response?.data?.assignments) {
                setAssignData(response.data.assignments);
            }
            else {
                setHasError(true);
                setErrorMessage("Assignments could not be fetched");    
            }
        }
        catch (err) {
            console.log(err);
            setHasError(true);
            setErrorMessage(err.response?.data?.message || err.message);
        }
    };

    return (
        <div>
            {hasError
            ? <ErrorNotifier message={errorMessage} setHasError={setHasError}/>
            : ""
            }
            <AssignmentStatusDropDown 
                setAssignmentStatus={setAssignmentStatus}
                assignmentStatus={assignmentStatus} 
            />
            {!Detailed &&
                AssignData?.length > 0 &&
                AssignData?.map((data, index) => (
                    <AssignmentCard
                        deadline={data?.deadline}
                        title={data?.title}
                        key={data?.id}
                        setDetailed={setDetailed}
                        Detailed={Detailed}
                        setActiveAssign={setActiveAssign}
                        data={data}
                        AssignData={AssignData}
                    />
                ))}
            {!Detailed && AssignData?.length === 0 && (
                <Box
                    style={{
                        padding: 10,
                        fontWeight: 'bold',
                        textAlign: 'center'
                    }}
                >
                    No Assignments Available
                </Box>
            )}
            {Detailed && (
                <DetailedAssign
                    Detailed={Detailed}
                    setDetailed={setDetailed}
                    AssignData={ActiveAssign}
                />
            )}
        </div>
    );
}

function AssignmentStatusDropDown({ assignmentStatus, setAssignmentStatus }) {
    const handleChange = (event) => {
        setAssignmentStatus(event.target.value);
    };

    return (
        <Box sx={{ minWidth: 120, display: 'flex', justifyContent: 'flex-end'}}>
            <FormControl>
                <InputLabel id="assignment-status">Status</InputLabel>
                <Select
                    labelId="assignment-status"
                    id="assignment-status"
                    value={assignmentStatus}
                    label="Status"
                    onChange={handleChange}
                    sx={{
                        width: '10rem'
                    }}
                >
                    <MenuItem value="all">All</MenuItem>
                    <MenuItem value="pending">Pending</MenuItem>
                    <MenuItem value="completed">Completed</MenuItem>
                </Select>
            </FormControl>
        </Box>
    );
}

import React from 'react';
import { Typography, Box } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export default function SuccessScreen({ successMessage }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: 'background.paper',
        boxShadow: 2,
        p: 8,
        borderRadius: 5,
        maxWidth: 500,
      }}
    >
      <CheckCircleIcon
        color='success'
        sx={{
          fontSize: 60,
        }}
      />
      <Typography
        variant='h4'
        align='center'
        color='#2C2C2C'
        sx={{
          mt: '10px',
        }}
      >
        {successMessage}
      </Typography>
    </Box>
  );
}

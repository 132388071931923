import { Button, Tooltip } from '@material-ui/core';
import React, { useState } from 'react';
import NewLevelTestModal from './NewLevelTestModal';

const NewLevelTestButton = ({ setToastNotification }) => {
  const [open, setOpen] = useState(false);

  const handleClose = (e) => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title='This button should only be used to create first TGP test.'>
        <Button
          onClick={(e) => setOpen(!open)}
          color='primary'
          variant='contained'
        >
          Create TGP test
        </Button>
      </Tooltip>

      {open && (
        <NewLevelTestModal
          handleClose={handleClose}
          open={open}
          setToastNotification={setToastNotification}
        />
      )}
    </>
  );
};

export default NewLevelTestButton;

import React from 'react';
import { Box, Typography } from '@mui/material';
import CircularProgressWithLabel from './CircularProgressBar';

const SessionOverviewItem = ({ title, icon, data, onClick, index }) => {
  const progress = (data.completed / data.total) * 100;
  return (
    <Box
      onClick={onClick}
      sx={{
        width: '31.7%',
        height: '70px',
        marginLeft: index % 2 === 0 ? 0 : 'auto',
        p: 1,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'grey.300',
        textAlign: 'center',
        display: 'flex',
        cursor: onClick ? 'pointer' : 'default',
        backgroundColor: '#c1e6f7',
        borderRadius: '7px',
      }}
    >
      <Box
        sx={{
          marginLeft: '10px',
          textAlign: 'start',
        }}
      >
        <Typography
          variant='body1'
          color='initial'
          fontWeight={600}
        >
          {title}
        </Typography>
        <Typography>
          {data.completed}/{data.total} completed
        </Typography>
      </Box>
      <CircularProgressWithLabel value={progress} />
    </Box>
  );
};

export default SessionOverviewItem;

import React, { useEffect, useState } from 'react';
import {
  Box,
  Chip,
  Grid,
  Typography,
  Divider,
  Tooltip,
} from '@material-ui/core';
import VideocamIcon from '@mui/icons-material/Videocam';
import LockIcon from '@mui/icons-material/Lock';
import { Link, useNavigate } from 'react-router-dom';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import moment from 'moment';
import liveSessionsIcon from './images/smart_display_1.svg';
import preReadIcon from './images/article.svg';
import testIcon from './images/coding_assignments.svg';
import recordingNotAvailableIcon from './images/videocam_off.svg';

export const SessionListItem = ({
  sessionData,
  text,
  details,
  type,
  moduleName,
  recordedSessions,
  index,
}) => {
  const navigate = useNavigate();
  let isDue = false;
  let isOpen = true;
  let daysDelayed = 0;
  let dueDate = '';

  const endpoint = text.split(' ').join('-');
  const isCompleted =
    (type === 'assignment' && details?.has_completed) ||
    (type === 'session' &&
      ['conducted', 'verified', 'payment_processed'].includes(
        details.status
      )) ||
    (type === 'preRead' && details?.completed);

  if (type === 'assignment') {
    const deadline = moment(details?.deadline);
    const opensAt = moment(details?.opens_at);
    const now = moment();

    dueDate = deadline.format('DD MMM, hh:mm A');
    const timeDifferenceMs = now.diff(deadline);

    // Convert milliseconds to days
    daysDelayed = Math.floor(timeDifferenceMs / (1000 * 60 * 60 * 24));

    isDue = now.isSameOrAfter(deadline) && !details.has_completed;
    isOpen = now.isAfter(opensAt);
  }

  const renderIcon = () => {
    if (type === 'session') {
      return (
        <img
          src={liveSessionsIcon}
          style={{ width: '24px', marginRight: '8px' }}
          alt='liveSessionIcon'
        />
      );
    } else if (type === 'preRead') {
      return (
        <img
          src={preReadIcon}
          style={{ width: '24px', marginRight: '8px' }}
          alt='preReadIcon'
        />
      );
    } else if (type === 'assignment') {
      return (
        <img
          src={testIcon}
          style={{ width: '28px', marginRight: '8px' }}
          alt='testIcon'
        />
      );
    }
  };

  const sessionDateDetails = () => {
    const sessionStartDate = moment(details.start).format('DD MMM, hh:mm A');
    const isSessionConducted = !(
      details.status === 'created' || details.status === 'scheduled'
    );
    return (
      <Typography
        sx={{
          fontSize: '12px',
          position: 'absolute',
          right: '30px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {isSessionConducted ? (
          <>
            <Chip
              label='Conducted'
              variant='outlined'
              sx={{
                fontSize: '12px',
                fontWeight: '800',
                padding: '0px 8px',
                marginRight: '10px',
                color: 'Green',
                border: '1px Green solid',
              }}
            />
            <CalendarTodayIcon
              style={{
                fontSize: '16px',
                marginRight: '4px',
                marginBottom: '2px',
              }}
            />{' '}
            {sessionStartDate}
            {recordedSessions?.includes(details.id) ? (
              <Tooltip title='Session recording is available'>
                <VideocamIcon
                  sx={{
                    marginLeft: '5px',
                    fontSize: '25px',
                    transition: 'font-size 0.3s ease',
                    '&:hover': {
                      fontSize: '30px',
                    },
                    marginBottom: '2px',
                  }}
                />
              </Tooltip>
            ) : (
              <Tooltip title='Recording will be available soon'>
                <Box
                  component='img'
                  src={recordingNotAvailableIcon}
                  alt='recordingNotAvailableIcon'
                  sx={{
                    marginLeft: '5px',
                    width: '22px',
                    transition: 'width 0.3s ease',
                    marginBottom: '2px',
                    '&:hover': {
                      width: '25px',
                    },
                  }}
                />
              </Tooltip>
            )}
          </>
        ) : (
          <>
            {details?.status === 'scheduled' && (
              <Chip
                variant='outlined'
                label='Scheduled'
                sx={{
                  fontSize: '12px',
                  fontWeight: '800',
                  padding: '0px 8px',
                  marginRight: '10px',
                  color: 'orange',
                  border: '1px orange solid',
                  backgroundColor: '#FFF',
                }}
              />
            )}
            <CalendarTodayIcon
              style={{
                fontSize: '16px',
                marginRight: '4px',
                marginBottom: '2px',
              }}
            />
            {sessionStartDate}
            <VideocamIcon
              sx={{
                marginLeft: '5px',
                fontSize: '25px',
                marginBottom: '2px',
                opacity: '0',
              }}
            />
          </>
        )}
      </Typography>
    );
  };

  return (
    <Box
      sx={{
        boxShadow:
          'rgba(17, 17, 26, 0.0001) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px',
        padding: '10px 10px 10px 20px',
        marginTop: '10px',
        borderRadius: '5px',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        ':hover': {
          backgroundColor: '#f0f7ff',
        },
        borderLeft: isCompleted ? '10px #4bc944 solid' : '10px #cbcaca solid',
        minHeight: '50px',
      }}
      onClick={() => {
        if (
          type === 'session' &&
          isCompleted &&
          !recordedSessions?.includes(details.id)
        )
          return;
        if (isOpen) {
          localStorage.setItem('sessionSelectedId', JSON.stringify(details.id));
          navigate(`${endpoint}-${details.id}`, {
            state: { sessionData, details, moduleName, text, index },
          });
        }
      }}
    >
      {(type !== 'session' || !isCompleted) && !isOpen && (
        <LockIcon style={{ fontSize: '22px', marginRight: '10px' }} />
      )}
      {isOpen && renderIcon()}

      <Typography>{text}</Typography>
      {type === 'session' && sessionDateDetails()}
      {isDue && type === 'assignment' ? (
        <Typography
          sx={{
            fontSize: '12px',
            position: 'absolute',
            right: '30px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Chip
            label={`Due by ${daysDelayed} Days`}
            sx={{
              fontSize: '12px',
              fontWeight: '800',
              padding: '0px 8px',
              color: 'red',
              border: '1px solid red',
              marginRight: '10px',
            }}
            variant='outlined'
          />{' '}
          <CalendarTodayIcon
            style={{
              fontSize: '16px',
              marginRight: '4px',
              marginBottom: '2px',
            }}
          />{' '}
          <span>{`${dueDate}`}</span>
          <VideocamIcon
            sx={{
              marginLeft: '5px',
              fontSize: '25px',
              marginBottom: '2px',
              display: 'hidden',
              opacity: '0',
            }}
          />
        </Typography>
      ) : (
        <></>
      )}
    </Box>
  );
};

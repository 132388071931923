// @ts-nocheck
import React, { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useFastrackPipeline } from 'src/layouts/EfPipelineContext/FastrackPipelineContex';
import { PressedForTimeoptions } from '..';

const TimePressed1 = ({ flowData, setFlowData, saveFlowData }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleCheckboxChange = (option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const { setScreenSource, trackButtonClick } = useFastrackPipeline();
  const handleContinue = () => {
    trackButtonClick({ source: 'screen_3o', eventName: 'continue' });
    saveFlowData({
      screen: 'screen_3o',
      response: { options: selectedOptions },
    });
    setScreenSource('screen_3f');
  };

  return (
    <Box
      sx={{
        textAlign: 'center',
        margin: '0 auto',
      }}
    >
      <Typography
        component='h1'
        sx={{
          color: '#1976d2',
          fontWeight: 'bold',
          fontSize: {
            xs: '2rem',
            sm: '2.4rem',
          },
          marginBottom: '10px',
        }}
      >
        Pressed for time? We've got you!
      </Typography>
      <Typography
        variant='body1'
        sx={{
          fontSize: '1.2rem',
          color: 'black',
          marginBottom: '20px',
        }}
      >
        We get it - between work, family, and other commitments, finding time
        for career growth is tough
      </Typography>
      <Typography
        variant='body2'
        sx={{
          fontSize: '1.1rem',
          marginBottom: '15px',
          color: 'black',
          fontWeight: 'bold',
        }}
      >
        Select the ones you think can help you balance everything & still make
        progress
      </Typography>
      <Typography
        variant='body2'
        sx={{
          fontSize: '1rem',
          color: 'black',
          fontStyle: 'italic',
          marginBottom: '20px',
        }}
      >
        (select all that applies)
      </Typography>
      <FormGroup>
        {PressedForTimeoptions.map((option) => (
          <FormControlLabel
            key={option}
            control={
              <Checkbox
                checked={selectedOptions.includes(option)}
                onChange={() => handleCheckboxChange(option)}
                sx={{ display: 'none' }}
              />
            }
            label={option}
            sx={{
              marginBottom: '10px',
              border: '1px solid #1976d2',
              borderRadius: '5px',
              padding: '10px 10px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              color: selectedOptions.includes(option) ? '#fff' : '#1976d2',
              backgroundColor: selectedOptions.includes(option)
                ? '#1976d2'
                : '#fff',
              transition: 'all 0.3s ease',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: selectedOptions.includes(option)
                  ? '#145ca8'
                  : '#e3f2fd',
              },
              marginLeft: '10px',
            }}
          />
        ))}
      </FormGroup>
      <Button
        variant='contained'
        sx={{
          backgroundColor: '#1976d2',
          color: '#fff',
          padding: '10px 20px',
          marginTop: '20px',
        }}
        onClick={handleContinue}
      >
        Continue →
      </Button>
    </Box>
  );
};

export default TimePressed1;

import { Button, Card, CardContent, FormControl, InputLabel, MenuItem, Select, Stack, Typography } from '@material-ui/core'
import { Box } from '@material-ui/system'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import { makeStyles } from '@material-ui/styles';
import { deleteCodingTestProblemURL, fetchCodingProblemsURL, fetchCodingTestProblemsURL, getHeaders, postCodingTestProblemURL } from '../../../../apis/urls';
import ErrorNotifier from '../../../ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../../ToastNotifications/SuccessNotifier';
import AddCodingTest from './AddCodingTest';

const cardStyles = makeStyles({
	card: {
		transition: 'all 0.5s ease-in-out',
		'&:hover': {
			transition: 'all 0.3s ease-in-out',
			transform: 'scale(1.02)',
		},
        textAlign: "center"
	}
});


function AdminCodingTestDetails({ selectedTest, setSelectedTest }) {
    const initialState = { 
      id: selectedTest?.testId,
      name: selectedTest?.name, 
      start_time: selectedTest?.startTime, 
      end_time: selectedTest.endTime,
      enrollment_ids: (!selectedTest?.enrollment_ids ? "" : selectedTest?.enrollment_ids) 
    };
    const [allProblems, setAllProblems] = useState({});
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isSuccess, setIsSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [currSelectedProblemId, setCurrSelectedProblemId] = useState("");
    const [allSelectedProblemIds, setAllSelectedProblemIds] = useState([]);
    const [openAddTest, setOpenAddTest] = useState(false);
    const [newTestDetails, setNewTestDetails] = useState(initialState);
    const currentProgram = useSelector(state => state.programs.currentProgram);
    const classes = cardStyles();

    useEffect(() => {
        if (currentProgram) {
          fetchAllCodeProblems();
          fetchTestProblems();
        }
        return () => {};
    }, [currentProgram]);

    function fetchAllCodeProblems() {
      axios.get(fetchCodingProblemsURL(), {
        headers: getHeaders(currentProgram?.programId)
      })
      .then((res) => {
          const tmpProblems = {};
          res?.data?.forEach((problem, index) => {
              tmpProblems[problem?.id] = problem
          });
          setAllProblems(tmpProblems);
      })
      .catch((err) => {
          console.log(err);
          setHasError(true);
          setErrorMessage(err.response?.data?.message || err.message);
      })
    }

    function fetchTestProblems() {
        axios.get(fetchCodingTestProblemsURL(selectedTest?.testId), {
            headers: getHeaders(currentProgram?.programId)
        })
        .then((res) => {
            if (res?.data?.length > 0) {
                const tmpAllProblems = res.data?.map((problem, index) => problem.code_problem.id);
                setAllSelectedProblemIds(tmpAllProblems);
            }
        })
        .catch((err) => {
            console.log(err);
            setHasError(true);
            setErrorMessage(err.response?.data?.message || err.message);
        });  
    }

    const submitProblem = (pid) => {
        if (pid === "") {
          return;
        }
        axios.post(postCodingTestProblemURL(selectedTest?.testId), {
            problem_id: pid
        }, {
            headers: getHeaders(currentProgram?.programId)
        })
        .then((res) => {
            setIsSuccess(true);
            setSuccessMessage("Test problems have been successfully updated.");
            fetchTestProblems();
        })
        .catch((err) => {
            console.log(err);
            setHasError(true);
            setErrorMessage(err.response?.data?.message || err.message);
        });
    }

    const handleChange = (e) => {
        setCurrSelectedProblemId(e.target.value);
        submitProblem(e.target.value);
    }

    const handleDeleteClick = (e, pid) => {
        axios.delete(deleteCodingTestProblemURL(selectedTest?.testId, pid), {
            headers: getHeaders(currentProgram?.programId)
        })
        .then((res) => {
            setAllSelectedProblemIds(allSelectedProblemIds.filter((problemId, i1) => problemId !== pid));
            setIsSuccess(true);
            setSuccessMessage("Test problems have been successfully updated.");
        })
        .catch((err) => {
            console.log(err);
            setHasError(true);
            setErrorMessage(err.response?.data?.message || err.message);
        });
    }

    const handleUpdateTestClick = (e) => {
        setOpenAddTest(true);
    }
    
    return (
        <Stack>
            {hasError && <ErrorNotifier message={errorMessage} setHasError={setHasError}/> }
            {isSuccess && <SuccessNotifier message={successMessage} setIsSuccess={setIsSuccess} /> }
            <Box 
                display="flex"
                justifyContent="flex-start"
                onClick={() => setSelectedTest(null)}
                sx={{cursor: 'pointer', m: 1}}
            >   
                <ArrowBackIcon fontSize='medium' color="primary" />
                <Typography variant="body2">back</Typography>
            </Box>
            
            <Box display="flex" justifyContent="flex-end" sx={{ m: 1 }}>
                <Button variant='contained' onClick={(e) => handleUpdateTestClick(e)}>
                    Update Test
                </Button>
            </Box>

            <FormControl sx={{ m: 2, minWidth: 60 }}>
                <InputLabel id="problem">Problem</InputLabel>
                <Select
                    labelId="problem"
                    id="problems"
                    value={currSelectedProblemId}
                    label="Problem"
                    onChange={handleChange}
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    {allProblems && Object.keys(allProblems).length > 0 &&
                        Object.keys(allProblems).map((problemId, index) => (
                            <MenuItem value={problemId}>{allProblems[problemId].title}</MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
            <Box>
                {allSelectedProblemIds && allSelectedProblemIds?.length > 0 &&
                    allSelectedProblemIds?.map((pid, index) => (
                        <Card sx={{margin: 2}} key={index + 1} className={classes.card}>
                            <CardContent>
                                <div style={{display: "flex", justifyContent:"space-between"}}>
                                    <Typography variant="h5">
                                        {allProblems[pid]?.title || "Problem name not available"}
                                    </Typography>
                                    <DeleteIcon 
                                        fontSize='medium'
                                        sx={{color: "red", cursor: "pointer"}}
                                        onClick={(e) => handleDeleteClick(e, pid)}
                                    />
                                </div>
                            </CardContent>
                        </Card>
                    ))
                }
            </Box>
            {openAddTest &&
                <AddCodingTest
                    openAddTest={openAddTest}
                    setOpenAddTest={setOpenAddTest}
                    newTestDetails={newTestDetails}
                    setNewTestDetails={setNewTestDetails}
                    setErrorMessage={setErrorMessage}
                    setHasError={setHasError}
                    setIsSuccess={setIsSuccess}
                    setSuccessMessage={setSuccessMessage}
                    initialState={initialState}
                    newTest={false}
                />
            }
        </Stack>
    )
}

export default AdminCodingTestDetails
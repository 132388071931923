// @ts-nocheck
import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import ErrorNotifier from '../../ToastNotifications/ErrorNotifier';
import { BASE_URL, getHeaders } from '../../../apis/urls';
import LoadingScreen from '../../../pages/LoadingScreen';
import DetailedSubmission from './DetailedSubmission';

const timeFormat = 'MMM DD, YYYY, hh:mm A';

const CodingAssignmentSubmissions = ({ assignmentId }) => {
  const [assignmentSubmissions, setAssignmentSubmissions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedSubmission, setSelectedSubmission] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const currentProgram = useSelector((state) => state.programs.currentProgram);

  useEffect(() => {
    fetchingAssignmentSubmissions();
    return () => {};
  }, []);

  const fetchingAssignmentSubmissions = () => {
    setLoading(true);

    axios
      .get(`${BASE_URL}/api/coding_assignments/${assignmentId}/submissions`, {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((res) => {
        setAssignmentSubmissions(res.data);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err?.response?.data?.message ||
            'An error occured while fetching the submissions.'
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Stack>
      <LoadingScreen loading={loading} />

      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}

      {selectedSubmission !== null && (
        <DetailedSubmission
          selectedSubmission={selectedSubmission}
          setSelectedSubmission={setSelectedSubmission}
          setHasError={setHasError}
          setErrorMessage={setErrorMessage}
        />
      )}

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Language</TableCell>
              <TableCell>Score</TableCell>
              <TableCell>Submission status</TableCell>
              <TableCell>Submission on</TableCell>
              <TableCell align='center'>Action</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {assignmentSubmissions.map((submission, index) => {
              return (
                <TableRow key={index + 1}>
                  <TableCell>{submission.attempt_number}</TableCell>

                  <TableCell>{submission.language}</TableCell>

                  <TableCell>{submission.score}</TableCell>

                  <TableCell sx={{ display: 'flex' }}>
                    {submission.submission_status === 'accepted' ? (
                      <DoneOutlineIcon
                        color='success'
                        fontSize='small'
                      />
                    ) : (
                      <CloseIcon
                        color='error'
                        fontSize='small'
                      />
                    )}
                    &nbsp;
                    {submission.submission_status}
                  </TableCell>

                  <TableCell>
                    {moment(submission.submitted_at).format(timeFormat)}
                  </TableCell>

                  <TableCell
                    align='center'
                    onClick={(e) => setSelectedSubmission(submission)}
                    sx={{
                      cursor: 'pointer',
                      color: 'blue',
                      '&:hover': {
                        textDecoration: 'underline',
                      },
                    }}
                  >
                    View submission
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>

          {assignmentSubmissions.length === 0 && (
            <Typography
              variant='body1'
              component='p'
              textAlign='center'
              alignSelf='center'
            >
              No submissions found
            </Typography>
          )}
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default CodingAssignmentSubmissions;

// @ts-nocheck
import React, { useState } from 'react';
import { Box, IconButton, Popover } from '@mui/material';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import ReactEmojiRender from 'react-emoji-render';
import axios from 'axios';
import { getHeaders, postChatReaction } from '../../../apis/urls';
import { emojis } from './emojiReactionsStore';

export default function ReactionSelectionPopup({ message }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleButtonClick = (event) => {
    if (!menuOpen) {
      setAnchorEl(event.currentTarget);
      setMenuOpen(true);
    } else {
      handleClose();
    }
  };

  const postReactions = (e) => {
    const body = {
      chat_message_id: message.id,
      reaction: e,
    };
    axios
      .post(postChatReaction(), body, {
        headers: getHeaders(),
      })
      .then((res) => {
        // getAllEmojiData();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        handleClose();
      });
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <IconButton
        size='small'
        onClick={handleButtonClick}
        sx={{
          margin: 'auto 10px',
        }}
      >
        <EmojiEmotionsIcon sx={{ fontSize: '20px', color: 'gray' }} />
      </IconButton>

      <Popover
        id='message-popover'
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        sx={{ cursor: 'pointer' }}
      >
        <Box
          padding={1}
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(7,1fr)',
          }}
        >
          {emojis.map((e) => {
            return (
              <button
                style={{
                  height: '30px',
                  display: 'flex',
                  alignItems: 'center',
                  margin: '2px',
                  background: '#fff',
                  borderRadius: '100%',
                  border: 'none',
                  fontSize: '22px',
                  cursor: 'pointer',
                }}
                onClick={() => postReactions(e)}
              >
                <ReactEmojiRender text={e} />
              </button>
            );
          })}
        </Box>
      </Popover>
    </>
  );
}

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Tab } from '@material-ui/core';
import { TabList } from '@material-ui/lab';
import { Box } from '@material-ui/system';
import { useNavigate } from 'react-router-dom';
import { slugify } from '../utils/common';
import { getHeaders, fetchPlacementPool } from '../../apis/urls';
import { getUserType, getUserId } from '../../configs/auth';
import './TabLayout.css';
import { topBarContents } from './StudentNavbarData';
import ChildTabLayout from './ChildTabLayout';
import DashboardNavbarV2 from 'src/layouts/shared/DashboardNavbarV2';

const MainTabLayoutV2 = ({
  setOpen,
  open,
  value,
  setValue,
  subTabValue,
  setSubTabValue,
}) => {
  const userType = getUserType();
  const navigate = useNavigate();
  const currentProgram = useSelector((state) => state.programs?.currentProgram);
  const programName = currentProgram?.programSlug;
  const [placementPoolData, setPlacementPoolData] = useState([]);
  const [showAnimation, setShowAnimation] = useState(false);

  useEffect(() => {
    getAllPlacementPoolData();
    const interval = setInterval(() => {
      setShowAnimation((prev) => !prev);
    }, 120000);

    return () => clearInterval(interval);
  }, []);

  const handleAccordionToggle = (index) => {
    setOpen((prevState) => {
      const newState = { ...prevState };
      Object.keys(newState).forEach((key) => {
        newState[key] = false;
      });
      newState[index] = !prevState[index];
      return newState;
    });
  };

  const getAllPlacementPoolData = async () => {
    await axios
      .get(fetchPlacementPool(), {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((res) => {
        const userDataFromPlacementPool = res?.data?.filter(
          (user) =>
            user.enrollment.user.id === getUserId() && user.status === 'active'
        );
        setPlacementPoolData(userDataFromPlacementPool);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (newValue) => {
    setValue(String(newValue));
    setSubTabValue('0');
    if (topBarContents[newValue].children.length > 0) {
      navigate(
        `/student/${programName}/${slugify(
          topBarContents[newValue].key
        )}/${slugify(topBarContents[newValue].children[0].key)}`
      );
    } else {
      navigate(
        `/student/${programName}/${slugify(topBarContents[newValue].key)}`
      );
    }
  };

  const checksToShowRespectiveTabs = (
    key,
    hideFromProgramTypes,
    hideFromRoles
  ) => {
    if (
      !currentProgram?.hasProgramStarted &&
      !['overview', 'pre-course', 'practice'].includes(key)
    ) {
      return false;
    }
    if (hideFromProgramTypes.includes(currentProgram?.type)) {
      return false;
    }
    if (hideFromRoles.includes(userType)) {
      return false;
    }
    if (key === 'placements' && placementPoolData.length === 0) {
      return false;
    }
  };

  return (
    <>
      <Box className='main-tab-layout'>
        <TabList
          orientation='vertical'
          value={value}
          indicatorColor='none'
          textColor='none'
          sx={{
            padding: '0 10px',
          }}
        >
          {topBarContents.map((tab, index) => {
            const {
              hideFromProgramTypes,
              hideFromRoles,
              key,
              label,
              children,
            } = tab;
            const isSignatureFramework = label === 'Signature Frameworks';
            const hasChild = children && children.length > 0;

            const shouldShowTab = checksToShowRespectiveTabs(
              key,
              hideFromProgramTypes,
              hideFromRoles
            );

            if (shouldShowTab) {
              return null;
            }
          return (
            <Box
              key={index}
              sx={{
                width: '220px',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
              }}
            >
              <Tab
                label={label}
                className={
                  isSignatureFramework && showAnimation
                    ? 'signature-framework'
                    : ''
                }
                value={index}
                onClick={() => {
                  handleAccordionToggle(index);
                  handleChange(index);
                }}
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'start',
                  color: 'black',
                  borderRadius: '10px',
                  backgroundColor:
                    (!hasChild && value === index) || value === index.toString()
                      ? '#ABE1FF'
                      : '',
                  '&:hover': {
                    backgroundColor: '#ABE1FF',
                  },
                  // backgroundColor: 'transparent',
                }}
              />

                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    padding: '0 0 0 20px',
                  }}
                >
                  {hasChild && (
                    <ChildTabLayout
                      open={open}
                      index={index}
                      value={value}
                      subTabValue={subTabValue}
                      children={children}
                      setSubTabValue={setSubTabValue}
                    />
                  )}
                </Box>
              </Box>
            );
          })}
        </TabList>
      </Box>
    </>
  );
};

export default MainTabLayoutV2;

import React, { useState } from 'react';
import { Box, Typography, Divider } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { updatePlacementRequestStatus, getHeaders } from '../../../apis/urls';

const ReceivingCompanyDetails = ({
  allCompaniesData,
  setShowConfetties,
  setErrorMessage,
  setHasError,
  handleNextCompany,
  currentCompanyIndex,
}) => {
  const [showLoaderInButton, setShowLoaderInButton] = useState(false);
  const currentProgram = useSelector(
    (state) => state?.programs?.currentProgram
  );

  const handleSave = async () => {
    setShowLoaderInButton(true);
    const requestId = allCompaniesData[currentCompanyIndex].id;
    const statusChange = [
      {
        id: requestId,
        status: 'contact_viewed',
      },
    ];
    try {
      await axios.patch(
        updatePlacementRequestStatus(),
        { _json: statusChange },
        {
          headers: getHeaders(currentProgram?.programId),
        }
      );
      if (currentCompanyIndex + 1 < allCompaniesData.length) {
        handleNextCompany();
      } else {
        setShowConfetties(false);
        setShowLoaderInButton(false);
      }
    } catch (error) {
      setHasError(true);
      setErrorMessage('Error updating action status');
      console.error(error);
      setShowLoaderInButton(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          textAlign: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'center',
              alignItems: 'center',
              borderRadius: '15px',
              width: '400px',
              height: '437px',
              backgroundColor: 'white',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                padding: '40px 0 20px 0',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  position: 'relative',
                }}
              >
                <Divider
                  sx={{
                    backgroundColor: 'black',
                    height: '2px',
                    width: '100%',
                    position: 'absolute',
                    transform: 'translateY(-50%)',
                  }}
                  variant='middle'
                />
                <Box
                  sx={{
                    backgroundColor: 'white',
                    zIndex: '2',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '30px',
                      zIndex: '2',
                      padding: '0 30px',
                      fontWeight: '700',
                      fontFamily: 'Lato, sans-serif',
                    }}
                  >
                    <span
                      style={{
                        backgroundImage:
                          'linear-gradient(to right, #009AF0  , #FBC704 120%)',
                        WebkitTextFillColor: 'transparent',
                        WebkitBackgroundClip: 'text',
                      }}
                    >
                      Congratulations!
                    </span>
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='44'
                height='44'
                viewBox='0 0 64 64'
                fill='none'
              >
                <g clipPath='url(#clip0_928_1689)'>
                  <path
                    d='M31.9998 45.3334C34.9332 45.3334 37.3332 42.9334 37.3332 40.0001C37.3332 37.0667 34.9332 34.6667 31.9998 34.6667C29.0665 34.6667 26.6665 37.0667 26.6665 40.0001C26.6665 42.9334 29.0665 45.3334 31.9998 45.3334ZM47.9998 21.3334H45.3332V16.0001C45.3332 8.64008 39.3598 2.66675 31.9998 2.66675C24.6398 2.66675 18.6665 8.64008 18.6665 16.0001H23.7332C23.7332 11.4401 27.4398 7.73342 31.9998 7.73342C36.5598 7.73342 40.2665 11.4401 40.2665 16.0001V21.3334H15.9998C13.0665 21.3334 10.6665 23.7334 10.6665 26.6667V53.3334C10.6665 56.2668 13.0665 58.6667 15.9998 58.6667H47.9998C50.9332 58.6667 53.3332 56.2668 53.3332 53.3334V26.6667C53.3332 23.7334 50.9332 21.3334 47.9998 21.3334ZM47.9998 53.3334H15.9998V26.6667H47.9998V53.3334Z'
                    fill='black'
                  />
                </g>
                <defs>
                  <clipPath id='clip0_928_1689'>
                    <rect
                      width='64'
                      height='64'
                      fill='white'
                    />
                  </clipPath>
                </defs>
              </svg>
            </Box>
            <Box>
              <Typography
                sx={{
                  color: '#000',
                  textAlign: 'center',
                  fontFamily: 'Open Sans, sans-serif',
                  fontSize: '20px',
                  fontWeight: '550',
                }}
              >
                DETAILS UNLOCKED
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                margin: '10px 0 0 0',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <Typography
                  sx={{
                    color: '#000',
                    textAlign: 'center',
                    fontFamily: 'Open Sans, sans-serif',
                    fontSize: '28px',
                    fontWeight: '550',
                  }}
                >
                  {
                    allCompaniesData[currentCompanyIndex]?.company_role
                      .role_title
                  }
                </Typography>

                <Typography
                  sx={{
                    color: '#000',
                    textAlign: 'center',
                    fontFamily: 'Open Sans, sans-serif',
                    fontSize: '20px',
                    fontWeight: '550',
                  }}
                >
                  {
                    allCompaniesData[currentCompanyIndex]?.company_role.company
                      .name
                  }
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  gap: '2px',
                  width: '100%',
                }}
              >
                <Typography
                  sx={{
                    color: '#000',
                    textAlign: 'center',
                    fontFamily: 'Open Sans, sans-serif',
                    fontSize: '20px',
                  }}
                >
                  {
                    allCompaniesData[currentCompanyIndex]?.company_role
                      ?.poc_name
                  }
                </Typography>
                <Typography
                  sx={{
                    color: '#000',
                    textAlign: 'center',
                    fontFamily: 'Open Sans, sans-serif',
                    fontSize: '20px',
                  }}
                >
                  {
                    allCompaniesData[currentCompanyIndex]?.company_role
                      ?.poc_email
                  }
                </Typography>

                <Typography
                  sx={{
                    color: '#000',
                    textAlign: 'center',
                    fontFamily: 'Open Sans, sans-serif',
                    fontSize: '20px',
                  }}
                >
                  {
                    allCompaniesData[currentCompanyIndex]?.company_role
                      ?.poc_contact
                  }
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                }}
              >
                <LoadingButton
                  loading={showLoaderInButton}
                  variant='contained'
                  onClick={handleSave}
                  sx={{
                    paddingX: '50px',
                    backgroundColor: '#1976D2',
                  }}
                >
                  Hurray!
                </LoadingButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ReceivingCompanyDetails;

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Chip, Grid, Typography } from '@material-ui/core';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@material-ui/lab';
import axios from 'axios';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import ScheduleIcon from '@mui/icons-material/Schedule';
import moment from 'moment';
import SingleSession from './SingleSession';
import { fetchResourcesUrl, getHeaders } from '../../apis/urls';
import { useSessionContext } from './SessionContext';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';
import ProgramNotStarted from '../ProgramNotStarted';
import { session_status } from '../utils/constants';

const { CONDUCTED, VERIFIED, PAYMENT_PROCESSED } = session_status;
const sessionCompletedStatuses = [CONDUCTED, VERIFIED, PAYMENT_PROCESSED];

function getPropertiesBasedOnSchedule(date, sessionDurationInMinutes, status) {
  if (!date) {
    return {
      color: 'text.disabled',
      status: 'unscheduled',
    };
  }
  const today = moment();
  const start = moment(date);
  const sessionHour = Math.floor(sessionDurationInMinutes / 60);
  const sessionMinute = sessionDurationInMinutes % 60;
  const properties = {};

  if (
    today.date() === start.date() &&
    today.month() === start.month() &&
    today.year() === start.year()
  ) {
    if (sessionCompletedStatuses.includes(status)) {
      properties.color = '#388e3c';
      properties.status = 'done';
    } else {
      properties.color = '#ffa726'; // orange
      properties.status = 'today';
    }
  } else if (start.isAfter(today)) {
    properties.color = 'grey';
    properties.status = 'pending';
  } else {
    switch (true) {
      case sessionCompletedStatuses.includes(status):
        properties.color = '#388e3c';
        properties.status = 'done';
        break;
      default:
        properties.color = '#FF0000';
        properties.status = 'not_marked_conducted';
        break;
    }
  }
  return properties;
}

function getGroupProperties(sessions) {
  /**
   * returns 'done' when all the sessions of the group have been conducted.
   * returns 'today' if there's atleast one session scheduled today in the group.
   * returns 'pending' for future sessions having date, or no date assigned.
   */
  const today = moment();
  const properties = {
    color: '#388e3c',
    status: 'done',
  };

  let allDone = true;
  let scheduledToday = false;
  let hasUnMarkedSession = false;
  sessions.forEach((session, idx) => {
    if (!session.start) {
      // We haven't yet assigned a date to this session. Hence, the session is still "unscheduled".
      allDone = false;
      return;
    }
    const date = moment(session.start);
    const sessionHour = Math.floor(session?.session_type?.duration / 60);
    const sessionMinute = session?.session_type?.duration % 60;

    if (
      today.date() === date.date() &&
      today.month() === date.month() &&
      today.year() === date.year()
    ) {
      const laterToday = moment(session.start);
      laterToday.add(sessionHour, 'hours').add(sessionMinute, 'minutes');

      if (!sessionCompletedStatuses.includes(session.status)) {
        allDone = false;
        scheduledToday = true;
      }
    } else if (date.isAfter(today)) {
      allDone = false;
    } else if (!sessionCompletedStatuses.includes(session.status)) {
      allDone = false;
      hasUnMarkedSession = true;
    }
  });

  if (scheduledToday) {
    properties.color = '#ffa726';
    properties.status = 'today';
  } else if (!allDone) {
    if (hasUnMarkedSession) {
      properties.color = '#FF0000';
      properties.status = 'not_marked_conducted';
    } else {
      properties.color = 'grey';
      properties.status = 'pending';
    }
  }
  return properties;
}

function SessionStatusIcon({ color, status }) {
  switch (status) {
    case 'done':
      return (
        <CheckCircleIcon
          fontSize='small'
          sx={{ color }}
        />
      );
    case 'today':
      return (
        <ScheduleIcon
          fontSize='small'
          sx={{ color }}
        />
      );
    case 'pending':
      return (
        <PendingOutlinedIcon
          fontSize='small'
          sx={{ color }}
        />
      );
    default:
      return (
        <PendingOutlinedIcon
          fontSize='small'
          sx={{ color }}
        />
      );
  }
}

export const Sessions = () => {
  const [selectedSessionId, setSelectedSessionId] = useState('');
  const [singleSessionData, setSingleSessionData] = useState({});
  const { setMaterialResource } = useSessionContext();
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [groupToSessions, setGroupToSessions] = useState({});
  const currentProgram = useSelector((state) => state.programs.currentProgram);
  const sessions = useSelector((state) => state.sessions.sessions);
  const [sessionChanged, setSessionChanged] = useState(false);

  useEffect(() => {
    if (sessions.length > 0) {
      const tmpGroups = {};
      let groupCount = 1;
      let tmpFirstSession = {};
      let lastGroup = '';
      Object.keys(sessions).forEach((sessId, idx) => {
        const session = sessions[sessId];
        if (!session?.group_name) {
          return;
        }

        const { group_name } = session;
        if (lastGroup === group_name) {
          tmpGroups[`${group_name}_${groupCount - 1}`].sessions.push(session);
          return;
        }

        lastGroup = group_name;
        tmpGroups[`${group_name}_${groupCount}`] = {
          groupId: groupCount,
          sessions: [session],
        };
        if (groupCount === 1) {
          tmpFirstSession = session;
        }
        groupCount += 1;
      });

      setGroupToSessions(tmpGroups);
      setSingleSessionData(tmpFirstSession);
      if (Object.keys(tmpFirstSession).length !== 0) {
        setSelectedSessionId(tmpFirstSession.id);
      }
    }
    return () => {};
  }, [sessions]);

  useEffect(() => {
    if (
      Object.keys(singleSessionData)?.length > 0 &&
      currentProgram?.hasProgramStarted
    ) {
      getResources();
    }
    return () => {};
  }, [singleSessionData]);

  function TimelineSessionNameItem({ session }) {
    const { color, status } = getPropertiesBasedOnSchedule(
      session.start,
      session.session_type.duration,
      session.status
    );

    function getLabel() {
      if (status === 'today') {
        return `Today, ${moment(session.start).format('h:mm A')}`;
      }
      if (status === 'unscheduled') {
        return 'Unscheduled';
      }
      return moment(session.start).format('MMM DD, h:mm A');
    }

    return (
      <TimelineItem
        key={session.id}
        sx={{ minHeight: 50, cursor: 'pointer' }}
        onClick={(e) => handleSessionSelect(session)}
      >
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot
            variant='outlined'
            sx={{ alignSelf: 'center', borderStyle: 'none', margin: 0 }}
          >
            <SessionStatusIcon
              color={color}
              status={status}
            />
          </TimelineDot>
        </TimelineSeparator>
        <TimelineContent
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            color,
          }}
        >
          <Typography
            variant={selectedSessionId === session.id ? 'h6' : 'body1'}
          >
            {session.title}
          </Typography>
          <Chip
            label={getLabel()}
            variant='outlined'
            sx={{ color, fontWeight: 'bold' }}
          />
        </TimelineContent>
      </TimelineItem>
    );
  }

  function TimelineSessionGroupNameItem({ groupName, groupId }) {
    const { color, status } = getGroupProperties(
      groupToSessions[groupName]?.sessions
    );

    return (
      <TimelineItem
        key={groupId}
        sx={{ minHeight: 0 }}
      >
        <TimelineDot
          variant='outlined'
          sx={{ alignSelf: 'center', borderStyle: 'none', margin: 0 }}
        >
          <SessionStatusIcon
            color={color}
            status={status}
          />
        </TimelineDot>
        <TimelineContent sx={{ alignSelf: 'center', color }}>
          {groupName.split('_')[0]}
        </TimelineContent>
      </TimelineItem>
    );
  }

  const handleSessionSelect = (session) => {
    setSelectedSessionId(session?.id);
    setSessionChanged(true);
    setSingleSessionData(session);
  };

  function getResources() {
    const topic_ids = [singleSessionData?.id];
    axios
      .get(
        `${fetchResourcesUrl()}/get_by_topics/?topic_ids[]=${topic_ids.join(
          ','
        )}`,
        {
          headers: getHeaders(currentProgram?.programId),
        }
      )
      .then((res) => {
        setMaterialResource(res.data);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(err.response?.data?.message || err.message);
      });
  }

  if (!currentProgram?.hasProgramStarted) {
    return <ProgramNotStarted />;
  }

  return (
    <Grid container>
      <Grid
        item
        lg={3.5}
      >
        {hasError && (
          <ErrorNotifier
            message={errorMessage}
            setHasError={setHasError}
          />
        )}
        <Box
          sx={{
            height: '70vh',
            padding: '0',
            flexGrow: 1,
            overflowY: 'auto',
            borderRight: '1px solid gray',
          }}
        >
          <Timeline
            sx={{
              '& .MuiTimelineItem-root:before': {
                flex: 0,
                padding: 0,
              },
            }}
          >
            {Object.keys(groupToSessions)?.length > 0 &&
              Object.keys(groupToSessions)?.map((groupName, idx1) => {
                return (
                  <>
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <TimelineSessionGroupNameItem
                          groupName={groupName}
                          groupId={groupToSessions[groupName]?.groupId}
                        />
                      </AccordionSummary>
                      <AccordionDetails>
                        <Timeline
                          sx={{
                            '& .MuiTimelineItem-root:before': {
                              flex: 0,
                              paddingLeft: 1,
                            },
                          }}
                        >
                          {groupToSessions[groupName]?.sessions?.map(
                            (session, idx2) => {
                              return (
                                <TimelineSessionNameItem session={session} />
                              );
                            }
                          )}
                        </Timeline>
                      </AccordionDetails>
                    </Accordion>
                  </>
                );
              })}
          </Timeline>
        </Box>
      </Grid>
      <Grid
        item
        lg={8.5}
      >
        <SingleSession
          sessionChanged={sessionChanged}
          setSessionChanged={setSessionChanged}
          sessionData={singleSessionData}
          getSessionResources={() => getResources()}
        />
      </Grid>
    </Grid>
  );
};

import React from 'react';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { getUserType } from '../../configs/auth';

const ErrorBoundary = () => {
  const navigate = useNavigate();
  const userRole = getUserType();

  const handleNavigation = () => {
    console.log('clicked');
    switch (userRole) {
      case 'program_manager':
      case 'admin':
      case 'teaching_assistant':
        navigate('/mentor/programs');
        break;
      case 'student':
      case 'coach':
        navigate('/student/my-courses');
        break;
      case 'finance':
        navigate('/mentor/finances');
        break;
      case 'problem_setter':
        navigate('/mentor/problems_library');
        break;
      case 'visitor':
        navigate('/eligibility-forms');
        break;
      default:
        navigate('/');
    }
    window.location.reload();
  };

  return (
    <div style={{ position: 'relative', height: '100vh', overflow: 'hidden' }}>
      <img
        src='/static/error-page.png'
        alt='Error'
        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
      />
      <div
        style={{
          position: 'absolute',
          bottom: '30px',
          left: '50%',
          transform: 'translateX(-50%)',
          textAlign: 'center',
        }}
      >
        <Button
          style={{ backgroundColor: '#3994FF' }}
          variant='contained'
          onClick={handleNavigation}
        >
          Back to home
        </Button>
      </div>
    </div>
  );
};

export default ErrorBoundary;

// @ts-nocheck
import React, { useState } from 'react';
import { Box, Typography, Popover } from '@mui/material';
import ReactEmojiRender from 'react-emoji-render';
import axios from 'axios';
import { getUserId } from '../../../configs/auth';
import { deteleReaction, getHeaders } from '../../../apis/urls';

export default function MessageReactionViews({ message }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleButtonClick = (event) => {
    if (!menuOpen) {
      setAnchorEl(event.currentTarget);
      setMenuOpen(true);
    } else {
      handleClose();
    }
  };

  const handleDelete = (id) => {
    axios
      .delete(deteleReaction(id), {
        headers: getHeaders(),
      })
      .then((res) => {})
      .catch((e) => {
        console.log(e.message);
      });

    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          marginLeft: message.from_self ? 'auto' : 0,
          marginRight: message.from_self ? 0 : 'auto',
          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px;',
          marginTop: '-12px',
          borderRadius: '20px',
          zIndex: '1',
          background: '#fff',
          padding: '1px 2px 1px 2px',
          cursor: 'pointer',
          marginBottom: '20px',
        }}
        onClick={handleButtonClick}
      >
        {message?.chat_message_reactions.map((e) => {
          return <ReactEmojiRender text={e.reaction} />;
        })}
      </Box>

      <Popover
        id='message-popover'
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: message.from_self ? 'right' : 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: message.from_self ? 'right' : 'left',
        }}
        sx={{
          cursor: 'default',
        }}
      >
        <Box sx={{ width: '350px' }}>
          <Box sx={{ padding: '4px' }}>
            <Typography
              variant='body2'
              color='textSecondary'
              textAlign='center'
              fontWeight='500'
            >
              Reacted Users
            </Typography>
          </Box>

          <Box
            sx={{
              height: '150px',
              overflowY: 'auto',
            }}
          >
            {message?.chat_message_reactions.map((e) => {
              return (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '8px',
                    borderTop: '1px solid #d6d4d4',
                    '&:hover': {
                      background: '#edffff',
                    },
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                >
                  <Box>
                    <Typography
                      variant='body2'
                      color='textSecondary'
                      fontSize='16px'
                      fontWeight='500'
                    >
                      {e.added_by.name}
                    </Typography>

                    {getUserId() === e.added_by.id && (
                      <Typography
                        variant='body2'
                        color='textSecondary'
                        fontSize='12px'
                        fontWeight='500'
                        sx={{ color: '#bd5555' }}
                        onClick={(event) => handleDelete(e.id)}
                      >
                        Click here to Remove
                      </Typography>
                    )}
                  </Box>
                  <ReactEmojiRender text={e.reaction} />
                </Box>
              );
            })}
          </Box>
        </Box>
      </Popover>
    </>
  );
}

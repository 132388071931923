import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { TabContext } from '@material-ui/lab';
import { Box } from '@material-ui/system';
import { Outlet, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { getUserType, getUserId } from '../../configs/auth';
import { slugify } from '../utils/common';
import {
  fetchProgramSessionsUrl,
  getHeaders,
  getLastResponseTime,
} from '../../apis/urls';
import FeedbackForm from '../feedback/FeedbackForm';
import SignatureFrameworkUnlocked from '../modal/SignatureFrameworkUnlocked';
import './TabLayout.css';
import { topBarContents } from './StudentNavbarData';
import DividerComponent from './DividerComponent';
import MainTabLayout from './MainTabLayout';
import TabRender from './TabRender';
import FrustrationLevelModal from '../feedback/FrustrationLevelModal';

const TabLayout = () => {
  const userType = getUserType();
  const navigate = useNavigate();
  const [value, setValue] = useState('0');
  const [subTabValue, setSubTabValue] = useState('0');
  const currentProgram = useSelector((state) => state.programs?.currentProgram);
  const programName = currentProgram?.programSlug;
  const [programSessions, setProgramSessions] = useState([]);
  const [lastSubmittedFormResponse, setLastSubmittedFormResponse] =
    useState(null);
  const [open, setOpen] = useState(false);
  const onClose = () => setOpen(false);
  const dispatch = useDispatch();
  const hasIntroducedSignatureFramework = localStorage.getItem(
    'hasIntroducedSignatureFramework'
  );
  const [showSignatureFramework, setShowSignatureFramework] = useState(
    !(hasIntroducedSignatureFramework ?? false)
  );

  useEffect(() => {
    getLastResponse();
  }, [currentProgram]);

  useEffect(() => {
    navigate(`/student/${programName}/${slugify(topBarContents[0].key)}`);
  }, [programName]);

  const getLastResponse = () => {
    // API call for getting last response time
    axios
      .get(getLastResponseTime(), {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((res) => {
        setLastSubmittedFormResponse(res?.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  if (showSignatureFramework && userType === 'student') {
    return (
      <>
        <SignatureFrameworkUnlocked
          setShowSignatureFramework={setShowSignatureFramework}
          showSignatureFramework={showSignatureFramework}
        />
      </>
    );
  }

  return (
    <>
      <>
        {lastSubmittedFormResponse?.show_form && (
          <FeedbackForm
            open={open}
            onClose={onClose}
            lastSubmittedFormResponse={lastSubmittedFormResponse}
            setLastSubmittedFormResponse={setLastSubmittedFormResponse}
          />
        )}

        {false && (
          <FrustrationLevelModal
            open={open}
            onClose={onClose}
            lastSubmittedFormResponse={lastSubmittedFormResponse}
            setLastSubmittedFormResponse={setLastSubmittedFormResponse}
          />
        )}
        <TabContext value={value}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              height: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
              }}
            >
              <MainTabLayout
                open={open}
                setOpen={setOpen}
                value={value}
                setValue={setValue}
                subTabValue={subTabValue}
                setSubTabValue={setSubTabValue}
              />
              <DividerComponent />
            </Box>
            <TabRender
              value={value}
              subTabValue={subTabValue}
            />
          </Box>
        </TabContext>
      </>
    </>
  );
};

export default TabLayout;

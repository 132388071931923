import React, { useEffect, useState } from 'react';
import {
  Backdrop,
  Box,
  Modal,
  Fade,
  Button,
  Typography,
  TextField,
  CircularProgress,
} from '@mui/material';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import axios from 'axios';
import {
  addLearnerToPlacementPool,
  getHeaders,
  getUserThroughEmail,
} from '../../../apis/urls';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: '400px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
  borderRadius: '10px',
};

const AddLearner = ({
  openAddLearnerModal,
  setIsSuccess,
  setSuccessMessage,
  setHasError,
  setErrorMessage,
  setOpenAddLearnerModal,
}) => {
  const [learnerData, setLearnerData] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const delayedSearch = setTimeout(() => {
      if (searchText && searchText.length >= 3) {
        handleSearch();
      }
    }, 1000);

    return () => clearTimeout(delayedSearch);
  }, [searchText]);

  const handleSearchText = (e) => {
    setSearchText(e.target.value);
  };

  const handleCloseAddLearnerModal = () => {
    setOpenAddLearnerModal(false);
    setSearchResults([]);
    setSearchText('');
  };

  const handleSearch = async () => {
    try {
      setLoading(true);
      const response = await axios.get(getUserThroughEmail(searchText), {
        headers: getHeaders(),
      });
      if (response?.data?.message) {
        setHasError(true);
        setErrorMessage(response?.data?.message);
        setSearchResults([]);
      } else {
        setSearchResults(response?.data[0]);
        setIsSuccess(true);
        setSuccessMessage('Learner Found');
        setLearnerData({ id: response?.data[0]?.enrollment?.id });
      }
    } catch (err) {
      setHasError(true);
      setErrorMessage(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleAddLearner = async () => {
    try {
      await axios.post(addLearnerToPlacementPool(), learnerData, {
        headers: getHeaders(),
      });
      setIsSuccess(true);
      setSuccessMessage('Learner Added Successfully');
    } catch (err) {
      console.error(err);
      setHasError(true);
      setErrorMessage(err.message);
    }
  };

  return (
    <Modal
      open={openAddLearnerModal}
      onClose={handleCloseAddLearnerModal}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={openAddLearnerModal}>
        <Box sx={style}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'center',
              gap: '15px',
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: '30px',
                  fontWeight: '700',
                }}
              >
                Add Learner
              </Typography>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                textAlign: 'start',
                gap: '20px',
              }}
            >
              <Box
                sx={{
                  width: '100%',
                }}
              >
                <TextField
                  fullWidth
                  placeholder='Search by email id'
                  value={searchText}
                  onChange={handleSearchText}
                />
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  textAlign: 'center',
                  width: '20%',
                }}
              >
                {loading ? <CircularProgress /> : <SearchRoundedIcon />}
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'start',
                gap: '10px',
                borderRadius: '10px',
                backgroundColor: '#ffffbf',
                padding: '15px',
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Typography>Name</Typography>
                <Typography>
                  {searchResults?.user?.name || 'Not Found'}
                </Typography>
              </Box>

              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Typography>Email</Typography>
                <Typography>
                  {searchResults?.user?.email || 'Not Found'}
                </Typography>
              </Box>

              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Typography>Contact No.</Typography>
                <Typography>
                  {searchResults?.user?.phone || 'Not Found'}
                </Typography>
              </Box>

              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Typography>Program</Typography>
                <Typography>
                  {searchResults?.program?.name || 'Not Found'}
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
            >
              <Button
                variant='contained'
                onClick={handleAddLearner}
                disabled={searchResults.length === 0}
              >
                Add to placement pool
              </Button>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default AddLearner;

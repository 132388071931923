import React from 'react';
import { withStyles, makeStyles } from '@material-ui/styles';
import {
    Button,
    Table,
    TableContainer,
    TableBody,
    TableRow,
    TableHead,
    Paper,
    TableCell
} from '@material-ui/core';
import { format } from 'date-fns';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        textAlign: 'center'
    },
    body: {
        fontSize: 14,
        textAlign: 'center'
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover
        }
    }
}))(TableRow);
const useStyles = makeStyles({
    table: {
        minWidth: 700
    }
});

const SubmissionsTable = ({ submission, Handler }) => {
    const classes = useStyles();
    return (
        <TableContainer component={Paper} style={{ height: 700 }}>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Student ID</StyledTableCell>
                        <StyledTableCell>Student Name</StyledTableCell>
                        <StyledTableCell>Submission</StyledTableCell>
                        <StyledTableCell>Submitted On</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <>
                        {submission?.length !== 0 &&
                            submission &&
                            submission
                                ?.filter(
                                    (v, i, a) => a.findIndex((t) => t.user_id === v.user_id) === i
                                )
                                ?.map((subs) => {
                                    return (
                                        <StyledTableRow key={subs.user_id}>
                                            <StyledTableCell>{subs.user_id}</StyledTableCell>
                                            <StyledTableCell>{subs.student_name}</StyledTableCell>
                                            <StyledTableCell>
                                                <Button onClick={() => Handler(subs)}>
                                                    View All Submissions
                                                </Button>
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                {format(new Date(subs.created_at), 'do MMMM yyyy')}
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    );
                                })}
                    </>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default SubmissionsTable;

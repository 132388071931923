// @ts-nocheck
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Box, Stack, Typography } from '@mui/material';
import MissOutImage from '../../assets/pop-up_1.png';
import { useFastrackPipeline } from 'src/layouts/EfPipelineContext/FastrackPipelineContex';

const FastrackOfferLetterExitPopup = ({ setShowOfferLetter }) => {
  const { trackButtonClick } = useFastrackPipeline();
  const [showPopup, setShowPopup] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    document.addEventListener('mouseleave', handleMouseLeave);
    return () => {
      document.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, [showPopup]); // This is required because when we attach event listeners in react using `addEventListener`, we cannot access updated react states inside the event handler.

  const handleMouseLeave = (event) => {
    if (event.clientY <= 0 && !showPopup) {
      setShowPopup(true);
      trackButtonClick({ source: 'fastrack_ol_screen', eventName: 'exit' });
    }
  };

  const handleClosePopup = (event, reason) => {
    setShowPopup(false);
  };

  const handleSecureYouSeat = () => {
    setShowPopup(false);
    setShowOfferLetter(true);
    trackButtonClick({
      source: 'fastrack_ol_screen_exit_popup',
      eventName: 'release_offer_letter',
    });
  };

  return (
    <Dialog
      maxWidth='sm'
      fullWidth
      open={showPopup}
      onClose={handleClosePopup}
      PaperProps={{
        style: {
          maxWidth: '500px',
          height: 'fit-content',
        },
      }}
    >
      <img
        style={{
          maxWidth: '500px',
        }}
        src={MissOutImage}
        alt='missout-image'
      />
      <Stack
        spacing={1.5}
        padding='1rem'
        maxWidth='inherit'
      >
        <Typography
          variant='h4'
          component='h4'
          sx={{
            fontFamily: 'Inter',
            flexWrap: 'wrap',
          }}
        >
          <b>Don't miss out on your future!</b>
        </Typography>

        <Typography
          variant='body1'
          component='p'
          sx={{
            fontFamily: 'Inter',
            flexWrap: 'wrap',
          }}
        >
          You're just a step away from securing your spot in the program. If you
          leave now, you may lose your offer and the chance to advance your
          career with us. The clock is ticking, and so is your opportunity!
        </Typography>

        <Box
          display='flex'
          justifyContent='center'
        >
          <Button
            variant='contained'
            onClick={handleSecureYouSeat}
            sx={{
              fontFamily: 'Inter',
              margin: '10px',
              padding: '11px 16px',
              textAlign: 'center',
              textTransform: 'uppercase',
              transition: '0.5s',
              backgroundSize: '200% auto',
              color: 'white',
              borderRadius: '10px',
              border: 0,
              fontWeight: 700,
              boxShadow: '0px 0px 14px -7px #f09819',
              backgroundImage:
                'linear-gradient(45deg, #FF512F 0%, #F09819 51%, #FF512F 100%)',
              cursor: 'pointer',
              userSelect: 'none',
              WebkitUserSelect: 'none',
              touchAction: 'manipulation',
              '&:hover': {
                backgroundPosition: 'right center',
                color: '#fff',
                textDecoration: 'none',
              },
              '&:active': {
                transform: 'scale(0.95)',
              },
            }}
          >
            Secure your seat
          </Button>
        </Box>
      </Stack>
    </Dialog>
  );
};

export default FastrackOfferLetterExitPopup;

// @ts-nocheck
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import moment from 'moment';
import { Typography } from '@mui/material';
import { Container } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { getHeaders, getLevelTestAttempts } from '../../../apis/urls';

const headers = ['Name', 'Score', 'Result', 'Submitted At'];

export default function LevelTestAttempts() {
  const currentProgram = useSelector((state) => state.programs.currentProgram);
  const [testAttempts, setTestAttempts] = useState([]);

  useEffect(() => {
    fetchCodingTests();
    return () => {};
  }, []);

  const fetchCodingTests = () => {
    axios
      .get(getLevelTestAttempts(), {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((res) => {
        setTestAttempts(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getResult = (testAttempt) => {
    if (typeof testAttempt.score === 'number') {
      return testAttempt.is_passed ? 'Passed' : 'Failed';
    }
    return 'Pending';
  };

  const getScore = (testAttempt) => {
    if (typeof testAttempt.score === 'number') {
      return testAttempt.is_passed ? '100' : '0';
    }
    return '-';
  };

  if (testAttempts.length === 0) {
    return (
      <Typography
        variant='body1'
        textAlign='center'
        component='p'
        mt={2}
      >
        No attempts available
      </Typography>
    );
  }

  return (
    <Container>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              {headers.map((header, index) => {
                return <TableCell key={index}>{header}</TableCell>;
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {testAttempts.map((testAttempt, idx) => (
              <TableRow
                key={idx}
                sx={{
                  '&:last-child td, &:last-child th': {
                    border: 0,
                  },
                }}
              >
                <TableCell
                  component='th'
                  scope='row'
                >
                  {testAttempt.name}
                </TableCell>

                <TableCell>{getScore(testAttempt)}</TableCell>
                <TableCell>{getResult(testAttempt)}</TableCell>
                <TableCell>
                  {moment(testAttempt.submitted_at).format(
                    'YYYY-MM-DD HH:mm:ss'
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}

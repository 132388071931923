import React, { useState, useContext, useEffect } from 'react';
import { Tab } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { useNavigate } from 'react-router-dom';
import { Box } from '@material-ui/system';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import {
  setPrograms,
  setCurrentProgram,
  unsetCurrentProgram,
} from '../redux-store/slices/programSlice';
import { Typography } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Courses from './Courses';
import SavedItems from './SavedItems';
import { getUserType } from '../../configs/auth';
import CoachingLogs from '../CoachingLogs/CoachingLogs';
import MentorSessionCoach from '../_dashboard/Mentor/MentorSessionCoach';

export default function MyLearnings() {
  const [currTab, setCurrTab] = useState('1');
  const userType = getUserType();
  const isCoach = userType === 'coach';
  const programs = useSelector((state) => state.programs.programs);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const handleTabChange = (e, tabIndex) => {
    setCurrentTabIndex(tabIndex);
  };

  const handleChange = (event, newcurrTab) => {
    setCurrTab(newcurrTab);
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={currTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList
            onChange={handleChange}
            variant='fullWidth'
          >
            <Tab
              label='Courses'
              value='1'
            />
            {/* <Tab label="Saved Items" value="2" /> */}
            {isCoach && (
              <Tab
                label='Coaching logs'
                value='3'
              />
            )}
          </TabList>
        </Box>
        <TabPanel value='1'>
          <Courses />
        </TabPanel>
        <TabPanel value='2'>
          <SavedItems />
        </TabPanel>
        <TabPanel
          value='3'
          style={{
            height: '100vh',
          }}
        >
          <div style={{ display: 'flex', height: '100%' }}>
            <Tabs
              value={currentTabIndex}
              orientation='vertical'
              onChange={handleTabChange}
              sx={{
                borderRight: '2px solid black',
                width: '350px',
                marginRight: '20px',
                paddingRight: '20px',
              }}
            >
              <Tab
                sx={{
                  fontWeight: '500',
                  alignItems: 'start',
                  color: 'black',
                  borderRadius: '10px',
                  backgroundColor: currentTabIndex === 0 ? '#ABE1FF' : '#fff',
                  '&:hover': {
                    backgroundColor: '#ABE1FF',
                    color: 'black',
                  },
                }}
                label='Sessions'
              />
              <Tab
                sx={{
                  fontWeight: '500',
                  alignItems: 'start',
                  color: 'black',
                  borderRadius: '10px',
                  backgroundColor: currentTabIndex === 1 ? '#ABE1FF' : '#fff',
                  '&:hover': {
                    backgroundColor: '#ABE1FF',
                    color: 'black',
                  },
                }}
                label='Mentor Sessions'
              />
            </Tabs>

            <div
              style={{
                width: '100%',
                left: '90px',
                width: '100%',
                position: 'relative',
              }}
            >
              {/* TAB 1 Contents */}
              {currentTabIndex === 0 && (
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    color: 'black',
                  }}
                >
                  <CoachingLogs />
                </Box>
              )}

              {/* TAB 2 Contents */}
              {currentTabIndex === 1 && (
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    color: 'black',
                  }}
                >
                  <MentorSessionCoach />
                </Box>
              )}
            </div>
          </div>
        </TabPanel>
      </TabContext>
    </Box>
  );
}

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Backdrop,
  Stack,
  Typography,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import CircularProgress from '@mui/material/CircularProgress';
import ErrorNotifier from '../../ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../ToastNotifications/SuccessNotifier';
import {
  postInteractionUrl,
  getHeaders,
  fetchAllInteractionsWithUser,
  getLearnerTickets,
  getLearnerFeedbacks,
} from '../../../apis/urls';
import InteractionList from '../../_dashboard/Programs/Interactions/InteractionList';
import AddInteraction from '../Atoms/AddInteraction';
import TicketsList from '../../_dashboard/Programs/Interactions/TicketsList';
import NpsList from '../../_dashboard/Programs/Interactions/NpsList';
import CombinedList from '../../_dashboard/Programs/Interactions/CombinedList';

export default function Interactions({
  enrollmentId,
  programId,
  name,
  isFocusMode = false,
  nextLearner = () => {},
}) {
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [interactions, setInteractions] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [feedbacks, setFeedbacks] = useState([]);
  const [selectedInteractionType, setSelectedInteractionType] = useState('');
  const [selectedTabs, setSelectedTabs] = useState([
    'interactions',
    'tickets',
    'nps',
  ]); // All tabs selected by default

  useEffect(() => {
    if (enrollmentId) {
      handleEnrollmentSelect(enrollmentId);
      handleTicketSelect(enrollmentId);
      handleFeedbacks(enrollmentId);
    }
  }, [enrollmentId]);

  const handleFeedbacks = (enrollment_id) => {
    setLoading(true);
    axios
      .get(getLearnerFeedbacks(enrollment_id), {
        headers: getHeaders('2'),
      })
      .then((res) => {
        setFeedbacks(res?.data.reverse() ?? []);
      })
      .catch((err) => {
        setHasError(true);
        setErrorMessage("Couldn't fetch the feedbacks.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTicketSelect = (enrollment_id) => {
    setLoading(true);
    axios
      .get(getLearnerTickets(enrollment_id), {
        headers: getHeaders('2'),
      })
      .then((res) => {
        setTickets(res?.data?.tickets.reverse() ?? []);
      })
      .catch((err) => {
        setHasError(true);
        setErrorMessage("Couldn't fetch the tickets.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleEnrollmentSelect = (enrollment_id) => {
    setLoading(true);
    axios
      .get(fetchAllInteractionsWithUser(enrollment_id), {
        headers: getHeaders('2'),
      })
      .then((res) => {
        setInteractions(res?.data.reverse() ?? []);
      })
      .catch((err) => {
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || "Couldn't fetch the interactions"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const postInteraction = (newInteraction, interaction_type = 'cad') => {
    setLoading(true);
    const body = {
      notes: newInteraction,
      enrollment_id: enrollmentId,
      interaction_type: interaction_type,
    };

    axios
      .post(postInteractionUrl(), body, {
        headers: getHeaders(programId),
      })
      .then((res) => {
        setInteractions([res.data, ...interactions]);
        if (isFocusMode) {
          nextLearner();
        }
      })
      .catch((err) => {
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || "Couldn't add new interaction."
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleInteractionTypeChange = (event) => {
    setSelectedInteractionType(event.target.value);
  };

  const handleUpdateActivity = () => {
    if (selectedInteractionType === 'answered-callback') {
      postInteraction(`${name} has requested for a callback`, 'rcb');
    } else if (selectedInteractionType === 'did-not-pick') {
      postInteraction(`${name} did not pick up the call`, 'dnp');
    }
  };

  const toggleTab = (tab) => {
    setSelectedTabs((prevSelectedTabs) =>
      prevSelectedTabs.includes(tab)
        ? prevSelectedTabs.filter((t) => t !== tab)
        : [...prevSelectedTabs, tab]
    );
  };

  const renderCombinedList = () => {
    if (selectedTabs.length === 0) return null;

    const combinedData = [
      ...(selectedTabs.includes('interactions')
        ? interactions.map((item) => ({ ...item, type: 'interaction' }))
        : []),
      ...(selectedTabs.includes('tickets')
        ? tickets.map((item) => ({ ...item, type: 'ticket' }))
        : []),
      ...(selectedTabs.includes('nps')
        ? feedbacks.map((item) => ({ ...item, type: 'feedback' }))
        : []),
    ];

    combinedData.sort(
      (a, b) => new Date(b.created_at) - new Date(a.created_at)
    );

    return <CombinedList data={combinedData} />;
  };

  return (
    <Stack
      width='100%'
      paddingY='10px'
      spacing={3}
    >
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      <Backdrop
        sx={{
          color: '#fff',
          height: '100vh',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loading}
      >
        <CircularProgress color='inherit' />
      </Backdrop>

      {/* Always visible RadioGroup */}
      <Typography sx={{ fontSize: '18px', fontWeight: '700' }}>
        Select an interaction type
      </Typography>

      <RadioGroup
        aria-labelledby='interaction-type-label'
        name='interaction-type'
        row
        value={selectedInteractionType}
        onChange={handleInteractionTypeChange}
        sx={{ marginY: 2 }} // Add margin for spacing
      >
        <FormControlLabel
          value='answered-discussed'
          control={<Radio />}
          label='Call answered & discussed'
        />
        <FormControlLabel
          value='answered-callback'
          control={<Radio />}
          label='Call answered & requested callback'
        />
        <FormControlLabel
          value='did-not-pick'
          control={<Radio />}
          label='Did not pick'
        />
      </RadioGroup>

      {/* AddInteraction and Update Activity button */}
      <Stack spacing={3}>
        {enrollmentId && selectedInteractionType === 'answered-discussed' && (
          <AddInteraction postInteraction={postInteraction} />
        )}

        {enrollmentId &&
          (selectedInteractionType === 'answered-callback' ||
            selectedInteractionType === 'did-not-pick') && (
            <Button
              variant='contained'
              onClick={handleUpdateActivity}
              sx={{
                width: 'fit-content',
                alignSelf: 'flex-end',
              }}
            >
              Update Activity
            </Button>
          )}
      </Stack>

      <Typography
        sx={{ fontSize: '18px', fontWeight: '700', marginY: '0 !important' }}
      >
        Previous Interactions
      </Typography>
      <Stack
        direction='row'
        spacing={2}
      >
        <Button
          variant={
            selectedTabs.includes('interactions') ? 'contained' : 'outlined'
          }
          onClick={() => toggleTab('interactions')}
          sx={{
            borderRadius: '20px',
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none',
            },
          }}
        >
          Interactions
        </Button>
        <Button
          variant={selectedTabs.includes('tickets') ? 'contained' : 'outlined'}
          onClick={() => toggleTab('tickets')}
          sx={{
            borderRadius: '20px',
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none',
            },
          }}
        >
          Tickets
        </Button>
        <Button
          variant={selectedTabs.includes('nps') ? 'contained' : 'outlined'}
          onClick={() => toggleTab('nps')}
          sx={{
            borderRadius: '20px',
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none',
            },
          }}
        >
          NPS
        </Button>
      </Stack>
      <Stack spacing={3}>
        <Stack
          sx={{ height: isFocusMode ? '200px' : '300px', overflowY: 'scroll' }}
        >
          {renderCombinedList()}
        </Stack>
      </Stack>

      {/*{selectedTabs.includes('interactions') && (
        <Stack spacing={3}>
          <Stack sx={{ overflowY: 'scroll', height: isFocusMode ? '200px' : '300px' }}>
            <CombinedList interactions={interactions} />
          </Stack>
        </Stack>
      )}

      {selectedTabs.includes('tickets') && (
        <Stack spacing={3}>
          <Stack sx={{ overflowY: 'scroll', height: isFocusMode ? '200px' : '300px' }}>
            <CombinedList tickets={tickets} />
          </Stack>
        </Stack>
      )}

      {selectedTabs.includes('nps') && (
        <Stack spacing={3}>
          <Stack sx={{ overflowY: 'scroll', height: isFocusMode ? '200px' : '300px' }}>
            <CombinedList feedbacks = {feedbacks} />
          </Stack>
        </Stack>
      )}*/}

      {!enrollmentId && (
        <Typography
          textAlign='center'
          variant='body1'
          pt={5}
        >
          Select a learner to see the interactions.
        </Typography>
      )}
    </Stack>
  );
}

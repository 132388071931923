import React, { useState } from 'react';
import {
  Typography,
  Card,
  CardContent,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
} from '@mui/material';
import axios from 'axios';
import { getHeaders, postQuizQuestion } from '../../../../apis/urls';
import ErrorNotifier from '../../../ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../../ToastNotifications/SuccessNotifier';
import { validateQuestion } from './validateQuestion';
import NewQuestionOptions from './NewQuestionOptions';

const initialState = {
  quiz_id: '',
  question: '',
  question_type: 'single',
  quiz_question_options: [],
};

export default function AddQuestionForm({ quizId, addQuestionToQuiz }) {
  const [questionData, setQuestionData] = useState(initialState);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const handleQuestionTypeChange = (e) => {
    setQuestionData((prevState) => ({
      ...prevState,
      question_type: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const validationError = validateQuestion(questionData);
    if (validationError) {
      setHasError(true);
      setErrorMessage(validationError);
      return;
    }
    questionData.quiz_id = quizId;
    axios
      .post(postQuizQuestion(), questionData, {
        headers: getHeaders(),
      })
      .then((res) => {
        addQuestionToQuiz(res?.data);
        setIsSuccess(true);
        setSuccessMessage('Question created successfully');
      })
      .catch((e) => {
        console.log(e);
        setHasError(true);
        setErrorMessage(
          e.response?.data?.message ||
            'An error occurred while creating the quiz question'
        );
      });
    setQuestionData(initialState);
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      {isSuccess && (
        <SuccessNotifier
          message={successMessage}
          setIsSuccess={setIsSuccess}
        />
      )}
      <Box
        component='form'
        onSubmit={handleSubmit}
      >
        <Card>
          <CardContent>
            <Typography variant='h6'>Add Question</Typography>
            <Box
              sx={{
                display: 'flex',
                gap: '16px',
                width: '100%',
                flexWrap: 'wrap',
                '@media (max-width: 600px)': {
                  flexDirection: 'column',
                },
              }}
            >
              <TextField
                label='Question'
                value={questionData.question}
                onChange={(e) =>
                  setQuestionData((prevState) => ({
                    ...prevState,
                    question: e.target.value,
                  }))
                }
                fullWidth
                margin='normal'
                required
                sx={{ flexGrow: 1, flexBasis: '70%' }}
              />
              <FormControl
                sx={{ flexGrow: 1, flexBasis: '20%' }}
                margin='normal'
              >
                <InputLabel>Question Type</InputLabel>
                <Select
                  value={questionData.question_type}
                  onChange={handleQuestionTypeChange}
                  label='Question Type'
                >
                  <MenuItem value='single'>Single Choice</MenuItem>
                  <MenuItem value='multiple'>Multiple Choice</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <NewQuestionOptions
              questionData={questionData}
              setQuestionData={setQuestionData}
            />
          </CardContent>
          <Button
            color='primary'
            variant='contained'
            sx={{ mb: 3, ml: 3 }}
            type='submit'
          >
            Add Question
          </Button>
        </Card>
      </Box>
    </>
  );
}

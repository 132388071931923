import React from 'react';
import { Divider } from '@mui/material';

const DividerComponent = ({ dividerStyleClass }) => {
  return (
    <>
      <Divider
        orientation='horizontal'
        className={dividerStyleClass}
      />
    </>
  );
};

export default DividerComponent;

import React from 'react';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
} from '@material-ui/core';
import LoadingButton from '@mui/lab/LoadingButton';
import { FiberManualRecord } from '@mui/icons-material';
import EastIcon from '@mui/icons-material/East';
import ListRenderComponent from './ListRenderComponent';
import { TricksWithBitsConst } from '../../utils/techniqueSagaConstant';
import ParagraphBox from './ParagraphBox';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

// components
import CustomButton from '../../practice/MindMaps/Atoms/CustomButton';
import ArrayOne from '../../../images/ArrayOne.png';
import ArrayTwo from '../../../images/ArrayTwo.png';
import ArrayThree from '../../../images/ArrayThree.png';
import ArrayFour from '../../../images/ArrayFour.png';
import ArrayFive from '../../../images/ArrayFive.png';
import ArraySix from '../../../images/ArraySix.png';
import ArrayNine from '../../../images/ArrayNine.png';
import ArrayTen from '../../../images/ArrayTen.png';
import ArrayEleven from '../../../images/ArrayEleven.png';
import ArrayTwelve from '../../../images/ArrayTwelve.png';
import ArrayThirteen from '../../../images/ArrayThirteen.png';
import ArrayFourteen from '../../../images/ArrayFourteen.png';
import ArrayFifteen from '../../../images/ArrayFifteen.png';

import BitwiseOperators from '../../../images/BitwiseOperators.png';

import { bitwisOperators } from '../../utils/techniqueSagaConstant';

const Introduction = ({
  handlePostCompletedTopic,
  hasMarkedCompleted,
  loading,
  topic_id,
  handleNext,
}) => {
  return (
    <Box
      display='flex'
      flexDirection='column'
      gap='20px'
      paddingY={2}
      maxWidth='calc(100% - 300px)'
    >
      <Box>
        <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
          TECHNIQUE 2
        </Typography>

        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='center'
        >
          <Typography
            sx={{
              fontSize: '48px',
              fontWeight: 600,
              lineHeight: '58px',
              letterSpacing: '0.01em',
              textAlign: 'left',
            }}
          >
            Two Pointer
          </Typography>
          {hasMarkedCompleted(topic_id) && (
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '4px',
                color: 'green',
                fontWeight: '500',
                border: '2px solid green',
                padding: '4px 10px',
                borderRadius: '20px',
                cursor: 'default',
              }}
            >
              Completed
            </Typography>
          )}
        </Box>
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        gap='24px'
      >
        <Typography sx={{ fontSize: '22px', fontWeight: 400 }}>
          Introduction
        </Typography>

        <ParagraphBox>
          <Typography sx={{ fontSize: '20px', fontWeight: 400 }}>
            Two Pointer
          </Typography>
          <Typography>
            The <strong>Two Pointer Algorithm</strong> is a popular approach
            used in solving programming problems that involve arrays or linked
            lists. It involves using two pointers that are initialised to
            different positions in the array or linked list, and then moving
            them towards each other in a certain way to solve the problem.
          </Typography>
          <Typography>
            One of the primary applications of the Two Pointer is the Sliding
            Window Technique, which isused to find a continuous subarray or
            substring in an array or string that satisfies certain conditions.
            Another common application is to find pairs or triplets of elements
            in an <strong>array</strong> that meet certain criteria.
          </Typography>
        </ParagraphBox>

        <ParagraphBox>
          <Typography sx={{ fontSize: '20px', fontWeight: 400 }}>
            Why Learn Two Pointers?
          </Typography>
          <Typography>
            The Two Pointer Algorithm is particularly useful for solving
            problems that require a linear or linearithmic solution, as it often
            reduces the time complexity of the solution from O(n^2) to O(n). It
            can also be used for sorting an array in linear time, which is
            faster than most other sorting algorithms.
          </Typography>
          <Typography>
            While the Two Pointer is a powerful technique, it does have some
            limitations. It is not always applicable to all problems and may not
            always be the optimal solution. However, it remains a valuable tool
            in the toolkit of any programmer who deals with arrays and linked
            lists.
          </Typography>
        </ParagraphBox>

        <ParagraphBox>
          <Typography sx={{ fontSize: '20px', fontWeight: 400 }}>
            What is a Two-Pointer Approach?
          </Typography>
          <Typography>
            As the name suggests we will keep track using two pointers, where
            pointers are nothing but indexes of an array. These two pointers are
            moved on the data set which could be an array, string or even a
            linked list and their movement is defined accordingly.{' '}
            <strong>
              In many problem we have to analyze each element of the array
              compared to its other elements.
            </strong>
          </Typography>
          <Typography>
            To solve problems like these we usually start from the first index
            and loop through the array one or more times depending on our
            implementation. Sometimes, we also have to create a temporary array
            depending on our problem’s requirements.
          </Typography>
          <Typography>
            The above approach might give us the correct result, but it likely
            won’t give us the most space- and time-efficient solution.
          </Typography>
          <Typography>
            As a result, it is often good to consider whether our problem can be
            solved efficiently by using the two-pointers approach.
          </Typography>
          <Typography>
            <strong>
              In the two-pointer approach, pointers refer to an array’s indexes.
              By using pointers, we can process two elements per loop, instead
              of just one.
            </strong>
          </Typography>
        </ParagraphBox>
      </Box>

      <ParagraphBox>
        <Typography sx={{ fontSize: '20px', fontWeight: 400 }}>
          Example Usage
        </Typography>
        <Typography>
          Let us try to understand it with some basic examples to see how it
          works exactly.
        </Typography>

        <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>
          Subarray Sum
        </Typography>
        <Typography>
          As the first example, consider a problem where we are given an array
          of n positive integers and a target sum x, and we want to find a
          subarray whose sum is x or report that there is no such subarray.
        </Typography>
        <Typography>For example, the array</Typography>
        <img
          src={ArrayOne}
          alt=''
          style={{ height: '40px', width: '600px', text: 'center' }}
        />
        <Typography>contains a subarray whose sum is 8:</Typography>

        <Typography sx={{ fontSize: '20px', fontWeight: 400 }}>
          Brute Force Way
        </Typography>

        <Typography>
          One simple way of achieving that would be to generate all possible
          subarrays and out of those subarrays check if anyone has the required
          sum. The checking of the sum can be done as we are generating the
          subarrays. Basically if you think about the generation of subarray you
          have to traverse at every starting point of a subarray and from there
          generate all the possible ones.You can accomplish that by iterating on
          all the starting points and for each starting point traverse on all
          the ending points. This would give you the sum of each possible
          subarray and at any point if you find the required sum, you can tell
          that a subarray with sum k exists.
        </Typography>

        <Typography>
          Let us visualize that in the example that was provided
        </Typography>

        <Typography>In the array check food the sum 8.</Typography>

        <img
          src={ArrayTwo}
          alt=''
          style={{ height: '40px', width: '600px', text: 'center' }}
        />

        <Typography>
          The first step is to iterate on all the starting points
        </Typography>

        <img
          src={ArrayTen}
          alt=''
          style={{ height: '40px', width: '600px', text: 'center' }}
        />

        <Typography>
          Now traverse on every ending point from that starting point.
        </Typography>

        <img
          src={ArrayNine}
          alt=''
          style={{ height: '40px', width: '600px', text: 'center' }}
        />
        <img
          src={ArrayEleven}
          alt=''
          style={{ height: '40px', width: '600px', text: 'center' }}
        />
        <img
          src={ArrayTwelve}
          alt=''
          style={{ height: '40px', width: '600px', text: 'center' }}
        />

        <Typography>Similarly you will do until you reach the end.</Typography>

        <img
          src={ArrayThirteen}
          alt=''
          style={{ height: '40px', width: '600px', text: 'center' }}
        />
        <Typography>
          Now when you have all the subarrays starting from 1 you can now look
          at all the subarrays starting from the next index.
        </Typography>

        <img
          src={ArrayFourteen}
          alt=''
          style={{ height: '40px', width: '600px', text: 'center' }}
        />

        <img
          src={ArrayFifteen}
          alt=''
          style={{ height: '40px', width: '600px', text: 'center' }}
        />

        <Typography>
          And so on, eventually you will come across to the conclusion whether
          there exists a subarray with required sum or not. If you try to
          implement this approach it would basically require you to find all the
          subarrays in the worst case scenario, which are nothing but n*(n+1)/2
          and would require us to run two loops, one to traverse all the
          starting points and an inner loop to traverse on all the ending points
          of that particular start. The Time Complexity for that would be
          O(n^2).
        </Typography>

        <Typography sx={{ fontSize: '20px', fontWeight: 400 }}>
          Two Pointer Way
        </Typography>

        <Typography>
          This problem can be solved in O(n) time by using the two pointers
          method. The idea is to maintain pointers that point to the first and
          last value of a subarray. On each turn, the left pointer moves one
          step to the right, and the right pointer moves to the right as long as
          the resulting subarray sum is at most x. If the sum becomes exactly x,
          a solution has been found.
        </Typography>

        <Typography>
          As an example, consider the following array and a target sum x = 8:
        </Typography>
        <img
          src={ArrayThree}
          alt=''
          style={{ height: '40px', width: '600px', text: 'center' }}
        />

        <Typography>
          The initial subarray contains the values 1, 3 and 2 whose sum is 6:
        </Typography>
        <img
          src={ArrayFour}
          alt=''
          style={{ height: '40px', width: '600px', text: 'center' }}
        />

        <Typography>
          Then, the left pointer moves one step to the right. The right pointer
          does not move, because otherwise the subarray sum would exceed x.
        </Typography>
        <img
          src={ArrayFive}
          alt=''
          style={{ height: '40px', width: '600px', text: 'center' }}
        />

        <Typography>
          Again, the left pointer moves one step to the right, and this time the
          right pointer moves three steps to the right. The subarray sum is 2 +
          5 + 1 = 8, so a subarray whose sum is x has been found.
        </Typography>
        <img
          src={ArraySix}
          alt=''
          style={{ height: '40px', width: '600px', text: 'center' }}
        />

        <Typography>
          The running time of the algorithm depends on the number of steps the
          right pointer moves. While there is no useful upper bound on how many
          steps the pointer can move on a single turn. We know that the pointer
          moves a total of O(n) steps during the algorithm, because it only
          moves to the right. Since both the left and right pointer move O(n)
          steps during the algorithm, the algorithm works in O(n) time.
        </Typography>
      </ParagraphBox>

      <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>
        Variations of Two Pointer
      </Typography>
      <Typography>
        Two pointer is a constructive algorithm, so there is no fixed way of how
        pointers are going to be moving or where they will be placed initially
        but there are a range of different variations of this that can be
        applied to a range of problems:
      </Typography>

      <ParagraphBox>
        <Box
          display='flex'
          flexDirection='column'
          gap='10px'
        >
          <List>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecord fontSize='8px' />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography>
                    <strong>Starting from both ends of the array</strong>, a
                    left pointer at the start (0) and a right pointer at the end
                    of the array.
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecord fontSize='8px' />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography>
                    <strong>Slow and fast pointer</strong>, one pointer moving
                    slow (1 step for instance) and another moving fast (2 steps
                    or variable number of steps).
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecord fontSize='8px' />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography>
                    <strong>Starting from 2 different arrays</strong>, for
                    merging etc.
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecord fontSize='8px' />
              </ListItemIcon>
              <ListItemText
                primary={
                  <strong>
                    Split array and then start a pointer from each.
                  </strong>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecord fontSize='8px' />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography>
                    <strong>
                      2 slow pointers starting from the beginning of the array
                    </strong>
                    , variable / sliding window technique.
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </Box>
      </ParagraphBox>

      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        alignSelf='end'
        justifyContent='space-between'
        gap='12px'
      >
        {!hasMarkedCompleted(topic_id) && (
          <LoadingButton
            variant='contained'
            onClick={() => handlePostCompletedTopic(topic_id)}
            loading={loading}
            loadingPosition='center'
            children='Mark as completed'
            style={{
              width: '170px',
              // backgroundColor: 'transparent',
              borderRadius: '8px',
              border: '1px solid rgba(64, 96, 245, 0.5)',
            }}
          />
        )}

        <Box
          display='flex'
          alignItems='center'
          gap='8px'
        >
          <Button
            sx={{ gap: '4px' }}
            onClick={handleNext}
          >
            Next <EastIcon />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Introduction;

import React, { useEffect } from 'react';
import { Tooltip } from '@mui/material';
import '../Templates/TGPTriangle.css';
import { useSelector } from 'react-redux';

const LEVEL_ARRAY = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];

const TGPTriangle = ({
  clearedLevels,
  setSelectedLevel,
  setClickableLevel,
}) => {
  let nextLevel = 1;
  clearedLevels.forEach((clearedLevel) => {
    nextLevel = Math.max(nextLevel, Number(clearedLevel.substring(1)) + 1);
  });
  const currentProgram = useSelector((state) => state.programs.currentProgram);

  useEffect(() => {
    const newClickableLevels = LEVEL_ARRAY.filter((levelNumber) =>
      isClickable(levelNumber)
    );
    setClickableLevel(newClickableLevels);
  }, [clearedLevels]);

  const isClickable = (levelNumber) => {
    return (
      clearedLevels.includes(`C${levelNumber}`) || levelNumber === nextLevel
    );
  };

  const determineAnimation = (level, unlockedLevels) => {
    const passedLevels = unlockedLevels.length + 1;
    const trianglesUpToUnlockedLevel =
      unlockedLevels !== 0
        ? LEVEL_ARRAY.slice(0, passedLevels)
        : LEVEL_ARRAY.slice(0, nextLevel);
    if (level === 8) {
      return 'placementLevelColor 1s linear';
    }
    if (!trianglesUpToUnlockedLevel.includes(level)) {
      return 'levelColor 1s linear';
    }
    return 'none';
  };

  return (
    <>
      {LEVEL_ARRAY.map((levelNumber) => (
        <Tooltip
          key={levelNumber}
          title={`Level C${levelNumber}`}
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: 'white',
                color: 'black',
                fontSize: '14px',
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
              },
            },
          }}
        >
          <div
            className={`triangle${LEVEL_ARRAY.length + 1 - levelNumber}`}
            role='button'
            tabIndex={isClickable(levelNumber) ? 0 : -1}
            onClick={
              isClickable(levelNumber)
                ? () => setSelectedLevel(`C${levelNumber}`)
                : null
            }
            onKeyDown={
              isClickable(levelNumber)
                ? () => setSelectedLevel(`C${levelNumber}`)
                : null
            }
            style={{
              animation: determineAnimation(levelNumber, clearedLevels),
              animationFillMode: 'forwards',
              cursor: isClickable(levelNumber) ? 'pointer' : 'not-allowed',
              zIndex: '1',
            }}
          />
        </Tooltip>
      ))}
    </>
  );
};

export default TGPTriangle;

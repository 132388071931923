import React from 'react';
import { Drawer } from '@mui/material';
import DrawerComponent from './DrawerComponent';

const LevelInfoDrawer = ({
  setSelectedTest,
  setOpenTest,
  selectedTest,
  openDrawer,
  setOpenDrawer,
  selectedLevel,
  setSelectedLevel,
  allTests,
}) => {
  const handleCloseDrawer = () => {
    setOpenDrawer(false);
    setSelectedTest(null);
    setSelectedLevel(null);
    setOpenTest(false);
  };

  return (
    <>
      <Drawer
        anchor='right'
        open={openDrawer}
        onClose={handleCloseDrawer}
        PaperProps={{
          sx: { width: '30%' },
        }}
      >
        <DrawerComponent
          setSelectedTest={setSelectedTest}
          setOpenTest={setOpenTest}
          selectedTest={selectedTest}
          selectedLevel={selectedLevel}
          allTests={allTests}
        />
      </Drawer>
    </>
  );
};

export default LevelInfoDrawer;

import React, { useState } from 'react';

export default function Button({
  children,
  text = 'Text',
  backgroundColor = '#1976D2',
  hoverColor = '#0f5eab',
  width = '160px',
  height = '36px',
  borderRadius = '4px',
  border = 'none',
  color = '#fff',
  fontWeight = '500',
  fontSize = '16px',
  margin = '0px',
  onClick,
  href,
}) {
  const [isHovered, setIsHovered] = useState(false);

  const handleClick = () => {
    if (onClick) {
      onClick();
    }

    if (href) {
      window.open(href, '_blank');
    }
  };

  return (
    <button
      style={{
        backgroundColor: isHovered ? hoverColor : backgroundColor,
        width,
        height,
        borderRadius,
        border,
        color,
        fontWeight,
        fontSize,
        margin,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        padding: '10px 15px',
        transition: '0.5s',
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={handleClick}
    >
      {children || text}
    </button>
  );
}

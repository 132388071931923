import { List, ListItemButton, ListItemIcon, ListItemText } from '@material-ui/core'
import React from 'react'

const SideBar = ({
    label,
    Icon,
    selectedIndex,
    handleListItemClick,
    index
}) => {
    return (
        <div style={{ padding: 2 }}>
            <List component="nav">
                <ListItemButton
                    selected={selectedIndex === index}
                    onClick={() => handleListItemClick(index)}
                >
                    <ListItemIcon>
                        {
                            Icon &&
                            <Icon />
                        }
                    </ListItemIcon>
                    <ListItemText >
                        {label}
                    </ListItemText>
                </ListItemButton>
            </List>
        </div>
    )
}

export default SideBar
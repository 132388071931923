import { Button, Dialog, DialogActions, DialogContent, DialogContentText, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react';

function SessionLogDetails({ 
  openLog, 
  setOpenLog, 
  session,
  setHasError, 
  setErrorMessage,
  handleUpdateSessionApiCall
 }) {
  const initialState = {
    duration: ""
  }
  const descriptionElementRef = useRef(null);
  const [updatedData, setUpdatedData] = useState(initialState);
  const [durationIntervals, setDurationIntervals] = useState([]);

  useEffect(() => { 
    if (openLog) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) { descriptionElement.focus(); }
    }
    const tmpIntervals = [];
    for (let i = 15; i <= session?.session_type?.duration; i += 15) { 
      tmpIntervals.push(i); 
    }

    setDurationIntervals(tmpIntervals);
    return () => {};
  }, [openLog]);

  const handleUpdateClick = (event) => {
    const body = {};
    const emptyFields = [];
    
    if (updatedData?.duration === "") {
      emptyFields.push("duration");
    }
      
    if (emptyFields.length > 0) {
      setHasError(true);
      setErrorMessage(`${emptyFields.join(", ")} cannot be empty`);
      return;
    }

    body.duration = updatedData.duration;
    handleUpdateSessionApiCall(session.id, body);
    setOpenLog(false);
  }
  
  return (
    <Dialog open={openLog} onClose={() => setOpenLog(false)} scroll="paper" fullWidth maxWidth="md">
      <DialogActions>
        <Button onClick={() => setOpenLog(false)}>Close</Button>
      </DialogActions>

      <DialogContent dividers>
        <DialogContentText ref={descriptionElementRef} tabIndex={-1}>
          <Stack>
            <FormControl 
              sx={{ minWidth: 120 }} 
              disabled={
                session.status 
                && session.status !== "conducted"
              }
            >
              <InputLabel id="duration">Logged duration (editable)</InputLabel>
              <Select
                labelId="duration"
                name="duration"
                id="duration"
                // defaultValue={session?.duration}
                value={(updatedData?.duration ?? "") === "" ? session.duration : updatedData?.duration}
                label="Duration"
                onChange={(e) => setUpdatedData({...updatedData, duration: e.target.value})}
              >
                <MenuItem value="">None</MenuItem>
                {
                  durationIntervals.map((interval, index) => {
                    return (
                      <MenuItem key={index + 1} value={interval}>
                        {interval} minutes
                      </MenuItem>
                    );
                  }) 
                }
              </Select>
            </FormControl>

            <Typography id="flow" variant='body2'>Flow</Typography>
            <TextField
              id="flow"
              multiline
              value={session?.flow}
              placeholder="Flow not found."
              sx={{width: "100%", marginBottom: 4}}
              inputProps={{ readOnly: true }}
              minRows={3}
            />
            {session?.program?.program_type !== "super30" &&
              <>
                <Typography id="feedback" variant='body2'>Learner Feedback</Typography>
                <TextField
                  multiline
                  id="feedback"
                  value={session?.learner_feedback}
                  placeholder="Feedback not found."
                  style={{width: "100%", marginBottom: 4}}
                  inputProps={{ readOnly: true }}
                  minRows={3}
                />
              </>
            }
          </Stack>
        </DialogContentText>
        <DialogActions>
          <Button
            variant='contained'
            disabled={
              session.duration === Number(updatedData.duration)
              || updatedData.duration === ""
              || session.status === "payment_processed"
            } 
            onClick={(e) => handleUpdateClick(e)}
          >
            Update
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

export default SessionLogDetails;

import { Typography } from '@mui/material';

export const HighlightedText = ({ text, search }) => {
  if (!text) {
    return null;
  }
  const parts = text.split(new RegExp(`(${search})`, 'i'));
  if (!Array.isArray(parts)) {
    return text;
  }
  const highlightedText = parts.map((part, index) =>
    part.toLowerCase() === search.toLowerCase() ? (
      <span
        key={index}
        style={{ backgroundColor: '#ffdf66' }}
      >
        {part}
      </span>
    ) : (
      part
    )
  );
  return (
    <Typography
      variant='body2'
      sx={{ color: 'gray' }}
    >
      {highlightedText}
    </Typography>
  );
};

import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { slugify } from 'src/components/utils/common';

const initialState = {
  programs: {},
  currentProgram: null,
  lastSubmittedFormResponse: null,
  // This attribute is added as identifier to use new student layout. It's been added to avoid
  // the dependency on currentProgram for choosing the new Layout
  shouldShowNewStudentLayout: null,
};

const today = moment();

export const programSlice = createSlice({
  name: 'programs',
  initialState,
  reducers: {
    unsetPrograms: (state) => {
      state.programs = {};
      state.currentProgram = null;
      state.lastSubmittedFormResponse = null;
    },

    setCurrentProgram: (state, action) => {
      // "action.payload" must always be a program id.
      if (Object.keys(state.programs).length > 0) {
        state.currentProgram = state.programs[action.payload];
      }
    },

    unsetCurrentProgram: (state) => {
      state.currentProgram = null;
      state.lastSubmittedFormResponse = null;
    },

    updatePrograms: (state, action) => {
      // "action.payload" must always be an object.
      if (Object.keys(action.payload ?? {}).length === 0) {
        return;
      }

      const date = moment(action.payload.start_date);
      const remDays = date.isAfter(today)
        ? date.diff(today, 'days') + 10
        : 10 - today.diff(date, 'days');

      const p = {
        programId: action.payload.id,
        programSlug: slugify(action.payload.name),
        internalNameSlug: slugify(action.payload.internal_name),
        name: action.payload.name,
        internal_name: action.payload.internal_name,
        remDays,
        hasProgramStarted: date.isBefore(today),
        type: action.payload.program_type,
      };
      if (action.payload.program_access) {
        p.programAccess = action.payload.program_access;
      }

      state.programs = {
        ...state.programs,
        [action.payload.id]: p,
      };
    },

    setPrograms: (state, action) => {
      // "action.payload" must always be a list of programs.
      const programObj = {};
      action.payload.forEach((p) => {
        const date = moment(p.start_date);
        const remDays = date.isAfter(today)
          ? date.diff(today, 'days') + 10
          : 10 - today.diff(date, 'days');

        programObj[p.id] = {
          programId: p.id,
          programSlug: slugify(p.name),
          internalNameSlug: slugify(p.internal_name),
          name: p.name,
          internal_name: p.internal_name,
          remDays,
          hasProgramStarted: date.isBefore(today),
          type: p.program_type,
        };
        if (p.program_access) {
          programObj[p.id].programAccess = p.program_access;
        }
      });

      state.programs = programObj;
    },
    setShouldShowNewStudentLayout: (state, action) => {
      state.shouldShowNewStudentLayout = action.payload;
    },
  },
});

export const {
  setCurrentProgram,
  unsetCurrentProgram,
  setPrograms,
  updatePrograms,
  unsetPrograms,
  setShouldShowNewStudentLayout,
} = programSlice.actions;

export default programSlice.reducer;

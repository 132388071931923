import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import axios from 'axios';
import LoadingButton from '@mui/lab/LoadingButton';
import CircularProgress from '@mui/material/CircularProgress';
import ConfettiEffect from '../../ConfettiEffect/ConfettiEffect';
import {
  fetchAllPlacementRequests,
  fetchAllRoleTechStacks,
  getHeaders,
} from '../../../apis/urls';
import ErrorNotifier from '../../ToastNotifications/ErrorNotifier';
import ReceivingCompanyDetails from './ReceivingCompanyDetails';

const LearnerJobs = ({ selectedFilter }) => {
  const [roleTechStacks, setRoleTechSTacks] = useState([]);
  const [allCompaniesData, setAllCompaniesData] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isGettingData, setIsGettingData] = useState(false);
  const [showConfetties, setShowConfetties] = useState(false);
  const [currentCompanyIndex, setCurrentCompanyIndex] = useState(0);
  const [showLoaderInButton, setShowLoaderInButton] = useState(false);
  const currentProgram = useSelector(
    (state) => state?.programs?.currentProgram
  );

  useEffect(() => {
    getAllCompanyDetailsDetailsFromPlacementRequests();
    getAllRoleTechStacks();
    return () => {};
  }, [selectedFilter]);

  const handleNextCompany = () => {
    if (currentCompanyIndex + 1 < allCompaniesData.length) {
      setCurrentCompanyIndex((prevIndex) => prevIndex + 1);
      setShowConfetties(true);
    } else {
      setShowConfetties(false);
    }
  };

  const getAllCompanyDetailsDetailsFromPlacementRequests = () => {
    setIsGettingData(true);
    axios
      .get(fetchAllPlacementRequests(), {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((res) => {
        const liveCompanies = res?.data?.filter(
          (request) =>
            request.company_role.job_status === 'live' &&
            request.company_role.approval_status === 'approved' &&
            request.status === 'approved'
        );
        setAllCompaniesData(liveCompanies || []);
        setIsGettingData(false);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage('Error while getting companies');
        setIsGettingData(false);
      });
  };

  const handleShowContactDetails = async () => {
    setShowLoaderInButton(true);
    if (allCompaniesData.length > 0) {
      setShowLoaderInButton(false);
      setShowConfetties(true);
    }
  };

  const getAllRoleTechStacks = () => {
    axios
      .get(fetchAllRoleTechStacks(), {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((res) => {
        if (!res.data) {
          return;
        }
        setRoleTechSTacks(res?.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  return (
    <>
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      {showConfetties && <ConfettiEffect />}
      {showConfetties && (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'rgba(0,0,0,0.2)',
            zIndex: 9999,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 9999,
            }}
          >
            <ReceivingCompanyDetails
              showConfetties={showConfetties}
              setShowConfetties={setShowConfetties}
              allCompaniesData={allCompaniesData}
              setHasError={setHasError}
              setErrorMessage={setErrorMessage}
              currentCompanyIndex={currentCompanyIndex}
              handleNextCompany={handleNextCompany}
            />
          </Box>
        </Box>
      )}
      <Box
        sx={{
          padding: '30px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '50px',
            padding: '30px 10px 0 10px',
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              textAlign: 'center',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            {isGettingData && <CircularProgress />}
          </Box>
          {allCompaniesData.length === 0 ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                textAlign: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography sx={{ fontSize: '40px', color: '#b0b0b0' }}>
                No company details approved
              </Typography>
            </Box>
          ) : (
            allCompaniesData.map((company) => (
              <Box
                sx={{
                  boxShadow: 2,
                  padding: '15px',
                  borderRadius: '10px',
                  width: '430px',
                  border: '1px solid #D2D2D2',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box
                    component='img'
                    alt='logo'
                    src={company?.logo}
                    sx={{
                      width: '94px',
                      height: '74px',
                      backgroundColor: '#D2D2D2',
                      borderRadius: '5px',
                    }}
                  />
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      textAlign: 'center',
                      alignItems: 'start',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'start',
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: '28px',
                          fontWeight: '600',
                          color: '#4F4F4F',
                        }}
                      >
                        {company.company_role.role_title}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'start',
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: '22px',
                          fontWeight: '500',
                          color: '#4F4F4F',
                        }}
                      >
                        {company.company_role.company.name}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'start',
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: '18px',
                          fontWeight: '400',
                          color: '#4F4F4F',
                        }}
                      >
                        {company.company_role.location} |{' '}
                        {company.company_role.job_type}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    padding: '20px 0 10px 0',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '20px',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: '900px',
                      color: '#4F4F4F',
                    }}
                  >
                    Tech Stack
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '10px',
                  }}
                  key={company.id}
                >
                  {roleTechStacks
                    .filter(
                      (techStack) => techStack.company_role_id === company.id
                    )
                    .slice(0, 5)
                    .map((filteredTechStack, index) => (
                      <Typography
                        key={index}
                        sx={{
                          backgroundColor: '#D2E7FF',
                          padding: '7px',
                          fontSize: '13px',
                          borderRadius: '30px',
                          width: 'fit-content',
                        }}
                      >
                        {filteredTechStack.tech_stack.name}
                      </Typography>
                    ))}
                  {roleTechStacks
                    .filter(
                      (techStack) => techStack.company_role_id === company.id
                    )
                    .slice(5).length > 0 && (
                    <Typography
                      sx={{
                        padding: '7px',
                        fontSize: '13px',
                        borderRadius: '30px',
                        width: 'fit-content',
                        fontWeight: '700',
                        color: '#424242',
                      }}
                    >
                      +
                      {
                        roleTechStacks
                          .filter(
                            (techStack) =>
                              techStack.company_role_id === company.id
                          )
                          .slice(5).length
                      }{' '}
                      more
                    </Typography>
                  )}
                </Box>

                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    padding: '20px 0 0 0',
                  }}
                >
                  <Box
                    sx={{
                      padding: '5px 0',
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '20px',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '18px',
                        fontWeight: '900px',
                        color: '#1976D2',
                      }}
                    >
                      <strong>
                        CTC {company.company_role.min_ctc} -{' '}
                        {company.company_role.max_ctc} LPA
                      </strong>
                    </Typography>
                  </Box>
                  <LoadingButton
                    loading={showLoaderInButton}
                    sx={{
                      backgroundColor: '#1976D2',
                    }}
                    variant='contained'
                    onClick={handleShowContactDetails}
                  >
                    Show Details
                  </LoadingButton>
                </Box>
              </Box>
            ))
          )}
        </Box>
      </Box>
    </>
  );
};

export default LearnerJobs;

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Stack, Typography } from '@material-ui/core';
import { Tab, Tabs, Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { BASE_URL, getHeaders } from '../../apis/urls';
import LoadingScreen from '../../pages/LoadingScreen';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';

const CoadingAssignmentSubmissions = () => {
  const [url, setUrl] = useState('');
  const [finalDashboardUrl, setFinalDashboardUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${BASE_URL}/api/metabases/coding_assignment_submissions`, {
        headers: getHeaders(),
      })
      .then((res) => {
        setUrl(res?.data?.url);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message ??
            'An error occured while finding the dashboard'
        );
      })
      .finally(() => {
        setLoading(false);
      });

    setLoading(true);
    axios
      .get(
        `${BASE_URL}/api/metabases/coding_assignment_submissions_final_dashboard`,
        {
          headers: getHeaders(),
        }
      )
      .then((res) => {
        setFinalDashboardUrl(res?.data?.url);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message ??
            'An error occured while finding the dashboard'
        );
      })
      .finally(() => {
        setLoading(false);
      });

    return () => {};
  }, []);

  return (
    <>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            variant='scrollable'
            scrollButtons='auto'
          >
            <Tab label='Coding Assignment' />
            <Tab label='Coding Assignment Final Dashboard' />
          </Tabs>
        </Box>
        <Outlet />
      </Box>

      {value === 0 ? (
        <Stack
          alignContent='center'
          width='100%'
        >
          {hasError && (
            <ErrorNotifier
              message={errorMessage}
              setHasError={setHasError}
            />
          )}

          <LoadingScreen loading={loading} />

          {url === '' && (
            <Typography
              variant='body1'
              component='p'
              margin='auto'
              marginTop='100px'
            >
              {loading ? 'Finding dashboard' : 'No dashboard found'}
            </Typography>
          )}

          <iframe
            title='Coding Assignment Submissions'
            src={url}
            frameBorder={0}
            width='inherit'
            height={900}
            allowTransparency
          />
        </Stack>
      ) : (
        <Stack
          alignContent='center'
          width='100%'
        >
          {hasError && (
            <ErrorNotifier
              message={errorMessage}
              setHasError={setHasError}
            />
          )}

          <LoadingScreen loading={loading} />

          {finalDashboardUrl === '' && (
            <Typography
              variant='body1'
              component='p'
              margin='auto'
              marginTop='100px'
            >
              {loading ? 'Finding dashboard' : 'No dashboard found'}
            </Typography>
          )}

          <iframe
            title='Coding Assignment Final Dashboard'
            src={finalDashboardUrl}
            frameBorder={0}
            width='inherit'
            height={900}
            allowTransparency
          />
        </Stack>
      )}
    </>
  );
};

export default CoadingAssignmentSubmissions;

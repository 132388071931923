import React, { useRef } from 'react';
import Confetti from 'react-confetti';

const ConfettiEffect = () => {
  const width = window.innerWidth;
  const height = window.innerHeight;

  return (
    <>
      <Confetti
        width={width}
        height={height}
        numberOfPieces={1000}
        recycle={false}
      />
    </>
  );
};

export default ConfettiEffect;

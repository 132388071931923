import React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@material-ui/core';
import ProgressBar from '../Atoms/ProgressBar';

const TopicProgress = ({
  progress,
  totalTopic,
  unlockedTopic,
  width,
  height,
}) => {
  return (
    <Box
      display='flex'
      mt={3}
      pr={17}
      pl={25}
      mb={3}
    >
      <ProgressBar
        progress={progress}
        width={80}
        height={15}
      />
      <div style={{ alignSelf: 'flex-start', marginLeft: '10px' }}>
        <Typography sx={{ marginLeft: '10px', width: 'max-content' }}>
          {unlockedTopic}/{totalTopic} Topics unlocked
        </Typography>
      </div>
    </Box>
  );
};

export default TopicProgress;

import React from 'react';
import { Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { constant } from 'lodash';

// Eg: 'Apr 18, 2022 10:33 PM'
export function getHumanReadableTimestamp(timestamp) {
  const date = timestamp.toLocaleDateString('en-US', {
    day: 'numeric',
    year: 'numeric',
    month: 'short',
  });
  const time = timestamp.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
  });
  return `${date} ${time}`;
}

// Eg: 'Apr 18'
export function getHumanReadableDate(timestamp) {
  const date = timestamp.toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'short',
  });
  return date;
}

// Eg: '2022-04-18'
export function getDatabaseFormattedDate(timestamp) {
  const year = timestamp.getFullYear();
  const month = timestamp.toLocaleDateString('en-US', {
    month: '2-digit',
  });
  const day = timestamp.toLocaleDateString('en-US', {
    day: '2-digit',
  });

  return `${year}-${month}-${day}`;
}

export function slugify(str) {
  const strArray = str.toLowerCase().split(' ');
  return strArray.join('-');
}

export function sortAssignmentsByDeadline(arr) {
  function cmp(d1, d2) {
    if (d1.deadline <= d2.deadline) {
      return 1;
    }
    return -1;
  }
  return arr.sort(cmp);
}

export const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const splitByCamelCaseAndCapitalize = (name, specialChars = ['_']) => {
  if (name.length === 0) return '';

  const result = [];
  let stack = [];
  let lastChar = '';
  const len = name.length;

  for (let i = 0; i < len; i += 1) {
    if (name[i] >= '0' && name[i] <= '9') {
      if (lastChar >= '0' && lastChar <= '9') {
        stack.push(name[i]);
      } else {
        stack.push(` ${name[i]}`);
      }
      lastChar = name[i];
    } else if (specialChars.includes(name[i])) {
      // split point
      if (stack.length > 0) {
        result.push(stack.join(''));
        stack = [];
        lastChar = '';
      }
    } else if (name[i] === name[i].toUpperCase() && stack.length > 0) {
      // split point
      result.push(stack.join(''));
      stack = [];
    }
    lastChar = name[i].toLowerCase();
    stack.push(lastChar);
  }

  if (stack.length > 0) {
    result.push(stack.join(''));
  }

  return splitByUnderscoresAndCapitalize(result.join(' '));
};

export const splitByUnderscoresAndCapitalize = (name) => {
  if (name.length === 0) return '';
  name = capitalize(name); // capitalize first letter.
  name = name.split('_').join(' ');
  return name;
};

export const CopyIcon = (copyText) => {
  const [showCopiedMsg, setShowCopiedMsg] = React.useState(false);

  function fallbackCopyTextToClipboard(text) {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      const successful = document.execCommand('copy');
      const msg = successful ? 'successful' : 'unsuccessful';
      console.log(`Fallback: Copying text command was ${msg}`);
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }
    document.body.removeChild(textArea);
  }

  function copyTextToClipboard(text) {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text).then(
      () => {
        setShowCopiedMsg(true);
        setTimeout(() => {
          setShowCopiedMsg(false);
        }, 300);
        console.log('Async: Copying to clipboard was successful!');
      },
      (err) => {
        console.error('Async: Could not copy text: ', err);
      }
    );
  }

  return (
    <>
      <ContentCopyIcon
        style={{ marginLeft: '20px', color: '#9e9e9e', cursor: 'pointer' }}
        onClick={() => copyTextToClipboard(copyText)}
      />
      <Typography
        display={showCopiedMsg ? 'inline-block' : 'none'}
        ml='10px'
        color='#9e9e9e'
        bottom='5px'
        position='relative'
      >
        Copied!
      </Typography>
    </>
  );
};

// for C1 => 1
export function extractLevelNumberFromName(str) {
  if (!str) {
    return null;
  }

  const match = str.match(/\d+/);
  if (match) {
    return parseInt(match[0], 10);
  }
  return null;
}

// // Eg: '22:34'
// export function getDatabaseFormattedTime(timestamp) {
//   return timestamp.getHours() + ":" + timestamp.getMinutes()
// }

// // Eg: '2022-04-18 22:34'
// export function getDatabaseFormattedDateTime(timestamp) {
//   return getDatabaseFormattedDate(timestamp)
//           + " "
//           + getDatabaseFormattedTime(timestamp);
// }

// export function capitalize(string) {
//   return string.charAt(0).toUpperCase() + string.slice(1);
// }

// export const CopyIcon = (copyText) => {
//   const [showCopiedMsg, setShowCopiedMsg] = React.useState(false);

//   function fallbackCopyTextToClipboard(text) {
//     var textArea = document.createElement("textarea");
//     textArea.value = text;
//     document.body.appendChild(textArea);
//     textArea.focus();
//     textArea.select();
//     try {
//       var successful = document.execCommand("copy");
//       var msg = successful ? "successful" : "unsuccessful";
//       console.log("Fallback: Copying text command was " + msg);
//     } catch (err) {
//       console.error("Fallback: Oops, unable to copy", err);
//     }
//     document.body.removeChild(textArea);
//   }

//   function copyTextToClipboard(text) {
//     if (!navigator.clipboard) {
//       fallbackCopyTextToClipboard(text);
//       return;
//     }
//     navigator.clipboard.writeText(text).then(
//       function() {
//         setShowCopiedMsg(true);
//         setTimeout(() => {setShowCopiedMsg(false)}, 300);
//         console.log("Async: Copying to clipboard was successful!");
//       },
//       function(err) {
//         console.error("Async: Could not copy text: ", err);
//       }
//     );
//   }

//   return (
//     <>
//       <ContentCopyIcon
//         style={{marginLeft: '20px', color: '#9e9e9e', cursor: 'pointer'}}
//         onClick={() => copyTextToClipboard(copyText)}
//       />
//       <Typography display={showCopiedMsg ? 'inline-block' : 'none'} ml={'10px'} color={'#9e9e9e'} bottom={'5px'} position={'relative'}>
//         Copied!
//       </Typography>
//     </>
//   )
// }

export function formatSessionTitle(title) {
  let formattedTitle = title.trim();
  formattedTitle =
    formattedTitle.charAt(0).toUpperCase() + formattedTitle.slice(1);
  if (formattedTitle.length > 15) {
    formattedTitle = `${formattedTitle.substr(0, 13)}...`;
  }
  return formattedTitle;
}

export const shouldShowNewSessionsUI = (programId) => {
  return (
    programId === 2 ||
    programId >= 100 ||
    programId == 74 ||
    programId == 76 ||
    programId == 85 ||
    programId == 89
  );
};

export const shouldShowNewStudentLayout = (programId) => {
  return (
    programId == 2 ||
    programId == 74 ||
    programId == 76 ||
    programId == 85 ||
    programId == 89 ||
    programId == 108 ||
    programId == 109
  );
};

export const makeProblemIdentifier = (
  problemContext,
  languageId,
  problemId
) => {
  return (
    problemContext.toString() + '_' + languageId + '_' + problemId + '_code'
  );
};

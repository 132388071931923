import { format, formatDistanceToNow } from 'date-fns';
import moment from 'moment';

// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), 'dd MMMM yyyy');
}

export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fDateFix(date) {
  const time = new Date(date).toLocaleTimeString('en', {
    timeStyle: 'short',
    hour12: false,
    timeZone: 'UTC',
  });
  return time;
}

export function fDateTimeOnly(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}

export function getTimeInterval(startTime, endTime, lunchTime) {
  const start = moment(startTime, 'HH:mm');
  const end = moment(endTime, 'HH:mm');
  const minutes = end.diff(start, 'minutes');
  const interval = moment().hour(0).minute(minutes);
  interval.subtract(lunchTime, 'minutes');
  return interval.format('HH:mm');
}

export function getStartAndEndTime(startTime, endTime, startDate) {
  const startDateTime = moment(`${startDate} ${startTime}`).format(
    'YYYY-MM-DD HH:mm:ss'
  );
  const modDateTime = startDateTime;
  const stTime = moment(startTime, 'HH:mm:ss');
  const enTime = moment(endTime, 'HH:mm:ss');

  const duration = moment(getTimeInterval(stTime, enTime, 0), 'HH:mm');

  const hours = duration.get('hours');
  const minutess = duration.get('minutes');

  const endDateTime = moment(modDateTime)
    .add(hours, 'hours')
    .add(minutess, 'minutes')
    .format('YYYY-MM-DD HH:mm:ss');

  return {
    startDateTime,
    endDateTime,
  };
}

export const formatDateLabelChatMessage = (date) => {
  if (!date) {
    return '';
  }
  const today = moment();
  const messageDate = moment(date);

  if (messageDate.isSame(today, 'day')) {
    return 'TODAY';
  }

  if (messageDate.isSame(today.clone().subtract(1, 'day'), 'day')) {
    return 'YESTERDAY';
  }
  return messageDate.format('MMM DD, YYYY');
};

export const formatDateLabelChatList = (date) => {
  if (!date) {
    return '';
  }
  const today = moment();
  const messageDate = moment(date);

  if (messageDate.isSame(today, 'day')) {
    return moment(date).format('hh:mm A');
  }

  if (messageDate.isSame(today.clone().subtract(1, 'day'), 'day')) {
    return 'YESTERDAY';
  }
  return messageDate.format('MMM DD, YYYY');
};

import React from 'react';
import { Box, Button, Card, CardContent, Typography } from '@material-ui/core';
import moment from 'moment';

function QuizCard({ selectedQuizAttempt, handleQuizClick, joinQuiz }) {
  const format1 = 'DD-MM-YYYY, hh:mm A';
  const currTime = moment();

  return (
    <Card
      sx={{
        minWidth: 200,
        minHeight: 200,
        cursor: 'pointer',
        m: 2,
      }}
    >
      <CardContent>
        <Typography
          variant='h5'
          textAlign='center'
          sx={{
            mt: 2,
          }}
        >
          {selectedQuizAttempt?.name}
        </Typography>

        <Box
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          sx={{ mt: 2 }}
        >
          <Typography variant='body1'>
            {`Duration: ${selectedQuizAttempt?.duration} min`}
          </Typography>
          <Typography variant='body1'>
            {`Start time: ${selectedQuizAttempt?.startTime.format(
              format1
            )} IST`}
          </Typography>
          <Typography variant='body1'>
            {`End time: ${selectedQuizAttempt?.endTime.format(format1)} IST`}
          </Typography>
          {selectedQuizAttempt?.showScore &&
            selectedQuizAttempt?.score !== null && (
              <Typography variant='body1'>
                {`Score: ${selectedQuizAttempt?.score}/${selectedQuizAttempt?.totalMarks}`}
              </Typography>
            )}
          {(() => {
            switch (true) {
              case selectedQuizAttempt?.hasSubmitted:
                return (
                  <Button
                    sx={{
                      width: '100%',
                      mt: 2,
                    }}
                    variant='contained'
                    disabled
                  >
                    Submitted
                  </Button>
                );
              case currTime.isAfter(selectedQuizAttempt?.endTime):
                return (
                  <Button
                    sx={{
                      width: '100%',
                      mt: 2,
                    }}
                    variant='contained'
                    disabled
                  >
                    Ended
                  </Button>
                );
              case currTime.isBefore(selectedQuizAttempt?.startTime):
                return (
                  <Button
                    sx={{
                      width: '100%',
                      mt: 2,
                    }}
                    variant='contained'
                    disabled
                  >
                    Scheduled
                  </Button>
                );
              case selectedQuizAttempt?.hasJoined:
                return (
                  <Button
                    sx={{
                      width: '100%',
                      mt: 2,
                    }}
                    variant='contained'
                    onClick={(e) => handleQuizClick(e, selectedQuizAttempt)}
                  >
                    Resume
                  </Button>
                );
              default:
                return (
                  <Button
                    sx={{
                      width: '100%',
                      mt: 2,
                    }}
                    variant='contained'
                    onClick={(e) => joinQuiz(e, selectedQuizAttempt)}
                  >
                    Start
                  </Button>
                );
            }
          })()}
        </Box>
      </CardContent>
    </Card>
  );
}

export default QuizCard;

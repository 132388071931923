// @ts-nocheck
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import LabelsDropdown from './Labels/LabelsDropdown';
import SingleProgramSelectionDropdown from './Broadcast/SingleProgramSelectionDropdown';
import { fetchProgramsUrl, getHeaders } from '../../apis/urls';

export default function ChatListFilters({
  handleApplyFilters,
  filters,
  setFilters,
}) {
  const [programs, setPrograms] = useState({});

  useEffect(() => {
    getPrograms();
    return () => {};
  }, []);

  const getPrograms = () => {
    axios
      .get(fetchProgramsUrl(), {
        headers: getHeaders(),
      })
      .then((res) => {
        const programData = res.data.map((p) => ({
          programId: p.id,
          internal_name: p.internal_name,
        }));
        setPrograms(programData);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilters((p) => ({ ...p, [name]: value }));
  };

  const handleProgramChange = (e, v) => {
    const { value } = v;
    setFilters((p) => ({ ...p, program_id: value }));
  };

  return (
    <Stack
      spacing={1}
      m={1}
    >
      <SingleProgramSelectionDropdown
        programs={programs}
        onChangeHandler={handleProgramChange}
        size='medium'
      />

      <Box
        display='flex'
        justifyContent='space-between'
        gap={1}
      >
        <FormControl fullWidth>
          <InputLabel>Read Status</InputLabel>
          <Select
            value={filters.read_status}
            onChange={handleChange}
            label='Read Status'
            name='read_status'
            size='medium'
          >
            <MenuItem value='all'>All</MenuItem>
            <MenuItem value='unread'>Unread</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel>Archive Status</InputLabel>
          <Select
            value={filters.archive_status}
            onChange={handleChange}
            label='Archive Status'
            name='archive_status'
            size='medium'
          >
            <MenuItem value='all'>All</MenuItem>
            <MenuItem value='unarchived'>Unarchived</MenuItem>
            <MenuItem value='archived'>Archived</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <LabelsDropdown
        filters={filters}
        setFilters={setFilters}
      />

      <Box sx={{ textAlign: 'center' }}>
        <Button
          onClick={handleApplyFilters}
          variant='contained'
          size='small'
        >
          Apply
        </Button>
      </Box>
    </Stack>
  );
}

import React, { useState } from 'react';
import { Box, Button, Tabs, Tab, Typography } from '@material-ui/core';
import LoadingButton from '@mui/lab/LoadingButton';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import CustomTabPanel from '../CustomTabPanel';
import { CheckIfNumberIsPowerOfTwoConstant } from '../../../utils/techniqueSagaConstant';
import TopicTitle from '../TopicTitle';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const CheckIfNumberIsPowerOfTwo = ({
  handlePostCompletedTopic,
  hasMarkedCompleted,
  loading,
  topic_id,
  handleNext,
  handlePrevious,
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap='36px'
      paddingY={2}
      maxWidth='calc(100% - 300px)'
    >
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
      >
        <TopicTitle>1. Check if a number is a power of 2</TopicTitle>
        {hasMarkedCompleted(topic_id) && (
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              color: 'green',
              fontWeight: '500',
              border: '2px solid green',
              padding: '4px 10px',
              borderRadius: '20px',
              cursor: 'default',
            }}
          >
            Completed
          </Typography>
        )}
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        gap='24px'
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            width: '34rem',
            background: '#FEF7FF',
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
          >
            <Tab
              label='Problem Statement'
              {...a11yProps(0)}
            />
            <Tab
              label='Traditional Approach'
              {...a11yProps(1)}
            />
            <Tab
              label='Bit Manipulation Approach'
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <CustomTabPanel
          value={value}
          index={0}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Typography>
              {CheckIfNumberIsPowerOfTwoConstant.ProblemStatement01}
            </Typography>

            <Box
              display='flex'
              flexDirection='column'
              gap='8px'
            >
              <Typography>For Example:</Typography>
              <Typography>
                {CheckIfNumberIsPowerOfTwoConstant.ProblemStatement02}
              </Typography>
            </Box>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={1}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Typography>
              {CheckIfNumberIsPowerOfTwoConstant.TraditionalApproach.text1}
            </Typography>

            <Box
              sx={{
                backgroundColor: 'black',
                padding: '24px',
                borderRadius: '10px',
                width: '583px',
              }}
            >
              <pre style={{ color: 'white', margin: 0 }}>
                {`Bool check(int n){
    If (n==0){
        return false;    
    }

   while (n != 1) {
        if (n % 2 != 0)
            return false;
        n = n / 2;
    }

    return true;
}`}
              </pre>
            </Box>

            <Typography>
              {CheckIfNumberIsPowerOfTwoConstant.TraditionalApproach.text2}
            </Typography>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={2}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Typography>
              {CheckIfNumberIsPowerOfTwoConstant.BitManipulationApproach.text01}
            </Typography>

            <Box
              sx={{
                backgroundColor: 'black',
                padding: '24px',
                borderRadius: '10px',
                width: '583px',
              }}
            >
              <pre style={{ color: 'white', margin: 0 }}>
                {`bool isPowerOfTwo(int n)
{
    if(n<0) return false;

    return n && (!(n & (n - 1)));
}`}
              </pre>
            </Box>
            <Typography>
              {CheckIfNumberIsPowerOfTwoConstant.BitManipulationApproach.text02}
            </Typography>
          </Box>
        </CustomTabPanel>
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        alignSelf='end'
        justifyContent='space-between'
        gap='12px'
      >
        {!hasMarkedCompleted(topic_id) && (
          <LoadingButton
            variant='contained'
            onClick={() => handlePostCompletedTopic(topic_id)}
            loading={loading}
            loadingPosition='center'
            children='Mark as completed'
            style={{
              width: '170px',
              // backgroundColor: 'transparent',
              borderRadius: '8px',
              border: '1px solid rgba(64, 96, 245, 0.5)',
            }}
          />
        )}

        <Box
          display='flex'
          alignItems='center'
          gap='8px'
        >
          <Button
            sx={{ gap: '4px' }}
            onClick={handlePrevious}
          >
            <WestIcon /> Previous
          </Button>

          <Button
            sx={{ gap: '4px' }}
            onClick={handleNext}
          >
            Next <EastIcon />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default CheckIfNumberIsPowerOfTwo;

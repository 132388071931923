import React, { useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { Stack, Typography, Box } from '@material-ui/core';
import axios from 'axios';
import Page from '../components/Page';
import { getUserType  } from '../configs/auth';
import { fetchProgramAssignmentsUrl, getHeaders } from '../apis/urls';
import ModalButton from '../components/modal/ModalButton';
import AssignmentCardStudent from '../components/_dashboard/assignments/AssignmentCardStudent';
import AssignmentCardCoach from '../components/_dashboard/assignments/AssignmentCardCoach';
import { AssignmentContext } from '../configs/AssignmentContext';

export default function Assignments() {
	const user_type = getUserType();
	const currentProgram = useSelector(state => state.programs.currentProgram);
	const [assignments, setAssignments] = useContext(AssignmentContext);

	useEffect(() => {
		if (currentProgram) {
			fetchProgramAssignments();
		}
		return () => {};
	}, [currentProgram]);
	
	const fetchProgramAssignments = () => {
		axios
			.get(fetchProgramAssignmentsUrl(), { 
				headers: getHeaders(currentProgram.programId) 
			})
			.then((res) => {
				const assignmentsData = res.data.assignments;
				const completionData = res.data.completion_count;
				setAssignments(assignmentsData);
				AddCompletionRateMessage(completionData, assignmentsData.length);
			})
			.catch((e) => {
				const connection_error = JSON.parse(JSON.stringify(e));
			});
	}

	const AddCompletionRateMessage = (completionRate, totalAssignments) => {
		const submissionPercentage = ((completionRate / totalAssignments) * 100).toPrecision(2);
		switch (true) {
			case submissionPercentage < 60:
				// setCompletionMessage({ message: `You have a submission rate of ${submissionPercentage}%. Please try to keep Assignment submission rate above 75%. Consistency is the key!`, severity: "error" })
				break;
			case submissionPercentage > 60 && submissionPercentage < 75:
				// setCompletionMessage({ message: `Try to keep assignment submission rate above 75%. You have a submission rate of ${submissionPercentage}%`, severity: "warning" })
				break;
			case assignments.length === 0:
				// setCompletionMessage({ message: `There are no assignments due.`, severity: "success" })
				break;
			default:
			// setCompletionMessage({ message: `Great Going! You have a submission rate of ${submissionPercentage}%`, severity: "success" })
		}
	};

	const buttonCss = {};

	return (
		<div>
			<Page>
				<Stack spacing={2} m={2}>
          {user_type !== 'student' && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                mr: 2
              }}
            >
              <ModalButton
                label="New Assignment"
                buttonCss={buttonCss}
                modalType="create_assignment"
              />
            </Box>
          )}
					{assignments?.length > 0 ?
						(
							assignments.map((assignment, index) =>
								user_type !== 'student' ? (
									<Box style={{ margin: '15px' }}>
										<AssignmentCardCoach index={index} key={index} />
									</Box>
								) : (
									<Box style={{ margin: '15px' }}>
										<AssignmentCardStudent index={index} key={index} />
									</Box>
								)
							)
						) : (
							<Typography variant="body1" textAlign="center">
								No assignments found.
							</Typography>
						)
					}
				</Stack>
			</Page>
		</div>
	);
}

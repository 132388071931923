import React, { useState } from 'react';
import { getUserType } from '../../../configs/auth';
import AssignmentsViewForCoaches from './AssignmentsViewForCoaches';
import AssignmentsViewForStudents from './AssignmentsViewForStudents';


export const Assignments = ({ category = "course" }) => {
  const [Detailed, setDetailed] = useState(false);
  const userType = getUserType();

  return (
    <>
      {userType === 'coach'
        ? <AssignmentsViewForCoaches />
        : <AssignmentsViewForStudents category={category} Detailed={Detailed} setDetailed={setDetailed} />
      }
    </>
  );
};

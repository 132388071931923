// @ts-nocheck
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Box, Stack, Typography } from '@mui/material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Trophy from '../../../assets/trophy.png';
import axios from 'axios';
import { BASE_URL } from 'src/apis/urls';
import { useFastrackPipeline } from 'src/layouts/EfPipelineContext/FastrackPipelineContex';

const FastrackOfferLetterInfoPopup = () => {
  const {
    trackButtonClick,
    setShowOfferLetterInfoPopup,
    shouldShowOfferLetterInfoPopup,
  } = useFastrackPipeline();

  const theme = useTheme();

  const handleClosePopup = () => {
    trackButtonClick({
      source: 'v4_welcome_screen',
      eventName: 'i_understand',
    });
    setShowOfferLetterInfoPopup(false);
  };

  return (
    <Dialog
      maxWidth='md'
      open={shouldShowOfferLetterInfoPopup}
      onClose={handleClosePopup}
      PaperProps={{
        style: {
          width: '700px',
          height: 'fit-content',
        },
      }}
    >
      <Stack
        alignItems='center'
        sx={{
          height: 'fit-content',
          padding: '10px 0',
          background: '#e3f2fd',
        }}
      >
        <Box
          style={{
            width: '90px',
            padding: '10px',
            background: '#42a5f5',
            borderRadius: '50%',
          }}
        >
          <img
            style={{
              width: '90px',
              borderRadius: '50%',
              background: '#e3f2fd',
            }}
            src={Trophy}
            alt='trophy'
          />
        </Box>
      </Stack>
      <Stack
        spacing={2}
        padding='1rem'
        maxWidth='inherit'
      >
        <Stack
          direction='row'
          justifyContent='center'
          textAlign='center'
          spacing={1}
        >
          <Box
            sx={{
              fontSize: 'clamp(1.2rem, 1.8rem, 1.8rem)',
              alignSelf: 'center',
            }}
          >
            🌟
          </Box>

          <Typography
            variant='body1'
            component='p'
            sx={{
              color: '#1976d2',
              fontFamily: 'Inter',
              fontWeight: 'bolder',
              fontSize: 'clamp(1.0rem, 1.8rem, 2.4rem)',
              width: 'fit-content',
            }}
          >
            Congratulations, you've passed!
          </Typography>
          <Box
            sx={{
              fontSize: 'clamp(1.2rem, 1.8rem, 1.8rem)',
              alignSelf: 'center',
            }}
          >
            🌟
          </Box>
        </Stack>

        <Typography
          variant='body1'
          component='p'
          sx={{
            fontFamily: 'Inter',
            flexWrap: 'wrap',
            textAlign: 'center',
          }}
        >
          We've reserved your offer letter for the next 30 minutes.
        </Typography>

        <Typography
          variant='body1'
          component='p'
          sx={{
            fontFamily: 'Inter',
            flexWrap: 'wrap',
            textAlign: 'center',
            textWrap: 'stable',
          }}
        >
          Seats are limited, so if you don't enroll in the next 30 minutes, you
          may miss the opportunity to secure your spot.
        </Typography>

        <Box
          display='flex'
          justifyContent='center'
        >
          <Button
            variant='contained'
            endIcon={<ArrowRightAltIcon fontSize='large' />}
            onClick={handleClosePopup}
            sx={{
              fontFamily: 'Inter',
              margin: '10px',
              padding: '11px 16px',
              textAlign: 'center',
              textTransform: 'uppercase',
              transition: '0.5s',
              backgroundSize: '200% auto',
              color: 'white',
              borderRadius: '10px',
              border: 0,
              fontWeight: 700,
              boxShadow: '0px 0px 14px -7px #f09819',
              backgroundImage:
                'linear-gradient(to left, rgb(0, 154, 240), rgb(251, 199, 4) 120%)',
              cursor: 'pointer',
              userSelect: 'none',
              WebkitUserSelect: 'none',
              touchAction: 'manipulation',
              '&:hover': {
                backgroundPosition: 'right center',
                color: '#fff',
                textDecoration: 'none',
              },
              '&:active': {
                transform: 'scale(0.95)',
              },
              textTransform: 'none',
            }}
          >
            I understand
          </Button>
        </Box>
      </Stack>
    </Dialog>
  );
};

export default FastrackOfferLetterInfoPopup;

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Autocomplete, TextField } from '@mui/material';

/**
 * https://mui.com/material-ui/react-autocomplete/#ControllableStates.js
 *
 * Mandatory props = {
 *  sx: {
 *   Any styling goes here.
 *  },
 *
 *  onChangeHandler: () => {} // to update the value of program,
 *
 *  value: stores currently selected programId
 * }
 *
 * Some of the props for which default value can be overriden = {
 *  label: "Program" (default),
 *  showAllOption: false (default),
 *  showNoneOption: false (default),
 * }
 *
 * all other props are optional.
 */

const defaultStyle = {
  width: '100%',
};

export default function ProgramsListAutocompleteDropdown({
  value,
  label = 'Program',
  onChangeHandler,
  sx = defaultStyle,
  showAllOption = false,
  showNoneOption = false,
  ...restProps
}) {
  const programs = useSelector((state) => state.programs.programs);
  const [inputValue, setInputValue] = useState('');
  const [allPrograms, setAllPrograms] = useState([]);

  useEffect(() => {
    getPrograms();
    return () => {};
  }, []);

  function getPrograms() {
    const tmpPrograms = [];

    if (showAllOption) {
      tmpPrograms.push({
        label: 'All',
        value: '',
      });
    }

    if (showNoneOption) {
      tmpPrograms.push({
        label: 'None',
        value: 'none',
      });
    }

    Object.keys(programs).forEach((pid, index) => {
      tmpPrograms.push({
        label: programs[pid].internal_name,
        value: programs[pid].programId,
      });
    });

    setAllPrograms(tmpPrograms);
  }

  return (
    <Autocomplete
      {...restProps}
      value={inputValue}
      onChange={(event, newValue) => {
        setInputValue(newValue);
        onChangeHandler(event, newValue);
      }}
      options={allPrograms}
      getOptionLabel={(option) => option.label ?? ''}
      sx={sx}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
        />
      )}
      disableClearable
    />
  );
}

import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Typography } from '@material-ui/core';
import { Box } from '@material-ui/system';
import DownloadIcon from '@mui/icons-material/Download';
import Papa from 'papaparse';
import moment from 'moment';
import CoachPaymentsTable from './CoachPaymentsTable';
import PaymentModal from './PaymentModal';

export default function FinancesTable({
  coaches,
  setHasError,
  setErrorMessage,
  openPaymentModal,
  setOpenPaymentModal,
  addPayment,
  totalAmountToBeProcessed,
}) {
  const [selectedCoach, setSelectedCoach] = useState({});
  const [openCoachPayments, setOpenCoachPayments] = useState(false);
  const [perPage, setPerPage] = useState(50);

  const checkForMissingFields = () => {
    const missingFields = [];
    coaches.forEach((coach) => {
      if (
        coach.transferred &&
        (!coach.account_number ||
          !coach.beneficiary_name ||
          !coach.ifsc_code ||
          !coach.bank_name)
      ) {
        missingFields.push({
          coachName: coach.name,
        });
      }
    });
    return missingFields;
  };

  const filteredCoaches = () => {
    const coachesData = [];
    for (let i = 0; i < coaches.length - 1; i += 1) {
      const ifsc = coaches[i].ifsc_code?.substring(0, 4).toUpperCase();
      const data = [
        ifsc === 'HDFC' ? 'I' : 'N',
        coaches[i].id ? coaches[i].id : '',
        coaches[i].account_number ? coaches[i].account_number : '',
        coaches[i].transferred ? coaches[i].transferred : '',
        coaches[i].beneficiary_name ? coaches[i].beneficiary_name : '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        coaches[i].id ? coaches[i].id : '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        moment().format('DD/MM/YYYY'),
        '',
        coaches[i].ifsc_code ? coaches[i].ifsc_code : '',
        coaches[i].bank_name ? coaches[i].bank_name : '',
        '',
        coaches[i].email ? coaches[i].email : '',
      ];
      if (coaches[i].total !== 0) {
        coachesData.push(data);
      }
    }
    return coachesData;
  };

  const errorNotificationMessage = (missingFields) => {
    let errorMessage = 'Missing bank details for ';
    const coachNames = missingFields.map((item) => item.coachName);
    errorMessage += coachNames.join(', ');
    return errorMessage;
  };

  const downloadCSV = () => {
    const missingFields = checkForMissingFields();
    if (missingFields.length > 0) {
      const errorMessage = errorNotificationMessage(missingFields);
      setHasError(true);
      setErrorMessage(errorMessage);
      return;
    }

    const csv = Papa.unparse(filteredCoaches());
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');

    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, 'coaches-payment-data.csv');
    } else {
      const url = URL.createObjectURL(blob);
      link.href = url;
      link.setAttribute('download', 'coaches-payment-data.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const columns = [
    {
      field: 'id',
      headerName: 'Id',
      type: 'number',
      width: 90,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => params.row.id,
    },
    {
      field: 'coach',
      headerName: 'Coach',
      width: 350,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => `${params.row.name} (${params.row.email})`,
    },
    {
      field: 'total',
      headerName: 'Total',
      type: 'number',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => params.row?.total,
    },
    {
      field: 'tds',
      headerName: 'TDS (10%)',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => params.row?.tds,
    },
    {
      field: 'transferred',
      headerName: 'Amount payable',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => params.row?.transferred,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      cellClassName: 'actions',
      getActions: ({ id, row }) => {
        return [
          <Button
            variant='contained'
            size='small'
            disabled={
              row.session_ids.length === 0 || (row?.transferred ?? 0) === 0
            }
            onClick={(e) => {
              setSelectedCoach(row);
              setOpenPaymentModal(true);
            }}
          >
            Process payment
          </Button>,
        ];
      },
    },
  ];

  return (
    <>
      <Button
        component='label'
        variant='contained'
        sx={{ position: 'absolute', right: '5%', marginBottom: '20px' }}
        startIcon={<DownloadIcon />}
        onClick={downloadCSV}
      >
        Download CSV
      </Button>
      {!openCoachPayments && (
        <Box sx={{ width: 1100, height: '100%', mt: 5, alignSelf: 'center' }}>
          <Typography variant='h6'>
            Total amount to be processed: &#8377; {totalAmountToBeProcessed}
          </Typography>

          <DataGrid
            rows={coaches}
            columns={columns}
            pageSize={perPage}
            onPageSizeChange={(pageSize) => setPerPage(pageSize)}
            rowsPerPageOptions={[50, 100, 150]}
            autoHeight
            disableSelectionOnClick
            sx={{
              '& .MuiDataGrid-row:hover': {
                backgroundColor: '#e3f2fd',
                cursor: 'pointer',
              },
            }}
            onRowClick={(params, event) => {
              setSelectedCoach(params.row);
              setOpenCoachPayments(true);
            }}
            experimentalFeatures={{ newEditingApi: true }}
            onRowEditStart={(params, event) => {
              event.defaultMuiPrevented = true;
            }}
            onRowEditStop={(params, event) => {
              event.defaultMuiPrevented = true;
            }}
          />
        </Box>
      )}
      {openCoachPayments && (
        <CoachPaymentsTable
          {...{
            setOpenCoachPayments,
            coach: selectedCoach,
            setHasError,
            setErrorMessage,
          }}
        />
      )}
      {openPaymentModal && (
        <PaymentModal
          {...{
            openPaymentModal,
            coach: selectedCoach,
            setOpenPaymentModal,
            addPayment,
          }}
        />
      )}
    </>
  );
}

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import tw from 'twin.macro';
import { Link, AttachFileSharp } from '@material-ui/icons';
import { IconButton, Box } from '@material-ui/core';
import { openS3URL, getHeaders } from '../../../apis/urls';

const PrimaryBtn = tw.a`rounded-lg px-3 py-1  mt-2 text-sm sm:text-base  font-bold shadow transition duration-300 bg-blue-500 text-gray-100 hocus:bg-blue-700 hocus:text-gray-200 focus:outline-none focus:shadow-inner`;

function ResourceType({ type, resource, programId }) {
  const [file, setFile] = useState(null);
  
  useEffect(() => {
    const openFileHandler = async () => {
      try {
        const { data } = await axios.get(openS3URL(resource.id), { 
          headers: getHeaders(programId) 
        });
        setFile(data.file);
      } catch (err) {
        console.log(err);
      }
    };
    if (type === 'file') {
      openFileHandler();
    }
    return () => {}
  }, []);

  switch (type) {
    case 'link':
      return (
        <Box marginTop="0.5rem">
          <span>
            <IconButton>
              <Link />
            </IconButton>
            <a href={resource.link}>{resource.link}</a>
            <br />
          </span>
        </Box>
      );
    case 'file':
      return (
        <Box marginTop="0.5rem">
          <span>
            <IconButton>
              <AttachFileSharp />
            </IconButton>
            <PrimaryBtn
              style={{
                textDecoration: 'none',
                width: 'auto',
                height: 'auto',
                cursor: 'pointer'
              }}
              href={file}
              target="_blank"
              rel="noreferrer"
            >
              view
            </PrimaryBtn>
            <br />
          </span>
        </Box>
      );
    default:
      return <></>;
  }
}

export default ResourceType;

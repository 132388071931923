import React from 'react';
import Box from '@mui/material/Box';

const LeftArrowCircle = ({ rotateCircles }) => {
  return (
    <Box
      style={{
        position: 'absolute',
        top: '85%',
        left: '48%',
        transform: 'translate(-50%, -50%)',
        display: 'inline-block',
        width: '50px',
        height: '50px',
        marginRight: '10px',
        margin: '5px',
        cursor: 'pointer',
      }}
      onClick={() => rotateCircles('left')}
    >
      <svg
        width='45'
        height='45'
        viewBox='0 0 64 64'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <circle
          cx='32'
          cy='32'
          r='31.5'
          transform='rotate(180 32 32)'
          fill='white'
          stroke='#424242'
        />
        <path
          d='M15.2929 31.2929C14.9024 31.6834 14.9024 32.3166 15.2929 32.7071L21.6569 39.0711C22.0474 39.4616 22.6805 39.4616 23.0711 39.0711C23.4616 38.6805 23.4616 38.0474 23.0711 37.6569L17.4142 32L23.0711 26.3431C23.4616 25.9526 23.4616 25.3195 23.0711 24.9289C22.6805 24.5384 22.0474 24.5384 21.6569 24.9289L15.2929 31.2929ZM48 31L16 31L16 33L48 33L48 31Z'
          fill='#424242'
        />
      </svg>
    </Box>
  );
};

export default LeftArrowCircle;

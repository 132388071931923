import axios from 'axios';
import React, { useState, createContext, useEffect } from 'react';
import { fetchProgramsUrl, getHeaders } from '../apis/urls';
import ErrorNotifier from '../components/ToastNotifications/ErrorNotifier';
import { slugify } from '../components/utils/common';

export const AdminProgramContext = createContext();

export const AdminProgramProvider = (props) => {
    const [programs, setPrograms] = useState({});
    const [programAddSuccess, setProgramAddSuccess] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
      axios.get(fetchProgramsUrl(), {
        headers: getHeaders()
      })
      .then((res) => {
        if (!res.data) {
          setHasError(true);
          setErrorMessage("Could not fetch program details");
          return;
        }
        const storedAllPrograms = {};
        res?.data?.forEach((val) => {
          storedAllPrograms[slugify(val.name)] = {
            programId: val.id,
            name: val.name
          }
        });
        setPrograms(storedAllPrograms);
      })
      .catch((e) => {
        console.log(e);
        setHasError(e);
        setErrorMessage(e.response?.data?.message || e.message);
      });
      return () => {};
    }, [programAddSuccess]);

    return (
      <AdminProgramContext.Provider value={{programs, programAddSuccess, setProgramAddSuccess, setPrograms}}>
        { hasError && <ErrorNotifier message={errorMessage} setHasError={setHasError} /> }
        {props.children}
      </AdminProgramContext.Provider>
    );
  }
import { Button, FormControl, InputLabel, MenuItem, Modal, Select, Stack, TextField } from '@material-ui/core';
import { Box } from '@material-ui/system';
import axios from 'axios';
import moment from 'moment/moment';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addSessions, updateSession } from '../../../redux-store/slices/sessionSlice';
import { getHeaders, postSessionURL } from '../../../../apis/urls';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function NewSessionModal({ 
  sessionTypes, 
  openNewSessionModal, 
  setOpenNewSessionModal, 
  setHasError, 
  setErrorMessage, 
  setIsSuccess, 
  setSuccessMessage, 
  programId,
}) {
  const dispatch = useDispatch();
  const [modalData, setModalData] = useState({
    title: "",
    start_time: null,
    session_type_id: "",
    group_name: "",
    topic_id: 1 // hard coding this right now. But, change this whenever requirements change
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const body = {...modalData};
    body.start_time = moment(body.start_time).utc();

    if (body.session_type_id === "") {
      setHasError(true);
      setErrorMessage("Session type cannot be empty.");
      return;
    }
    if (body.group_name === "") {
      setHasError(true);
      setErrorMessage("Group name cannot be empty.");
      return;
    }

    axios.post(postSessionURL(), body, {
      headers: getHeaders(programId)
    })
    .then((res) => {
      setIsSuccess(true);
      setSuccessMessage("The session was created successfully.");
      dispatch(addSessions(res?.data ?? []));
      setOpenNewSessionModal(false);
    })
    .catch((err) => {
      console.log(err);
      setOpenNewSessionModal(false);
      setHasError(true);
      setErrorMessage(err.response?.data?.message || "Could not create the session.");
    })
  }
  
  return (
    <Modal open={openNewSessionModal} onClose={() => setOpenNewSessionModal(false)} style={{ overflow: "scroll" }}>
      <form onSubmit={handleSubmit}>
        <Box sx={style}>
          <Stack spacing={4}>
            <TextField 
              fullWidth
              id="title"
              label="title"
              name="title"
              variant="outlined"
              value={modalData?.title}
              onChange={(e) => setModalData({...modalData, title: e.target.value})}
              required
            />
            <TextField 
              fullWidth
              id="start-time"
              label="Start time in IST"
              type="datetime-local"
              name="start_time"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={modalData?.start_time}
              onChange={(e) => setModalData({...modalData, start_time: e.target.value})}
              required
            />
            <GroupNameDropdown {...{modalData, setModalData, sx: {width: '100%', height: 40, mr: 1} }}/>
            <SessionTypeDropdown {...{modalData, setModalData, sx: {width: '100%', height: 40, mr: 1}, sessionTypes}}/>
            <Button variant='contained' type="submit">
              Add session
            </Button>
          </Stack>
        </Box>
      </form>
    </Modal>
  );
}

function GroupNameDropdown({ modalData, setModalData, sx }) {
  return (
    <>
      <FormControl sx={sx}>
        <InputLabel id="group-name">Group name</InputLabel>
        <Select
          required
          labelId="group-name"
          id="group-name"
          value={modalData.group_name}
          label="Select learner"
          onChange={(e) => setModalData({...modalData, group_name: e.target.value})}
        >
          <MenuItem value=""><em>None</em></MenuItem>
          {sessionGroups?.map((group, index) => (
            <MenuItem value={group}>{group}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}

function SessionTypeDropdown({ modalData, setModalData, sx, sessionTypes }) {
  return (
    <>
      <FormControl sx={sx}>
        <InputLabel id="session-type">Session type</InputLabel>
        <Select
          required
          labelId="session-type"
          id="session-type"
          value={modalData.session_type_id}
          label="Select learner"
          onChange={(e) => setModalData({...modalData, session_type_id: e.target.value})}
        >
          <MenuItem value=""><em>None</em></MenuItem>
          {sessionTypes?.map((type, index) => (
            <MenuItem value={type.id}>{type.duration} minutes ({type.name})</MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}

const sessionGroups = [
  'Hiring manager round', 
  'Profile Presentation', 
  'Fireside chat', 
  'Dynamic programming', 
  'Graph algorithms', 
  'High level system design interviews', 
  'Basic data structures and Dynammic Programming', 
  'Advanced data structures', 
  'Building blocks and basic algorithms', 
  'Week-0 Basics', 
  'Basic Data Structures and Dynamic Programming', 
  'Advanced concepts', 
  'Low Level Design', 
  'High level design concepts', 
  'Basic data structures', 
  'MAANG system design interviews',
  'Personal Coaching'
]
import { Button, Stack, Typography } from '@material-ui/core';
import { Box } from '@material-ui/system';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import { fetchProgramEnrollmentsUrl, fetchProgramTasksURL, getHeaders, updateTaskURL } from '../../../../apis/urls';
import ErrorNotifier from '../../../ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../../ToastNotifications/SuccessNotifier';
import NewTaskModal from './NewTaskModal';
import AdminDetailedTaskDialog from './AdminDetailedTaskModal';

const timeFormat = "MMM DD, YYYY, hh:mm A";
export default function Tasks() {
  const [tasks, setTasks] = useState([]);
  const [openNewTaskModal, setOpenNewTaskModal] = useState(false);
  const [taskIdToIndexMap, setTaskIdToIndexMap] = useState({});
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [learners, setLearners] = useState([]);
  const [coaches, setCoaches] = useState([]);
  const [taskBeingUpdated, setTaskBeingUpdated] = useState({});
  const [openDetailedTask, setOpenDetailedTask] = useState(false);
  const [selectedTask, setSelectedTask] = useState({});
  const currentProgram = useSelector(state => state.programs.currentProgram);

  useEffect(() => {
    if (currentProgram) {
      getTasks();
      getEnrollments();
    }
    return () => {};
  }, [currentProgram])

  function getTasks() {
    axios.get(fetchProgramTasksURL(), {
      headers: getHeaders(currentProgram?.programId)
    })
    .then((res) => {
      const tmpIdMap = {};
      res.data?.forEach((task, index) => { tmpIdMap[task.id] = index; });
      setTasks(res.data);
      setTaskIdToIndexMap(tmpIdMap);
    })
    .catch((err) => {
      console.log(err);
      setHasError(true);
      setErrorMessage(err?.response?.data?.message || err.message);
    })
  }

  function getEnrollments() {
    axios.get(fetchProgramEnrollmentsUrl(), {
      headers: getHeaders(currentProgram?.programId)
    })
    .then((res) => {
      const tmpLearners = [];
      const tmpCoaches = [];
      res.data.forEach((enrollment, index) => {
        if (enrollment.program_access === "allowed") {
          if (enrollment?.user.role === "student") {
            tmpLearners.push({
              enrollmentId: enrollment.id,
              userId: enrollment?.user.id,
              name: enrollment?.user.name,
              email: enrollment?.user.email
            })
          } else if (enrollment?.user.role === "coach") {
            tmpCoaches.push({
              enrollmentId: enrollment.id,
              userId: enrollment?.user.id,
              name: enrollment?.user.name,
              email: enrollment?.user.email
            })
          }
        }
      });
      setLearners(tmpLearners);
      setCoaches(tmpCoaches);
    })
    .catch((err) => {
      console.log(err);
      setHasError(true);
      setErrorMessage(err?.response?.data?.message || err.message);
    })
  }

  const columns = [
    {
      field: "id",
      headerName: "Task id",
      type: "number",
      width: 70,
      align: "center",
      headerAlign: "center"
    },
    {
      field: "title",
      headerName: "Title",
      width: 240,
      align: "center",
      editable: true,
      headerAlign: "center"
    },
    {
      field: "assignee",
      headerName: "Assignee",
      width: 180,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => params.row.assignee?.name || "NA",
    },
    {
      field: "deadline",
      headerName: "Deadline",
      width: 200,
      align: "center",
      headerAlign: "center",
      type: "dateTime",
      editable: true,
      valueGetter: (params) => (params.row?.deadline ? moment(params.row.deadline).format(timeFormat): 'NA'),
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      align: "center",
      headerAlign: "center",
      type: "singleSelect",
      valueOptions: ["pending", "completed awaiting verification", "completed and accepted"],
      editable: true,
      valueGetter: (params) => params.row.status?.split("_").join(" ") || "NA",
    },
    {
      field: "added_by",
      headerName: "Added by",
      width: 180,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => params.row.added_by?.name || "NA",
    },
    {
      field: "closed_at",
      headerName: "Closed at",
      width: 200,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => (params.row.closed_at ? moment(params.row.closed_at).format(timeFormat): 'NA'),
    },
    {
      field: "closed_by",
      headerName: "Closed by",
      width: 180,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => params.row.closed_by?.name || "NA",
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 140,
      align: "center",
      headerAlign: "center",
      cellClassName: "actions",
      getActions: ({ id, row }) => {
        const isInEditMode = taskBeingUpdated[id]?.mode === "edit";
        if (row.status === "completed_and_accepted") {
          return [];
        }
        if (isInEditMode) {
          return [
            <CheckIcon fontSize='medium' color="success" sx={{cursor: 'pointer'}} onClick={(e) => handleTaskUpdate("update", id)} />,
            <ClearIcon fontSize='medium' color="error" onClick={(e) => handleTaskUpdate("clear", id)} sx={{cursor: 'pointer'}} />
          ];
        }
        return [
          <EditIcon onClick={(e) => {
              setTaskBeingUpdated({ ...taskBeingUpdated, [id]: { mode: 'edit' } });
            }}
            sx={{cursor: 'pointer'}}
          />
        ];
      }
    }
  ];

  const handleTaskUpdate = (clickType, id) => {
    if (clickType === "clear") {
      setTaskBeingUpdated({
        ...taskBeingUpdated,
        [id]: { mode: "view", ignoreModifications: true },
      });
      return;
    }
    setTaskBeingUpdated({ ...taskBeingUpdated, [id]: { mode: "view" } });
  }

  const processTaskUpdate = (updatedTask) => {
    const body = {};
    body.deadline = moment(updatedTask.deadline).utc();
    body.title = updatedTask.title;
    body.status = updatedTask.status.split(" ").join("_");

    axios.patch(updateTaskURL(updatedTask.id), body, {
      headers: getHeaders(currentProgram?.programId)
    })
    .then((res) => {
      const index = taskIdToIndexMap[updatedTask.id];
      const tmpTasks = [...tasks];
      tmpTasks[index] = res.data;
      setIsSuccess(true);
      setSuccessMessage("Task successfully updated.");
      setTasks(tmpTasks);
    })
    .catch((err) => {
      console.log(err);
      setHasError(true);
      setErrorMessage(err?.response?.data?.message || err.message);
    })
    return updatedTask; // this is required. Didn't find the reason in MUI documentation.
    // for reference, refer to this link: https://mui.com/x/react-data-grid/editing/#full-featured-crud-component
  }

  return (
    <Stack mt={2}>
      { hasError && <ErrorNotifier message={errorMessage} setHasError={setHasError} />}
      { isSuccess && <SuccessNotifier message={successMessage} setIsSuccess={setIsSuccess} />}
      <Box display="flex" justifyContent="flex-end" sx={{mr: 1}}>
        <Button variant='contained' onClick={() => setOpenNewTaskModal(true)}>
          Add New Task
        </Button>
      </Box>
      {tasks.length > 0 &&
        <Box sx={{ width: '100%', height: "100%", mt: 2 }}>
          <DataGrid 
            rows={tasks}
            columns={columns}
            pageSize={100}
            rowsPerPageOptions={[100]}
            autoHeight
            disableSelectionOnClick
            onRowClick={(params, event) => {
              setOpenDetailedTask(true);
              setSelectedTask(params.row);
            }}
            sx={{
              "& .MuiDataGrid-row:hover": {
                backgroundColor: "#e3f2fd",
                cursor: "pointer"
              }
            }}
            rowModesModel={taskBeingUpdated}
            experimentalFeatures={{ newEditingApi: true }}
            editMode='row'
            processRowUpdate={processTaskUpdate}
            onRowEditStart={(params, event) => {
              event.defaultMuiPrevented = true;
            }}
            onRowEditStop={(params, event) => {
              event.defaultMuiPrevented = true;
            }}
          />
        </Box>
      }
      {tasks.length === 0 &&
        <Typography align='center' variant='body2' sx={{mt: 2}}>
          No tasks have been added so far.
        </Typography>
      }
      {openNewTaskModal &&
        <NewTaskModal
          openNewTaskModal={openNewTaskModal}
          setOpenNewTaskModal={setOpenNewTaskModal}
          setHasError={setHasError}
          setErrorMessage={setErrorMessage}
          setSuccessMessage={setSuccessMessage}
          setIsSuccess={setIsSuccess}
          programId={currentProgram?.programId}
          tasks={tasks}
          setTasks={setTasks}
          learners={learners}
          coaches={coaches}
        />
      }
      {openDetailedTask && 
        <AdminDetailedTaskDialog 
          {...{task: selectedTask, openDetailedTask, setOpenDetailedTask, programId: currentProgram?.programId, setErrorMessage, setHasError}}
        />
      }
    </Stack>
  );
}

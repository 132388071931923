import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import { Box } from '@material-ui/system';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import {
  fetchAllEnrollmentsLevelsURL,
  fetchProgramEnrollmentsUrl,
  getHeaders,
  updateEnrollmentsLevelURL,
  postEnrollmentsLevelURL,
} from '../../../apis/urls';
import ErrorNotifier from '../../ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../ToastNotifications/SuccessNotifier';
import LevelDropdown from '../Levels/LevelsDropdown';
import { style } from './Programs';

const initialState = {
  enrollment_id: '',
  level_id: '',
};

export function EnrollmentDropdown({
  sx,
  enrollments,
  handleEnrollmentSelect,
  enrollmentIdFilter,
}) {
  return (
    <>
      <FormControl sx={sx}>
        <InputLabel id='learner'>Select Learner</InputLabel>
        <Select
          labelId='learner'
          id='learner'
          value={enrollmentIdFilter}
          label='Select learner'
          onChange={handleEnrollmentSelect}
        >
          <MenuItem value=''>
            <em>None</em>
          </MenuItem>
          {enrollments?.map((enrollment, index) => (
            <MenuItem value={enrollment.enrollmentId}>
              {enrollment.name}
              {` (${enrollment.email})`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}

export default function LearnerLevels() {
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [enrollmentIdFilter, setEnrollmentIdFilter] = useState('');
  const [levelIdFilter, setLevelIdFilter] = useState('');
  const [shouldOpenEnrollmentLevelModal, setShouldOpenEnrollmentLevelModal] =
    useState(false);
  const [enrollments, setEnrollments] = useState([]);
  const [learnerLevelData, setLearnerLevelData] = useState([]);
  const currentProgram = useSelector((state) => state.programs.currentProgram);

  useEffect(() => {
    if (currentProgram) {
      getProgramEnrollments();
      fetchEnrollmentsLevels();
    }
    return () => {};
  }, [levelIdFilter, enrollmentIdFilter, isSuccess, currentProgram]);

  useEffect(() => {
    if (currentProgram) {
      getProgramEnrollments();
    }
    return () => {};
  }, []);

  function getProgramEnrollments() {
    axios
      .get(fetchProgramEnrollmentsUrl(), {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((res) => {
        const tmpEnrollments = [];
        res.data?.forEach((enrollment, index) => {
          if (
            enrollment.program_access === 'allowed' &&
            enrollment?.user.role === 'student'
          ) {
            tmpEnrollments.push({
              enrollmentId: enrollment.id,
              userId: enrollment?.user.id,
              name: enrollment?.user.name,
              email: enrollment?.user.email,
            });
          }
        });
        setEnrollments(tmpEnrollments);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(err.response?.data?.message || err.message);
      });
  }

  function fetchEnrollmentsLevels() {
    const queryParams = [];
    if (enrollmentIdFilter !== '') {
      queryParams.push(`enrollment_id=${enrollmentIdFilter}`);
    }
    if (levelIdFilter !== '') {
      queryParams.push(`level_id=${levelIdFilter}`);
    }

    axios
      .get(
        fetchAllEnrollmentsLevelsURL(
          queryParams.length > 0 ? `?${queryParams.join('&')}` : ''
        ),
        {
          headers: getHeaders(currentProgram?.programId),
        }
      )
      .then((res) => {
        const tmpData = res.data?.map((val, index) => ({
          id: val?.id,
          eid: val?.enrollment?.id,
          userName: val?.enrollment?.user?.name,
          LevelName: val?.level?.name,
          lid: val?.level?.id,
          unlockedOn: val?.unlocked_on,
        }));
        setLearnerLevelData(tmpData);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(err.response?.data?.message || err.message);
      });
  }

  const handleClickMarkAsFailed = (enrollmentId) => {
    axios
      .patch(
        updateEnrollmentsLevelURL(enrollmentId),
        {
          mark_failed: true,
        },
        {
          headers: getHeaders(currentProgram?.programId),
        }
      )
      .then((res) => {
        setIsSuccess(true);
        setSuccessMessage('Successfully marked as failed');
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(err.response?.data?.message || err.message);
      });
  };

  const handleEnrollmentSelect = (e) => {
    setEnrollmentIdFilter(e.target.value);
  };

  return (
    <Box mt={2}>
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      {isSuccess && (
        <SuccessNotifier
          message={successMessage}
          setIsSuccess={setIsSuccess}
        />
      )}
      <Box
        display='flex'
        justifyContent='flex-end'
        alignItems='center'
        sx={{ mb: 1, mt: 1 }}
      >
        <LevelDropdown
          levelIdFilter={levelIdFilter}
          setLevelIdFilter={setLevelIdFilter}
          sx={{
            width: '15rem',
            height: 40,
            mr: 1,
          }}
        />
        <EnrollmentDropdown
          enrollmentIdFilter={enrollmentIdFilter}
          handleEnrollmentSelect={handleEnrollmentSelect}
          enrollments={enrollments}
          sx={{
            width: '15rem',
            height: 40,
            mr: 1,
          }}
        />
        <Button
          variant='contained'
          onClick={(e) => setShouldOpenEnrollmentLevelModal(true)}
          sx={{ mr: 1 }}
        >
          Unlock new Level
        </Button>
      </Box>

      <LearnerLevelTableStructure
        learnerLevelData={learnerLevelData}
        handleClickMarkAsFailed={handleClickMarkAsFailed}
      />
      {learnerLevelData?.length === 0 && (
        <Typography
          variant='body1'
          align='center'
          sx={{ mt: 2 }}
        >
          No data found.
        </Typography>
      )}
      {shouldOpenEnrollmentLevelModal && (
        <UnlockNewLevel
          setErrorMessage={setErrorMessage}
          setHasError={setHasError}
          setIsSuccess={setIsSuccess}
          setSuccessMessage={setSuccessMessage}
          shouldOpenEnrollmentLevelModal={shouldOpenEnrollmentLevelModal}
          setShouldOpenEnrollmentLevelModal={setShouldOpenEnrollmentLevelModal}
        />
      )}
    </Box>
  );
}

function LearnerLevelTableStructure({
  learnerLevelData,
  handleClickMarkAsFailed,
}) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650, mt: 4 }}>
        <TableHead>
          <TableRow>
            <TableCell align='center'>id</TableCell>
            <TableCell align='center'>Level name</TableCell>
            <TableCell align='center'>Learner</TableCell>
            <TableCell align='center'>Unlocked on</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {learnerLevelData?.map((data, index) => (
            <TableRow key={index + 1}>
              <TableCell align='center'>{data?.id}</TableCell>
              <TableCell align='center'>{data?.LevelName}</TableCell>
              <TableCell align='center'>{data?.userName}</TableCell>
              <TableCell align='center'>
                {moment(data?.unlockedOn).format('DD-MM-YYYY')}
              </TableCell>

              <TableCell align='center'>
                {['C11', 'C12', 'C13'].includes(data?.LevelName) && (
                  <Button
                    variant='contained'
                    color='warning'
                    onClick={() => handleClickMarkAsFailed(data?.id)}
                  >
                    Mark as Failed
                  </Button>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function UnlockNewLevel({
  setErrorMessage,
  setHasError,
  setIsSuccess,
  setSuccessMessage,
  shouldOpenEnrollmentLevelModal,
  setShouldOpenEnrollmentLevelModal,
}) {
  const [levelId, setLevelId] = useState('');
  const [enrollmentId, setEnrollmentId] = useState('');
  const [unlockedOn, setUnlockedOn] = useState(null);
  const currentProgram = useSelector((state) => state.programs.currentProgram);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (levelId === '') {
      setHasError(true);
      setErrorMessage('Please select a level.');
      return;
    }
    axios
      .post(
        postEnrollmentsLevelURL(),
        {
          level_id: levelId,
          enrollment_id: enrollmentId,
          unlocked_on: moment(unlockedOn).utc(),
        },
        {
          headers: getHeaders(currentProgram?.programId),
        }
      )
      .then((res) => {
        setShouldOpenEnrollmentLevelModal(false);
        setIsSuccess(true);
        setSuccessMessage('Successfully unlocked the level.');
        setLevelId('');
        setEnrollmentId('');
        setUnlockedOn(null);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || 'Could not unlock the level.'
        );
        setLevelId('');
        setEnrollmentId('');
        setUnlockedOn(null);
      });
  };

  return (
    <>
      <Modal
        open={shouldOpenEnrollmentLevelModal}
        onClose={() => setShouldOpenEnrollmentLevelModal(false)}
        style={{ overflow: 'scroll' }}
      >
        <form onSubmit={handleSubmit}>
          <Box sx={style}>
            <Stack spacing={3}>
              <TextField
                fullWidth
                id='enrollment_id'
                label='Enrollment id'
                name='enrollment_id'
                variant='outlined'
                value={enrollmentId}
                onChange={(e) => setEnrollmentId(e.target.value)}
                required
              />
              <LevelDropdown
                levelIdFilter={levelId}
                setLevelIdFilter={setLevelId}
                sx={{ width: '100%' }}
              />
              <TextField
                fullWidth
                id='unlocked-on'
                label='unlock date'
                type='datetime-local'
                variant='outlined'
                InputLabelProps={{ shrink: true }}
                value={unlockedOn}
                onChange={(e) => setUnlockedOn(e.target.value)}
                required
              />
              <Button
                size='large'
                type='submit'
                variant='contained'
                color='secondary'
                startIcon={<LockOpenIcon />}
              >
                Unlock
              </Button>
            </Stack>
          </Box>
        </form>
      </Modal>
    </>
  );
}

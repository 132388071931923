// @ts-nocheck
import React, { useEffect, useState } from 'react';
import {
  TextField,
  Box,
  Button,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
} from '@mui/material';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { fetchQuizAttempts, getHeaders } from '../../../../apis/urls';
import SendQuizModal from './SendQuizModal';
import ErrorNotifier from '../../../ToastNotifications/ErrorNotifier';
import LoadingScreen from '../../../../pages/LoadingScreen';
import SuccessNotifier from '../../../ToastNotifications/SuccessNotifier';
import QuizAttemptsTable from './QuizAttemptsTable';

const filterState = {
  learner_name: '',
  quiz_name: '',
  quiz_type: '',
};

export default function ProgramQuizAttempts() {
  const [filter, setFilter] = useState(filterState);
  const [quizResponses, setQuizResponses] = useState([]);
  const [open, setOpen] = useState(false);
  const onClose = () => setOpen(false);
  const currentProgram = useSelector((state) => state.programs.currentProgram);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchAttempts();
    return () => {};
  }, [filter]);

  const fetchAttempts = () => {
    setLoading(true);
    const queryParams = [];
    if (filter.learner_name) {
      queryParams.push(`learner_name=${filter.learner_name}`);
    }
    if (filter.quiz_name) {
      queryParams.push(`quiz_name=${filter.quiz_name}`);
    }
    if (filter.quiz_type) {
      queryParams.push(`quiz_type=${filter.quiz_type}`);
    }

    axios
      .get(fetchQuizAttempts(queryParams), {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((res) => {
        setQuizResponses(res?.data);
      })
      .catch((e) => {
        console.log(e);
        setHasError(true);
        setErrorMessage(
          e.response?.data?.message ||
            'An error occurred while fetching the quiz attempts'
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function handleFilterUpdate(e) {
    const { name, value } = e.target;
    setFilter((prevFilter) => ({
      ...prevFilter,
      [name]: value,
    }));
  }

  return (
    <>
      <LoadingScreen loading={loading} />
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      {isSuccess && (
        <SuccessNotifier
          message={successMessage}
          setIsSuccess={setIsSuccess}
        />
      )}
      <Box
        m={2}
        textAlign='right'
      >
        <FormControl>
          <InputLabel>Quiz Type</InputLabel>
          <Select
            label='Quiz Type'
            name='quiz_type'
            value={filter.quiz_type}
            onChange={(e) => handleFilterUpdate(e)}
            sx={{ width: '7rem', textAlign: 'left' }}
          >
            <MenuItem value='practice'>Practice</MenuItem>
            <MenuItem value='exam'>Exam</MenuItem>
          </Select>
        </FormControl>
        <TextField
          sx={{ mx: 2 }}
          label='Search Learner Name'
          name='learner_name'
          value={filter.learner_name}
          onChange={(e) => handleFilterUpdate(e)}
        />
        <TextField
          sx={{ mx: 2 }}
          label='Search Quiz Name'
          name='quiz_name'
          value={filter.quiz_name}
          onChange={(e) => handleFilterUpdate(e)}
        />
        <Button
          sx={{ m: 1 }}
          variant='contained'
          onClick={() => setOpen(true)}
        >
          Send Quiz
        </Button>
      </Box>
      <QuizAttemptsTable quizResponses={quizResponses} />
      {open && (
        <SendQuizModal
          open={open}
          onClose={onClose}
          setHasError={setHasError}
          setErrorMessage={setErrorMessage}
          setIsSuccess={setIsSuccess}
          setSuccessMessage={setSuccessMessage}
        />
      )}
    </>
  );
}

import { Stack, TextField, Typography } from '@material-ui/core';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box } from '@material-ui/system';
import { fetchProgramReports, getHeaders } from '../../../apis/urls';
import ErrorNotifier from '../../ToastNotifications/ErrorNotifier';
import ProgramsListAutocompleteDropdown from '../../../pages/ProgramsListAutocompleteDropdown';
import { capitalize } from '../../utils/common';

export default function ProgramReports() {
  const [filters, setFilters] = useState({
    programId: "",
    from: "",
    to: ""
  });
  const [reportsData, setReportsData] = useState({});
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const { programId="", from="", to="" } = filters;
    if (programId !== "" && from !== "" && to !== "") {
      getReportsData();
    }
    return () => {};
  }, [filters]);

  function getReportsData() {
    const { programId="", from="", to="" } = filters;
    axios.get(fetchProgramReports(programId, from, to), {
      headers: getHeaders()
    })
    .then((res) => {
      setReportsData(res.data);
    })
    .catch(err => {
      console.log(err);
      setHasError(true);
      setErrorMessage(err?.response?.data?.message || "Couldn't fetch the reports");
    })
  }
  
  return (
    <Stack spacing={5} m={4}>
      {hasError && <ErrorNotifier {...{ message: errorMessage, setHasError }} /> }
      <Stack direction="row" spacing={2} display="flex" justifyContent="flex-end">
        <ProgramsListAutocompleteDropdown 
          value={filters.programId}
          onChangeHandler={(e, v) => setFilters({...filters, programId: v.value})}
          size="small"
        />
        <TextField 
          type='date' 
          label="from"
          size="small" 
          InputLabelProps={{ shrink: true }}
          onChange={(e) => setFilters({...filters, from: e.target.value})}
          value={filters.from}
        />
        <TextField
          type='date' 
          label="to"
          size="small" 
          InputLabelProps={{ shrink: true }}
          onChange={(e) => setFilters({...filters, to: e.target.value})}
          value={filters.to}
        />
      </Stack>

      {Object.keys(reportsData).length > 0 && Object.keys(reportsData?.summary ?? {}).length > 0 ? (
          <ProgramSummary 
            reportsData={reportsData.summary}
          />
        ) : (
          <Typography variant="body1" textAlign="center">
            No summary found.
          </Typography>
        )
      }

      {Object.keys(reportsData).length > 0 && (reportsData?.learners ?? []).length > 0 ? (
          <LearnerReports 
            reportsData={reportsData.learners}
          />
        ) : (
          <Typography variant="body1" textAlign="center">
            No learners data found.
          </Typography>
        )
      }
    </Stack>
  );
}

function ProgramSummary({ reportsData }) {

  function TableRow({ rowKey, rowVal }) {
    return (
      <Box height={30} width={500} sx={{border: "solid 1px black", display: "flex", justifyContent: "space-around"}}>
        <Typography variant="body1" component="span">
          {capitalize(rowKey)}
        </Typography>
        <Typography variant="body1" component="span">
          {rowVal}
        </Typography>
      </Box>
    );
  }
  
  function Table({ data }) {
    return (
      <Stack spacing={0} width="inherit">
        {Object.keys(data).length > 0 &&
          Object.keys(data).map(datum => {
            return (
              <TableRow rowKey={datum} rowVal={data[datum]}/>
            );
          })
        }
      </Stack>
    );
  }

  return (
    <Stack spacing={2}>
      <Typography variant="h6" component="h6">
        Summary
      </Typography>
      <Table 
        data={reportsData} 
      />
    </Stack>
  );
}

function LearnerReports({ reportsData }) {
  const [perPage, setPerPage] = useState(30);

  const columns = [
    {
      field: "id",
      headerName: "Id",
      type: "number",
      width: 90,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => params.row.id
    },
    {
      field: "name",
      headerName: "Name",
      width: 350,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => `${params.row.name}`
    },
    {
      field: "attendance",
      headerName: "Attendance",
      type: "number",
      width: 250,
      align: "center",
      headerAlign: "center",
      valueFormatter: ({ value }) => `${(value * 100).toFixed(2)} %`,
    },
    {
      field: "assignment",
      headerName: "Assignment submission",
      width: 250,
      type: "number",
      align: "center",
      headerAlign: "center",
      valueFormatter: ({ value }) => `${(value * 100).toFixed(2)} %`,
    }
  ];

  return (
    <Stack spacing={2}>
      <Typography variant="h6" component="h6">
        Learners
      </Typography>
      <DataGrid
        rows={reportsData}
        columns={columns}
        pageSize={perPage}
        rowsPerPageOptions={[30, 50, 100]} // 100 is the max page size in non-pro version
        onPageSizeChange={(page) => setPerPage(page)}
        pagination
        autoHeight
        disableSelectionOnClick
        getRowHeight={() => 'auto'}
        sx={{
          '& .MuiDataGrid-row:hover': {
            backgroundColor: "#e3f2fd"
          },
          '& .MuiDataGrid-cell': {
            paddingTop: 0.8,
            paddingBottom: 0.8
          }
        }}
      />
    </Stack>
  );
}
// @ts-nocheck
import React from 'react';
import { Typography, Button, Box } from '@mui/material';
import { useFastrackPipeline } from 'src/layouts/EfPipelineContext/FastrackPipelineContex';
import Snail from '../../../../assets/snail.png';

const NegativeHike = ({ saveFlowData }) => {
  const { setScreenSource, trackButtonClick } = useFastrackPipeline();

  const handleContinue = () => {
    trackButtonClick({ source: 'screen_3k', eventName: 'continue' });
    saveFlowData({ screen: 'screen_3k' });
    setScreenSource('screen_3l');
  };

  return (
    <Box
      sx={{
        textAlign: 'center',
        padding: { xs: '1rem', sm: '1.5rem', md: '3rem', lg: '4rem' },
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        maxWidth: '1200px',
        margin: '0 auto',
      }}
    >
      <Typography
        variant='h4'
        sx={{
          fontWeight: 'bold',
          color: '#0078F0',
          marginBottom: { xs: '0.8rem', md: '1.5rem' },
          fontSize: { xs: '1.4rem', sm: '1.8rem', md: '2.5rem', lg: '3rem' },
          lineHeight: { xs: '1.2', sm: '1.3', md: '1.4', lg: '1.5' },
        }}
      >
        Your future self just gave you a negative hike.
      </Typography>

      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        mb={{ xs: 1, md: 2 }}
      >
        <img
          src={Snail}
          alt='Snail icon'
          style={{
            width: '4rem',
            height: '3.5rem',
            marginBottom: { xs: '1rem', md: '1.5rem' },
          }}
        />
      </Box>

      <Typography
        variant='body1'
        sx={{
          marginBottom: { xs: '1.2rem', sm: '1.5rem', md: '2rem' },
          fontSize: { xs: '0.85rem', sm: '1rem', md: '1.2rem', lg: '1.3rem' },
          color: 'black',
        }}
      >
        With less than 2 hours a day for learning, you're moving at a snail's
        pace.
        <br />
        At this rate, that promotion might feel as distant as your next
        vacation…
      </Typography>

      <Button
        variant='outlined'
        sx={{
          marginTop: { xs: '1.2rem', md: '1.8rem', lg: '2.2rem' },
          borderRadius: '60%',
          padding: 0,
          paddingBottom: { xs: '5px', sm: '5px' },
          height: { xs: '3.5rem', sm: '3.5rem', md: '4rem', lg: '4.5rem' },
          width: { xs: '3.5rem', sm: '3.5rem', md: '4rem', lg: '4.5rem' },
          fontSize: { xs: '1.5rem', sm: '1.6rem', md: '1.7rem' },
          lineHeight: 0.5,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onClick={handleContinue}
      >
        →
      </Button>
    </Box>
  );
};

export default NegativeHike;

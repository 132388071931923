// @ts-nocheck
import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import axios from 'axios';
import { sendEmailLearnerUrl, getHeaders } from '../../apis/urls';

export default function NotifyByEmailConfirmation({
  message,
  shouldNotifyByEmail,
  setShouldNotifyByEmail,
  setNotifyByEmailStatus,
}) {
  const handleSendEmail = () => {
    setShouldNotifyByEmail(false);
    setNotifyByEmailStatus('Sending email...');
    const body = {
      message_id: message.id,
    };
    axios
      .post(sendEmailLearnerUrl(message.id), body, {
        headers: getHeaders(),
      })
      .then(() => {
        setNotifyByEmailStatus('Sent');
        setTimeout(() => {
          setNotifyByEmailStatus('');
        }, 1000);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <Dialog
      open={shouldNotifyByEmail}
      onClose={() => setShouldNotifyByEmail(false)}
    >
      <DialogTitle>Notify student by email</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to notify by email?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setShouldNotifyByEmail(false)}>Cancel</Button>
        <Button
          onClick={handleSendEmail}
          autoFocus
        >
          Yes, I am sure
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import { Button, Card, CardContent, Chip, FormControl, InputLabel, MenuItem, Modal, Paper, Select, Stack, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Box } from '@material-ui/system';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { fetchAllLevelsURL, getHeaders, postLevelURL } from '../../../apis/urls';
import ErrorNotifier from '../../ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../ToastNotifications/SuccessNotifier';
import { style } from '../Programs/Programs';

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const initialState = {
  name: "",
  objecive: "",
  definition: "",
  topics_covered: []
}

const cardStyles = makeStyles({
	card: {
		transition: 'all 0.5s ease-in-out',
		'&:hover': {
			transition: 'all 0.3s ease-in-out',
			transform: 'scale(1.01)',
		}
	}
});

export default function Levels() {
  const [allLevels, setAllLevels] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [openAddLevelModal, setOpenAddLevelModal] = useState(false);
  const classes = cardStyles();

  useEffect(() => {
    fetchLevels();
    return () => {};
  }, [isSuccess]);

  function fetchLevels() {
    axios.get(fetchAllLevelsURL(), {
      headers: getHeaders()
    })
    .then((res) => {
      setAllLevels(res?.data);
    })
    .catch((err) => {
      console.log(err);
      setHasError(true);
      setErrorMessage(err.response?.data?.message || "Could not fetch levels.")
    })
  }

  const handleAddLevelClick = (e) => {
    setOpenAddLevelModal(true);
  }

  return (
    <Box>
      {hasError && <ErrorNotifier message={errorMessage} setHasError={setHasError} /> }
      {isSuccess && <SuccessNotifier message={successMessage} setIsSuccess={setIsSuccess} /> }
      <Box display="flex" justifyContent="flex-end" sx={{paddingInlineEnd: "2rem"}}>
        <Button variant="contained" onClick={handleAddLevelClick}>
          Add Level
        </Button>
      </Box>

      {
        allLevels?.map((level, index) => (
          <Card 
            sx={{ margin: 2, minWidth: 200, minHeight: 200 }} 
            key={level?.id} 
            className={classes?.card}  
          >
            <CardContent>
              <Stack>
                <Typography component="div" variant="h5">
                  {level?.name}
                </Typography>
                            
                <Typography variant="body1" component="div" sx={{mt: 1}}>
                  <strong>Objective</strong>
                  <br />
                  <TextField
                    multiline
                    defaultValue={level?.objective}
                    style={{width: "100%", paddingLeft: 10, margin: 2}}
                    InputProps={{ readOnly: true, disableUnderline: true }}
                    variant="standard"
                  />
                </Typography>

                <Typography variant="body1" component="div" sx={{mt: 1}}>
                  <strong>Definition</strong>
                  <br />
                  <TextField
                    multiline
                    defaultValue={level?.definition}
                    style={{width: "100%", paddingLeft: 10, margin: 2}}
                    InputProps={{ readOnly: true, disableUnderline: true }}
                    variant="standard"
                  />
                </Typography>

                <Typography variant="body1" component="div" sx={{mt: 1}}>
                  <strong>Topics covered</strong>
                  <Stack direction="row" spacing={1} sx={{m: 1}}>
                    {level?.topics_covered.split(",").map((topic, index) => (
                      <Chip label={topic} />
                    ))}
                  </Stack>
                </Typography>
              </Stack>
            </CardContent>
          </Card>
        ))
      }

      {allLevels?.length === 0 &&
        <Typography align="center" variant="h6" sx={{mt: 2}}>
          No levels found
        </Typography>
      }

      {openAddLevelModal && (
        <AddLevel 
          setErrorMessage={setErrorMessage}
          setHasError={setHasError}
          setIsSuccess={setIsSuccess}
          setSuccessMessage={setSuccessMessage}
          openAddLevelModal={openAddLevelModal}
          setOpenAddLevelModal={setOpenAddLevelModal}
        />
      )}
    </Box>
  );
}

function AddLevel({
  setErrorMessage,
  setHasError,
  setIsSuccess,
  setSuccessMessage,
  openAddLevelModal,
  setOpenAddLevelModal
}) {
  const [newLevelDetails, setNewLevelDetails] = useState(initialState);
  const [selectedTopic, setSelectedTopic] = useState("");
  const [selectedTopicsList, setSelectedTopicsList] = useState([]);
  const allTopics = useSelector(state => state.topics.topics);

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post(postLevelURL(), {
      ...newLevelDetails,
      topics_covered: selectedTopicsList.join(",")
    }, {
      headers: getHeaders()
    })
    .then((res) => {
      setNewLevelDetails(initialState);
      setOpenAddLevelModal(false);
      setIsSuccess(true);
      setSuccessMessage("Level successfully created.");
    })
    .catch((err) => {
      console.log(err);
      setHasError(true);
      setErrorMessage(err.respose?.data?.message || "Could not create the level.")
    })
  }

  const handleChange = (e) => {
    setNewLevelDetails({...newLevelDetails, [e.target.name]: e.target.value});
  }

  const handleTopicSelect = (e) => {
    setSelectedTopic(e.target.value);
    if (selectedTopicsList.indexOf(e.target.value) === -1) {
      setSelectedTopicsList([...selectedTopicsList, e.target.value]);
    }
  }

  const handleDelete = (name) => {
    setSelectedTopicsList(selectedTopicsList.filter((topic, idx) => topic !== name));
  }

  return (
    <>
      <Modal
        open={openAddLevelModal}
        onClose={() => setOpenAddLevelModal(false)}
        style={{ overflow: 'scroll' }}
      >
        <form onSubmit={handleSubmit}>
          <Box sx={style}>
            <Stack spacing={3}>
              <TextField 
                fullWidth
                id="name"
                label="Name"
                name="name"
                variant="outlined"
                value={newLevelDetails?.name}
                onChange={handleChange}
                required
              />
              <TextField
                multiline
                fullWidth
                id="objective"
                label="Objective"
                name="objective"
                variant="outlined"
                value={newLevelDetails?.objective}
                onChange={handleChange}
                minRows={5}
                maxRows={10}
                required
              />
              <TextField 
                multiline
                fullWidth
                id="definition"
                label="Definition"
                name="definition"
                variant="outlined"
                value={newLevelDetails?.definition}
                onChange={handleChange}
                minRows={5}
                maxRows={10}
                required
              />
              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="topics">Topics List</InputLabel>
                <Select
                  labelId="topics"
                  id="topics"
                  value={selectedTopic}
                  label="Topics List"
                  onChange={handleTopicSelect}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {
                    allTopics?.map((topic, index) => (
                      <MenuItem 
                        key={topic?.id} 
                        id={topic?.id} 
                        value={topic?.name}
                      >
                        {topic?.name}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
              <ChipArray 
                selectedTopicsList={selectedTopicsList}
                handleDelete={handleDelete}
              />
              <Button
                size="large"
                type="submit"
                variant="contained"
                color="secondary"
              >
                Add
              </Button>
            </Stack>
          </Box>
        </form>
      </Modal>
    </>
  )
}

function ChipArray({ selectedTopicsList, handleDelete }) {
  return (
    <Paper
      sx={{
        display: 'flex',
        listStyle: 'none',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
      }}
      component="ul"
    >
      {selectedTopicsList.map((topic, index) => (
        <ListItem key={index + 1}>
          <Chip 
            label={topic} 
            onDelete={(e) => handleDelete(topic)} 
          />
        </ListItem>
      ))}
    </Paper>
  );
}
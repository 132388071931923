import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getHeaders, updateAssignee } from 'src/apis/urls';

const AssigneeDropdown = ({ value, ticketId, options, onAssigneeChange }) => {
  const [localValue, setLocalValue] = useState('');

  useEffect(() => {
    // Find the current assignee's ID based on the name (value)
    const currentAssigneeId =
      options.find((option) => option.name === value)?.id || '';
    setLocalValue(currentAssigneeId);
  }, [value, options]);

  const handleAssigneeChange = async (event) => {
    const newAssigneeId = event.target.value;
    try {
      await axios.patch(
        updateAssignee(ticketId),
        { assignee_id: newAssigneeId },
        { headers: getHeaders() }
      );
      setLocalValue(newAssigneeId);
      // Call the callback function to update the parent component
      const newAssigneeName = options.find(
        (option) => option.id === newAssigneeId
      )?.name;
      onAssigneeChange(ticketId, newAssigneeName);
    } catch (error) {
      console.error('Failed to update assignee:', error);
    }
  };

  return (
    <select
      value={localValue}
      onChange={handleAssigneeChange}
    >
      <option
        value=''
        disabled
      >
        Select an assignee
      </option>
      {options.map(({ id, name }) => (
        <option
          key={id}
          value={id}
        >
          {name}
        </option>
      ))}
    </select>
  );
};

export default AssigneeDropdown;

// @ts-nocheck
import React, { useEffect, useRef, useState } from 'react';
import { Typography } from '@mui/material';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { getUserId, getUserType } from '../../configs/auth';
import {
  getChatMessages,
  getHeaders,
  markMessagesAsRead,
} from '../../apis/urls';
import ChatHeader from './ChatHeader';
import { SendNewMessage } from './SendNewMessage';
import consumer from '../../cable';
import MessageLayout from './MessageLayout';

const styles = {
  individualChatContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundRepeat: 'repeat',
    backgroundSize: 'cover',
    borderRadius: '20px',
  },
  chatMessageDisplay: {
    width: '100%',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column-reverse',
    overflow: 'auto',
    padding: '16px',
  },
  userMessage: {
    alignSelf: 'flex-end',
  },
};

const IndividualChat = ({ chat, searchType }) => {
  const [audio] = useState(new Audio('/static/notification.mp3'));
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [updateMessageStatus, setUpdateMessageStatus] = useState('');
  const [deleteMessageStatus, setDeleteMessageStatus] = useState('');
  const chatDisplayRef = useRef(null);
  const pageNumber = useRef(1);
  const PAGE_SIZE = 25;
  const [hasMoreMessages, setHasMoreMessages] = useState(false);

  useEffect(() => {
    const channel = consumer.subscriptions.create(
      {
        channel: 'ChatChannel',
        chat_id: chat.id,
        user_id: getUserId(),
      },
      {
        connected: () => console.log(`Subscribed to ChatChannel => ${chat.id}`),
        disconnected: () =>
          console.log(`Unsubscribed to ChatChannel => ${chat.id}`),
        received: (data) => {
          console.log('Update received in ChatChannel', data);

          const { type: eventType } = data;
          if (eventType === 'new_message') {
            handleNewMessageEventOnChat(data);
          } else if (eventType === 'edit_message') {
            handleEditMessageEventOnChat(data);
          } else if (eventType === 'delete_message') {
            handleDeleteMessageEventOnChat(data);
          } else if (eventType === 'read_receipts') {
            handleReadReceiptsEventOnChat(data);
          } else if (eventType === 'new_reaction') {
            handleNewReactionEventOnChat(data);
          } else if (eventType === 'edit_reaction') {
            handleEditReactionEventOnChat(data);
          } else if (eventType === 'delete_reaction') {
            handleDeleteReactionEventOnChat(data);
          }
        },
      }
    );

    return () => {
      setMessages([]);
      setHasMoreMessages(false);
      setDeleteMessageStatus('');
      setUpdateMessageStatus('');
      chatDisplayRef.current = null;
      pageNumber.current = 1;
      channel.unsubscribe();
    };
  }, [chat]);

  useEffect(() => {
    if (chat.id) {
      fetchNextPage();
    }
  }, [chat]);

  useEffect(() => {
    const unreadMessageIds = messages
      .filter((msg) => !msg.is_read && msg.sent_by_id !== getUserId())
      .map((msg) => msg.id);

    if (unreadMessageIds.length === 0) {
      return;
    }

    updateReadReceipts(unreadMessageIds);
    return () => {};
  }, [messages]);

  const fetchNextPage = () => {
    setIsLoading(true);
    axios
      .get(getChatMessages(chat.id), {
        params: {
          page: pageNumber.current,
          per_page: PAGE_SIZE,
        },
        headers: getHeaders(),
      })
      .then((res) => {
        const { messages: newMessages, total } = res?.data;
        setHasMoreMessages(newMessages.length + messages.length < total);

        if (newMessages.length > 0) {
          setMessages((prevMessages) => [...newMessages, ...prevMessages]);
          pageNumber.current += 1;
        }
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  };

  const updateReadReceipts = (unreadMessageIds) => {
    const body = {
      message_ids: unreadMessageIds,
      chat_id: chat.id,
    };

    axios
      .post(markMessagesAsRead(), body, { headers: getHeaders() })
      .then(() => {})
      .catch((e) => {
        console.log(e);
      });
  };

  const handleReadReceiptsEventOnChat = (response) => {
    if (getUserType() === 'student') {
      return;
    }

    let { read_message_ids: readMessageIds } = response;
    readMessageIds = new Set(readMessageIds);

    setMessages((prev) => {
      return prev.map((m) => {
        if (!readMessageIds.has(m.id)) {
          return m;
        }

        return {
          ...m,
          read_by_other_user: true,
        };
      });
    });
  };

  const handleNewMessageEventOnChat = (response) => {
    const { message: newMessage } = response;

    setMessages((prev) => {
      return [...prev, newMessage];
    });

    const { from_self, is_deleted, is_edited } = newMessage;
    if (!from_self && !is_deleted && !is_edited) {
      audio.play();
    }
  };

  const handleEditMessageEventOnChat = (response) => {
    const { message: editedMessage } = response;

    setMessages((prev) => {
      const messageIndex = prev.findIndex((m) => m.id === editedMessage.id);
      if (messageIndex === -1) {
        return prev;
      }

      const updatedMessage = {
        ...prev[messageIndex],
        ...editedMessage,
      };

      return [
        ...prev.slice(0, messageIndex),
        updatedMessage,
        ...prev.slice(messageIndex + 1),
      ];
    });
  };

  const handleDeleteMessageEventOnChat = (response) => {
    const { message: deletedMessage } = response;

    setMessages((prev) => {
      const messageIndex = prev.findIndex((m) => m.id === deletedMessage.id);
      if (messageIndex === -1) {
        return prev;
      }

      const updatedMessage = {
        ...prev[messageIndex],
        ...deletedMessage,
        chat_message_reactions: [],
      };

      return [
        ...prev.slice(0, messageIndex),
        updatedMessage,
        ...prev.slice(messageIndex + 1),
      ];
    });
  };

  const handleNewReactionEventOnChat = (response) => {
    const { body: reaction } = response;
    setMessages((prev) => {
      const messageIndex = prev.findIndex(
        (m) => m.id === reaction.chat_message_id
      );
      if (messageIndex === -1) {
        return prev;
      }

      const updatedMessage = {
        ...prev[messageIndex],
      };

      updatedMessage.chat_message_reactions.push(reaction);

      return [
        ...prev.slice(0, messageIndex),
        updatedMessage,
        ...prev.slice(messageIndex + 1),
      ];
    });
  };

  const handleEditReactionEventOnChat = (response) => {
    const { body: reaction } = response;
    setMessages((prev) => {
      const messageIndex = prev.findIndex(
        (m) => m.id === reaction.chat_message_id
      );
      if (messageIndex === -1) {
        return prev;
      }

      const reactionIndex = prev[messageIndex].chat_message_reactions.findIndex(
        (r) => r.id === reaction.id
      );
      if (reactionIndex === -1) {
        return prev;
      }

      const updatedMessage = {
        ...prev[messageIndex],
      };

      updatedMessage.chat_message_reactions[reactionIndex] = reaction;

      return [
        ...prev.slice(0, messageIndex),
        updatedMessage,
        ...prev.slice(messageIndex + 1),
      ];
    });
  };

  const handleDeleteReactionEventOnChat = (response) => {
    const { body: reaction } = response;
    setMessages((prev) => {
      const messageIndex = prev.findIndex(
        (m) => m.id === reaction.chat_message_id
      );
      if (messageIndex === -1) {
        return prev;
      }

      const reactionIndex = prev[messageIndex].chat_message_reactions.findIndex(
        (r) => r.id === reaction.id
      );
      if (reactionIndex === -1) {
        return prev;
      }

      const updatedMessage = {
        ...prev[messageIndex],
      };

      updatedMessage.chat_message_reactions.splice(reactionIndex, 1);

      return [
        ...prev.slice(0, messageIndex),
        updatedMessage,
        ...prev.slice(messageIndex + 1),
      ];
    });
  };

  return (
    <div style={styles.individualChatContainer}>
      <ChatHeader chat={chat} />
      {(isLoading || messages?.length === 0) && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Typography>
              Hey, send the first message to start chatting
            </Typography>
          )}
        </Box>
      )}
      <div style={styles.chatMessageDisplay}>
        <div style={styles.chatMessages}>
          <MessageLayout
            hasMoreMessages={hasMoreMessages}
            fetchNextPage={fetchNextPage}
            messages={messages}
            setUpdateMessageStatus={setUpdateMessageStatus}
            setDeleteMessageStatus={setDeleteMessageStatus}
            searchType={searchType}
            chat={chat}
          />
        </div>

        <style>
          {`
          ::-webkit-scrollbar {
            width: 8px;
            scrollbar-width: thin;
          }
          
          ::-webkit-scrollbar-track {
            border-radius: 6px;
          }
          
          ::-webkit-scrollbar-thumb {
            background: rgba(0, 0, 0, 0.4);
            border-radius: 6px;
          }
          
          ::-webkit-scrollbar-thumb:hover {
            background: rgba(0, 0, 0, 0.6);
          }
          scrollbar-width: thin;
        `}
        </style>
      </div>

      <SendNewMessage
        chat={chat}
        updateMessageStatus={updateMessageStatus}
        deleteMessageStatus={deleteMessageStatus}
      />
    </div>
  );
};

export default IndividualChat;

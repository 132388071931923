import { Typography, Button, Stack } from '@material-ui/core';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Box } from '@material-ui/system';
import {
  deleteAttendanceURL,
  fetchProgramSessionsUrl,
  getHeaders,
  postAttendanceURL,
  updateAttendanceURL,
} from '../../../../apis/urls';
import ErrorNotifier from '../../../ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../../ToastNotifications/SuccessNotifier';
import MarkAttendanceModal from './MarkAttendanceModal';
import AttendanceReportDisplay from './AttendanceReportDisplay';
import LoadingScreen from '../../../../pages/LoadingScreen';
import {
  markAttendanceInState,
  setSessions,
} from 'src/components/redux-store/slices/attendanceSlice';

function sortSessionsByStartTime(sessArray) {
  function cmp(d1, d2) {
    if (moment(d1[0]).isBefore(moment(d2[0]))) {
      return -1;
    }
    return 1;
  }
  return sessArray.sort(cmp);
}

export default function AttendanceReport({ allLearners }) {
  // Note: session times will be returned in IST time by rails. When formating the dates use moment(startTime).utc() because,
  // using just moment(startTime) will add +05:30 to the startTime which is already in IST.
  const allSessions = useSelector((state) => state?.attendance?.sessions);
  const currentProgram = useSelector((state) => state.programs.currentProgram);
  const [learners, setLearners] = useState({});
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [countOfSessionsConducted, setCountOfSessionsCounducted] = useState(0);
  const [openAttendanceModal, setOpenAttendanceModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  /**
   * 'sessionsList' is needed because 'allSessions' is an object. JS objects by-default sorts everything based
   * on keys, but we want our sessions to be sorted based on their start time. The only way we can achieve this
   * is by having 'sessionsList', which stores arrays of [startTime, sessionId]. Now we can sort 'sessionsList'
   * based on 'startTime' and iterate over it. 'sessionId' is being stored so that we can get O(1) access to
   * our desired session.
   */
  const [sessionsList, setSessionsList] = useState([]); // stores [[startTime, sessionId], ....]

  useEffect(() => {
    fetchSessions();
  }, [allLearners, currentProgram]);

  function fetchSessions() {
    setLoading(true);
    axios
      .get(fetchProgramSessionsUrl(), {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((res) => {
        const tmpSessions = {};
        const tmpSessionsList = [];
        const tmpLearners = { ...allLearners };
        let conductedSessionsCount = 0;

        Object.keys(tmpLearners).forEach((learner, index) => {
          tmpLearners[learner].discipline = 0;
        });

        res.data.forEach((session, index) => {
          tmpSessions[session?.id] = {
            title: session?.title,
            startTime: session?.start_time,
            attendances: {},
          };

          // count of sessions that have been conducted so far.
          conductedSessionsCount +=
            session?.status !== 'created' && session?.status !== 'scheduled'
              ? 1
              : 0;

          session?.attendances?.forEach((attendance, index) => {
            const userId = attendance?.user_id;
            tmpSessions[session?.id].attendances[userId] = {
              id: attendance?.id,
              status: attendance?.status,
            };
            if (userId in tmpLearners) {
              // count of sessions that a learner actually joined.
              tmpLearners[userId].discipline +=
                attendance?.status === 'joined' ? 1 : 0;
            }
          });
          tmpSessionsList.push([session?.start_time, session?.id]);
        });
        setSessionsList(sortSessionsByStartTime(tmpSessionsList));
        dispatch(setSessions(tmpSessions));
        setLearners(tmpLearners);
        setCountOfSessionsCounducted(conductedSessionsCount);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(err.response?.data?.message || err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const markAttendance = (sessionId, studentId, status) => {
    if (status === 'clear') {
      clearAttendance(sessionId, studentId);
      return;
    }

    const response =
      allSessions[sessionId]?.attendances[studentId] === undefined
        ? axios.post(
            postAttendanceURL(sessionId),
            {
              user_id: studentId,
              status,
            },
            {
              headers: getHeaders(currentProgram?.programId),
            }
          )
        : axios.patch(
            updateAttendanceURL(
              sessionId,
              allSessions[sessionId].attendances[studentId]?.id
            ),
            {
              status,
            },
            {
              headers: getHeaders(currentProgram?.programId),
            }
          );

    response
      .then((res) => {
        dispatch(
          markAttendanceInState({
            sessionId,
            studentId,
            status,
            data: res.data,
          })
        );
        setIsSuccess(true);
        setSuccessMessage('Attendance successfully marked.');
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(err.response?.data?.message || err.message);
      });
  };

  const clearAttendance = (sessionId, studentId) => {
    const attendanceId = allSessions[sessionId]?.attendances[studentId]?.id;
    axios
      .delete(deleteAttendanceURL(attendanceId), {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((res) => {
        setIsSuccess(true);
        setSuccessMessage('Attendance successfully deleted.');
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(err?.response?.data?.message || err?.message);
      });
  };

  const handleModalClose = () => {
    setOpenAttendanceModal(false);
    fetchSessions();
  };

  return (
    <Stack
      m={2}
      spacing={2}
    >
      {loading && <LoadingScreen loading={loading} />}
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      {isSuccess && (
        <SuccessNotifier
          message={successMessage}
          setIsSuccess={setIsSuccess}
        />
      )}

      <Box
        display='flex'
        justifyContent='flex-end'
      >
        <Button
          variant='contained'
          onClick={fetchSessions}
          sx={{
            display: !currentProgram && 'none',
            marginRight: '10px',
          }}
        >
          Refresh Table
        </Button>
        <Button
          variant='contained'
          onClick={() => setOpenAttendanceModal(true)}
          sx={{
            display: !currentProgram && 'none',
          }}
        >
          Upload attendance
        </Button>
      </Box>

      <Box>
        {Object.keys(learners)?.length > 0 &&
          Object.keys(allSessions)?.length > 0 && (
            <AttendanceReportDisplay
              learners={learners}
              sessions={allSessions}
              markAttendance={markAttendance}
              sessionsList={sessionsList}
              totalConductedSessions={countOfSessionsConducted}
            />
          )}
      </Box>

      {(Object.keys(learners)?.length === 0 ||
        Object.keys(allSessions)?.length === 0) && (
        <Typography
          variant='body1'
          align='center'
          sx={{ mt: 2 }}
        >
          No attendance records found
        </Typography>
      )}
      {openAttendanceModal && (
        <MarkAttendanceModal
          {...{
            openAttendanceModal,
            handleModalClose,
            allSessions,
            setIsSuccess,
            setSuccessMessage,
          }}
        />
      )}
    </Stack>
  );
}

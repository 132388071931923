import { PDFDocument, rgb, degrees, StandardFonts } from 'pdf-lib';
import moment from 'moment';

async function generatePDF(leadFName, leadLName, resp) {
  const pdfDoc = await PDFDocument.create();
  const page = pdfDoc.addPage([595, 842]);

  const fontSize = 10;

  const imageBytes = await fetch('/static/heycoach-logo.png').then((res) =>
    res.arrayBuffer()
  );
  const imageBytes2 = await fetch('/static/kamat-sign.png').then((res) =>
    res.arrayBuffer()
  );

  const image = await pdfDoc.embedPng(imageBytes);
  const image2 = await pdfDoc.embedPng(imageBytes2);

  page.drawImage(image, {
    x: (page.getWidth() - 450) / 2,
    y: (page.getHeight() - 150) / 2,
    width: 450,
    height: 95,
    opacity: 0.1,
    rotate: degrees(0),
  });

  page.drawText(moment().format('Do MMMM YYYY'), {
    x: 450,
    y: 740,
    size: 12,
    color: rgb(0, 0, 0),
    maxWidth: 150,
  });

  page.drawText(`Preparing engineers for the top tech companies!`, {
    x: 50,
    y: 322,
    opacity: 0.1,
    rotate: degrees(0),
  });

  page.drawImage(image, {
    x: 50,
    y: 730,
    width: 140,
    height: 30,
  });

  page.drawText(`Congratulations on receiving your offer letter`, {
    x: 100,
    y: 650,
    size: 20,
    color: rgb(0, 0, 0),
    maxWidth: 495,
  });

  page.drawRectangle({
    x: 30,
    y: 630,
    width: 535,
    height: 0,
    borderWidth: 4,
    borderColor: rgb(
      0.09803921568627451,
      0.37254901960784315,
      0.9607843137254902
    ),
  });

  page.drawText(`Hi ${leadFName || ''},`, {
    x: 50,
    y: 580,
    size: fontSize + 2,
    color: rgb(0, 0, 0),
    maxWidth: 495,
  });

  page.drawText(
    `We extend our warmest congratulations to you for your selection for ${
      resp.program || ''
    } program! Your dedication and hard work have paid off, and we believe you'll be an invaluable addition to our program.`,
    {
      x: 50,
      y: 540,
      size: fontSize + 2,
      color: rgb(0, 0, 0),
      maxWidth: 495,
    }
  );

  page.drawText(
    `For onboarding, we kindly request a token payment of ${
      resp.token_amount || ''
    } rupees to block your seat. Once you've paid the token amount, we'll initiate the onboarding process upon receipt.`,
    {
      x: 50,
      y: 440,
      size: fontSize + 2,
      color: rgb(0, 0, 0),
      maxWidth: 495,
    }
  );

  page.drawText(
    `We eagerly anticipate your arrival. If you have questions or need assistance, please reach out to us on `,
    {
      x: 50,
      y: 360,
      size: fontSize + 2,
      color: rgb(0, 0, 0),
      maxWidth: 495,
    }
  );

  const emailText = 'team@heycoach.in';
  page.drawText(`${emailText}`, {
    x: 115,
    y: 335,
    size: fontSize + 2,
    color: rgb(0, 0, 1),
    maxWidth: 495,
  });

  page.drawText(
    `. This is an exciting journey, and we're thrilled to have you`,
    {
      x: 220,
      y: 335,
      size: fontSize + 2,
      color: rgb(0, 0, 0),
      maxWidth: 495,
    }
  );

  page.drawText(
    `on board. Once again, congratulations on your achievement, and welcome to ${
      resp.program || ''
    } program.`,
    {
      x: 50,
      y: 315,
      size: fontSize + 2,
      color: rgb(0, 0, 0),
      maxWidth: 495,
    }
  );

  page.drawText(`Best regards,`, {
    x: 50,
    y: 225,
    size: fontSize + 2,
    color: rgb(0, 0, 0),
    maxWidth: 495,
  });
  page.drawImage(image2, {
    x: 40,
    y: 170,
    width: 110,
    height: 40,
  });
  page.drawText(`Aditya Kamat,`, {
    x: 50,
    y: 160,
    size: fontSize + 2,
    color: rgb(0, 0, 0),
    maxWidth: 495,
  });
  page.drawText(`Co-Founder, HeyCoach.`, {
    x: 50,
    y: 140,
    size: fontSize + 2,
    color: rgb(0, 0, 0),
    maxWidth: 495,
  });

  page.drawText(`HeyCoach Corporate Office:`, {
    x: 250,
    y: 50,
    size: fontSize - 2,
    color: rgb(0, 0, 0),
    maxWidth: 495,
  });

  page.drawText(`9353912654`, {
    x: 280,
    y: 40,
    size: fontSize - 3,
    color: rgb(0, 0, 0),
    maxWidth: 495,
  });

  page.drawText(
    `UrbanVault 72, Service Rd, LRDE Layout, Doddanekundi, Doddanekkundi, Bengaluru, Karnataka 560037`,
    {
      x: 130,
      y: 30,
      size: fontSize - 3,
      color: rgb(0, 0, 0),
      maxWidth: 495,
    }
  );

  const pdfBytes = await pdfDoc.save();

  return pdfBytes;
}

export default generatePDF;

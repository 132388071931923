import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { fetchAllLevelsURL, getHeaders } from '../../../apis/urls';
import ErrorNotifier from '../../ToastNotifications/ErrorNotifier';

export default function LevelDropdown({ levelIdFilter, setLevelIdFilter, sx }) {
  const [levels, setLevels] = useState({});
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const currentProgram = useSelector(state => state.programs.currentProgram);

  useEffect(() => {
    if (currentProgram) {
      axios.get(fetchAllLevelsURL(), {
        headers: getHeaders(currentProgram?.programId)
      })
      .then((res) => {
        const tmpAllLevels = {};
        res?.data.forEach((level, index) => {
          tmpAllLevels[level?.name] = level?.id
        })
        setLevels(tmpAllLevels);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(err?.response?.data?.message || "Could not fetch levels.");
      })
    }
    return () => {};
  }, [currentProgram]);

  const handleChange = (e) => {
    setLevelIdFilter(e.target.value);
  }

  return (
    <>
      {hasError && <ErrorNotifier message={errorMessage} setHasError={setHasError} /> }
      <FormControl 
        sx={sx}
      >
        <InputLabel id="level">Select level</InputLabel>
        <Select
          labelId="level"
          id="level"
          value={levelIdFilter}
          label="Select level"
          onChange={handleChange}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {Object.keys(levels)?.map((name, index) => (
            <MenuItem value={levels[name]}>{name}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}

import React from 'react';
import Avatar from '@mui/material/Avatar';
import { makeStyles } from '@material-ui/styles';
import { getUserType } from '../../configs/auth';
import { isAdmin, isProgramManager } from './ChatDashboard';

const useStyles = makeStyles((theme) => ({
  chatHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    borderBottom: '1px solid #ccc',
    backgroundColor: '#075e54',
    color: 'white',
    borderTopRightRadius: '5px',
    borderTopLeftRadius: '5px',
  },
  chatInfo: {
    marginLeft: '10px',
    display: 'flex',
    flexDirection: 'column',
  },
  chatName: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
  chatStatus: {
    fontSize: '14px',
  },
}));

const ChatHeader = ({ chat }) => {
  const classes = useStyles();

  return (
    <div className={classes.chatHeader}>
      {isAdmin || isProgramManager ? (
        <Avatar />
      ) : (
        <Avatar src='/favicon/favicon-32x32.png' />
      )}
      <div className={classes.chatInfo}>
        <div className={classes.chatName}>
          {isAdmin || isProgramManager
            ? chat.user_name
            : 'Program management team'}
        </div>
      </div>
    </div>
  );
};

export default ChatHeader;

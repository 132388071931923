import React from 'react';
import { Avatar, Backdrop, Divider, Grid, InputAdornment, Paper, TextField } from '@material-ui/core';
import SendIcon from '@mui/icons-material/Send';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment';

const timeFormat = "MMM DD, YYYY, hh:mm A";

export default function Comments({ comments, postComment, newComment, setNewComment, loading, setLoading }) {
  // TODO Lokesh: Add option to edit the comment.

  return (
    <Grid container height="55vh" sx={{overflowY: "auto", flexGrow: 1}}>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {comments.length > 0 &&
        <Paper style={{ padding: "40px 20px", width: "inherit"}}>
          {
            comments.map((comment, index) => {
              return (
                <ListComment key={index + 1} comment={comment} />
              )
            })
          }
        </Paper>
      }
      <AddComment {...{postComment, newComment, setNewComment}}/>
    </Grid>
  )
}

function AddComment({ postComment, newComment, setNewComment }) {
  return (
    <Grid item xs={12} sx={{ml: 1, mr: 1, alignSelf: "flex-end"}}>
      <TextField
        fullWidth
        multiline
        minRows={5}
        margin='normal'
        placeholder='Add comment here.'
        value={newComment}
        onChange={(e) => setNewComment(e.target.value)}
        autoFocus
        sx={{
          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "black"
          },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" sx={{display: "flex", alignItems: "flex-end"}}>
              <SendIcon fontSize='large' onClick={() => postComment()} sx={{ cursor: "pointer"}}/>
            </InputAdornment>
          ),
        }}
        variant="outlined"
      />
    </Grid>
  );
}

function ListComment({ comment }) {
  return(
    <>
      <Grid container wrap="nowrap" spacing={2}>
        <Grid item>
          <Avatar alt={comment.added_by.name} src={comment.added_by.image} />
        </Grid>
        <Grid justifyContent="left" item xs zeroMinWidth>
          <h4 style={{ margin: 0, textAlign: "left" }}>
            {comment.added_by.name} <span style={{ fontSize: "15px", color: "gray" }}>({comment.added_by.role})</span>
          </h4>
          <p style={{ textAlign: "left" }}>
            {comment.note}{" "}
          </p>
          <p style={{ textAlign: "left", color: "gray", marginBlockStart: 5, fontSize: "15px" }}>
            posted on {moment(comment.created_at).format(timeFormat)}
          </p>
        </Grid>
      </Grid>
      <Divider variant="fullWidth" style={{ margin: "30px 0" }} />
    </>
  );
}
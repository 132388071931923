import React, { useEffect, useState } from 'react';
import {
  Grid,
  Checkbox,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Box,
} from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import { useSelector } from 'react-redux';
import Timer from './Timer';
import {
  fetchAndsubmitQuizAttemptURL,
  fetchQuizDetails,
  getHeaders,
  submitQuizQuestionAttemptURL,
} from '../../../apis/urls';
import ErrorNotifier from '../../ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../ToastNotifications/SuccessNotifier';
import QuestionsSidebar from './QuestionsSidebar';
import LoadingScreen from '../../../pages/LoadingScreen';
import SubmitQuizConfirmation from './SubmitQuizConfirmation';

export default function QuizLayout({
  selectedQuizAttempt,
  setSelectedQuizAttempt,
}) {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [quiz, setQuiz] = useState();
  const [allQuestions, setAllQuestions] = useState([]);
  const [answered, setAnswered] = useState([]);
  const [open, setOpen] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const currentProgram = useSelector((state) => state.programs.currentProgram);

  useEffect(() => {
    fetchQuiz();
    handleAutoSubmit();
    return () => {};
  }, []);

  useEffect(() => {
    fetchQuizAttemptQuestion();
    return () => {};
  }, [allQuestions]);

  const fetchQuiz = () => {
    if (!selectedQuizAttempt?.id) {
      return;
    }
    setLoading(true);
    axios
      .get(fetchQuizDetails(selectedQuizAttempt?.quiz_id), {
        headers: getHeaders(currentProgram.programId),
      })
      .then((res) => {
        setQuiz(res?.data);
        setAllQuestions(res?.data?.quiz_questions);
      })
      .catch((e) => {
        console.log(e);
        setHasError(true);
        setErrorMessage(
          e.response?.data?.message ||
            'An error occurred while fetching the quiz'
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchQuizAttemptQuestion = () => {
    axios
      .get(fetchAndsubmitQuizAttemptURL(selectedQuizAttempt?.id), {
        headers: getHeaders(currentProgram.programId),
      })
      .then((res) => {
        const answeredIds = res?.data.map(
          (attemptedQuestion) => attemptedQuestion.quiz_question_id
        );
        const unattemptedQuestions = allQuestions.filter((question) => {
          return !res?.data.some(
            (attemptedQuestion) =>
              attemptedQuestion.quiz_question_id === question.id
          );
        });
        const unattemptedQuestionIndex = allQuestions.indexOf(
          unattemptedQuestions[0]
        );
        setCurrentQuestion(
          unattemptedQuestionIndex !== -1 ? unattemptedQuestionIndex : 0
        );
        setAnswered(answeredIds);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleAutoSubmit = () => {
    if (selectedQuizAttempt?.started_at) {
      const startedAt = moment(selectedQuizAttempt?.started_at);
      const closesAt = moment(selectedQuizAttempt?.endTime);
      startedAt.add(selectedQuizAttempt?.duration, 'minutes');
      const currTime = moment();
      const diffStart = startedAt.diff(currTime, 'seconds');
      const diffClose = closesAt.diff(currTime, 'seconds');
      if (diffClose <= 1 || diffStart <= 1) {
        submitQuiz();
      }
    }
  };

  const submitQuiz = () => {
    setLoading(true);
    axios
      .patch(
        fetchAndsubmitQuizAttemptURL(selectedQuizAttempt?.id),
        {
          id: selectedQuizAttempt?.id,
        },
        {
          headers: getHeaders(currentProgram.programId),
        }
      )
      .then((res) => {
        setIsSuccess(true);
        setSuccessMessage('Quiz Submitted Successfully');
        setOpen(false);
        setSelectedQuizAttempt(null);
      })
      .catch((e) => {
        console.log(e);
        setHasError(true);
        setErrorMessage(
          e.response?.data?.message ||
            'An error occurred while submitting the quiz'
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSaveAnswer = () => {
    if (!selectedOption && selectedOptions.length === 0) {
      setHasError(true);
      setErrorMessage('Please select atleast one option');
      return;
    }
    const isLastQuestion = currentQuestion === quiz?.quiz_questions.length - 1;

    const selectedOptionsToSend =
      quiz?.quiz_questions[currentQuestion]?.question_type === 'multiple'
        ? selectedOptions
        : [selectedOption];

    const body = {
      quiz_attempt_id: selectedQuizAttempt?.id,
      quiz_question_id: quiz?.quiz_questions[currentQuestion]?.id,
      quiz_question_options: selectedOptionsToSend,
    };
    axios
      .post(submitQuizQuestionAttemptURL(selectedQuizAttempt?.id), body, {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((res) => {
        if (isLastQuestion) {
          submitQuiz();
        }
        handleNextQuestion();
        setAnswered((prev) => [...prev, res?.data[0].quiz_question_id]);
      })
      .catch((e) => {
        console.log(e);
        setHasError(true);
        setErrorMessage(
          e.response?.data?.message ||
            'An error occurred while saving the answer'
        );
      });
  };

  const handleNextQuestion = () => {
    if (currentQuestion < allQuestions?.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
      setSelectedOption(null);
      setSelectedOptions([]);
    }
  };

  const handleRadioOptionChange = (e) => {
    const optionId = parseInt(e.target.value, 10);
    setSelectedOption(optionId);
  };

  const handleCheckboxOptionChange = (e) => {
    const optionId = parseInt(e.target.value, 10);
    if (selectedOptions.includes(optionId)) {
      setSelectedOptions(selectedOptions.filter((opt) => opt !== optionId));
    } else {
      setSelectedOptions([...selectedOptions, optionId]);
    }
  };

  const getQuestionType = () => {
    return (
      <Grid
        display='flex'
        border='2px solid #b5e2ff'
        sx={{ mt: 2, p: 1 }}
      >
        <Typography
          variant='body1'
          mr={4}
        >
          Question:{' '}
          <span style={{ color: '#4060f5' }}>{currentQuestion + 1}</span>
        </Typography>
        <Typography variant='body1'>
          Type:{' '}
          <span style={{ color: '#4060f5' }}>
            {allQuestions[currentQuestion]?.question_type} correct
          </span>
        </Typography>
      </Grid>
    );
  };

  const getOptions = () => {
    const questionType = allQuestions[currentQuestion]?.question_type;
    const options = allQuestions[currentQuestion]?.quiz_question_options;

    switch (questionType) {
      case 'single':
        return getSingleSelectOptions(options);
      case 'multiple':
        return getMultiSelectOptions(options);
      default:
        return null;
    }
  };

  const getSingleSelectOptions = (options) => {
    return (
      <RadioGroup
        value={selectedOption}
        onChange={handleRadioOptionChange}
      >
        {options.map((opt, idx) => (
          <FormControlLabel
            key={idx}
            value={opt.id}
            control={<Radio />}
            label={opt.option}
          />
        ))}
      </RadioGroup>
    );
  };

  const getMultiSelectOptions = (options) => {
    return (
      <FormControl>
        {options.map((opt, idx) => (
          <FormControlLabel
            key={idx}
            control={
              <Checkbox
                checked={selectedOptions.includes(opt.id)}
                onChange={handleCheckboxOptionChange}
                value={opt.id}
              />
            }
            label={opt.option}
          />
        ))}
      </FormControl>
    );
  };

  return (
    <>
      <LoadingScreen loading={loading} />
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      {isSuccess && (
        <SuccessNotifier
          message={successMessage}
          setIsSuccess={setIsSuccess}
        />
      )}
      <Box
        display='flex'
        justifyContent='space-around'
        sx={{ m: 2 }}
      >
        <Box
          width='60%'
          border='2px solid #b5e2ff'
          sx={{ p: 3 }}
        >
          <Box
            display='flex'
            justifyContent='space-between'
          >
            <Typography variant='h6'>{quiz?.title}</Typography>
            <Timer
              handleAutoSubmit={handleAutoSubmit}
              startedAt={moment(selectedQuizAttempt?.started_at)}
              duration={selectedQuizAttempt?.duration}
              closesAt={moment(selectedQuizAttempt?.endTime)}
            />
          </Box>
          {getQuestionType()}
          <Box
            display='flex'
            border='2px solid #b5e2ff'
            flexDirection='column'
            sx={{ mt: 1, p: 3 }}
          >
            <Typography variant='h6'>
              {allQuestions[currentQuestion]?.question}
            </Typography>
            <FormControl sx={{ mt: 2 }}>{getOptions()}</FormControl>
            <Box
              sx={{
                textAlign: 'right',
                mt: 2,
              }}
            >
              <Button
                sx={{
                  px: 5,
                  py: 1,
                }}
                onClick={handleSaveAnswer}
                variant='contained'
                color='success'
              >
                Save & Continue
              </Button>
            </Box>
          </Box>
        </Box>
        <QuestionsSidebar
          quiz={quiz}
          answered={answered}
          setOpen={setOpen}
        />
        <SubmitQuizConfirmation
          openConfirmationDialog={open}
          setOpenConfirmationDialog={setOpen}
          submitQuiz={submitQuiz}
        />
      </Box>
    </>
  );
}

// @ts-nocheck
import { Box, LinearProgress, Stack, Typography } from '@mui/material';
import React from 'react';

const NewSopStrength = ({ sop }) => {
  const getProgress = () => {
    const len = sop.length ?? '';
    if (len <= 125) {
      return 25;
    }
    if (len <= 250) {
      return 50;
    }
    if (len < 375) {
      return 75;
    }
    return 100;
  };

  const getStrengthText = () => {
    const len = sop.length ?? '';
    if (len <= 125) {
      return 'Weak';
    }
    if (len <= 250) {
      return 'Fair';
    }
    if (len < 375) {
      return 'Good';
    }
    return 'Excellent';
  };

  const getStrengthColor = () => {
    const len = sop.length ?? '';
    if (len <= 125) {
      return 'error';
    }
    if (len <= 250) {
      return 'warning';
    }
    if (len < 375) {
      return 'primary';
    }
    return 'success';
  };

  return (
    <Stack mb={2}>
      <Typography
        variant='body2'
        component='p'
        sx={{
          fontFamily: 'Inter',
        }}
      >
        Strength: {getStrengthText()}
      </Typography>
      <Box sx={{ width: '100%' }}>
        <LinearProgress
          variant='determinate'
          value={getProgress()}
          color={getStrengthColor()}
        />
      </Box>
    </Stack>
  );
};

export default NewSopStrength;

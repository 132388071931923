import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/system';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Stack,
  Typography,
  Button,
  Grid,
  Paper,
  Divider,
} from '@material-ui/core';
import IndividualProblem from '../Molecules/IndividualProblem';
import SideNavigation from './SideNavigation';
import {
  fetchMindMapActivities,
  fetchMindMapTopicData,
  getHeaders,
  postMindMapMarkedasCompleted,
} from '../../../../apis/urls';
import { mindmapTopics } from '../mindmapTopics';
import LoadingScreen from '../../../../pages/LoadingScreen';
import { mindmapTopicDescriptions } from '../mindmapTopicDescriptions';
import ErrorNotifier from '../../../../components/ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../../../components/ToastNotifications/SuccessNotifier';

const TopicProblems = ({ selectedSubTopic, subTopics, problems }) => {
  const [loading, setLoading] = useState(false);
  const [topicLoading, setTopicLoading] = useState(false);
  const [allButtonsDisabled, setAllButtonsDisabled] = useState(false);
  const [allSubTopics, setAllSubTopics] = useState([]);
  const [results, setResults] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [completedProblemIds, setCompletedProblemIds] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [result, setResult] = useState({});
  const [renderProblemId, setRenderProblemId] = useState({});
  const currentProgram = useSelector((state) => state.programs.currentProgram);
  const { topic } = useParams();
  const { subTopic } = useParams();

  const topicProblems = problems[subTopic];

  const selectedTopic = mindmapTopics.filter(
    (topics) => topics?.name === topic
  )[0];

  const markAsCompleted = async (problemId, problemLevel, problemSlug) => {
    const body = {
      problem_id: problemId,
      category_id: selectedTopic?.categoryId,
      problem_level: problemLevel,
      problem_slug: problemSlug,
    };

    setLoading(true);
    setAllButtonsDisabled(true);
    try {
      const response = await axios.post(postMindMapMarkedasCompleted(), body, {
        headers: getHeaders(currentProgram.programId),
      });

      // Update completedProblemIds state on successful API call
      setCompletedProblemIds([...completedProblemIds, problemId]); // Add marked problem ID to state
      setRenderProblemId(response.data);
      setIsSuccess(true);
      setSuccessMessage('Marked problem completed');
    } catch (err) {
      console.error(err);
      setHasError(true);
      setErrorMessage(
        err.response?.data.message ||
          'An error occured while marking the problem as completed.'
      );
    } finally {
      setLoading(false);
      setAllButtonsDisabled(false);
    }
  };

  useEffect(() => {
    handleFetchTopicDatas(topic);
  }, [topic, subTopic, renderProblemId]);

  const handleFetchTopicDatas = (topic) => {
    setTopicLoading(true);
    axios
      .get(fetchMindMapTopicData(topic), {
        headers: getHeaders(),
      })
      .then((res) => {
        setQuestions(res.data.data_hash[subTopic]);
        setAllSubTopics(res?.data.data_hash);
        setTopicLoading(false);

        setResult(res);
      })
      .catch((err) => {
        setTopicLoading(false);
        console.log(err);
      });

    axios
      .get(fetchMindMapActivities(selectedTopic?.categoryId), {
        headers: getHeaders(currentProgram.programId),
      })
      .then((res) => {
        const data = res?.data ?? [];

        const completedProblemIds = data
          .filter((problem) => problem.rejected === false)
          .map((problem) => problem.problem_id);

        setCompletedProblemIds(completedProblemIds);
        setResults(problems.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const hasMarkedCompleted = (problemId) => {
    const isProblemCompleted =
      completedProblemIds?.includes(problemId) ?? false;
    return isProblemCompleted;
  };

  return (
    <Box
      mt={2}
      ml={4}
    >
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
          duration={12000}
        />
      )}
      {isSuccess && (
        <SuccessNotifier
          message={successMessage}
          setIsSuccess={setIsSuccess}
        />
      )}
      <LoadingScreen loading={topicLoading} />
      <Box>
        <Typography
          variant='h4'
          sx={{ textTransform: 'capitalize' }}
        >
          {subTopic}
        </Typography>
      </Box>
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={9}
        >
          <Box
            display='flex'
            flexDirection='row'
          >
            <Paper
              sx={{
                width: '100%',
              }}
            >
              <Box
                mt={1}
                mb={2}
              >
                <Typography variant='body1'>
                  {mindmapTopicDescriptions[topic] &&
                    mindmapTopicDescriptions[topic][subTopic]}
                </Typography>
              </Box>

              {questions &&
                questions.map((problem, index) => (
                  <IndividualProblem
                    index={index}
                    problem={problem}
                    markAsCompleted={markAsCompleted}
                    hasMarkedCompleted={hasMarkedCompleted}
                    loading={loading}
                    disabled={allButtonsDisabled}
                  />
                ))}
            </Paper>
          </Box>
        </Grid>

        <Grid
          item
          xs={3}
        >
          <SideNavigation
            subTopics={subTopics}
            subTopic={subTopic}
            selectedSubTopic={selectedSubTopic}
            result={result}
            allSubTopics={allSubTopics}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default TopicProblems;

/* eslint-disable no-new */
/* eslint-disable react/prop-types */
import { DirectUpload } from 'activestorage';
import Compressor from 'compressorjs';
import { useState, useContext } from 'react';
import { styled } from '@material-ui/core/styles';
import axios from 'axios';
import CloseOutlined from '@iconify/icons-ant-design/close-outlined';
import { Icon } from '@iconify/react';
// material
import { Stack, TextField, Typography, Box, Modal, IconButton } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { AssignmentContext } from '../../../configs/AssignmentContext';
import { fetchActiveStorageUrl, getHeaders, submitAssignmentUrl } from '../../../apis/urls';

const ContentStyle = styled('div')(() => ({
	maxWidth: 590,
	maxHeight: 768,
	margin: 'auto',
	display: 'flex',
	marginTop: 12,
	borderRadius: 6,
	backgroundColor: 'white',
	flexDirection: 'column',
	justifyContent: 'center',
	padding: 32
}));

const UploadDocumentsModal = ({ open = true, setOpen, index }) => {
	const [submission, setSubmission] = useState(null);
	const [loading, setLoading] = useState(false);
	const [assignments, setAssignments] = useContext(AssignmentContext);

	const handleInputChange = (e) => {
		const file = e.target.files[0];
		new Compressor(file, {
			quality: 0.8,
			success: (compressedResult) => {
				setSubmission(compressedResult);
			}
		});
	};

	const uploadFile = (file, id) => {
		const upload = new DirectUpload(file, fetchActiveStorageUrl());
		upload.create((error, blob) => {
			if (error) console.log(error);
			else {
				const body = JSON.stringify({ submission: blob.signed_id });
				axios
					.post(submitAssignmentUrl(id), body, { headers: getHeaders() })
					.then((response) => {
						// setAssignmentCompleted(true);
						setLoading(false);
					})
					.catch((e) => { });
			}
		});
	};

	const handleFormSubmit = (e) => {
		e.preventDefault();
		setLoading(true);
		uploadFile(submission, assignments[index].id);
	};

	return (
		<Modal open={open} onClose={() => setOpen(!open)}>
			<ContentStyle width={600}>
				<Box
					display="flex"
					flexDirection="row"
					justifyContent="space-between"
					alignItems="baseline"
				>
					<Typography variant="p" fontSize={28}>
						Upload Your Submission
					</Typography>
					<IconButton
						sx={{
							width: '18',
							height: '18'
						}}
					>
						<Icon
							onClick={() => setOpen(!open)}
							style={{ cursor: 'pointer' }}
							icon={CloseOutlined}
						/>
					</IconButton>
				</Box>
				<form onSubmit={handleFormSubmit} style={{ marginTop: '12px' }}>
					<Stack spacing={3}>
						<TextField
							fullWidth
							type="file"
							name="submission"
							inputProps={{ accept: 'image/*' }}
							onChange={handleInputChange}
						/>
						<Typography variant="p" color="textSecondary" fontSize={20} mb={1}>
							<span>File Size up to: </span> 3 MB
						</Typography>
						<LoadingButton
							fullWidth
							size="large"
							type="submit"
							variant="contained"
							color="secondary"
							loading={loading}
						>
							Mark As Completed
						</LoadingButton>
					</Stack>
				</form>
			</ContentStyle>
		</Modal>
	);
};

export default UploadDocumentsModal;

// @ts-nocheck
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Box, Stack, Typography } from '@mui/material';
import SeizingOpportunityImage from '../../assets/pop-up_2.png';

const ScreeningTestWelcomeScreenExitPopup = ({
  startScreeningTest,
  trackButtonClick,
  holdON,
  source,
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    document.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      document.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, [showPopup]); // This is required because when we attach even listeners in react using `addEventListener`, we cannot access updated react states inside the event handler.

  const handleMouseLeave = (event) => {
    if (event.clientY <= 0 && !showPopup) {
      setShowPopup(true);
      trackButtonClick({ source: source, eventName: 'exit' });
    }
  };

  const handleClosePopup = (event, reason) => {
    setShowPopup(false);
    if (reason === 'backdropClick') {
      trackButtonClick({
        source: source,
        eventName: 'backdrop_click',
      });
    }
  };

  const handleBeginTest = () => {
    setShowPopup(false);
    startScreeningTest({
      source: source,
      eventName: 'begin_test',
    });
  };

  return (
    <Dialog
      maxWidth='sm'
      fullWidth
      open={showPopup}
      onClose={handleClosePopup}
      PaperProps={{
        style: {
          maxWidth: '500px',
          height: 'fit-content',
        },
      }}
    >
      <img
        style={{
          maxWidth: '500px',
        }}
        src={SeizingOpportunityImage}
        alt='seize-opportunity-image'
      />

      <Stack
        spacing={1.5}
        padding='1rem'
        maxWidth='inherit'
      >
        <Typography
          variant='h4'
          component='h4'
          sx={{
            fontFamily: 'Inter',
            flexWrap: 'wrap',
          }}
        >
          <b>Seize your opportunity now</b>
        </Typography>

        <Typography
          variant='body1'
          component='p'
          sx={{
            fontFamily: 'Inter',
            flexWrap: 'wrap',
          }}
        >
          The traditional pace can be tough to crack, so why wait? Fastrack
          screening test is shown only to selected individuals. Your next big
          opportunity is just a test away!
        </Typography>

        <Box
          display='flex'
          justifyContent='center'
        >
          <Button
            variant='contained'
            onClick={handleBeginTest}
            sx={{
              fontFamily: 'Inter',
              margin: '10px',
              padding: '10px 15px',
              textAlign: 'center',
              textTransform: 'uppercase',
              transition: '0.5s',
              backgroundSize: '200% auto',
              color: 'white',
              borderRadius: '10px',
              border: 0,
              fontWeight: 700,
              boxShadow: '0px 0px 14px -7px #f09819',
              backgroundImage:
                'linear-gradient(45deg, #FF512F 0%, #F09819 51%, #FF512F 100%)',
              cursor: 'pointer',
              userSelect: 'none',
              WebkitUserSelect: 'none',
              touchAction: 'manipulation',
              '&:hover': {
                backgroundPosition: 'right center',
                color: '#fff',
                textDecoration: 'none',
              },
              '&:active': {
                transform: 'scale(0.95)',
              },
            }}
            disabled={holdON}
          >
            Begin Test
          </Button>
        </Box>
      </Stack>
    </Dialog>
  );
};

export default ScreeningTestWelcomeScreenExitPopup;

import React, { useEffect, useState } from 'react';
import {
  Box,
  Chip,
  Grid,
  Typography,
  Divider,
  Tooltip,
} from '@material-ui/core';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import { useSelector } from 'react-redux';
import { SESSION_TESTS_MAPPING } from './SessionDetails';
import moduleIcon from './images/modules.svg';

function fixCasing(sentence) {
  let words = sentence.split(' ');
  let returnSentence = '';
  for (let i = 0; i < words.length; i++) {
    returnSentence += words[i].charAt(0).toLowerCase() + words[i].slice(1);
    if (i !== words.length - 1) {
      returnSentence += ' ';
    }
  }
  returnSentence =
    returnSentence.charAt(0).toUpperCase() + returnSentence.slice(1);
  return returnSentence;
}

export const Modules = ({
  groupToSessions,
  selectedSessionTopic,
  setSelectedSessionTopic,
}) => {
  const [inCompleteTopics, setIncompleteTopics] = useState([]);
  const [startedTopics, setStatedTopics] = useState([]);
  const sessionTests = useSelector((state) => state.sessions.session_tests);
  const isCompleted = (module) => {
    return (
      module.pre_reads.filter((preRead) => !preRead.completed).length === 0 &&
      module.coding_assignments.filter((assignment) => !assignment.completed)
        .length === 0 &&
      module.sessions.filter((session) => !session.completed).length === 0
    );
  };
  const isStarted = (module) => {
    return (
      module.pre_reads.filter((preRead) => preRead.completed).length > 0 ||
      module.coding_assignments.filter((assignment) => assignment.completed)
        .length > 0 ||
      module.sessions.filter((session) => session.completed).length > 0
    );
  };

  const checkSessionTestsCompleted = (topic) => {
    const { sessions } = groupToSessions[topic];
    const firstSessionIndex = sessions[0].index;
    const lastSessionIndex = sessions[sessions.length - 1].index;
    const sessions_containing_session_tests = Object.keys(
      SESSION_TESTS_MAPPING
    ).filter((value) => {
      return firstSessionIndex < value && value <= lastSessionIndex;
    });

    let sessionTestsCompleted = true;
    sessions_containing_session_tests.forEach((this_session) => {
      const sessionTest = sessionTests[SESSION_TESTS_MAPPING[this_session] - 1];
      if (sessionTest) {
        sessionTestsCompleted = sessionTestsCompleted && sessionTest.hasCleared;
      }
    });

    return sessionTestsCompleted;
  };

  const renderIcon = (topic) => {
    if (!inCompleteTopics.includes(topic)) {
      return <CheckCircleIcon style={{ color: '#388e3c', fontSize: '22px' }} />;
    } else if (startedTopics.includes(topic)) {
      return (
        <img
          src={moduleIcon}
          alt='module-icon'
        />
      );
    }
    return (
      <CircleIcon
        style={{
          color: 'transparent',
          fontSize: '18px',
          border: '2px dashed rgb(64, 96, 245)',
          borderRadius: '50%',
        }}
      />
    );
  };

  useEffect(() => {
    const topics = Object.keys(groupToSessions);
    setStatedTopics(
      topics.filter((key) => {
        const thisTopic = groupToSessions[key];
        return isStarted(thisTopic);
      })
    );

    // filtering incomplete topics
    setIncompleteTopics(
      topics.filter((key) => {
        const thisTopic = groupToSessions[key];
        return !isCompleted(thisTopic);
      })
    );
  }, [groupToSessions]);

  // useEffect(() => {
  //   if (inCompleteTopics.length > 0) {
  //     setSelectedSessionTopic(inCompleteTopics[0]);
  //   }
  // }, [inCompleteTopics]);

  function clickHandler(topic) {
    setSelectedSessionTopic(topic);
    localStorage.setItem('lastVisitedSession', JSON.stringify(topic));
  }
  return (
    <Box
      sx={{
        width: '25%',
        border: '1.5px solid #cccaca',
        borderRadius: '12px',
        height: '76vh',
        padding: '10px',
      }}
    >
      <Typography
        sx={{
          marginBottom: '10px',
          marginLeft: '10px',
          fontWeight: '1000',
          fontSize: 20,
        }}
      >
        Modules
      </Typography>

      <Divider
        style={{ width: '95%', margin: 'auto', backgroundColor: '#e8e8e8' }}
      />
      <Box
        sx={{
          overflow: 'overlay',
          height: '68vh',
          marginTop: '2px',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        {Object.keys(groupToSessions)?.map((topic, i) => {
          const sessionTopic = `${topic}_${i + 1}`;
          return (
            <Tooltip
              title={sessionTopic ? fixCasing(sessionTopic.split('_')[0]) : ''}
            >
              <Box
                key={i}
                sx={{
                  padding: '10px',
                  width: '100%',
                  marginTop: '5px',
                  backgroundColor: topic === selectedSessionTopic && '#e3f1ff',
                  borderRadius: '50px',
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  ':hover': {
                    backgroundColor: '#f0f7ff',
                  },
                }}
                onClick={() => clickHandler(topic)}
              >
                {renderIcon(topic)}

                <Typography
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    marginLeft: '8px',
                    fontWeight: topic === selectedSessionTopic ? '900' : '500',
                    fontSize: '14px',
                  }}
                >
                  {sessionTopic.split('_')[0]}
                </Typography>
              </Box>
            </Tooltip>
          );
        })}
      </Box>
    </Box>
  );
};

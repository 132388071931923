import { Box } from '@mui/material';
import MDEditor from '@uiw/react-md-editor';
import React from 'react';

// link to the doc: https://uiwjs.github.io/react-md-editor/
export default function MarkdownEditor({
  content,
  setContent,
  showEditor = false,
}) {
  return (
    <Box data-color-mode='light'>
      {showEditor && (
        <MDEditor
          value={content}
          onChange={(value) => setContent(value)}
          height={800}
        />
      )}
      {!showEditor && <MDEditor.Markdown source={content} />}
    </Box>
  );
}

import React from 'react';
import { Box, Typography } from '@material-ui/core';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BookIcon from '@material-ui/icons/Book';
import { languageBibleList } from './LanguageBibleContent';

const LanguageBible = () => {
  return (
    <div>
      {languageBibleList.map((category, index) => (
        <Accordion key={index}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <BookIcon />
              <Typography>{category.category}</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              {category.items.map((item, index) => (
                <Typography key={index}>
                  <a
                    href={item.link}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {item.name}
                  </a>
                </Typography>
              ))}
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default LanguageBible;

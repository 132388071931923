/* eslint-disable react/jsx-no-undef */
// @ts-nocheck
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import React from 'react';
import { useFastrackPipeline } from 'src/layouts/EfPipelineContext/FastrackPipelineContex';
import axios from 'axios';
import { BASE_URL, getHeaders } from 'src/apis/urls';

const BasicModePaymentCard = () => {
  const { offerLetter, trackButtonClick, setHasJoinedWaitlist } =
    useFastrackPipeline();

  const handleJoinWaitlist = async () => {
    trackButtonClick({
      source: 'fastrack_ol_screen_v3',
      eventName: 'join_waitlist',
    });

    try {
      const res = await axios.post(
        `${BASE_URL}/api/offer-letters/${offerLetter.olId}/join_waitlist`,
        {
          lead_id: offerLetter.leadId,
          assignee: offerLetter.lead_owner,
          lead_name:
            (offerLetter.fname ?? '') +
            (offerLetter.lname ? ` ${offerLetter.lname}` : ''),
        },
        {
          headers: getHeaders(),
        }
      );
    } catch (err) {
      console.error(err);
    } finally {
      setHasJoinedWaitlist(true);
    }
  };

  return (
    <Stack
      spacing={1.5}
      sx={{
        width: {
          xs: '20rem',
          sm: '22rem',
          md: '23rem',
          lg: '36%',
          xl: '32%',
        },
        height: '28.5rem',
        border: '1px solid #bdbdbd',
        borderRadius: '0.5rem',
        padding: '1.3rem',
        display: 'flex',
      }}
    >
      <Typography
        variant='body1'
        component='p'
        sx={{
          fontFamily: 'Inter',
          fontWeight: 'bold',
          fontSize: '1.5rem',
        }}
      >
        Basic Mode
      </Typography>

      <Typography
        variant='body2'
        component='p'
        sx={{
          fontFamily: 'Inter',
          fontWeight: 500,
          fontSize: '0.8rem',
        }}
      >
        Enter the waitlist for the interview, where your spot will be secured
        when availability opens up.
      </Typography>

      <Box
        display='flex'
        gap='8px'
      >
        <Typography
          style={{
            fontFamily: 'Inter',
            fontWeight: 600,
            fontSize: '1.5rem',
          }}
        >
          &#8377;
        </Typography>
        <Typography
          variant='h2'
          component='h2'
          sx={{
            fontFamily: 'Inter',
            fontWeight: 'bolder',
            paddingTop: 0,
            lineHeight: 'normal',
          }}
        >
          10,000
        </Typography>
      </Box>

      <Divider
        orientation='horizontal'
        flexItem
        sx={{ borderBottomWidth: 1.5 }}
      />

      <Stack
        spacing={1.5}
        pt='0.6rem'
        sx={{ flexGrow: 1 }}
      >
        <Typography
          variant='body1'
          sx={{
            fontFamily: 'Inter',
            fontWeight: 600,
            display: 'flex',
            alignItems: 'center',
            gap: '0.8rem',
            color: '#000000',
          }}
        >
          <CheckCircleIcon color='success' /> Regular access to course
        </Typography>

        <Stack
          display='flex'
          alignItems={'center'}
        >
          <Button
            variant='contained'
            sx={{
              marginTop: '8.5rem',
              width: '80%',
              backgroundColor: '#ffffff',
              color: '#000000',
              border: '1px solid #000000',
              fontFamily: 'Inter',
              textTransform: 'none',
              fontWeight: 600,
              padding: '0.75rem',
              borderRadius: '0.375rem',
              boxShadow: 'none',
              '&:hover': {
                backgroundColor: '#f0f0f0',
                boxShadow: 'none',
              },
            }}
            onClick={handleJoinWaitlist}
          >
            Join the waitlist
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default BasicModePaymentCard;

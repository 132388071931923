import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import Paper from '@mui/material/Paper';
import moment from 'moment';
import axios from 'axios';
import {
  fetchAssignmentSubmissions,
  fetchImageURL,
  getHeaders,
} from '../../../apis/urls';
import GradeAssignmentSubmission from './GradeAssignmentSubmission';
import ErrorNotifier from '../../ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../ToastNotifications/SuccessNotifier';
import { getUserType } from '../../../configs/auth';

export default function StudentSubmissions({
  openRow,
  handleClose,
  selectedAssignmentId,
  selectedStudentId,
}) {
  const userType = getUserType();
  const [shouldGradeAssignment, setShouldGradeAssignment] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [selectedSubmission, setSelectedSubmission] = useState(null);
  const [allSubmissions, setAllSubmissions] = useState([]);
  const currentProgram = useSelector((state) => state.programs.currentProgram);
  const descriptionElementRef = useRef(null);

  useEffect(() => {
    if (openRow) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openRow]);

  useEffect(() => {
    if (!shouldGradeAssignment && currentProgram) {
      fetchSubmissionsOfUser();
    }
    return () => {};
  }, [shouldGradeAssignment, currentProgram]);

  function fetchSubmissionsOfUser() {
    axios
      .get(fetchAssignmentSubmissions(selectedAssignmentId), {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((res) => {
        const tmpAllSubmissions = [];
        res?.data?.assignment_submissions.forEach((submission, index) => {
          if (submission.user_id === selectedStudentId) {
            tmpAllSubmissions.push(submission);
          }
        });
        setAllSubmissions(tmpAllSubmissions);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(err.response?.data?.message || err.message);
      });
  }

  const fetchAssignmentSubmissionFile = async (submission) => {
    await fetch(fetchImageURL(submission?.id), {
      headers: getHeaders(currentProgram.programId),
    })
      .then((resp) => resp.json())
      .then((res) => {
        if (res?.file) {
          openInNewTab(res?.file);
        } else {
          setHasError(true);
          setErrorMessage('An error occurred while opening this file');
        }
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(err.response?.data?.message || err.message);
      });
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const gradeSubmissionHandler = (submission) => {
    setSelectedSubmission(submission);
    setShouldGradeAssignment(true);
  };

  function getDialogContent() {
    if (allSubmissions.length > 0) {
      return (
        <SubmissionTableStructure
          submissions={allSubmissions}
          gradeSubmissionHandler={gradeSubmissionHandler}
          submissionFileHandler={(submission) =>
            fetchAssignmentSubmissionFile(submission)
          }
        />
      );
    }
    return (
      <Typography
        variant='body1'
        textAlign='center'
      >
        No submissions found
      </Typography>
    );
  }

  return (
    <>
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      {isSuccess && (
        <SuccessNotifier
          message={successMessage}
          setIsSuccess={setIsSuccess}
        />
      )}
      {!shouldGradeAssignment ? (
        <Dialog
          open={openRow}
          onClose={handleClose}
          scroll='paper'
          fullWidth
          maxWidth='md'
        >
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
          <DialogTitle>Submissions</DialogTitle>
          <DialogContent dividers>
            <DialogContentText
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              {getDialogContent()}
            </DialogContentText>
          </DialogContent>
        </Dialog>
      ) : (
        <GradeAssignmentSubmission
          submission={selectedSubmission}
          assignment_id={selectedAssignmentId}
          setShouldGradeAssignment={setShouldGradeAssignment}
          shouldGradeAssignment={shouldGradeAssignment}
          setHasError={setHasError}
          setIsSuccess={setIsSuccess}
          setErrorMessage={setErrorMessage}
          setSuccessMessage={setSuccessMessage}
        />
      )}
    </>
  );
}

function SubmissionTableStructure({
  submissions,
  gradeSubmissionHandler,
  submissionFileHandler,
}) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650, marginBlockStart: '1rem' }}>
        <TableHead>
          <TableRow>
            <TableCell>Submission</TableCell>
            <TableCell>Submitted on</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Evaluate</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {submissions.map((submission, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell>
                <Button onClick={() => submissionFileHandler(submission)}>
                  View File
                </Button>
              </TableCell>
              <TableCell>
                {moment(submission.created_at).format('dddd, MMMM Do YYYY')}
              </TableCell>
              <TableCell>
                {submission.score != null ? 'Graded' : 'Not Graded'}
              </TableCell>
              <TableCell>
                <Button onClick={() => gradeSubmissionHandler(submission)}>
                  Grade
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

import React from 'react';
import { useSelector } from 'react-redux';
import TabLayoutV2 from './TabLayoutV2';
import TabLayout from './TabLayout';

export default function TabLayoutWrapper() {
  const isNewLayout = useSelector(
    (state) => state.programs?.shouldShowNewStudentLayout
  );

  return isNewLayout ? <TabLayoutV2 /> : <TabLayout />;
}

import { Box, Typography } from '@material-ui/core';
import React from 'react';
import moment from 'moment';
import Countdown, { zeroPad } from 'react-countdown';

export default function Timer({
  handleAutoSubmit,
  duration = 0,
  startedAt,
  closesAt,
}) {
  const getDurationForCountdown = () => {
    const now = moment(startedAt);
    const targetTime = now.add(duration, 'minutes');
    const t = moment.min(closesAt, targetTime);
    return t;
  };

  const CountdownRenderer = ({ hours, minutes, seconds }) => {
    if (hours) {
      return (
        <span>
          {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
        </span>
      );
    }
    return (
      <span>
        {zeroPad(minutes)}:{zeroPad(seconds)}
      </span>
    );
  };

  return (
    <>
      <Box
        sx={{
          marginY: 'auto',
          width: 105,
        }}
      >
        <Typography variant='h6'>
          <Countdown
            onTick={handleAutoSubmit}
            date={getDurationForCountdown()}
            renderer={CountdownRenderer}
          />
        </Typography>
      </Box>
    </>
  );
}

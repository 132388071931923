// @ts-nocheck
import { Stack } from '@mui/material';
import React from 'react';
import QuestionDescription from '../QuestionDescription';
import SingleCorrectOptions from './SingleCorrectOptions';

const SingleCorrectQuestion = ({
  qid,
  type,
  description,
  options,
  response,
  setResponse,
  setShouldAllowNext,
  revealAnswerOfQuestionNumber,
}) => {
  return (
    <Stack
      spacing={2}
      width='100%'
    >
      <QuestionDescription description={description} />

      <SingleCorrectOptions
        {...{
          qid,
          options,
          response,
          setResponse,
          setShouldAllowNext,
          revealAnswerOfQuestionNumber,
        }}
      />
    </Stack>
  );
};

export default SingleCorrectQuestion;

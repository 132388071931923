import { Box, Button, Modal, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { getUserType } from '../../configs/auth';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../ToastNotifications/SuccessNotifier';
import ReadingMaterial from './ReadingMaterial';
import { useSessionContext } from './SessionContext';
import AddResourceForm from '../_dashboard/resource/AddResourceForm';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function StudentResources({ sessionData, getSessionResources }) {
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const { setMaterialResource } = useSessionContext();
  const [open, setOpen] = useState(false);
  const userType = getUserType();
  const isCoach = userType === 'coach';

  useEffect(() => {
    if (isSuccess) {
      getSessionResources();
    }
    return () => {};
  }, [isSuccess]);

  return (
    <Box sx={{ width: '100%', typography: 'body1', paddingTop: '35px' }}>
      {isCoach && hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      {isCoach && isSuccess && (
        <SuccessNotifier
          message={successMessage}
          setIsSuccess={setIsSuccess}
        />
      )}
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        {isCoach && (
          <Button
            variant='contained'
            onClick={() => setOpen(true)}
          >
            Add resource
          </Button>
        )}
        {isCoach && (
          <Modal
            open={open}
            onClose={() => setOpen(false)}
          >
            <Box sx={style}>
              <AddResourceForm
                setResources={setMaterialResource}
                setOpen={setOpen}
                setIsSuccess={setIsSuccess}
                setHasError={setHasError}
                setSuccessMessage={setSuccessMessage}
                setErrorMessage={setErrorMessage}
                receivedSessionId={sessionData?.id}
              />
            </Box>
          </Modal>
        )}
      </Box>
      <ReadingMaterial
        getSessionResources={getSessionResources}
        setHasError={setHasError}
        setIsSuccess={setIsSuccess}
        setErrorMessage={setErrorMessage}
        setSuccessMessage={setSuccessMessage}
      />
    </Box>
  );
}

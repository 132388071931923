import { Box } from '@mui/material';
import { Stack, Typography } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import dsaVisulazationImage from '../images/dsaVisulazationImage.png';
import greatPyramidImage from '../images/greatPyramidImage.png';
import loginImage from '../images/loginImage.png';
import mindMapImage from '../images/mindMapImage.png';

const Item = styled('div')(({ theme }) => ({
  background: `linear-gradient(rgba(25, 118, 210, 1), rgba(25, 118, 210, 0.5))`,
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  width: '100%',
  height: '100%',

  '@media (max-width: 1000px)': {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  borderTopRightRadius: '30px',
  borderBottomRightRadius: '30px',
}));

const LoginCarousel = ({ theme, carouselRef }) => {
  const carouselData = [
    {
      img: loginImage,
      name: '',
    },
    {
      img: mindMapImage,
      name: 'Mindmap',
    },
    {
      img: dsaVisulazationImage,
      name: 'DSA Visulaizer',
    },
    {
      img: greatPyramidImage,
      name: 'The Great Pyramid',
    },
  ];
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        '@media (max-width: 1000px)': { height: '70vh' },
        '@media (max-width: 300px)': { height: '150vh' },
      }}
    >
      <Item>
        <Stack
          sx={{
            [theme.breakpoints.up('md')]: {
              height: '43%',
            },
            width: '100%',
            marginBottom: '30px',
            '@media (max-width: 1000px)': { marginBottom: 0, height: '50%' },
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Inter',
              fontSize: '33px',
              fontWeight: 500,
              letterSpacing: '0.01em',
              textAlign: 'left',
              '@media (max-width: 1000px)': {
                paddingLeft: '10px',
                paddingRight: '10px',
              },
              paddingLeft: '10px',
              paddingRight: '10px',
              color: 'white',
            }}
          >
            Master DSA<span style={{ fontWeight: 300 }}> with </span>
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Inter',
              fontSize: '33px',
              fontWeight: 500,
              textAlign: 'left',
              color: 'white',
              '@media (max-width: 1000px)': {
                paddingLeft: '10px',
                paddingRight: '10px',
              },
              paddingLeft: '10px',
              paddingRight: '10px',
            }}
          >
            <span style={{ fontWeight: 300 }}> our</span> Signature Frameworks
          </Typography>
          <Typography
            variant='body1'
            component='p'
            align='justify'
            sx={{
              color: 'white',
              textAlign: 'left',
              fontSize: '15px',
              marginBottom: '2px',
              '@media (max-width: 1000px)': {
                paddingLeft: '10px',
                paddingRight: '10px',
              },
              paddingLeft: '10px',
              paddingRight: '10px',
            }}
          >
            Our frameworks are designed to deepen understanding and accelerate
            growth, these tools are your pathway to conquering interviews and
            excelling in the industry.
          </Typography>
        </Stack>
        <Carousel
          autoPlay
          infiniteLoop
          interval={3000}
          showStatus={false}
          showThumbs={false}
          showArrows={false}
          ref={carouselRef}
        >
          {carouselData.map((datum, index) => (
            <Box key={index}>
              <Typography
                variant='body1'
                align='center'
                fontSize='20px'
                sx={{
                  color: 'white',
                  fontWeight: '500',
                  width: '100%',
                }}
              >
                {datum.name}
              </Typography>
              <img
                src={datum.img}
                alt={datum.name}
                style={{ width: '285px' }}
              />
            </Box>
          ))}
        </Carousel>
      </Item>
    </Box>
  );
};

LoginCarousel.propTypes = {
  theme: PropTypes.object.isRequired,
  carouselRef: PropTypes.object.isRequired,
};

export default LoginCarousel;

import React from 'react';
import { 
  Button, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogContentText, 
  DialogTitle 
} from "@material-ui/core";

export default function CodingTestConfirmationDialog({ 
  openConfirmationDialog, 
  setOpenConfirmationDialog, 
  handleClick,
  testId
}) {

  return (
    <Dialog 
      open={openConfirmationDialog} 
      onClose={() => setOpenConfirmationDialog(false)}
    >
      <DialogTitle>
        Submit
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          Are you sure you want to exit? You won't be able to attempt the test again until the test is over.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button 
          onClick={() => setOpenConfirmationDialog(false)}
        >
          Cancel
        </Button>
        <Button 
          onClick={(e) => handleClick(e, testId)} 
          autoFocus
        >
          Yes, I am sure
        </Button>
      </DialogActions>
    </Dialog>
  );
}
// @ts-nocheck
import {
  Typography,
  Stack,
  Box,
  Button,
  useTheme,
  useMediaQuery,
  Grid,
} from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import { useFastrackPipeline } from 'src/layouts/EfPipelineContext/FastrackPipelineContex';
import { useEffect, useState } from 'react';
import { companyData } from '../index.js';

const CompanisListScreen = ({ flowData, setFlowData, saveFlowData }) => {
  const { setScreenSource, trackButtonClick } = useFastrackPipeline();
  const theme = useTheme();
  const isBelow600 = useMediaQuery(theme.breakpoints.down('sm'));
  const [revealMaxCompaniesText, setRevealMaxCompaniesText] = useState(false);

  useEffect(() => {
    if (!revealMaxCompaniesText) {
      return;
    }

    const id = setTimeout(() => setRevealMaxCompaniesText(false), 5 * 1000); // 5 seconds
    return () => clearTimeout(id);
  }, [revealMaxCompaniesText]);

  const handleCompanyClick = (companyName) => {
    if ((flowData?.companies ?? []).length === 3 && !isSelected(companyName)) {
      setRevealMaxCompaniesText(true);
      return;
    }

    setFlowData((prev) => {
      let tmp = [...(prev?.companies ?? [])];
      const entries = [];
      let found = false;
      tmp.forEach((t) => {
        if (t.name !== companyName) {
          entries.push(t);
        } else {
          found = true;
        }
      });

      if (!found) {
        entries.push({
          name: companyName,
          roles: [],
        });
      }

      return { ...prev, companies: entries };
    });
  };

  const isSelected = (companyName) => {
    const selectedCompanies = flowData?.companies ?? [];
    return selectedCompanies.map((c) => c.name).includes(companyName);
  };

  const shouldShowNext = () => {
    const selectedCompanies = flowData?.companies ?? [];
    return selectedCompanies.length > 0;
  };

  const handleNext = () => {
    trackButtonClick({ source: 'screen_4', eventName: 'next' });
    saveFlowData({
      screen: 'screen_4',
      response: {
        companies: flowData.companies.map((c) => ({ name: c.name })),
      },
    });

    // We have removed screen_5.
    setScreenSource('screen_6');
  };

  return (
    <Stack
      spacing={isBelow600 ? 2 : 2}
      alignItems='center'
      textAlign='center'
      width='inherit'
      rowGap={2}
    >
      <Stack
        width={{
          xs: '100%',
          sm: '100%',
          md: '90%',
          lg: '70%',
          xl: '70%',
        }}
        textAlign='center'
        alignItems='center'
      >
        <Typography
          variant='body1'
          component='p'
          sx={{
            fontFamily: 'Inter',
            fontSize: {
              xs: '1.1rem',
              sm: '1.2rem',
              md: '1.2rem',
              lg: '1.2rem',
            },
            width: 'fit-content',
            textAlign: 'center',
            textWrap: 'stable',
          }}
        >
          Alright, the fees might seem high. Let’s look at how this course can
          help you transition from a product to a top tech company, potentially
          boosting your salary and making the investment pay off.
        </Typography>
      </Stack>

      <Typography
        variant='body1'
        component='p'
        sx={{
          fontFamily: 'Inter',
          fontSize: {
            xs: '1.2rem',
            sm: '1.2rem',
            md: '1.3rem',
            lg: '1.3rem',
          },
          width: 'fit-content',
          textAlign: 'center',
          textWrap: 'stable',
        }}
      >
        <b>Which leading tech company are you aiming for?</b>
      </Typography>

      <Typography
        variant='body1'
        component='p'
        sx={{
          fontFamily: 'Inter',
          fontSize: {
            xs: '1rem',
            sm: '1rem',
            md: '1rem',
            lg: '1rem',
          },
          width: 'fit-content',
          textAlign: 'center',
          textWrap: 'stable',
          visibility: !revealMaxCompaniesText && 'hidden',
        }}
      >
        You can select a maximum of 3 companies
      </Typography>

      <Grid
        container
        spacing={2}
        // justifyContent='center'
        alignItems='center'
        width='100%'
        rowGap={5}
      >
        {Object.keys(companyData).map((company, index) => {
          return (
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={4}
              key={company}
              justifyContent='center'
              display='flex'
              alignItems='center'
            >
              <Box
                component='img'
                src={companyData[company].logo}
                alt={company}
                sx={{
                  width: '100%',
                  maxWidth: '200px',
                  height: '40px',
                  objectFit: 'contain',
                  cursor: 'pointer',
                  '&:hover': {
                    transition: 'all .4s ease-in-out',
                    scale: '1.1',
                  },
                  ...companyData[company].style,
                  ...(isSelected(company) && {
                    border: '1px solid #1976d2',
                    borderRadius: '0.5rem',
                    padding: '0.4rem',
                  }),
                }}
                onClick={(e) => handleCompanyClick(company)}
              />
            </Grid>
          );
        })}
      </Grid>

      <Button
        disableElevation
        endIcon={<EastIcon />}
        variant='contained'
        style={{
          visibility: !shouldShowNext() && 'hidden',
          boxShadow: 'none',
          fontFamily: 'Inter',
          textTransform: 'none',
          padding: '0.6rem 1.5rem',
          background: '#1976d2',
          '&:hover': {
            background: '#1976d2',
          },
          marginBlockStart: '2.5rem',
        }}
        onClick={handleNext}
      >
        Next
      </Button>
    </Stack>
  );
};

export default CompanisListScreen;

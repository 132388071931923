import { Button, Stack, Typography } from '@material-ui/core';
import { Box } from '@material-ui/system';
import React, { useEffect, useState } from 'react';
import {
  DataGrid,
  GridActionsCellItem,
  GridEditInputCell,
} from '@mui/x-data-grid';
import ErrorNotifier from 'src/components/ToastNotifications/ErrorNotifier';
import SuccessNotifier from 'src/components/ToastNotifications/SuccessNotifier';
import NewMentorSessionModal from './NewMentorSessionModal';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import { useSelector } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import {
  fetchProgramEnrollmentsUrl,
  getHeaders,
  mentorSessionsURL,
  updateMentorSessions,
} from 'src/apis/urls';
import moment from 'moment';
import MentorSessionLogDetails from './MentorSessionLogDetails';
export default function MentorSession() {
  const [openNewMentorSessionModal, setOpenNewMentorSessionModal] =
    useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const currentProgram = useSelector((state) => state.programs.currentProgram);
  const [learners, setLearners] = useState([]);
  const [data, setData] = useState([]);
  const [sessionBeingUpdated, setSessionBeingUpdated] = useState({});
  const [coaches, setCoaches] = useState({});
  const [openLog, setOpenLog] = useState(false);
  const [selectedMentorSession, setSelectedMentorSession] = useState({});

  const timeFormat = 'MMM DD, YYYY, hh:mm A';

  useEffect(() => {
    if (isSuccess || currentProgram) {
      fetchEnrollmentsData();
      fetchMentorSessionData();
    }
    return () => {};
  }, [currentProgram, isSuccess]);

  function fetchEnrollmentsData() {
    axios
      .get(fetchProgramEnrollmentsUrl(), {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((res) => {
        const tmpCoaches = {};
        const allLearners = [];

        res.data?.forEach((enrollment) => {
          const { user, program_access } = enrollment;
          if (user?.role === 'coach' && program_access === 'allowed') {
            tmpCoaches[`${user.name} (${user.email})`] = user.id;
          }
          if (user?.name?.length > 0) {
            allLearners.push({
              id: user.id,
              name: user.name,
              email: user.email,
            });
          }
        });
        setCoaches(tmpCoaches);
        setLearners(allLearners);
      })
      .catch((e) => {
        console.error(e);
      });
  }

  function fetchMentorSessionData() {
    axios
      .get(mentorSessionsURL(), {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((res) => {
        setData(res.data.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  const columns = [
    {
      field: 'id',
      headerName: 'Id',
      width: 70,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'title',
      headerName: 'Title',
      width: 240,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'learner',
      headerName: 'Learner Name',
      width: 180,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => params.row.learner.name,
    },
    {
      field: 'start_time',
      headerName: 'Start Time',
      width: 200,
      align: 'center',
      editable: true,
      headerAlign: 'center',
      type: 'dateTime',
      renderEditCell: (params) => (
        <GridEditInputCell
          {...params}
          inputProps={{
            min: moment().format('YYYY-MM-DDTHH:mm'),
            type: 'datetime-local',
            value: moment(params.row.start_time).format('YYYY-MM-DDTHH:mm'),
          }}
        />
      ),
      valueGetter: (params) =>
        params.row?.start_time
          ? moment(params.row.start_time).format(timeFormat)
          : 'NA',
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 200,
      align: 'center',
      headerAlign: 'center',
      editable: true,
      type: 'singleSelect',
      valueOptions: [
        'Created',
        'Approved',
        'Scheduled',
        'Conducted',
        'Verified',
        'Payment processed',
      ],
      valueGetter: (params) => {
        const status = params.row.status ?? ' ';
        if (status === 'payment_processed') {
          return 'Payment processed';
        }
        return status.charAt(0).toUpperCase() + status.slice(1);
      },
    },
    {
      field: 'coach',
      headerName: 'Coach',
      width: 200,
      align: 'center',
      headerAlign: 'center',
      editable: true,
      type: 'singleSelect',
      valueOptions: Object.keys(coaches),
      valueGetter: (params) => {
        return params.row.coach.id
          ? `${params.row.coach?.name} (${params.row.coach?.email})`
          : 'NA';
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      width: 180,
      align: 'center',
      headerAlign: 'center',
      cellClassName: 'actions',
      getActions: ({ id, row }) => {
        const isInEditMode = sessionBeingUpdated[id]?.mode === 'edit';
        // Add the Status the Can't be Edited if found
        if (row.status === 'payment_processed') {
          return [];
        }
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={
                <CheckIcon
                  fontSize='medium'
                  color='success'
                />
              }
              label='Save'
              sx={{
                color: 'success',
              }}
              onClick={(e) => handleSessionUpdate('update', id)}
            />,
            <GridActionsCellItem
              icon={
                <ClearIcon
                  fontSize='medium'
                  color='error'
                />
              }
              label='Cancel'
              onClick={(e) => handleSessionUpdate('clear', id)}
              color='error'
              sx={{ cursor: 'pointer' }}
            />,
          ];
        }
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label='Edit'
            className='textPrimary'
            onClick={(e) => {
              setSessionBeingUpdated({
                ...sessionBeingUpdated,
                [id]: { mode: 'edit' },
              });
            }}
            sx={{ cursor: 'pointer' }}
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label='Delete'
            color='inherit'
            onClick={handleDeleteClick(id)}
          />,
        ];
      },
    },
  ];

  const handleSessionUpdate = (clickType, id) => {
    if (clickType === 'clear') {
      setSessionBeingUpdated({
        ...sessionBeingUpdated,
        [id]: { mode: 'view', ignoreModifications: true },
      });
      return;
    }
    setSessionBeingUpdated({ ...sessionBeingUpdated, [id]: { mode: 'view' } });
  };

  const processUpdate = (updatedSession) => {
    const body = {};
    body.status = updatedSession.status.toLowerCase().split(' ').join('_');
    body.start_time = moment(updatedSession.start_time).utc();
    body.coach_id = coaches[updatedSession.coach];

    const current_obj = data.find((item) => item.id === updatedSession.id);
    const from = current_obj.status;
    const to = body.status;

    const foundStatus = validSessionStatusOrder[from].find(
      (status) => status === to
    );
    if (!foundStatus) {
      setHasError(true);
      setErrorMessage(
        `Session status from ${from} cannot be converted back to ${to}`
      );
      return current_obj;
    }

    axios
      .patch(updateMentorSessions(updatedSession.id), body, {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((res) => {
        setIsSuccess(true);
        setSuccessMessage('Mentor Session Successfully Updated.');
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || 'Could Not Update The Mentor Session.'
        );
      });
    return updatedSession;
  };

  const handleDeleteClick = (id) => () => {
    setData(data.filter((row) => row.id !== id));
    axios
      .delete(updateMentorSessions(id), {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((res) => {
        setIsSuccess(true);
        setSuccessMessage('Mentor Session Successfully Deleted.');
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || 'Could Not Delete The Mentor Session.'
        );
      });
  };

  return (
    <Stack mt={2}>
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      {isSuccess && (
        <SuccessNotifier
          message={successMessage}
          setIsSuccess={setIsSuccess}
        />
      )}
      <Box
        display='flex'
        justifyContent='flex-end'
        sx={{ mr: 1 }}
      >
        <Button
          variant='contained'
          onClick={() => setOpenNewMentorSessionModal(true)}
        >
          Create Mentor Session
        </Button>
      </Box>
      {data.length == 0 ? (
        <Typography
          align='center'
          variant='body2'
          sx={{ mt: 2 }}
        >
          No Mentor Session have been added so far.
        </Typography>
      ) : (
        <Box sx={{ height: 400, width: '100%' }}>
          <DataGrid
            autoHeight
            rows={data}
            columns={columns}
            sx={{
              '& .MuiDataGrid-row:hover': {
                backgroundColor: '#e3f2fd',
                cursor: 'pointer',
              },
            }}
            pageSize={100}
            rowsPerPageOptions={[100]}
            rowModesModel={sessionBeingUpdated}
            experimentalFeatures={{ newEditingApi: true }}
            editMode='row'
            processRowUpdate={processUpdate}
            onRowClick={(params, event) => {
              setOpenLog(true);
              setSelectedMentorSession(params.row);
            }}
            onRowEditStart={(params, event) => {
              event.defaultMuiPrevented = true;
            }}
            onRowEditStop={(params, event) => {
              event.defaultMuiPrevented = true;
            }}
            disableRowSelectionOnClick
          />
        </Box>
      )}
      {openLog && Object.keys(selectedMentorSession)?.length > 0 && (
        <MentorSessionLogDetails
          openLog={openLog}
          setOpenLog={setOpenLog}
          mentorSession={selectedMentorSession}
        />
      )}
      {openNewMentorSessionModal && (
        <NewMentorSessionModal
          setOpenNewMentorSessionModal={setOpenNewMentorSessionModal}
          openNewMentorSessionModal={openNewMentorSessionModal}
          learners={learners}
          currentProgram={currentProgram}
          setIsSuccess={setIsSuccess}
          setSuccessMessage={setSuccessMessage}
          setErrorMessage={setErrorMessage}
          setHasError={setHasError}
        />
      )}
    </Stack>
  );
}

const validSessionStatusOrder = {
  created: [
    'created',
    'scheduled',
    'conducted',
    'verified',
    'payment_processed',
  ],
  scheduled: ['scheduled', 'conducted', 'verified', 'payment_processed'],
  conducted: ['conducted', 'verified', 'payment_processed'],
  verified: ['verified', 'payment_processed'],
  payment_processed: [],
};

export const DSATopic = [
  {
    displayName: 'Sort',
    categoryId: 1,
    description:
      'Sorting is the process of arranging items in a specific order, like alphabetically or numerically, to make them easier to find or understand, often from smallest to largest or A to Z.',
    gif: '/static/dsa-visualizer/sorting/',
    image_count: 6,
  },
  {
    displayName: 'Traversal',
    categoryId: 2,
    description:
      "Traversal refers to moving through or visiting each element or item in a collection, like a list or tree structure, to perform an operation or gather information. It's like checking each item in a list one by one.",
    gif: '/static/dsa-visualizer/traversal/',
    image_count: 4,
  },
  {
    displayName: 'Searching',
    categoryId: 3,
    description:
      "Searching is the act of looking for a specific item or information within a collection, such as a list or database, to find whether it exists and where it's located. It's like finding a particular book in a library.",
    gif: '/static/dsa-visualizer/searching/',
    image_count: 4,
  },
  {
    displayName: 'Set',
    categoryId: 4,
    description:
      "A set is a collection of distinct elements or values with no duplicates. It's like a container for unique items, and it's often used in programming to manage data with unique characteristics.",
    gif: '/static/dsa-visualizer/set/',
    image_count: 6,
  },
  {
    displayName: 'Tree',
    categoryId: 5,
    description:
      'A tree is a hierarchical data structure with nodes connected like branches, starting from a single root, commonly used in algorithms and data organization.',
    gif: '/static/dsa-visualizer/tree/',
    image_count: 4,
  },
  {
    displayName: 'Heap',
    categoryId: 8,
    description:
      'A heap is a complete binary tree-based data structure where each parent node is either greater than or equal to (in a max heap) or less than or equal to (in a min heap) its child nodes.',
    gif: '/static/dsa-visualizer/heap/',
    image_count: 3,
  },
  {
    displayName: 'Graph',
    categoryId: 9,
    description:
      'A graph is a non-linear data structure consisting of nodes (vertices) and edges that represent connections or relationships between the nodes.',
    gif: '/static/dsa-visualizer/graphs/',
    image_count: 4,
  },
  {
    displayName: 'Backtracking',
    categoryId: 14,
    description:
      'Backtracking is a technique for finding solutions by exploring all possible candidates incrementally and backtracking when a solution is found to be infeasible.',
    gif: '/static/dsa-visualizer/backtracking/',
    image_count: 7,
  },
  {
    displayName: 'Dynammic Programming',
    categoryId: 19,
    description:
      'Dynamic programming is a method for solving complex problems by breaking them down into overlapping subproblems, solving each subproblem only once, and storing the results for future use to optimise performance.',
    gif: '/static/dsa-visualizer/sliding window/',
    image_count: 4,
  },
  {
    displayName: 'Hash',
    categoryId: 18,
    description:
      "A hash is a fixed-size numerical value generated from data, like text or files, using a hashing algorithm. It's used for quick data retrieval and verification, like a unique fingerprint for data.",
    gif: '/static/dsa-visualizer/hashmap/',
    image_count: 5,
  },
];

// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';

const defaultStyle = {
  width: '100%',
};

export default function SingleProgramSelectionDropdown({
  programs,
  label = 'Program',
  onChangeHandler,
  sx = defaultStyle,
}) {
  const [inputValue, setInputValue] = useState('');
  const [allPrograms, setAllPrograms] = useState([]);

  useEffect(() => {
    getPrograms();
    return () => {};
  }, [programs]);

  function getPrograms() {
    const tmpPrograms = [];
    Object.keys(programs).forEach((pid, index) => {
      tmpPrograms.push({
        label: programs[pid].internal_name,
        value: programs[pid].programId,
      });
    });

    setAllPrograms(tmpPrograms);
  }

  return (
    <Autocomplete
      loading
      value={inputValue}
      onChange={(event, newValue) => {
        setInputValue(newValue);
        onChangeHandler(event, newValue);
      }}
      options={allPrograms}
      getOptionLabel={(option) => option.label ?? ''}
      sx={sx}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
        />
      )}
      size='medium'
      disableClearable
    />
  );
}

// @ts-nocheck
import React, { useState } from 'react';
import { Box, Typography, IconButton, Popover } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export default function AdminMessageOptions({
  setIsEditing,
  setDeleteConfirmation,
  isHovered,
  setShouldNotifyByEmail,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleButtonClick = (event) => {
    if (!menuOpen) {
      setAnchorEl(event.currentTarget);
      setMenuOpen(true);
    } else {
      handleClose();
    }
  };

  const handleEdit = (e) => {
    setIsEditing(true);
    handleClose();
  };

  const handleDelete = () => {
    setDeleteConfirmation(true);
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  const handleNotify = () => {
    setShouldNotifyByEmail(true);
    handleClose();
  };

  const open = Boolean(anchorEl);

  return (
    <>
      {isHovered && (
        <IconButton
          size='small'
          onClick={handleButtonClick}
          sx={{
            position: 'absolute',
            right: '5px',
            width: '20px',
            height: '20px',
            opacity: 1,
            backgroundColor: '#E8FFFF',
          }}
        >
          <ArrowDropDownIcon />
        </IconButton>
      )}
      <Popover
        id='message-popover'
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        sx={{ cursor: 'pointer' }}
      >
        <Box padding={1}>
          <Typography
            variant='body2'
            color='textSecondary'
            onClick={handleEdit}
          >
            Edit
          </Typography>

          <Typography
            variant='body2'
            color='textSecondary'
            onClick={handleDelete}
          >
            Delete
          </Typography>

          <Typography
            variant='body2'
            color='textSecondary'
            onClick={handleNotify}
          >
            Notify by email
          </Typography>
        </Box>
      </Popover>
    </>
  );
}

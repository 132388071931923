// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Stack } from '@material-ui/core';
import axios from 'axios';
import PreviousPage from '../../../pages/PreviousPage';
import ErrorNotifier from '../../ToastNotifications/ErrorNotifier';
import { BASE_URL, getHeaders } from '../../../apis/urls';
import LoadingScreen from '../../../pages/LoadingScreen';
import { ProblemSubTabs } from './ProblemSubTabs';
import CodingAssignmentCodeEditor from './CodingAssignmentCodeEditor';
import { NewProblemSubTabs } from './NewProblemSubTabs';
import NewCodingAssignmentCodeEditor from './NewCodingAssignmentCodeEditor';
import { useDispatch } from 'react-redux';

const SelectedCodingAssignment = ({
  selectedAssignment,
  setSelectedAssignment,
  shouldShowBackButton = true,
}) => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [selectedProblem, setSelectedProblem] = useState({});
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const currentProgram = useSelector((state) => state.programs.currentProgram);
  useEffect(() => {
    fetchCodingAssignment();
    return () => {
      setSelectedProblem({});
    };
  }, [selectedAssignment]);

  const fetchCodingAssignment = () => {
    setLoading(true);
    axios
      .get(
        `${BASE_URL}/api/code_problems/${selectedAssignment.code_problem_id}`,
        {
          headers: getHeaders(currentProgram?.programId),
        }
      )
      .then((res) => {
        setSelectedProblem(res.data);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err?.response?.data?.message ||
            'An error occured while fetching the assignment problem.'
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Stack>
      {shouldShowBackButton && (
        <Box
          display='flex'
          justifyContent='flex-start'
        >
          <PreviousPage
            handlePrevPageClick={() => setSelectedAssignment(null)}
          />
        </Box>
      )}

      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      <LoadingScreen loading={loading} />

      {Object.keys(selectedProblem).length > 0 && (
        <>
          <NewProblemSubTabs
            selectedProblem={selectedProblem}
            assignmentId={selectedAssignment.id}
          />
          <NewCodingAssignmentCodeEditor
            selectedAssignment={selectedAssignment}
            setSelectedAssignment={setSelectedAssignment}
            selectedProblem={selectedProblem}
            assignmentId={selectedAssignment.id}
            problemContext={
              location.pathname.includes('sessions') ? 'Session' : 'Foundation'
            }
          />
        </>
      )}
    </Stack>
  );
};

export default SelectedCodingAssignment;

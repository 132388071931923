import { useState } from 'react';
import { styled } from '@material-ui/core/styles';
import { Stack, TextField, Typography, Button, Box } from '@material-ui/core';
import { Icon } from '@iconify/react';
import CloseOutlined from '@iconify/icons-ant-design/close-outlined';
import { LoadingButton } from '@material-ui/lab';
import axios from 'axios';
import { getUserId } from '../../configs/auth';
import { getHeaders, getUserLinks } from '../../apis/urls';
import SelectFieldComponent from 'src/layouts/shared/SelectFieldComponent';
// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  maxHeight: 600,
  margin: 'auto',
  marginTop: 50,
  overflowY: 'auto',
  borderRadius: 15,
  backgroundColor: 'white',
  justifyContent: 'center',
  padding: 32,
}));

const AddUserLinks = (props) => {
  const [formData, setFormData] = useState({
    platformName: '',
    platformLink: '',
  });

  const [errors, setErrors] = useState({
    platformName: false,
    platformLink: false,
  });

  function handleSubmit() {
    let hasError = false;

    if (!formData.platformName.trim()) {
      setErrors((prevErrors) => ({ ...prevErrors, platformName: true }));
      hasError = true;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, platformName: false }));
    }

    if (!formData.platformLink.trim()) {
      setErrors((prevErrors) => ({ ...prevErrors, platformLink: true }));
      hasError = true;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, platformLink: false }));
    }

    if (!hasError) {
      const body = {
        user_id: getUserId(),
        platform_name: formData.platformName,
        platform_link: formData.platformLink,
      };

      axios
        .post(getUserLinks(), body, {
          headers: getHeaders(),
        })
        .then((res) => {
          props.setAddUserLinksModel(false);
          props.setSuccess(true);
          props.getUserLinksData();
        })
        .catch((err) => {
          console.log(err);
          props.setAddUserLinksModel(false);
        });
    }
  }

  const platformOptions = {
    name: 'platformName',
    label: 'Platform type',
    required: true,
    options: [
      'Leetcode',
      'Codeforces',
      'AtCoder',
      'HackerRank',
      'Topcoder',
      'Codechef',
      'Other',
    ],
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div>
      <ContentStyle>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            mb: 4,
          }}
        >
          <Typography variant='h4'>Add User Links</Typography>
          <Button onClick={() => props.setAddUserLinksModel(false)}>
            <Icon
              icon={CloseOutlined}
              width='18'
              height='18'
            />
          </Button>
        </Box>
        <form
          autoComplete='off'
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <Stack spacing={2}>
            <Stack spacing={2}>
              <Box>
                <SelectFieldComponent
                  field={platformOptions}
                  formData={formData}
                  handleChange={handleChange}
                  error={errors.platformName ? 'Platform name is required' : ''}
                />
              </Box>
              <Box>
                <TextField
                  fullWidth
                  name='platformLink'
                  label='Platform link'
                  sx={{ marginTop: '16px' }}
                  onChange={handleChange}
                  error={errors.platformLink}
                  helperText={
                    errors.platformLink && 'Platform link is required'
                  }
                />
              </Box>
            </Stack>
            <LoadingButton
              fullWidth
              size='large'
              type='submit'
              variant='contained'
              color='secondary'
            >
              Add User Link
            </LoadingButton>
          </Stack>
        </form>
      </ContentStyle>
    </div>
  );
};

export default AddUserLinks;

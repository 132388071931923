import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Paper,
} from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import NewResourcesTab from './NewResourcesTab';
import { ResourcesContent } from './ResourcesContent';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  category: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[1],
    borderRadius: theme.shape.borderRadius,
  },
  categoryTitle: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  listItem: {
    paddingLeft: theme.spacing(4),
  },
}));

const ResourcesTab = () => {
  const currentProgram = useSelector((state) => state.programs.currentProgram);
  const classes = useStyles();

  return (
    <>
      <NewResourcesTab />
    </>
  );
};

export default ResourcesTab;

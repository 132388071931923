import React, { useEffect, useRef, useState } from 'react';
import Calendar from 'react-calendar';
import { Box, Stack, Typography } from '@mui/material';
import 'react-calendar/dist/Calendar.css';
import './ProgressCalendar.css';
import axios from 'axios';
import { useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import { getUserId } from '../../configs/auth';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';
import { fetchLearnerDailyProgressURL, getHeaders } from '../../apis/urls';
import { getTileClassName, disablePrev2Button } from './ProgressCalendarUtils';
import ProgressBox from './ProgressBox';

const ProgressCalendar = () => {
  const currentProgram = useSelector((state) => state.programs.currentProgram);
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [studentDailyProgress, SetStudentDailyProgress] = useState([]);
  const calendarRef = useRef(null);

  useEffect(() => {
    axios
      .get(fetchLearnerDailyProgressURL(getUserId()), {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((res) => {
        SetStudentDailyProgress(res.data?.learner_progress);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(err.response?.data?.message || err?.message);
        setLoading(false);
      });

    return () => {};
  }, []);

  useEffect(() => {
    if (calendarRef.current) {
      disablePrev2Button(calendarRef.current);
    }
  }, [loading]);

  return (
    <div style={{ position: 'relative' }}>
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      {loading && (
        <Box
          sx={{
            position: 'absolute',
            top: '40%',
            left: '50%',
            marginTop: '200px',
            transform: 'translate(-50%, -50%)',
            zIndex: 9999,
          }}
        >
          <CircularProgress color='inherit' />
        </Box>
      )}
      {!loading && (
        <>
          <Calendar
            tileClassName={({ date }) =>
              getTileClassName(date, studentDailyProgress)
            }
            calendarType='US'
            next2Label={null}
            prev2Label='Your Progress'
            ref={calendarRef}
          />
          <Stack
            direction='row'
            justifyContent='space-between'
            width='340px'
            margin='auto'
            mt='10px'
          >
            <ProgressBox
              color='#f7bf57'
              label='Session'
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '2px',
                alignItems: 'center',
              }}
            >
              <Typography>Low</Typography>
              <ProgressBox color='#c4e6f1' />
              <ProgressBox color='#79cbec' />
              <ProgressBox color='#25a1e9' />
              <ProgressBox color='#0882c9' />
              <Typography>High</Typography>
            </Box>
          </Stack>
        </>
      )}
    </div>
  );
};

export default ProgressCalendar;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { TabContext } from '@material-ui/lab';
import { Box } from '@material-ui/system';
import { Outlet, useNavigate } from 'react-router-dom';
import './onboarding.css';
import { getUserType, getUserId } from '../../configs/auth';
import OnboardingMainTabLayout from './OnboardingMainTabLayout';
import OnboardingTabRender from './OnboardingTabrRender';

const OnboardingTabLayout = () => {
  const userType = getUserType();
  const navigate = useNavigate();
  const [value, setValue] = useState('0');
  const [subTabValue, setSubTabValue] = useState('0');
  const currentProgram = useSelector((state) => state.programs?.currentProgram);
  const programName = currentProgram?.programSlug;
  const [programSessions, setProgramSessions] = useState([]);
  const [lastSubmittedFormResponse, setLastSubmittedFormResponse] =
    useState(null);
  const [open, setOpen] = useState(false);
  const onClose = () => setOpen(false);
  const dispatch = useDispatch();

  return (
    <>
      <TabContext value={value}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            height: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              marginLeft: '250px',
            }}
          >
            <OnboardingMainTabLayout
              open={open}
              setOpen={setOpen}
              value={value}
              setValue={setValue}
              subTabValue={subTabValue}
              setSubTabValue={setSubTabValue}
            />
          </Box>
          <OnboardingTabRender
            value={value}
            subTabValue={subTabValue}
          />
        </Box>
      </TabContext>
    </>
  );
};

export default OnboardingTabLayout;

import { Button, Stack, TextField, Typography } from '@material-ui/core';
import { Box } from '@material-ui/system';
import axios from 'axios';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { getHeaders, postCodeRunURL } from '../../../apis/urls';
import ErrorNotifier from '../../ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../ToastNotifications/SuccessNotifier';
import CodeMirrorEditor from '../../CodeMirrorEditor/CodeMirrorEditor';
import ProgramNotStarted from '../../ProgramNotStarted';
import { LanguageSelect } from './LanguageSelect';
import { getSubmissionStatusForClient } from '../CodingTest/DetailedTest/CodingTestCodeEditor';

export default function CodeEditor() {
  const currentProgram = useSelector(state => state.programs.currentProgram);
  const [selectedLanguage, setSelectedLanguage] = useState({ 
    languageId: 54, 
    languageName: "C++ (GCC 9.2.0)" 
  }); // default language
  const [codeArea, setCodeArea] = useState("");
  const [stdin, setStdin] = useState("");
  const [stdout, setStdout] = useState("");
  const [hasError, setHasError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleCodeAreaChange = useCallback((value, viewUpdate=null) => {
    setCodeArea(value);
  }, []);

  if (!currentProgram?.hasProgramStarted) {
    return (
      <ProgramNotStarted />
    );
  }

  const handleCodeSubmit = (type) => {
    if (selectedLanguage.languageId === 0) {
      setHasError(true);
      setErrorMessage("Please select a language.")
      return;
    }

    const body = {
      language_id: selectedLanguage.languageId,
      source_code: codeArea,
      stdin: (stdin.length === 0 ? null : stdin)
    }

    const url = postCodeRunURL();
    
    (async() => {
      await setStdout("Submitting..")
    })();
      
    axios.post(url, body, {
      headers: getHeaders(currentProgram?.programId)
    })
    .then((res) => {
      const status = getSubmissionStatusForClient(res.data);
      console.log(status);
      setStdout(status.message);
      
      if (status?.verdict) {
        setIsSuccess(true);
        setSuccessMessage(res.data?.status?.description ?? "Submitted");
      } else {
        setHasError(true);
        setErrorMessage(res.data?.status?.description ?? "Submitted");
      }
    })
    .catch((err) => {
      console.log(err);
      setHasError(true);
      setErrorMessage(err.response?.data?.message || err.message);
      setStdout("Submitted.");
    })
  }

  const handleTestcaseChange = (event) => {
    setStdin(event.target.value);
  }

  return (
    <Box m={1}>
      {hasError && 
        <ErrorNotifier 
          message={errorMessage} 
          setHasError={setHasError} 
        /> 
      }
      {isSuccess && 
        <SuccessNotifier 
          message={successMessage} 
          setIsSuccess={setIsSuccess} 
        /> 
      }
      
      <Stack spacing={1}>
        <Box 
          display="flex" 
          justifyContent="flex-end" 
          alignItems="center"
          mb={1}
        >
          <LanguageSelect
            setSelectedLanguage={setSelectedLanguage}
            selectedLanguage={selectedLanguage}
          />

          <Button 
            variant="contained" 
            color="success" 
            sx={{ 
              width: "8rem", 
              height: 40, 
              mr: 1 
            }}
            onClick={() => handleCodeSubmit("run")}
          >
            Run
          </Button>
        </Box>

        <CodeMirrorEditor 
          content={codeArea}
          handleContentChange={handleCodeAreaChange}
          languageType={selectedLanguage.languageName}
        />

        <Stack direction="row" spacing={2}>
          <Stack>
            <Typography 
              variant="body2" 
              pl="1em"
            >
              Stdin
            </Typography>
            <TextField
              minRows="10"
              maxRows="10"
              multiline 
              defaultValue={stdin}
              style={{
                width: "35rem", 
                padding: 7
              }}
              onChange={handleTestcaseChange}
            />
          </Stack>

          <Stack>
            <Typography 
              variant="body2"
              pl="1em"
            >
              Stdout
            </Typography>
            <TextField
              minRows="10" 
              maxRows="10"
              multiline 
              defaultValue={stdout} 
              style={{
                width: "58rem", 
                padding: 7
              }}
              inputProps={{ readOnly: true }}
            />
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
}
// @ts-nocheck
import React, { useState } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Button,
  Box,
  CircularProgress,
} from '@mui/material';

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MultiProgramSelectionForBroadcastMessage({
  programs,
  selectedPrograms,
  setSelectedPrograms,
  loading,
}) {
  const [displayedChips, setDisplayedChips] = useState([]);

  const handleChange = (event) => {
    const selectedValues = event.target.value;
    setSelectedPrograms(selectedValues);
    updateDisplayedChips(selectedValues);
  };

  const updateDisplayedChips = (selectedValues) => {
    setDisplayedChips((prev) => {
      const internalNames = [];
      selectedValues.forEach((id) => {
        const index = programs.findIndex((p) => p.programId === id);
        if (index !== -1) {
          internalNames.push(programs[index].internal_name);
        }
      });

      return internalNames;
    });
  };

  const handleDeleteSelection = (index) => {
    const updatedSelected = selectedPrograms.filter(
      (value) => value !== selectedPrograms[index]
    );
    setSelectedPrograms(updatedSelected);
    updateDisplayedChips(updatedSelected);
  };

  const handleClearSelections = () => {
    setSelectedPrograms([]);
    setDisplayedChips([]);
  };

  return (
    <Box sx={{ textAlign: 'left' }}>
      <FormControl
        margin='normal'
        fullWidth
      >
        <InputLabel>Select Programs</InputLabel>
        <Select
          multiple
          value={selectedPrograms}
          onChange={handleChange}
          label='Select Programs'
          MenuProps={MenuProps}
        >
          {loading ? (
            <CircularProgress
              style={{
                marginLeft: '50%',
              }}
            />
          ) : (
            Object.values(programs).map((program) => (
              <MenuItem
                key={program.programId}
                value={program.programId}
              >
                {program.internal_name}
              </MenuItem>
            ))
          )}
        </Select>
      </FormControl>

      <Box sx={{ mt: 1 }}>
        {displayedChips.map((chipText, index) => (
          <Chip
            key={index}
            label={chipText}
            onDelete={() => handleDeleteSelection(index)}
          />
        ))}
      </Box>

      <Button
        variant='outlined'
        color='error'
        onClick={handleClearSelections}
        sx={{ mt: 2 }}
      >
        Clear Selection
      </Button>
    </Box>
  );
}

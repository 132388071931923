import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LoadingScreen from '../../pages/LoadingScreen';
import TermsAndConditionsAgreement from '../../components/terms-and-conditions/TermsAndConditionsAgreement';
import { fetchTermsAndConditions, getHeaders } from '../../apis/urls';
import { getUserType } from '../../configs/auth';

const isTnCRequired = (tnc) => {
  if (!tnc) {
    return false;
  }
  return tnc === 'pending';
};

export default function TermsAndCondtitionsLayout({
  handleSubmit,
  isAnyAnswerEmpty,
}) {
  const [pdfBytes, setPdfBytes] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const userType = getUserType();

  useEffect(() => {
    axios
      .get(fetchTermsAndConditions(), {
        headers: getHeaders(),
      })
      .then((res) => {
        const showTnC =
          userType !== 'visitor' ||
          !isTnCRequired(res?.data?.terms_and_conditions_agreed);
        if (showTnC) {
          navigate(`/eligibility-forms`);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    async function fetchPdf() {
      try {
        const response = await fetch(
          '/static/HeyCoach_-_Terms_and_conditions.pdf'
        );
        const pdfData = await response.arrayBuffer();
        setPdfBytes(pdfData);
      } catch (error) {
        console.error('Error fetching PDF:', error);
      }
    }
    fetchPdf();
  }, []);

  return (
    <div>
      <LoadingScreen loading={loading} />
      <TermsAndConditionsAgreement
        handleSubmit={handleSubmit}
        isAnyAnswerEmpty={isAnyAnswerEmpty}
        pdfBytes={pdfBytes}
        setPdfBytes={setPdfBytes}
        setLoading={setLoading}
      />
    </div>
  );
}

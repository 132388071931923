import { Box, Button, Card, CardContent, Modal, Typography } from '@material-ui/core'
import React from 'react'

const SubmitModal = ({
    open,
    handleClose,
    file,
    setfile,
    submit
}) => {
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Card sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                }}>
                    <CardContent>
                        <Typography id="modal-modal-title" variant="h4" component="h2">
                            Upload Your Submission
                        </Typography>
                        <Box component="div" style={{
                            display: "flex",
                            padding: 20,
                            alignItems: "center"
                        }}>
                            <Button
                                variant="outlined"
                                component="label"
                            >
                                Upload File
                                <input
                                    type="file"
                                    hidden
                                    onChange={(e) => setfile(e.target.files[0])}
                                    required
                                />

                            </Button>
                            <Typography variant="body2" component="h5"
                                style={{
                                    marginLeft: 4
                                }}
                            >
                                {
                                    file ? file?.name : "no file selected"
                                }
                            </Typography>
                        </Box>
                        <Button
                            variant="contained"
                            component="label"
                            style={{
                                width: "100%"
                            }}
                            onClick={submit}
                        >
                            Submit
                        </Button>
                    </CardContent>
                    <Typography variant="body2" component="h5"
                        style={{
                            marginLeft: 20
                        }}
                    >
                        FILE SIZE UPTO 3MB
                    </Typography>
                </Card>
            </Modal>
        </div>
    )
}

export default SubmitModal
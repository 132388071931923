import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

const initialState = {
  sessions: [],
  pre_reads: [],
  coding_assignments: [],
  session_tests: [],
  session_recordings: [],
  loading: false,
};

export const sessionSlice = createSlice({
  name: 'sessions',
  initialState,
  reducers: {
    unsetSessions: (state) => {
      state.sessions = [];
      state.pre_reads = [];
      state.coding_assignments = [];
      state.session_tests = [];
      state.session_recordings = [];
    },

    setSessions: (state, action) => {
      state.sessions = action.payload.sessions.map((session, index) => {
        return {
          index,
          title: session.title,
          id: session.id,
          duration: session.duration,
          topic_id: session.topic_id,
          group_name: session.group_name,
          status: session.status,
          description: session.description,
          session_type: session.session_type,
          verified_by: session.verified_by,
          flow: session.flow,
          learner_feedback: session.learner_feedback,
          coach: session.coach,
          start: session.start_time,
          end: session.end_time,
          googleEventId: session.google_event_id,
          recording_uploaded: session?.recording_uploaded ?? false,
        };
      });
      state.pre_reads = action.payload.pre_reads?.filter(
        (pre_read) => pre_read.resource_context === 'pre_read'
      );
      state.session_recordings = action.payload.pre_reads?.filter(
        (resource) => resource.resource_context == 'session_recording'
      );
      state.coding_assignments = action.payload.coding_assignments;
      state.session_tests = action.payload.session_tests?.map((test) => {
        const startTime = moment(test.start_time);
        const endTime = moment(test.end_time);
        const startedAt = moment(test.started_at);

        return {
          testId: test.id,
          name: test.name,
          hasJoined: test.has_joined,
          hasSubmitted: test.has_submitted,
          isLateAttempt: test.is_late_attempt,
          hasCleared: test.has_cleared,
          duration: test.duration,
          startedAt,
          endTime,
          startTime,
        };
      });
      state.loading = action.payload.loading;
    },

    updateSession: (state, action) => {
      const index = state.sessions.findIndex(
        (session) => session.id === action.payload.id
      );
      if (index !== -1) {
        return;
      }

      state.sessions[index] = {
        ...state.sessions[index],
        title: action.payload.title,
        id: action.payload.id,
        duration: action.payload.duration,
        topic_id: action.payload.topic_id,
        group_name: action.payload.group_name,
        status: action.payload.status,
        session_type: action.payload.session_type,
        verified_by: action.payload.verified_by,
        flow: action.payload.flow,
        learner_feedback: action.payload.learner_feedback,
        coach: action.payload.coach,
        start: action.payload.start_time,
        end: action.payload.end_time,
        googleEventId: action.payload.google_event_id,
      };
    },

    addSessions: (state, action) => {
      const sessions = !Array.isArray(action.payload)
        ? [action.payload]
        : action.payload;

      const newSessions = sessions.map((session) => {
        return {
          title: session.title,
          id: session.id,
          duration: session.duration,
          topic_id: session.topic_id,
          group_name: session.group_name,
          status: session.status,
          session_type: session.session_type,
          verified_by: session.verified_by,
          flow: session.flow,
          learner_feedback: session.learner_feedback,
          coach: session.coach,
          start: session.start_time,
          end: session.end_time,
          googleEventId: session.google_event_id,
        };
      });

      state.sessions.push(...newSessions);
    },

    markPreReadAsComplete: (state, action) => {
      const { id, completed } = action.payload;

      state.pre_reads.forEach((pre_read) => {
        if (pre_read.id === id) pre_read.completed = completed;
      });
    },
  },
});

export const {
  setSessions,
  updateSession,
  addSessions,
  unsetSessions,
  markPreReadAsComplete,
} = sessionSlice.actions;

export default sessionSlice.reducer;

import React, { useCallback, useState } from 'react';
import CodeMirror from '@uiw/react-codemirror';
import { autocompletion } from '@codemirror/autocomplete';
import { cpp, cppLanguage } from '@codemirror/lang-cpp';
import { java, javaLanguage } from '@codemirror/lang-java';
import { python, pythonLanguage } from '@codemirror/lang-python';
import {
  javascript,
  javascriptLanguage,
  jsxLanguage,
} from '@codemirror/lang-javascript';
import { csharp } from '@replit/codemirror-lang-csharp';

// link to doc: https://uiwjs.github.io/react-codemirror/

const basicSetupOptions = {
  lineNumbers: true,
  highlightActiveLineGutter: true,
  highlightSpecialChars: true,
  allowMultipleSelections: true,
  syntaxHighlighting: true,
  bracketMatching: true,
  autoCompletion: true,
  closeBrackets: true,
  highlightActiveLine: true,
  highlightSelectionMatches: true,
  completionKeymap: true,
  lintKeymap: true,
  searchKeymap: true,
};

function getLanguageExtension(language) {
  if (language === 'C++ (GCC 9.2.0)') {
    return cpp;
  }
  if (language === 'Java (OpenJDK 13.0.1)') {
    return java;
  }
  if (language === 'Python (3.8.1)') {
    return python;
  }
  if (language === 'JavaScript (Node.js 12.14.0)') {
    return javascript;
  }
  if (language === 'C# (Mono 6.6.0.161)') {
    return csharp;
  }
}

export default function CodeMirrorEditor({
  content,
  languageType = 'C++ (GCC 9.2.0)',
  handleContentChange,
  options = {},
}) {
  return (
    <CodeMirror
      value={content}
      height='85vh'
      placeholder='Enter your code here'
      autoFocus
      theme='dark'
      basicSetup={basicSetupOptions}
      extensions={[autocompletion(), getLanguageExtension(languageType)()]}
      onChange={handleContentChange}
      {...options}
    />
  );
}

// @ts-nocheck
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Box, Stack } from '@mui/material';
import {
  fetchChatUsersUrl,
  getHeaders,
  sendMessageLearnersUrl,
} from '../../../apis/urls';
import LearnerDropdown from './LearnerDropdown';
import SendMessageConfirmation from './SendMessageConfirmation';
import SingleProgramSelectionDropdown from './SingleProgramSelectionDropdown';

export default function BroadcastMessageToLearners({
  message,
  programs,
  setHasError,
  setErrorMessage,
  setIsSuccess,
  setSuccessMessage,
  handleClose,
  notifyByEmail,
}) {
  const [learners, setLearners] = useState([]);
  const [programId, setProgramId] = useState(null);
  const [selectedLearners, setSelectedLearners] = useState([]);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  useEffect(() => {
    if (programId) {
      fetchChatUsers();
    }
    return () => {};
  }, [programId]);

  function fetchChatUsers() {
    axios
      .get(fetchChatUsersUrl(), {
        headers: getHeaders(programId),
      })
      .then((res) => {
        setLearners(res?.data);
      })
      .catch((e) => {
        console.log(e);
        setHasError(true);
        setErrorMessage(
          e.response?.data?.message ||
            'An error occurred while fetching the learners'
        );
      });
  }

  const handleSendMessage = () => {
    const body = {
      message,
      notify_by_email: notifyByEmail,
      data: selectedLearners.map(({ chat_id, user_id }) => ({
        chat_id,
        user_id,
      })),
    };

    axios
      .post(sendMessageLearnersUrl(), body, {
        headers: getHeaders(programId),
      })
      .then((res) => {
        setIsSuccess(true);
        setSuccessMessage('Message successfully sent to learners');
      })
      .catch((e) => {
        console.log(e);
        setHasError(true);
        setErrorMessage(
          e.response?.data?.message ||
            'An error occurred while broadcasting messages to the learners.'
        );
      });
    handleClose();
  };

  return (
    <Stack
      spacing={2}
      m={2}
    >
      <SingleProgramSelectionDropdown
        programs={programs}
        onChangeHandler={(e, v) => setProgramId(v.value)}
        size='small'
      />
      <LearnerDropdown
        learners={learners}
        selectedLearners={selectedLearners}
        setSelectedLearners={setSelectedLearners}
      />
      <Box
        sx={{
          textAlign: 'center',
          mt: 2,
        }}
      >
        <Button
          disabled={!programId || !message || selectedLearners.length === 0}
          variant='contained'
          onClick={() => setOpenConfirmationDialog(true)}
        >
          Send as DM
        </Button>
      </Box>
      {openConfirmationDialog && (
        <SendMessageConfirmation
          openConfirmationDialog={openConfirmationDialog}
          setOpenConfirmationDialog={setOpenConfirmationDialog}
          sendMessage={handleSendMessage}
        />
      )}
    </Stack>
  );
}

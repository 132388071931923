// @ts-nocheck
import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box } from '@material-ui/core';
import moment from 'moment';

const timeFormat = 'MMM DD, YYYY, hh:mm A';

const CodingAssignmentsTable = ({
  assignments,
  loading,
  setSelectedAssignment,
  filters,
}) => {
  const columns = [
    {
      field: 'id',
      headerName: '#',
      type: 'number',
      width: 70,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'title',
      headerName: 'Title',
      width: 400,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => params.row.problem_name,
    },
    {
      field: 'deadline',
      headerName: 'Deadline',
      width: 250,
      align: 'center',
      headerAlign: 'center',
      type: 'dateTime',
      valueGetter: (params) =>
        params.row?.deadline
          ? moment(params.row.deadline).format(timeFormat)
          : 'No deadline',
    },
    {
      field: 'score',
      headerName: 'Score',
      width: 250,
      align: 'center',
      headerAlign: 'center',
      type: 'dateTime',
      valueGetter: (params) => {
        const { max_score, obtained_score } =
          params.row?.recent_submission ?? {};
        return `${obtained_score ?? 0} / ${max_score}`;
      },
    },
    {
      field: 'submitted_on',
      headerName: 'Submitted on',
      width: 250,
      align: 'center',
      headerAlign: 'center',
      type: 'dateTime',
      valueGetter: (params) => {
        const { created_at = null } = params.row?.recent_submission ?? {};
        return created_at ? moment(created_at).format(timeFormat) : '-';
      },
    },
  ];

  const handleRowClick = (params, event) => {
    setSelectedAssignment(params.row);
  };

  return (
    <Box
      sx={{
        width: '70vw',
        height: '100%',
      }}
    >
      <DataGrid
        columnVisibilityModel={{
          score: filters.status !== 'pending',
          submitted_on: filters.status !== 'pending',
        }}
        rows={assignments}
        columns={columns}
        pageSize={100}
        rowsPerPageOptions={[50, 100]}
        autoHeight
        disableSelectionOnClick
        onRowClick={handleRowClick}
        loading={loading}
        sx={{
          '& .MuiDataGrid-row:hover': {
            backgroundColor: '#e3f2fd',
            cursor: 'pointer',
          },
        }}
      />
    </Box>
  );
};

export default CodingAssignmentsTable;

import React, { useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import {
  TextField,
  FormControl,
  Select,
  Box,
  MenuItem,
  InputLabel,
  Button,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import axios from 'axios';
import tw from 'twin.macro';
import { fetchResourcesUrl, getHeaders } from '../../../apis/urls';
import { SessionsListContext } from '../../../configs/SessionsListContext';
import { getUserType } from '../../../configs/auth';
import TopicsDropdown from '../../../pages/TopicsDropdown';

const H5 = tw.h5`text-sm text-black ml-5`;

function AddResourceForm({
  setResources,
  setOpen,
  setIsSuccess,
  setHasError,
  setErrorMessage,
  setSuccessMessage,
  receivedSessionId = '',
}) {
  const [title, setTitle] = useState('');
  const [type, setType] = useState('file');
  const [file, setFile] = useState('');
  const [isSessionRecording, setSessionRecording] = useState(false);
  const [resourceLink, setResourceLink] = useState('');
  const [selectedSession, setSelectedSession] = useState('');
  const [selectedTopicIds, setSelectedTopicIds] = useState([]);
  const userType = getUserType();
  const currentProgram = useSelector((state) => state.programs.currentProgram);
  const sessions = useSelector((state) => state.sessions.sessions);

  const handleSessionSelection = (event) => {
    setSelectedSession(event.target.value);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    let body;

    if (file) {
      body = new FormData();
      body.append('title', title);
      body.append('resource_file', file);
      body.append('resource_type', 'file');
      body.append(
        'session_id',
        receivedSessionId === '' ? selectedSession : receivedSessionId
      );
      body.append(
        'topic_ids[]',
        selectedTopicIds.map((t) => t.toString()).join(',')
      );
      body.append('is_session_recording', isSessionRecording);
    } else {
      body = {
        title,
        topic_ids: selectedTopicIds,
        resource_type: type,
        resource_link: resourceLink,
        session_id:
          receivedSessionId === '' ? selectedSession : receivedSessionId,
        is_session_recording: isSessionRecording,
      };
    }

    axios
      .post(fetchResourcesUrl(), body, {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((res) => {
        setIsSuccess(true);
        setSuccessMessage('Resource successfully created');
        setOpen(false);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(err.response?.data?.message || err?.message);
      });
  };

  function getResourceLinkTypeField() {
    return (
      <>
        {type === 'file' && (
          <input
            placeholder='File'
            type='file'
            onChange={(e) => setFile(e.target.files[0])}
          />
        )}
        {type !== 'file' && (
          <TextField
            type='url'
            name='link'
            placeholder='Add your Link'
            value={resourceLink}
            onChange={(e) => setResourceLink(e.target.value)}
          />
        )}
      </>
    );
  }

  return (
    <>
      <form onSubmit={submitHandler}>
        <TextField
          fullWidth
          id='title'
          label='Title'
          variant='outlined'
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
        />
        {receivedSessionId === '' && (
          <FormControl fullWidth>
            <InputLabel id='session-select'>Select session</InputLabel>
            <Select
              sx={{ marginTop: '1rem' }}
              labelId='session-select'
              onChange={handleSessionSelection}
              label='Sessions'
              value={selectedSession}
              required
            >
              {sessions &&
                sessions.map((session, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={session.id}
                    >
                      {session.title}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        )}
        <Box
          display='flex'
          alignItems='center'
          marginTop='1rem'
        >
          <FormControl sx={{ width: 150, marginRight: '1rem' }}>
            <Select
              value={type}
              onChange={(e) => setType(e.target.value)}
              id='type'
              label='Type'
              placeholder='Type'
            >
              <MenuItem value='file'>File</MenuItem>
              <MenuItem value='link'>Link</MenuItem>
            </Select>
          </FormControl>
          {getResourceLinkTypeField()}
        </Box>
        {type === 'link' && (
          <FormControlLabel
            control={
              <Checkbox
                checked={isSessionRecording}
                onChange={(e) => setSessionRecording(e.target.checked)}
                name='sessionRecording'
                color='primary'
              />
            }
            label='Is this a session recording?'
            sx={{ marginTop: '1rem' }}
          />
        )}
        {currentProgram && (
          <TopicsDropdown
            {...{
              selectedTopicIds,
              setSelectedTopicIds,
              setErrorMessage,
              setHasError,
              sx: { m: 1, minWidth: 120 },
            }}
          />
        )}
        <Button
          sx={{ marginTop: '1rem' }}
          type='submit'
          variant='contained'
          color='secondary'
        >
          Add resource
        </Button>
      </form>
    </>
  );
}

export default AddResourceForm;

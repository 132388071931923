// @ts-nocheck
import { Stack, Typography, useTheme } from '@mui/material';
import React from 'react';
import QuestionDescription from './QuestionDescription';

const options = ['< 1 hour', '1-2 hours', '3-4 hours', '5+ hours'];

const DailyHoursCommitment = ({
  id,
  response,
  setResponse,
  setShouldAllowNext,
}) => {
  const theme = useTheme();

  const handleClick = (value) => {
    if (response[id] === value) {
      setResponse((prev) => {
        const x = { ...prev };
        delete x[id];
        return x;
      });
      setShouldAllowNext(false);
    } else {
      setResponse((prev) => ({ ...prev, [id]: value }));
      setShouldAllowNext(true);
    }
  };

  const shouldHighlight = (val) => {
    return response[id] && response[id] === val;
  };

  return (
    <Stack spacing={2}>
      <>
        <QuestionDescription title='How many hours can you commit to learning daily?' />
        <Typography
          variant='body1'
          component='p'
          sx={{
            fontWeight: 400,
            fontFamily: 'Inter',
          }}
        >
          <i>(single-select)</i>
        </Typography>
      </>

      <Stack spacing={1}>
        {options.map((opt, index) => {
          return (
            <Typography
              variant='body1'
              component='p'
              sx={{
                borderRadius: '10px',
                padding: '5px',
                background: 'white',
                width: 'fit-content',
                cursor: 'pointer',
                fontSize: '1.2rem',
                fontFamily: 'Inter',
                ...(shouldHighlight(opt) && {
                  border: '2px solid blue',
                }),
                [theme.breakpoints.down('sm')]: {
                  fontSize: '0.9rem',
                },
              }}
              onClick={() => handleClick(opt)}
            >
              {String.fromCharCode(65 + index)}. &nbsp; {opt}
            </Typography>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default DailyHoursCommitment;

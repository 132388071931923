// @ts-nocheck
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import axios from 'axios';
import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { BASE_URL, getHeaders, postLevelTest } from '../../../../apis/urls';

const NewLevelTestModal = ({ open, handleClose, setToastNotification }) => {
  const currentProgram = useSelector((state) => state.programs.currentProgram);
  const [filters, setFilters] = useState({
    start_time: '',
  });

  const handleCreateTest = (e) => {
    const body = {
      ...filters,
    };

    axios
      .post(`${BASE_URL}/api/level_tests`, body, {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((res) => {
        setToastNotification(
          'success',
          res.data?.message ?? 'Test successfully created.'
        );
      })
      .catch((err) => {
        console.log(err);
        setToastNotification(
          'error',
          err?.response?.data?.message ||
            'An error occured while creating the test.'
        );
      })
      .finally(() => {
        handleClose();
      });
  };

  const handleFieldUpdate = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth='xs'
      fullWidth
    >
      <DialogTitle>TGP Test</DialogTitle>
      <DialogContent>
        <TextField
          type='date'
          InputLabelProps={{ shrink: true }}
          label='Start time'
          name='start_time'
          value={filters.start_time}
          onChange={handleFieldUpdate}
          required
        />
      </DialogContent>

      <DialogActions>
        <Button
          onClick={handleCreateTest}
          variant='contained'
        >
          Create Test
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewLevelTestModal;

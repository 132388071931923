import { Card, CardContent, Typography, Box, Button, Stack, Tooltip, Modal, Grid, Chip } from '@material-ui/core';
import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Icon } from '@iconify/react';
import { styled } from '@material-ui/core/styles';
import DeleteOutlined from '@iconify/icons-ant-design/delete-outlined';
import { format } from 'date-fns';
import { DisplayLine } from '../../performance/Assignment/DetailedAssign';
import { AssignmentContext } from '../../../configs/AssignmentContext';
import ModalCreateAssignment from './ModalCreateAssignment';
import { fetchAssignmentUrl, getHeaders } from '../../../apis/urls';
import AssignmentCompletion from './AssignmentCompletion';
import ErrorNotifier from '../../ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../ToastNotifications/SuccessNotifier';

const DeleteButton = styled('div')(() => ({ color: 'red' }));

const CardAssignment = ({ index }) => {
  const [editAssignmentModalOpen, setEditAssignmentModalOpen] = useState(false);
  const [assignments, setAssignments] = useContext(AssignmentContext);
  const [topicName, setTopicName] = useState('');
  const [topics, setTopics] = useState({});
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [showModalAssignment, setShowModalAssignment] = useState(false);
  const currentProgram = useSelector(state => state.programs.currentProgram);
  const storedTopics = useSelector(state => state.topics.topics);
  const toggleAssignmentModal = () => setShowModalAssignment(!showModalAssignment);

  useEffect(() => {
    storedTopics.forEach((val) => {
      topics[val.id] = val.name;
    });
    setTopics(topics);
    setTopicName(topics[assignments[index].topic_id]);
  }, [topics, assignments, index, currentProgram]);

  const handleDeleteClick = (e) => {
    e.stopPropagation();
    handleDelete(e);
  };

  const handleDelete = (e) => {
    e.preventDefault();
    axios.delete(fetchAssignmentUrl(assignments[index].id), { 
      headers: getHeaders(currentProgram.programId) 
    })
    .then((response) => {
      const removeAssignment = assignments.filter(
        (assignment) => assignment?.id !== assignments[index]?.id
      );
      setAssignments(removeAssignment);
      setIsSuccess(true);
      setSuccessMessage("Assignment successfully deleted.")
    })
    .catch((e) => {
      console.log(e);
      setHasError(true);
      setErrorMessage(e.response?.data?.message || e.message);
    });
  };

  return (
    <>
      {hasError && <ErrorNotifier message={errorMessage} setHasError={setHasError} /> }
      {isSuccess && <SuccessNotifier message={successMessage} setIsSuccess={setIsSuccess} /> }
      <Card>
        <CardContent>
          <Stack direction="column-reverse" alignItems="flex-end" sx={{ float: 'right' }}>
            <Tooltip title="Delete" aria-label="delete" style={{ float: 'right' }}>
              <DeleteButton onClick={(e) => handleDeleteClick(e)} sx={{cursor: "pointer"}}>
                <Icon icon={DeleteOutlined} width="18" height="18" />
              </DeleteButton>
            </Tooltip>

            <Typography
              component="p"
              variant="subtitle2"
              color="textSecondary"
              gutterBottom
            >
              {assignments && assignments[index].created_at
              ? format(new Date(assignments[index].created_at), 'do MMMM yyyy')
              : ''
              }
            </Typography>
          </Stack>
          <Typography variant="h5" component="h2">
            {assignments[index].title}
          </Typography>
          <Typography color="textSecondary" component="h5">
            {topicName}
          </Typography>

          <Box width="70%">
            {assignments[index].description &&
              String(assignments[index].description).split('\n').map((val, i) => {
                return (
                  <DisplayLine line={val} index={i} />
                );
            })}
          </Box>
          <div
            style={{
              width: '100%',
              marginTop: '10px'
            }}
          >
            <Box display="flex" justifyContent="space-between">
              <Tooltip title="Deadline" aria-label="deadline">
                <Chip
                  label={assignments && assignments[index].deadline
                  ? format(new Date(assignments[index].deadline), 'do MMMM yyyy')
                  : ''
                  }
                  size="small"
                  color="error"
                  variant="outlined"
                />
              </Tooltip>
              <div>
                <Tooltip title="Status" aria-label="edit">
                  <Button
                    onClick={() => setShowModalAssignment(true)}
                    variant="contained"
                    color="secondary"
                    size="small"
                  >
                    View Student Submissions
                  </Button>
                </Tooltip>
                <Tooltip title="Edit" aria-label="edit">
                  <Button
                    sx={{ marginLeft: '10px' }}
                    onClick={() => setEditAssignmentModalOpen(!editAssignmentModalOpen)}
                    variant="contained"
                    color="secondary"
                    size="small"
                  >
                    Edit
                  </Button>
                </Tooltip>
              </div>
            </Box>
          </div>
        </CardContent>
        {editAssignmentModalOpen && (
          <ModalCreateAssignment
            open={editAssignmentModalOpen}
            handleClose={setEditAssignmentModalOpen}
            titleLabel="Edit Assignment"
            buttonLabel="Update Assignment"
            operationType="update"
            index={index}
          />
        )}
        {showModalAssignment && (
          <Modal open={showModalAssignment} onClose={() => setShowModalAssignment(false)}>
            <AssignmentCompletion id={assignments[index].id} handleClose={toggleAssignmentModal} />
          </Modal>
        )}
      </Card>
    </>
  );
};

export default CardAssignment;

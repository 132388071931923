// @ts-nocheck
import {
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  Box,
  Grid,
} from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import React, { useEffect, useState } from 'react';
import Img1 from '../../../../assets/group_195714.png';
import Img2 from '../../../../assets/group_195713.png';
import Img3 from '../../../../assets/group_195715.png';
import { useFastrackPipeline } from 'src/layouts/EfPipelineContext/FastrackPipelineContex';

const RightDashedArrow = () => {
  return (
    <svg
      width='66'
      height='30'
      viewBox='0 0 66 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M65.4142 16.4142C66.1953 15.6332 66.1953 14.3668 65.4142 13.5858L52.6863 0.857864C51.9052 0.0768156 50.6389 0.0768156 49.8579 0.857864C49.0768 1.63891 49.0768 2.90524 49.8579 3.68629L61.1716 15L49.8579 26.3137C49.0768 27.0948 49.0768 28.3611 49.8579 29.1421C50.6389 29.9232 51.9052 29.9232 52.6863 29.1421L65.4142 16.4142ZM0 17H4V13H0V17ZM12 17H20V13H12V17ZM28 17H36V13H28V17ZM44 17H52V13H44V17ZM60 17H64V13H60V17ZM65.4142 16.4142C66.1953 15.6332 66.1953 14.3668 65.4142 13.5858L52.6863 0.857864C51.9052 0.0768156 50.6389 0.0768156 49.8579 0.857864C49.0768 1.63891 49.0768 2.90524 49.8579 3.68629L61.1716 15L49.8579 26.3137C49.0768 27.0948 49.0768 28.3611 49.8579 29.1421C50.6389 29.9232 51.9052 29.9232 52.6863 29.1421L65.4142 16.4142ZM0 17H4V13H0V17ZM12 17H20V13H12V17ZM28 17H36V13H28V17ZM44 17H52V13H44V17ZM60 17H64V13H60V17Z'
        fill='black'
      />
    </svg>
  );
};

const DownDashedArrow = () => {
  return (
    <svg
      width='16'
      height='19'
      viewBox='0 0 16 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.29289 18.7071C7.68342 19.0976 8.31658 19.0976 8.70711 18.7071L15.0711 12.3431C15.4616 11.9526 15.4616 11.3195 15.0711 10.9289C14.6805 10.5384 14.0474 10.5384 13.6569 10.9289L8 16.5858L2.34315 10.9289C1.95262 10.5384 1.31946 10.5384 0.928933 10.9289C0.538408 11.3195 0.538408 11.9526 0.928933 12.3431L7.29289 18.7071ZM7 4.37114e-08L7 0.9L9 0.9L9 -4.37114e-08L7 4.37114e-08ZM7 2.7L7 4.5L9 4.5L9 2.7L7 2.7ZM7 6.3L7 8.1L9 8.1L9 6.3L7 6.3ZM7 9.9L7 11.7L9 11.7L9 9.9L7 9.9ZM7 13.5L7 15.3L9 15.3L9 13.5L7 13.5ZM7 17.1L7 18L9 18L9 17.1L7 17.1ZM7.29289 18.7071C7.68342 19.0976 8.31658 19.0976 8.70711 18.7071L15.0711 12.3431C15.4616 11.9526 15.4616 11.3195 15.0711 10.9289C14.6805 10.5384 14.0474 10.5384 13.6569 10.9289L8 16.5858L2.34315 10.9289C1.95262 10.5384 1.31946 10.5384 0.928933 10.9289C0.538408 11.3195 0.538408 11.9526 0.928933 12.3431L7.29289 18.7071ZM7 4.37114e-08L7 0.9L9 0.9L9 -4.37114e-08L7 4.37114e-08ZM7 2.7L7 4.5L9 4.5L9 2.7L7 2.7ZM7 6.3L7 8.1L9 8.1L9 6.3L7 6.3ZM7 9.9L7 11.7L9 11.7L9 9.9L7 9.9ZM7 13.5L7 15.3L9 15.3L9 13.5L7 13.5ZM7 17.1L7 18L9 18L9 17.1L7 17.1Z'
        fill='black'
      />
    </svg>
  );
};

const OneToOnePastInterviewMetrics = ({
  setFlowData,
  flowData,
  saveFlowData,
}) => {
  const { screenSource, setScreenSource, trackButtonClick } =
    useFastrackPipeline();
  const theme = useTheme();
  const isBelowLarge = useMediaQuery(theme.breakpoints.down('lg'));

  const handleContinue = () => {
    trackButtonClick({
      source: 'clearing_interview_3',
      eventName: 'continue',
    });
    saveFlowData({
      screen: 'clearing_interview_3',
      response: { continue: true },
    });
    setScreenSource('clearing_interview_4');
  };

  return (
    <Stack
      spacing={3}
      width='100%'
    >
      <Stack
        spacing={1}
        direction={isBelowLarge ? 'column' : 'row'}
        justifyContent='center'
        alignItems='center'
      >
        <Box
          sx={{
            maxWidth: isBelowLarge ? 'clamp(20rem, 22rem, 25rem)' : '32rem',
          }}
        >
          <img src={Img1} />
        </Box>

        {isBelowLarge ? <DownDashedArrow /> : <RightDashedArrow />}
        <Box
          sx={{
            maxWidth: isBelowLarge ? 'clamp(20rem, 22rem, 25rem)' : '32rem',
          }}
        >
          <img src={Img2} />
        </Box>

        {isBelowLarge ? <DownDashedArrow /> : <RightDashedArrow />}

        <Box
          sx={{
            maxWidth: isBelowLarge ? 'clamp(20rem, 22rem, 25rem)' : '32rem',
          }}
        >
          <img src={Img3} />
        </Box>
      </Stack>

      <Typography
        variant='body1'
        component='p'
        sx={{
          textAlign: 'center',
          fontFamily: 'Inter',
          fontWeight: 'normal',
          fontSize: {
            xs: '1.1rem',
            sm: '1.2rem',
            md: '1.2rem',
            lg: '1.3rem',
          },
          textWrap: 'pretty',
          paddingBottom: '1rem',
        }}
      >
        Now, let’s take a look at the results Arun achieved following this
      </Typography>

      <Button
        sx={{
          width: 'unset',
          padding: '0.5rem 1.5rem',
          textTransform: 'none',
          fontFamily: 'Inter',
          fontSize: '1rem',
          fontWeight: 650,
          '&:hover': {
            color: 'white',
            backgroundColor: '#1976d2',
          },
          color: 'white',
          backgroundColor: '#1976d2',
          alignSelf: 'center',
        }}
        endIcon={<EastIcon />}
        onClick={handleContinue}
      >
        Continue
      </Button>
    </Stack>
  );
};

export default OneToOnePastInterviewMetrics;

const session_status = {
  CREATED: 'created',
  CONDUCTED: 'conducted',
  VERIFIED: 'verified',
  PAYMENT_PROCESSED: 'payment_processed'
};

module.exports = {
  session_status
};

// @ts-nocheck
import {
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  Box,
  Grid,
} from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import React, { useEffect, useState } from 'react';
import Step3 from '../../../../assets/group_195696.png';
import Step2 from '../../../../assets/group_195697.png';
import Step1 from '../../../../assets/group_195698.png';
import { useFastrackPipeline } from 'src/layouts/EfPipelineContext/FastrackPipelineContex';

const OneToOnePastInterviewAnalysis = ({
  setFlowData,
  flowData,
  saveFlowData,
}) => {
  const { screenSource, setScreenSource, trackButtonClick } =
    useFastrackPipeline();
  const theme = useTheme();
  const isBelowMedium = useMediaQuery('@media(max-width: 900px)');

  const handleNext = () => {
    trackButtonClick({
      source: 'clearing_interview_2',
      eventName: 'next',
    });
    saveFlowData({
      screen: 'clearing_interview_2',
      response: { next: true },
    });
    setScreenSource('clearing_interview_3');
  };

  return (
    <Stack
      direction='row'
      justifyContent='space-around'
      minHeight='30rem'
      width='100%'
      paddingLeft={isBelowMedium ? 0 : '2rem'}
      spacing={5}
    >
      <Stack
        spacing={3}
        width='fit-content'
        sx={{
          display: isBelowMedium && 'none',
        }}
        alignItems='center'
      >
        <Box
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            width: '80%',
            height: {
              sm: '30%',
              md: '70%',
              lg: '60%',
              xl: '50%',
            },
            '@media (min-width:900px) and (max-width:1000px)': {
              height: '50%',
            },
          }}
        >
          <img
            src={Step1}
            alt='interview'
          />
        </Box>

        <Box
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            width: '65%',

            height: {
              sm: '29%',
              md: '50%',
              lg: '60%',
              xl: '40%',
            },
            '@media (min-width:900px) and (max-width:1000px)': {
              height: '40%',
            },
          }}
        >
          <img
            src={Step2}
            alt='shortlist'
          />
        </Box>

        <Box
          sx={{
            width: '55%',
            height: {
              sm: '18%',
              md: '50%',
              lg: '40%',
              xl: '35%',
            },
            '@media (min-width:900px) and (max-width:1000px)': {
              height: '30%',
            },
          }}
        >
          <img
            src={Step3}
            alt='offer'
          />
        </Box>
      </Stack>
      <Stack
        justifyContent='space-around'
        height='100%'
        width='inherit'
      >
        <Stack
          spacing={4}
          width='inherit'
        >
          <Typography
            variant='body1'
            component='p'
            sx={{
              textAlign: isBelowMedium ? 'center' : 'left',
              fontFamily: 'Inter',
              fontWeight: 'normal',
              fontSize: {
                xs: '1.1rem',
                sm: '1.2rem',
                md: '1.2rem',
                lg: '1.3rem',
              },
              textWrap: 'pretty',
              paddingBottom: '1rem',
            }}
          >
            Analysing Arun's past interviews performance{' '}
            <b>
              revealed that his lack of confidence and interview nervousness
              held him back during face-to-face rounds, leading to frequent
              rejections.
            </b>
          </Typography>

          <Typography
            variant='body1'
            component='p'
            sx={{
              textAlign: isBelowMedium ? 'center' : 'left',
              fontFamily: 'Inter',
              fontWeight: 'normal',
              fontSize: {
                xs: '1.1rem',
                sm: '1.2rem',
                md: '1.2rem',
                lg: '1.3rem',
              },
              textWrap: 'pretty',
              paddingBottom: '1rem',
            }}
          >
            To tackle this, the placement support team worked on boosting his
            confidence and reducing nervousness through mock interview sessions
          </Typography>
        </Stack>

        <Button
          sx={{
            width: 'unset',
            padding: '0.5rem 1.5rem',
            textTransform: 'none',
            fontFamily: 'Inter',
            fontSize: '1rem',
            fontWeight: 650,
            '&:hover': {
              color: 'white',
              backgroundColor: '#1976d2',
            },
            color: 'white',
            backgroundColor: '#1976d2',
            alignSelf: 'center',
          }}
          onClick={handleNext}
          endIcon={<EastIcon />}
        >
          Next
        </Button>
      </Stack>
    </Stack>
  );
};

export default OneToOnePastInterviewAnalysis;

import { Box, DialogTitle, Modal } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { Typography, TextField, Button, Stack, Chip } from '@mui/material';
import axios from 'axios';
import { useState } from 'react';
import { escalationURL, getHeaders, postProgramFeedback } from 'src/apis/urls';
import Success from './Success';
export default function FrustrationLevelModal({
  showFrustrationForm,
  setShowFrustrationForm,
  lastSubmittedFormResponse,
  setLastSubmittedFormResponse,
  setShowFeedbackButton,
  setShowFeedbackState,
  currentProgram,
}) {
  const initialState = {
    areas_experiencing_problem: [],
    escalation_text: '',
  };
  const [formValues, setFormValues] = useState(initialState);
  const [loadingForSubmitButton, setLoadingForSubmitButton] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const handleChipClick = (label) => {
    // Check if the chip is already selected
    const isSelected = formValues.areas_experiencing_problem.includes(label);

    // Update the state to either add or remove the selected chip
    const updatedAreas = isSelected
      ? formValues.areas_experiencing_problem.filter((area) => area !== label)
      : [...formValues.areas_experiencing_problem, label];

    // Set updated formValues
    setFormValues({
      ...formValues,
      areas_experiencing_problem: updatedAreas,
    });
  };

  const handleEscalationTextChange = (event) => {
    setFormValues({
      ...formValues,
      escalation_text: event.target.value,
    });
  };

  const handleSubmit = async () => {
    setLoadingForSubmitButton(true); // Set loading state to true to show a spinner if needed
    const body = {
      escalation: {
        form_data: JSON.stringify({
          areas: formValues.areas_experiencing_problem,
          what_went_wrong: formValues.escalation_text,
        }),
        form_type: 0,
      },
    };
    try {
      const response = await axios.post(escalationURL(), JSON.stringify(body), {
        headers: getHeaders(currentProgram?.programId),
      });
      setShowFeedbackButton(false);
      setShowFrustrationForm(false);
      setShowSuccess(true)
    } catch (error) {
      console.error('-----------', error);
    }
  };

  const handleClose = () => {
    setShowFrustrationForm(false);
    setShowFeedbackButton(true);
    setShowFeedbackState('FrustrationModal');
  };

  const handleSuccessClose = () => {
    setShowSuccess(false);
  };

  return (
    <>
      <Modal
        open={showFrustrationForm}
        aria-labelledby='modal-title'
        aria-describedby='modal-description'
        BackdropProps={{
          style: { backgroundColor: 'rgba(0, 0, 0, 0.6)' },
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 450,
            bgcolor: 'background.paper',
            borderRadius: 2,
            p: 4,
            height: 450,
            outline: 'none',
            border: 'none',
            boxShadow: 'none',
          }}
        >
          <Typography
            id='modal-title'
            variant='h5'
            component='h2'
            sx={{ fontSize: '22px !important' }}
          >
            We are sorry for the inconvenience.
          </Typography>
          <Typography
            id='modal-description'
            sx={{ mt: 2, fontSize: 15 }}
          >
            Select the areas where you're experiencing problems:
          </Typography>

          <Box
            direction='row'
            spacing={1}
            mt={2}
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '8px',
              maxWidth: '100%',
            }}
          >
            {LabelCreated.map((label, index) => (
              <Chip
                key={index}
                label={label}
                variant={
                  formValues.areas_experiencing_problem.includes(label)
                    ? 'filled'
                    : 'outlined'
                }
                sx={{
                  margin: '0 0 8px 0',
                  backgroundColor:
                    formValues.areas_experiencing_problem.includes(label)
                      ? '#1976d2'
                      : 'transparent',
                  color: formValues.areas_experiencing_problem.includes(label)
                    ? 'white'
                    : 'black',
                  '&:hover': {
                    backgroundColor:
                      formValues.areas_experiencing_problem.includes(label)
                        ? '#1565c0'
                        : 'rgba(0, 0, 0, 0.04)',
                  },
                }}
                onClick={() => handleChipClick(label)}
              />
            ))}
          </Box>

          <Typography sx={{ mt: 2, fontSize: 15 }}>
            Tell us what went wrong*
          </Typography>
          <TextField
            fullWidth
            multiline
            rows={4}
            placeholder='Type here...'
            variant='outlined'
            value={formValues.escalation_text}
            onChange={handleEscalationTextChange}
            sx={{ mt: 1 }}
          />

          <Stack
            direction='row'
            spacing={2}
            justifyContent='space-between'
            mt={2}
          >
            <Button
              variant='text'
              onClick={handleClose}
            >
              I would like to skip
            </Button>
            <Button
              variant='contained'
              disabled={
                !formValues.areas_experiencing_problem ||
                !formValues.escalation_text
              }
              onClick={() => handleSubmit()}
              sx={{
                backgroundColor:
                  !formValues.areas_experiencing_problem ||
                  !formValues.escalation_text
                    ? '#fff'
                    : '#1976d2',
                color:
                  !formValues.areas_experiencing_problem ||
                  !formValues.escalation_text
                    ? '#000'
                    : '#fff',
                '&:hover': {
                  backgroundColor:
                    !formValues.areas_experiencing_problem ||
                    !formValues.escalation_text
                      ? '#fff'
                      : '#1565c0',
                },
              }}
            >
              Submit
            </Button>
          </Stack>
        </Box>
      </Modal>
      <Success
        open={showSuccess}
        onClose={handleSuccessClose}
      />
    </>
  );
}

const LabelCreated = [
  'Learning portal',
  'Course content',
  'Support',
  'Coach',
  'Other issue',
];

import React, { useState } from "react";
import { Stack } from '@material-ui/core';
import { 
  Button, 
  TextField
} from "@mui/material";

export default function AddInteraction({ postInteraction }) {
  const [newInteraction, setNewInteraction] = useState("");

  return (
    <Stack spacing={1}>
      <TextField 
        multiline 
        rows={3} 
        fullWidth 
        placeholder="Enter an interaction"
        value={newInteraction}
        onChange={e => setNewInteraction(e.target.value)} 
      />
      <Button 
        variant='contained'  
        onClick={(e) => {
          postInteraction(newInteraction);
          setNewInteraction("");
        }} 
        disabled={newInteraction.length === 0}
      >
        Record an interaction
      </Button>
    </Stack>
  );
}

import React, { createContext, useContext, useEffect, useState } from 'react';
import { getUserType } from 'src/configs/auth';

const ActivityContext = createContext({
  lastActivityTime: Date.now(),
  isActive: true,
});

export const ActivityProvider = ({ children }) => {
  let isActive = true;
  let trackingDuration = 20000; // 20 seconds
  const shouldSendActivityData = false;

  useEffect(() => {
    if (getUserType() === 'student' && shouldSendActivityData) {
      const handleMouseMove = () => {
        isActive = true;
      };
      window.addEventListener('mousemove', handleMouseMove);

      setInterval(() => updateActiveDuration(), trackingDuration);
      return () => {
        window.removeEventListener('mousemove', handleMouseMove);
        updateActiveDuration();
      };
    }
  }, []);

  const updateActiveDuration = () => {
    if (!isActive) {
      return;
    }
    const currentDate = new Date().toISOString().split('T')[0];
    const activityData = JSON.parse(localStorage.getItem('activityData')) || {};

    if (activityData[currentDate]) {
      activityData[currentDate] += trackingDuration;
    } else {
      activityData[currentDate] = trackingDuration;
    }

    localStorage.setItem('activityData', JSON.stringify(activityData));
    isActive = false;
  };

  return (
    <ActivityContext.Provider value={{ isActive }}>
      {children}
    </ActivityContext.Provider>
  );
};

export const useActivity = () => useContext(ActivityContext);

// @ts-nocheck
import { Box, Divider, Stack, Typography } from '@mui/material';
import React, { useRef } from 'react';
import { getUserMail } from 'src/configs/auth';

const EfPipelineOfferLetter = (props) => {
  const {
    fname: leadFName,
    lname: leadLName,
    program: programName = 'Super-30',
    leadId,
    shouldOverflow = false,
    offerLetterContent,
  } = props;
  return (
    <Box
      sx={{
        boxShadow: '10',
        borderRadius: '10px',
        padding: '25px 25px 0px 25px',
        border: '1px solid #f0f0f0',
        width: 'fit-content',
        background: 'white',
        height: '100%',
        overflow: !shouldOverflow ? 'hidden' : 'auto',
      }}
      ref={offerLetterContent}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '1rem',
        }}
      >
        <img
          src='/static/heycoach-logo.png'
          alt='heycoach logo'
          width='100px'
        />
        <Box>
          <Typography
            align='right'
            sx={{
              color: 'black',
              fontSize: '0.7rem',
            }}
          >
            {leadFName} {leadLName}
          </Typography>
          <Typography
            align='right'
            sx={{
              color: 'black',
              fontSize: '0.7rem',
            }}
          >
            Bangalore
          </Typography>
          <Typography
            align='right'
            sx={{
              color: 'blue',
              fontSize: '0.7rem',
            }}
          >
            {getUserMail()}
          </Typography>
        </Box>
      </Box>
      <Divider />
      <br />
      <Stack spacing={1}>
        <Typography
          textAlign='justify'
          sx={{ fontWeight: '800', fontSize: '0.7rem' }}
        >
          <span style={{ fontWeight: 'normal' }}>Dear</span> {leadFName}{' '}
          {leadLName},
        </Typography>

        <Typography
          textAlign='justify'
          fontSize='0.7rem'
          fontWeight='800'
        >
          Subject: Offer of Confirmation for Super 30 Program at HeyCoach
        </Typography>

        <Typography
          textAlign='justify'
          fontSize='0.7rem'
        >
          We are thrilled to extend this offer of confirmation for your
          selection in the prestigious {programName} at HeyCoach.
          Congratulations on being shortlisted among the cream of the crop – the
          exclusive 30 individuals chosen for this premium program.
        </Typography>

        <Typography
          textAlign='justify'
          fontSize='0.7rem'
        >
          At HeyCoach, we pride ourselves on selecting the brightest minds and
          nurturing their talents to reach new heights of success. Your
          outstanding qualifications, dedication, and potential have
          distinguished you as one of the elite candidates deserving of this
          opportunity.
        </Typography>

        <Typography
          textAlign='justify'
          fontSize='0.7rem'
          sx={{ fontWeight: '800' }}
        >
          Details of the Program:
        </Typography>

        <Typography
          textAlign='justify'
          fontSize='0.7rem'
        >
          <span style={{ fontWeight: '800' }}>Program Duration:</span> 16 Weeks{' '}
          <br />
          <span style={{ fontWeight: '800' }}>Venue:</span> Online <br />
          <span style={{ fontWeight: '800' }}>Applicant ID:</span> HC2024
          {leadId} <br />
        </Typography>

        <Typography
          textAlign='justify'
          fontSize='0.7rem'
          sx={{ fontWeight: '800' }}
        >
          As a participant in the {programName}, you will gain access to:
        </Typography>

        <Typography
          textAlign='justify'
          fontSize='0.7rem'
        >
          -Personalised coaching and mentorship from industry experts for the
          batch. <br />
          -Tailored curriculum designed to enhance your skills and knowledge.{' '}
          <br />
          -Exclusive networking opportunities with peers and professionals.{' '}
          <br />
          -Career development mentoring sessions and career growth guidance.{' '}
          <br />
          -Recognition and certification upon successful completion of the
          program.
        </Typography>
        <Typography
          textAlign='justify'
          fontSize='0.7rem'
        >
          Please review your offer letter having details of your participation
          in the {programName} Program followed by completing the seat booking
          and registration process in the presence of a selection panel member
          for a smooth process and understanding your growth journey ahead. The
          timelines are the key priority metrics which define your intent and
          respect towards this offer letter hence this offer letter comes with a
          certain validity.
        </Typography>
        <Typography
          textAlign='justify'
          fontSize='0.7rem'
        >
          We are excited to embark on this transformative journey with you and
          look forward to seeing your remarkable progress throughout the
          program. If you have any questions or require further assistance,
          please do not hesitate to contact us at{' '}
          <a
            href='mailto:team@heycoach.in'
            target='_top'
          >
            team@heycoach.in
          </a>{' '}
          or{' '}
          <a
            href='mailto:support@heycoach.co.in'
            target='_top'
          >
            support@heycoach.co.in
          </a>
          .
        </Typography>
{/*         <Typography
          textAlign='justify'
          fontSize='0.7rem'
        >
          Once again, congratulations on your selection, and welcome to the
          HeyCoach {programName} Program! Please click here to proceed with
          registration and onboarding towards the {programName} program.
        </Typography> */}

        <Typography
          textAlign='justify'
          fontSize='0.7rem'
        >
          Best regards,
        </Typography>
      </Stack>
      <img
        style={{ width: '100px', marginTop: '5px' }}
        src='/static/kamat-sign.png'
        alt='kamat sign'
      />
      <Typography
        textAlign='justify'
        fontSize='0.7rem'
      >
        Aditya Kamat, <br />
        Co-Founder, HeyCoach.
      </Typography>
      <img
        style={{
          marginLeft: 'auto',
          marginRight: '-25px',
          marginTop: '-25px',
          borderRadius: '0px 0px 10px 0px',
          width: '190px',
        }}
        src='/static/offer_letter_design.png'
        alt='offer letter design'
      />
    </Box>
  );
};

export default EfPipelineOfferLetter;

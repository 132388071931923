// @ts-nocheck
import { Stack, Typography, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import React from 'react';

const SingleCorrectOptions = ({
  qid,
  options,
  response,
  setResponse,
  setShouldAllowNext,
  revealAnswerOfQuestionNumber,
}) => {
  const theme = useTheme();
  const isMultiCorrect =
    (options ?? []).filter((opt) => opt.isCorrect).length > 1;

  const handleClick = (e) => {
    const { id } = e.target;
    const index = Number(id);
    const value = options[index].option;
    const selections = response[qid] ?? [];

    if (selections.includes(value)) {
      // de-selection
      setResponse((prev) => {
        const tmp = prev[qid].filter((p) => p !== value);
        return {
          ...prev,
          [qid]: tmp,
        };
      });

      if (selections.length === 1) {
        setShouldAllowNext(false);
      } else {
        setShouldAllowNext(true);
      }
    } else {
      setResponse((prev) => {
        const tmp = {
          ...prev,
          [qid]: [value],
        };

        if (isMultiCorrect) {
          tmp[qid].push(...prev[qid]);
        }
        return tmp;
      });
      setShouldAllowNext(true);
    }
  };

  const shouldShowSelection = (opt) => {
    const { option } = opt;
    const selections = response[qid] ?? [];
    return selections.includes(option);
  };

  const shouldShowResult = () => {
    return revealAnswerOfQuestionNumber === qid;
  };

  return (
    <Stack
      spacing={1}
      onClick={handleClick}
    >
      {options.map((opt, idx) => {
        const { option, isCorrect } = opt;
        return (
          <Typography
            variant='body1'
            component='p'
            id={idx}
            sx={{
              borderRadius: '10px',
              padding: '8px',
              background: 'white',
              width: 'fit-content',
              cursor: 'pointer',
              fontSize: '1.2rem',
              fontFamily: 'Inter',
              border: '1.2px solid black',
              ...(shouldShowSelection(opt) && {
                border: '2px solid blue',
              }),
              ...(shouldShowResult() && {
                border: isCorrect ? '1px solid #66bb6a' : '1px solid #ef5350',
                background: isCorrect ? '#c8e6c9' : '#ffcdd2',
              }),
              [theme.breakpoints.down('sm')]: {
                fontSize: '0.8rem',
              },
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {String.fromCharCode(65 + idx)}. &nbsp; {option}
            &nbsp;
            {shouldShowResult() &&
              (isCorrect ? (
                <CheckIcon
                  fontSize='small'
                  color='success'
                />
              ) : (
                <CloseIcon
                  fontSize='small'
                  color='error'
                />
              ))}
          </Typography>
        );
      })}
    </Stack>
  );
};

export default SingleCorrectOptions;

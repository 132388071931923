import React, { useState } from 'react';
import { Box, Button, Typography, Tabs, Tab } from '@material-ui/core';
import LoadingButton from '@mui/lab/LoadingButton';
import VerifiedIcon from '@mui/icons-material/Verified';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import CustomTabPanel from '../CustomTabPanel';
import { CountOnesBinaryConstant } from '../../../utils/techniqueSagaConstant';
import TopicTitle from '../TopicTitle';
import CustomButton from '../../../practice/MindMaps/Atoms/CustomButton';
import ListRenderComponent from '../ListRenderComponent';
import MultiListComponent from '../MultiListComponent';

import 'react-responsive-carousel/lib/styles/carousel.min.css';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const CountOnesBinaryRepresentation = ({
  handlePostCompletedTopic,
  hasMarkedCompleted,
  loading,
  topic_id,
  handleNext,
  handlePrevious,
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap='36px'
      paddingY={2}
      maxWidth='calc(100% - 300px)'
    >
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
      >
        <TopicTitle>2. Count Ones in Binary Representation</TopicTitle>
        {hasMarkedCompleted(topic_id) && (
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              color: 'green',
              fontWeight: '500',
              border: '2px solid green',
              padding: '4px 10px',
              borderRadius: '20px',
              cursor: 'default',
            }}
          >
            Completed
          </Typography>
        )}
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        gap='24px'
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            width: '34rem',
            background: '#FEF7FF',
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
          >
            <Tab
              label='Problem Statement'
              {...a11yProps(0)}
            />
            <Tab
              label='Traditional Approach'
              {...a11yProps(1)}
            />
            <Tab
              label='Bit Manipulation Approach'
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <CustomTabPanel
          value={value}
          index={0}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Typography>
              {CountOnesBinaryConstant.ProblemStatement.text01}
            </Typography>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={1}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Typography>
              {CountOnesBinaryConstant.TraditionalApproach.text01}
            </Typography>

            <Box
              sx={{
                backgroundColor: 'black',
                padding: '24px',
                borderRadius: '10px',
                width: '583px',
              }}
            >
              <pre style={{ color: 'white', margin: 0 }}>
                {`Int count1s(int n){
    Int cnt=0;
    while(n>0){
        if(n&1==1){
            cnt++;
        }
        
        n=n>>1
   }
   
   return cnt;
}`}
              </pre>
            </Box>

            <Typography>
              {CountOnesBinaryConstant.TraditionalApproach.text02}
            </Typography>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={2}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Box
              sx={{
                backgroundColor: 'black',
                padding: '24px',
                borderRadius: '10px',
                width: '583px',
              }}
            >
              <pre style={{ color: 'white', margin: 0 }}>
                {`int countOne(int n) {
    int count = 0;
    while (n) {
        n = n & (n - 1);
        count++;
    }
    return count;
}`}
              </pre>
            </Box>

            <Box>
              <Typography sx={{ fontSize: '22px', fontWeight: 400, mb: '8px' }}>
                Why does this work?
              </Typography>
              <ListRenderComponent
                data={CountOnesBinaryConstant.BitManipulationApproach01}
              />
            </Box>

            <Box>
              <Typography sx={{ fontSize: '22px', fontWeight: 400, mb: '8px' }}>
                Example
              </Typography>
              <MultiListComponent
                data={CountOnesBinaryConstant.BitManipulationApproach02}
              />
              <Typography
                sx={{
                  mb: 3,
                }}
              >
                In our algorithm at each step we are doing an AND operation
                between two consecutive numbers which effectively flips the
                least significant '1' bit to zero. Thus our loop will terminate
                after all the ones are flipped to zero, and it will run only
                that many times as many ones are present in the binary
                representation.
              </Typography>
              <Typography
                sx={{
                  mb: 3,
                }}
              >
                <span style={{ fontWeight: '600' }}>Time Complexity:</span>
                {
                  ' O(K), where K is the number of ones in the binary representation of the given number.'
                }
              </Typography>
              <Typography>
                <span style={{ fontWeight: '600' }}>Space Complexity:</span>
                {' O(1)'}
              </Typography>
            </Box>
          </Box>
        </CustomTabPanel>
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        alignSelf='end'
        justifyContent='space-between'
        gap='12px'
      >
        {!hasMarkedCompleted(topic_id) && (
          <LoadingButton
            variant='contained'
            onClick={() => handlePostCompletedTopic(topic_id)}
            loading={loading}
            loadingPosition='center'
            children='Mark as completed'
            style={{
              width: '170px',
              // backgroundColor: 'transparent',
              borderRadius: '8px',
              border: '1px solid rgba(64, 96, 245, 0.5)',
            }}
          />
        )}

        <Box
          display='flex'
          alignItems='center'
          gap='8px'
        >
          <Button
            sx={{ gap: '4px' }}
            onClick={handlePrevious}
          >
            <WestIcon /> Previous
          </Button>

          <Button
            sx={{ gap: '4px' }}
            onClick={handleNext}
          >
            Next <EastIcon />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default CountOnesBinaryRepresentation;

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { Box } from '@mui/material';
import SessionItemDetails from './SessionItemDetails';
import SessionsNav from './SessionsNav';

export default function SessionItemDetailsLayout() {
  const { details, text, sessionData, moduleName, index } = useLocation().state;
  const session_id = details.session_id ? details.session_id : details.id;
  const pre_reads = useSelector((state) => state.sessions.pre_reads);

  useEffect(() => {
    const selected_pre_reads = pre_reads?.filter(
      (pre_read) => pre_read.session_id === session_id
    );
    if (selected_pre_reads?.length > 0) {
      sessionData
        .filter((data) => data.type === 'preRead')
        .forEach((data, index) => {
          const pre = selected_pre_reads[index];
          sessionData[index].details = pre;
          sessionData[index].completed = pre.completed;
        });
    }
  }, [pre_reads]);

  const [currentIndex, setCurrentIndex] = useState(index);
  const [itemDetails, setItemDetails] = useState(details);
  const [titleText, setTitleText] = useState(text);
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    setItemDetails(sessionData[currentIndex].details);
    setTitleText(sessionData[currentIndex].text);
  }, [currentIndex, pre_reads]);

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ width: '80%', flexGrow: 1 }}>
        <SessionItemDetails
          style={{ minHeight: '100%' }}
          details={itemDetails}
          moduleName={moduleName}
          setCurrentIndex={setCurrentIndex}
          text={titleText}
        />
      </div>
      <Box
        style={{
          backgroundColor: '#f0f0f0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '50%',
          width: '30px',
          height: '30px',
          position: 'sticky',
          transform: 'translateX(15px)',
          boxShadow: '1px 2px #cccccc',
          border: '1px solid #cccaca',
          marginTop: '15px',
          cursor: 'pointer',
        }}
        onClick={() => setCollapsed((prev) => !prev)}
      >
        <ArrowForwardIosSharpIcon
          sx={{
            transform: collapsed ? 'rotate(180deg)' : 'rotate(0deg)',
            fontSize: 'medium',
          }}
        />
      </Box>
      <div
        style={{
          display: collapsed ? 'none' : 'block',
          flexGrow: 1,
          maxWidth: '20%',
        }}
      >
        <SessionsNav
          sessionData={sessionData}
          setCurrentIndex={setCurrentIndex}
          currentIndex={currentIndex}
        />
      </div>
    </div>
  );
}

import axios from 'axios';
import CloseOutlined from '@iconify/icons-ant-design/close-outlined';
import { Icon } from '@iconify/react';
import React, { useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
// material
import { Stack, TextField, Modal, Box, Button } from '@material-ui/core';
import moment from 'moment/moment';
import { getUserType } from '../../../configs/auth';
import { fetchAssignmentsUrl, fetchAssignmentUrl, getHeaders } from '../../../apis/urls';
import { AssignmentContext } from '../../../configs/AssignmentContext';
import ErrorNotifier from '../../ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../ToastNotifications/SuccessNotifier';
import TopicsDropdown from '../../../pages/TopicsDropdown';

// ----------------------------------------------------------------------

export const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const initialState = {
  title: "",
  description: "",
  deadline: null
}

const ModalCreateAssignment = ({ open, handleClose, index, titleLabel, buttonLabel, operationType }) => {
  const [newAssignment, setNewAssignment] = useState(initialState);
  const [selectedTopicIds, setSelectedTopicIds] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [assignments, setAssignments] = useContext(AssignmentContext);
  const userType = getUserType();
  const currentProgram = useSelector(state => state.programs.currentProgram);
  const min_date = new Date().toISOString().slice(0, 10);

  useEffect(() => {
    if (operationType === "update") {
      setNewAssignment({
        title: (assignments[index]?.title || ""),
        description: (assignments[index]?.description || ""),
        deadline: (assignments[index]?.deadline 
          ? moment(assignments[index].deadline).format('YYYY-MM-DD') 
          : null
        )
      });
      if (assignments[index]?.topics_entities) {
        setSelectedTopicIds(
          assignments[index]?.topics_entities.map(entity => entity.topic?.id)
        );
      }
    }
    return () => {};
  }, []);
  
  const handleAssignmentSubmit = (e) => {
    const body = {...newAssignment};

    if (selectedTopicIds.length === 0) {
      if (operationType !== "update") {
        setHasError(true);
        setErrorMessage("Please add a topic for the assignment");
        return;
      }
    } else {
      body.topic_ids = selectedTopicIds;
    }

    let request;
    if (operationType === 'update') {
      request = axios.patch(fetchAssignmentUrl(assignments[index].id), body, {
        headers: getHeaders(currentProgram.programId)
      });
    } else {
      request = axios.post(fetchAssignmentsUrl(), body, { 
        headers: getHeaders(currentProgram.programId)
      });
    }

    request
      .then((res) => {
        const oldAssignment = assignments.filter(
          (assignment) => assignment?.id !== assignments[index]?.id
        );
        const updatedAssignments = [res.data, ...oldAssignment];
        setSuccessMessage(`Assignment ${operationType}d`);
        setIsSuccess(true);
        setAssignments(updatedAssignments);
        handleFormClose();
      })
      .catch((e) => { 
        setHasError(true);
        setErrorMessage(e.response?.data?.message || e.message);
      });
  }

  const handleFormClose = () => {
    handleClose(!open);
  };

  return (
    <>
      {isSuccess && <SuccessNotifier message={successMessage} setIsSuccess={setIsSuccess} /> }
      {hasError && <ErrorNotifier message={errorMessage} setHasError={setHasError} /> }
      <Modal open={open} onClose={handleFormClose} style={{ overflow: 'auto' }}>
        <Box sx={style}>
          <Stack spacing={3}>
            <Stack direction="row" sx={{display: "flex", justifyContent: "flex-end"}}>
              <Icon
                onClick={handleFormClose}
                style={{ cursor: 'pointer' }}
                icon={CloseOutlined}
                width="18"
                height="18"
              />
            </Stack>

            <TextField
              fullWidth
              label="Title"
              value={newAssignment.title}
              onChange={(e) => setNewAssignment({...newAssignment, title: e.target.value})}
            />
            <TextField
              fullWidth
              label="Description"
              value={newAssignment.description}
              onChange={(e) => setNewAssignment({...newAssignment, description: e.target.value})}
              multiline
              rows={4}
            />
            <TextField
              id="date"
              type="date"
              InputLabelProps={{ shrink: true }}
              inputProps={{ min: min_date }}
              onChange={(e) => setNewAssignment({...newAssignment, deadline: e.target.value})}
              defaultValue={newAssignment?.deadline}
            />

            {currentProgram &&
              <TopicsDropdown 
                {...{
                  selectedTopicIds, setSelectedTopicIds, setErrorMessage, setHasError,
                  sx: { m: 1, minWidth: 120 }
                }}
              />
            }

            <Button size="large" variant="contained" color="secondary" onClick={handleAssignmentSubmit}>
              {buttonLabel}
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default ModalCreateAssignment;
import React, { useEffect, useState } from 'react';
import { TextField, Autocomplete } from '@mui/material';

export default function QuizDropdown({ quizzes, setSelectedQuizId }) {
  const [allQuizzes, setAllQuizzes] = useState([]);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    getQuizzes();
    return () => {};
  }, [quizzes]);

  function getQuizzes() {
    const tmpQuizzes = [];
    Object.keys(quizzes).forEach((qid, index) => {
      tmpQuizzes.push({
        label: quizzes[qid].internal_name,
        value: quizzes[qid].id,
      });
    });

    setAllQuizzes(tmpQuizzes);
  }

  return (
    <Autocomplete
      value={inputValue}
      options={allQuizzes}
      onChange={(event, newValue) => {
        setInputValue(newValue);
        setSelectedQuizId(newValue.value);
      }}
      getOptionLabel={(option) => option.label ?? ''}
      renderInput={(params) => (
        <TextField
          {...params}
          label='Select a Quiz'
        />
      )}
      disableClearable
    />
  );
}

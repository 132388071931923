// @ts-nocheck
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { getUserName, getUserData, getUserId } from '../../configs/auth';
import {
  BASE_URL,
  fetchUserEnrollmentsUrl,
  getHeaders,
  ticketUrl,
} from '../../apis/urls';
import ErrorNotifier from '../../components/ToastNotifications/ErrorNotifier';
import { slugify } from '../../components/utils/common';
import styles from './HelpSection.module.css';
import { useSelector } from 'react-redux';

const isCodingTestRelatedQuery = (message) => {
  message = (message ?? '').toLowerCase();
  const keywords = [
    'tgp',
    'test',
    'tests',
    'levels',
    'level',
    'coding test',
    'coding tests',
    'session test',
    'session tests',
    'weekly test',
    'weekly tests',
  ];

  return keywords.some((keyword) => message.includes(keyword));
};

const pushToPgmCodingTestThreadForTech = (webhookURL, info) => {
  const discordUserIdentified = '<@994335839549141114>'; // Tags lokesh
  const serverId = '1009812013024821248';
  const threadId = '1230132281017110620';

  const content = [
    discordUserIdentified,
    `User id: ${info.userId}`,
    `Message: ${info.message}`,
    `Track here: https://discord.com/channels/${serverId}/${info.channelId}/${info.messageId}`,
  ].join('\n');

  const url = [webhookURL, `&thread_id=${threadId}`].join('&');

  axios.post(url, { content });
};

const HelpPopover = ({ isOpen, closeHandler }) => {
  const [selectedOption, setSelectedOption] = useState('');
  const [discordIcon, setDiscordIcon] = useState('');
  const [showSecondModal, setShowSecondModal] = useState(false);
  const [message, setMessage] = useState('');
  const [hasError, setHasError] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [programs, setPrograms] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const currentProgram = useSelector((state) => state.programs.currentProgram);
  const today = moment();

  const handleOptionClick = (option) => {
    setShowSecondModal(true);
    setSelectedOption(option);
    if (option === 'Request Callback') {
      setDiscordIcon(':telephone_receiver:');
    } else if (option === 'Doubt Solving Assistance') {
      setDiscordIcon(':grey_question:');
    } else if (option === 'Others') {
      setDiscordIcon(':speech_balloon:');
    }
  };

  useEffect(() => {
    axios
      .get(fetchUserEnrollmentsUrl(), {
        headers: getHeaders(),
      })
      .then((res) => {
        if (!res.data || res.data.length === 0) {
          return;
        }
        const storedAllPrograms = {};
        res?.data?.forEach((enrollmentObj) => {
          if ('program' in enrollmentObj) {
            const date = moment(enrollmentObj.program.start_date);
            const remDays = date.isAfter(today)
              ? date.diff(today, 'days') + 10
              : 10 - today.diff(date, 'days');

            storedAllPrograms[slugify(enrollmentObj.program.name)] = {
              programId: enrollmentObj.program.id,
              name: enrollmentObj.program.name,
              programAccess: enrollmentObj?.program_access,
              remDays,
            };
          }
        });
        setPrograms(storedAllPrograms);
      })
      .catch((e) => {
        console.log(e);
        setHasError(e);
        setErrorMessage(e.response?.data?.message || e.message);
      });

    return () => {};
  }, []);

  const handleCloseOptionClick = () => {
    setShowSecondModal(false);
  };

  const handleSubmitRequest = async () => {
    setIsSubmitting(true);
    await submitTicket();
    // await sendDiscordMessage();
    setIsSubmitting(false);
  };

  const submitTicket = async () => {
    let category = 'others';
    if (selectedOption === 'Request Callback') {
      category = 'request_callback';
    } else if (selectedOption === 'Doubt Solving Assistance') {
      category = 'doubt_solving_assistance';
    }

    try {
      const body = {
        ticket: {
          query: message,
          category: category,
          program_id: currentProgram?.programId,
        },
      };
      const res = await axios.post(`${BASE_URL}/api/ticket`, body, {
        headers: getHeaders(currentProgram?.programId),
      });
      setShowSuccessMessage(true);
    } catch (error) {
      console.error('Error occurred during axios request:', error);
    }
  };

  const sendDiscordMessage = async () => {
    const userName = getUserName();
    const namesArray = programs
      ? Object.keys(programs).map((key) => programs[key].name)
      : null;
    const formattedMessage = `
      ${discordIcon} ${selectedOption}:
From Learner: ${userName}
Program: ${namesArray}
Message: ${message}
    `;

    const webhookURL = // Note: Don't remove 'wait=true
      'https://discord.com/api/webhooks/1118473610932256798/RTHCwwc7NXemPcGebXjKoHSs7ZBualZrclcB7k5D0DDHdaJ8cQ313FT19WrsyjmPB6ab?wait=true';

    try {
      const originalEscalation = await axios.post(webhookURL, {
        content: formattedMessage,
      });

      const { data } = originalEscalation;
      setShowSuccessMessage(true);
      if (isCodingTestRelatedQuery(message)) {
        pushToPgmCodingTestThreadForTech(webhookURL, {
          message,
          messageId: data.id,
          channelId: data.channel_id,
          userId: getUserId(),
        });
      }
    } catch (error) {
      console.error('Error sending message to Discord:', error);
    }
  };

  let thankYouMsg;
  let optionMessage;
  if (selectedOption === 'Request Callback') {
    thankYouMsg =
      'We have received your request. Someone from the team will get back to you soon.';
    optionMessage =
      "Need to discuss your concerns or queries in detail? Let us call you back at a time that's convenient for you.";
  } else if (selectedOption === 'Doubt Solving Assistance') {
    thankYouMsg =
      'We have received your request. Someone from the team will get back to you soon.';
    optionMessage =
      'Stuck on a problem or need clarification on a topic? Our experts are on hand to clear your doubts';
  } else if (selectedOption === 'Others') {
    thankYouMsg =
      "We're looking into your request and will provide the assistance you need promptly.";
    optionMessage =
      'Have a different query or need further assistance? Tell us more about how we can help you.';
  }

  let content;
  if (showSuccessMessage) {
    content = (
      <div className={styles.optionsContainer}>
        <div className={styles.successContainer}>
          <CheckCircleIcon className={styles.checkmark} />
          <div className={styles.headingText}>Thank you!</div>
          <div className={styles.successText}>{thankYouMsg}</div>
        </div>
      </div>
    );
  } else if (showSecondModal) {
    content = (
      <div className={styles.optionsContainer}>
        <div className={styles.optionHeader}>
          <ArrowBackIosIcon
            className={styles.backBtn}
            onClick={handleCloseOptionClick}
          />
          {selectedOption}
        </div>
        <div className={styles.mainContainer}>
          <div className={styles.informativeText}>{optionMessage}</div>
          <textarea
            required
            className={styles.textArea}
            rows={7}
            name='message'
            placeholder='Enter Your Message...'
            onChange={(e) => setMessage(e.target.value)}
          />
          <div className={styles.submitCtaContainer}>
            <button
              className={styles.submitCTA}
              disabled={!message || isSubmitting}
              onClick={handleSubmitRequest}
            >
              {isSubmitting ? <>Submitting...</> : <>Submit request</>}
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    content = (
      <div>
        <div className={styles.optionHeader}>Help</div>
        <div className={styles.mainContainer}>
          <div className={styles.headingText}>How can we help?</div>
          <div className={styles.informativeText}>
            Our team is committed to providing support and guidance to ensure
            your success. Please explore the available options to seek
            assistance:
          </div>
          <div className={styles.ctaContainer}>
            <button
              className={styles.optionCta}
              onClick={() => handleOptionClick('Request Callback')}
            >
              <div className={styles.ctaStyle}>
                Request Callback
                <ArrowRightAltIcon />
              </div>
            </button>
            <button
              className={styles.optionCta}
              onClick={() => handleOptionClick('Doubt Solving Assistance')}
            >
              <div className={styles.ctaStyle}>
                Doubt Solving Assistance
                <ArrowRightAltIcon />
              </div>
            </button>
            <button
              className={styles.optionCta}
              onClick={() => handleOptionClick('Others')}
            >
              <div className={styles.ctaStyle}>
                Others
                <ArrowRightAltIcon />
              </div>
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.choicePopOver}>
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      <CloseIcon
        className={styles.closeIcon}
        onClick={closeHandler}
      />
      {content}
    </div>
  );
};

export default HelpPopover;

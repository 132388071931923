// @ts-nocheck
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, CardContent, Stack, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useSessionContext } from './SessionContext';
import { getHeaders, openS3URL } from '../../apis/urls';

const SessionRecording = () => {
  const { materialResource } = useSessionContext();
  const [recordings, setRecordings] = useState([]);
  const currentProgram = useSelector((state) => state.programs.currentProgram);

  useEffect(() => {
    const openFileHandler = async () => {
      const len = materialResource.length;

      for (let i = 0; i < len; i += 1) {
        if (materialResource[i].resource_context === 'session_recording') {
          axios
            .get(openS3URL(materialResource[i].id), {
              headers: getHeaders(currentProgram?.programId),
            })
            .then((res) => {
              const { data } = res;
              setRecordings((p) => [
                ...p,
                {
                  ...materialResource[i],
                  link: data.file,
                },
              ]);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    };

    openFileHandler();
    return () => {};
  }, []);

  return (
    <Stack
      spacing={2}
      textAlign='center'
    >
      {recordings.length === 0 && (
        <Typography
          variant='body1'
          component='p'
          mt={4}
        >
          Either recording doesn't exist, or it has not been uploaded yet.
          Please contact your POC.
        </Typography>
      )}

      {recordings.map((r, index) => {
        return (
          <Card sx={{ width: '100%' }}>
            <CardContent>
              <video
                width='100%'
                height='360'
                controls
              >
                <source
                  src={r.link}
                  type='video/webm'
                />
                <track
                  kind='captions'
                  label='English'
                  srcLang='en'
                  src=''
                />
              </video>
            </CardContent>
          </Card>
        );
      })}
    </Stack>
  );
};

export default SessionRecording;

// @ts-nocheck
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { LoadingButton } from '@material-ui/lab';
import axios from 'axios';
import { BASE_URL, getHeaders } from '../../../../apis/urls';

function NewSessionRecordings({
  openSessionRecordingsModal,
  handleClose,
  selectedSession,
  setHasError,
  setErrorMessage,
  setIsSuccess,
  setSuccessMessage,
}) {
  const currentProgram = useSelector((state) => state.programs.currentProgram);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);

  const handleChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append('video', file);

    setLoading(true);
    try {
      await axios.post(
        `${BASE_URL}/api/sessions/${selectedSession?.id}/recordings`,
        formData,
        {
          headers: {
            ...getHeaders(currentProgram?.programId),
            'Content-Type': 'multipart/form-data',
          },
          timeout: 10 * 60 * 1000, // 10 minutes in milliseconds
          timeoutErrorMessage: 'Request timed out',
        }
      );
      setIsSuccess(true);
      setSuccessMessage('Recording uploaded successfully!');
    } catch (error) {
      console.error('Error uploading recording:', error);
      setHasError(true);
      setErrorMessage(`Error uploading recording. ${error}`);
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  return (
    <Dialog
      open={openSessionRecordingsModal}
      onClose={handleClose}
      maxWidth='sm'
      fullWidth
    >
      <Stack
        direction='row'
        justifyContent='space-between'
      >
        <DialogTitle>Add session recording</DialogTitle>
        <Button
          variant='text'
          onClick={handleClose}
        >
          Close
        </Button>
      </Stack>

      <DialogContent>
        <TextField
          type='text'
          name='Session name'
          value={selectedSession?.title}
          size='small'
          disabled
          fullWidth
        />

        <input
          type='file'
          accept='video/*'
          onChange={handleChange}
        />
      </DialogContent>

      <DialogActions>
        <LoadingButton
          color='success'
          size='small'
          variant='contained'
          disabled={file === null}
          loading={loading}
          onClick={handleUpload}
          autoFocus
        >
          Upload
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default NewSessionRecordings;

import React, { useState } from 'react';
import { Stack } from '@material-ui/core';
import { Button, TextField } from '@mui/material';

export default function AddInteraction({ postInteraction }) {
  const [newInteraction, setNewInteraction] = useState('');

  return (
    <Stack
      spacing={3}
      sx={{
        border: '1px solid #C1C1C1',
        borderRadius: '10px',
        padding: '20px 32px',
      }}
    >
      <TextField
        multiline
        rows={3}
        fullWidth
        placeholder='Enter an interaction'
        value={newInteraction}
        onChange={(e) => setNewInteraction(e.target.value)}
      />
      <Button
        variant='contained'
        onClick={(e) => {
          postInteraction(newInteraction);
          setNewInteraction('');
        }}
        disabled={newInteraction.length === 0}
        sx={{
          width: 'fit-content',
          alignSelf: 'flex-end',
        }}
      >
        + Add Interaction
      </Button>
    </Stack>
  );
}

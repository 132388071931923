// @ts-nocheck
import {
  Box,
  Button,
  Chip,
  Divider,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import React from 'react';
import FastrackOfferLetterCountdown from '../FastrackOfferLetterCountdown';
import { useFastrackPipeline } from 'src/layouts/EfPipelineContext/FastrackPipelineContex';

const FastrackModePaymentCard = ({ time, setShowOfferLetter }) => {
  const { trackButtonClick } = useFastrackPipeline();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const releaseOfferLetter = () => {
    setShowOfferLetter(true);
    trackButtonClick({
      source: 'fastrack_ol_screen',
      eventName: 'release_offer_letter',
    });
  };

  return (
    <Stack
      spacing={1.5}
      sx={{
        width: {
          xs: '20rem',
          sm: '22rem',
          md: '23rem',
          lg: '36%',
          xl: '32%',
        },
        height: { xs: '29.5rem', sm: '28.5rem' },
        border: '1px solid #bdbdbd',
        borderRadius: '0.5rem',
        padding: '1.3rem',
        backgroundColor: 'white',
        backgroundImage: 'linear-gradient(#FFFFDD, #D0F2F4)',
      }}
    >
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
      >
        <Typography
          variant='h6'
          component='p'
          sx={{
            fontFamily: 'Inter',
            fontWeight: 700,
            fontSize: '1.25rem',
            color: '#000000',
          }}
        >
          Fastrack Mode
        </Typography>

        <Chip
          size='small'
          label='Best Value'
          sx={{
            fontFamily: 'Inter',
            fontWeight: 600,
            backgroundColor: '#fff',
            color: '#000',
            padding: '0.5rem',
          }}
        />
      </Stack>

      <Typography
        variant='body2'
        component='p'
        sx={{
          fontFamily: 'Inter',
          fontWeight: 500,
          fontSize: '0.875rem',
          color: '#4F4F4F',
        }}
      >
        Accelerate your application process by skipping the waitlist for
        screening interview.
      </Typography>

      <Box
        display='flex'
        gap='8px'
      >
        <Typography
          sx={{
            fontFamily: 'Inter',
            fontWeight: 600,
            fontSize: '1.5rem',
          }}
        >
          &#8377;
        </Typography>
        <Typography
          variant='h2'
          component='h2'
          sx={{
            fontFamily: 'Inter',
            fontWeight: 700,
            fontSize: '2.125rem',
            color: '#000000',
            lineHeight: '1',
          }}
        >
          10,000
        </Typography>

        <Stack
          direction='row'
          spacing={0.5}
        >
          <Typography
            variant={isMobile ? 'caption' : 'body2'}
            component='p'
            sx={{
              fontFamily: 'Inter',
              fontWeight: 600,
              fontSize: '1rem',
              color: '#000000',
            }}
          >
            +
          </Typography>
          <Typography
            variant={isMobile ? 'caption' : 'body2'}
            component='p'
            sx={{
              fontFamily: 'Inter',
              fontWeight: 600,
              fontSize: '0.875rem',
              color: '#000000',
              lineHeight: '1.5',
            }}
          >
            additional benefits
          </Typography>
        </Stack>
      </Box>

      <Divider sx={{ borderBottomWidth: 1.5 }} />

      <Stack
        spacing={1.5}
        pt='0.6rem'
      >
        <Typography
          variant='body1'
          sx={{
            fontFamily: 'Inter',
            fontWeight: 600,
            display: 'flex',
            alignItems: 'center',
            gap: '0.8rem',
            color: '#000000',
          }}
        >
          <CheckCircleIcon color='success' /> Regular access to course
        </Typography>

        <Typography
          variant='body1'
          sx={{
            fontFamily: 'Inter',
            fontWeight: 600,
            display: 'flex',
            alignItems: 'center',
            gap: '0.8rem',
            color: '#000000',
          }}
        >
          <CheckCircleIcon color='success' /> Coupons worth Rs 10,000
          <Tooltip
            title={
              <>
                <Typography
                  color='inherit'
                  fontWeight='bold'
                  fontSize='0.875rem'
                >
                  Exclusive Coupons
                </Typography>
                <Typography fontSize='0.875rem'>
                  Get coupons worth Rs. 10,000/ from top brands like Myntra,
                  Beardo, etc.
                </Typography>
              </>
            }
            arrow
            placement='right'
            sx={{
              maxWidth: '50px',
              '& .MuiTooltip-tooltip': {
                color: 'black',
                fontSize: '1rem',
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '1.5rem',
                height: '1.5rem',
                borderRadius: '50%',
              }}
            >
              <InfoOutlinedIcon sx={{ fontSize: '1rem', color: '#000000' }} />
            </Box>
          </Tooltip>
        </Typography>

        <Typography
          variant='body1'
          sx={{
            fontFamily: 'Inter',
            fontWeight: 600,
            display: 'flex',
            alignItems: 'center',
            gap: '0.8rem',
            color: '#000000',
          }}
        >
          <CheckCircleIcon color='success' /> Access to language bible
          <Tooltip
            title={
              <>
                <Typography
                  color='inherit'
                  fontWeight='bold'
                  fontSize='0.875rem'
                >
                  Language Bible
                </Typography>
                <Typography fontSize='0.875rem'>
                  The comprehensive guide to mastering the world's leading
                  programming languages!
                </Typography>
              </>
            }
            arrow
            placement='right'
            sx={{
              maxWidth: '50px',
              '& .MuiTooltip-tooltip': {
                backgroundColor: 'white',
                color: 'black',
                fontSize: '1rem',
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '1.5rem',
                height: '1.5rem',
                borderRadius: '50%',
              }}
            >
              <InfoOutlinedIcon sx={{ fontSize: '1rem', color: '#000000' }} />
            </Box>
          </Tooltip>
        </Typography>
      </Stack>

      <Stack
        spacing={1.5}
        pt='1rem'
      >
        <Stack
          direction='row'
          justifyContent='center'
          alignItems='center'
          gap='0.6rem'
        >
          <Typography
            variant='body1'
            sx={{
              fontFamily: 'Inter',
              fontWeight: 500,
              color: '#000000',
            }}
          >
            The offer expires in:
          </Typography>
          <FastrackOfferLetterCountdown time={time} />
        </Stack>

        <Button
          endIcon={<ArrowRightAltIcon />}
          sx={{
            fontFamily: 'Inter',
            fontWeight: 700,
            backgroundColor: '#009AEF',
            backgroundImage: 'linear-gradient(to right, #009AEF, #FBC704)',
            color: 'white',
            padding: '0.75rem',
            width: '80%',
            alignSelf: 'center',
            borderRadius: '0.375rem',
          }}
          onClick={releaseOfferLetter}
        >
          Release my offer letter
        </Button>
      </Stack>
    </Stack>
  );
};

export default FastrackModePaymentCard;

import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';

const SignatureFrameworkUnlocked = ({
  setShowSignatureFramework,
  showSignatureFramework,
}) => {
  const closeModal = () => {
    setShowSignatureFramework(false);
    localStorage.setItem('hasIntroducedSignatureFramework', 'true');
  };

  return (
    <Dialog
      open={showSignatureFramework}
      onClose={closeModal}
    >
      <DialogTitle>
        Congratulations, our signature frameworks have been unlocked for you!
      </DialogTitle>
      <DialogContent>
        <img
          src='https://img.freepik.com/free-vector/open-locker_53876-25497.jpg?w=740&t=st=1708348424~exp=1708349024~hmac=beeaaae06b02f2075aa3bddd13a28ef447a9557da50e3deda8f26674757bbfbf'
          alt='unlocked-png'
          style={{
            height: '150px',
            width: '150px',
            margin: '0 auto',
            display: 'block',
          }}
        />
        <Typography
          variant='body1'
          sx={{ marginBottom: '10px' }}
        >
          1. Mindmap
        </Typography>
        <Typography
          variant='body1'
          sx={{ marginBottom: '10px' }}
        >
          2. DSA Visualisation
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={closeModal}
          color='primary'
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SignatureFrameworkUnlocked;

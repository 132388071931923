import React from 'react';
import { Typography } from '@material-ui/core';
import {
  Box,
  Button,
  InputLabel,
  IconButton,
  MenuItem,
  Select,
  TextField,
  FormControl,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const daysOfWeek = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

export default function TeachingDays({ formState, setFormState, edit }) {
  const handleTeachingDayChange = (index, field, value) => {
    setFormState((prevState) => {
      const updatedTeachingDays = [...prevState.teaching_days];
      updatedTeachingDays[index][field] = value;
      return { ...prevState, teaching_days: updatedTeachingDays };
    });
  };

  const addNewTeachingDay = () => {
    setFormState((prevState) => ({
      ...prevState,
      teaching_days: [...prevState.teaching_days, { day: '', time: '' }],
    }));
  };

  const removeTeachingDay = (index) => {
    setFormState((prevState) => {
      const updatedTeachingDays = prevState.teaching_days.filter(
        (_, i) => i !== index
      );
      return { ...prevState, teaching_days: updatedTeachingDays };
    });
  };

  return (
    <Box>
      <Typography variant='label'>Teaching Days</Typography>
      {formState.teaching_days &&
        formState.teaching_days.map((teachingDay, index) => (
          <Box
            key={index}
            sx={{ mt: 2 }}
          >
            <FormControl>
              <InputLabel id='day'>Day</InputLabel>

              <Select
                label='Day'
                labelId='day'
                id='day'
                value={teachingDay.day}
                onChange={(e) =>
                  handleTeachingDayChange(index, 'day', e.target.value)
                }
                disabled={!edit}
                size='small'
                sx={{ width: '7rem' }}
              >
                <MenuItem
                  value=''
                  disabled
                >
                  Select Day
                </MenuItem>
                {daysOfWeek.map((day, dayIndex) => (
                  <MenuItem
                    key={dayIndex}
                    value={day}
                  >
                    {day}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label='Time'
              type='time'
              size='small'
              value={teachingDay.time}
              onChange={(e) =>
                handleTeachingDayChange(index, 'time', e.target.value)
              }
              disabled={!edit}
              sx={{ marginLeft: '1rem', marginRight: '1rem' }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            {edit && (
              <IconButton onClick={() => removeTeachingDay(index)}>
                <DeleteIcon />
              </IconButton>
            )}
          </Box>
        ))}
      {edit && (
        <Box>
          <Button
            onClick={addNewTeachingDay}
            variant='contained'
            sx={{ mt: 2 }}
          >
            Add New Teaching Day
          </Button>
        </Box>
      )}
    </Box>
  );
}

// @ts-nocheck
import React from 'react';
import {
  Grid,
  Typography,
  Card,
  CardContent,
  Button,
  CardMedia,
  Box,
} from '@mui/material';
import Recording from '../../../../assets/Recording.png';
import Support from '../../../../assets/Support.png';
import { useFastrackPipeline } from 'src/layouts/EfPipelineContext/FastrackPipelineContex';

const BalanceSupportSection = ({ flowData, setFlowData, saveFlowData }) => {
  const { setScreenSource, trackButtonClick } = useFastrackPipeline();
  const handleContinue = () => {
    trackButtonClick({ source: 'screen_3h', eventName: 'continue' });
    saveFlowData({ screen: 'screen_3h' });
    setScreenSource('screen_3i');
  };
  return (
    <Box sx={{ textAlign: 'center' }}>
      <Typography
        variant='h4'
        sx={{
          fontWeight: 'bold',
          color: '#0078F0',
          marginBottom: '2rem',
          fontSize: { xs: '1.8rem', sm: '2rem', md: '2.5rem', lg: '2.8rem' },
        }}
      >
        You’re not alone – <br />
        we can help you balance it all!
      </Typography>

      <Grid
        container
        spacing={3}
        justifyContent='center'
      >
        <Grid
          item
          xs={12}
          sm={6}
          md={5}
          lg={4}
        >
          <Card
            sx={{
              boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
              borderRadius: '8px',
              overflow: 'hidden',
              height: '100%',
            }}
          >
            <CardMedia
              component='img'
              height='200'
              image={Recording}
              sx={{ objectFit: 'cover' }}
              alt='Missed Class'
            />
            <CardContent>
              <Typography
                variant='h6'
                gutterBottom
                sx={{ fontWeight: 'bold', textAlign: 'left' }}
              >
                Falling behind on topics?
              </Typography>
              <Typography
                variant='body1'
                color='textSecondary'
                sx={{ textAlign: 'left' }}
              >
                Our Mindmap tool breaks complex topics into smaller steps,
                making it easier to tackle challenges.
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={5}
          lg={4}
        >
          <Card
            sx={{
              boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
              borderRadius: '8px',
              overflow: 'hidden',
              height: '100%',
            }}
          >
            <CardMedia
              component='img'
              height='200'
              image={Support}
              sx={{ objectFit: 'cover' }}
              alt='Support Team'
            />
            <CardContent>
              <Typography
                variant='h6'
                gutterBottom
                sx={{ fontWeight: 'bold', textAlign: 'left' }}
              >
                Ready to level up?
              </Typography>
              <Typography
                variant='body1'
                color='textSecondary'
                sx={{ textAlign: 'left' }}
              >
                The Great Pyramid guides you through 14 levels, with mock test
                at each step to trcak your progress.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: { xs: '1.2rem', md: '1.8rem', lg: '2.2rem' },
        }}
      >
        <Button
          variant='outlined'
          sx={{
            borderRadius: '50%',
            padding: 0,
            paddingBottom: { xs: '0.5rem', sm: '0.5rem' },
            height: { xs: '3.5rem', sm: '3.5rem', md: '4rem', lg: '4.5rem' },
            width: { xs: '3.5rem', sm: '3.5rem', md: '4rem', lg: '4.5rem' },
            fontSize: { xs: '1.5rem', sm: '1.6rem', md: '1.7rem' },
            lineHeight: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={handleContinue}
        >
          →
        </Button>
      </Box>
    </Box>
  );
};

export default BalanceSupportSection;

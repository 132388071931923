// @ts-nocheck
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Box, Stack, Typography } from '@mui/material';
import { getNodeDepth } from '../ScreeningTest/ScreeningQuestions';
import Image3 from '../../assets/pop-up_3.png';
import Image4 from '../../assets/pop-up_4.png';

const TERMINAL_QUESTION = '5';

const ScreeningTestWelcomeScreenExitPopup = ({
  currentQuestionNumber,
  trackButtonClick,
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    document.addEventListener('mouseleave', handleMouseLeave);
    return () => {
      document.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, [showPopup, currentQuestionNumber]); // This is required because when we attach even listeners in react using `addEventListener`, we cannot access updated react states inside the event handler.

  const getQuestionNumber = () => {
    console.log(currentQuestionNumber);
    return `question_${getNodeDepth(currentQuestionNumber) + 1}`;
  };

  const handleMouseLeave = (event) => {
    if (event.clientY <= 0 && !showPopup) {
      setShowPopup(true);
      trackButtonClick({ source: getQuestionNumber(), eventName: 'exit' });
    }
  };

  const isTestNearToEnd = () => {
    const currentDepth = getNodeDepth(currentQuestionNumber);
    const actualDepth = getNodeDepth(TERMINAL_QUESTION);
    return Math.max(actualDepth - currentDepth, 0) < 2;
  };

  const handleClosePopup = (event, reason) => {
    setShowPopup(false);
    if (reason === 'backdropClick') {
      trackButtonClick({
        source: getQuestionNumber(),
        eventName: 'backdrop_click',
      });
    }
  };

  const handleButtonClick = () => {
    setShowPopup(false);
    trackButtonClick({
      source: getQuestionNumber(),
      eventName: isTestNearToEnd() ? 'finish' : 'continue',
    });
  };

  const getButtonText = () => {
    return isTestNearToEnd() ? 'Finish Test' : 'Continue Test';
  };

  const getPopupMessage = () => {
    if (isTestNearToEnd()) {
      return "You're close to completing the test! If you leave now, you'll lose all your progress and miss the chance to move faster through our screening process. Finish strong!";
    }
    return "Don't give up now after coming this far! Remember, the Fastrack Screening Test is shown only to a select few and comes with flexible payment benefits.";
  };

  const getPopupHeading = () => {
    return isTestNearToEnd()
      ? "Keep going – you're almost there!"
      : 'Finish what you started!';
  };

  const getImage = () => {
    return isTestNearToEnd() ? Image4 : Image3;
  };

  return (
    <Dialog
      maxWidth='sm'
      fullWidth
      open={showPopup}
      onClose={handleClosePopup}
      PaperProps={{
        style: {
          maxWidth: '500px',
          height: 'fit-content',
        },
      }}
    >
      <img
        style={{
          maxWidth: '500px',
        }}
        src={getImage()}
        alt='missout-image'
      />
      <Stack
        spacing={1.5}
        padding='1rem'
        maxWidth='inherit'
      >
        <Typography
          variant='h4'
          component='h4'
          sx={{
            fontFamily: 'Inter',
            flexWrap: 'wrap',
          }}
        >
          <b>{getPopupHeading()}</b>
        </Typography>

        <Typography
          variant='body1'
          component='p'
          sx={{
            fontFamily: 'Inter',
            flexWrap: 'wrap',
          }}
        >
          {getPopupMessage()}
        </Typography>

        <Box
          display='flex'
          justifyContent='center'
        >
          <Button
            variant='contained'
            onClick={handleButtonClick}
            sx={{
              fontFamily: 'Inter',
              margin: '10px',
              padding: '10px 15px',
              textAlign: 'center',
              textTransform: 'uppercase',
              transition: '0.5s',
              backgroundSize: '200% auto',
              color: 'white',
              borderRadius: '10px',
              border: 0,
              fontWeight: 700,
              boxShadow: '0px 0px 14px -7px #f09819',
              backgroundImage:
                'linear-gradient(45deg, #FF512F 0%, #F09819 51%, #FF512F 100%)',
              cursor: 'pointer',
              userSelect: 'none',
              WebkitUserSelect: 'none',
              touchAction: 'manipulation',
              '&:hover': {
                backgroundPosition: 'right center',
                color: '#fff',
                textDecoration: 'none',
              },
              '&:active': {
                transform: 'scale(0.95)',
              },
            }}
          >
            {getButtonText()}
          </Button>
        </Box>
      </Stack>
    </Dialog>
  );
};

export default ScreeningTestWelcomeScreenExitPopup;

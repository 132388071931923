import React from 'react';
import PropTypes from 'prop-types';
// material
import { Box } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { getUserType } from '../configs/auth';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  const navigate = useNavigate();
  const moveToAllPrograms = (event) => {
    const user = getUserType();
    if (user !== 'admin') {
      navigate("/student/my-courses");
    }
  }

  return (
      <Box
          component="img"
          src="/static/heycoach-logo.png"
          sx={{ width: 180, height: 40, ...sx }}
          onClick={moveToAllPrograms}
          style={{
            cursor: 'pointer'
          }}
      />
  );
}

// @ts-nocheck
import {
  Alert,
  Box,
  Grid,
  Snackbar,
  SnackbarContent,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import { LoadingButton } from '@material-ui/lab';
import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import FastrackOfferLetterCountdown from '../FastrackOfferLetterCountdown';
import EfPipelineOfferLetter from '../../ScreeningTest/EfPipelineOfferLetter';
import OfferLetterModal from '../OfferLetterModal';
import ConnectingScreen from '../FastrackOLScreen1/ConnectingScreen';
import BasicModePaymentCard from './BasicModePaymentCard';
import FastrackModePaymentCard from './FastrackModePaymentCard';
import axios from 'axios';
import { BASE_URL, getHeaders } from 'src/apis/urls';
import FastrackOfferLetterExitPopup from '../FastrackOfferLetterExitPopup';
import { useEfPipeline } from 'src/layouts/EfPipelineContext/EfPipelineContext';
import { useFastrackPipeline } from 'src/layouts/EfPipelineContext/FastrackPipelineContex';
import JoinWaitList from './JoinWaitList';

const PaymentModesScreen = () => {
  const {
    offerLetter,
    trackButtonClick,
    hasJoinedWaitlist,
    setHasJoinedWaitlist,
  } = useFastrackPipeline();
  const { setShowFastrackOfferLetter, setMessage } = useEfPipeline();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));
  const [showOfferLetter, setShowOfferLetter] = useState(false);
  const { link } = offerLetter;
  const expiryDate = new Date(offerLetter?.expires_at).getTime();
  const [time, setTime] = useState(expiryDate - new Date().getTime());

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(expiryDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [expiryDate]);

  const reset = () => {
    setShowFastrackOfferLetter(false);
    setMessage(
      'You need an invitation to apply to HeyCoach programs. If you are an existing learner, please check your login email id.'
    );
  };

  if (time <= 0) {
    reset();
    return;
  }

  return (
    <>
      <FastrackOfferLetterExitPopup setShowOfferLetter={setShowOfferLetter} />

      <OfferLetterModal
        {...{
          showOfferLetter,
          setShowOfferLetter,
          time,
          link,
        }}
      />

      <Grid
        container
        item
        rowSpacing={4}
        height='inherit'
        justifyContent='space-between'
        sx={{
          flexWrap: 'wrap',
          paddingTop: '0.5rem',
          paddingLeft: 'clamp(2rem, 3rem, 4.5rem)',
          paddingRight: 'clamp(2rem, 3rem, 4.5rem)',
        }}
      >
        <Grid
          item
          xs={12}
        >
          <Stack
            spacing={isSmall ? 2 : 1.3}
            alignItems='center'
          >
            <Stack
              direction='row'
              justifyContent='center'
              textAlign='center'
              spacing={1}
            >
              <Box
                sx={{
                  fontSize: 'clamp(1.2rem, 2rem, 2.2rem)',
                  alignSelf: 'center',
                }}
              >
                🌟
              </Box>

              <Typography
                variant='body1'
                component='p'
                sx={{
                  color: '#1976d2',
                  fontFamily: 'Inter',
                  fontWeight: 'bolder',
                  fontSize: 'clamp(1.8rem, 2.1rem, 2.4rem)',
                  width: 'fit-content',
                }}
              >
                Congratulations, you've passed!
              </Typography>
              <Box
                sx={{
                  fontSize: 'clamp(1.2rem, 2rem, 2.2rem)',
                  alignSelf: 'center',
                }}
              >
                🌟
              </Box>
            </Stack>

            <Typography
              variant='body1'
              component='p'
              sx={{
                textAlign: 'center',
                fontFamily: 'Inter',
                fontWeight: 'bold',
                fontSize: 'clamp(1.4rem, 1.5rem, 1.8rem)',
              }}
            >
              You're among the top candidates!
            </Typography>

            <Stack
              spacing={isSmall ? 1 : 0.5}
              alignItems='center'
            >
              <Typography
                variant='caption'
                component='p'
                textAlign='center'
                sx={{
                  textWrap: 'wrap',
                  fontFamily: 'Inter',
                  fontWeight: 400,
                  fontSize: '1.1rem',
                }}
              >
                With a selection rate of just 5%, you've made it through several
                layers of evaluations.
              </Typography>

              <Typography
                variant='caption'
                component='p'
                textAlign='center'
                sx={{
                  textWrap: 'wrap',
                  fontFamily: 'Inter',
                  fontWeight: 400,
                  fontSize: '1.1rem',
                }}
              >
                As a result of your impressive performance, you've been
                exclusively offered the Fastrack mode option.
              </Typography>
            </Stack>
          </Stack>
        </Grid>

        <Grid
          item
          xs={12}
        >
          <Stack
            direction={isSmall ? 'column-reverse' : 'row'}
            justifyContent='center'
            alignItems='center'
            spacing={3}
          >
            <BasicModePaymentCard setHasJoinedWaitlist={setHasJoinedWaitlist} />
            <FastrackModePaymentCard
              time={time}
              setShowOfferLetter={setShowOfferLetter}
            />
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default PaymentModesScreen;

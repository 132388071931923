import { Button, Stack } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  fetchCodingTestSubmitURL,
  fetchCodingTestProblemsURL,
  getHeaders,
} from '../../../../apis/urls';
import ErrorNotifier from '../../../ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../../ToastNotifications/SuccessNotifier';
import CodingTestCountdown from '../CodingTestCountdown';
import CodingTestConfirmationDialog from './CodingTestConfirmationDialog';
import CodingTestDetails from './CodingTestDetails';
import LoadingScreen from '../../../../pages/LoadingScreen';

function DetailedTest({ selectedTest, setSelectedTest }) {
  const [allContestProblems, setAllContestProblems] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const currentProgram = useSelector((state) => state.programs.currentProgram);
  const currTime = moment();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    getCodingTestProblems();
    return () => {};
  }, []);

  const getCodingTestProblems = () => {
    setLoading(true);
    axios
      .get(fetchCodingTestProblemsURL(selectedTest?.testId), {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((res) => {
        if (res.data?.length > 0) {
          const tmpAllProblems = res.data?.map((problem, index) => ({
            id: problem.code_problem.id,
            title: problem.code_problem.title,
            description: problem.code_problem.description,
            default_input: problem.code_problem?.default_testcase?.input ?? '',
            code_skeletons: problem.code_problem?.code_skeletons ?? [],
          }));
          setAllContestProblems(tmpAllProblems);
        }
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(err.response?.data?.message || err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleAutoSubmit();
  }, []);

  const handleAutoSubmit = () => {
    const attemptEndsAt = moment(selectedTest?.startedAt);
    attemptEndsAt.add(selectedTest?.duration, 'minutes');
    if (selectedTest.isLateAttempt) {
      if (attemptEndsAt.isSameOrBefore(currTime)) {
        handleSubmit('', selectedTest?.testId);
      }
    } else {
      // This means, learner started the test within the test validity window.
      if (
        selectedTest.endTime.isSameOrBefore(currTime) ||
        attemptEndsAt.isSameOrBefore(currTime)
      ) {
        handleSubmit('', selectedTest?.testId);
      }
    }
  };

  const handleSubmitClick = (e) => {
    setOpenConfirmationDialog(true);
  };

  const handleSubmit = (e, testId) => {
    axios
      .patch(
        fetchCodingTestSubmitURL(testId),
        {
          id: testId,
        },
        {
          headers: getHeaders(currentProgram?.programId),
        }
      )
      .then((res) => {
        setOpenConfirmationDialog(false);
        setIsSuccess(true);
        setSuccessMessage('You have successfully submitted the test.');
        const { pathname } = location;
        navigate(0); // soft reload. Don't use window.location.reload() because it will reset the states.
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(err?.response?.data?.message || err.message);
      });
  };

  const isTestInProgress = () => {
    const attemptEndsAt = moment(selectedTest?.startedAt);
    attemptEndsAt.add(selectedTest?.duration, 'minutes');

    const { hasJoined, hasSubmitted } = selectedTest;
    return hasJoined && !hasSubmitted && attemptEndsAt.isAfter(currTime);
  };

  return (
    <Stack>
      <LoadingScreen loading={loading} />
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}

      {isSuccess && (
        <SuccessNotifier
          message={successMessage}
          setIsSuccess={setIsSuccess}
        />
      )}

      {openConfirmationDialog && (
        <CodingTestConfirmationDialog
          openConfirmationDialog={openConfirmationDialog}
          setOpenConfirmationDialog={setOpenConfirmationDialog}
          handleClick={(e, testId) => handleSubmit(e, testId)}
          testId={selectedTest?.testId}
        />
      )}

      <>
        <Stack
          direction='row'
          spacing={2}
          display='flex'
          justifyContent='flex-end'
          sx={{
            cursor: 'pointer',
            m: 1,
          }}
        >
          {isTestInProgress() && (
            <CodingTestCountdown
              duration={selectedTest?.duration}
              startedAt={selectedTest?.startedAt}
              handleAutoSubmit={handleAutoSubmit}
            />
          )}
          <Button
            variant='contained'
            onClick={handleSubmitClick}
            sx={{
              width: '8rem',
              height: 35,
              m: 1,
            }}
          >
            End test
          </Button>
        </Stack>
      </>
      <CodingTestDetails
        allContestProblems={allContestProblems}
        selectedTest={selectedTest}
        isTestInProgress={isTestInProgress}
      />
    </Stack>
  );
}

export default DetailedTest;

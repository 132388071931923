import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import './Style.css';
import DateRangePickerInput from '../Atoms/DateRangePickerInput';
import axios from 'axios';

function Filters({ allPrograms, onApplyFilters, sortOrder, sortField }) {
  const [searchText, setSearchText] = useState('');
  const [programId, setProgramId] = useState('');
  const [status, setStatus] = useState('open');
  const [category, setCategory] = useState('');
  const [assigneeName, setAssigneeName] = useState('');
  const [dateRange, setDateRange] = useState([null, null]);

  const statuses = {
    closed: {
      color: 'Red',
    },
    open: {
      color: 'Green',
    },
  };
  const categories = [
    'request_callback',
    'others',
    'doubt_solving_assistance',
    'detractor',
  ];

  useEffect(() => {
    handleApplyFilters();
  }, [sortField, sortOrder]);

  const handleApplyFilters = () => {
    const fromDate = dateRange[0] ? new Date(dateRange[0]).toISOString() : null;
    const toDate = dateRange[1] ? new Date(dateRange[1]).toISOString() : null;
    const queryParams = {
      searchText,
      programId,
      status,
      category,
      fromDate,
      toDate,
      assigneeName,
    };
    onApplyFilters(queryParams);
  };

  const clearAllFilters = () => {
    setSearchText('');
    setProgramId('');
    setStatus('');
    setCategory('');
    setAssigneeName('');
    handleApplyFilters();
    setDateRange([null, null]);
  };

  return (
    <Box
      sx={{
        padding: '10px 5px',
        fontSize: '14px',
      }}
    >
      <Box
        display='flex'
        flexWrap='wrap'
        gap={2}
        width='100%'
        paddingX={3}
      >
        <TextField
          sx={{ width: 'calc(30% - 0px)', padding: '0' }}
          type='text'
          placeholder='Search with name or learner id...'
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment
                position='start'
                sx={{ padding: '0' }}
              >
                <i className='fas fa-search' />
              </InputAdornment>
            ),
            sx: { fontSize: '14px', height: '40px' },
          }}
        />
        <TextField
          sx={{ width: 'calc(30% - 0px)', padding: '0' }}
          type='text'
          placeholder='Search assignee with name'
          value={assigneeName}
          onChange={(e) => setAssigneeName(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment
                position='start'
                sx={{ padding: '0' }}
              >
                <i className='fas fa-search' />
              </InputAdornment>
            ),
            sx: { fontSize: '14px', height: '40px' },
          }}
        />

        <Select
          sx={{ width: 'calc(30% - 0px)', fontSize: '14px', height: '40px' }}
          value={programId}
          onChange={(e) => setProgramId(e.target.value)}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 350,
              },
            },
          }}
        >
          <MenuItem
            value=''
            sx={{ fontSize: '14px', height: '40px' }}
          >
            All Programs
          </MenuItem>
          {Object.values(allPrograms).map((program) => (
            <MenuItem
              key={program.programId}
              value={program.programId}
              sx={{ fontSize: '14px', height: '40px' }}
            >
              {program.internal_name}
            </MenuItem>
          ))}
        </Select>

        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <span>Status :&nbsp;</span>
          <Select
            sx={{ width: '150px', fontSize: '14px', height: '40px' }}
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            renderValue={() => (status ? status : 'All')}
          >
            <MenuItem
              value=''
              sx={{ fontSize: '14px', height: '40px' }}
            >
              All
            </MenuItem>
            {statuses
              ? Object.keys(statuses).map((status_item) => (
                  <MenuItem
                    key={status_item}
                    value={status_item}
                    sx={{ fontSize: '14px', height: '40px' }}
                  >
                    {status_item}
                  </MenuItem>
                ))
              : null}
          </Select>
        </Box>

        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <span>Category :&nbsp;</span>
          <Select
            sx={{ width: '150px', fontSize: '14px', height: '40px' }}
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            renderValue={() => (category ? getCategory(category) : 'All')}
          >
            <MenuItem
              value=''
              sx={{ fontSize: '14px', height: '40px' }}
            >
              All
            </MenuItem>
            {categories
              ? categories.map((category_item) => (
                  <MenuItem
                    key={category_item}
                    value={category_item}
                    sx={{ fontSize: '14px', height: '40px' }}
                  >
                    {getCategory(category_item)}
                  </MenuItem>
                ))
              : null}
          </Select>
        </Box>
        <DateRangePickerInput
          value={dateRange}
          setValue={setDateRange}
        />
      </Box>

      <Box
        paddingX={2}
        paddingTop={1}
      >
        <Button
          sx={{ width: 'calc(20% - 13px)', height: '40px', margin: '10px' }}
          onClick={handleApplyFilters}
          variant='contained'
        >
          Apply Filters
        </Button>

        <Button
          sx={{ width: 'calc(20% - 13px)', height: '40px', margin: '10px' }}
          onClick={clearAllFilters}
          variant='outlined'
        >
          Reset
        </Button>
      </Box>
    </Box>
  );
}

function getCategory(category) {
  if (!category) {
    return '';
  }
  return category
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export default Filters;

// @ts-nocheck
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import axios from 'axios';
import DetailedTest from '../CodingTest/DetailedTest/DetailedTest';
import {
  fetchCodingTestJoinNowURL,
  getHeaders,
  requestMockInterviewURL,
} from '../../../apis/urls';
import { getUserId } from '../../../configs/auth';

const headers = [
  'Name',
  'Start Time',
  'End Time',
  'Duration (minutes)',
  'Action',
];

function LevelTestTable({ allTests, setErrorMessage, setHasError }) {
  const currentProgram = useSelector((state) => state.programs.currentProgram);
  const enrollmentLevels = useSelector(
    (state) => state.users.enrollments_levels
  );
  const [selectedTest, setSelectedTest] = useState({});
  const [mockInterviews, setMockInterviews] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    const mockInterviewStates = ['C11', 'C12', 'C13'];

    const len = enrollmentLevels.length;
    const interviewLevels = [];

    enrollmentLevels.forEach((el) => {
      if (mockInterviewStates.includes(el.level.name)) {
        interviewLevels.push(el);
      }
    });

    setMockInterviews(interviewLevels);
    return () => {};
  }, []);

  const joinTest = (test, index) => {
    if (test.hasJoined) {
      setSelectedTest(test);
      return;
    }

    axios
      .post(
        fetchCodingTestJoinNowURL(test.testId),
        { id: test.testId },
        {
          headers: getHeaders(currentProgram?.programId),
        }
      )
      .then((res) => {
        setSelectedTest({
          ...test,
          hasJoined: true,
          startedAt: moment(),
        });
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(err.response?.data?.message || err.message);
      });
  };

  const getTestButtonProperties = (test) => {
    const currentTime = moment();
    const options = {
      text: 'Expired',
      disabled: true,
    };

    if (test.startTime.isAfter(currentTime)) {
      options.text = 'Scheduled';
    } else if (currentTime.isBetween(test.startTime, test.endTime)) {
      if (test.hasSubmitted) {
        options.text = 'Submitted';
      } else if (!test.hasJoined) {
        options.text = 'Start now';
        options.disabled = false;
      } else {
        const attemptEndsAt = moment(test.startedAt);
        attemptEndsAt.add(test.duration, 'minutes');

        if (currentTime.isAfter(attemptEndsAt)) {
          options.text = 'Submitted';
        } else {
          options.disabled = false;
          options.text = 'Resume';
        }
      }
    } else if (test.hasJoined) {
      options.text = 'Submitted';
    }
    return options;
  };

  const getMockInterviewButtonProperties = (interview) => {
    const options = {
      disabled: true,
    };

    const format = 'MMM D YY, h:mm A';
    if (!interview.lockedTill) {
      options.text = `Locked till ${moment(interview.lockedTill).format(
        format
      )}`;
    } else {
      options.text = `Request Mock Inteview`;
      options.disabled = false;
    }

    return options;
  };

  const requestMockInterview = () => {
    axios
      .post(requestMockInterviewURL(getUserId()), {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((res) => {
        setIsSuccess(true);
        setSuccessMessage('Mock Interview successfully requested.');
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(err.response?.data?.message || err.message);
      });
  };

  if (Object.keys(selectedTest).length > 0) {
    return (
      <DetailedTest
        selectedTest={selectedTest}
        setSelectedTest={setSelectedTest}
      />
    );
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ width: '100%' }}>
        <TableHead>
          <TableRow>
            {headers.map((header, index) => {
              return <TableCell key={index}>{header}</TableCell>;
            })}
          </TableRow>
        </TableHead>

        <TableBody>
          {allTests.map((test, index) => {
            const options = getTestButtonProperties(test);
            return (
              <TableRow
                key={index}
                sx={{
                  '&:last-child td, &:last-child th': {
                    border: 0,
                  },
                }}
              >
                <TableCell
                  component='th'
                  scope='row'
                >
                  {test.name}
                </TableCell>

                <TableCell>
                  {test.startTime.format('MMM Do YYYY, h:mm A')}
                </TableCell>

                <TableCell>
                  {test.endTime.format('MMM Do YYYY, h:mm A')}
                </TableCell>

                <TableCell>{test.duration}</TableCell>

                <TableCell>
                  <Button
                    variant='contained'
                    color='success'
                    onClick={(e) => joinTest(test, index)}
                    {...(options.disabled && {
                      disabled: true,
                    })}
                  >
                    {options.text}
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}

          {/* {mockInterviews.length > 0 &&
            mockInterviews.map((interview, index) => {
              const options = getMockInterviewButtonProperties(interview);
              return (
                <TableRow
                  key={index}
                  sx={{
                    '&:last-child td, &:last-child th': {
                      border: 0,
                    },
                  }}
                >
                  <TableCell
                    component='th'
                    scope='row'
                  >
                    {test.name}
                  </TableCell>

                  <TableCell>-</TableCell>

                  <TableCell>-</TableCell>

                  <TableCell>{test.duration}</TableCell>

                  <TableCell>
                    <Button
                      variant='contained'
                      color='success'
                      onClick={requestMockInterview}
                      {...(options.disabled && {
                        disabled: true,
                      })}
                    >
                      {options.text}
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })} */}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default LevelTestTable;

import React from 'react';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import LogoV2 from 'src/components/LogoV2';
import OnboardingTabLayout from './OnboardingTabLayout';

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
  backgroundColor: 'rgb(229, 245, 253)',
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export const Onboarding = () => {
  return (
    <RootStyle>
      <MainStyle>
        <LogoV2 />
        <OnboardingTabLayout />
      </MainStyle>
    </RootStyle>
  );
};

import React from 'react';
import { List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import { FiberManualRecord } from '@mui/icons-material';

const MultiListComponent = ({ data }) => {
  return (
    <List>
      <ListItem>
        <ListItemText primary={data.title} />
      </ListItem>
      <List sx={{ pl: 2 }}>
        {data.lists.map((operator, index) => (
          <ListItem
            key={index}
            sx={{ paddingY: '2px', alignItems: 'flex-start' }}
          >
            <ListItemIcon>
              <FiberManualRecord sx={{ width: '0.5em' }} />
            </ListItemIcon>
            <ListItemText primary={operator} />
          </ListItem>
        ))}
      </List>
    </List>
  );
};

export default MultiListComponent;

import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import {
  addPlacementRequests,
  fetchAllCompanyRole,
  fetchAllPlacementRequests,
  fetchAllRoleTechStacks,
  getHeaders,
} from '../../../apis/urls';
import { getUserId } from '../../../configs/auth';
import SuccessNotifier from '../../ToastNotifications/SuccessNotifier';
import ErrorNotifier from '../../ToastNotifications/ErrorNotifier';

const LearnerHome = ({ selectedFilter }) => {
  const [allCompaniesData, setAllCompaniesData] = useState([]);
  const [allPlacementRequestsData, setAllPlacementRequestsData] = useState([]);
  const [roleTechStacks, setRoleTechSTacks] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [isGettingData, setIsGettingData] = useState(false);
  const [showLoaderInButton, setShowLoaderInButton] = useState(false);
  const currentProgram = useSelector(
    (state) => state?.programs?.currentProgram
  );

  useEffect(() => {
    getAllDetailsFromPlacementRequests();
    getAllDetailsFromCompanyRoles();
    getAllRoleTechStacks();
    return () => {};
  }, [selectedFilter]);

  const getAllDetailsFromPlacementRequests = () => {
    axios
      .get(fetchAllPlacementRequests(), {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((res) => {
        const liveCompanies = res?.data?.filter(
          (request) =>
            request.company_role.job_status === 'live' &&
            request.company_role.approval_status === 'approved'
        );
        setAllPlacementRequestsData(liveCompanies || []);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage('Error while getting companies');
        setIsGettingData(false);
      });
  };

  const getAllDetailsFromCompanyRoles = () => {
    setIsGettingData(true);
    axios
      .get(fetchAllCompanyRole(), {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((res) => {
        const liveCompanies = res?.data?.filter(
          (request) =>
            request.job_status === 'live' &&
            request.approval_status === 'approved'
        );
        setAllCompaniesData(liveCompanies || []);
        setIsGettingData(false);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage('Error while getting companies');
        setIsGettingData(false);
      });
  };

  const getAllRoleTechStacks = () => {
    axios
      .get(fetchAllRoleTechStacks(), {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((res) => {
        if (!res.data) {
          return;
        }
        setRoleTechSTacks(res?.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleRequestContact = (companyRoleId) => {
    const learnerRequest = {
      id: getUserId(),
      company_role_id: companyRoleId,
    };
    setShowLoaderInButton((prev) => ({
      ...prev,
      [companyRoleId]: true,
    }));
    axios
      .post(addPlacementRequests(), learnerRequest, {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((res) => {
        if (res.data.message) {
          setHasError(true);
          setErrorMessage(res.data.message);
          setShowLoaderInButton((prev) => ({
            ...prev,
            [companyRoleId]: false,
          }));
        } else {
          setIsSuccess(true);
          setSuccessMessage('Request sent for details');
          setShowLoaderInButton((prev) => ({
            ...prev,
            [companyRoleId]: false,
          }));
        }
      })
      .catch((err) => {
        setHasError(true);
        setErrorMessage('Error sending request for details');
        setShowLoaderInButton((prev) => ({
          ...prev,
          [companyRoleId]: false,
        }));
      });
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      {isSuccess && (
        <SuccessNotifier
          message={successMessage}
          setIsSuccess={setIsSuccess}
        />
      )}
      <Box
        sx={{
          padding: '30px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            padding: '0 10px',
            gap: '20px',
            flexWrap: 'wrap',
            position: 'relative',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              textAlign: 'center',
              position: 'absolute',
              width: '100%',
              height: '100%',
            }}
          >
            {isGettingData && <CircularProgress />}
          </Box>
          {(!isGettingData && allCompaniesData.length === 0) ||
          allCompaniesData.filter(
            (company) =>
              !allPlacementRequestsData.some(
                (request) => request.company_role.id === company.id
              )
          ).length === 0 ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                textAlign: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography sx={{ fontSize: '40px', color: '#b0b0b0' }}>
                No new roles to show
              </Typography>
            </Box>
          ) : (
            allCompaniesData.map((company) => (
              <Box
                sx={{
                  boxShadow: 2,
                  padding: '15px',
                  borderRadius: '10px',
                  width: '430px',
                  border: '1px solid #D2D2D2',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box
                    component='img'
                    alt='logo'
                    src={company?.logo}
                    sx={{
                      width: '94px',
                      height: '74px',
                      backgroundColor: '#D2D2D2',
                      borderRadius: '5px',
                    }}
                  />
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      textAlign: 'center',
                      alignItems: 'start',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'start',
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: '28px',
                          fontWeight: '600',
                          color: '#4F4F4F',
                        }}
                      >
                        {company.role_title}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'start',
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: '22px',
                          fontWeight: '500',
                          color: '#4F4F4F',
                        }}
                      >
                        {company.company.name}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'start',
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: '18px',
                          fontWeight: '400',
                          color: '#4F4F4F',
                        }}
                      >
                        {company.location} | {company.job_type}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '20px',
                    padding: '20px 0 10px 0',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: '900px',
                      color: '#4F4F4F',
                    }}
                  >
                    Tech Stack
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '10px',
                  }}
                  key={company.id}
                >
                  {roleTechStacks
                    .filter(
                      (techStack) => techStack.company_role_id === company.id
                    )
                    .slice(0, 5)
                    .map((filteredTechStack, index) => (
                      <Typography
                        key={filteredTechStack.id}
                        sx={{
                          backgroundColor: '#D2E7FF',
                          padding: '7px',
                          fontSize: '13px',
                          borderRadius: '30px',
                          width: 'fit-content',
                        }}
                      >
                        {filteredTechStack.tech_stack.name}
                      </Typography>
                    ))}
                  {roleTechStacks
                    .filter(
                      (techStack) => techStack.company_role_id === company.id
                    )
                    .slice(5).length > 0 && (
                    <Typography
                      sx={{
                        fontSize: '13px',
                        borderRadius: '30px',
                        width: 'fit-content',
                        fontWeight: '700',
                        color: '#424242',
                        justifyContent: 'center',
                        textAlign: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      +
                      {
                        roleTechStacks
                          .filter(
                            (techStack) =>
                              techStack.company_role_id === company.id
                          )
                          .slice(5).length
                      }{' '}
                      more
                    </Typography>
                  )}
                </Box>

                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    padding: '20px 0 0 0',
                  }}
                >
                  <Box
                    sx={{
                      padding: '5px 10px',
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '20px',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '18px',
                        fontWeight: '900px',
                        color: '#1976D2',
                      }}
                    >
                      <strong>
                        CTC {company.min_ctc} - {company.max_ctc} LPA
                      </strong>
                    </Typography>
                  </Box>

                  <LoadingButton
                    loading={showLoaderInButton[company.id]}
                    sx={{
                      backgroundColor: '#1976D2',
                    }}
                    variant='contained'
                    onClick={() => handleRequestContact(company.id)}
                  >
                    Request details
                  </LoadingButton>
                </Box>
              </Box>
            ))
          )}
        </Box>
      </Box>
    </>
  );
};

export default LearnerHome;

export const formatDate = (dateString) => {
  if (!dateString) return '-';
  const lastDate = new Date(dateString);
  const currentDate = new Date();
  const timeDiff = currentDate - lastDate;
  const dayDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
  if (dayDiff === 0) return 'Today';
  if (dayDiff === 1) return 'Yesterday';
  return `${dayDiff} days ago`;
};

export const transformLearnerData = (learners) => {
  return learners.map((item) => ({
    learner: item.personal_details.name + ' $' + item.personal_details.id,
    learnerId: item.personal_details.id,
    name: item.personal_details.name,
    mobileNumber: item.personal_details.phone,
    email: item.personal_details.email,
    leetCodeId: item.personal_details.leetcode_username,
    dob: item.personal_details.birth_date,
    profession: item.personal_details.profession,
    college: item.personal_details.college,
    attended: item.attendance_progress.total_sessions_joined,
    scheduled: item.attendance_progress.total_sessions_conducted,
    assignmetCompleted: item.assignment_progress.submitted_assignments,
    assignmetTotal: item.assignment_progress.total_assignments,
    mindmapCompleted: item.mindmap_progress.mindmap_completed,
    mindmapTotal: item.mindmap_progress.total_mindmap_problems,
    tgpCompleted: item.tgp_progress.max_cleared_tgp_level,
    tgpTotal: item.tgp_progress.max_achievable_level,
    tgpPercentage: item.tgp_progress.percentage,
    programName: item.personal_details.program_name,
    programId: item.personal_details.program_id,
    lastCalled: formatDate(item.last_interaction?.created_at),
    attendance: item.attendance_progress.attendance_percentage,
    assignment: item.assignment_progress.percentage,
    tqpLevel: item.tgp_progress.max_cleared_tgp_level,
    enrollmentId: item.personal_details?.enrollment_id,
    lastActivity: item.last_activity,
  }));
};

import React from 'react';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { topBarContents } from './StudentNavbarData';
import DashboardNavbarV2 from 'src/layouts/shared/DashboardNavbarV2';
import '../../App.css';
import DividerComponent from './DividerComponent';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { theme } from 'twin.macro';
import FooterV2 from 'src/layouts/studentDashboard/FooterV2';

const TabRenderV2 = ({ value, subTabValue }) => {
  const MainStyle = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(3),
    paddingLeft: theme.spacing(1),
    height: '100vh',
    overflow: 'auto',
    width: '100%',
    margin: '20px',
    '@media (min-width: 1280px)': {
      width: '78vw',
      marginRight: '0.6%',
    },
    '@media (min-width: 1440px)': {
      width: '80vw',
    },
    '@media (min-width: 1480px)': {
      width: '83vw',
    },
  }));
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          marginTop: '20px',
          marginBottom: '20px',
          gap: 0,
          position: 'relative',
        }}
        className='tab-render'
      >
        <DashboardNavbarV2 />
        <DividerComponent />
        {/* {topBarContents[value].children.length > 0 ? (
          <MainStyle>
            <>{topBarContents[value].children[subTabValue].component}</>
          </MainStyle>
        ) : (
          <MainStyle>
            <Outlet />
          </MainStyle>
        )} */}
        <MainStyle>
          {topBarContents[value].children.length > 0 ? (
            topBarContents[value].children[subTabValue].component
          ) : (
            <Outlet />
          )}
        </MainStyle>
        <FooterV2 />
      </Box>
    </>
  );
};

export default TabRenderV2;

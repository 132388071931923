import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@material-ui/core';

export default function SessionLogDetails({
  openLog,
  setOpenLog,
  mentorSession,
}) {
  return (
    <Dialog
      open={openLog}
      onClose={() => setOpenLog(false)}
      scroll='paper'
      fullWidth
      maxWidth='md'
    >
      <DialogActions>
        <Button onClick={() => setOpenLog(false)}>Close</Button>
      </DialogActions>

      <DialogContent dividers>
        <DialogContentText tabIndex={-1}>
          <Stack>
            <FormControl
              sx={{ minWidth: 120 }}
              disabled
            >
              <InputLabel id='duration'>Logged duration</InputLabel>
              <Select
                labelId='duration'
                name='duration'
                id='duration'
                value='30'
                label='Duration'
                disabled='true'
              >
                <MenuItem value='30'>30</MenuItem>
              </Select>
            </FormControl>

            <Typography
              id='flow'
              variant='body2'
            >
              Agenda
            </Typography>
            <TextField
              id='agenda'
              multiline
              value={mentorSession?.agenda}
              placeholder={"Agenda not Given"}
              sx={{ width: '100%', marginBottom: 4 }}
              inputProps={{ readOnly: true }}
              minRows={3}
            />
            {
              <>
                <Typography
                  id='feedback'
                  variant='body2'
                >
                  Coach Feedback
                </Typography>
                <TextField
                  multiline
                  id='feedback'
                  value={mentorSession?.description}
                  placeholder='Feedback not Given.'
                  style={{ width: '100%', marginBottom: 4 }}
                  inputProps={{ readOnly: true }}
                  minRows={3}
                />
              </>
            }
          </Stack>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

import React from 'react';
import { Box } from '@mui/material';

export default function ChatLabels({ labels }) {
  return (
    <Box sx={{ width: '100%', mt: 1 }}>
      {labels.map((label) => (
        <div
          key={label.id}
          style={{
            display: 'inline-block',
            alignItems: 'center',
            marginRight: '5px',
            marginBottom: '2px',
          }}
        >
          <span
            style={{
              backgroundColor: label.bgcolor,
              color: label.fontcolor,
              padding: '5px 8px',
              borderRadius: '4px',
              fontSize: '12px',
            }}
          >
            {label.name}
          </span>
        </div>
      ))}
    </Box>
  );
}

// @ts-nocheck
import {
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import AlarmIcon from '@mui/icons-material/Alarm';
import React from 'react';
import axios from 'axios';
import { useEfPipeline } from '../../layouts/EfPipelineContext/EfPipelineContext';
import { getHeaders, BASE_URL } from '../../apis/urls';

const ScreeningInterviewPreferanceConfirmation = ({
  trackButtonClick,
  setScreeningTestStatus,
  setShowInstructions,
  setHasError,
  setErrorMessage,
  setShouldConfirmScreeningInterview,
}) => {
  const { setLoading, formId, setFormId, setMessage, setShowScreeningTest } =
    useEfPipeline();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const preferScreeningInterview = async () => {
    try {
      setLoading(true);
      const res = await axios.post(
        `${BASE_URL}/api/eligibility-forms/${formId}/screening-test`,
        {
          action_type: 'prefer_interview',
        },
        {
          headers: getHeaders(),
        }
      );

      const { message } = res.data;
      if (message) {
        setFormId(null);
        setMessage(message);
        setShowScreeningTest(false);
        return;
      }

      setScreeningTestStatus('prefer_interview');
      setShouldConfirmScreeningInterview(false);
    } catch (err) {
      console.log(err);
      setHasError(true);
      setErrorMessage(
        err.response?.data?.message ?? 'Oop! Something went wrong.'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Stack
      spacing={4}
      alignItems='center'
      justifyContent='center'
      sx={{
        mt: '5rem',
        '@media (max-width: 400px)': {
          mt: 0,
        },
        backgroundColor: 'white',
        borderRadius: '8px',
        width: '60%',
        padding: '4rem',
        [theme.breakpoints.down('md')]: {
          width: '98%',
          padding: '2rem',
          height: 'fit-content',
          paddingBottom: '4rem',
          paddingTop: '4rem',
        },
      }}
    >
      <Stack spacing={2}>
        <Typography
          variant='h5'
          component='h5'
          sx={{
            textAlign: 'center',
            textWrap: 'pretty',
            fontFamily: 'Inter',
            fontWeight: 500,
            [theme.breakpoints.down('sm')]: {
              fontSize: '1.1rem',
            },
          }}
        >
          The selection rate of Fastrack Screening Test is significantly higher
          than that of the online screening interview.
        </Typography>

        <Typography
          variant='h5'
          component='h5'
          sx={{
            textAlign: 'center',
            textWrap: 'pretty',
            fontFamily: 'Inter',
            fontWeight: 500,
            [theme.breakpoints.down('sm')]: {
              fontSize: '1.1rem',
            },
          }}
        >
          We encourage you to opt for the Fastrack Screening Test, which
          typically takes less than 10 minutes to complete.
        </Typography>
      </Stack>

      <Stack
        spacing={2}
        alignItems='center'
      >
        <Button
          variant='contained'
          onClick={() => {
            trackButtonClick({
              source: 'last_call_screen',
              eventName: 'fastrack_screening_test',
            });
            setShowInstructions(true);
            setShouldConfirmScreeningInterview(false);
          }}
          sx={{
            boxShadow: 'none',
            borderRadius: '0.3rem',
            background: '#1976d2',
            fontSize: '1rem',
            ':hover': {
              background: '#1976d2',
            },
            [theme.breakpoints.down('sm')]: {
              fontSize: '0.75rem',
              fontWeight: 900,
            },
          }}
          size='large'
          disableElevation
          startIcon={<AlarmIcon />}
        >
          {isMobile
            ? `Fastrack Screening Test`
            : `Continue to Fastrack Screening Test`}
        </Button>

        <Typography
          variant={isMobile ? 'caption' : 'body1'}
          component='p'
          sx={{
            color: '#1976d2',
            textDecoration: 'underline #1976d2 solid 2px',
            fontFamily: 'Inter',
            ':hover': {
              cursor: 'pointer',
            },
            textDecorationSkipInk: 'none',
          }}
          onClick={() => {
            trackButtonClick({
              source: 'last_call_screen',
              eventName: 'prefer_screening_interview',
            });
            preferScreeningInterview();
          }}
        >
          I'd still prefer screening interview
        </Typography>
      </Stack>
    </Stack>
  );
};

export default ScreeningInterviewPreferanceConfirmation;

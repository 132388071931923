import React from 'react';
import {
  FormControlLabel,
  Checkbox,
  Grid,
  FormControl,
  InputLabel,
  TextField,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';

export default function QuizAttemptDetails({ formState, setFormState }) {
  const handleFormChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;

    setFormState((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };

  return (
    <Grid
      container
      spacing={2}
      mt={1}
    >
      <Grid
        item
        xs={12}
        sm={6}
      >
        <TextField
          label='Opens At'
          name='opens_at'
          type='datetime-local'
          value={formState.opens_at}
          onChange={handleFormChange}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>

      <Grid
        item
        xs={12}
        sm={6}
      >
        <TextField
          label='Closes At'
          name='closes_at'
          type='datetime-local'
          value={formState.closes_at}
          onChange={handleFormChange}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>

      <Grid
        item
        xs={2}
        sm={4}
      >
        <FormControl fullWidth>
          <InputLabel>Quiz Type</InputLabel>
          <Select
            label='Quiz Type'
            name='quiz_type'
            value={formState.quiz_type}
            onChange={handleFormChange}
          >
            <MenuItem value='practice'>Practice</MenuItem>
            <MenuItem value='exam'>Exam</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      {formState.quiz_type === 'practice' && (
        <Grid
          item
          xs={2}
          sm={4}
        >
          <TextField
            label='Duration (minutes)'
            name='duration'
            type='number'
            value={formState.duration}
            onChange={handleFormChange}
          />
        </Grid>
      )}

      <Grid
        item
        xs={2}
        sm={4}
      >
        <FormControlLabel
          sx={{ flexGrow: 1, flexBasis: '22%' }}
          control={
            <Checkbox
              name='show_scores'
              checked={formState.show_scores}
              onChange={handleFormChange}
            />
          }
          label='Show Scores'
        />
      </Grid>
      {formState.quiz_type === 'exam' && (
        <Typography
          m={2}
          variant='caption'
        >
          Please note duration of the exam will be calculated based on the start
          date & time and end date & time
        </Typography>
      )}
    </Grid>
  );
}

import React from 'react';
import { useSelector } from 'react-redux';
import { getUserType } from '../../configs/auth';
import ProgramsSidebar from '../../components/_dashboard/Programs/ProgramsSidebar';
import DashboardSidebar from './DashboardSidebar';

export default function SidebarSelection({ open, setOpen }) {
  const currentProgram = useSelector((state) => state.programs.currentProgram);
  const programName = currentProgram?.internalNameSlug ?? '';
  const userType = getUserType();
  return (
    <>
      {programName === '' || userType === 'problem_setter' ? (
        <DashboardSidebar
          isOpenSidebar={open}
          onCloseSidebar={() => setOpen(false)}
        />
      ) : (
        <ProgramsSidebar
          isOpenSidebar={open}
          onCloseSidebar={() => setOpen(false)}
          programName={programName}
        />
      )}
    </>
  );
}

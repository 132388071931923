// @ts-nocheck
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { Icon } from '@iconify/react';
import VideocamIcon from '@mui/icons-material/Videocam';
import AddToDriveIcon from '@mui/icons-material/AddToDrive';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import ChatIcon from '@mui/icons-material/Chat';
import { Badge } from '@mui/material';
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  Chip,
} from '@material-ui/core';
import Button from '@mui/material/Button';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getUserId, getUserType } from '../../configs/auth';
import Logo from '../../components/Logo';
import { MHidden } from '../../components/@material-extend';
import AccountPopover from './AccountPopover';
import BellNotifications from './Notifications';
import HelpButton from './HelpButton';
import { fetchLiveSessionLinks, getHeaders, showChat } from '../../apis/urls';
import ErrorNotifier from '../../components/ToastNotifications/ErrorNotifier';
import { chatUsers } from './chatUsers';
import FocusModeSwitch from '../../components/LearnerModule/Atoms/FocusModeSwitch';

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  position: 'sticky', // Make AppBar sticky
  top: 0,
  borderRadius: '20px',
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 3),
  },
}));

DashboardNavbarV2.propTypes = {
  onOpenSidebar: PropTypes.func,
};

function NavBarTabs({ currTab, setCurrTab }) {
  const currentProgram = useSelector((state) => state.programs.currentProgram);
  const programName = currentProgram?.name; // original name

  return (
    <Box>
      {programName && (
        <Chip
          sx={{
            padding: '5px',
            fontWeight: 'bold',
            // marginInlineStart: '2rem',
          }}
          label={programName}
        />
      )}
      {/* <ButtonGroup variant="contained" size="medium" sx={{marginInlineStart: "2rem"}}> */}
      {/* <Button 
          value="explore" 
          onClick={handleClick}
          color={(currTab === "explore") ? "primary" : "inherit"}
        >
          Explore
        </Button>
        <Button 
          value="university-club"
          onClick={handleClick}
          color={(currTab === "university-club") ? "primary" : "inherit"}
        >
          University Club
        </Button> */}
      {/* </ButtonGroup> */}
    </Box>
  );
}

function DashboardNavbarV2({ onOpenSidebar }) {
  const [currTab, setCurrTab] = useState('my-courses');
  const userRole = getUserType();
  const [driveAndMeetLinks, setDriveAndMeetLinks] = useState({});
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const currentProgram = useSelector((state) => state.programs.currentProgram);
  const [showChatIcon, setShowChatIcon] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [isSticky, setIsSticky] = useState(false);

  const K_PROGRAM_MANAGER = 'program_manager';

  useEffect(() => {
    axios
      .get(showChat(), {
        headers: getHeaders(),
      })
      .then((res) => {
        setShowChatIcon(res?.data?.show_chat);
        setUnreadCount(res?.data?.unread_count);
      })
      .catch((err) => {
        console.log(err);
      });
    return () => {};
  }, []);

  useEffect(() => {
    if (currentProgram?.hasProgramStarted) {
      axios
        .get(fetchLiveSessionLinks(), {
          headers: getHeaders(currentProgram?.programId),
        })
        .then((res) => {
          setDriveAndMeetLinks(res.data?.links);
        })
        .catch((err) => {
          console.log(err);
          setHasError(true);
          setErrorMessage(err.response?.data?.message || err.message);
        });
    }
    return () => {};
  }, [currentProgram]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleJoinLiveMeetClick = () => {
    const googleMeetLink = driveAndMeetLinks?.google_meet_link;
    if (googleMeetLink) {
      window.open(googleMeetLink, '_blank', 'noopener noreferrer');
    }
  };

  return (
    <RootStyle>
      <ToolbarStyle>
        <MHidden width='lgUp'>
          <IconButton
            onClick={onOpenSidebar}
            sx={{ mr: 1, color: 'text.primary' }}
          >
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>
        {/* <Box sx={{ display: 'inline-flex' }}>
          <Logo />
        </Box> */}
        {hasError ? (
          <ErrorNotifier
            message={errorMessage}
            setHasError={setHasError}
          />
        ) : (
          ''
        )}
        {(userRole === 'student' || userRole === 'coach') && (
          <NavBarTabs
            currTab={currTab}
            setCurrTab={setCurrTab}
          />
        )}

        <Box sx={{ flexGrow: 1 }} />
        {(userRole === 'student' || userRole === 'coach') && (
          <>
            {currentProgram?.hasProgramStarted &&
              Object.keys(driveAndMeetLinks).length > 0 && (
                <>
                  <Button
                    sx={{ margin: '0 0.5%' }}
                    component='a'
                    href={`${
                      driveAndMeetLinks?.google_meet_link.includes('https://')
                        ? ''
                        : 'https://'
                    }${driveAndMeetLinks?.google_meet_link}`}
                    target='_blank'
                    rel='noopener noreferrer'
                    variant='outlined'
                    color='primary'
                    startIcon={<VideocamIcon />}
                  >
                    Join Live Session
                  </Button>
                  {(driveAndMeetLinks?.drive_link ?? '') !== '' && (
                    <Button
                      sx={{
                        marginTop: '0 1.5%',
                        marginRight: '1.7%',
                      }}
                      component='a'
                      href={`${driveAndMeetLinks?.drive_link}`}
                      target='_blank'
                      rel='noopener noreferrer'
                      variant='contained'
                      color='primary'
                      startIcon={<AddToDriveIcon />}
                    >
                      Drive Folder
                    </Button>
                  )}
                </>
              )}

            {/* <BellNotifications
              programId={currentProgram ? currentProgram?.programId : ''}
            /> */}
          </>
        )}
        {userRole == K_PROGRAM_MANAGER && (
          <Box mr={3}>
            <FocusModeSwitch />
          </Box>
        )}
        {showChatIcon && (
          <Link
            to='/chat'
            target='_blank'
          >
            <Badge
              badgeContent={unreadCount}
              color='error'
              max={9}
              sx={{ mr: 3 }}
            >
              <ChatIcon sx={{ fontSize: '30px', color: '#8e8e8e' }} />
            </Badge>
          </Link>
        )}
        {userRole === 'student' &&
          !window?.location?.pathname?.includes('my-courses') && <HelpButton />}
        <Stack
          direction='row'
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}

export default DashboardNavbarV2;

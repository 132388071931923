// @ts-nocheck
import { Popover, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import axios from 'axios';
import { isAdmin, isProgramManager } from './ChatDashboard';
import {
  archiveOrUnarchive,
  getHeaders,
  markReadOrUnread,
} from '../../apis/urls';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../ToastNotifications/SuccessNotifier';
import UpdateChatLabelModal from './Labels/UpdateChatLabelModal';

export const ChatListItemOptions = ({
  chatItem,
  filters,
  anchorEl,
  handleClosePopover,
  updateLoaderForChatList,
  setChatList,
}) => {
  const [open, setOpen] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const handleMarkAsUnread = (e) => {
    updateLoaderForChatList(true);
    const body = {
      marked_unread: true,
    };

    axios
      .post(markReadOrUnread(chatItem.id), body, {
        headers: getHeaders(),
      })
      .then((res) => {
        setChatList((prev) => {
          const updatedChatList = prev.map((chat) =>
            chat.id === chatItem.id ? { ...chat, marked_unread: true } : chat
          );
          return updatedChatList;
        });
      })
      .catch((error) => {
        console.error('Error fetching chat messages:', error);
      })
      .finally(() => {
        updateLoaderForChatList(false);
        handleClosePopover();
      });
  };

  const handleChatArchive = (e) => {
    updateLoaderForChatList(true);
    const body = {
      is_archived: !chatItem.is_archived,
    };

    axios
      .post(archiveOrUnarchive(chatItem.id), body, {
        headers: getHeaders(),
      })
      .then((res) => {
        const { data } = res;
        const { archive_status = 'all' } = filters;

        if (archive_status === 'all') {
          setChatList((prev) => {
            const updatedChatList = prev.map((chat) =>
              chat.id === chatItem.id ? data : chat
            );
            return updatedChatList;
          });
          return;
        }

        setChatList((prev) => {
          const updatedChatList = prev.filter((p) => p.id !== chatItem.id);
          return updatedChatList;
        });
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        updateLoaderForChatList(false);
        handleClosePopover();
      });
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}

      {isSuccess && (
        <SuccessNotifier
          message={successMessage}
          setIsSuccess={setIsSuccess}
        />
      )}

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Typography
          variant='body2'
          onClick={(e) => handleMarkAsUnread(e, chatItem)}
          sx={{ padding: '8px 12px', cursor: 'pointer' }}
        >
          Mark as unread
        </Typography>

        {(isAdmin || isProgramManager) && (
          <Typography
            variant='body2'
            onClick={(e) => {
              setOpen(true);

              // Note: This is required to prevent this bug: https://github.com/mui/material-ui/issues/10341
              handleClosePopover();
            }}
            sx={{ padding: '8px 12px', cursor: 'pointer' }}
          >
            Labels
          </Typography>
        )}

        <Typography
          variant='body2'
          onClick={(e) => handleChatArchive(chatItem)}
          sx={{ padding: '8px 12px', cursor: 'pointer' }}
        >
          {chatItem.is_archived ? 'Unarchive' : 'Archive'}
        </Typography>
      </Popover>

      {open && (
        <UpdateChatLabelModal
          open={open}
          onClose={() => setOpen(false)}
          chatId={chatItem.id}
          setChatList={setChatList}
          handleClosePopover={handleClosePopover}
          setHasError={setHasError}
          setErrorMessage={setErrorMessage}
          setIsSuccess={setIsSuccess}
          setSuccessMessage={setSuccessMessage}
        />
      )}
    </>
  );
};

import moment from 'moment';

export const getTileClassName = (date, studentDailyProgress) => {
  const today = moment();
  const dateData = studentDailyProgress.find((item) =>
    moment(item.date).isSame(date, 'day')
  );

  if (!dateData) {
    return 'simpleTiles';
  }

  if (dateData && dateData.has_session) {
    return 'sessionClass';
  }

  if (dateData && !dateData.has_session) {
    switch (true) {
      case dateData.progress === 0:
        return 'zeroProgress';
      case dateData.progress >= 1 && dateData.progress < 2:
        return 'veryLowProgress';
      case dateData.progress >= 2 && dateData.progress < 4:
        return 'lowProgress';
      case dateData.progress >= 4 && dateData.progress < 6:
        return 'highProgress';
      case dateData.progress === 6:
        return 'veryHighProgress';
      default:
        return 'simpleTiles';
    }
  }

  if (!moment(date).isSame(today, 'month')) {
    return 'otherMonth';
  }

  return 'simpleTiles';
};

export const disablePrev2Button = () => {
  const prev2Button = document.querySelector(
    '.react-calendar .react-calendar__navigation .react-calendar__navigation__prev2-button'
  );
  if (prev2Button) {
    prev2Button.disabled = true;
  }
};

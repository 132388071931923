import { Stack } from '@material-ui/core';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {
  fetchAllCoachesFinanceDetails,
  getHeaders,
  postCoachPaymentURL,
} from '../../../apis/urls';
import ErrorNotifier from '../../ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../ToastNotifications/SuccessNotifier';
import FinancesTable from './FinancesTable';

function Finances() {
  const [coaches, setCoaches] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [totalAmountToBeProcessed, setTotalAmountToBeProcessed] = useState(0);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [coachIdToIndexMap, setCoachIdToIndexMap] = useState({});

  useEffect(() => {
    fetchFinancesInfo();
    return () => {};
  }, []);

  function fetchFinancesInfo() {
    axios
      .get(fetchAllCoachesFinanceDetails(), {
        headers: getHeaders(),
      })
      .then((res) => {
        const verifiedCoaches = [];
        const remainingCoaches = [];
        let amountToBeProcessed = 0;
        res.data.forEach((coach) => {
          if (coach.session_ids.length === 0) {
            remainingCoaches.push(coach);
          } else {
            verifiedCoaches.push(coach);
          }
          amountToBeProcessed += coach.transferred;
        });

        const tmpCoaches = [...verifiedCoaches, ...remainingCoaches];
        const tmpMap = {};
        tmpCoaches.forEach((coach, index) => (tmpMap[coach.id] = index));

        setCoaches(tmpCoaches);
        setCoachIdToIndexMap(tmpMap);
        setTotalAmountToBeProcessed(amountToBeProcessed);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || 'Could not fetch the payment details.'
        );
      });
  }

  const addPayment = (transactionId, sendEmailChecked, selectedCoach) => {
    const body = {
      coach_id: selectedCoach?.id,
      session_ids: selectedCoach?.session_ids,
      total: selectedCoach?.total,
      tds: selectedCoach?.tds,
      transferred: selectedCoach?.transferred,
      transaction_id: transactionId,
      hourly_incentive_cost: selectedCoach?.hourly_incentive_cost,
      notify_coach: sendEmailChecked,
    };

    axios
      .post(postCoachPaymentURL(), body, {
        headers: getHeaders(),
      })
      .then((res) => {
        setIsSuccess(true);
        setSuccessMessage('Successfully updated the payment details.');
        const tmpCoaches = [...coaches];
        const index = coachIdToIndexMap[selectedCoach.id];
        tmpCoaches[index] = {
          ...selectedCoach,
          session_ids: [],
          tds: 0,
          total: 0,
          transferred: 0,
        };
        setCoaches(tmpCoaches);
        setTotalAmountToBeProcessed(
          totalAmountToBeProcessed - selectedCoach.transferred
        );
        setOpenPaymentModal(false);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || 'Could not update the payment details'
        );
      });
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      {isSuccess && (
        <SuccessNotifier
          message={successMessage}
          setIsSuccess={setIsSuccess}
        />
      )}
      <Stack spacing={2}>
        {coaches.length > 0 && (
          <FinancesTable
            {...{
              coaches,
              setHasError,
              setErrorMessage,
              openPaymentModal,
              setOpenPaymentModal,
              addPayment,
              totalAmountToBeProcessed,
            }}
          />
        )}
      </Stack>
    </>
  );
}

export default Finances;

// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/system';
import { NoteAlt } from '@mui/icons-material';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { Grid } from '@material-ui/core';
import CodeIcon from '@mui/icons-material/Code';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import EditIcon from '@mui/icons-material/Edit';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { useNavigate } from 'react-router-dom';
import { getUserType } from '../../configs/auth';
import SideBar from './sidebar';
import ProblemsList from './CodeProblems/ProblemsList';
import Mindmap from './Mindmap/Mindmap';
import CodeEditor from './CodeEditor/CodeEditor';
import CodingTestsList from './CodingTest/CodingTestsList';
import { slugify } from '../utils/common';
import QuizStudentLayout from './Quizzes/QuizStudentLayout';
import LearnerLevelTests from './LevelTests/LearnerLevelTests';
import LevelTestAttempts from './LevelTests/LevelTestAttempts';
import DSAVisualization from './DSAVisualization/DSAVisualization';
import SessionTests from './SessionTests/SessionTests';
import DSAVisualizer from './DSAVisualizer/DSAVisualizer';
import TGPMainScreen from '../TheGreatePyramid/TGPMainScreen';
import Mindmaps from './MindMaps/Pages/Mindmaps';

const Practice = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const userType = getUserType();
  const navigate = useNavigate();
  const currentProgram = useSelector((state) => state.programs.currentProgram);
  const programName = currentProgram?.programSlug;

  useEffect(() => {
    setIndexOfDefaultSubTab();
    return () => {};
  }, []);

  // useEffect(() => {
  //   const label = slugify(LeftBarContents[selectedIndex].label);
  //   navigate(`/student/${programName}/practice/${slugify(label)}`);
  //   return () => {};
  // }, [selectedIndex]);

  function setIndexOfDefaultSubTab() {
    const tab = window.location.pathname.slice(1).split('/')[3] || '';
    for (let i = 0; i < LeftBarContents.length; i += 1) {
      const label = slugify(LeftBarContents[i].label);
      if (
        LeftBarContents[i].isFor === 'both' ||
        LeftBarContents[i].isFor === userType
      ) {
        // checks if the subtab can be accessed by current user type.
        if (label === tab) {
          setSelectedIndex(i);
          break;
        }
      }
    }
  }

  const handleListItemClick = (index) => {
    setSelectedIndex(index);
  };

  return (
    <Grid container>
      <Grid
        item
        lg={2}
        md={2}
      >
        <Box
          sx={{
            height: '55vh',
            padding: '0',
            flexGrow: 1,
            overflowY: 'auto',
            borderRight: '1px solid gray',
          }}
        >
          <div
            style={{
              paddingTop: '10px',
            }}
          >
            {LeftBarContents?.map((content, index) => {
              if (content.isFor === 'both' || content.isFor === userType) {
                if (
                  !['mindmap', 'dsa visualization'].includes(
                    content.label.toLowerCase()
                  ) &&
                  !currentProgram?.hasProgramStarted
                ) {
                  return null;
                }
                return (
                  <SideBar
                    key={index}
                    label={content.label}
                    Icon={content.Icon}
                    selectedIndex={selectedIndex}
                    handleListItemClick={handleListItemClick}
                    index={index}
                  />
                );
              }
              return <></>;
            })}
          </div>
        </Box>
      </Grid>

      <Grid
        item
        lg={10}
        md={10}
      >
        {LeftBarContents &&
          LeftBarContents?.map((content, index) => {
            const shouldRender =
              (content.isFor === 'both' || content.isFor === userType) &&
              selectedIndex === index;
            if (shouldRender) {
              return content.component;
            }
            return <></>;
          })}
      </Grid>
    </Grid>
  );
};

export default Practice;

const LeftBarContents = [
  // {
  //   label: 'Mindmap',
  //   Icon: NoteAlt,
  //   isFor: 'both',
  //   component: <Mindmap />,
  // },
  {
    label: 'DSA Visualizer',
    Icon: AccountTreeIcon,
    isFor: 'both',
    component: <DSAVisualizer />,
  },
  // {
  //   label: 'Code Editor',
  //   Icon: CodeIcon,
  //   isFor: 'both',
  //   component: <CodeEditor />
  // },
  // {
  //   label: 'Problems',
  //   Icon: MenuBookIcon,
  //   isFor: 'student',
  //   component: <ProblemsList />
  // },
  // {
  //   label: 'Coding Tests',
  //   Icon: EditIcon,
  //   isFor: 'student',
  //   component: <CodingTestsList />,
  // },
  {
    label: 'Session Tests',
    Icon: WysiwygIcon,
    isFor: 'student',
    component: <SessionTests />,
  },
  {
    label: 'Level Tests',
    Icon: WysiwygIcon,
    isFor: 'student',
    component: <LearnerLevelTests />,
  },
  {
    label: 'Level Tests Attempts',
    Icon: FactCheckIcon,
    isFor: 'student',
    component: <LevelTestAttempts />,
  },
  {
    label: 'Quizzes',
    Icon: FormatListBulletedIcon,
    isFor: 'student',
    component: <QuizStudentLayout />,
  },
  {
    label: 'The Great Pyramid',
    Icon: FormatListBulletedIcon,
    isFor: 'student',
    component: <TGPMainScreen />,
  },
  {
    label: 'MindMap',
    Icon: NoteAlt,
    isFor: 'student',
    component: <Mindmaps />,
  },
];

import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Box, Tab } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {
  fetchAllCompanyRole,
  fetchPlacementPool,
  fetchAllPlacementRequests,
  fetchAllCompanies,
  fetchAllTechStacks,
  getHeaders,
} from '../../apis/urls';
import Roles from './AdminPlacementComponents/Roles';
import PlacementsPool from './AdminPlacementComponents/PlacementsPool';
import ContactRequests from './AdminPlacementComponents/ContactRequests';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../ToastNotifications/SuccessNotifier';

const MainPlacementsPage = () => {
  const [value, setValue] = useState('1');
  const [companyRoles, setCompanyRoles] = useState([]);
  const [placementPoolData, setPlacementPoolData] = useState([]);
  const [placementRequestsData, setPlacementRequestsData] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [techStacks, setTechStacks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const currentProgram = useSelector(
    (state) => state?.programs?.currentProgram
  );

  useEffect(() => {
    getAllCompanyRoles();
    getAllLearnersFromPlacementPool();
    getContactRequests();
    getAllCompanies();
    getAllTechStacks();
  }, []);

  const getAllCompanies = () => {
    axios
      .get(fetchAllCompanies(), {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((res) => {
        if (!res?.data) {
          return;
        }
        setCompanies(res?.data);
        setLoading(true);
      })
      .catch((error) => {
        console.log(error);
        setHasError(true);
        setErrorMessage(error);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getAllTechStacks = () => {
    axios
      .get(fetchAllTechStacks(), {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((response) => {
        if (!response?.data) {
          return;
        }
        setTechStacks(response?.data);
        setLoading(true);
      })
      .catch((error) => {
        console.error(error);
        setHasError(true);
        setErrorMessage(error);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getAllCompanyRoles = () => {
    axios
      .get(fetchAllCompanyRole(), {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((response) => {
        if (!response?.data) {
          return;
        }
        setCompanyRoles(response?.data);
        setLoading(true);
      })
      .catch((error) => {
        console.error(error);
        setHasError(true);
        setErrorMessage(error);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getAllLearnersFromPlacementPool = () => {
    axios
      .get(fetchPlacementPool(), {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((response) => {
        if (!response?.data) {
          return;
        }
        const formattedData = handleExtractedDataFromPlacementPool(
          response?.data
        );
        setPlacementPoolData(formattedData);
        setLoading(true);
      })
      .catch((error) => {
        console.error(error);
        setHasError(true);
        setErrorMessage(error);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleExtractedDataFromPlacementPool = (data) => {
    const currentDate = new Date();

    const extractedData = data.map((item) => {
      const createdAtDate = new Date(item.created_at);
      const daysAgo = Math.floor(
        (currentDate - createdAtDate) / (1000 * 60 * 60 * 24)
      );
      return {
        id: item?.id,
        name: item?.enrollment?.user?.name ?? '-',
        email: item?.enrollment?.user?.email ?? '-',
        contact: item?.enrollment?.user?.phone ?? '-',
        program: item?.enrollment?.program?.name ?? '-',
        added_since: `${daysAgo} days ago` ?? '-',
        action: item?.status ?? '-',
      };
    });
    return extractedData;
  };

  const handleExtractedDataFromPlacementRequests = (data) => {
    if (data) {
      const extractedData = data.map((item) => {
        return {
          id: item?.id,
          name: item?.enrollment?.user?.name ?? '-',
          email: item?.enrollment?.user?.email ?? '-',
          contact: item?.enrollment?.user?.phone ?? '-',
          program: item?.enrollment?.program?.name ?? '-',
          role: item?.company_role?.role_title ?? '-',
          company: item?.company_role?.company?.name ?? '-',
          status: item?.status ?? '-',
          rejection_reason: item?.rejection_reason ?? '-',
        };
      });
      return extractedData;
    }
  };

  const getContactRequests = () => {
    axios
      .get(fetchAllPlacementRequests(), {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((res) => {
        if (!res?.data) {
          return;
        }
        const formattedData = handleExtractedDataFromPlacementRequests(
          res?.data
        );
        setPlacementRequestsData(formattedData);
        setLoading(true);
      })
      .catch((error) => {
        console.log(error);
        setHasError(true);
        setErrorMessage(error);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              onChange={handleChange}
              aria-label='lab API tabs example'
            >
              <Tab
                label='Roles'
                value='1'
              />
              <Tab
                label='Placement Pool'
                value='2'
              />
              <Tab
                label='Contact Requests'
                value='3'
              />
            </TabList>
          </Box>
          <TabPanel value='1'>
            <Roles
              ErrorNotifier={ErrorNotifier}
              SuccessNotifier={SuccessNotifier}
              companyRoles={companyRoles}
              companies={companies}
              techStacks={techStacks}
              loading={loading}
            />
          </TabPanel>
          <TabPanel value='2'>
            <PlacementsPool
              ErrorNotifier={ErrorNotifier}
              SuccessNotifier={SuccessNotifier}
              placementPoolData={placementPoolData}
              loading={loading}
            />
          </TabPanel>
          <TabPanel value='3'>
            <ContactRequests
              ErrorNotifier={ErrorNotifier}
              SuccessNotifier={SuccessNotifier}
              placementRequestsData={placementRequestsData}
              loading={loading}
            />
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
};

export default MainPlacementsPage;

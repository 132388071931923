// @ts-nocheck
import React, { useState, useEffect, useRef } from 'react';
import { Tooltip, IconButton, TextField, Typography, Box } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import axios from 'axios';
import { getHeaders, sendMessage } from '../../apis/urls';

const styles = {
  chatInputContainer: {
    backgroundColor: 'white',
    display: 'flex',
    padding: '8px',
    alignItems: 'center',
    borderTop: '1px solid #ccc',
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px',
  },
  negativeCount: {
    color: 'red',
  },
};

export const SendNewMessage = ({ chat }) => {
  const [newMessage, setNewMessage] = useState('');
  const inputRef = useRef(null);
  const CHARACTER_LIMIT = 2000;
  const CHARACTER_WARNING_LIMIT = 1800;
  const isCharacterLimitExceeded = newMessage.length > CHARACTER_LIMIT;
  const characterCount = CHARACTER_LIMIT - newMessage.length;

  useEffect(() => {
    inputRef.current.focus();
    return () => {};
  }, []);

  const htmlEncode = (str) => {
    return String(str).replace(/[^\w. ]/gi, (c) => {
      return `&#${c.charCodeAt(0)};`;
    });
  };

  const handleSendMessage = () => {
    if (newMessage.trim() === '') return;
    const xssText = htmlEncode(newMessage);
    const sanitizedMessage = xssText.replace(/\n/g, '\\n');

    const body = {
      message: sanitizedMessage,
      chat_id: chat.id,
    };

    axios
      .post(sendMessage(), body, {
        headers: getHeaders(),
      })
      .then((res) => {})
      .catch((e) => {
        console.log(e);
      });
    setNewMessage('');
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && e.shiftKey) {
      e.preventDefault();
      setNewMessage(`${newMessage}\n`);
    } else if (e.key === 'Enter' && !isCharacterLimitExceeded) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <>
      <div style={styles.chatInputContainer}>
        <TextField
          variant='standard'
          placeholder='Type a message'
          value={newMessage}
          onChange={(e) => {
            setNewMessage(e.target.value);
          }}
          onKeyDown={handleKeyPress}
          inputRef={inputRef}
          sx={{
            flex: 1,
            marginRight: 2,
            maxHeight: '200px',
            overflow: 'auto',
            border: 'none',
            wordBreak: 'break-word',
          }}
          multiline
          InputProps={{
            disableUnderline: true,
          }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
          }}
        >
          {newMessage.length >= CHARACTER_WARNING_LIMIT && (
            <Typography
              variant='body2'
              style={
                characterCount < 0
                  ? { ...styles.negativeCount }
                  : styles.characterCounter
              }
            >
              {CHARACTER_LIMIT - newMessage.length}
            </Typography>
          )}
          <Tooltip
            title={isCharacterLimitExceeded ? 'Character limit exceeded' : ''}
          >
            <span>
              <IconButton
                variant='contained'
                color='primary'
                onClick={handleSendMessage}
                disabled={isCharacterLimitExceeded}
              >
                <SendIcon />
              </IconButton>
            </span>
          </Tooltip>
        </Box>
      </div>
    </>
  );
};

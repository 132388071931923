// @ts-nocheck
import React from 'react';
import {
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const WeakSopDialog = ({ open, onClose, onSubmit, loading }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          backgroundColor: '#fdfdfd',
          padding: '20px',
          borderRadius: '10px',
        },
      }}
    >
      <DialogTitle>
        <Box
          display='flex'
          alignItems='center'
          gap={1}
          sx={{
            backgroundColor: '#FFD1D1',
            padding: '8px',
            borderRadius: '4px',
            width: '226px',
          }}
        >
          <ErrorOutlineIcon color='error' />
          <Typography
            color='error'
            fontWeight='bold'
          >
            Weak SOP Submission
          </Typography>
        </Box>
      </DialogTitle>

      <DialogContent>
        <DialogContentText
          style={{ color: '#555', fontSize: '1rem', fontWeight: '400' }}
        >
          You’re about to submit a weak SOP which can significantly reduce your
          chances of being accepted into the program.
        </DialogContentText>
        <Typography
          variant='body2'
          style={{
            marginTop: '16px',
            fontWeight: 'bold',
            color: '#333',
            fontSize: '1.2rem',
          }}
        >
          Are you sure you want to proceed?
        </Typography>
      </DialogContent>
      <DialogActions
        style={{ justifyContent: 'flex-start', paddingLeft: '24px' }}
      >
        <Button
          onClick={() => onSubmit(true)}
          variant='outlined'
          color='primary'
          style={{ fontWeight: 'bold' }}
          autoFocus
          disabled={loading}
        >
          {loading ? (
            <CircularProgress
              size={20}
              color='inherit'
            />
          ) : (
            'Submit anyway'
          )}
        </Button>
        <Button
          onClick={onClose}
          variant='contained'
          color='primary'
          style={{ marginLeft: '8px' }}
          disabled={loading}
        >
          Go back
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WeakSopDialog;

// @ts-nocheck
import {
  Box,
  Grid,
  Stack,
  Tooltip,
  Typography,
  useTheme,
  Button,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

const ConnectingScreen = ({ setShowConnectingScreen }) => {
  const [shouldEndConnection, setShouldEndConnection] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const id = setTimeout(() => setShouldEndConnection(true), 5 * 60 * 1000); // 5 minutes

    return () => {
      if (id) {
        clearTimeout(id);
      }
    };
  }, []);

  const getHeading = () => {
    return shouldEndConnection
      ? 'You must have received a call by now.'
      : 'We have received your request.';
  };

  const getSubHeading = () => {
    return shouldEndConnection
      ? "Incase you've not received a call get in touch with your career counsellor."
      : 'Please wait a moment, someone from the team will reach out to you shortly.';
  };

  const DotConnectingLoader = () => {
    return (
      <div>
        <span className='dot-flashing'></span>
        <span className='dot-flashing'></span>
        <span className='dot-flashing'></span>

        <style>
          {`
          @keyframes dotFlashing {
            0% {
              opacity: 1;
            }
            50%,
            100% {
              opacity: 0;
            }
          }

          .dot-flashing {
            margin-left: 2px;
            margin-right: 2px;
            width: 8px;
            height: 8px;
            background-color: black;
            border-radius: 50%;
            display: inline-block;
            animation: dotFlashing 1s infinite linear alternate;
          }

          .dot-flashing:nth-child(2) {
            animation-delay: 0.2s;
          }
          .dot-flashing:nth-child(3) {
            animation-delay: 0.4s;
          }

          `}
        </style>
      </div>
    );
  };

  return (
    <Stack
      spacing={isMobile ? 3 : 5}
      textAlign='center'
      justifySelf='center'
      width='100%'
      justifyContent='space-around'
    >
      <Stack
        spacing={2}
        alignSelf={'center'}
      >
        <Typography
          variant='body1'
          component='p'
          sx={{
            color: '#1976d2',
            fontFamily: 'Inter',
            fontWeight: 'bolder',
            fontSize: 'clamp(1.8rem, 2rem, 2.4rem)',
          }}
        >
          {getHeading()}
        </Typography>

        <Typography
          variant='caption'
          component='p'
          textAlign='justify'
          sx={{
            textWrap: 'wrap',
            fontFamily: 'Inter',
            fontWeight: 400,
            fontSize: '1.3rem',
            '@media (max-width: 1400px)': {
              fontSize: '1.2rem',
            },
            '@media (max-width: 1200px)': {
              fontSize: '1.1rem',
            },
          }}
        >
          {getSubHeading()}
        </Typography>
      </Stack>

      {!shouldEndConnection && (
        <Stack>
          <DotConnectingLoader />
          <Typography
            variant='caption'
            component='p'
            sx={{
              fontFamily: 'Inter',
              fontSize: 'clamp(2rem, 2.2rem, 2.4rem)',
              fontWeight: 400,
            }}
          >
            Connecting
          </Typography>
        </Stack>
      )}

      <Button
        variant='contained'
        onClick={(e) => setShowConnectingScreen(false)}
        sx={{
          background: '#1976d2',
          ':hover': {
            background: '#1976d2',
          },
          alignSelf: 'center',
          width: '80px',
        }}
      >
        Back
      </Button>
    </Stack>
  );
};

export default ConnectingScreen;

import { Box, Card, CardContent, Chip, Typography } from '@material-ui/core';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import moment from 'moment';
import React from 'react';
import DateRangeIcon from '@mui/icons-material/DateRange';

const AssignmentCard = ({
    title,
    deadline,
    Detailed,
    setDetailed,
    setActiveAssign,
    data
}) => {
    const date = moment(deadline);
    const today = moment(new Date());
    const hasDeadlinePassed = date.isBefore(today);
    return (
        <div>
            <Card
                sx={{ display: 'flex', margin: 2, cursor: 'pointer' }}
                onClick={() => {
                    setDetailed(!Detailed);
                    setActiveAssign(data);
                }}
            >
                <Box
                    style={{
                        width: '100%'
                    }}
                >
                    <CardContent>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}
                        >
                            <Typography component="div" variant="h5">
                                {title}
                            </Typography>
                            
                            <Typography 
                                variant="h6" 
                                component="div"
                            >
                                {`${data?.total_submissions} submission${data?.total_submissions > 1 ? "s" : ""}`}
                            </Typography>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}
                        >
                            <Typography 
                                variant="body2"
                                style={{ 
                                    color: (hasDeadlinePassed ? 'red': 'black')
                                }} 
                                component="div"
                                display="flex"
                            >
                                <DateRangeIcon /> {date.format("dddd, MMMM Do YYYY")}
                            </Typography>

                            <Typography 
                                variant="h6"
                                component="div"
                                display="flex"
                            >
                                <ShowSubmissionDetailsOnCard
                                    maxScore={data?.max_score}
                                    totalGradedSubmissions={data?.total_graded_submissions}
                                    totalSubmissions={data?.total_submissions}
                                    category={data?.category}
                                />
                                <ArrowForwardIcon fontSize='medium' color='primary' sx={{ml: 1}}/>
                            </Typography>
                        </div>
                    </CardContent>
                </Box>
            </Card>
        </div>
    );
};

export default AssignmentCard;


function ShowSubmissionDetailsOnCard({maxScore, totalGradedSubmissions, totalSubmissions, category}) {
    
    return (
        <>
            {(() => {
                switch (true) {
                    case totalSubmissions === 0:
                        return <Chip label="Missing" variant="outlined" color="error" sx={{mr: 1}}/>;
                    case totalGradedSubmissions < totalSubmissions:
                        return <Chip label="Grades Awaited" variant="outlined" color="secondary" sx={{mr: 1}}/>;
                    default:
                        return null
                }
            })()}
            {category === "course" &&
              `Score: ${maxScore}/100`
            }
        </>
    );
}
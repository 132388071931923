import {
  Button,
  Checkbox,
  FormControlLabel,
  Modal,
  Stack,
  TextField,
} from '@material-ui/core';
import { Box } from '@material-ui/system';
import axios from 'axios';
import moment from 'moment/moment';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getHeaders, rescheduleSessionUrl } from '../../../../apis/urls';
import ReschedulingReasonDropdown from './ReschedulingReasonDropdown';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function RescheduleSessionsModal({
  openRescheduleSessionsModal,
  setOpenRescheduleSessionsModal,
  setHasError,
  setErrorMessage,
  setIsSuccess,
  setSuccessMessage,
  programId,
  selectedSession,
}) {
  const [modalData, setModalData] = useState({
    new_start_time: selectedSession.start_time,
    session_id: selectedSession.id,
    rescheduling_reason: '',
    reschedule_following_sessions: false,
  });
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    const body = { ...modalData };
    body.new_start_time = moment(body.new_start_time).utc();

    if (body.rescheduling_reason === '') {
      setHasError(true);
      setErrorMessage('Rescheduling reason is required.');
      return;
    }

    axios
      .patch(rescheduleSessionUrl(modalData.session_id), body, {
        headers: getHeaders(programId),
      })
      .then((res) => {
        setIsSuccess(true);
        setSuccessMessage('The session was rescheduled successfully.');
        setOpenRescheduleSessionsModal(false);
        navigate(0);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || 'Could not reschedule the session.'
        );
      });
  };

  return (
    <Modal
      open={openRescheduleSessionsModal}
      onClose={() => setOpenRescheduleSessionsModal(false)}
      style={{ overflow: 'auto' }}
    >
      <form onSubmit={handleSubmit}>
        <Box sx={style}>
          <Stack spacing={4}>
            <TextField
              fullWidth
              id='session_id'
              label='session_id'
              name='Session Id'
              variant='outlined'
              value={modalData?.session_id}
              onChange={(e) =>
                setModalData({ ...modalData, session_id: e.target.value })
              }
              required
            />
            <TextField
              fullWidth
              id='start-time'
              label='New start time'
              type='datetime-local'
              name='start_time'
              variant='outlined'
              InputLabelProps={{ shrink: true }}
              value={modalData?.new_start_time}
              onChange={(e) =>
                setModalData({ ...modalData, new_start_time: e.target.value })
              }
              required
            />
            <ReschedulingReasonDropdown
              {...{
                modalData,
                setModalData,
                sx: { width: '100%', height: 40, mr: 1 },
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={modalData.reschedule_following_sessions}
                  onChange={(e) =>
                    setModalData({
                      ...modalData,
                      reschedule_following_sessions:
                        !modalData.reschedule_following_sessions,
                    })
                  }
                />
              }
              label='Reschedule Following Sessions'
            />
            <Button
              variant='contained'
              type='submit'
            >
              Reschedule session
            </Button>
          </Stack>
        </Box>
      </form>
    </Modal>
  );
}

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, MenuItem, Select, Stack, TextField } from '@material-ui/core'
import moment from 'moment';
import React, { useEffect, useState } from 'react'

function CoachingLogModal({ openLogModal, setOpenLogModal, session, updateLog, setHasError, setErrorMessage }) {
  const initialState = {
    start_time: "",
    duration: "",
    flow: "",
    learner_feedback: ""
  }
  const descriptionElementRef = React.useRef(null);
  const [updatedData, setUpdatedData] = useState(initialState);
  const [durationIntervals, setDurationIntervals] = useState([]);

  useEffect(() => {
    if (openLogModal) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) { descriptionElement.focus(); }
    }
    const tmpIntervals = [];
    for (let i = 15; i <= session?.session_type?.duration; i += 15) { 
      tmpIntervals.push(i); 
    }
    if (session.flow && session.flow !== "") { 
      initialState.flow = session.flow; 
    }
    if (session.learner_feedback && session.learner_feedback !== "") { 
      initialState.learner_feedback = session.learner_feedback; 
    }
    if (session.duration) { 
      initialState.duration = session.duration; 
    }

    setUpdatedData(initialState);
    setDurationIntervals(tmpIntervals);
    return () => {};
  }, [openLogModal]);

  const handleUpdateClick = (event) => {
    const body = {};
    if (updatedData.start_time !== "") {
      body.start_time = moment(updatedData.start_time).utc();
    } else {
      const emptyFields = [];
      if (updatedData.duration === "") {
        emptyFields.push("duration");
      }
      if (updatedData.flow === "") {
        emptyFields.push("session flow");
      }
      if (session.program.program_type !== "super30") {
        if (updatedData.learner_feedback === "") {
          emptyFields.push("feedback");
        } else {
          body.learner_feedback = updatedData.learner_feedback;
        }
      }
  
      if (emptyFields.length > 0) {
        setHasError(true);
        setErrorMessage(`${emptyFields.join(", ")} cannot be empty`);
        return;
      }
  
      body.duration = updatedData.duration;
      body.flow = updatedData.flow;
    }
    updateLog(session.id, session?.program?.id, body);
  }

  return (
    <Dialog open={openLogModal} onClose={() => setOpenLogModal(false)} scroll="paper" fullWidth maxWidth="md">
      <DialogActions>
        <Button onClick={() => setOpenLogModal(false)}>Close</Button>
      </DialogActions>
      <DialogTitle>
        {(session.status && (session.status === "verified" || session.status === "payment_processed")) 
          ? `Session update for ${session?.title}` 
          :  `Add session update for ${session?.title}`
        } 
      </DialogTitle>

      <DialogContent dividers>
        <DialogContentText ref={descriptionElementRef} tabIndex={-1}>
          <Stack style={{ margin: 10, height: "auto" }} spacing={2}>
            {/* 
              We want to restrict the number of re-schedules by a coach. So, until we add this restriction in backend,
              this will remain commented.
            */}
            {/* <TextField
              helperText="Update the session start time only when session needs to be re-scheduled"
              type='datetime-local'
              disabled={session.status && (session.status === "verified" || session.status === "payment_processed")}
              fullWidth
              label="Session start time in IST"
              onChange={(e) => setUpdatedData({...updatedData, start_time: e.target.value})}
              defaultValue={session.start_time ? moment(session.start_time).format("YYYY-MM-DDThh:mm") : ""}
              InputLabelProps={{ shrink: true }}
            /> */}

            <FormControl sx={{ minWidth: 120 }} disabled={session.status && (session.status === "verified" || session.status === "payment_processed")}>
              <InputLabel id="duration">Duration</InputLabel>
              <Select
                labelId="duration"
                name="duration"
                id="duration"
                value={updatedData.duration}
                label="Duration"
                onChange={(e) => setUpdatedData({...updatedData, duration: e.target.value})}
                multiline
              >
                <MenuItem value="">None</MenuItem>
                {
                  durationIntervals.map((interval, index) => {
                    return (
                      <MenuItem key={index + 1} value={interval}>{ interval } minutes</MenuItem>
                    );
                  }) 
                }
              </Select>
            </FormControl>
            
            <TextField
              disabled={session.status &&  (session.status === "verified" || session.status === "payment_processed")}
              fullWidth
              label="What was covered during the session? Please share the session flow."
              defaultValue={updatedData.flow}
              onChange={(e) => setUpdatedData({...updatedData, flow: e.target.value})}
              placeholder={
                updatedData.flow !== "" 
                ? updatedData.flow
                : ([
                    "Overview of Time and space complexity", "Master's theorem", "Substitution method", 
                    "Problems discussed: Time complexity of Fibonacci numbers and palindrome string"
                  ].join("\n")
                )
              }
              minRows={7}
              multiline
            />
            {session?.program?.program_type !== "super30" &&
              <TextField
                disabled={session.status &&  (session.status === "verified" || session.status === "payment_processed")}
                fullWidth
                label={updatedData.learner_feedback === "" && "Feedback on the learner's progress"}
                onChange={(e) => setUpdatedData({...updatedData, learner_feedback: e.target.value})}
                placeholder={updatedData.learner_feedback !== "" ? updatedData.learner_feedback : ""}
                minRows={5}
              />
            }
            <Button variant='contained' onClick={handleUpdateClick} sx={{mt: 2}}
              disabled={session.status && (session.status === "verified" || session.status === "payment_processed")}
            >
              Update session details
            </Button>
          </Stack>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

export default CoachingLogModal
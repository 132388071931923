import { Autocomplete, FormControl, TextField, Divider } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { createOrFetchLabel, getHeaders } from '../../../apis/urls';

export default function LabelsDropdown({ filters, setFilters }) {
  const [labels, setLabels] = useState([]);

  useEffect(() => {
    axios
      .get(createOrFetchLabel(), {
        headers: getHeaders(),
      })
      .then((res) => {
        setLabels(res?.data);
      })
      .catch((e) => {
        console.log(e);
      });

    return () => {};
  }, []);

  const handleLabelChange = (e, newValue) => {
    const label_id = newValue ? newValue.id : '';
    setFilters((p) => ({ ...p, label_id }));
  };

  return (
    <FormControl fullWidth>
      <Autocomplete
        id='labels-filter'
        size='medium'
        options={labels.map((label) => ({
          id: label.id,
          name: label.name,
          font_color: label.fontcolor,
          bg_color: label.bgcolor,
        }))}
        value={labels.find((label) => label.id === filters.label_id) || null}
        onChange={handleLabelChange}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField
            {...params}
            label='Label'
          />
        )}
        renderOption={(props, option) => (
          <>
            <span {...props}>
              <span
                style={{
                  color: option.font_color,
                  backgroundColor: option.bg_color,
                  padding: '5px',
                  borderRadius: '5px',
                  fontSize: '12px',
                }}
              >
                {option.name}
              </span>
            </span>
            <Divider />
          </>
        )}
      />
    </FormControl>
  );
}

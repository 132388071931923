import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { ScratchCard, SCRATCH_TYPE } from 'scratchcard-js';
import { getHeaders, scratchCoupon } from 'src/apis/urls';
import { IconButton } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';

const ScratchCardComponent = ({ couponCode, couponId, isScratched = true }) => {
  const scratchCardRef = useRef(null);
  const [scratched, setScratched] = useState(isScratched);
  const handleCopyClick = () => {
    navigator.clipboard.writeText(couponCode);
    // You can also show a success message to the user if needed
  };

  useEffect(() => {
    if (!isScratched && scratchCardRef.current) {
      const sc = new ScratchCard('#scratch-card-container', {
        scratchType: SCRATCH_TYPE.SPRAY,
        containerWidth: 300,
        containerHeight: 300,
        imageForwardSrc: '/static/scratch-card.png',
        htmlBackground: `<div style="position: fixed; z-index: -1; display: flex; align-items: center; justify-content: center; height: 300px; width: 300px">
            <img src="/static/rectangle.png" alt="Frame" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: -1; pointer-events: none;">
        <h3 style="color: #ff9a7a;">${couponCode}<h3>
        <button class="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium css-admp4s-MuiButtonBase-root-MuiIconButton-root" tabindex="0" type="button" aria-label="Copy code">
          <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="FileCopyIcon"><path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm-1 4 6 6v10c0 1.1-.9 2-2 2H7.99C6.89 23 6 22.1 6 21l.01-14c0-1.1.89-2 1.99-2h7zm-1 7h5.5L14 6.5V12z"></path></svg>
          <span class="MuiTouchRipple-root css-8je8zh-MuiTouchRipple-root"></span>
        </button>
        </div>`,
        clearZoneRadius: 30,
        nPoints: 200,
        pointSize: 8,
        percentToFinish: 30,
        callback: () => {
          axios
            .post(scratchCoupon(couponId), '', { headers: getHeaders() })
            .then(() => {
              setScratched(true);
            });
        },
      });

      sc.init();
    }
  }, [couponCode, couponId, isScratched]);

  return (
    <div className='scratch-card'>
      <div
        style={{
          display: 'flex',
          height: '300px',
          width: '300px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {scratched ? (
          <>
            <img
              src='/static/rectangle.png'
              alt='Frame'
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                pointerEvents: 'none',
              }}
            />
            <h3 style={{ color: '#ff9a7a' }}>{couponCode}</h3>
            <IconButton
              onClick={handleCopyClick}
              aria-label='Copy code'
            >
              <FileCopyIcon />
            </IconButton>
          </>
        ) : (
          <div
            id='scratch-card-container'
            style={{ zIndex: 999999 }}
            ref={scratchCardRef}
          ></div>
        )}
      </div>
    </div>
  );
};

export default ScratchCardComponent;

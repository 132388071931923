import { Box, Button, Stack, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import CodeMirrorEditor from '../../CodeMirrorEditor/CodeMirrorEditor';

export default function IdealCodeSolution({ allSolutions, language }) {
  const [editorProps, setEditProps] = useState({
    content: '',
    languageType: language.languageName,
    handleContentChange: () => {},
  });
  useEffect(() => {
    if (allSolutions[language?.languageId]) {
      setEditProps({
        content: allSolutions[language?.languageId].code,
        languageType: language?.languageName,
      });
    }
  }, [allSolutions, language]);

  return (
    <Stack
      spacing={2}
      sx={{ pt: 5 }}
    >
      <Stack
        direction='row'
        spacing={2}
        sx={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Typography variant='h3'>Old Solution</Typography>
      </Stack>

      <CodeMirrorEditor
        {...editorProps}
        options={{
          readOnly: true,
          editable: false,
          height: '500px',
        }}
      />
    </Stack>
  );
}

// @ts-nocheck
import { Button, Typography, Box, Paper } from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import React from 'react';
import { useFastrackPipeline } from 'src/layouts/EfPipelineContext/FastrackPipelineContex';

const ConcernedAboutWorkBalance = ({ saveFlowData }) => {
  const { setScreenSource, trackButtonClick } = useFastrackPipeline();
  const handleContinueClick = () => {
    trackButtonClick({ source: 'screen_3', eventName: 'continue' });
    saveFlowData({
      screen: 'screen_3',
    });
    setScreenSource('screen_3a');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: 'auto',
        padding: {
          xs: '1rem',
          sm: '1.5rem',
          md: '2rem',
        },
        marginTop: {
          xs: '2rem',
          sm: '0',
        },
      }}
    >
      <Paper
        elevation={0}
        sx={{
          maxWidth: '800px',
          width: '100%',
          textAlign: 'center',
          padding: {
            xs: '2rem 1rem',
            sm: '2rem',
          },
          borderRadius: '16px',
        }}
      >
        <Box
          sx={{
            maxWidth: '550px',
            margin: '0 auto',
          }}
        >
          <Typography
            variant='h4'
            component='h1'
            sx={{
              color: '#1976d2',
              fontFamily: 'Inter',
              fontWeight: 'bold',
              fontSize: {
                xs: '1.4rem',
                sm: '1.6rem',
                md: '1.8rem',
                lg: '2.2rem',
              },
            }}
          >
            Concerned about balancing work, life, and learning?
          </Typography>
        </Box>
        <Box
          sx={{
            maxWidth: '800px',
            margin: '0 auto',
          }}
        >
          <Typography
            variant='body1'
            component='p'
            sx={{
              color: '#5f6368',
              fontFamily: 'Inter',
              fontWeight: 'normal',
              fontSize: {
                xs: '0.85rem',
                sm: '0.95rem',
                md: '1.1rem',
              },
              marginTop: '1rem',
              marginBottom: '2rem',
            }}
          >
            We get it – life can be busy. But let’s understand how you can
            manage your time effectively while working towards your career
            goals.
          </Typography>
        </Box>

        <Button
          variant='contained'
          sx={{
            backgroundColor: '#1976d2',
            color: '#fff',
            fontWeight: 'bold',
            textTransform: 'none',
            fontSize: {
              xs: '0.85rem',
              sm: '1rem',
            },
            padding: {
              xs: '0.6rem 1.5rem',
              sm: '0.5rem 1.5rem',
            },
            marginBottom: '1.5rem',
            borderRadius: '8px',
            width: 'fit-content',
          }}
          onClick={handleContinueClick}
          endIcon={<EastIcon />}
        >
          Continue
        </Button>
      </Paper>
    </Box>
  );
};

export default ConcernedAboutWorkBalance;

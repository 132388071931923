import React, { useState } from 'react';
import { Box, Button, Tabs, Tab, Typography } from '@material-ui/core';
import CodeMirror from '@uiw/react-codemirror';
import LoadingButton from '@mui/lab/LoadingButton';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import TopicTitle from '../BitManipulation/TopicTitle';
import CustomTabPanel from '../BitManipulation/CustomTabPanel';
import { powerOfX } from 'src/components/utils/techniqueSagaConstant';
import { renderElement } from '../TechniqueSagaUtil';
import CodeMirrorEditor from 'src/components/CodeMirrorEditor/CodeMirrorEditor';
import ReactCodeMirror from '@uiw/react-codemirror';
import CodeBlockComponent from './CodeBlockComponent';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const FindPowerOfX = ({
  handlePostCompletedTopic,
  hasMarkedCompleted,
  loading,
  topic_id,
  handleNext,
  handlePrevious,
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap='36px'
      paddingY={2}
      maxWidth='calc(100% - 300px)'
    >
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
      >
        <TopicTitle>1. Find power of x</TopicTitle>
        {hasMarkedCompleted(topic_id) && (
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              color: 'green',
              fontWeight: '500',
              border: '2px solid green',
              padding: '4px 10px',
              borderRadius: '20px',
              cursor: 'default',
            }}
          >
            Completed
          </Typography>
        )}
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        gap='24px'
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            // width: '34rem',
            background: '#FEF7FF',
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
          >
            <Tab
              label='Problem Statement'
              {...a11yProps(0)}
            />
            <Tab
              label='Traditional Approach'
              {...a11yProps(1)}
            />
            <Tab
              label='Recursion Approach'
              {...a11yProps(2)}
            />
            <Tab
              label='Another Approach'
              {...a11yProps(3)}
            />
          </Tabs>
        </Box>
        <CustomTabPanel
          value={value}
          index={0}
        >
          {powerOfX['problem-statement'].map((element) => {
            return renderElement(element);
          })}
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={1}
        >
          {powerOfX['iterative-approach'].map((element) => {
            return renderElement(element);
          })}
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={2}
        >
          {powerOfX['recursive-approach'].map((element) => {
            return renderElement(element);
          })}
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={3}
        >
          {powerOfX['another-approach'].map((element) => {
            return renderElement(element);
          })}
        </CustomTabPanel>
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        alignSelf='end'
        justifyContent='space-between'
        gap='12px'
      >
        {!hasMarkedCompleted(topic_id) && (
          <LoadingButton
            variant='contained'
            onClick={() => handlePostCompletedTopic(topic_id)}
            loading={loading}
            loadingPosition='center'
            children='Mark as completed'
            style={{
              width: '170px',
              // backgroundColor: 'transparent',
              borderRadius: '8px',
              border: '1px solid rgba(64, 96, 245, 0.5)',
            }}
          />
        )}

        <Box
          display='flex'
          alignItems='center'
          gap='8px'
        >
          <Button
            sx={{ gap: '4px' }}
            onClick={handlePrevious}
          >
            <WestIcon /> Previous
          </Button>

          <Button
            sx={{ gap: '4px' }}
            onClick={handleNext}
          >
            Next <EastIcon />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default FindPowerOfX;

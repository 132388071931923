import { FormControl, InputLabel, MenuItem, Select, Stack } from '@material-ui/core';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { fetchTopicsUrl, getHeaders } from '../apis/urls';
import TopicsList from './TopicsList';

export default function TopicsDropdown({ selectedTopicIds, setSelectedTopicIds, setHasError, setErrorMessage, sx={} }) {
  const [allTopics, setAllTopics] = useState({}); // map of type => { topicName: topicId }
  const [selectedTopic, setSelectedTopic] = useState("");
  const [selectedTopicsList, setSelectedTopicsList] = useState([]);

  useEffect(() => {
    getAllTopics();
    return () => {};
  }, []);

  useEffect(() => {
    if (Object.keys(allTopics).length > 0 && selectedTopicIds.length > 0) {
      const topics = [];
      Object.keys(allTopics).forEach(topicName => {
        const topicId = allTopics[topicName];
        if (selectedTopicIds.indexOf(topicId) !== -1) {
          topics.push(topicName);
        }
      })
      setSelectedTopicsList(topics);
    }
    return () => {};
  }, [allTopics]);
  
  function getAllTopics() {
    axios.get(fetchTopicsUrl(), {
      headers: getHeaders()
    })
    .then(res => {
      const tmpMap = {};
      res.data.forEach(topic => tmpMap[topic.name] = topic.id);
      setAllTopics(tmpMap);
    })
    .catch(err => {
      console.log(err);
      setHasError(true);
      setErrorMessage(err.response?.data?.message || "Couldn't fetch topics.");
    })
  }

  const handleTopicSelect = (e) => {
    setSelectedTopic(e.target.value);
    if (e.target.value !== "") {
      const index = selectedTopicsList.indexOf(e.target.value); 
      if (index === -1) {
        setSelectedTopicsList([...selectedTopicsList, e.target.value]);
        setSelectedTopicIds([...selectedTopicIds, allTopics[e.target.value]]);
      }
    }
  }

  const handleTopicDelete = (name) => {
    setSelectedTopicsList(selectedTopicsList.filter((topic, idx) => topic !== name));
    setSelectedTopicIds(selectedTopicIds.filter((topicId, idx) => topicId !== allTopics[name]));
  }

  return (
    <Stack spacing={1}>
      <TopicsList {...{ topics: selectedTopicsList, handleTopicDelete, readOnly:false }} />

      <FormControl sx={sx}>
        <InputLabel id="topics">Topics List</InputLabel>
        <Select labelId="topics" id="topics" value={selectedTopic} label="Topics List" onChange={handleTopicSelect}>
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {Object.keys(allTopics)?.length > 0 &&
            Object.keys(allTopics)?.map((topicName, index) => (
              <MenuItem key={allTopics[topicName]} id={allTopics[topicName]} value={topicName}>
                {topicName}
              </MenuItem>
            ))
          }
        </Select>
      </FormControl>
    </Stack>
  );
}

import React, { useState, useEffect } from 'react';
import { useTable, useSortBy } from 'react-table';
import axios from 'axios';
import {
  Box,
  Button,
  Modal,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Chip,
} from '@mui/material';
import TicketDetailView from './TicketDetailView';
import { Tooltip, Typography } from '@material-ui/core';

import {
  formatDate,
  formatTimeDelta,
  getCategory,
  getStatusColor,
  isMoreThan24Hours,
} from '../TicketingSystemUtil';
import SouthIcon from '@mui/icons-material/South';
import AssigneeDropdown from './AssigneeDropdown';
import { getHeaders, allAssignees } from 'src/apis/urls';

function TableView({
  columns,
  data,
  setSortField,
  setSortOrder,
  sortField,
  sortOrder,
}) {
  const [visibleColumns, setVisibleColumns] = useState(columns);
  const [detailView, setDetailView] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState(
    columns.map((column) => column.Header)
  );
  const [assigneeOptions, setAssigneeOptions] = useState([]);
  const [admin, isAdmin] = useState(false);

  useEffect(() => {
    axios
      .get(allAssignees(), { headers: getHeaders() })
      .then((res) => {
        const assigneesArray = Array.isArray(res.data)
          ? res.data
          : Object.values(res.data);
        // Convert array of arrays to array of objects with id and name
        const formattedAssignees = assigneesArray.map(([id, name]) => ({
          id,
          name,
        }));
        setAssigneeOptions(formattedAssignees);
      })
      .catch((error) => {
        console.error('There was an error fetching the assignees!', error);
      });
  }, []);

  useEffect(() => {
    const role = JSON.parse(localStorage.getItem('user'))['role'];
    if (role === 'admin') {
      isAdmin(true);
    }
  }, []);

  const handleSelectChange = (event) => {
    const value = event.target.value;
    setSelectedColumns(value);
  };

  const handleFilterClick = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleRowClick = (row) => {
    setSelectedTicket(row.original);
    setDetailView(true);
  };

  const handleCellClick = (event, cell) => {
    if (cell.column.Header === 'Assignee') {
      event.stopPropagation(); // Prevent row click
    } else {
      handleRowClick(cell.row);
    }
  };

  const handleClose = () => {
    setSelectedTicket(null);
    setDetailView(false);
  };

  const handleSubmit = () => {
    setVisibleColumns(
      columns.filter((column) => selectedColumns.includes(column.Header))
    );
    setShowPopup(false);
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns: visibleColumns,
      data,
    });

  return (
    <Box position='relative'>
      {detailView && (
        <Modal
          open={detailView}
          onClose={handleClose}
          style={{ display: 'flex', justifyContent: 'right', zIndex: 99999 }}
        >
          <TicketDetailView
            handleClose={handleClose}
            ticket={selectedTicket}
          />
        </Modal>
      )}
      <Box
        display='flex'
        flexDirection='column'
      >
        {showPopup && (
          <Modal
            open={showPopup}
            onClose={handleClosePopup}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                width: '900px',
                height: '250px',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 3,
                background: '#fff',
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                borderRadius: '8px',
              }}
            >
              <Button
                onClick={handleClosePopup}
                variant='outlined'
              >
                Close
              </Button>
              <Box
                display='flex'
                gap={1}
              >
                <Select
                  multiple
                  value={selectedColumns}
                  onChange={handleSelectChange}
                  renderValue={(selected) => selected.join(', ')}
                  style={{ width: '100%' }}
                >
                  {columns.map((column, index) => (
                    <MenuItem
                      key={index}
                      value={column.Header}
                    >
                      <Checkbox
                        checked={selectedColumns.indexOf(column.Header) > -1}
                      />
                      <ListItemText primary={column.Header} />
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Button
                onClick={handleSubmit}
                style={{ marginTop: '10px' }}
                variant='contained'
              >
                Submit
              </Button>
            </Box>
          </Modal>
        )}

        {/* <Button
            sx={{ cursor: 'pointer', alignSelf: 'flex-end' }}
            onClick={handleFilterClick}
          >
            Fields :
            <FilterAltIcon />
          </Button> */}
        <div style={{ overflow: 'auto' }}>
          <table
            {...getTableProps()}
            style={{
              margin: '0 auto',
              borderCollapse: 'collapse',
              width: '100%',
            }}
          >
            <thead>
              {headerGroups.map((headerGroup, i) => (
                <tr
                  key={i}
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup.headers.map((column, i) => (
                    <th
                      key={i}
                      style={{
                        borderTop: 'solid 1px #ddd',
                        borderBottom: 'solid 1px #ddd',
                        color: '#333',
                        fontWeight: 'bold',
                        padding: '20px',
                        cursor: 'pointer',
                        textAlign: 'center',
                        width: 'max-content',
                        whiteSpace: 'nowrap',
                      }}
                      onClick={() =>
                        handleSorting(
                          setSortField,
                          setSortOrder,
                          column,
                          sortField
                        )
                      }
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginLeft: '30px',
                        }}
                      >
                        <Typography
                          fontWeight='800'
                          style={{ fontSize: '0.8em', textAlign: 'center' }}
                        >
                          {column.render('Header')}
                        </Typography>
                        {column.id === sortField ? (
                          <SouthIcon
                            style={{
                              fontSize: '16px',
                              transform:
                                sortOrder === 'desc' ? 'rotate(180deg)' : '',
                              height: 'auto',
                            }}
                          />
                        ) : null}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                const shouldHighlight = row.original.category === 'detractor';

                return (
                  <tr
                    key={i}
                    {...row.getRowProps()}
                    style={{
                      cursor: 'pointer',
                      backgroundColor: shouldHighlight ? ' #FFE2C0' : 'inherit',
                    }}
                    onClick={() => handleRowClick(row)}
                  >
                    {row.cells.map((cell, i) => (
                      <td
                        key={i}
                        {...cell.getCellProps()}
                        style={{
                          padding: '10px',
                          borderTop: 'solid 1px #ddd',
                          borderBottom: 'solid 1px #ddd',
                          textAlign: 'center',
                          fontSize: '0.8em',
                          backgroundColor: shouldHighlight
                            ? '#FFE2C0'
                            : needAttention(cell)
                            ? '#ffcccb'
                            : null,
                        }}
                        onClick={(event) => handleCellClick(event, cell)}
                      >
                        {makeCell(cell, assigneeOptions, admin)}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Box>
    </Box>
  );
}

function needAttention(cell) {
  return (
    cell.column.Header === 'Created at' &&
    isMoreThan24Hours(cell.value) &&
    cell.row.original['status'] === 'open'
  );
}

const makeCell = (cell, assigneeOptions, admin) => {
  const columnHeader = cell.column.Header;
  if (admin) {
    if (columnHeader === 'Assignee') {
      return (
        <AssigneeDropdown
          value={cell.row.values['assignee.name']}
          ticketId={cell.row.values['id']}
          options={assigneeOptions}
        />
      );
    }
  }

  if (columnHeader === 'Status') {
    return (
      <Chip
        label={cell.value}
        style={{
          backgroundColor: getStatusColor(cell.value),
          color: 'white',
        }}
      />
    );
  }

  if (columnHeader === 'Created at') {
    return (
      <Tooltip title={formatDate(cell.value)}>
        <span>{formatTimeDelta(cell.value)}</span>
      </Tooltip>
    );
  }

  if (columnHeader === 'Name') {
    return `${cell.value} $${cell.row.original['learner']?.id}`;
  }

  if (columnHeader === 'Category') {
    return getCategory(cell.value);
  }

  return cell.render('Cell');
};

const handleSorting = (setSortField, setSortOrder, column, sortField) => {
  setSortField(column.id);
  if (sortField === column.id) {
    setSortOrder('desc');
  } else {
    setSortOrder('asc');
  }
};

export default TableView;

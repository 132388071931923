import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  DialogTitle,
} from '@material-ui/core';
import axios from 'axios';
import { useState } from 'react';
import { getHeaders, updateMentorSessions } from 'src/apis/urls';
export default function MentorSessionCoachModal({
  setOpenMentorSessionCoachModal,
  openMentorSessionCoachModal,
  selectedMentorSession,
  setIsSuccess,
  setSuccessMessage,
  setErrorMessage,
  setHasError,
  currentProgram,
}) {
  const [modalData, setModalData] = useState({ description: '' });

  const handleSubmit = (e) => {
    e.preventDefault();
    const body = { ...modalData };
    if (body.description === '') {
      setHasError(true);
      setErrorMessage('Flow cannot be empty.');
      return;
    }
    axios
      .patch(updateMentorSessions(selectedMentorSession.id), body, {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((res) => {
        setIsSuccess(true);
        setSuccessMessage('Mentor Session was Updated SuccessFully.');
        setOpenMentorSessionCoachModal(false);
      })
      .catch((err) => {
        console.log('===============', err);
        setOpenMentorSessionCoachModal(false);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || 'Could not update the mentor session.'
        );
      });
  };

  return (
    <Dialog
      open={openMentorSessionCoachModal}
      onClose={() => setOpenMentorSessionCoachModal(false)}
      scroll='paper'
      fullWidth
      maxWidth='md'
    >
      <DialogTitle
        sx={{ m: 0, p: 2 }}
        id='customized-dialog-title'
      >
        {`Add Session Update For ${selectedMentorSession.title}`}
      </DialogTitle>
      <Button
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
        onClick={() => setOpenMentorSessionCoachModal(false)}
      >
        Close
      </Button>

      <DialogContent dividers>
        <DialogContentText tabIndex={-1}>
          <Stack>
            <form
              onSubmit={handleSubmit}
              sx={{ minWidth: 120 }}
            >
              <InputLabel id='duration'>Logged duration</InputLabel>
              <Select
                labelId='duration'
                name='duration'
                id='duration'
                value='30'
                label='Duration'
                disabled='true'
              >
                <MenuItem value='30'>30</MenuItem>
              </Select>

              <Typography
                id='flow'
                variant='body2'
              >
                What was covered during the session? Please share the session
                flow.
              </Typography>
              <TextField
                id='description'
                multiline
                placeholder={'Please Share The Session Flow'}
                sx={{ width: '100%', marginBottom: 4 }}
                minRows={5}
                onChange={(e) =>
                  setModalData({ ...modalData, description: e.target.value })
                }
                required
              />
              <Button
                variant='contained'
                type='submit'
              >
                Update Session Details
              </Button>
            </form>
          </Stack>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

export const resetButtonStyle = {
  marginRight: '15px',
};

export const buttonTopbarContainerStyle = {
  display: 'flex',
  alignItems: 'center',
};

export const closeFullScreenStyle = {
  padding: '5px',
  boxShadow: '2px 5px #edeff1',
  '&:hover': { border: '1px solid black' },
  borderRadius: '5px',
  fontSize: '30px',
  marginRight: '10px',
};

export const openInFullStyle = {
  padding: '5px',
  boxShadow: '2px 5px #edeff1',
  '&:hover': { border: '1px solid black' },
  borderRadius: '5px',
  fontSize: '30px',
  marginRight: '10px',
};

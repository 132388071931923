// @ts-nocheck
import React, { useState } from 'react';
import { TextField, Button, Box, Tooltip } from '@mui/material';
import axios from 'axios';
import { getHeaders, createOrFetchLabel } from '../../../apis/urls';
import ColorPicker from './ColorPicker';

const initialState = {
  name: '',
  bgcolor: '#f44336',
  fontcolor: '#ffffff',
};

export default function AddNewLabel({
  setFormState,
  setHasError,
  setErrorMessage,
  setIsSuccess,
  setSuccessMessage,
  setFetchLabelsLoader,
}) {
  const [newLabel, setNewLabel] = useState(initialState);

  const handleCreateLabel = () => {
    setFetchLabelsLoader(true);
    const body = { ...newLabel };
    axios
      .post(createOrFetchLabel(), body, {
        headers: getHeaders(),
      })
      .then((res) => {
        setIsSuccess(true);
        setSuccessMessage('Chat Label created successfully');
        setFormState((prev) => [{ ...res?.data, selected: false }, ...prev]);
      })
      .catch((e) => {
        console.log(e);
        setHasError(true);
        setErrorMessage(
          e.response?.data?.message ||
            'An error occurred while creating the chat label'
        );
      })
      .finally(() => {
        setFetchLabelsLoader(false);
        setNewLabel(initialState);
      });
  };

  const handleNewLabelChange = (e) => {
    const { name, value } = e.target;
    setNewLabel((prevNewLabel) => ({
      ...prevNewLabel,
      [name]: value,
    }));
  };

  const handleBgColorChange = (color) => {
    setNewLabel((prevNewLabel) => ({
      ...prevNewLabel,
      bgcolor: color.hex,
    }));
  };

  const handleFontColorChange = (color) => {
    setNewLabel((prevNewLabel) => ({
      ...prevNewLabel,
      fontcolor: color.hex,
    }));
  };

  return (
    <Box
      display='flex'
      justifyContent='space-between'
      mt={2}
    >
      <TextField
        fullWidth
        required
        label='New Label Name'
        variant='outlined'
        name='name'
        value={newLabel.name}
        onChange={handleNewLabelChange}
      />
      <Tooltip
        title='Background Color'
        placement='top'
      >
        <Box>
          <ColorPicker
            color={newLabel.bgcolor}
            handleChange={handleBgColorChange}
          />
        </Box>
      </Tooltip>
      <Tooltip
        title='Font Color'
        placement='top'
      >
        <Box>
          <ColorPicker
            color={newLabel.fontcolor}
            colorsArray={['#000', '#fff']}
            handleChange={handleFontColorChange}
          />
        </Box>
      </Tooltip>
      <Button
        onClick={handleCreateLabel}
        variant='contained'
        type='submit'
        sx={{ height: '40px' }}
        disabled={!newLabel.name}
      >
        Add
      </Button>
    </Box>
  );
}

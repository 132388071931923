// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import axios from 'axios';
import moment from 'moment';
import { Typography } from '@mui/material';
import Countdown, { zeroPad } from 'react-countdown';
import { useEfPipeline } from '../../layouts/EfPipelineContext/EfPipelineContext';
import { getUserName } from '../../configs/auth';
import { getForm, getHeaders } from '../../apis/urls';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';
import FormContainer from './FormContainer';

const RootContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '10px',
  paddingBottom: '3rem',
});

export default function ActiveFormComponent({ invitationMessage }) {
  const { formId, setIsSuccess, setSuccessMessage, isSuccess, setMessage } =
    useEfPipeline();
  const userName = getUserName();
  const [loading, setLoading] = useState(true);
  const [expiresAt, setExpiresAt] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    axios
      .get(getForm(formId), {
        headers: getHeaders(),
      })
      .then((res) => {
        const { expires_at, message } = res.data;

        setLoading(false);

        const congratulatoryMessage =
          'Congratulations! Your response has been received.';

        if (message) {
          if (message.toLowerCase() === congratulatoryMessage.toLowerCase()) {
            setIsSuccess(true);
            setSuccessMessage(
              'Congratulations! Your response has been received.'
            );
          } else {
            setMessage(message);
          }
          return;
        }

        setQuestions(res?.data?.questions);
        setExpiresAt(expires_at);
      })
      .catch((e) => {
        console.log(e);
        setHasError(true);
        setErrorMessage(
          e.response?.data?.message || 'This form is no longer active.'
        );
        setLoading(false);
      });
    return () => {};
  }, []);

  const CountdownRenderer = ({ days, hours, minutes, seconds, completed }) => {
    return (
      <span>
        {zeroPad(days)}:{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
      </span>
    );
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}

      {!isSuccess && !loading && (
        <RootContainer>
          <>
            <Typography
              variant='h3'
              component='div'
              align='center'
            >
              Congrats, {userName}!
            </Typography>
            <Typography
              variant='h5'
              align='center'
            >
              {invitationMessage}
              <br />
              <br />
              The invitation expires in{' '}
              {expiresAt !== null && (
                <span
                  style={{
                    padding: '8px',
                    borderRadius: '5px',
                    background: '#f0f0f0',
                  }}
                >
                  <Countdown
                    date={moment(expiresAt)}
                    renderer={CountdownRenderer}
                  />
                </span>
              )}
            </Typography>
          </>
          <FormContainer
            questions={questions}
            loading={loading}
          />
        </RootContainer>
      )}
    </>
  );
}

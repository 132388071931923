import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import programReducer from './slices/programSlice';
import topicReducer from './slices/topicSlice';
import sessionReducer from './slices/sessionSlice';
import userReducer from './slices/userSlice';
import ticketSlice from './slices/ticketSlice';
import attendanceSlice from './slices/attendanceSlice';
const reducers = combineReducers({
  programs: programReducer,
  topics: topicReducer,
  sessions: sessionReducer,
  users: userReducer,
  tickets: ticketSlice,
  attendance: attendanceSlice,
});

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

// @ts-nocheck
import React, { useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  List,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArchiveIcon from '@mui/icons-material/Archive';
import axios from 'axios';
import { getUserId, getUserType } from '../../configs/auth';
import {
  fetchChatList,
  getHeaders,
  chatBulkArchive,
  markReadOrUnread,
} from '../../apis/urls';
import SendMessageModal from './Broadcast/SendMessageModal';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../ToastNotifications/SuccessNotifier';
import {
  isAdmin,
  isProgramManager,
  isStudent,
  shouldShowChatList,
  shouldShowFilters,
} from './ChatDashboard';
import ChatListFilters from './ChatListFilters';
import { ChatListItem } from './ChatListItem';
import consumer from '../../cable';
import { IconButton, Tooltip } from '@mui/material';

const initialState = {
  read_status: 'all',
  archive_status: 'unarchived',
  label_id: '',
  program_id: '',
};

const ChatList = ({
  chatList,
  selectedChat,
  setSelectedChat,
  setChatList,
  searchType,
  setSearchType,
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [filters, setFilters] = useState(initialState);
  const [open, setOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const PAGE_SIZE = 20;
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [showArchiveButton, setShowArchiveButton] = useState(false);

  /**
   * TODO: When multiple events are received at nearly same time and while we're handling the first event, second event comes,
   * updates the state and goes away, then it can leave the UI in an undesired state. Undesired state doesn't mean it'll show error
   * it just means that it'll not update the UI the way we want it to update.
   *
   * Solution: Maintain a local queue here, push all the events in this queue, and only process the events from the queue.
   *
   * Do not delete the comment, until this issue is resolved.
   */
  useEffect(() => {
    if (getUserType() === 'student') {
      return;
    }

    const chatListChannel = consumer.subscriptions.create(
      {
        channel: 'ChatListChannel',
        user_id: getUserId(),
      },
      {
        connected: () => console.log(`Subscribed to ChatListChannel`),
        disconnected: () => console.log(`Unsubscribed from ChatListChannel`),
        received: (data) => {
          console.log('An update received on chatList', data);

          const { type: eventType } = data;
          if (eventType === 'new_message') {
            handleNewMessageEventOnChatList(data);
          } else if (eventType === 'delete_message') {
            handleDeleteMessageEventOnChatList(data);
          } else if (eventType === 'edit_message') {
            handleEditMessageEventOnChatList(data);
          } else if (eventType === 'read_receipts') {
            handleReadReceiptsEventOnChatList(data);
          } else if (eventType === 'archive_update') {
            handleArchiveUpdateEventOnChatList(data);
          }
        },
      }
    );

    return () => chatListChannel.unsubscribe();
  }, []);

  useEffect(() => {
    if (!selectedChat || !selectedChat.marked_unread) {
      return;
    }

    markChatAsUnread();
    return () => {};
  }, [selectedChat]);

  useEffect(() => {
    getChatList(pageNumber);
    return () => {};
  }, []);

  useEffect(() => {
    if (chatList.length > 0 && !selectedChat && isStudent) {
      handleSelectChat(chatList[0]);
    }
    return () => {};
  }, [chatList]);

  const getChatList = (page) => {
    setLoading(true);
    const body = {};
    if (searchQuery) {
      body.search_query = searchQuery;
      body.search_type = searchType;
    }
    if (filters.read_status !== 'all') {
      body.read_status = filters.read_status;
    }
    if (filters.archive_status !== 'all') {
      body.archive_status = filters.archive_status;
    }
    if (filters.label_id) {
      body.label_id = filters.label_id;
    }
    if (filters.program_id) {
      body.program_id = filters.program_id;
    }

    const queryStr = Object.keys(body)
      .map((key) => `${key}=${encodeURIComponent(body[key])}`)
      .join('&');

    axios
      .get(fetchChatList(queryStr), {
        params: {
          page: page,
          per_page: PAGE_SIZE,
        },
        headers: getHeaders(),
      })
      .then((res) => {
        const { total_pages, list } = res.data;

        setPageNumber(page);
        setChatList(list);
        setTotalPages(total_pages);
        setShowArchiveButton(filters.archive_status === 'unarchived');
      })
      .catch((e) => {
        console.log(e);
        setHasError(true);
        setErrorMessage('An error occurred while fetching chats');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // const debouncedGetChats = debounce(getChatList, 500);

  const markChatAsUnread = () => {
    const body = {
      marked_unread: false,
    };

    axios
      .post(markReadOrUnread(selectedChat.id), body, {
        headers: getHeaders(),
      })
      .then(() => {
        setChatList((prev) => {
          const updatedChatList = prev.map((p) => {
            if (p.id === selectedChat.id) {
              return {
                ...p,
                marked_unread: false,
              };
            }
            return p;
          });

          return updatedChatList;
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleSelectChat = (chat) => {
    setSelectedChat(chat);
  };

  const handleApplyFilters = (e) => {
    setChatList([]);
    getChatList(1);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleNewMessageEventOnChatList = (response) => {
    const { body } = response;
    setChatList((prev) => {
      const chatIndex = prev.findIndex((ch) => ch.id === body.id);
      if (chatIndex === -1) {
        return [body, ...prev];
      }

      return [body, ...prev.slice(0, chatIndex), ...prev.slice(chatIndex + 1)];
    });
  };

  const handleDeleteMessageEventOnChatList = (response) => {
    const { body } = response;
    setChatList((prev) => {
      const chatIndex = prev.findIndex((ch) => ch.id === body.chat_id);
      if (chatIndex === -1) {
        return prev;
      }

      // if message received from the web socket isn't the last message of the chat, then there's no point in updating the chat list.
      if (prev[chatIndex].last_message_id !== body.id) {
        return prev;
      }

      const updatedChat = {
        ...prev[chatIndex],
        last_message: 'This message has been deleted.',
      };
      return [
        updatedChat,
        ...prev.slice(0, chatIndex),
        ...prev.slice(chatIndex + 1),
      ];
    });
  };

  const handleEditMessageEventOnChatList = (response) => {
    const { body } = response;
    setChatList((prev) => {
      const chatIndex = prev.findIndex((ch) => ch.id === body.chat_id);
      if (chatIndex === -1) {
        return prev;
      }

      // if message received from the web socket isn't the last message of the chat, then there's no point in updating the chat list.
      if (prev[chatIndex].last_message_id !== body.id) {
        return prev;
      }

      const updatedChat = {
        ...prev[chatIndex],
        last_message: body.message,
      };
      return [
        updatedChat,
        ...prev.slice(0, chatIndex),
        ...prev.slice(chatIndex + 1),
      ];
    });
  };

  const handleReadReceiptsEventOnChatList = (response) => {
    const { body } = response;
    setChatList((prev) => {
      const chatIndex = prev.findIndex((ch) => ch.id === body.chat_id);
      if (chatIndex === -1) {
        return prev;
      }

      const updatedChat = {
        ...prev[chatIndex],
        unread_count: body.unread_count,
      };

      return [
        ...prev.slice(0, chatIndex),
        updatedChat,
        ...prev.slice(chatIndex + 1),
      ];
    });
  };

  const handleArchiveUpdateEventOnChatList = (response) => {
    const { body } = response;
    if (filters.archive_status === '') {
      setChatList((prev) => {
        const chatIndex = prev.findIndex((ch) => ch.id === body.chat_id);
        if (chatIndex === -1) {
          return prev;
        }
        const updatedChat = {
          ...prev[chatIndex],
          is_archived: body.is_archived,
        };

        return [
          ...prev.slice(0, chatIndex),
          updatedChat,
          ...prev.slice(chatIndex + 1),
        ];
      });
    } else if (filters.archive_status === 'unarchived' && !body.is_archived) {
      getChatList();
    } else if (filters.archive_status === 'archived' && !body.is_archived) {
      setChatList((prev) => prev.filter((p) => p.id !== body.chat_id));
    }
  };

  const handleBulkChatArchive = (e) => {
    setLoading(true);
    axios
      .patch(
        chatBulkArchive(),
        {},
        {
          headers: getHeaders(),
        }
      )
      .then(() => {
        setChatList([]);
        setIsSuccess(true);
        setSuccessMessage('Chats successfully archived.');
      })
      .catch((e) => {
        console.log(e);
        setHasError(true);
        setErrorMessage('An error occurred while archiving');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const shouldDisableArchiveButton = () => {
    return chatList.length === 0;
  };

  const shouldDisablePreviousPage = () => pageNumber <= 1;
  const shouldDisableNextPage = () => totalPages <= pageNumber;

  if (!shouldShowChatList) {
    return <></>;
  }

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}

      {isSuccess && (
        <SuccessNotifier
          message={successMessage}
          setIsSuccess={setIsSuccess}
        />
      )}

      <Box
        display='flex'
        justifyContent='space-between'
      >
        <Typography
          variant='h5'
          m={2}
        >
          Chat List
        </Typography>

        {(isAdmin || isProgramManager) && (
          <Box sx={{ textAlign: 'right', m: 2 }}>
            <Button
              onClick={(e) => setOpen(true)}
              variant='contained'
            >
              New Message
            </Button>
          </Box>
        )}

        {open && (
          <SendMessageModal
            open={open}
            handleClose={(e) => setOpen(false)}
            setHasError={setHasError}
            setErrorMessage={setErrorMessage}
            setIsSuccess={setIsSuccess}
            setSuccessMessage={setSuccessMessage}
          />
        )}
      </Box>

      {/* Temporarily disable it */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          padding: '0px 8px 0px 8px',
        }}
      >
        <TextField
          placeholder={searchQuery === '' && `Search ${searchType}`}
          value={searchQuery}
          onChange={handleSearchChange}
          sx={{
            marginRight: '2px',
            width: 'inherit',
          }}
          InputProps={{
            startAdornment: <SearchIcon style={{ marginRight: '8px' }} />,
          }}
        />
        {/* <FormControl sx={{ width: '40%' }}>
          <InputLabel>Search In</InputLabel>
          <Select
            value={searchType}
            onChange={(e) => {
              setSearchType(e.target.value);
              setSearchQuery('');
            }}
            label='Search In'
            name='Search In'
          >
            <MenuItem value='Chats'>Chats</MenuItem>
            <MenuItem value='Messages'>Messages</MenuItem>
          </Select>
        </FormControl> */}
      </Box>

      {shouldShowFilters && (
        <ChatListFilters
          handleApplyFilters={handleApplyFilters}
          filters={filters}
          setFilters={setFilters}
        />
      )}

      <Divider />
      <List
        sx={{
          flexGrow: 1,
          overflowY: 'auto',
          height: '100%',
        }}
      >
        {loading && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              background:
                'linear-gradient(to bottom left, rgba(180, 180, 180, 0.5), rgba(180, 180, 180, 0.5))',
              borderBottomLeftRadius: '5px',
            }}
          >
            <CircularProgress />
          </Box>
        )}

        {showArchiveButton && (
          <Button
            sx={{
              height: '26px',
              fontSize: '14px',
              marginLeft: '10px',
              marginBottom: '10px',
            }}
            variant='outlined'
            onClick={handleBulkChatArchive}
            disabled={shouldDisableArchiveButton()}
          >
            <ArchiveIcon sx={{ fontSize: '18px', marginRight: '6px' }} />{' '}
            Archive all
          </Button>
        )}

        {chatList?.length === 0 ? (
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            height='100%'
          >
            <Typography
              variant='body1'
              color='textSecondary'
            >
              No chats were found with these filters
            </Typography>
          </Box>
        ) : (
          chatList?.map((chat, index) => (
            <React.Fragment key={chat.id}>
              <ChatListItem
                filters={filters}
                chatItem={chat}
                handleSelectChat={handleSelectChat}
                chatList={chatList}
                setChatList={setChatList}
                searchQuery={searchQuery}
                searchType={searchType}
                updateLoaderForChatList={(v) => setLoading(v)}
              />
            </React.Fragment>
          ))
        )}
      </List>

      <Divider />

      <Box
        sx={{
          flexGrow: 1,
          height: '5rem',
          display: 'flex',
          justifyContent: 'center',
          gap: '1rem',
          padding: '1rem',
          justifySelf: 'flex-end',
        }}
      >
        <Tooltip title='Previous page'>
          <IconButton
            disabled={shouldDisablePreviousPage()}
            color='primary'
            onClick={(e) => getChatList(Math.max(pageNumber - 1, 1))}
          >
            <ArrowBackIosIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title='Next page'>
          <IconButton
            disabled={shouldDisableNextPage()}
            color='primary'
            onClick={(e) => getChatList(pageNumber + 1)}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default ChatList;

// @ts-nocheck
import {
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
  OutlinedInput,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import { Box } from '@material-ui/system';
import React, { useEffect, useState } from 'react';
import moment from 'moment/moment';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import InputAdornment from '@mui/material/InputAdornment';
import HelpIcon from '@mui/icons-material/Help';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import { isAdmin, isProgramManager } from '../../ChatDashboard/ChatDashboard';
import { getUserType } from '../../../configs/auth';
import { unsetSessions } from '../../redux-store/slices/sessionSlice';
import {
  fetchAllUsersURL,
  fetchProgramsUrl,
  fetchTopicsUrl,
  getHeaders,
} from '../../../apis/urls';
import ErrorNotifier from '../../ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../ToastNotifications/SuccessNotifier';
import {
  setCurrentProgram,
  setPrograms,
  unsetCurrentProgram,
  updatePrograms,
} from '../../redux-store/slices/programSlice';
import { setTopics } from '../../redux-store/slices/topicSlice';
import LoadingScreen from '../../../pages/LoadingScreen';
import KnowMoreAboutProgramFields from './KnowMoreAboutProgramFields';

const daysOfWeek = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

export const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const cardStyles = makeStyles({
  card: {
    transition: 'all 0.5s ease-in-out',
    '&:hover': {
      transition: 'all 0.3s ease-in-out',
      transform: 'scale(1.1)',
    },
    cursor: 'pointer',
    textAlign: 'center',
  },
});

const initialState = {
  name: '',
  internal_name: '',
  program_type: '',
  start_date: '',
  end_date: '',
  drive_link: '',
  google_meet_link: '',
  program_manager_id: '',
  feedback_link: '',
  sessions_start_on: '',
  sessions_end_on: '',
  induction_start_on: '',
  max_classes: 65,
};

export default function Programs() {
  const dispatch = useDispatch();
  const programs = useSelector((state) => state.programs.programs);
  const [openProgramModal, setOpenProgramModal] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const handleClose = () => setOpenProgramModal(false);
  const [newProgramDetails, setNewProgramDetails] = useState(initialState);
  const [searchTxt, setSearchTxt] = useState('');
  const [programData, setProgramData] = useState(programs);
  const [programManagers, setProgramManagers] = useState([]);
  const navigate = useNavigate();
  const classes = cardStyles();

  useEffect(() => {
    // reset current program in redux store
    dispatch(unsetCurrentProgram());

    // reset sessions in redux store
    dispatch(unsetSessions());

    getTopics();
    getProgramManager();
  }, []);

  useEffect(() => {
    const searchFilter = () => {
      if (!searchTxt) {
        setProgramData(programs);
        return;
      }
      const filteredPrograms = {};
      Object.keys(programs).forEach((key) => {
        const program = programs[key];
        if (
          program.internal_name.toLowerCase().includes(searchTxt.toLowerCase())
        ) {
          filteredPrograms[key] = program;
        }
      });
      setProgramData(filteredPrograms);
    };
    searchFilter();
  }, [searchTxt, programs]);

  const getProgramManager = () => {
    axios
      .get(fetchAllUsersURL(), {
        headers: getHeaders(),
        params: {
          role: 'program_manager',
        },
      })
      .then((res) => {
        setProgramManagers(res.data);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(err.response?.data?.message || err.message);
      });
  };

  const getTopics = () => {
    axios
      .get(fetchTopicsUrl(), {
        headers: getHeaders(),
      })
      .then((res) => {
        dispatch(setTopics(res.data ?? []));
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(err.response?.data?.message || err.message);
      });
  };

  const handleAddProgramClick = (event) => {
    setOpenProgramModal(true);
  };

  const moveToSpecificProgram = (programName, pid) => {
    dispatch(setCurrentProgram(pid));
    if (getUserType() === 'teaching_assistant') {
      navigate(`/mentor/programs/${programName}/reports`);
    } else {
      navigate(`/mentor/programs/${programName}/enrollments`);
    }
  };

  return (
    <Box>
      {hasError ? (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      ) : (
        ''
      )}
      {isSuccess ? (
        <SuccessNotifier
          message={successMessage}
          setIsSuccess={setIsSuccess}
        />
      ) : (
        ''
      )}
      <Box
        display='flex'
        justifyContent='space-between'
        sx={{ paddingInlineEnd: '2rem', marginBottom: '20px' }}
      >
        <FormControl
          sx={{ width: '360px', height: '40px', marginLeft: '12px' }}
        >
          <OutlinedInput
            placeholder='Search programs'
            sx={{ width: '360px', height: '40px' }}
            onChange={(e) => {
              setSearchTxt(e.target.value);
            }}
            startAdornment={
              <InputAdornment position='start'>
                <SearchIcon fontSize='16px' />
              </InputAdornment>
            }
          />
        </FormControl>
        {(isAdmin || isProgramManager) && (
          <Button
            variant='contained'
            onClick={handleAddProgramClick}
          >
            Add program
          </Button>
        )}
      </Box>

      <Box
        display='flex'
        flexWrap='wrap'
      >
        {programData &&
          Object.keys(programData).length > 0 &&
          Object.keys(programData)?.map((pid, index) => (
            <Card
              sx={{
                margin: 2,
                minWidth: 200,
                minHeight: 200,
              }}
              key={index + 1}
              className={classes.card}
            >
              <CardContent
                onClick={(e) =>
                  moveToSpecificProgram(programs[pid].internalNameSlug, pid)
                }
                sx={{ pt: 10, pb: 10 }}
              >
                {programs[pid].internal_name}
              </CardContent>
            </Card>
          ))}
      </Box>
      {(!programData || Object.keys(programData).length === 0) && (
        <Typography
          variant='h5'
          component='h2'
          textAlign='center'
          sx={{ marginBlockStart: '5rem' }}
        >
          No programs found
        </Typography>
      )}

      {openProgramModal && (
        <AddProgram
          openProgramModal={openProgramModal}
          newProgramDetails={newProgramDetails}
          programManagers={programManagers}
          setNewProgramDetails={setNewProgramDetails}
          handleClose={handleClose}
          setErrorMessage={setErrorMessage}
          setHasError={setHasError}
          setIsSuccess={setIsSuccess}
          setSuccessMessage={setSuccessMessage}
        />
      )}
    </Box>
  );
}

function AddProgram({
  openProgramModal,
  newProgramDetails,
  setNewProgramDetails,
  handleClose,
  setHasError,
  setErrorMessage,
  setIsSuccess,
  programManagers,
  setSuccessMessage,
}) {
  const dispatch = useDispatch();
  const [teachingDays, setTeachingDays] = useState([{ time: '', day: '' }]);
  const [loading, setLoading] = useState(false);
  const [showKnowMore, setShowKnowMore] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    const start_date = moment(newProgramDetails.start_date)
      .utc()
      .startOf('day');

    const induction_start_on = moment(newProgramDetails.induction_start_on)
      .utc()
      .startOf('day');

    const end_date = moment(newProgramDetails.end_date).utc().endOf('day');
    const sessions_start_on = moment(newProgramDetails.sessions_start_on).utc();

    const sessions_end_on = moment(newProgramDetails.sessions_end_on)
      .utc()
      .endOf('day');

    const body = {
      ...newProgramDetails,
      end_date,
      sessions_end_on,
      start_date,
      induction_start_on,
      sessions_start_on,
    };

    if (body.program_type === 'super30') {
      if (
        teachingDays.length === 0 ||
        teachingDays[0].time === '' ||
        teachingDays[0].day === ''
      ) {
        setHasError(true);
        setErrorMessage(
          'Atleast one teaching day information is required for super30 programs.'
        );
        return;
      }
      body.teaching_days = JSON.stringify(teachingDays);
    }

    setLoading(true);

    axios
      .post(fetchProgramsUrl(), body, {
        headers: getHeaders(),
      })
      .then((res) => {
        setIsSuccess(true);
        setSuccessMessage('Program successfully created.');
        dispatch(updatePrograms(res.data ?? {}));
        handleClose();
        setNewProgramDetails(initialState);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(err.response?.data?.message || err.message);
      })
      .finally(() => setLoading(false));
  };

  const handleChange = (e) => {
    setNewProgramDetails({
      ...newProgramDetails,
      [e.target.name]: e.target.value,
    });
  };

  const addMoreTeachingDays = (e) => {
    setTeachingDays((prev) => [...prev, { time: '', day: '' }]);
  };

  const removeTeachingDay = (index) => {
    if (teachingDays.length === 1) {
      setHasError(true);
      setErrorMessage(
        'Atleast one teaching day information is required for super30 programs.'
      );
      return;
    }
    setTeachingDays((prev) => [
      ...prev.slice(0, index),
      ...prev.slice(index + 1),
    ]);
  };

  const handleTeachingDayChange = (index, field, value) => {
    setTeachingDays((prevState) => {
      const updatedTeachingDays = [...prevState];
      updatedTeachingDays[index][field] = value;
      return updatedTeachingDays;
    });
  };

  const handleKnowMore = (e) => {
    setShowKnowMore((p) => !p);
  };

  return (
    <>
      <Modal
        open={openProgramModal}
        onClose={handleClose}
        style={{ overflow: 'auto' }}
      >
        <form onSubmit={handleSubmit}>
          <Box sx={style}>
            <LoadingScreen loading={loading} />

            {showKnowMore && (
              <KnowMoreAboutProgramFields handleKnowMore={handleKnowMore} />
            )}

            <Stack spacing={1}>
              <Box
                display='flex'
                justifyContent='flex-end'
              >
                <Tooltip title='Know more about the fields'>
                  <IconButton onClick={handleKnowMore}>
                    <HelpIcon color='info' />
                  </IconButton>
                </Tooltip>
              </Box>

              <TextField
                fullWidth
                id='name'
                label='Program name'
                name='name'
                variant='outlined'
                value={newProgramDetails.name}
                onChange={handleChange}
                required
              />
              <TextField
                fullWidth
                id='internal_name'
                label='Program Internal name'
                name='internal_name'
                variant='outlined'
                value={newProgramDetails.internal_name}
                onChange={handleChange}
                required
              />
              <Grid
                container
                columnSpacing={2}
                sx={{ marginLeft: '-16px !important' }}
              >
                <Grid
                  item
                  xs={6}
                >
                  <TextField
                    fullWidth
                    id='start_date'
                    type='date'
                    name='start_date'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label='Start date'
                    value={newProgramDetails.start_date}
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                >
                  <TextField
                    fullWidth
                    id='end_date'
                    type='date'
                    name='end_date'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label='End date'
                    value={newProgramDetails.end_date}
                    onChange={handleChange}
                    required
                  />
                </Grid>
              </Grid>
              <Grid
                container
                columnSpacing={2}
                sx={{ marginLeft: '-16px !important' }}
              >
                <Grid
                  item
                  xs={6}
                >
                  <TextField
                    fullWidth
                    id='sessions_start_on'
                    type='datetime-local'
                    name='sessions_start_on'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label='Sessions start on '
                    value={newProgramDetails.sessions_start_on}
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                >
                  <TextField
                    fullWidth
                    id='sessions_end_on'
                    type='date'
                    name='sessions_end_on'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label='Sessions end on'
                    value={newProgramDetails.sessions_end_on}
                    onChange={handleChange}
                    required
                  />
                </Grid>
              </Grid>
              <TextField
                fullWidth
                id='induction_start_on'
                type='datetime-local'
                name='induction_start_on'
                InputLabelProps={{
                  shrink: true,
                }}
                label='Induction start on'
                value={newProgramDetails.induction_start_on}
                onChange={handleChange}
                required
              />
              <TextField
                fullWidth
                id='drive_link'
                label='Drive link'
                name='drive_link'
                variant='outlined'
                value={newProgramDetails.drive_link}
                onChange={handleChange}
              />
              <TextField
                fullWidth
                id='google_meet_link'
                label='Google meet link'
                name='google_meet_link'
                value={newProgramDetails.google_meet_link}
                onChange={handleChange}
                required
              />
              <TextField
                fullWidth
                id='feedback_link'
                name='feedback_link'
                label='Feedback link'
                value={newProgramDetails.feedback_link}
                onChange={handleChange}
                required
              />

              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id='program_type'>Program type</InputLabel>
                <Select
                  labelId='program_type'
                  name='program_type'
                  id='program_type'
                  value={newProgramDetails.program_type}
                  label='Program type'
                  onChange={handleChange}
                >
                  <MenuItem value='cp_coach'>CP coach</MenuItem>
                  <MenuItem value='super30'>Super 30</MenuItem>
                  <MenuItem value='personalized'>Personal coaching</MenuItem>
                </Select>
              </FormControl>

              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id='program_manager_id'>Program Manager</InputLabel>
                <Select
                  labelId='program_manager_id'
                  name='program_manager_id'
                  id='program_manager_id'
                  value={newProgramDetails.program_manager_id}
                  label='Program Manager'
                  onChange={handleChange}
                  required
                >
                  {programManagers.length &&
                    programManagers.map((e) => {
                      return (
                        <MenuItem value={e.id}>{`${e.name && e.name} (${
                          e.email && e.email
                        })`}</MenuItem>
                      );
                    })}
                </Select>
              </FormControl>

              {newProgramDetails.program_type === 'personalized' && (
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id='max_classes'>Max classes</InputLabel>
                  <Select
                    labelId='max_classes'
                    name='max_classes'
                    id='max_classes'
                    label='Max classes'
                    onChange={handleChange}
                  >
                    <MenuItem value='36'>
                      36 (3 months and 12 classes per month)
                    </MenuItem>

                    <MenuItem value='48'>
                      48 (4 months and 12 classes per month)
                    </MenuItem>
                    <MenuItem value='65'>
                      65 (5 months and 12 classes per month)
                    </MenuItem>
                    <MenuItem value='72'>
                      72 (6 months and 12 classes per month)
                    </MenuItem>
                    <MenuItem value='96'>
                      96 (8 months and 12 classes per month)
                    </MenuItem>
                    <MenuItem value='120'>
                      120 (10 months and 12 classes per month)
                    </MenuItem>
                    <MenuItem value='144'>
                      144 (12 months and 12 classes per month)
                    </MenuItem>
                  </Select>
                </FormControl>
              )}

              {newProgramDetails?.program_type === 'super30' && (
                <>
                  {teachingDays.map((td, index) => {
                    return (
                      <Stack
                        direction='row'
                        spacing={1}
                      >
                        <FormControl>
                          <InputLabel id='day'>Day</InputLabel>
                          <Select
                            label='Day'
                            labelId='day'
                            id='day'
                            value={td.day}
                            onChange={(e) =>
                              handleTeachingDayChange(
                                index,
                                'day',
                                e.target.value
                              )
                            }
                            size='small'
                            sx={{ width: '7rem' }}
                            required
                          >
                            {daysOfWeek.map((day, dayIndex) => (
                              <MenuItem
                                key={dayIndex}
                                value={day}
                              >
                                {day}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>

                        <TextField
                          label='Time'
                          type='time'
                          size='small'
                          value={td.time}
                          onChange={(e) =>
                            handleTeachingDayChange(
                              index,
                              'time',
                              e.target.value
                            )
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                          required
                        />
                        <IconButton onClick={(e) => removeTeachingDay(index)}>
                          <DeleteIcon color='error' />
                        </IconButton>
                      </Stack>
                    );
                  })}

                  <Button
                    size='small'
                    color='success'
                    variant='contained'
                    onClick={addMoreTeachingDays}
                  >
                    Add more teaching days
                  </Button>
                </>
              )}
              <Button
                size='large'
                type='submit'
                variant='contained'
                color='secondary'
              >
                Add
              </Button>
            </Stack>
          </Box>
        </form>
      </Modal>
    </>
  );
}

// @ts-nocheck
import React, { useEffect } from 'react';
import { Typography, Button, Box, Link } from '@mui/material';
import { useFastrackPipeline } from 'src/layouts/EfPipelineContext/FastrackPipelineContex';
import EastIcon from '@mui/icons-material/East';
import Thunder from '../../../../assets/bolt.png';
import { BASE_URL, getHeaders } from 'src/apis/urls';
import axios from 'axios';
import { Concerns } from '../index';
const { STILL_HAVE_DOUBT } = Concerns;

const EnrollSection = ({ saveFlowData }) => {
  const {
    releaseOfferLetter,
    setScreenSource,
    screenSource,
    trackButtonClick,
    offerLetter,
  } = useFastrackPipeline();

  useEffect(() => {
    setScreenSource('screen_3j');
    return () => {};
  }, []);

  const sendAlert = async ({ concern }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/api/offer-letters/${offerLetter.olId}/concerns_alert`,
        {
          concern,
          lead_id: offerLetter.leadId,
          assignee: offerLetter.lead_owner,
          lead_name:
            (offerLetter.fname ?? '') +
            (offerLetter.lname ? ` ${offerLetter.lname}` : ''),
        },
        {
          headers: getHeaders(),
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleReadyToEnroll = () => {
    releaseOfferLetter({ source: screenSource });
  };

  const handleStillHaveDoubts = () => {
    sendAlert({ concern: STILL_HAVE_DOUBT });
    trackButtonClick({
      source: 'screen_3j',
      eventName: 'still_have_doubts',
    });
    saveFlowData({
      screen: 'screen_3j',
      response: { is_ready_to_enroll: false },
    });
    setScreenSource('screen_2b');
  };

  return (
    <Box
      sx={{
        textAlign: 'center',
        padding: { xs: '1rem', sm: '1.5rem', md: '3rem' },
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        maxWidth: '1200px',
        margin: '0 auto',
      }}
    >
      <Typography
        variant='h4'
        sx={{
          fontWeight: 'bold',
          color: '#0078F0',
          marginBottom: { xs: '0.8rem', md: '1.5rem' },
          fontSize: { xs: '1.4rem', sm: '1.8rem', md: '2.5rem' },
          lineHeight: { xs: '1.2', sm: '1.3', md: '1.4' },
        }}
      >
        Get left behind, or catch up before it’s too late.
      </Typography>

      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        mb={{ xs: 1, md: 2 }}
      >
        <img
          src={Thunder}
          alt='Thunder icon'
          style={{
            width: '5rem',
            height: '4rem',
          }}
        />
      </Box>

      <Typography
        variant='body1'
        sx={{
          marginBottom: { xs: '1.2rem', sm: '1.5rem', md: '2rem' },
          fontSize: { xs: '0.85rem', sm: '1rem', md: '1.2rem' },
          color: 'black',
        }}
      >
        Remember, promotions and dream jobs don’t wait for anyone. Don’t let{' '}
        <br />
        others zoom ahead while you're still hesitating.
      </Typography>

      <Button
        variant='contained'
        sx={{
          fontFamily: 'Inter',
          textTransform: 'none',
          padding: { xs: '0.4rem 0.8rem', sm: '0.5rem 1rem' },
          fontSize: { xs: '0.85rem', sm: '0.95rem', md: '1rem' },
          background:
            'linear-gradient(to right, rgb(0, 154, 240), rgb(251, 199, 4))',
        }}
        onClick={handleReadyToEnroll}
        endIcon={<EastIcon />}
      >
        I'm ready to enroll
      </Button>

      <Box mt={{ xs: 1.2, md: 2 }}>
        <Link
          href='#'
          sx={{
            color: '#0078F0',
            fontSize: { xs: '0.85rem', md: '1rem' },
            textDecoration: 'underline',
          }}
          onClick={handleStillHaveDoubts}
        >
          {STILL_HAVE_DOUBT}
        </Link>
      </Box>
    </Box>
  );
};

export default EnrollSection;

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import {
  MenuItem,
  Typography,
  Select,
  InputLabel,
  FormControl,
} from '@material-ui/core';
import { Box, Button, TextField } from '@mui/material';
import ErrorNotifier from '../../../ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../../ToastNotifications/SuccessNotifier';
import {
  getHeaders,
  fetchProgramDetailsUrl,
  updateProgramDetailsUrl,
} from '../../../../apis/urls';
import TeachingDays from './TeachingDays';

const formatDate = (date) => {
  return date ? moment(date).format('YYYY-MM-DD') : '';
};
const formatData = (programData) => {
  return {
    name: programData.name,
    internal_name: programData.internal_name,
    start_date: programData.start_date,
    end_date: programData.end_date,
    drive_link: programData.drive_link,
    google_meet_link: programData.google_meet_link,
    feedback_link: programData.feedback_link,
    teaching_days: JSON.parse(programData.teaching_days ?? '[]'),
    max_classes: programData.max_classes,
  };
};

export default function ProgramSettings() {
  const currentProgram = useSelector((state) => state.programs.currentProgram);
  const [formState, setFormState] = useState({});
  const [program, setProgram] = useState({});
  const [edit, setEdit] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    if (currentProgram) {
      getProgramDetails();
    }
    return () => {};
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const getProgramDetails = () => {
    axios
      .get(fetchProgramDetailsUrl(currentProgram?.programId), {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((res) => {
        const programData = res.data;
        setProgram(formatData(programData));
        setFormState(formatData(programData));
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message ||
            'An error occured while fetching program details'
        );
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const allTeachingDays = [...(formState.teaching_days || [])];
    const filteredTeachingDays = allTeachingDays
      .filter(
        (teachingDay, index, self) =>
          index ===
          self.findIndex(
            (td) => td.day === teachingDay.day && td.time === teachingDay.time
          )
      )
      .filter((teachingDay) => teachingDay.day && teachingDay.time);

    const patchData = {
      ...formState,
      teaching_days: JSON.stringify(filteredTeachingDays),
    };

    const body = {};
    Object.keys(patchData).forEach((key) => {
      if (patchData[key] !== program[key]) {
        body[key] = patchData[key];
      }
    });

    axios
      .patch(updateProgramDetailsUrl(currentProgram?.programId), body, {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((res) => {
        const programData = res.data;
        setProgram(formatData(programData));
        setFormState(formatData(programData));
        setIsSuccess(true);
        setSuccessMessage('Program Details successfully updated');
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message ||
            'An error occured while updating the program details'
        );
      });
    setEdit(false);
  };

  const handleCancel = () => {
    setFormState(program);
    setEdit(false);
  };
  console.log(formState);
  return (
    <Box sx={{ m: 2 }}>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      {isSuccess && (
        <SuccessNotifier
          message={successMessage}
          setIsSuccess={setIsSuccess}
        />
      )}
      <Typography
        variant='h4'
        sx={{
          mb: 4,
        }}
      >
        Settings
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          gap: '16px',
          width: '90%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '16px',
            width: '100%',
            flexWrap: 'wrap',
            '@media (max-width: 600px)': {
              flexDirection: 'column',
            },
          }}
        >
          <TextField
            label='Name'
            type='text'
            name='name'
            value={formState.name}
            onChange={handleChange}
            InputLabelProps={{ shrink: true }}
            disabled={!edit}
            sx={{ flexGrow: 1, flexBasis: '22%' }}
          />
          <TextField
            label='Internal Name'
            type='text'
            name='internal_name'
            value={formState.internal_name}
            onChange={handleChange}
            InputLabelProps={{ shrink: true }}
            disabled={!edit}
            sx={{ flexGrow: 1, flexBasis: '45%' }}
          />
          <TextField
            label='Start Date'
            type='date'
            name='start_date'
            value={formatDate(formState.start_date)}
            onChange={handleChange}
            disabled={!edit}
            InputLabelProps={{ shrink: true }}
            sx={{ flexGrow: 1, flexBasis: '24%' }}
          />
          <TextField
            label='End Date'
            type='date'
            name='end_date'
            value={formatDate(formState.end_date)}
            onChange={handleChange}
            disabled={!edit}
            InputLabelProps={{ shrink: true }}
            sx={{ flexGrow: 1, flexBasis: '24%' }}
          />
        </Box>
        <TextField
          label='Drive Link'
          type='text'
          name='drive_link'
          value={formState.drive_link}
          onChange={handleChange}
          disabled={!edit}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label='Google Meet Link'
          type='text'
          name='google_meet_link'
          value={formState.google_meet_link}
          onChange={handleChange}
          disabled={!edit}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label='Feedback Link'
          type='text'
          name='feedback_link'
          value={formState.feedback_link}
          onChange={handleChange}
          disabled={!edit}
          InputLabelProps={{ shrink: true }}
        />
        {formState.max_classes && (
          <FormControl sx={{ width: '100%' }}>
            <InputLabel id='max_classes'>Max classes</InputLabel>
            <Select
              disabled
              labelId='max_classes'
              name='max_classes'
              id='max_classes'
              value={formState.max_classes}
              label='Max classes'
              onChange={handleChange}
            >
              <MenuItem value='36'>
                36 (3 months and 12 classes per month)
              </MenuItem>
              <MenuItem value='48'>
                48 (4 months and 12 classes per month)
              </MenuItem>
              <MenuItem value='72'>
                72 (6 months and 12 classes per month)
              </MenuItem>
              <MenuItem value='96'>
                96 (8 months and 12 classes per month)
              </MenuItem>
              <MenuItem value='120'>
                120 (10 months and 12 classes per month)
              </MenuItem>
              <MenuItem value='144'>
                144 (12 months and 12 classes per month)
              </MenuItem>
            </Select>
          </FormControl>
        )}
        <TeachingDays
          formState={formState}
          edit={edit}
          setFormState={setFormState}
        />
        <Box
          sx={{
            textAlign: 'center',
            mt: 2,
          }}
        >
          {edit ? (
            <Box>
              <Button
                onClick={handleSubmit}
                sx={{
                  px: 6,
                  py: 1,
                  mr: 4,
                }}
                variant='contained'
                type='submit'
              >
                Save
              </Button>
              <Button
                onClick={handleCancel}
                sx={{
                  px: 6,
                  py: 1,
                }}
                color='error'
                variant='outlined'
                type='submit'
              >
                Cancel
              </Button>
            </Box>
          ) : (
            <Button
              onClick={() => setEdit(true)}
              sx={{
                px: 9,
                py: 1,
              }}
              variant='contained'
            >
              Edit
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
}

import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

const reschedulingReasons = [
  'Change in coach',
  'No coach available',
  'Electricity - Network Issues',
  'On call',
  'Other commitments',
];

export default function ReschedulingReasonDropdown({
  modalData,
  setModalData,
  sx,
}) {
  return (
    <>
      <FormControl sx={sx}>
        <InputLabel id='rescheduling_reason'>Rescheduling Reason</InputLabel>
        <Select
          required
          labelId='rescheduling_reason'
          id='rescheduling_reason'
          value={modalData.rescheduling_reason}
          label='Rescheduling Reason'
          onChange={(e) =>
            setModalData({ ...modalData, rescheduling_reason: e.target.value })
          }
        >
          {reschedulingReasons?.map((reason, index) => (
            <MenuItem value={reason}>{reason}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}

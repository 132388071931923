import React from 'react';
import InteractionList from './InteractionList';
import TicketList from './TicketsList';
import NpsList from './NpsList';

export default function CombinedList({ data }) {
  const renderItem = (item) => {
    switch (item.type) {
      case 'interaction':
        return <InteractionList interactions={[item]} />;
      case 'ticket':
        return <TicketList tickets={[item]} />;
      case 'feedback':
        return <NpsList feedbacks={[item]} />;
      default:
        return null;
    }
  };

  return (
    <>
      {data.map((item, index) => (
        <React.Fragment key={index}>{renderItem(item)}</React.Fragment>
      ))}
    </>
  );
}

import React from 'react';
import { Box } from '@mui/material';
import DividerComponent from '../Atoms/DividerComponent';
import TypographyText from '../Atoms/TypographyText';

const PlacementText = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '400%',
        gap: '10px',
      }}
    >
      <DividerComponent dividerStyleClass='placement-divider' />
      <TypographyText text='Placement Milestone' />
    </Box>
  );
};

export default PlacementText;

import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import {
    Typography,
    Box,
    Button,
    Table,
    TableContainer,
    TableBody,
    TableRow,
    TableHead,
    Grid,
    TableCell,
    Paper
} from '@material-ui/core';
import CloseOutlined from '@iconify/icons-ant-design/close-outlined';
import { Icon } from '@iconify/react';
import { format } from 'date-fns';
import axios from 'axios';
import { styled } from '@material-ui/core/styles';
import { withStyles, makeStyles } from '@material-ui/styles';
import { ArrowBack } from '@mui/icons-material';
import { fetchAssignmentSubmissions, getHeaders, fetchImageURL } from '../../../apis/urls';
import GradeAssignment from './GradeAssignment';
import SubmissionsTable from './SubmissionsTable';
import ErrorNotifier from '../../ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../ToastNotifications/SuccessNotifier';
import { getUserType } from '../../../configs/auth';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        textAlign: 'center'
    },
    body: {
        fontSize: 14,
        textAlign: 'center'
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover
        }
    }
}))(TableRow);
const ContentStyle = styled('div')(() => ({
    maxWidth: 1200,
    margin: 'auto',
    display: 'flex',
    marginTop: 18,
    borderRadius: 6,
    backgroundColor: 'white',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 32
}));
const useStyles = makeStyles({
    table: {
        minWidth: 700,
    }
});

function AssignmentCompletion({ id, handleClose }) {
    const classes = useStyles();
    const userType = getUserType();
    const [submission, setSubmission] = useState([]);
    const [FocusOnStudent, setFocusOnStudent] = useState(false);
    const [selectedAssignment, setselectedAssignment] = useState();
    const [selectForGrade, setselectForGrade] = useState();
    const [showGradeCard, setShowGradeCard] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const currentProgram = useSelector(state => state.programs.currentProgram);

    useEffect(() => {
        if (!showGradeCard && currentProgram) {
            axios.get(fetchAssignmentSubmissions(id), { 
                headers: getHeaders(currentProgram.programId) 
            })
            .then((res) => {
                setSubmission(res?.data?.assignment_submissions);
            })
            .catch((e) => {
                console.log(e);
                setHasError(true);
                setErrorMessage(e.response?.data?.message || e.message);
            });
        }
        return () => {};
    }, [showGradeCard, currentProgram]);

    const fetchAssignmentSubmissionFile = async (submission) => {
        await fetch(fetchImageURL(submission?.id), { 
            headers: getHeaders(currentProgram.programId) 
        })
        .then((resp) => resp.json())
        .then((res) => {
            if (res?.file) {
                openInNewTab(res?.file);
            } else {
                setHasError(true);
                setErrorMessage('There was an error opening the file');    
            }
        })
        .catch((e) => {
            console.log(e);
            setHasError(true);
            setErrorMessage(e.response?.data?.message || 'There was an error opening the file');
        });
    };

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    };

    function GetAllSubmissions(subs) {
        const SinglestudentInformation = submission?.filter((a) => {
            return a?.user_id === subs?.user_id;
        });
        setselectedAssignment(SinglestudentInformation);
        setFocusOnStudent(true);
    }

    return (
        <ContentStyle>
            <Grid container style={{ marginBottom: '8px' }}>
                {hasError
                ? <ErrorNotifier message={errorMessage} setHasError={setHasError} />
                : ""
                }
                {isSuccess
                ? <SuccessNotifier message={successMessage} setIsSuccess={setIsSuccess} />
                : ""
                }
                <Grid items xs={11}>
                    {FocusOnStudent && (
                        <Box
                            style={{
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <ArrowBack
                                onClick={() => {
                                    setselectedAssignment(null);
                                    setFocusOnStudent(!FocusOnStudent);
                                    setSubmission(submission);
                                    setShowGradeCard(false);
                                }}
                                style={{ padding: 2, color: 'blue', cursor: 'pointer' }}
                            />
                            <Typography
                                onClick={() => {
                                    setselectedAssignment(null);
                                    setFocusOnStudent(!FocusOnStudent);
                                    setSubmission(submission);
                                    setShowGradeCard(false);
                                }}
                                component="div"
                                variant="body2"
                                color="blue"
                                style={{
                                    cursor: 'pointer'
                                }}
                            >
                                Back to All Assignments
                            </Typography>
                        </Box>
                    )}
                    <Typography textAlign="center" variant="h3" component="h3">
                        {FocusOnStudent
                            ? `${
                                  selectedAssignment && selectedAssignment[0]?.student_name
                              }'s Assignments`
                            : 'Student Submissions'}
                    </Typography>
                </Grid>
                <Grid items xs={1} style={{ textAlign: 'center', margin: 'auto' }}>
                    <Icon
                        icon={CloseOutlined}
                        onClick={() => handleClose()}
                        style={{ cursor: 'pointer' }}
                        width="20"
                        height="20"
                    />
                </Grid>
            </Grid>
            {submission.length !== 0 ? (
                <>
                    {!FocusOnStudent ? (
                        <>
                            <SubmissionsTable
                                Handler={(subs) => GetAllSubmissions(subs)}
                                submission={submission}
                            />
                        </>
                    ) : (
                        <>
                            {selectedAssignment && !showGradeCard && selectedAssignment?.length > 0 && (
                                <AssignmentsDisplayCard
                                    submission={selectedAssignment}
                                    onClickHandler={(val) => fetchAssignmentSubmissionFile(val)}
                                    button_text="View File"
                                    GradeHandler={(gradeselect) => {
                                        setShowGradeCard(true);
                                        setselectForGrade(gradeselect);
                                    }}
                                />
                            )}
                            {showGradeCard && (
                                <GradeAssignment 
                                    submission={selectForGrade}
                                    assignment_id={id} 
                                    setHasError={setHasError}
                                    setIsSuccess={setIsSuccess}
                                    setErrorMessage={setErrorMessage}
                                    setSuccessMessage={setSuccessMessage}
                                    setShowGradeCard={setShowGradeCard}
                                />
                            )}
                        </>
                    )}
                </>
            ) : (
                <Box display="flex" justifyContent="center">
                    <Typography textAlign="center" variant="h5" component="h5">
                        No Submission yet
                    </Typography>
                </Box>
            )}
        </ContentStyle>
    );
}

export default AssignmentCompletion;

function AssignmentsDisplayCard({ submission, onClickHandler, button_text, GradeHandler }) {
    const classes = useStyles();
    return (
        <TableContainer component={Paper} style={{ height: 700 }}>
            <Table className={classes.table} >
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Status</StyledTableCell>
                        <StyledTableCell>Submission</StyledTableCell>
                        <StyledTableCell>Submitted On</StyledTableCell>
                        <StyledTableCell>Evaluate</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <>
                        {submission?.length !== 0 &&
                            submission &&
                            submission?.map((subs) => {
                                return (
                                    <StyledTableRow key={subs.user_id}>
                                        <StyledTableCell>
                                            {(subs.score != null) ? 'Graded' : 'Not Graded'}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <Button onClick={() => onClickHandler(subs)}>
                                                {button_text}
                                            </Button>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {format(new Date(subs.created_at), 'do MMMM yyyy')}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <Button onClick={() => GradeHandler(subs)}>
                                                Grade
                                            </Button>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                );
                            })}
                    </>
                </TableBody>
            </Table>
        </TableContainer>
    );
}

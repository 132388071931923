/* eslint-disable react/prop-types */
import * as Yup from 'yup';
import { styled } from '@material-ui/core/styles';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
// material
import {
  Stack,
  TextField,
  Typography,
  Select,
  MenuItem,
  Button,
  FormControl,
  InputLabel,
  Box,
} from '@material-ui/core';
import { Icon } from '@iconify/react';
import CloseOutlined from '@iconify/icons-ant-design/close-outlined';
import { LoadingButton } from '@material-ui/lab';
import axios from 'axios';
import { fetchUserProfileUrl, getHeaders } from '../apis/urls';
import SelectFieldComponent from 'src/layouts/shared/SelectFieldComponent';
import moment from 'moment';
// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  maxHeight: 600,
  margin: 'auto',
  marginTop: 50,
  overflowY: 'auto',
  borderRadius: 15,
  backgroundColor: 'white',
  justifyContent: 'center',
  padding: 32,
}));

const EditProfile = (props) => {
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const RegisterSchema = Yup.object().shape({
    // name: Yup.string().min(2, 'Too short!').max(50, 'Too long!').required('Title required'),
    phone: Yup.string()
      .matches(phoneRegExp, 'Phone number is not valid')
      .required('Phone number is required')
      .min(10, 'Too short!')
      .max(10, 'Too long!'),
    gender: Yup.string().required('Gender is required'),
  });

  const formik = useFormik({
    initialValues: {
      // name: props.name,
      gender: props.users.gender,
      phone: props.users.phone,
      birth_date: props.users.birth_date,
      headline: props.users.headline,
      location: props.users.location,
      leetcode_username: props.users.leetcode_username,
      about: props.users.about,
    },
    validationSchema: RegisterSchema,
    onSubmit: (values) => {
      axios
        .put(fetchUserProfileUrl(), values, {
          headers: getHeaders(),
        })
        .then((res) => {
          const updatedUser = res.data;
          props.updateUser(updatedUser);
          props.setErrors(false);
          props.setSuccess(true);
          props.setErrorMessage('');
        })
        .catch((e) => {
          const connection_error = JSON.parse(JSON.stringify(e));
          if (connection_error.message === 'Network Error') {
            props.setErrors(true);
            props.setSuccess(false);
            props.setErrorMessage("Can't connect to the servers right now");
          } else if (e.response && e.response.data) {
            const { data } = e.response;
            props.setErrors(true);
            props.setErrorMessage(data.message);
          }
        });
      props.handleModalClose();
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    handleChange,
  } = formik;

  const genderField = {
    name: 'gender',
    label: 'Gender',
    required: true,
    options: ['Male', 'Female', 'Other'],
  };

  return (
    <div>
      <FormikProvider value={formik}>
        <ContentStyle width={600}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
            }}
          >
            <Typography
              variant='h4'
              mb={5}
            >
              Edit Profile
            </Typography>
            <Button onClick={() => props.handleModalClose(false)}>
              <Icon
                style={{
                  float: 'right',
                  cursor: 'pointer',
                  // padding: '4px',
                  alignSelf: 'flex-end',
                }}
                icon={CloseOutlined}
                width='18'
                height='18'
              />
            </Button>
          </div>
          <Form
            autoComplete='off'
            noValidate
            onSubmit={handleSubmit}
          >
            <Stack spacing={2}>
              <Stack spacing={2}>
                <Box>
                  <SelectFieldComponent
                    field={genderField}
                    formData={formik.values}
                    handleChange={handleChange}
                    error={touched.gender && errors.gender}
                  />
                </Box>
                <Box>
                  <TextField
                    fullWidth
                    type='number'
                    label='Phone number'
                    {...getFieldProps('phone')}
                    error={Boolean(touched.phone && errors.phone)}
                    helperText={touched.phone && errors.phone}
                    required
                  />
                </Box>
                <Box>
                  <FormControl fullWidth>
                    <Typography
                      variant='caption'
                      gutterBottom
                      sx={{
                        fontSize: '15px',
                        marginBottom: '0px',
                        display: 'block',
                      }}
                    >
                      Birth date
                    </Typography>
                    <TextField
                      type='date'
                      id='birth_date'
                      InputProps={{
                        inputProps: {
                          max: moment().format('YYYY-MM-DD'),
                        },
                      }}
                      placeholder='Birth date'
                      {...getFieldProps('birth_date')}
                      error={Boolean(touched.birth_date && errors.birth_date)}
                    />
                  </FormControl>
                </Box>
                <Box>
                  <TextField
                    fullWidth
                    label='Headline'
                    {...getFieldProps('headline')}
                    error={Boolean(touched.headline && errors.headline)}
                  />
                </Box>
                <Box>
                  <TextField
                    fullWidth
                    label='Location'
                    {...getFieldProps('location')}
                    error={Boolean(touched.location && errors.location)}
                  />
                </Box>
                <Box>
                  <TextField
                    fullWidth
                    label='Leetcode username'
                    {...getFieldProps('leetcode_username')}
                    error={Boolean(touched.location && errors.location)}
                  />
                </Box>
                <Box>
                  <TextField
                    fullWidth
                    label='About'
                    multiline
                    rows={4}
                    {...getFieldProps('about')}
                    error={Boolean(touched.about && errors.about)}
                  />
                </Box>
              </Stack>
              <LoadingButton
                fullWidth
                size='large'
                type='submit'
                variant='contained'
                color='secondary'
                loading={isSubmitting}
              >
                Save
              </LoadingButton>
            </Stack>
          </Form>
        </ContentStyle>
      </FormikProvider>
    </div>
  );
};
export default EditProfile;

import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  Box,
  Button,
  Checkbox,
  InputAdornment,
  MenuItem,
  ListItemText,
  Select,
  TextField,
} from '@mui/material';

function Filters({
  allPrograms,
  onApplyFilters,
  onClearFilters,
  sortField,
  sortOrder,
}) {
  const [searchText, setSearchText] = useState('');
  const [programNames, setProgramNames] = useState([]);
  const [attendance, setAttendance] = useState([]);
  const [assignment, setAssignment] = useState([]);
  const [tgpLevel, setTgpLevel] = useState([]);

  useEffect(() => {
    if (sortField !== '') {
      handleApplyFilters();
    }
  }, [sortField, sortOrder]);

  const handleApplyFilters = () => {
    const queryParams = [];
    const sort_field = sortField.replace(/([A-Z])/g, '_$1').toLowerCase();
    queryParams.push(`sort_field=${sort_field}`);
    queryParams.push(`sort_order=${sortOrder}`);

    if (programNames.length > 0) {
      const programIds = [];
      Object.keys(allPrograms).forEach((programId) => {
        if (programNames.includes(allPrograms[programId].name)) {
          programIds.push(allPrograms[programId].programId);
        }
      });

      queryParams.push(`program_id=${programIds.join(',')}`);
    }

    if (attendance.length > 0)
      queryParams.push(
        `attendance=${attendance.map((a) => a.replace('%', '')).join(',')}`
      );
    if (assignment.length > 0)
      queryParams.push(`assignment=${assignment.join(',')}`);
    if (tgpLevel.length > 0)
      queryParams.push(
        `tgp_level=${tgpLevel.map((level) => `C${level}`).join(',')}`
      );
    if (searchText) queryParams.push(`search_value=${searchText}`);

    onApplyFilters(queryParams);
  };

  const clearAllFilters = () => {
    setSearchText('');
    setProgramNames([]);
    setAttendance([]);
    setAssignment([]);
    setTgpLevel([]);
    onClearFilters();
  };

  return (
    <Accordion
      defaultExpanded
      sx={{
        padding: '6px',
      }}
    >
      <AccordionDetails>
        <Box
          display='flex'
          flexWrap='wrap'
          gap={2}
          width='100%'
          paddingX={3}
        >
          <TextField
            style={{
              height: '40px',
              width: 'calc(50% - 8px)',
              fontSize: '14px',
            }}
            type='text'
            placeholder='Search by name, email, phone and user id...'
            value={searchText}
            size={'small'}
            onChange={(e) => setSearchText(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <i className='fas fa-search' />
                </InputAdornment>
              ),
            }}
          />
          <Select
            sx={{ width: 'calc(20% - 13px)', height: '40px' }}
            multiple
            value={programNames}
            onChange={(e) => setProgramNames(e.target.value)}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            renderValue={(selected) =>
              selected.length === 0 ? 'Select Programs' : selected.join(', ')
            }
          >
            {Object.keys(allPrograms)
              .map((p) => allPrograms[p])
              .map((program) => {
                return (
                  <MenuItem
                    key={program.programId}
                    value={program.name}
                  >
                    <Checkbox checked={programNames.includes(program.name)} />
                    <ListItemText primary={program.name} />
                  </MenuItem>
                );
              })}
          </Select>
          <Select
            sx={{ width: 'calc(20% - 13px)', height: '40px' }}
            multiple
            value={attendance}
            onChange={(e) => setAttendance(e.target.value)}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            renderValue={(selected) =>
              selected.length === 0 ? 'All Attendance' : selected.join(', ')
            }
          >
            <MenuItem value=''>All Attendance</MenuItem>
            {Array.from({ length: 10 }, (_, i) => (
              <MenuItem
                key={i}
                value={`${i * 10}-${(i + 1) * 10}%`}
              >
                <Checkbox
                  checked={
                    attendance.indexOf(`${i * 10}-${(i + 1) * 10}%`) > -1
                  }
                />
                <ListItemText primary={`${i * 10}-${(i + 1) * 10}%`} />
              </MenuItem>
            ))}
          </Select>
          <Select
            sx={{ width: 'calc(20% - 13px)', height: '40px' }}
            multiple
            value={assignment}
            onChange={(e) => setAssignment(e.target.value)}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            renderValue={(selected) =>
              selected.length === 0
                ? 'All Assignment'
                : selected.map((a) => `${a}%`).join(', ')
            }
          >
            <MenuItem value=''>All Assignment</MenuItem>
            {Array.from({ length: 10 }, (_, i) => (
              <MenuItem
                key={i}
                value={`${i * 10}-${(i + 1) * 10}`}
              >
                <Checkbox
                  checked={assignment.indexOf(`${i * 10}-${(i + 1) * 10}`) > -1}
                />
                <ListItemText primary={`${i * 10}-${(i + 1) * 10}%`} />
              </MenuItem>
            ))}
          </Select>
          <Select
            sx={{ width: 'calc(20% - 13px)', height: '40px' }}
            multiple
            value={tgpLevel}
            onChange={(e) => setTgpLevel(e.target.value)}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            renderValue={(selected) =>
              selected.length === 0
                ? 'All TGP Levels'
                : selected.map((val) => `C${val}`).join(', ')
            }
          >
            <MenuItem value=''>All TGP Levels</MenuItem>
            {Array.from({ length: 11 }, (_, i) => (
              <MenuItem
                key={i}
                value={`${i}`}
              >
                <Checkbox checked={tgpLevel.indexOf(`${i}`) > -1} />
                <ListItemText primary={`C${i}`} />
              </MenuItem>
            ))}
          </Select>
          <Button
            sx={{ paddingX: '30px', height: '40px' }}
            onClick={handleApplyFilters}
            variant='contained'
          >
            Apply Filters
          </Button>
          <Button
            sx={{ paddingX: '30px', height: '40px' }}
            onClick={clearAllFilters}
            variant='outlined'
          >
            Clear All Filters
          </Button>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

export default Filters;

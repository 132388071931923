// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Alert, Box, Chip, Typography } from '@material-ui/core';
import moment from 'moment';
import { formatDateLabelChatMessage } from '../../utils/formatTime';
import Message from './Message';
import { isStudent } from './ChatDashboard';

const styles = {
  individualChatContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundRepeat: 'repeat',
    backgroundSize: 'cover',
    borderRadius: '20px',
  },
  chatTitle: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #ccc',
    padding: '26px',
    marginLeft: '2px',
  },
  chatMessageDisplay: {
    width: '100%',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column-reverse',
    overflow: 'auto',
    padding: '16px',
  },
  userMessage: {
    alignSelf: 'flex-end',
  },
};

const MessageLayout = ({
  messages,
  setUpdateMessageStatus,
  setDeleteMessageStatus,
  searchType,
  chat,
  hasMoreMessages,
  fetchNextPage,
}) => {
  const [hoveredMessageIndex, setHoveredMessageIndex] = useState(null);
  const [absenceMessage, setAbsenceMessage] = useState('');

  useEffect(() => {
    updateTemporaryAbsenceMessage();
  }, []);

  const updateTemporaryAbsenceMessage = () => {
    const currentDate = moment();
    const currentHour = currentDate.hour();
    const currentDay = currentDate.day();

    if (currentDay === 2) {
      setAbsenceMessage(
        "Hello! We are currently unavailable, but don't worry, you can still message us. We will respond to you on Wednesday after 11 AM."
      );
    } else if (currentHour >= 22 || currentHour < 10) {
      setAbsenceMessage(
        `Hello! We are currently unavailable, but don't worry, you can still message us. We will get back to you ${
          currentHour <= 24 && currentHour >= 22 ? 'Tomorrow' : 'Today'
        } after 11 AM.`
      );
    } else {
      setAbsenceMessage('');
    }
  };

  const handleMessageHover = (index) => {
    setHoveredMessageIndex(index);
  };

  const linkify = (text) => {
    if (text) {
      const urlRegex = /(https?:\/\/[^\s]+)/g;

      return text.replace(urlRegex, (url) => {
        return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
      });
    }
    return text;
  };

  let currentGroup = '';
  let isNewMessagesSectionDisplayed = false;

  const MessageComponent = (index, message) => {
    const newMessage = message?.message
      ? message?.message.replace(/\\n/g, '\n')
      : message?.message;
    const messageWithLinks = linkify(newMessage);
    return (
      <Message
        key={`message-${index}`}
        message={{ ...message, message: messageWithLinks }}
        style={message.from_self ? styles.userMessage : null}
        onMouseEnter={() => handleMessageHover(index)}
        onMouseLeave={() => handleMessageHover(null)}
        isHovered={index === hoveredMessageIndex}
        setUpdateMessageStatus={setUpdateMessageStatus}
        setDeleteMessageStatus={setDeleteMessageStatus}
        searchType={searchType}
        chat={chat}
      />
    );
  };

  const clonedMessages = [
    {
      type: 'LOAD_MORE_MESSAGES',
    },
    ...messages,
  ];

  return (
    <>
      {clonedMessages?.map((message, index) => {
        const messageDate = new Date(message.created_at);
        const groupLabel = formatDateLabelChatMessage(messageDate);
        const isUnread = !message.is_read;

        if (index === 0) {
          if (!hasMoreMessages) {
            return <></>;
          }

          return (
            <Box
              display='flex'
              justifyContent='center'
              mb={1}
            >
              <Chip
                label='Load more'
                color='primary'
                sx={{
                  cursor: 'pointer',
                }}
                onClick={fetchNextPage}
              />
            </Box>
          );
        }

        if (isUnread && !isNewMessagesSectionDisplayed) {
          isNewMessagesSectionDisplayed = true;
          return (
            <>
              <Box
                borderBottom='1px solid #FF4842'
                mb={1}
              />
              <div key={`group-${index}`}>
                <Box
                  display='flex'
                  justifyContent='center'
                >
                  <Typography
                    variant='subtitle2'
                    color='error'
                  >
                    New Messages
                  </Typography>
                </Box>
              </div>
              {MessageComponent(index, message)}
            </>
          );
        }
        if (groupLabel !== currentGroup) {
          currentGroup = groupLabel;
          return (
            <div key={`group-${index}`}>
              <Box
                display='flex'
                justifyContent='center'
              >
                <Chip
                  label={groupLabel}
                  sx={{
                    my: 2,
                    backgroundColor: '#e8e8e8',
                    color: '#828282',
                  }}
                />
              </Box>
              {MessageComponent(index, message)}
            </div>
          );
        }
        return MessageComponent(index, message);
      })}
      {isStudent && absenceMessage !== '' && (
        <Box
          sx={{
            position: 'fixed',
            zIndex: 100,
            width: '87%',
            bottom: '80px',
          }}
        >
          <Alert
            severity='info'
            variant='filled'
            sx={{
              fontSize: '1rem',
              color: '#454545',
              backgroundColor: '#ffeecc',
              fontWeight: 450,
              padding: '0px 8px 0px 8px',
            }}
          >
            {absenceMessage}
          </Alert>
        </Box>
      )}
    </>
  );
};

export default MessageLayout;

// @ts-nocheck
import React from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  Button,
  Divider,
} from '@mui/material';
import { useFastrackPipeline } from 'src/layouts/EfPipelineContext/FastrackPipelineContex';

const HoldBack = ({ saveFlowData }) => {
  const { setScreenSource, trackButtonClick } = useFastrackPipeline();

  const handleContinue = () => {
    trackButtonClick({ source: 'screen_3l', eventName: 'continue' });
    saveFlowData({ screen: 'screen_3l' });
    setScreenSource('screen_3m');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: { xs: '1rem', sm: '1.5rem', md: '2rem', lg: '2.5rem' },
        margin: 'auto',
        textAlign: 'center',
      }}
    >
      <Typography
        variant='h4'
        sx={{
          fontWeight: 'bold',
          color: '#0078F0',
          marginBottom: { xs: '0.8rem', sm: '1.2rem', md: '1.5rem' },
          fontSize: { xs: '1.4rem', sm: '1.8rem', md: '2.5rem', lg: '3rem' },
          lineHeight: { xs: '1.2', sm: '1.3', md: '1.4', lg: '1.5' },
        }}
      >
        Are you really letting time hold you back?
      </Typography>

      <Typography
        variant='body1'
        sx={{
          textAlign: 'center',
          fontSize: { xs: '1rem', sm: '1.2rem', md: '1.5rem', lg: '1.6rem' },
          lineHeight: { xs: '1.3', sm: '1.4', md: '1.5' },
          marginBottom: { xs: '1rem', sm: '1.5rem', md: '2rem' },
        }}
      >
        It's not easy to balance it all, but those who are serious about their
        <br />
        careers make it happen.
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '1.25rem',
          padding: '1rem',
          borderRadius: '0.625rem',
          border: '1px solid #0078F0',
          width: { xs: '90%', sm: '80%', md: '60%' },
          backgroundColor: '#fff',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            display: 'inline-flex',
            marginRight: { xs: 0, sm: '1.25rem' },
            marginBottom: { xs: '1.25rem', sm: 0 },
          }}
        >
          <CircularProgress
            variant='determinate'
            value={87}
            size={80}
            sx={{
              color: '#0078F0',
              thickness: 4,
            }}
          />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography
              variant='h6'
              component='div'
              color='#0078F0'
              sx={{ fontWeight: 'bold' }}
            >
              87%
            </Typography>
          </Box>
        </Box>

        <Divider
          orientation='vertical'
          flexItem
          sx={{
            marginRight: { xs: 0, sm: '1.25rem' },
            height: {
              xs: '9rem',
              sm: '9rem',
              md: '9rem',
              lg: '7rem',
            },
            backgroundColor: '#e0e0e0',
            display: { xs: 'none', sm: 'block' },
          }}
        />

        <Typography
          variant='body2'
          sx={{
            fontSize: { xs: '0.9rem', sm: '1rem', md: '1.1rem' },
            color: '#000',
            lineHeight: '1.4',
            textAlign: { xs: 'center', sm: 'left' },
          }}
        >
          of our learners with similar schedules found time by making small
          changes – skipping unproductive screen time, planning ahead, and using
          our flexible tools.
        </Typography>
      </Box>

      <Button
        variant='outlined'
        sx={{
          marginTop: { xs: '1.2rem', md: '1.8rem', lg: '2.2rem' },
          borderRadius: '60%',
          padding: 0,
          paddingBottom: { xs: '5px', sm: '5px' },
          height: { xs: '3.5rem', sm: '3.5rem', md: '4rem', lg: '4.5rem' },
          width: { xs: '3.5rem', sm: '3.5rem', md: '4rem', lg: '4.5rem' },
          fontSize: { xs: '1.5rem', sm: '1.6rem', md: '1.7rem' },
          lineHeight: 0.5,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onClick={handleContinue}
      >
        →
      </Button>
    </Box>
  );
};

export default HoldBack;

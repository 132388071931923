// @ts-nocheck
import React, { useRef } from 'react';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import LoadingButton from '@mui/lab/LoadingButton';
import { Typography, Button, Grid, Box, Tooltip } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Countdown, { zeroPad } from 'react-countdown';
import Divider from '@mui/material/Divider';
import moment from 'moment';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import generatePDF from './generateOLPDF';
import { getUserMail } from '../../configs/auth';

const RootContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '10px',
});

export default function OfferLetterForm({
  leadFName,
  leadLName,
  setMessage,
  activeOLInfo,
  expiryDate,
  setActiveOLInfo,
  leadId,
  activeOLStatus,
  message,
}) {
  const offerLetterContent = useRef(null);

  const handleDownloadPDF = () => {
    const element = offerLetterContent.current;
    html2canvas(element, { scale: 5, type: 'pdf' }).then((canvas) => {
      const imgData = canvas.toDataURL('image/jpeg', 1.0);
      const pdf = jsPDF({
        orientation: 'portrait',
        unit: 'px',
        format: [210, 300],
      });
      pdf.addImage(imgData, 'JPEG', 0, 0, 210, 300);
      pdf.save('offer_letter.pdf');
    });
  };

  const CountdownRenderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      setActiveOLInfo(null);
      setMessage('This offer letter is no longer active.');
      return <></>;
    }
    return (
      <span>
        {zeroPad(days)}:{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
      </span>
    );
  };

  return (
    <>
      <RootContainer>
        <Grid
          justifyContent='center'
          alignItems='center'
          sx={{
            padding: '10px',
            width: '21cm',
          }}
        >
          <Box
            sx={{
              background: 'linear-gradient(45deg, #99ccff, #ffffcc, #99ccff)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 'fit-content',
              padding: '5px',
            }}
          >
            <Typography sx={{ fontSize: '25px' }}>
              The invitation expires in{' '}
            </Typography>
            <span
              style={{
                padding: '5px',
                fontSize: '25px',
              }}
            >
              <Countdown
                date={expiryDate}
                renderer={CountdownRenderer}
              />
            </span>
          </Box>
          <Box
            sx={{
              boxShadow: '20',
              borderRadius: '10px',
              padding: '50px 50px 0px 50px',
              border: '1px solid #f0f0f0',
              width: 'fit-content',
              marginTop: '20px',
              background: 'white',
            }}
            ref={offerLetterContent}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '30px',
              }}
            >
              <img
                src='/static/heycoach-logo.png'
                alt='heycoach logo'
                width='160px'
              />
              <Box>
                <Typography
                  align='right'
                  sx={{
                    color: 'black',
                    fontSize: '13px',
                  }}
                >
                  {leadFName} {leadLName}
                </Typography>
                <Typography
                  align='right'
                  sx={{
                    color: 'black',
                    fontSize: '13px',
                  }}
                >
                  Bangalore
                </Typography>
                <Typography
                  align='right'
                  sx={{
                    color: 'blue',
                    fontSize: '13px',
                  }}
                >
                  {getUserMail()}
                </Typography>
              </Box>
            </Box>
            <Divider />
            <br />
            <Typography
              textAlign='justify'
              sx={{ fontWeight: '800', fontSize: '13px' }}
            >
              <span style={{ fontWeight: 'normal' }}>Dear</span> {leadFName}{' '}
              {leadLName},
            </Typography>
            <br />
            <Typography
              textAlign='justify'
              fontSize='13px'
              fontWeight='800'
            >
              Subject: Offer of Confirmation for{' '}
              {activeOLInfo?.response?.program} Program at HeyCoach
            </Typography>
            <br />
            <Typography
              textAlign='justify'
              fontSize='13px'
            >
              We are thrilled to extend this offer of confirmation for your
              selection in the prestigious {activeOLInfo?.response?.program} at
              HeyCoach. Congratulations on being shortlisted among the cream of
              the crop – the exclusive 30 individuals chosen for this premium
              program.
            </Typography>
            <br />
            <Typography
              textAlign='justify'
              fontSize='13px'
            >
              At HeyCoach, we pride ourselves on selecting the brightest minds
              and nurturing their talents to reach new heights of success. Your
              outstanding qualifications, dedication, and potential have
              distinguished you as one of the elite candidates deserving of this
              opportunity.
            </Typography>
            <br />
            <Typography
              textAlign='justify'
              fontSize='13px'
              sx={{ fontWeight: '800' }}
            >
              Details of the Program:
            </Typography>
            <br />
            <Typography
              textAlign='justify'
              fontSize='13px'
            >
              <span style={{ fontWeight: '800' }}>Program Duration:</span> 16
              Weeks <br />
              <span style={{ fontWeight: '800' }}>Venue:</span> Online <br />
              <span style={{ fontWeight: '800' }}>Applicant ID:</span> HC2024
              {leadId} <br />
            </Typography>
            <br />
            <Typography
              textAlign='justify'
              fontSize='13px'
              sx={{ fontWeight: '800' }}
            >
              As a participant in the {activeOLInfo?.response?.program}, you
              will gain access to:
            </Typography>
            <br />
            <Typography
              textAlign='justify'
              fontSize='13px'
            >
              -Personalised coaching and mentorship from industry experts for
              the batch. <br />
              -Tailored curriculum designed to enhance your skills and
              knowledge. <br />
              -Exclusive networking opportunities with peers and professionals.{' '}
              <br />
              -Career development mentoring sessions and career growth guidance.{' '}
              <br />
              -Recognition and certification upon successful completion of the
              program.
              <br /> <br />
              Please review your offer letter having details of your
              participation in the {activeOLInfo?.response?.program} Program
              followed by completing the seat booking and registration process
              in the presence of a selection panel member for a smooth process
              and understanding your growth journey ahead. The timelines are the
              key priority metrics which define your intent and respect towards
              this offer letter hence this offer letter comes with a certain
              validity.
              <br />
              <br />
              We are excited to embark on this transformative journey with you
              and look forward to seeing your remarkable progress throughout the
              program. If you have any questions or require further assistance,
              please do not hesitate to contact us at{' '}
              <a
                href='mailto:team@heycoach.in'
                target='_top'
              >
                team@heycoach.in
              </a>{' '}
              or{' '}
              <a
                href='mailto:support@heycoach.co.in'
                target='_top'
              >
                support@heycoach.co.in
              </a>
              .
              <br />
              <br />
              Once again, congratulations on your selection, and welcome to the
              HeyCoach {activeOLInfo?.response?.program} Program! Please click
              here to proceed with registration and onboarding towards the{' '}
              {activeOLInfo?.response?.program} program.
            </Typography>
            <br />
            <br />
            <Typography
              textAlign='justify'
              fontSize='13px'
            >
              Best regards,
            </Typography>
            <img
              style={{ width: '120px', marginTop: '10px' }}
              src='/static/kamat-sign.png'
              alt='kamat sign'
            />
            <Typography
              textAlign='justify'
              fontSize='13px'
            >
              Aditya Kamat, <br />
              Co-Founder, HeyCoach.
            </Typography>
            <img
              style={{
                marginLeft: 'auto',
                marginRight: '-50px',
                marginTop: '-50px',
                borderRadius: '0px 0px 10px 0px',
                width: '380px',
              }}
              src='/static/offer_letter_design.png'
              alt='offer letter design'
            />
          </Box>

          {activeOLStatus === 'processing' && (
            <Grid
              xs={12}
              pt='1rem'
            >
              <Typography>
                If the payment was not completed last time, please use this
                link:{' '}
                <span>
                  <a href={`${activeOLInfo?.response?.payment_link}`}>Link</a>
                </span>
              </Typography>
            </Grid>
          )}
          <Grid
            sx={{
              padding: '5px',
              marginTop: '30px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              gap: '50px',
            }}
          >
            <Box>
              <Button
                variant='outlined'
                onClick={handleDownloadPDF}
              >
                Download offer letter
              </Button>
            </Box>

            <Box>
              <Tooltip title={activeOLStatus === 'processing' ? message : ''}>
                <span>
                  <LoadingButton
                    loading={activeOLStatus === 'processing'}
                    variant='contained'
                    href={activeOLInfo?.response?.payment_link}
                    sx={{
                      background: '#388e3c',
                      ':hover': {
                        background: '#388e3c',
                      },
                    }}
                    disabled={activeOLStatus === 'processing'}
                  >
                    Block your seat
                  </LoadingButton>
                </span>
              </Tooltip>
            </Box>
          </Grid>
        </Grid>
      </RootContainer>
    </>
  );
}

import React, { useState } from 'react';
import { Box } from '@material-ui/system';
import { useParams, useNavigate } from 'react-router-dom';
import { Stack, Typography, Button } from '@material-ui/core';
import { mindmapTopicDescriptions } from '../mindmapTopicDescriptions';
import SubTopicsList from '../Organisms/SubTopicsList';
import TopicProblems from '../Organisms/TopicProblems';
import './SubTopics.css';

const SubTopics = ({ selectedSubTopic, setSelectedSubTopic, problems }) => {
  const { topic } = useParams();
  const { subTopic } = useParams();
  const subTopics =
    topic in mindmapTopicDescriptions && mindmapTopicDescriptions[topic];
  const navigate = useNavigate();
  return (
    <Box>
      <Box
        display='flex'
        ml={4}
        mb={3}
      >
        <Typography
          style={{
            color: '#1976D2',
            cursor: 'pointer',
            textTransform: 'capitalize',
          }}
          onClick={() => {
            navigate(`signature-frameworks/mindmap`);
          }}
        >
          Mindmap &nbsp; &gt; &nbsp;
        </Typography>
        <Typography
          component='span'
          style={{
            color: subTopic && '#1976D2',
            cursor: 'pointer',
            textTransform: 'capitalize',
          }}
          onClick={() => {
            setSelectedSubTopic('');
            navigate(`signature-frameworks/mindmap/${topic}`);
          }}
        >
          {topic}
        </Typography>
        {subTopic && (
          <Typography style={{ textTransform: 'capitalize' }}>
            &nbsp; &gt; &nbsp; {subTopic}
          </Typography>
        )}
      </Box>
      {topic && subTopic ? (
        <TopicProblems
          selectedSubTopic={selectedSubTopic}
          subTopics={subTopics}
          problems={problems}
        />
      ) : (
        <>
          {topic && (
            <SubTopicsList
              topic={topic}
              subTopics={subTopics}
              setSelectedSubTopic={setSelectedSubTopic}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default SubTopics;

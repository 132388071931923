import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

const CompanyRoleDetails = ({
  setShowAllDetailsOfRole,
  selectedCompanyId,
  setSelectedCompanyId,
  allCompaniesData,
  roleTechStacks,
}) => {
  const handleCloseContactDetailsModal = async () => {
    setShowAllDetailsOfRole(false);
  };

  const formatDescription = (description) => {
    if (!description) return null;
    return description.split('\n').map((line, index) => (
      <>
        {line}
        <br />
      </>
    ));
  };

  return (
    <>
      <Box
        sx={{
          padding: '30px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            padding: '0 10px',
            gap: '20px',
            flexWrap: 'wrap',
            position: 'relative',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              boxShadow: 3,
              borderRadius: '5px',
              width: '630px',
              border: '1px solid #D2D2D2',
              backgroundColor: 'white',
              maxHeight: '600px',
              overflow: 'auto',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: '15px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  justifyContent: 'space-between',
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'start',
                    textAlign: 'center',
                    alignItems: 'end',
                    padding: '0 0 20px 0',
                    cursor: 'pointer',
                  }}
                >
                  <CloseRoundedIcon onClick={handleCloseContactDetailsModal} />
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-evenly',
                    gap: '5px',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'start',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '28px',
                        fontWeight: '600',
                        color: '#4F4F4F',
                      }}
                    >
                      {
                        allCompaniesData[selectedCompanyId]?.company_role
                          .role_title
                      }
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'start',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '22px',
                        fontWeight: '500',
                        color: '#4F4F4F',
                      }}
                    >
                      {
                        allCompaniesData[selectedCompanyId].company_role.company
                          .name
                      }
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'start',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '18px',
                        fontWeight: '400',
                        color: '#4F4F4F',
                      }}
                    >
                      {
                        allCompaniesData[selectedCompanyId]?.company_role
                          .location
                      }{' '}
                      |{' '}
                      {
                        allCompaniesData[selectedCompanyId]?.company_role
                          .job_type
                      }
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  padding: '5px 0',
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '20px',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '18px',
                    fontWeight: '900px',
                    color: '#1976D2',
                  }}
                >
                  <strong>
                    CTC{' '}
                    {allCompaniesData[selectedCompanyId]?.company_role.min_ctc}{' '}
                    -{' '}
                    {allCompaniesData[selectedCompanyId]?.company_role.max_ctc}{' '}
                    LPA
                  </strong>
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  padding: '0px 0 10px 0',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: '500',
                    color: '#4F4F4F',
                  }}
                >
                  Tech Stack
                </Typography>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '10px',
                  flexWrap: 'wrap',
                }}
                key={allCompaniesData[selectedCompanyId]?.id}
              >
                {roleTechStacks
                  .filter(
                    (techStack) =>
                      techStack.company_role_id ===
                      allCompaniesData[selectedCompanyId]?.id
                  )
                  .map((filteredTechStack, index) => (
                    <Typography
                      key={filteredTechStack.id}
                      sx={{
                        backgroundColor: '#D2E7FF',
                        padding: '7px',
                        fontSize: '13px',
                        borderRadius: '30px',
                        width: 'fit-content',
                      }}
                    >
                      {filteredTechStack.tech_stack.name}
                    </Typography>
                  ))}
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                textAlign: 'start',
                alignItems: 'start',
                padding: '0 0 0 20px',
              }}
            >
              <Typography
                sx={{
                  fontSize: '17px',
                }}
              >
                {formatDescription(
                  allCompaniesData[selectedCompanyId]?.company_role?.description
                )}
              </Typography>
            </Box>

            <Box
              sx={{
                padding: '10px 0 0 20px',
                display: 'flex',
                flexDirection: 'row',
                gap: '20px',
              }}
            >
              <Typography
                sx={{
                  fontSize: '18px',
                  fontWeight: '900px',
                  color: '#1976D2',
                }}
              >
                <strong>POC Details:</strong>
              </Typography>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: '0 0 10px 20px',
                justifyContent: 'start',
                borderRadius: '0 0 20px 10px',
                gap: '5px',
              }}
            >
              <Typography>
                {allCompaniesData[selectedCompanyId]?.company_role.poc_name ||
                  'Not Available'}
              </Typography>
              <Typography>
                {allCompaniesData[selectedCompanyId]?.company_role.poc_email ||
                  'Not Available'}
              </Typography>
              <Typography>
                {allCompaniesData[selectedCompanyId]?.company_role
                  .poc_contact || 'Not Available'}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CompanyRoleDetails;

// @ts-nocheck
import {
  Typography,
  Stack,
  Box,
  Button,
  useTheme,
  useMediaQuery,
  Slider,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import EastIcon from '@mui/icons-material/East';
import { useFastrackPipeline } from 'src/layouts/EfPipelineContext/FastrackPipelineContex';
import { useState } from 'react';
import { companyData } from '..';

const PrettoSlider = styled(Slider)({
  color: '#1976d2',
  height: 8,
  '& .MuiSlider-markLabel': {
    fontSize: 23,
    '@media (max-width: 600px)': {
      fontSize: 18,
    },
  },
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&::before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#1976d2',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&::before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});

const sliderMarks = [
  {
    value: 0,
    label: '0 LPA',
  },
  {
    value: 100,
    label: '1 Cr',
  },
];

const COURSE_FEE_IN_LPA = 1.58;

const SelectYourCTC = ({ flowData, setFlowData, saveFlowData }) => {
  const { screenSource, setScreenSource, trackButtonClick } =
    useFastrackPipeline();
  const [shouldAllowNext, setShouldAllowNext] = useState(false);
  const [ctc, setCTC] = useState(0);
  const theme = useTheme();
  const isBelow800 = useMediaQuery('(max-width: 800px)');

  const handleChange = (e, value) => {
    setFlowData((prev) => ({ ...prev, ctc: value }));
    setCTC(value);
    setShouldAllowNext(true);
  };

  const handleNext = () => {
    trackButtonClick({ source: 'screen_7', eventName: 'next' });
    saveFlowData({ screen: 'screen_7', response: { ctc } });
    setScreenSource('screen_8');
  };

  const getAverageSalary = () => {
    let min = 0,
      max = 0,
      total = 0;
    const { companies } = flowData;

    companies.forEach((company) => {
      if (company.roles?.length === 0) {
        return;
      }

      const roles = companyData[company.name].roles.filter((role) =>
        company.roles.includes(role.name)
      );
      total += roles.length;

      roles.forEach((role) => {
        min += role.minSalary;
        max += role.maxSalary;
      });
    });

    if (total === 0) {
      return {
        min: 60,
        max: 70,
      };
    }

    return {
      min: Math.floor(min / total / 100000),
      max: Math.floor(max / total / 100000),
    };
  };

  const getPercentageGain = () => {
    const { min, max } = getAverageSalary();
    return {
      min: Math.floor(min / COURSE_FEE_IN_LPA),
      max: Math.floor(max / COURSE_FEE_IN_LPA),
    };
  };

  return (
    <Stack
      spacing={5}
      alignItems='center'
      textAlign='center'
      width='inherit'
    >
      <Stack
        width={{
          xs: '100%',
          sm: '100%',
          md: '90%',
          lg: '60%',
          xl: '60%',
        }}
        textAlign='center'
        alignItems='center'
      >
        <Typography
          variant='body1'
          component='p'
          sx={{
            color: '#1976d2',
            fontFamily: 'Inter',
            fontWeight: 600,
            fontSize: {
              xs: '0.9rem',
              sm: '1.2rem',
              md: '1.3rem',
              lg: '1.3rem',
            },
            width: 'fit-content',
            textAlign: 'center',
            textWrap: 'stable',
          }}
        >
          Great. The average salary for the role that you are targeting is in
          the range of{' '}
          <span
            style={{
              textDecoration: 'underline dashed #1976d2 2.25px',
              textUnderlineOffset: '5px',
              fontFamily: 'Inter',
            }}
          >
            ₹{Object.values(getAverageSalary()).join('-')}
          </span>{' '}
          LPA
        </Typography>
      </Stack>

      <Typography
        variant='body1'
        component='p'
        sx={{
          fontFamily: 'Inter',
          fontSize: {
            xs: '0.9rem',
            sm: '1.2rem',
            md: '1.3rem',
            lg: '1.3rem',
          },
          width: 'fit-content',
          textAlign: 'center',
          textWrap: 'pretty',
        }}
      >
        That’s approximately{' '}
        <span
          style={{
            textDecoration: 'underline dashed #1976d2 2.25px',
            textUnderlineOffset: '5px',
            fontFamily: 'Inter',
          }}
        >
          <b>{Object.values(getPercentageGain()).join('-')} x</b>
        </span>{' '}
        gain on your{' '}
        <b>initial investment of ₹{COURSE_FEE_IN_LPA}L course fees</b>
      </Typography>

      <Typography
        variant='body1'
        component='p'
        sx={{
          fontFamily: 'Inter',
          fontSize: {
            xs: '0.9rem',
            sm: '1.2rem',
            md: '1.3rem',
            lg: '1.3rem',
          },
          width: 'fit-content',
          textAlign: 'center',
          textWrap: 'stable',
        }}
      >
        <b>
          Now lets visualise the % gains you could achieve after investing in
          this course.
        </b>
      </Typography>

      <Typography
        variant='body1'
        component='p'
        sx={{
          fontFamily: 'Inter',
          fontSize: {
            xs: '0.9rem',
            sm: '1.2rem',
            md: '1.3rem',
            lg: '1.3rem',
          },
          width: 'fit-content',
          textAlign: 'center',
          textWrap: 'stable',
        }}
      >
        Please select your current CTC salary in LPA
      </Typography>

      <Stack
        width={{
          xs: '100%',
          sm: '100%',
          md: '90%',
          lg: '70%',
          xl: '50%',
        }}
        textAlign='center'
        alignItems='center'
      >
        <PrettoSlider
          valueLabelDisplay='auto'
          marks={sliderMarks}
          min={0}
          max={100}
          onChangeCommitted={handleChange}
        />
      </Stack>

      <Button
        disableElevation
        endIcon={<EastIcon />}
        variant='contained'
        sx={{
          fontFamily: 'Inter',
          textTransform: 'none',
          padding: '0.6rem 1.5rem',
          background: '#1976d2',
          '&:hover': {
            background: '#1976d2',
          },
        }}
        disabled={!shouldAllowNext}
        onClick={handleNext}
      >
        Continue
      </Button>
    </Stack>
  );
};

export default SelectYourCTC;

import React from 'react';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { onboardingTopBarContent } from 'src/components/AtomicComponents/StudentNavbarData';
import DashboardNavbarV2 from 'src/layouts/shared/DashboardNavbarV2';
import '../../App.css';
import DividerComponent from 'src/components/AtomicComponents/DividerComponent';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { theme } from 'twin.macro';
import OnboardingDashboardNavbar from './OnboardingDashboardNavbar';

const OnboardingTabRender = ({ value, subTabValue }) => {
  const MainStyle = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(3),
    paddingLeft: theme.spacing(1),
    height: '100vh',
    overflow: 'auto',
  }));
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          padding: '0 20px',
          marginTop: '40px',
          gap: 2,
          position: 'relative',
        }}
        className='tab-render'
      >
        <OnboardingDashboardNavbar />
        <DividerComponent />
        <MainStyle>
          {onboardingTopBarContent[value].children.length > 0 ? (
            onboardingTopBarContent[value].children[subTabValue].component
          ) : (
            <Outlet />
          )}
        </MainStyle>
      </Box>
    </>
  );
};

export default OnboardingTabRender;

// @ts-nocheck
// material
import { Box, Stack, Typography } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { useMediaQuery } from '@mui/material';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

// components
import React, { useState, useRef } from 'react';
import { useTheme } from '@material-ui/styles';
import AuthSocial from '../components/authentication/AuthSocial';
import HCLPAnimation from '../components/loginAnimation/HCLPAnimation';
import LoginCarousel from '../components/LoginCarousel';
import Page from '../components/Page';
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'rgba(229, 245, 253, 1)',
  width: '100%',
  minHeight: '100vh',
}));

// ----------------------------------------------------------------------

export default function Login() {
  const theme = useTheme();
  const [isAnimationComplete, setIsAnimationComplete] = useState(false);
  const carouselRef = useRef(null);

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('custom'));

  if (!isAnimationComplete) {
    return <HCLPAnimation setIsAnimationComplete={setIsAnimationComplete} />;
  }

  return (
    <RootStyle>
      <Stack
        direction='row'
        spacing={0}
        width='80%'
        sx={{
          '@media (max-width: 1000px)': {
            width: '100%',

            flexDirection: 'column',
            gap: 2,
          },
        }}
      >
        {isSmallScreen && (
          <Box
            sx={{
              marginTop: '2rem',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'center',
              alignItems: 'center',
            }}
          >
            <img
              src='/static/heycoach-logo.png'
              alt='Hey Coach'
              width='150'
            />
          </Box>
        )}
        <Stack
          direction='column'
          sx={{
            backgroundColor: 'white',
            '@media (max-width: 1000px)': {
              borderRadius: '30px',
              width: '80%',
              marginLeft: '10%',
            },
            width: '143%',
            borderTopLeftRadius: '30px',
            borderBottomLeftRadius: '30px',
          }}
        >
          <Stack
            spacing={2}
            sx={{
              height: '70%',
              [theme.breakpoints.up('md')]: {
                height: '60%',
              },
              width: '100%',
              padding: '1.5rem 1.5rem 0.5rem',
            }}
          >
            <img
              src='/static/heycoach-logo.png'
              alt='Hey Coach'
              width='150'
              style={{
                textAlign: 'left',
                display: isSmallScreen ? 'none' : 'block',
              }}
            />
            <Typography
              variant='h5'
              component='p'
              sx={{
                fontWeight: 540,
                '@media (max-width: 1000px)': { textAlign: 'center' },
              }}
            >
              Welcome to the HeyCoach Learning Portal
            </Typography>
            <Typography
              variant='body1'
              component='p'
              align='justify'
            >
              We pave the way for aspiring tech enthusiasts to embark on a
              transformative journey towards a successful career in technology.
              Our comprehensive program is designed to empower learners with the
              skills and knowledge needed to thrive in the dynamic world of
              tech.
            </Typography>
          </Stack>
          <Stack
            direction='column'
            sx={{
              height: '10%',
              [theme.breakpoints.up('md')]: {
                height: '40%',
              },
              width: '100%',
              padding: '1rem',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ '@media (min-width: 1000px)': { paddingTop: '45px' } }}>
              <AuthSocial />
            </Box>
            <Box paddingTop='12px'>
              <a
                href='https://heycoach.in/super30'
                target='_blank'
                rel='noreferrer'
                style={{ textDecoration: 'none' }}
              >
                heycoach.in
              </a>
            </Box>
          </Stack>
        </Stack>

        <Box>
          <LoginCarousel
            theme={theme}
            carouselRef={carouselRef}
          />
        </Box>
      </Stack>
    </RootStyle>
  );
}

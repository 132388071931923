import React, { useEffect, useState } from 'react';
import GlobalSearch from '../components/TicketingSystem/GlobalSearch';
import ErrorNotifier from 'src/components/ToastNotifications/ErrorNotifier';

const TicketingSystem = () => {
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const columns = [
    {
      Header: 'Ticket ID',
      accessor: 'id',
    },
    {
      Header: 'Name',
      accessor: 'learner.id_name',
    },
    {
      Header: 'Category',
      accessor: 'category',
    },
    {
      Header: 'Mobile Number',
      accessor: 'learner.mobile_number',
    },
    {
      Header: 'Email',
      accessor: 'learner.email',
    },
    {
      Header: 'Created at',
      accessor: 'created_at',
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'Program Name',
      accessor: 'program.name',
    },
    {
      Header: 'Assignee',
      accessor: 'assignee.name',
    },
  ];

  return (
    <>
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      <GlobalSearch
        columns={columns}
        setErrorMessage={setErrorMessage}
        setHasError={setHasError}
      />
    </>
  );
};

export default TicketingSystem;

// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Container, Paper } from '@mui/material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { useFastrackPipeline } from 'src/layouts/EfPipelineContext/FastrackPipelineContex';
import OfferLetterModal from '../OfferLetterModal';
import JoinList from '../../../assets/waitlist.svg';

const JoinWaitList = () => {
  const { trackButtonClick, offerLetter } = useFastrackPipeline();
  const [showOfferLetter, setShowOfferLetter] = useState(false);
  const { link } = offerLetter;
  const expiryDate = new Date(offerLetter?.expires_at).getTime();
  const [time, setTime] = useState(expiryDate - new Date().getTime());

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(expiryDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [expiryDate]);

  const releaseOfferLetter = () => {
    setShowOfferLetter(true);
    trackButtonClick({
      source: 'join_waitlist',
      eventName: 'release_offer_letter',
    });
  };
  return (
    <>
      <OfferLetterModal
        {...{
          showOfferLetter,
          setShowOfferLetter,
          time,
          link,
        }}
      />
      <Box
        sx={{
          minHeight: '80vh',
          backgroundColor: '#e3f2fd',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Container
          sx={{
            height: '100%',
            minHeight: '70vh',
            textAlign: 'center',
            mt: 10,
            backgroundColor: 'white',
            p: 5,
            maxWidth: '80%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: '12px',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant='h2'
            fontWeight='bold'
            sx={{ mb: 2, color: '#1976d2', letterSpacing: 0.5 }}
          >
            You're now on the waitlist!
          </Typography>

          <img
            src={JoinList}
            alt='Join Waitlist Illustration'
            style={{
              width: '400px',
              margin: '20px 0',
            }}
          />

          <Typography
            variant='body1'
            color='textSecondary'
            sx={{ mb: 6, color: '#666' }}
          >
            Please get in touch with your counselor for further steps.
          </Typography>

          <Paper
            elevation={3}
            width='70%'
            sx={{
              p: 3,
              borderRadius: '16px',
              border: '0.2px solid grey',
              width: '80%',
              boxShadow: 'none',
            }}
          >
            <Typography
              variant='body1'
              fontWeight='600'
              sx={{ mb: 1 }}
            >
              Only 5% of candidates make it through the fast-track process!
            </Typography>
            <Typography
              variant='body1'
              sx={{ mb: 2 }}
            >
              If you miss this opportunity, you’ll need to undergo a more
              rigorous screening interview with a lower selection rate.
            </Typography>

            <Button
              endIcon={<ArrowRightAltIcon />}
              sx={{
                fontFamily: 'Inter',
                fontWeight: 500,
                backgroundColor: '#009AEF',
                backgroundImage: 'linear-gradient(to right, #009AEF, #FBC704)',
                color: 'white',
                padding: { xs: '0.2rem', sm: '0.3rem', md: '0.7rem' },
                width: {
                  xs: '100%',
                  sm: '60%',
                  md: '60%',
                  lg: '30%',
                },
                alignSelf: 'center',
                borderRadius: '0.375rem',
                mt: 2,
                fontSize: {
                  xs: '0.5rem',
                  sm: '0.7rem',
                  md: '0.9rem',
                },
              }}
              onClick={releaseOfferLetter}
            >
              Release my offer letter
            </Button>
          </Paper>
        </Container>
      </Box>
    </>
  );
};

export default JoinWaitList;

import React, { useState } from 'react';
import moment from 'moment';
import { Typography, Box, Link, Modal } from '@mui/material';
import { Stack } from '@material-ui/core';
import TicketDetailView from '../../../TicketingSystem/Modules/TicketDetailView';

export default function TicketList({ tickets }) {
  // Sort tickets by created_at in descending order (most recent first)
  const sortedTickets = [...tickets].sort((a, b) =>
    moment(b.created_at).diff(moment(a.created_at))
  );

  const [detailView, setDetailView] = useState(false);

  const handleClick = () => {
    setDetailView(true);
  };

  const handleClose = () => {
    setDetailView(false);
  };

  return sortedTickets.map((ticket) => {
    const timestamp = moment(ticket.created_at).format(
      'dddd, MMMM Do YYYY, h:mm:ss a'
    );
    return (
      <Stack
        key={ticket.id}
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        sx={{ marginBottom: '16px', width: '100%' }}
      >
        {detailView && (
          <Modal
            open={detailView}
            onClose={handleClose}
            style={{ display: 'flex', justifyContent: 'right', zIndex: 99999 }}
          >
            <TicketDetailView
              handleClose={handleClose}
              ticket={ticket}
            />
          </Modal>
        )}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              backgroundColor: '#EDBAFF',
              padding: '10px 16px',
              borderRadius: '10px',
              display: 'flex',
              width: '400px',
              alignItems: 'center',
              boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
            }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/ticket.png`}
              alt='Ticket Icon'
              style={{ marginRight: '8px', width: '16px', height: '16px' }}
            />

            <Typography
              sx={{ color: '#4a4a4a' }}
              variant='body2'
            >
              {ticket['learner']['id_name']} has raised a ticket
            </Typography>
          </Box>

          <Link
            style={{
              cursor: 'pointer',
              color: '#3a86ff', // Light blue color
              fontSize: '14px',
              marginLeft: '16px', // Spacing between the message and the link
              whiteSpace: 'nowrap', // Prevent wrapping of the link text
            }}
            onClick={handleClick}
          >
            View Ticket
          </Link>
        </Box>

        {/* Timestamp on the far right */}
        <Typography
          variant='body2'
          color='#9e9e9e'
          sx={{ marginLeft: '16px' }}
        >
          {timestamp}
        </Typography>
      </Stack>
    );
  });
}

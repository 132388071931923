// @ts-nocheck
import { Grid } from '@material-ui/core';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@material-ui/styles';
import { getUserType } from '../../configs/auth';
import {
  fetchTopicsUrl,
  fetchUserEnrollmentsUrl,
  getHeaders,
} from '../../apis/urls';
import {
  setPrograms,
  setCurrentProgram,
  unsetCurrentProgram,
  setShouldShowNewStudentLayout,
} from '../redux-store/slices/programSlice';
import { setTopics } from '../redux-store/slices/topicSlice';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';
import { unsetSessions } from '../redux-store/slices/sessionSlice';
import { setUserEnrollmentsLevels } from '../redux-store/slices/userSlice';
import { shouldShowNewStudentLayout, slugify } from '../utils/common';

const NO_PROGRAM_ENROLLMENT_FOUND =
  'You are currently not enrolled in any program.';

const styles = makeStyles({
  card: {
    transition: 'all 0.5s ease-in-out',
    '&:hover': {
      transition: 'all 0.3s ease-in-out',
      transform: 'scale(1.1)',
    },
    cursor: 'pointer',
  },
});

export default function Courses() {
  const dispatch = useDispatch();
  const programs = useSelector((state) => state.programs.programs);

  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const userType = getUserType();
  const navigate = useNavigate();
  const classes = styles();

  useEffect(() => {
    // reset current program in redux store

    dispatch(unsetCurrentProgram());

    // reset sessions in redux store
    dispatch(unsetSessions());

    getPrograms();
    getTopics();

    return () => {};
  }, []);

  const autoSelectProgram = (programs) => {
    // if learner is enrolled in single program then redirect it to that program instead of showing list of programs
    let allowedProgramCount = 0;
    let programSlug = '';
    let programId = null;

    Object.keys(programs)?.forEach((id, index) => {
      if (programs[id].program_access === 'allowed') {
        allowedProgramCount += 1;
        programSlug = slugify(programs[id].name);
        programId = programs[id].id;
      }
    });

    if (userType === 'student' && allowedProgramCount === 1) {
      dispatch(setCurrentProgram(programId));
      navigate(`/student/${programSlug}/overview`);
    }
  };

  function getPrograms() {
    axios
      .get(fetchUserEnrollmentsUrl(), {
        headers: getHeaders(),
      })
      .then((res) => {
        if (!res.data || res.data.length === 0) {
          return;
        }
        const p = res.data.map((e) => {
          return {
            ...e.program,
            program_access: e.program_access,
          };
        });
        dispatch(setPrograms(p));
        autoSelectProgram(p);
      })
      .catch((e) => {
        console.log(e);
        setHasError(true);
        setErrorMessage(
          e.response?.data?.message ||
            'An error occured while fetching the programs'
        );
      });
  }

  function getTopics() {
    axios
      .get(fetchTopicsUrl(), {
        headers: getHeaders(),
      })
      .then((res) => {
        dispatch(setTopics(res.data ?? []));
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(err.response?.data?.message || err.message);
      });
  }

  const handleClick = (event, slugifiedName, pid) => {
    if (programs[pid]?.programAccess === 'allowed') {
      dispatch(setCurrentProgram(pid));
      dispatch(setShouldShowNewStudentLayout(shouldShowNewStudentLayout(pid)));
      navigate(`/student/${slugifiedName}/overview`);
    }
  };

  function renderPrograms() {
    const res = [];
    Object.keys(programs)?.forEach((id, index) => {
      if (programs[id].programAccess === 'allowed') {
        res.push(
          createProgramCard(programs[id].programSlug, programs[id].name, id)
        );
      }
    });
    if (res.length === 0) {
      res.push(NO_PROGRAM_ENROLLMENT_FOUND);
    }
    return res;
  }

  function subText(message) {
    return (
      <Typography
        m={1}
        sx={{
          border: '2px solid',
          position: 'absolute',
          bottom: '1%',
          left: '1%',
          right: '5px',
          color: 'orangered',
          padding: '0px 10px',
          borderRadius: '10px',
          fontWeight: 400,
        }}
      >
        {message}
      </Typography>
    );
  }

  function createProgramCard(slugifiedName, programName, pid) {
    return (
      <Card
        sx={{
          minWidth: 280,
          minHeight: 170,
          m: 2,
        }}
        className={classes.card}
      >
        <CardContent
          onClick={(event) => handleClick(event, slugifiedName, pid)}
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Typography
            sx={{ pt: 4, pb: 4 }}
            fontSize='medium'
            textAlign='center'
            gutterBottom
          >
            {programName}
            <br />
            {(() => {
              switch (programs[pid]?.programAccess) {
                case 'disabled':
                  return subText('Your access has been revoked.');
                case 'expired':
                  return subText('Program has expired.');
                default:
                  return '';
              }
            })()}
          </Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Grid
      container
      spacing={0}
    >
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      {renderPrograms()}
    </Grid>
  );
}

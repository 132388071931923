import React, { useState } from 'react';
import { Box, Button, Tabs, Tab, Typography } from '@material-ui/core';
import EastIcon from '@mui/icons-material/East';
import { NewBlogssResourcesContent } from './ResourcesContent';

const TabsComponent = () => {
  const [value, setValue] = useState(0); // State to track the active tab

  const handleChange = (event, newValue) => {
    setValue(newValue); // Update the active tab when clicked
  };

  const handleBookClick = (link) => {
    window.open(link, '_blank');
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap={5}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        sx={{
          '& .MuiTabs-flexContainer': {
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
            gap: '12px',
          },
          '& .MuiTabs-indicator': { height: '0' },
          '& .Mui-selected': {
            backgroundColor: '#1960f5',
            color: '#ffffff !important',
          },
          background: '#ffffff',
          borderRadius: '32px',
          padding: '5px',
          minHeight: '0',
        }}
        aria-label='Tab navigation'
      >
        {NewBlogssResourcesContent.map((val) => (
          <Tab
            sx={{
              borderRadius: '32px',
              padding: '10px',
              minHeight: '0',
              background: '#ffffff',
              border: '1px solid black',
              color: '#000000',
            }}
            label={val.topicName}
          />
        ))}
      </Tabs>

      {NewBlogssResourcesContent.map((val, index) => (
        <Box
          key={index}
          sx={{
            display: value === index ? 'flex' : 'none',
            flexWrap: 'wrap',
            gap: '30px',
            paddingX: '100px',
          }}
        >
          {val.recources.map((content, index) => (
            <Box
              key={index}
              sx={{
                borderRadius: '10px',
                border: '1px solid black',
                width: 'calc((100% - 60px)/3)',
                padding: '18px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '20px',
              }}
            >
              <img
                src={content.image}
                alt='book_image'
                style={{
                  width: '100%',
                  height: '185px',
                  borderRadius: '12px',
                  border: '1px solid #bababa',
                }}
              />

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '18px',
                    lineHeight: '22px',
                    fontWeight: '700',
                    textAlign: 'center',
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 1,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {content.name}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: '500',
                    textAlign: 'center',
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 3,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {content.description}
                </Typography>
                <Box
                  display='flex'
                  flexWrap='wrap'
                  gap={1}
                >
                  {content.tags.map((tag, index) => (
                    <Box
                      key={index}
                      borderRadius={2}
                      padding='4px 8px'
                      fontSize='10px'
                      sx={{
                        background: '#ABE1FF',
                      }}
                    >
                      {tag}
                    </Box>
                  ))}
                </Box>

                <Button
                  variant='outlined'
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '8px',
                    width: '100%',
                    border: '1px solid black',
                    color: 'black',
                    marginTop: '10px',
                  }}
                  onClick={() => handleBookClick(content.link)}
                >
                  <Typography>Read More</Typography>
                  <EastIcon />
                </Button>
              </Box>
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );
};

export default TabsComponent;

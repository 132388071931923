import React, { useEffect, useState } from 'react';
import { Box, Chip, Grid, Typography, Divider } from '@material-ui/core';
import ArticleIcon from '@mui/icons-material/Article';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LockIcon from '@mui/icons-material/Lock';
import { useSelector } from 'react-redux';
import { SessionListItem } from './SessionListItem';
import LoadingScreen from '../../pages/LoadingScreen';
import NewSessionTestTable from '../practice/NewSessionTests/NewSessionTestTable';
import DetailedTest from '../practice/CodingTest/DetailedTest/DetailedTest';
import sessionIcon from './images/Video_library_1.svg';
import sessionTestIcon from './images/session_tests.svg';
import codingAssignmentIcon from './images/coding_assignments.svg';
import SessionDescriptions from './SessionDescriptions';
import ModuleDescriptions from './ModuleDescriptions';

export const SESSION_TESTS_MAPPING = {
  3: 1,
  8: 2,
  12: 3,
  16: 4,
  21: 5,
  27: 6,
  31: 7,
  36: 8,
  40: 9,
  61: 10,
  62: 11,
};

export const SessionDetails = ({
  setHideModules,
  selectedSessionTopic,
  groupToSessions,
}) => {
  const sessions = groupToSessions[selectedSessionTopic]?.sessions;
  const sessionTests = useSelector((state) => state.sessions.session_tests);
  const [selectedTest, setSelectedTest] = useState({});
  const codingAssignments =
    groupToSessions[selectedSessionTopic]?.coding_assignments;
  const preReadMaterial = useSelector((state) => state.sessions.pre_reads);
  const recordedSessions = useSelector(
    (state) => state.sessions.session_recordings
  )?.map((session_recording) => session_recording.session_id);
  const loading = useSelector((state) => state.sessions.loading);
  const [preReadsCount, setPreReadsCount] = useState([]);
  const [assignmentsCount, setAssignmentsCount] = useState([]);
  const [sessionsData, setSessionsData] = useState([]);
  const sessionSelectedId = localStorage.getItem('sessionSelectedId');

  let sessionTestCount;

  if (sessions !== undefined) {
    sessionTestCount = sessions.filter((value) => {
      return SESSION_TESTS_MAPPING[value.index + 1];
    }).length;
  }

  const isCompleted = (details, type) => {
    return (
      (type === 'assignment' && details?.has_completed) ||
      (type === 'session' &&
        ['conducted', 'verified', 'payment_processed'].includes(
          details.status
        )) ||
      (type === 'preRead' && details?.completed)
    );
  };

  useEffect(() => {
    const pre_reads_count = new Array(sessions?.length).fill(0);
    const assignments_count = new Array(sessions?.length).fill(0);

    const data = [];
    sessions?.forEach((session, index) => {
      const sessionData = [];
      preReadMaterial.forEach((preRead, ind) => {
        if (preRead.session_id === session.id) {
          sessionData.push({
            index: ind,
            type: 'preRead',
            details: preRead,
            text: `${preRead.title}`,
            completed: isCompleted(preRead, 'preRead'),
          });
          pre_reads_count[index] += 1;
        }
      });

      sessionData.push({
        type: 'session',
        details: session,
        text: `${session.title}`,
        completed: isCompleted(session, 'session'),
      });

      codingAssignments.forEach((assignment, ind) => {
        if (assignment.session_id === session.id) {
          sessionData.push({
            index: ind,
            type: 'assignment',
            details: assignment,
            text: assignment?.problem_name ? assignment?.problem_name : '',
            completed: isCompleted(assignment, 'assignment'),
          });
          assignments_count[index] += 1;
        }
      });

      data[index] = sessionData;
      setAssignmentsCount(assignments_count);
      setPreReadsCount(pre_reads_count);
    });

    setSessionsData(data);
  }, [groupToSessions, selectedSessionTopic]);

  const showSessionTestIcon = (isLocked, test) => {
    let icon;
    if (isLocked) {
      icon = <LockIcon style={{ fontSize: '22px', marginRight: '10px' }} />;
    } else if (test.hasCleared) {
      icon = (
        <CheckCircleIcon
          style={{
            color: '#4bc944',
            fontSize: '22px',
            marginRight: '10px',
          }}
        />
      );
    } else {
      icon = (
        <img
          src={sessionTestIcon}
          style={{ width: '24px', marginRight: '8px' }}
          alt='sessionTestIcon'
        />
      );
    }
    return icon;
  };

  const showSessionTest = (session) => {
    if (
      session.details.index !== undefined &&
      SESSION_TESTS_MAPPING[session.details.index + 1] !== undefined
    ) {
      const test =
        sessionTests[SESSION_TESTS_MAPPING[session.details.index + 1] - 1];
      const isLocked = test === undefined;
      return (
        <Accordion
          sx={{
            marginTop: '5px',
            boxShadow: 'rgba(0, 0, 0, 0.05) 0px 1px 2px 0px',
            border: '2px solid #e8e8e8',
            borderRadius: '8px',
          }}
          disableGutters={isLocked}
        >
          <AccordionSummary
            expandIcon={!isLocked && <ExpandMoreIcon />}
            aria-controls='panel2-content'
            id='panel2-header'
            sx={{ height: '48px' }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              {showSessionTestIcon(isLocked, test)}
              <Typography>
                {' '}
                Session test -{' '}
                {SESSION_TESTS_MAPPING[session.details.index + 1]}
              </Typography>
            </Box>
            <Chip
              label='Session test'
              variant='outlined'
              sx={{
                fontSize: '12px',
                fontWeight: '800',
                padding: '0px 8px',
                backgroundColor: 'transparent',
                color: '#ff8C02',
                border: '1px solid #ff8C02',
                marginRight: isLocked ? '3em' : '1em',
              }}
            />
          </AccordionSummary>
          {!isLocked && (
            <AccordionDetails>
              <NewSessionTestTable
                allTests={[test]}
                selectedTest={selectedTest}
                setSelectedTest={setSelectedTest}
              />
            </AccordionDetails>
          )}
        </Accordion>
      );
    }
  };

  if (Object.keys(selectedTest).length > 0) {
    setHideModules(true);
    return (
      <div
        style={{
          width: '100%',
        }}
      >
        <DetailedTest
          selectedTest={selectedTest}
          setSelectedTest={setSelectedTest}
        />
      </div>
    );
  }

  return (
    <>
      <LoadingScreen loading={loading} />
      <Box
        sx={{
          width: '68%',
          marginLeft: '48px',
          overflow: 'overlay',
          height: '76vh',
          marginTop: '2px',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        <Typography
          sx={{
            marginTop: '20px',
            fontSize: '30px',
          }}
          variant='h3'
        >
          {selectedSessionTopic}
        </Typography>

        <Divider style={{ margin: 'auto', backgroundColor: '#e8e8e8' }} />

        <Typography sx={{ marginTop: '20px' }}>
          {ModuleDescriptions[selectedSessionTopic]}
        </Typography>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-beetween',
            marginTop: '24px',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={sessionIcon}
              style={{ width: '24px', marginRight: '8px' }}
              alt='sessionIcon'
            />{' '}
            <Typography>{sessions?.length} Sessions</Typography>
          </Box>
          {sessionTestCount > 0 && (
            <Box
              sx={{ display: 'flex', alignItems: 'center', marginLeft: '46px' }}
            >
              <img
                src={sessionTestIcon}
                style={{ width: '24px', marginRight: '8px' }}
                alt='sessionTestIcon'
              />{' '}
              <Typography>{sessionTestCount} Session tests </Typography>
            </Box>
          )}
          {codingAssignments?.length > 0 && (
            <Box
              sx={{ display: 'flex', alignItems: 'center', marginLeft: '46px' }}
            >
              <img
                src={codingAssignmentIcon}
                style={{ width: '24px', marginRight: '8px' }}
                alt='codingAssignmentIcon'
              />{' '}
              <Typography>
                {' '}
                {codingAssignments?.length} Coding assignments
              </Typography>
            </Box>
          )}
        </Box>

        <Box sx={{ marginTop: '20px' }}>
          {sessionsData?.map((sessionData, i) => {
            const session = sessionData.filter(
              (item) => item.type === 'session'
            )[0];
            return (
              <>
                <Accordion
                  defaultExpanded={
                    sessionSelectedId === session.details.id.toString()
                  }
                  sx={{
                    marginTop: '5px',
                    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 1px 2px 0px',
                    border: '2px solid #e8e8e8',
                    borderRadius: '8px',
                    marginLeft: '15px solid #4bc944',
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='panel2-content'
                    id='panel2-header'
                    sx={{ height: '48px' }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      {sessionData.filter((item) => !item.completed).length !==
                      0 ? (
                        <img
                          src={sessionIcon}
                          style={{ width: '24px', marginRight: '8px' }}
                          alt='sessionIcon'
                        />
                      ) : (
                        <CheckCircleIcon
                          style={{
                            color: '#4bc944',
                            fontSize: '22px',
                            marginRight: '10px',
                          }}
                        />
                      )}
                      <Typography> {session.details.title}</Typography>
                    </Box>
                    <Chip
                      label='Live class'
                      color='primary'
                      variant='outlined'
                      sx={{
                        fontSize: '12px',
                        fontWeight: '800',
                        padding: '0px 8px',
                        marginRight: '1em',
                      }}
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      {SessionDescriptions[session.details.title]}
                    </Typography>
                    {(preReadsCount[i] > 0 || assignmentsCount[i] > 0) && (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-beetween',
                          marginTop: '16px',
                          marginBottom: '28px',
                          gap: '20px',
                        }}
                      >
                        {preReadsCount[i] > 0 && (
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <ArticleIcon
                              sx={{ fontSize: '22px', marginRight: '10px' }}
                            />{' '}
                            <Typography>
                              {preReadsCount[i]} Pre-Reads
                            </Typography>
                          </Box>
                        )}
                        {assignmentsCount[i] > 0 && (
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <img
                              src={codingAssignmentIcon}
                              style={{ width: '28px', marginRight: '8px' }}
                              alt='codingAssignmentIcon'
                            />{' '}
                            <Typography>
                              {' '}
                              {assignmentsCount[i]} Coding assignments
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    )}
                    <Divider sx={{ marginBottom: '5px' }} />
                    {sessionData.map((e, i) => {
                      return (
                        <SessionListItem
                          key={i}
                          index={i}
                          sessionData={sessionData}
                          text={e.text}
                          details={e.details}
                          type={e.type}
                          moduleName={selectedSessionTopic}
                          recordedSessions={recordedSessions}
                        />
                      );
                    })}
                  </AccordionDetails>
                </Accordion>
                {showSessionTest(session)}
              </>
            );
          })}
        </Box>
      </Box>
    </>
  );
};

import React from 'react';
import { useSelector } from 'react-redux';
import NPSFormS30Modal from './NPSFormS30Modal';
import NPSFormPCModal from './NPSFormPCModal';

const FeedbackForm = ({
  open,
  onClose,
  lastSubmittedFormResponse,
  setLastSubmittedFormResponse,
}) => {
  const program = useSelector((state) => state.programs.currentProgram);

  return (
    <div>
      {program?.type === 'super30' && (
        <NPSFormS30Modal
          open={open}
          onClose={onClose}
          lastSubmittedFormResponse={lastSubmittedFormResponse}
          setLastSubmittedFormResponse={setLastSubmittedFormResponse}
        />
      )}
      {program?.type === 'personalized' && (
        <NPSFormPCModal
          open={open}
          onClose={onClose}
          lastSubmittedFormResponse={lastSubmittedFormResponse}
          setLastSubmittedFormResponse={setLastSubmittedFormResponse}
        />
      )}
    </div>
  );
};

export default FeedbackForm;

export const mindmapTopicDescriptions = {
  stack: {
    'simple stack':
      'A basic data structure that follows the Last-In-First-Out (LIFO) principle, allowing operations like push and pop.',
    'monotonic stack':
      'A specialized stack used to solve problems related to finding next greater/smaller elements or maintaining monotonic sequences.',
    'two pointer':
      'Using two pointers to track elements or positions in a stack-based algorithm or problem-solving approach.',
    'dp based':
      'Applying dynamic programming techniques to solve problems involving stacks or utilizing stack-like operations.',
    recursion:
      'Utilizing recursive function calls and stack frames to solve problems or perform operations related to stacks.',
    design:
      'Designing custom stack implementations or stack-related data structures tailored to specific problem requirements.',
    'binary tree based':
      'Utilizing stacks to perform tree traversals or operations on binary tree structures.',
    hashing:
      'Using hashing techniques to store and retrieve elements or perform operations involving stacks.',
    'divide and conquer':
      'Applying divide and conquer strategies in stack-related problems to break them into smaller subproblems.',
  },

  queue: {
    design:
      'Design in Queue involves designing and implementing custom data structures or algorithms to efficiently handle operations in a queue, such as enqueue, dequeue, peek, or size calculations.',
    simulation:
      'Simulation in Queue involves using a queue data structure to simulate real-world scenarios or processes, often used in modeling systems, event-driven simulations, or process scheduling.',
    recursion:
      'Recursion in Queue involves solving queue-related problems or performing operations using recursive algorithms, where a function calls itself to process or manipulate elements in the queue.',
    'priority queue based':
      'Priority Queue based in Queue involves utilising a priority queue data structure to perform operations or maintain order based on priorities assigned to elements in the queue.',
    'sliding window':
      'Sliding Window in Queue involves using a sliding window technique to process or analyse a fixed-size window over the elements in the queue, facilitating efficient window-based computations or pattern matching.',
    hashing:
      'Hashing in Queue involves utilising hash functions or hash-based data structures to efficiently store, retrieve, or identify elements in a queue, based on their unique hash values.',
  },

  array: {
    'simple array':
      'A basic data structure that stores a collection of elements in contiguous memory locations.',
    'prefix-sum':
      'A technique to efficiently calculate the cumulative sum of elements in an array.',
    sorting:
      'Arranging the elements in a specific order, such as ascending or descending, within an array.',
    hashing:
      'Using hash functions and hash tables to store and retrieve elements in an array.',
    'bit manipulation':
      'Performing logical operations on individual bits within the elements of an array.',
    'two pointer':
      'Using two pointers to traverse or manipulate elements in an array, often in a linear or sliding window fashion.',
    'binary search':
      'Searching for a specific target value within a sorted array by repeatedly dividing it in half.',
    'dp based':
      'Applying dynamic programming techniques to solve problems efficiently using an array-based approach.',
    'sliding window':
      'A technique that involves maintaining a subarray or window of elements within an array to solve certain types of problems.',
    geometry:
      'Dealing with geometric calculations or operations involving arrays of points, lines, or shapes.',
    matrix:
      'Representing and manipulating multidimensional arrays with rows and columns.',
  },

  string: {
    'simple string':
      'Simple String refers to basic operations and manipulations performed on strings, such as concatenation, length calculation, character extraction, and substring operations.',
    hashing:
      'Hashing in Strings involves using hash functions to map strings to unique numerical values, facilitating efficient storage, retrieval, or identification of strings in hash-based data structures.',
    sorting:
      'Sorting in Strings refers to arranging strings in a specific order, such as lexicographically or based on specific criteria, to improve search or comparison operations.',
    'two pointer':
      'Two Pointer in Strings involves using two pointers or indices to traverse or manipulate strings, often used in algorithms that require simultaneous access or comparison of multiple characters or substrings.',
    counting:
      'Counting in Strings involves counting the occurrences or frequencies of characters or substrings in a string, often used in counting sort algorithms or frequency analysis.',
    'sliding window':
      'Sliding Window in Strings involves utilizing a sliding window technique to process or analyze a fixed-size window over a string, facilitating efficient window-based computations or pattern matching.',
    'bit manipulation':
      'Bit Manipulation in Strings involves performing bitwise operations or techniques on the characters or bits of a string for specialized bit-level operations or optimizations.',
    'dp based':
      'DP based in Strings refers to utilizing dynamic programming techniques in solving string-related problems, optimizing and storing intermediate results for efficient computations.',
    'prefix sum':
      'Prefix Sum in Strings involves precomputing cumulative sums or counts of characters in a string, enabling efficient queries or calculations on subsegments of the string.',
    recursion:
      'Recursion in Strings involves solving or manipulating strings using recursive algorithms, where a problem is divided into smaller subproblems and solved recursively.',
    backtracking:
      'Backtracking in Strings involves systematically exploring and searching for solutions or patterns in a string by trying out different possibilities and backtracking when a solution is not found.',
    'string matching':
      'String Matching refers to finding or determining the occurrence or position of a specific pattern or substring within a larger string, often using algorithms like KMP, Rabin-Karp, or Boyer-Moore.',
  },

  'linked list': {
    sorting:
      'Refers to the process of arranging elements in a specific order, such as ascending or descending, to facilitate efficient searching and data manipulation.',
    rotating:
      'Refers to the process of shifting elements within a data structure in a circular manner, often used to reposition elements or modify the order of elements.',
    reversing:
      'Refers to the process of flipping the order of elements within a data structure, typically done to change the sequence or orientation of the data.',
    splitting:
      'Refers to dividing a data structure or a collection of elements into multiple parts based on certain criteria or conditions, allowing for easier processing and manipulation of data.',
    removing:
      'Refers to the process of deleting or eliminating elements from a data structure, often done to modify the size or content of the structure to accommodate specific requirements or operations.',
    reordering:
      'Refers to changing the arrangement or sequence of elements within a data structure, typically done to optimise performance, improve accessibility, or fulfill specific ordering requirements.',
    'multiple pointer':
      'Refers to using multiple variables or references to traverse or manipulate data structures simultaneously, enabling efficient operations such as searching, merging, or partitioning.',
    recursion:
      'Refers to the process of solving a problem by breaking it down into smaller, similar subproblems, where each subproblem is solved by calling the same function recursively until a base case is reached.',
    'iterative manner':
      'Refers to solving a problem using iterative loops and iterative steps, where the solution is obtained by executing a set of instructions repeatedly until the desired outcome is achieved, without relying on recursive function calls.',
    'stack based questions':
      'A stack is a data structure that follows the Last-In-First-Out (LIFO) principle, where elements are added and removed from the top, allowing efficient insertion and deletion at one end.',
    'divide and conquer':
      'Divide and conquer is a problem-solving technique that involves breaking down a complex problem into smaller, more manageable subproblems, solving them independently, and combining their solutions to solve the original problem efficiently.',
    hashing:
      'Hashing is a technique used in computer science and data structures to map data of arbitrary size to fixed-size values, typically numeric or alphanumeric.It is commonly used for indexing and retrieving data quickly, as the hash function provides a unique identifier or address for each data item, allowing efficient storage and retrieval operations.',
  },

  'dynamic programming': {
    memoization:
      'Memoization is a technique used to optimise the execution time of a function by caching or storing the results of expensive function calls and returning the cached result when the same inputs occur again. This helps avoid redundant computations and improves the overall performance of the program.',
    'tabular form':
      'Tabular form in dynamic programming involves creating a table or matrix to store and calculate intermediate results, enabling efficient computation and optimisation of solutions to dynamic programming problems.',
  },

  'range query': {
    'range query':
      'Range query refers to a type of operation or query that involves retrieving or calculating information within a specific range or interval of elements or values in a data structure or array.',
  },

  recursion: {
    recursion:
      'Recursion is a programming technique where a function calls itself repeatedly to solve a problem by breaking it down into smaller, similar subproblems, until a base case is reached.',
  },

  math: {
    'number theory':
      'It involves the application of mathematical principles and concepts, such as prime numbers, modular arithmetic, and number properties, to solve algorithmic problems efficiently and accurately.',
    'game theory':
      'It involves the study of mathematical models and strategies to analyse and predict the outcomes of strategic interactions and decision-making processes in competitive scenarios, aiding in the development of efficient algorithms and solutions.',
    geometry:
      'It involves the utilisation of geometric principles and algorithms to solve computational problems related to shapes, spatial relationships, and geometric transformations, enabling efficient geometric computations and analysis.',
  },

  'bit manipulation': {
    'simple bit manipulation':
      'Simple bit manipulation involves manipulating individual bits of binary numbers using bitwise operators to perform operations such as setting, clearing, toggling, or checking the value of specific bits, providing compact and efficient solutions to certain computational problems.',
    hashing:
      'Hashing in bit manipulation involves mapping data to fixed-size values using bitwise operations, allowing efficient storage, retrieval, and manipulation of data represented in binary form.',
    backtracking:
      'Backtracking in bit manipulation involves exploring and manipulating binary values to find valid solutions by incrementally building and testing different combinations of bits.',
    'dp based':
      'Dynamic programming in bit manipulation involves using memoization or tabulation techniques to optimise and store intermediate results of bitwise operations, facilitating efficient computation of complex problems.',
    'sliding window':
      'Sliding window in bit manipulation involves using bitwise operations to maintain a dynamic window of fixed-size bit sequences while iterating through a binary value, optimising computations by reusing previously calculated results.',
    recursion:
      'Recursion in bit manipulation involves using recursive function calls to manipulate binary values by breaking them down into smaller subproblems, allowing for elegant and concise solutions to bitwise manipulation tasks.',
  },

  backtracking: {
    backtracking:
      'Backtracking is a problem-solving technique that involves systematically exploring all possible solutions by incrementally building and evaluating potential candidates and efficiently pruning search paths that are known to lead to invalid or suboptimal solutions.',
  },

  greedy: {
    'simple greedy':
      'Simple Greedy is a strategy in which locally optimal choices are made at each step without considering their long-term consequences, aiming to achieve a globally optimal solution.',
    sorting:
      'Sorting is the process of arranging elements in a specific order, typically in non-decreasing or non-increasing order, to facilitate efficient searching, accessing, and processing of data.',
    'two pointer':
      'Two Pointer is an algorithmic technique that involves using two pointers to traverse a data structure, often an array or linked list, in parallel or with a specific distance between them, facilitating efficient computation of certain problems.',
    hashing:
      'Hashing is a technique that uses a hash function to map data to fixed-size values, enabling efficient storage, retrieval, and search operations based on key-value pairs.',
    matrix:
      'A matrix is a rectangular array of elements organised in rows and columns, commonly used for representing and manipulating multi-dimensional data structures or solving problems that involve two-dimensional patterns or relationships.',
  },

  'divide and conquer': {
    'divide and conquer':
      'Divide and Conquer is a problem-solving approach that involves breaking down a complex problem into smaller, more manageable subproblems, solving them independently, and combining their solutions to solve the original problem efficiently.',
  },

  'searching and sorting': {
    'binary search':
      'Binary Search is a search algorithm that efficiently finds the position of a target value within a sorted array or list by repeatedly dividing the search space in half.',
    sorting:
      'Sorting in Searching and Sorting refers to the process of arranging a collection of elements in a specific order, such as ascending or descending, to facilitate efficient searching algorithms or improve data organisation and retrieval.',
  },

  'hash table': {
    'simple hashing':
      'Simple Hashing involves transforming data or keys into a unique numerical value using a hash function for efficient storage, retrieval, or identification in a hash table.',
    sorting:
      'Sorting in Hash Table refers to arranging the elements of a hash table in a specific order, typically based on the keys, to improve search or traversal operations.',
    counting:
      ' Counting in Hash Table involves using a hash table to efficiently count the occurrences or frequencies of elements in a collection, often used in counting sort algorithms or frequency analysis.',
    'bit manipulation':
      'Bit Manipulation in Hash Table involves performing bitwise operations or techniques on the elements or keys of a hash table for specialised bit-level operations or optimisations.',
    'binary search':
      'Binary Search in Hash Table refers to performing a binary search on the elements of a hash table, utilising the keys and the inherent ordering properties to efficiently locate a specific element.',
    'sliding window':
      'Sliding Window in Hash Table involves utilising a hash table to maintain a fixed-size window over a collection of elements, facilitating efficient window-based computations or statistics.',
    'two pointer':
      'Two Pointer in Hash Table involves using two pointers or indices to traverse or manipulate elements in a hash table, often used in algorithms that require simultaneous access or comparison of multiple elements.',
    'divide and conquer':
      'Divide and Conquer in Hash Table involves applying the divide and conquer algorithmic paradigm to solve hash table-related problems, recursively dividing the problem into smaller subproblems and combining the results.',
    'dp based':
      'DP based in Hash Table refers to utilising dynamic programming techniques in the implementation or operations of a hash table, optimising and storing intermediate results for efficient computations.',
    'tree based':
      'Tree-based in Hash Table involves incorporating tree structures, such as binary search trees or AVL trees, in the implementation or operations of a hash table, enhancing organisation, search, or deletion of elements.',
    matrix:
      'Matrix in Hash Table refers to representing a hash table using a two-dimensional matrix or array, where each element corresponds to a specific position based on the hash function, facilitating efficient hash table operations.',
  },

  trie: {
    hashing:
      'Use a hashing function to map data values to trie nodes for efficient storage and retrieval.',
    sorting:
      'Arrange trie nodes or keys in a specific order to optimize search or traversal operations.',
    // counting:
    //   'Track the number of occurrences or frequency of keys in the trie for statistical analysis or pattern matching.',
    'bit manipulation':
      'Perform bitwise operations on trie nodes or keys to extract or manipulate specific bits of information.',
    // 'binary search':
    //   'Utilize binary search algorithms on trie nodes or keys to efficiently locate specific values or patterns.',
    'sliding window':
      'Apply sliding window techniques on trie nodes or keys to solve problems involving substring or subarray operations.',
    'two pointer':
      'Use two pointers to traverse or manipulate trie nodes or keys simultaneously, optimizing certain algorithms or operations.',
    // 'divide and conquer':
    //   'Break down trie operations or problems into smaller subproblems for more efficient solving.',
    'dp based':
      'Utilize dynamic programming techniques on trie structures or algorithms to optimize certain computations or search operations.',
    // 'tree based':
    //   'Utilize trie structures as a type of tree-based data structure for efficient storage and retrieval of keys.',
    matrix:
      'Use trie structures or algorithms in the context of matrix operations or computations.',
    'simple trie':
      'A fundamental data structure for storing and searching strings efficiently, providing fast lookup and retrieval operations.',
    design:
      'Developing customized trie implementations tailored to specific problem domains, optimizing storage and retrieval of key-value pairs.',
    backtracking:
      'Leveraging trie structures in backtracking algorithms to efficiently explore and find solutions within the search space.',
    'dfs based':
      'Traversing and searching trie structures using a depth-first search approach for efficient exploration and pattern matching.',
  },

  graphs: {
    'simple graph':
      'A Simple Graph is a non-directed graph where edges represent relationships or connections between vertices, without considering their weights or directions.',
    dfs: 'DFS is a graph traversal algorithm that explores as far as possible along each branch before backtracking, often used to search for paths, cycles, or connected components in a graph.',
    bfs: 'BFS is a graph traversal algorithm that explores all the vertices of a graph at the same level before moving to the next level, often used to find the shortest path or explore neighbors in a graph.',
    'strongly connected components':
      "Strongly Connected Components are subgraphs in a directed graph where every vertex is reachable from every other vertex, typically identified using algorithms like Tarjan's or Kosaraju's algorithm.",
    backtracking:
      'Backtracking in Graphs involves exploring different paths or possibilities in a graph, incrementally building and evaluating valid solutions while discarding incorrect ones.',
    hashing:
      'Hashing in Graphs involves using a hash function to map graph elements or attributes to specific positions or values, facilitating efficient storage, retrieval, or search operations.',
    'union find':
      "Union Find (Disjoint Set Union) is a data structure used to efficiently track and merge disjoint sets, often used in algorithms like Kruskal's algorithm for finding minimum spanning trees or detecting cycles.",
    coloring:
      'Coloring in Graphs refers to assigning colors to the vertices or edges of a graph based on certain rules or constraints, often used in graph problems like graph coloring or scheduling.',
    'cycle detection':
      "Cycle Detection in Graphs involves identifying the presence of cycles or loops in a graph, often accomplished using algorithms like DFS or Floyd's cycle-finding algorithm.",
    'topological sorting':
      'Topological Sorting is an ordering of the vertices of a directed graph such that for every directed edge (u, v), vertex u comes before v in the ordering, commonly used in scheduling, dependency resolution, or task sequencing.',
    'spanning tree':
      'A Spanning Tree is a subgraph of a connected, undirected graph that includes all the vertices of the original graph with the minimum possible number of edges, often used to identify minimum-cost or efficient network structures.',
    'shortest path':
      "Shortest Path refers to finding the minimum-cost or minimum-length path between two vertices in a graph, commonly solved using algorithms like Dijkstra's, Bellman-Ford, or Floyd-Warshall algorithm.",
    'dp based':
      'DP based in Graphs involves utilising dynamic programming techniques to optimise and store intermediate results in graph problems, enhancing efficiency and reducing redundant computations.',
    geometry:
      'Geometry in Graphs involves utilising geometric concepts and algorithms to solve graph problems related to spatial positioning, distance calculations, or geometric relationships.',
    'bit manipulation':
      'Bit Manipulation in Graphs involves utilizing bitwise operations or techniques to optimise memory usage or support special bit-level operations in graph algorithms or representations.',
    'connected components':
      'Connected Components are subgraphs in an undirected graph where every vertex is reachable from every other vertex, often identified using algorithms like DFS or BFS.',
    'eulerian circuit':
      'An Eulerian Circuit is a circuit in an undirected graph that passes through each edge exactly once, often used to identify a specific type of graph connectivity or solve certain optimisation problems.',
    matrix:
      'Matrix in Graphs refers to representing a graph using a two-dimensional matrix or array, where the presence or absence of edges is indicated by specific values or flags in the matrix elements.',
  },

  heap: {
    'simple heap':
      'A Simple Heap is a data structure that maintains a partially ordered binary tree, where each node is greater (or smaller) than its children, commonly used for efficient insertion, deletion, or retrieval of the minimum (or maximum) element.',
    sorting:
      'Sorting in Heap involves using a heap data structure to sort a collection of elements in ascending or descending order, typically achieved through techniques like Heap Sort or Priority Queue.',
    counting:
      'Counting in Heap involves utilising a heap data structure to efficiently count the occurrences or frequencies of elements in a collection, often used in counting sort algorithms or frequency analysis.',
    'binary search':
      'Binary Search in Heap refers to using a heap data structure to perform a binary search on a sorted collection of elements, reducing the search space in each iteration for efficient searching.',
    matrix:
      'Matrix in Heap refers to representing a heap data structure using a two-dimensional matrix or array, where each element is placed in a specific position based on the heap property, facilitating efficient heap operations.',
    hashing:
      'Hashing in Heap involves using a hash function to map elements or attributes to specific positions or values in a heap data structure, enhancing search, insertion, or deletion operations.',
    'divide and conquer':
      'Divide and Conquer in Heap involves employing the divide and conquer algorithmic paradigm to solve heap-related problems, recursively dividing the problem into smaller subproblems and combining the results.',
    geometry:
      'Geometry in Heap refers to utilising geometric concepts and algorithms to solve problems related to heap data structures, such as spatial positioning, distance calculations, or geometric relationships.',
    'bit manipulation':
      'Bit Manipulation in Heap involves applying bitwise operations or techniques to optimise memory usage or perform specialized bit-level operations in heap-related algorithms or representations.',
    'prefix sum':
      'Prefix Sum in Heap involves using a heap data structure to efficiently calculate prefix sums of elements in a collection, facilitating fast range sum or cumulative sum calculations.',
    'tree based':
      'Tree-based in Heap refers to utilising tree structures, such as binary trees or n-ary trees, in the implementation or operations of heap data structures, enhancing organisation, traversal, or manipulation of elements.',
    'graph based':
      'Graph-based in Heap refers to utilising graph structures or algorithms in the implementation or operations of heap data structures, incorporating graph traversal, connectivity, or shortest path algorithms.',
    'dp based':
      'DP based in Heap involves employing dynamic programming techniques to optimise and store intermediate results in heap-related problems, enhancing efficiency and reducing redundant computations.',
    'sliding window':
      'Sliding Window in Heap involves using a heap data structure to efficiently maintain and process a fixed-size sliding window over a collection of elements, often used in problems requiring window-based computations or statistics.',
    'stack based':
      'Stack-based in Heap refers to utilising stack data structures or stack-like operations in the implementation or operations of heap-related algorithms, enhancing the management or manipulation of elements.',
    'queue based':
      'Queue-based in Heap refers to utilising queue data structures or queue-like operations in the implementation or operations of heap-related algorithms, facilitating efficient element insertion, retrieval, or removal based on the heap property.',
  },

  trees: {
    'binary tree':
      'A tree data structure in which each node has at most two children.',
    'binary search tree':
      'A binary tree in which the values of nodes in the left subtree are less than the value of the parent node, and the values of nodes in the right subtree are greater.',
    'dfs based':
      'Traversing or solving problems using a depth-first search algorithm on a tree structure.',
    'bfs based':
      'Traversing or solving problems using a breadth-first search algorithm on a tree structure.',
    'divide and conquer':
      'Breaking down tree operations or problems into smaller subproblems for more efficient solving.',
    'string matching':
      'Searching for patterns or matching strings within a tree structure.',
    'n-ary tree':
      'A tree data structure in which each node can have more than two children.',
    backtracking:
      'Exploring different paths or choices within a tree structure to find a solution or satisfy certain constraints.',
    'dp based':
      'Utilizing dynamic programming techniques on tree structures or algorithms to optimize certain computations or search operations.',
  },
};

export default {
  'Time Complexity':
    'Understand how to analyze the efficiency of algorithms and predict their performance scaling.',
  'Sorting-1':
    'Dive into basic sorting algorithms like Bubble Sort and Selection Sort, learning how they operate and their use-cases.',
  'Sorting-2':
    'Explore more complex sorting methods such as Merge Sort and Quick Sort, essential for efficient data manipulation.',
  'MAANG interviews':
    'Get tailored guidance on tackling algorithmic challenges commonly presented in interviews with top tech firms.',
  Searching:
    'Learn techniques for efficiently finding data within structures, including linear and binary search methods.',
  Arrays:
    'Master the fundamentals of arrays and their applications in storing and accessing sequential data.',
  Strings:
    'Understand string manipulation and operations critical for solving problems involving text analysis.',
  'Arrays and strings':
    'Explore combined problems involving arrays and strings, enhancing your data-handling skills.',
  Sets: 'Learn to use sets for unique data storage and operations like union and intersection.',
  Hashmaps:
    'Discover the power of hashmaps in data retrieval and storage for key-value pairs.',
  'Problem solving':
    'Develop your problem-solving skills by tackling diverse challenges using basic data structures.',
  Queues:
    'Master the FIFO (First In, First Out) concept with queues for managing ordered data.',
  'Stacks - 1':
    'Explore the LIFO (Last In, First Out) principle in managing data with stacks.',
  'Stacks - 2':
    'Advanced stack operations and problem-solving techniques using stacks.',
  'Backtracking and recursion':
    'Learn to solve problems recursively and use backtracking to explore potential solutions.',
  'Dynamic Programming - 1':
    'Each part covers different dynamic programming problems, from introductory to more advanced, including memoization and optimization techniques.',
  'Profile presentation and interview prep':
    'Learn to effectively present your professional profile, along with mastering interview strategies to impress potential employers.',
  'Dynamic programming - 2':
    'Each part covers different dynamic programming problems, from introductory to more advanced, including memoization and optimization techniques.',
  'Dynamic programming - 3':
    'Each part covers different dynamic programming problems, from introductory to more advanced, including memoization and optimization techniques.',
  'Dynamic programming - 4':
    'Each part covers different dynamic programming problems, from introductory to more advanced, including memoization and optimization techniques.',
  'Dynamic programming - 5':
    'Each part covers different dynamic programming problems, from introductory to more advanced, including memoization and optimization techniques.',
  'Linked lists - 1':
    'From basic to advanced manipulations, understand how to use linked lists in data handling.',
  'Linked lists - 2':
    'From basic to advanced manipulations, understand how to use linked lists in data handling.',
  'Hiring manager round':
    "Learn the nuances of engaging with hiring managers, including how to effectively present your skills, answer behavioral questions, and discuss your professional experiences and achievements. This topic also covers strategies for demonstrating your potential impact on the team and aligning your goals with the company's vision.",
  'Binary trees':
    'Explore tree structures for hierarchical data, focusing on binary trees and their traversal techniques.',
  'Binary search trees - 1':
    'Learn about BSTs and how they enable efficient searching and sorting.',
  'Binary search trees - 2':
    'Learn about BSTs and how they enable efficient searching and sorting.',
  Tries:
    'Master tries for efficient word manipulations and prefix-based searches.',
  'Heaps - 1':
    'Understand how heaps are used to manage prioritized data and support heap sort.',
  'Heaps - 2':
    'Understand how heaps are used to manage prioritized data and support heap sort.',
  'Heaps - 3':
    'Understand how heaps are used to manage prioritized data and support heap sort.',
  'Demo MAANG Interviews':
    'Experience mock interviews simulating the real interview scenarios at top tech companies.',
  'BFS and DFS - 1':
    'Understand the fundamentals and advanced applications of Breadth-First Search and Depth-First Search to explore graphs.',
  'BFS and DFS - 2':
    'Understand the fundamentals and advanced applications of Breadth-First Search and Depth-First Search to explore graphs.',
  'Minimum spanning trees':
    'Learn how to find the minimum spanning tree of a graph, crucial for network design and minimizing costs.',
  Greedy:
    'Dive into greedy algorithms to solve problems that require optimal localized decisions to achieve global optimality.',
  'Graphs - 1':
    'Cover basic to advanced concepts in graph theory, including traversal, shortest paths, and network flows.',
  'Graphs - 2':
    'Cover basic to advanced concepts in graph theory, including traversal, shortest paths, and network flows.',
  'Graphs - 3':
    'Cover basic to advanced concepts in graph theory, including traversal, shortest paths, and network flows.',
  Patterns:
    'Learn to recognize and apply common patterns in data structure problems.',
  'Object oriented programming':
    'Grasp the core principles of OOP including encapsulation, inheritance, and polymorphism to structure code efficiently.',
  'Introduction to design patterns':
    'Explore common design patterns like Singleton, Observer, and Factory to solve recurring design problems.',
  'Low level design':
    'Learn to design classes, interfaces, and algorithms that efficiently tackle specific problems.',
  'Advanced low level design':
    'Delve deeper into complex design scenarios and refine your ability to develop robust low-level systems.',
  'Networking concepts':
    'Gain insights into the fundamentals of networking such as protocols, IP addressing, and routing.',
  'Database concepts':
    'Explore key database concepts including normalization, indexing, and transactions to manage data effectively.',
  'Memory management concepts':
    'Understand how effective memory management improves application performance and resource utilization.',
  'OS concepts on concurrency':
    'Learn about operating system features that support concurrency and how to design applications to exploit these features effectively.',
  'Job Hunt':
    'Gain insights into effective job search strategies and how to navigate the tech employment landscape.',
  'System design concepts':
    'Cover the breadth of system design including architecture, scalability, and reliability.',
  Approach:
    'Learn structured approaches to tackle system design questions in interviews.',
  'Deep dive':
    'Engage in detailed discussions on complex system design scenarios and their solutions.',
  'Question-1':
    'Explore a specific high-level design question in depth, analyzing and solving it step-by-step.',
  'Question-2':
    'Tackle advanced system design problems that reflect the challenges faced in high-stakes tech interviews.',
  'Question-3':
    'Tackle advanced system design problems that reflect the challenges faced in high-stakes tech interviews.',
  'Open session':
    'An open format to discuss various topics, answer questions, and interact with mentors.',
  'Fireside chat':
    'Engage with experts in casual discussions about the tech industry and career advice.',
  'Union Find Topological sort':
    'Master advanced data structures and algorithms for union-find operations and topological sorting.',
  'Math for competitive programming':
    'Dive into mathematical concepts and techniques critical for solving competitive programming challenges.',
  'Self balancing trees':
    'Learn about AVL trees and Red-Black trees that maintain balanced heights to ensure efficient operations.',
  'Bit manipulation':
    'Understand techniques for manipulating bits within integers to solve problems more efficiently.',
  'Mock interview-1':
    'Simulate real interview experiences with mock sessions based on actual MAANG interview formats.',
  'Mock interview-2':
    'Simulate real interview experiences with mock sessions based on actual MAANG interview formats.',
};

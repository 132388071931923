import React from 'react';
import { Box, Typography } from '@material-ui/core';

const ParagraphBox = ({ children }) => {
  return (
    <Box
      display='flex'
      flexDirection='column'
      gap='10px'
    >
      {children}
    </Box>
  );
};

export default ParagraphBox;

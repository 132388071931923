import React from 'react';
import { Dialog, DialogContent, Typography, Box } from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified';

const SuccessModal = ({ open, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth='sm'
      fullWidth
    >
      <DialogContent>
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          textAlign='center'
          paddingBottom='16px'
          paddingTop='16px'
        >
          <VerifiedIcon sx={{ fontSize: 80, color: 'darkgreen', mb: 2 }} />
        </Box>
        <Box
          display='flex'
          flexDirection='column'
          alignItems='left'
          textAlign='left'
        >
          <Typography
            variant='h4'
            gutterBottom
          >
            Thank you! We have received the details.
          </Typography>
          <Typography variant='body1'>
            We've received your referral and will reach out to your friend soon.
            If they enroll, you'll receive an Amazon Voucher worth Rs 5000. Keep
            an eye on your inbox for updates!
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default SuccessModal;

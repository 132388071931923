// routes
import React, { useNavigate, useRoutes } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { googleLogout } from '@react-oauth/google';
import { clearReduxPersistState } from './index';
import routes from './routes';
// theme
import ThemeConfig from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import { isUserLoggedIn, getUserType, setUserLoggedOut } from './configs/auth';
import { unsetPrograms } from './components/redux-store/slices/programSlice';
import { unsetTopics } from './components/redux-store/slices/topicSlice';
import { unsetSessions } from './components/redux-store/slices/sessionSlice';
import ErrorBoundary from './utils/ErrorBoundary';
import { shouldShowNewStudentLayout } from './components/utils/common';

export default function App() {
  const routing = useRoutes(routes(isUserLoggedIn(), getUserType()));
  const dispatch = useDispatch();
  /**
   * conditionally apply the class "setRootBg" to the router to apply the background color to
   * only the demo program screens
   */
  return (
    <ThemeConfig>
      <ScrollToTop />
      <ErrorBoundary>{routing}</ErrorBoundary>
    </ThemeConfig>
  );
}

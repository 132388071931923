import React, { useState } from 'react';
import { Box, Button, Typography, Tabs, Tab } from '@material-ui/core';
import LoadingButton from '@mui/lab/LoadingButton';
import VerifiedIcon from '@mui/icons-material/Verified';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import { GenerateAllPossibleSubsetsConst } from '../../../utils/techniqueSagaConstant';
import CustomTabPanel from '../CustomTabPanel';
import ListRenderComponent from '../ListRenderComponent';
import CustomButton from '../../../practice/MindMaps/Atoms/CustomButton';
import TopicTitle from '../TopicTitle';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const GenerateAllPossibleSubsets = ({
  handlePostCompletedTopic,
  hasMarkedCompleted,
  loading,
  topic_id,
  handleNext,
  handlePrevious,
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap='36px'
      paddingY={2}
      maxWidth='calc(100% - 300px)'
    >
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
      >
        <TopicTitle>4. Generate All Possible Subsets</TopicTitle>
        {hasMarkedCompleted(topic_id) && (
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              color: 'green',
              fontWeight: '500',
              border: '2px solid green',
              padding: '4px 10px',
              borderRadius: '20px',
              cursor: 'default',
            }}
          >
            Completed
          </Typography>
        )}
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        gap='34px'
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            width: 'fit-content',
            background: '#FEF7FF',
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
          >
            <Tab
              label='Problem Statement'
              {...a11yProps(0)}
            />
            <Tab
              label='Traditional Approach'
              {...a11yProps(1)}
            />
            <Tab
              label='Bit Manipulation Approach'
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <CustomTabPanel
          value={value}
          index={0}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Box>
              <Typography>
                {GenerateAllPossibleSubsetsConst.ProblemStatement.text01}
              </Typography>
              <Typography>
                {GenerateAllPossibleSubsetsConst.ProblemStatement.text02}
              </Typography>
            </Box>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={1}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Typography>
              {GenerateAllPossibleSubsetsConst.TraditionalApproach.text01}
            </Typography>

            <Box
              sx={{
                backgroundColor: 'black',
                padding: '24px',
                borderRadius: '10px',
                width: '705px',
                overflowX: 'auto',
              }}
            >
              <pre style={{ color: 'white', margin: 0 }}>
                {`void generateSubsets(const vector<int>& nums, vector<vector<int>>& subsets, vector<int>& current, int index) {    
    if (index == nums.size()) {
        subsets.push_back(current);
        return;
    }

    current.push_back(nums[index]);
    generateSubsets(nums, subsets, current, index + 1);
    current.pop_back(); // Backtrack
    generateSubsets(nums, subsets, current, index + 1);
}

vector<vector<int>> subsets(const vector<int>& nums) {
    vector<vector<int>> subsets;
    vector<int> current;
    generateSubsets(nums, subsets, current, 0);
    return subsets;
}`}
              </pre>
            </Box>

            <Box
              display='flex'
              flexDirection='column'
              gap={1}
            >
              <Typography>
                {
                  GenerateAllPossibleSubsetsConst.TraditionalTimeComplexity
                    .text01
                }
              </Typography>
              <Typography>
                {
                  GenerateAllPossibleSubsetsConst.TraditionalTimeComplexity
                    .text02
                }
              </Typography>
            </Box>

            <Box
              display='flex'
              flexDirection='column'
              gap={2}
            >
              <Typography>
                <span style={{ fontWeight: '600' }}>Time Complexity:</span>
                {' O(n x 2^n)'}
              </Typography>
              <Typography>
                <span style={{ fontWeight: '600' }}>Space Complexity:</span>
                {' O(n x 2^n)'}
              </Typography>
            </Box>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={2}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Typography>
              {GenerateAllPossibleSubsetsConst.BitManipulationApproach01.text01}
            </Typography>

            <Box>
              <Typography sx={{ fontSize: '22px', fontWeight: 400, mb: '8px' }}>
                Example
              </Typography>
              <ListRenderComponent
                data={GenerateAllPossibleSubsetsConst.BitManipulationApproach02}
              />
            </Box>

            <Box
              sx={{
                backgroundColor: 'black',
                padding: '24px',
                borderRadius: '10px',
                width: '583px',
              }}
            >
              <pre style={{ color: 'white', margin: 0 }}>
                {`void possibleSubsets(int nums[], int N) {
    for (int i = 0; i < (1 << N); ++i) {
        for (int j = 0; j < N; ++j)
            if (i & (1 << j))
                cout << nums[j] << ' ';
        cout << endl;
    }
}`}
              </pre>
            </Box>

            <Typography>
              {
                GenerateAllPossibleSubsetsConst.BitManipulationTimeComplexity
                  .text01
              }
            </Typography>

            <Box
              display='flex'
              flexDirection='column'
              gap={2}
            >
              <Typography>
                <span style={{ fontWeight: '600' }}>Time Complexity:</span>
                {' O(n x 2^n)'}
              </Typography>
              <Typography>
                <span style={{ fontWeight: '600' }}>Space Complexity:</span>
                {' O(n x 2^n)'}
              </Typography>
            </Box>
          </Box>
        </CustomTabPanel>
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        alignSelf='end'
        justifyContent='space-between'
        gap='12px'
      >
        {!hasMarkedCompleted(topic_id) && (
          <LoadingButton
            variant='contained'
            onClick={() => handlePostCompletedTopic(topic_id)}
            loading={loading}
            loadingPosition='center'
            children='Mark as completed'
            style={{
              width: '170px',
              // backgroundColor: 'transparent',
              borderRadius: '8px',
              border: '1px solid rgba(64, 96, 245, 0.5)',
            }}
          />
        )}

        <Box
          display='flex'
          alignItems='center'
          gap='8px'
        >
          <Button
            sx={{ gap: '4px' }}
            onClick={handlePrevious}
          >
            <WestIcon /> Previous
          </Button>

          <Button
            sx={{ gap: '4px' }}
            onClick={handleNext}
          >
            Next <EastIcon />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default GenerateAllPossibleSubsets;

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import { getHeaders, BASE_URL } from '../../apis/urls';
import LoadingScreen from '../../pages/LoadingScreen';
import HeadingText from './HeadingTeaxt';
import SplashScreen from './SplashScreen';
import LevelInfoDrawer from './Molecules/LevelInfoDrawer';
import ResultModal from './Molecules/ResultModal';
import ApologyModal from './Molecules/ApologyModal';
import DetailedTest from '../practice/CodingTest/DetailedTest/DetailedTest';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';

const levelIdToNameMap = {
  2: 'C1',
  3: 'C2',
  4: 'C3',
  5: 'C4',
  6: 'C5',
  7: 'C6',
  8: 'C7',
  9: 'C8',
  10: 'C9',
  11: 'C10',
  12: 'C11',
  13: 'C12',
  14: 'C13',
};

const TGPMainScreen = () => {
  const currentProgram = useSelector((state) => state.programs.currentProgram);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openResultModal, setOpenResultModal] = useState(false);
  const [openApologyModal, setOpenApologyModal] = useState(false);
  const [selectedLevel, setSelectedLevel] = useState(null);
  const [allTests, setAllTests] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [clearedLevels, setClearedLevels] = useState([]);
  const [selectedTest, setSelectedTest] = useState(null);
  const [openTest, setOpenTest] = useState(false);

  useEffect(() => {
    getAllLevelTests();
    return () => {};
  }, [currentProgram]);

  useEffect(() => {
    if (!selectedLevel) {
      return;
    }

    const len = allTests.length;
    let foundTest = null;
    for (let i = 0; i < len; i += 1) {
      const test = allTests[i];
      if (selectedLevel === test.levelName) {
        if (test.hasCleared || test.isRunning) {
          foundTest = test;
          break;
        }
        foundTest = test;
      }
    }

    setSelectedTest(foundTest);
    setOpenDrawer(true);
    return () => {};
  }, [selectedLevel]);

  const getAllLevelTests = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${BASE_URL}/api/level_tests`, {
        headers: getHeaders(currentProgram?.programId),
      });

      const passedLevels = new Set([]);

      const levelTests = [];

      (response.data ?? []).forEach((test) => {
        const startTime = moment(test.start_time);
        const endTime = moment(test.end_time);
        const startedAt = moment(test.started_at);
        const submittedAt = moment(test.submitted_at);

        const levelName = levelIdToNameMap[test.level_set.level_id];
        if (test.has_cleared) {
          passedLevels.add(levelName);
        }

        const isRunning =
          moment().isBetween(startTime, endTime) && !test.has_submitted;

        levelTests.push({
          testId: test.id,
          levelName,
          name: test.name,
          hasJoined: test.has_joined,
          score: test.test_score,
          hasCleared: test.has_cleared,
          isRunning,
          hasSubmitted: test.has_submitted,
          duration: test.duration,
          levelId: test.level_set.level_id,
          levelSetId: test.level_set.id,
          startedAt,
          endTime,
          startTime,
          submittedAt,
          totalAttempts: test.total_level_attempts,
        });
      });

      setClearedLevels([...passedLevels]);
      setAllTests(levelTests ?? []);
    } catch (err) {
      console.log(err);
      setHasError(true);
      setErrorMessage(err.response?.data?.message || err.message);
    } finally {
      setLoading(false);
    }
  };

  if (openTest) {
    return <DetailedTest selectedTest={selectedTest} />;
  }

  return (
    <>
      <LoadingScreen loading={loading} />
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      <HeadingText />
      <SplashScreen
        clearedLevels={clearedLevels}
        setSelectedLevel={setSelectedLevel}
        setOpenApologyModal={setOpenApologyModal}
        allTests={allTests}
        level={selectedLevel}
      />

      {openDrawer && (
        <LevelInfoDrawer
          setSelectedTest={setSelectedTest}
          setSelectedLevel={setSelectedLevel}
          setOpenTest={setOpenTest}
          selectedTest={selectedTest}
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          selectedLevel={selectedLevel}
          allTests={allTests}
        />
      )}

      {/* To be fixed. */}
      {/* <ResultModal
            openResultModal={openResultModal}
            codingTestData={codingTestData}
            setOpenResultModal={setOpenResultModal}
            codingTestAttemptData={codingTestAttemptData}
          /> */}
      <ApologyModal
        openApologyModal={openApologyModal}
        setOpenApologyModal={setOpenApologyModal}
      />
    </>
  );
};

export default TGPMainScreen;

import React from 'react';
import { Button } from '@mui/material';
import FeedbackIcon from '@mui/icons-material/Feedback';

const FeedbackButton = ({ onClick }) => {
  return (
    <Button
      onClick={onClick}
      variant='contained'
      startIcon={<FeedbackIcon />}
      sx={{
        position: 'fixed',
        bottom: 0,
        marginBottom: '4.1% !important',
        left: 0,
        marginLeft: '2%',
        backgroundColor: '#3b82f6',
        color: 'white',
        textTransform: 'none',
        borderRadius: '8px',
        padding: '8px 16px',
        width: '214',
        fontWeight: 700,
        '&:hover': {
          backgroundColor: '#2563eb',
        },
      }}
    >
      Feedback Form
    </Button>
  );
};

export default FeedbackButton;

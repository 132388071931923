import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sessions: {},
};

export const attendanceSlice = createSlice({
  name: 'attendance',
  initialState,
  reducers: {
    setSessions: (state, action) => {
      state.sessions = action.payload;
    },

    markAttendanceInState: (state, action) => {
      const { sessionId, studentId, status, data } = action.payload;
      const session = state.sessions[sessionId];
      const attendance = session.attendances[studentId];
      if (!attendance) {
        session.attendances[studentId] = {};
      }
      session.attendances[studentId].status = status;
      session.attendances[studentId].id = data.id;
    },
  },
});

export const { setSessions, markAttendanceInState } = attendanceSlice.actions;

export default attendanceSlice.reducer;

// @ts-nocheck
import { Stack, Typography, useTheme } from '@mui/material';
import React from 'react';

const QuestionDescription = ({ title = '', subTitle = '' }) => {
  const theme = useTheme();

  return (
    <Stack spacing={1}>
      <Typography
        variant='h4'
        component='h4'
        sx={{
          textWrap: 'pretty',
          fontFamily: 'Inter',
          [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
          },
        }}
      >
        {title}
      </Typography>

      {subTitle !== '' && (
        <Typography
          variant='h4'
          component='h4'
          sx={{
            textWrap: 'pretty',
            fontFamily: 'Inter',
            [theme.breakpoints.down('sm')]: {
              fontSize: '1rem',
            },
          }}
        >
          {subTitle}
        </Typography>
      )}
    </Stack>
  );
};

export default QuestionDescription;

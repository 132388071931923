import React from 'react'
import { Backdrop } from '@material-ui/core';
import CircularProgress from '@mui/material/CircularProgress';

function LoadingScreen({ loading }) {
  
  return (
    <Backdrop 
      sx={{ 
        color: '#fff', 
        zIndex: (theme) => theme.zIndex.drawer + 1 
      }} 
      open={loading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

export default LoadingScreen;
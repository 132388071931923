import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Stack, Button, TextField, Typography, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText } from '@material-ui/core';
import axios from 'axios';
import { format } from 'date-fns';
import SuccessNotifier from '../../ToastNotifications/SuccessNotifier';
import ErrorNotifier from '../../ToastNotifications/ErrorNotifier';
import { getHeaders, postDailyProblemUrl, deleteDailyProblemUrl, getDailyProblemUrl} from '../../../apis/urls';
import { getDatabaseFormattedDate, getHumanReadableDate } from '../../utils/common';
import TopicsDropdown from '../../../pages/TopicsDropdown';

const DailyProblem = () => {
  const [dailyProblems, setDailyProblems] = useState([]);
  const [openAddNewProblemModal, setOpenAddNewProblemModal] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const currentProgram = useSelector(state => state.programs.currentProgram);
  
  useEffect(() => {
    if (currentProgram) {
      fetchDailyProblems();
    }
    return () => {};
  }, [currentProgram]);

  const onSubmit = async (postBody) => {
    try {
      const res = await axios.post(postDailyProblemUrl(), postBody, {
        headers: getHeaders(currentProgram.programId)
      });
      setIsSuccess(true);
      setSuccessMessage("Problem has been added successfully");
      if (res.data) {
        setDailyProblems([...dailyProblems, res.data]);
      }
      setOpenAddNewProblemModal(false);
    } catch (error) {
      console.log(error)
      setHasError(true);
      setErrorMessage("Problem could not be added. Error:");
    }
  }
  
  async function fetchDailyProblems() {
    try {
      const res = await axios.get(getDailyProblemUrl(), { 
        headers: getHeaders(currentProgram.programId)
      });
      setDailyProblems(res.data ?? []);
    } catch (error) {
      console.log(error);
      setHasError(true);
      setErrorMessage(error.response?.data?.message || "Couldn't fetch the problems.");
    }
  }
  
  async function deleteProblem(problem) {
    try {
      const res = await axios.delete(deleteDailyProblemUrl(problem.id), { 
        headers: getHeaders(currentProgram.programId) 
      });
      setIsSuccess(true);
      setSuccessMessage("Problem deleted successfully");
      fetchDailyProblems();
    } catch (err) {
      console.log(err);
      setHasError(true);
      setErrorMessage(err.response?.data?.message || err.message);
    }
  }
  
  function renderProblem(problem) {
    return (
      <div>
        <Typography component="div" display="inline" margin="0px 10px" variant="h6">
        {getHumanReadableDate(new Date(problem.scheduled_for))}
        </Typography>
        <a href={problem.problem_link}>
          {problem.problem_link}
        </a>
        <Button variant="outlined" onClick={() => deleteProblem(problem)} style={{ margin: 10 }}
          disabled={problem.status === 'processed' || problem.status === 'partially_processed'}
        >
          Delete
        </Button>
      </div>
    );
  }
  
  function getDailyProblems() {
    return dailyProblems.map(problem => renderProblem(problem));   
  }

  return (
    <Stack spacing={2} sx={{m: 2}}>
      {isSuccess && <SuccessNotifier message={successMessage} setIsSuccess={setIsSuccess} /> }
      {hasError && <ErrorNotifier message={errorMessage} setHasError={setHasError} /> }
      <Box display="flex" justifyContent="flex-end">
        <Button variant="contained" onClick={(e) => setOpenAddNewProblemModal(true)}>
          Add daily problem
        </Button>
      </Box>
      {dailyProblems.length > 0 && 
        getDailyProblems()
      }
      {dailyProblems.length === 0 &&
        <Typography variant='body1' alignSelf="center">
          No daily problems found
        </Typography>
      }
      {currentProgram && openAddNewProblemModal &&
        <AddNewDailyProblem 
          {...{ 
            openAddNewProblemModal, 
            setOpenAddNewProblemModal, 
            setHasError, 
            setErrorMessage,
            onSubmit
          }}
        />
      }
    </Stack>
  );
}

export default DailyProblem;

function AddNewDailyProblem({ openAddNewProblemModal, setOpenAddNewProblemModal, onSubmit, setErrorMessage, setHasError }) {
  const [hasModalError, setHasModalError] = useState(false);
  const [modalErrorMessage, setModalErrorMessage] = useState("");
  const [problemLink, setProblemLink] = useState("");
  const [selectedTopicIds, setSelectedTopicIds] = useState([]);
  const [scheduledFor, setScheduledFor] = useState(getDatabaseFormattedDate(new Date()));
  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);

  useEffect(() => {
    if (hasModalError) {
      setTimeout(() => {
        setHasModalError(false);
      }, 7000);
    }
    return () => {};
  }, [hasModalError]);

  const handleSubmit = (e) => {
    const errorsList = [];
    if (!problemLink) {
      errorsList.push("problem link");
    }
    if (selectedTopicIds.length === 0) {
      errorsList.push("topics");
    }
    if (!scheduledFor || scheduledFor === "") {
      errorsList.push("scheduled for");
    }
    if (errorsList.length > 0) {
      const itemCount = errorsList.length;
      setHasModalError(true);
      setModalErrorMessage(`${errorsList.join(",")} ${itemCount === 1 ? "is" : "are"} mandatory field${itemCount === 1 ? "" : "s"}`);
      return;
    }
    const body = {
      problem_link: problemLink,
      scheduled_for: scheduledFor,
      topic_ids: selectedTopicIds
    }
    onSubmit(body);
  }

  return (
    <Dialog open={openAddNewProblemModal} onClose={() => setOpenAddNewProblemModal(false)} scroll="paper" fullWidth maxWidth="sm">
      <DialogActions>
        <Button onClick={() => setOpenAddNewProblemModal(false)}>Close</Button>
      </DialogActions>
      <DialogTitle>
        Send a new problem
      </DialogTitle>

      <DialogContent dividers>
        {hasModalError && 
          <Typography color="error" variant="body2">
            *{modalErrorMessage}
          </Typography>
        }
        <DialogContentText tabIndex={-1}>
          <Stack spacing={2}>
            <TextField
              label="Problem link"
              value={problemLink}
              onChange={(e) => setProblemLink(e.target.value)}
            />
            <TextField
              id="datetime-local"
              type="date"
              InputLabelProps={{ shrink: true }}
              onChange={(e) => { setScheduledFor(e.target.value) }}
              label="Scheduled for"
              InputProps={{ inputProps: { min: format(tomorrow, 'yyyy-MM-dd') } }}
              defaultValue={format(tomorrow, 'yyyy-MM-dd')}
            />
            <TopicsDropdown 
              {...{
                selectedTopicIds, setSelectedTopicIds, setErrorMessage, setHasError,
                sx: { m: 1, minWidth: 120 }
              }}
            />
            <Button variant="contained" onClick={handleSubmit}>
              Add daily problem
            </Button>
          </Stack>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}
import React, { useState } from 'react';
import { Box, RadioGroup, Chip, Radio } from '@mui/material';
import LearnerHome from './LearnerPlacementComponents/LearnerHome';
import LearnerJobs from './LearnerPlacementComponents/LearnerJobs';
import CompaniesInContact from './LearnerPlacementComponents/CompaniesInContact';
import ContactRequestRejected from './LearnerPlacementComponents/ContactRequestRejected';
import ContactDetailsRequested from './LearnerPlacementComponents/ContactDetailsRequested';

const LearnersPlacementsMainPage = () => {
  const [selectedFilter, setSelectedFilter] = useState('All Jobs');
  const filterOptions = [
    'All Jobs',
    'Details Requested',
    'Approved Requests',
    'Details Received',
    'Rejected Requests',
  ];

  const handleChangeFilter = (name) => {
    setSelectedFilter(name);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          padding: '30px 0 0 0',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <RadioGroup
            sx={{
              flexWrap: 'wrap',
              gap: 2,
              width: 'fit-content',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            {filterOptions.map((name) => {
              return (
                <Chip
                  key={name}
                  label={name}
                  onClick={() => handleChangeFilter(name)}
                  sx={{
                    fontSize: '18px',
                    border:
                      selectedFilter === name ? 'none' : '1px solid black',
                    color: selectedFilter === name ? 'white' : 'black',
                    backgroundColor:
                      selectedFilter === name ? '#1976D2' : 'white',
                    '&:hover': {
                      backgroundColor:
                        selectedFilter === name ? '#1976D2' : 'white',
                    },
                  }}
                >
                  <Radio
                    variant='outlined'
                    label={name}
                    value={name}
                    onChange={(event) => {
                      if (event.target.checked) {
                        setSelectedFilter(name);
                      }
                    }}
                    sx={{
                      color: 'black',
                    }}
                  />
                </Chip>
              );
            })}
          </RadioGroup>
        </Box>
      </Box>

      {selectedFilter === 'All Jobs' && (
        <LearnerHome selectedFilter={selectedFilter} />
      )}
      {selectedFilter === 'Details Requested' && (
        <ContactDetailsRequested selectedFilter={selectedFilter} />
      )}
      {selectedFilter === 'Approved Requests' && (
        <LearnerJobs selectedFilter={selectedFilter} />
      )}
      {selectedFilter === 'Details Received' && (
        <CompaniesInContact selectedFilter={selectedFilter} />
      )}
      {selectedFilter === 'Rejected Requests' && (
        <ContactRequestRejected selectedFilter={selectedFilter} />
      )}
    </Box>
  );
};

export default LearnersPlacementsMainPage;

import { getUserId, getAccessToken, getUserMail } from '../configs/auth';

export const BASE_URL =
  process.env.REACT_APP_BASE_URL || 'http://localhost:3000';

export const API_WS_ROOT =
  'wss://api.heycoach.in/cable' || 'ws://localhost:3000/cable';

export function loginUrl() {
  return `${BASE_URL}/api/login`;
}

export function fetchUserProfileUrl() {
  return `${BASE_URL}/api/users/${getUserId()}`;
}

export function fetchUserProfileFullUrl() {
  return `${BASE_URL}/api/users/${getUserId()}/profile`;
}

export function fetchProgramAssignmentsUrl() {
  return `${BASE_URL}/api/programs/assignments`;
}

export function fetchProgramSessionsUrl() {
  return `${BASE_URL}/api/programs/sessions`;
}

export function fetchFeedbackFormLink() {
  return `${BASE_URL}/api/programs/feedback_form`;
}

export function fetchLiveSessionLinks() {
  return `${BASE_URL}/api/programs/live_session_links`;
}

export function fetchUserPublicProfile(name) {
  return `${BASE_URL}/api/users/${name}/profile`;
}

export function postTermsAndConditions() {
  return `${BASE_URL}/api/users/${getUserId()}/terms-and-conditions`;
}

export function fetchTermsAndConditions() {
  return `${BASE_URL}/api/users/${getUserId()}/terms-and-conditions`;
}

export function fetchUserEnrollmentsUrl() {
  return `${BASE_URL}/api/users/${getUserId()}/enrollments`;
}

export function fetchProgramEnrollmentsUrl() {
  return `${BASE_URL}/api/enrollments`;
}

export function fetchAssignmentsUrl() {
  return `${BASE_URL}/api/assignments/`;
}

export function fetchAssignmentUrl(id) {
  return `${BASE_URL}/api/assignments/${id}`;
}

export function assignmentStatusUrl(id) {
  return `${BASE_URL}/api/assignments/${id}/status`;
}

export function submitAssignmentUrl(id) {
  return `${BASE_URL}/api/assignments/${id}/submit`;
}

export function fetchAssignmentReports(params) {
  return `${BASE_URL}/api/assignments/reports?${params}`;
}

export function fetchTopicsUrl() {
  return `${BASE_URL}/api/topics/`;
}

export function fetchTopicUrl(id) {
  return `${BASE_URL}/api/topics/${id}`;
}

export function fetchSessionsUrl() {
  return `${BASE_URL}/api/sessions`;
}

export function fetchSessionUrl(id) {
  return `${BASE_URL}/api/sessions/${id}`;
}

export function fetchProgramsUrl() {
  return `${BASE_URL}/api/programs`;
}

export function fetchProgramDetailsUrl(id) {
  return `${BASE_URL}/api/programs/${id}`;
}

export function updateProgramDetailsUrl(id) {
  return `${BASE_URL}/api/programs/${id}`;
}

export function fetchAttendanceStatus(id) {
  return `${BASE_URL}/api/sessions/${id}/status`;
}

export function fetchAttendanceUrl(id) {
  return `${BASE_URL}/api/sessions/${id}/mark`;
}

export function fetchAttendanceAbsentUrl(id) {
  return `${BASE_URL}/api/sessions/${id}/absent`;
}

export function fetchContentsUrl(id) {
  return `${BASE_URL}/api/topics/${id}/show_content`;
}

export function fetchContentUrl() {
  return `${BASE_URL}/api/contents`;
}

export function fetchSessionContentUrl(id) {
  return `${BASE_URL}/api/sessions/${id}/add_slides`;
}

export function fetchSessionLink(id) {
  return `${BASE_URL}/api/sessions/${id}/show_session_link`;
}

export function fetchSlidesData(presentation_id) {
  return `${BASE_URL}/api/contents/${presentation_id}/get_slides`;
}

export function fetchActiveStorageUrl() {
  return `${BASE_URL}/rails/active_storage/direct_uploads`;
}

export function fetchResourcesUrl() {
  return `${BASE_URL}/api/resources`;
}

export function deleteResourcesURL(id) {
  return `${BASE_URL}/api/resources/${id}`;
}

export function fetchAssignmentSubmissions(id) {
  return `${BASE_URL}/api/assignments/${id}/submissions`;
}

export function fetchImageURL(fileId) {
  return `${BASE_URL}/api/get_image?image_id=${fileId}`;
}
export function openS3URL(id) {
  return `${BASE_URL}/api/resources/${id}/resource_file`;
}

export function updateEnrollmentUrl(id) {
  return `${BASE_URL}/api/enrollments/${id}`;
}

export function deleteEnrollmentUrl(id) {
  return `${BASE_URL}/api/enrollments/${id}`;
}

export function UpdateSubmission(assignment_id, submissionid) {
  return `${BASE_URL}/api/assignments/${assignment_id}/submissions/${submissionid}/update_submission`;
}
export function fetchSessionRecording(id) {
  return `${BASE_URL}/api/session/${id}/recording`;
}

export function postDailyProblemUrl() {
  return `${BASE_URL}/api/dailyproblems`;
}

export function getDailyProblemUrl() {
  return `${BASE_URL}/api/dailyproblems`;
}

export function deleteDailyProblemUrl(problemId) {
  return `${BASE_URL}/api/dailyproblems/${problemId}`;
}

export function fetchLearnersOnFilters(queryParams = []) {
  return `${BASE_URL}/api/learner_search?${queryParams.join('&')}`;
}

export function getHeaders(programId = '') {
  return {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
    'Access-Token': getAccessToken(),
    'X-Program-Id': programId,
  };
}

export function postMindmapQuestionUrl() {
  return `${BASE_URL}/api/mindmap/question`;
}

export function fetchMindMapActivities(id) {
  return `${BASE_URL}/api/mindmap_activities?category_id=${id}`;
}

export function fetchMindMapCompletedProblems() {
  return `${BASE_URL}/api/mindmap_activities/completed_problems`;
}

export function postMindMapMarkedasCompleted() {
  return `${BASE_URL}/api/mindmap_activities`;
}

export function fetchMindMapTopicUrl() {
  return `${BASE_URL}/api/mindmap/topic`;
}
export function fetchMindMapTopicData(topic) {
  return `${BASE_URL}/api/mindmap/topic?topic_name=${topic}`;
}

export function fetchDsaVisualizer(id) {
  return `${BASE_URL}/api/dsa_visualizer?topic_id=${id}`;
}

export function postDsaVisualizerMarkedasCompleted(id) {
  return `${BASE_URL}/api/dsa_visualizer`;
}

export function fetchTechniqueSaga() {
  return `${BASE_URL}/api/technique_saga`;
}

export function postTechniqueSagaCompleted() {
  return `${BASE_URL}/api/technique_saga`;
}

export function postCodeRunURL() {
  return `${BASE_URL}/api/code/run`;
}

export function fetchCodingProblemsURL(queryParams) {
  return `${BASE_URL}/api/code_problems?${queryParams.join('&')}`;
}

export function postCodeProblemURL() {
  return `${BASE_URL}/api/code_problems`;
}

export function postCodeSubmitURL(problemId) {
  return `${BASE_URL}/api/code_problems/${problemId}/submit`;
}

export function postCodeProblemTestcaseURL(problemId) {
  return `${BASE_URL}/api/code_problems/${problemId}/testcases`;
}

export function deleteCodeProblemTestcaseURL(problemId, testcaseId) {
  return `${BASE_URL}/api/code_problems/${problemId}/testcases/${testcaseId}`;
}

export function updateCodeProblemURL(problemId) {
  return `${BASE_URL}/api/code_problems/${problemId}`;
}

export function fetchCodeProblemDetailsURL(problemId) {
  return `${BASE_URL}/api/code_problems/${problemId}`;
}

export function postCodeProblemSolution(problemId) {
  return `${BASE_URL}/api/code_problems/${problemId}/solutions`;
}

export function updateCodeProblemSolution(problemId) {
  return `${BASE_URL}/api/code_problems/${problemId}/solutions`;
}

export function fetchCodeProblemSubmissionsURL(problemId) {
  return `${BASE_URL}/api/code_problems/${problemId}/submissions`;
}

export function fetchAllCodingTestsURL() {
  return `${BASE_URL}/api/coding_tests`;
}
export function fetchAllLevelTestsURL() {
  return `${BASE_URL}/api/level_tests`;
}

export function postCodingTestURL() {
  return `${BASE_URL}/api/coding_tests`;
}

export function fetchCodingTestProblemsURL(testId) {
  return `${BASE_URL}/api/coding_tests/${testId}/problems`;
}

export function fetchUpdateCodingTestURL(testId) {
  return `${BASE_URL}/api/coding_tests/${testId}`;
}

export function fetchDeleteCodingTestURL(testId) {
  return `${BASE_URL}/api/coding_tests/${testId}`;
}

export function fetchCodingTestJoinNowURL(testId) {
  return `${BASE_URL}/api/coding_tests/${testId}/attempts`;
}

export function fetchCodingTestSubmitURL(testId) {
  return `${BASE_URL}/api/coding_tests/${testId}/submit`;
}

export function postCodingTestProblemURL(testId) {
  return `${BASE_URL}/api/coding_tests/${testId}/problems`;
}

export function deleteCodingTestProblemURL(testId, problemId) {
  return `${BASE_URL}/api/coding_tests/${testId}/problems/${problemId}`;
}

export function fetchCodingTestProblemSubmissionsURL(testId, problemId) {
  return `${BASE_URL}/api/coding_tests/${testId}/problems/${problemId}/submissions`;
}

export function fetchCodingTestProblemAttemptStatusURL(attemptId) {
  return `${BASE_URL}/api/coding_tests_problems_attempts/${attemptId}/testcase_executions?status=1`;
}

export function fetchFirstFailedTestcaseURL(attemptId) {
  return `${BASE_URL}/api/coding_tests_problems_attempts/${attemptId}/first_failed_testcase`;
}

export function postCodingTestProblemSolutionURL(testId, problemId) {
  return `${BASE_URL}/api/coding_tests/${testId}/problems/${problemId}/submit`;
}

export function fetchAllEnrollmentsLevelsURL(queryParams = '') {
  return `${BASE_URL}/api/enrollments_levels${queryParams !== '' ? `${queryParams}` : ''
    }`;
}

export function fetchSingleEnrollmentsLevelURL(enrollmentsLevelId) {
  return `${BASE_URL}/api/enrollments_levels/${enrollmentsLevelId}`;
}

export function postEnrollmentsLevelURL() {
  return `${BASE_URL}/api/enrollments_levels`;
}

export function deleteEnrollmentsLevelURL(enrollmentsLevelId) {
  return `${BASE_URL}/api/enrollments_levels/${enrollmentsLevelId}`;
}

export function updateEnrollmentsLevelURL(enrollmentsLevelId) {
  return `${BASE_URL}/api/enrollments_levels/${enrollmentsLevelId}`;
}

export function postNewEnrollmentURL() {
  return `${BASE_URL}/api/enrollments`;
}

export function requestMockInterviewURL(userId) {
  return `${BASE_URL}/api/users/${userId}/request_mock_interview`;
}

export function fetchAllLevelsURL() {
  return `${BASE_URL}/api/levels`;
}

export function fetchSingleLevelURL(levelId) {
  return `${BASE_URL}/api/levels/${levelId}`;
}

export function postLevelURL() {
  return `${BASE_URL}/api/levels`;
}

export function deleteLevelURL(levelId) {
  return `${BASE_URL}/api/levels/${levelId}`;
}

export function postAttendanceURL(sessionID) {
  return `${BASE_URL}/api/sessions/${sessionID}/attendances`;
}

export function updateAttendanceURL(sessionId, attendanceId) {
  return `${BASE_URL}/api/sessions/${sessionId}/attendances/${attendanceId}`;
}

export function deleteAttendanceURL(attendanceId) {
  return `${BASE_URL}/api/attendances/${attendanceId}`;
}

export function fetchLearnerProgressURL(userId) {
  return `${BASE_URL}/api/users/${userId}/progress`;
}

export function updateSessionURL(sessId) {
  return `${BASE_URL}/api/sessions/${sessId}`;
}

export function fetchProgramTasksURL() {
  return `${BASE_URL}/api/programs/tasks`;
}

export function postTaskURL() {
  return `${BASE_URL}/api/tasks`;
}

export function updateTaskURL(taskId) {
  return `${BASE_URL}/api/tasks/${taskId}`;
}

export function deleteTaskURL(taskId) {
  return `${BASE_URL}/api/tasks/${taskId}`;
}

export function postTaskCommentURL() {
  return `${BASE_URL}/api/task_comments`;
}

export function updateTaskCommentURL(taskCommentId) {
  return `${BASE_URL}/api/task_comments/${taskCommentId}`;
}

export function fetchTaskCommentsURL(taskId) {
  return `${BASE_URL}/api/tasks/${taskId}/comments`;
}

export function fetchCoachSessionsURL(userId) {
  return `${BASE_URL}/api/users/${userId}/sessions`;
}

export function postCoachPaymentURL() {
  return `${BASE_URL}/api/coach_payments`;
}

export function fetchCoachFinanceDetails(userId) {
  return `${BASE_URL}/api/users/${userId}/finances`;
}

export function fetchAllUsersURL() {
  return `${BASE_URL}/api/users`;
}

export function fetchProgramResourcesURL() {
  return `${BASE_URL}/api/programs/resources`;
}

export function fetchAllSessionTypesURL() {
  return `${BASE_URL}/api/session_types`;
}

export function postSessionURL() {
  return `${BASE_URL}/api/sessions`;
}

export function fetchAllCoachesFinanceDetails() {
  return `${BASE_URL}/api/users/finances`;
}

export function fetchAllCodeSkeletonsOfAProblem(problemId) {
  return `${BASE_URL}/api/code_problems/${problemId}/code_skeletons`;
}

export function postCodeSkeleton() {
  return `${BASE_URL}/api/code_skeletons`;
}

export function updateCodeSkeleton(skeletonId) {
  return `${BASE_URL}/api/code_skeletons/${skeletonId}`;
}

export function fetchProgramReports(programId, from, to) {
  return `${BASE_URL}/api/reports?program_id=${programId}&from=${from}&to=${to}`;
}

export function calculateGoogleMeetAttendanceStatus() {
  return `${BASE_URL}/api/attendances/calculate/gmeet`;
}

export function postBulkAttendance() {
  return `${BASE_URL}/api/attendances/bulk/`;
}

export function fetchUserReceipt() {
  return `${BASE_URL}/api/users/receipt`;
}

export function postReceipt() {
  return `${BASE_URL}/api/receipts`;
}

export function updateReceipt(receiptId) {
  return `${BASE_URL}/api/receipts/${receiptId}`;
}

export function fetchAllInteractionsWithUser(enrollmentId) {
  return `${BASE_URL}/api/enrollments/${enrollmentId}/interactions`;
}

export function postInteractionUrl() {
  return `${BASE_URL}/api/interactions`;
}

export function postBulkSessionsUrl() {
  return `${BASE_URL}/api/sessions/bulk-create`;
}

export function rescheduleSessionUrl(id) {
  return `${BASE_URL}/api/sessions/${id}/reschedule`;
}

export function postProgramFeedback() {
  return `${BASE_URL}/api/program-feedback`;
}

export function getLastResponseTime() {
  return `${BASE_URL}/api/last-feedback`;
}

export function getActiveForm() {
  return `${BASE_URL}/api/eligibility-forms/active`;
}

export function getActiveLetter() {
  return `${BASE_URL}/api/offer-letters/active`;
}

export function getForm(form_id) {
  return `${BASE_URL}/api/eligibility-forms/${form_id}`;
}

export function postEligibilityForm(form_id) {
  return `${BASE_URL}/api/eligibility-forms/${form_id}/submit`;
}

export function postLevelTest() {
  return `${BASE_URL}/api/level-tests`;
}

export function getLevelTestAttempts() {
  return `${BASE_URL}/api/level_tests_attempts`;
}

export function getQuizzes(queryParams = []) {
  return `${BASE_URL}/api/quizzes?${queryParams.join('&')}`;
}

export function fetchQuizDetails(quiz_id) {
  return `${BASE_URL}/api/quizzes/${quiz_id}`;
}

export function postQuizQuestion() {
  return `${BASE_URL}/api/quiz_questions`;
}

export function patchQuizQuestion(question_id) {
  return `${BASE_URL}/api/quiz_questions/${question_id}`;
}

export function createQuizAttempt() {
  return `${BASE_URL}/api/quiz_attempts`;
}

export function fetchQuizAttempts(queryParams = '') {
  return `${BASE_URL}/api/quiz_attempts?${queryParams.join('&')}`;
}
export function fetchQuizzesLearners() {
  return `${BASE_URL}/api/enrollments/quizzes`;
}

export function fetchQuizJoinNowURL(attemptId) {
  return `${BASE_URL}/api/quiz_attempts/${attemptId}/attempt`;
}

export function fetchAndsubmitQuizAttemptURL(attemptId) {
  return `${BASE_URL}/api/quiz_attempts/${attemptId}`;
}

export function submitQuizQuestionAttemptURL() {
  return `${BASE_URL}/api/quiz_question_attempts`;
}

export function fetchChatList(queryParams = '') {
  return `${BASE_URL}/api/chats?${queryParams}`;
}

export function sendMessage() {
  return `${BASE_URL}/api/chat_messages`;
}

export function editMessage(messageId) {
  return `${BASE_URL}/api/chat_messages/${messageId}`;
}

export function deleteMessage(messageId) {
  return `${BASE_URL}/api/chat_messages/${messageId}`;
}

export function archiveOrUnarchive(chatId) {
  return `${BASE_URL}/api/chats/${chatId}/archive_or_unarchive`;
}

export function chatBulkArchive() {
  return `${BASE_URL}/api/chats/bulk_archive`;
}

export function markReadOrUnread(chatId) {
  return `${BASE_URL}/api/chats/${chatId}/mark_as_read_or_unread`;
}

export function markMessagesAsRead() {
  return `${BASE_URL}/api/chat_message_read_receipts`;
}

export function getChatMessages(chatId) {
  return `${BASE_URL}/api/chats/${chatId}/chat_messages`;
}

export function fetchChatUsersUrl() {
  return `${BASE_URL}/api/chat_users`;
}

export function sendMessageProgramsUrl() {
  return `${BASE_URL}/api/programs/chat_messages`;
}

export function sendMessageLearnersUrl() {
  return `${BASE_URL}/api/enrollments/chat_messages`;
}

export function createOrFetchLabel() {
  return `${BASE_URL}/api/labels`;
}

export function getChatLabels(id) {
  return `${BASE_URL}/api/chats/${id}/labels`;
}

export function updateChatLabels(id) {
  return `${BASE_URL}/api/chats/${id}/chat_labels`;
}

export function showChat() {
  return `${BASE_URL}/api/users/show_chat`;
}

export function sendEmailLearnerUrl(messageId) {
  return `${BASE_URL}/api/chat_messages/${messageId}/notify_by_email`;
}

export function postChatReaction() {
  return `${BASE_URL}/api/chat_message_reactions`;
}

export function deteleReaction(id) {
  return `${BASE_URL}/api/chat_message_reactions/${id}`;
}

export function getExperience() {
  return `${BASE_URL}/api/user_experiences/`;
}

export function deleteExperience(id) {
  return `${BASE_URL}/api/user_experiences/${id}`;
}

export function getEducation() {
  return `${BASE_URL}/api/user_educations/`;
}

export function deleteEducation(id) {
  return `${BASE_URL}/api/user_educations/${id}`;
}

export function getUserLinks() {
  return `${BASE_URL}/api/user_links/`;
}

export function deleteUserLinks(id) {
  return `${BASE_URL}/api/user_links/${id}`;
}

export function addCompany() {
  return `${BASE_URL}/api/companies`;
}

export function fetchAllCompanies() {
  return `${BASE_URL}/api/companies`;
}

export function addCompanyRole() {
  return `${BASE_URL}/api/company_roles`;
}

export function fetchAllCompanyRole() {
  return `${BASE_URL}/api/company_roles`;
}

export function bulkUpdateCompanyRoles() {
  return `${BASE_URL}/api/company_roles/bulk_status_update`;
}

export function fetchAllTechStacks() {
  return `${BASE_URL}/api/tech_stacks`;
}

export function addLearnerToPlacementPool() {
  return `${BASE_URL}/api/placement_pools`;
}

export function fetchPlacementPool() {
  return `${BASE_URL}/api/placement_pools`;
}

export function getUserThroughEmail(email) {
  return `${BASE_URL}/api/placement_pools/users?email=${email}`;
}

export function updatePlacementPoolAction() {
  return `${BASE_URL}/api/placement_pools/update_actions`;
}

export function addPlacementRequests() {
  return `${BASE_URL}/api/placement_requests`;
}

export function fetchAllPlacementRequests() {
  return `${BASE_URL}/api/placement_requests`;
}

export function updatePlacementRequestStatus() {
  return `${BASE_URL}/api/placement_requests/bulk_update`;
}

export function fetchAllRoleTechStacks() {
  return `${BASE_URL}/api/role_tech_stacks`;
}

export function fetchLearnerOverviewURL(userId) {
  return `${BASE_URL}/api/users/${userId}/learner_progress_details`;
}

export function fetchLearnerSessionDetailsURL(userId) {
  return `${BASE_URL}/api/users/${userId}/learner_session_details`;
}
export function fetchLearnerDailyProgressURL(userId) {
  return `${BASE_URL}/api/users/${userId}/learner_daily_progress`;
}

export function addCodingTestData() {
  return `${BASE_URL}/api/the_great_pyramid/add_coding_test`;
}

export function getCodingTestAttemptData() {
  return `${BASE_URL}/api/the_great_pyramid/get_coding_test_attempts`;
}

export function addCodingTestAttemptData() {
  return `${BASE_URL}/api/the_great_pyramid/add_coding_test_attempts`;
}

export function markAsCompleted(id) {
  return `${BASE_URL}/api/resources/${id}/mark_as_completed`;
}

export function fetchProgramCodingAssignmentsUrl() {
  return `${BASE_URL}/api/programs/session_assignments`;
}

export function fetchSessionTests() {
  return `${BASE_URL}/api/session_tests`;
}

export function ticketUrl(queryParams, page = 1, pageSize = 20) {
  const offset = (page - 1) * pageSize;
  return `${BASE_URL}/api/ticket?offset=${offset}&limit=${pageSize}&${queryParams}`;
}

export function updateTicketStatus(ticket_id) {
  return `${BASE_URL}/api/ticket/update-status/${ticket_id}`;
}

export function updateTicketInteraction(ticket_id) {
  return `${BASE_URL}/api/ticket/update-interaction/${ticket_id}`;
}

export function ticketsOverview() {
  return `${BASE_URL}/api/ticket/ticket-overview`;
}

export function allAssignees() {
  return `${BASE_URL}/api/ticket/assignees`;
}

export function updateAssignee(ticketId) {
  return `${BASE_URL}/api/ticket/${ticketId}/ticket_assignment`;
}

export function getLearnerTickets(id) {
  return `${BASE_URL}/api/enrollments/${id}/learner_tickets`;
}

export function getLearnerFeedbacks(id) {
  return `${BASE_URL}/api/enrollments/${id}/learner_feedbacks`;
}

export function getCoupons() {
  return `${BASE_URL}/api/coupons`;
}

export function scratchCoupon(couponId) {
  return `${BASE_URL}/api/coupons/${couponId}/scratch`;
}

export function focusModeLearners() {
  return `${BASE_URL}/api/learner_search/focus_mode`;
}

export function activityLogs() {
  return `${BASE_URL}/api/activity_logs`;
}

export function mentorSessionsURL() {
  return `${BASE_URL}/api/mentor_sessions`;
}

export function updateMentorSessions(mentor_session_id) {
  return `${BASE_URL}/api/mentor_sessions/${mentor_session_id}`;
}

export function referralURL() {
  return `${BASE_URL}/api/referrals`;
}

export function escalationURL() {
  return `${BASE_URL}/api/escalations`;
}

export function calendlyURLs() {
  return {
    careerCounselling: "https://calendly.com/teamheycoach/careercounselling?back=1&month=2024-09",
    onboarding: "https://calendly.com/heycoach_admission_team/30min?month=2024-08"
  }
}

export function leadgenURL() {
  return `${BASE_URL}/api/lead_register`
}

export function leadUpdateURL() {
  return `${BASE_URL}/api/lead_update`
}

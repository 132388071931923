import React, { useState } from 'react';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
} from '@material-ui/core';
import { Box } from '@mui/material';
import moment from 'moment';
import axios from 'axios';
import { mentorSessionsURL, getHeaders } from 'src/apis/urls';

export default function NewMentorSessionModal({
  setOpenNewMentorSessionModal,
  openNewMentorSessionModal,
  learners,
  currentProgram,
  setIsSuccess,
  setSuccessMessage,
  setErrorMessage,
  setHasError,
}) {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const [modalData, setModalData] = useState({
    title: '',
    start_time: '',
    learner_id: '',
    agenda: '',
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const body = { ...modalData, program_id: currentProgram.programId };
    body.start_time = moment(body.start_time).format('YYYY-MM-MMTHH:mm');
    if (body.title === '') {
      setHasError(true);
      setErrorMessage('Title cannot be empty.');
      return;
    }
    if (body.start_time === '') {
      setHasError(true);
      setErrorMessage('Start Time cannot be empty.');
      return;
    }
    if (body.learner_id === '') {
      setHasError(true);
      setErrorMessage('Learner cannot be empty.');
      return;
    }

    axios
      .post(mentorSessionsURL(), body, {
        headers: getHeaders(currentProgram.programId),
      })
      .then((res) => {
        setIsSuccess(true);
        setSuccessMessage('Mentor Session was created successfully.');
        setOpenNewMentorSessionModal(false);
      })
      .catch((err) => {
        console.log(err);
        setOpenNewMentorSessionModal(false);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || 'Could not create the mentor session.'
        );
      });
  };

  const handleClose = () => {
    setOpenNewMentorSessionModal(false);
  };

  const getCurrentDateTime = () => {
    const now = moment().format('YYYY-MM-DDTHH:mm');
    return now;
  };

  return (
    <Modal
      open={openNewMentorSessionModal}
      onClose={handleClose}
      style={{ overflow: 'scroll' }}
    >
      <form onSubmit={handleSubmit}>
        <Box sx={style}>
          <Stack spacing={3}>
            <TextField
              fullWidth
              id='title'
              label='Title'
              name='title'
              variant='outlined'
              value={modalData.title}
              onChange={(e) =>
                setModalData({ ...modalData, title: e.target.value })
              }
              required
            />
            <TextField
              fullWidth
              id='start-time'
              label='Start Time in IST'
              type='datetime-local'
              name='start_time'
              variant='outlined'
              InputLabelProps={{ shrink: true }}
              value={modalData?.start_time}
              onChange={(e) =>
                setModalData({ ...modalData, start_time: e.target.value })
              }
              required
              inputProps={{
                min: moment().format('YYYY-MM-DDTHH:mm'),
              }}
            />
            <TextField
              fullWidth
              id='agenda'
              label='Agenda of Session'
              name='Flow'
              variant='outlined'
              multiline
              rows={4}
              value={modalData.agenda}
              onChange={(e) =>
                setModalData({ ...modalData, agenda: e.target.value })
              }
              required
            />
            <LearnerNameDropdown
              {...{
                learners,
                modalData,
                setModalData,
                sx: { width: '100%', height: 40, mr: 1 },
              }}
            />
            <Button
              variant='contained'
              type='submit'
            >
              Add Session
            </Button>
          </Stack>
        </Box>
      </form>
    </Modal>
  );
}

function LearnerNameDropdown({ learners, modalData, setModalData, sx }) {
  const handleChange = (event) => {
    const selectedValues = event.target.value;
    setModalData({
      ...modalData,
      learner_id: selectedValues,
    });
  };

  return (
    <>
      <FormControl sx={sx}>
        <InputLabel id='group-name'>Select Learner</InputLabel>
        <Select
          required
          labelId='learner-name'
          id='learner-name'
          value={modalData.learner_id}
          label='Select Learners'
          onChange={handleChange}
          required
        >
          {learners.map((item) => (
            <MenuItem
              key={item.id}
              value={item.id}
            >
              {item.name} ({item.email})
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Typography,
  Box,
  Button,
  IconButton,
  Tooltip,
  Chip,
  Stack,
} from '@material-ui/core';
import DeleteOutlined from '@iconify/icons-ant-design/delete-outlined';
import axios from 'axios';
import tw from 'twin.macro';
import { Link, AttachFileSharp } from '@material-ui/icons';
import { Icon } from '@iconify/react';
import { styled } from '@material-ui/core/styles';
import { useSessionContext } from './SessionContext';
import { openS3URL, getHeaders, deleteResourcesURL } from '../../apis/urls';
import { CopyIcon } from '../utils/common';
import { getUserType } from '../../configs/auth';

const YOUTUBE_BASE_URL = 'https://www.youtube.com';
const TextC = tw.div`ml-4`;
const ResH5 = tw.h5`text-base mb-2`;
const PrimaryBtn = tw.a`rounded-lg px-3 py-1 text-sm  mt-2 text-sm sm:text-base  font-bold shadow transition duration-300 bg-blue-500 text-gray-100 hocus:bg-blue-700 hocus:text-gray-200 focus:outline-none focus:shadow-inner`;
const DeleteButton = styled('div')(() => ({ color: 'red', cursor: 'pointer' }));

function ReadingLink({ link, title }) {
  return (
    <TextC>
      <ResH5>{title}</ResH5>
      <br />
      <a
        href={link}
        target='_blank'
        rel='noopener noreferrer'
      >
        {link}
      </a>
      {CopyIcon(link)}
    </TextC>
  );
}

function YoutubeLink({ link, title }) {
  const embedId = link.split('watch?v=')[1];
  return (
    <TextC
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '0.5rem',
        borderRadius: '0.8rem',
        marginTop: '0.5rem',
      }}
    >
      <iframe
        width='350'
        height='200'
        src={`${YOUTUBE_BASE_URL}/embed/${embedId}`}
        frameBorder='0'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        allowFullScreen
        title={title}
        style={{ borderRadius: '0.8rem' }}
      />
      <ResH5 style={{ paddingLeft: '1rem' }}>{title}</ResH5>
    </TextC>
  );
}

function ReadingMaterial({
  setIsSuccess,
  setHasError,
  setErrorMessage,
  setSuccessMessage,
  getSessionResources,
}) {
  const { materialResource, setMaterialResource } = useSessionContext();
  const currentProgram = useSelector((state) => state.programs.currentProgram);
  const userType = getUserType();
  const isCoach = userType === 'coach';

  const handleDeleteClick = (e, id) => {
    e.stopPropagation();
    const deletResource = async () => {
      try {
        const { data } = await axios.delete(deleteResourcesURL(id), {
          headers: getHeaders(currentProgram?.programId),
        });
        getSessionResources();
        setIsSuccess(true);
        setSuccessMessage('Resource successfuly deleted');
      } catch (err) {
        console.log(err);
        setHasError(true);
        setErrorMessage(err.response?.data?.message || err.message);
      }
    };
    deletResource();
  };

  return (
    <>
      {materialResource.length === 0 && (
        <Typography
          variant='h5'
          component='h2'
          display='flex'
          justifyContent='center'
        >
          No pre-reads have been added
        </Typography>
      )}
      {materialResource &&
        materialResource.map((reading, index) => {
          if (reading.resource_context === 'session_recording') {
            return <></>;
          }

          return (
            reading.resource_type === 'link' && (
              <Box
                display='flex'
                alignItems='center'
                sx={{
                  padding: '0.5rem',
                  border: '1px solid rgb(202, 194, 194)',
                  borderRadius: '0.8rem',
                  marginTop: '0.5rem',
                }}
              >
                <Tooltip title='Link'>
                  <IconButton>
                    <Link fontSize='medium' />
                  </IconButton>
                </Tooltip>
                <Box>
                  {reading.link.includes(YOUTUBE_BASE_URL) ? (
                    <YoutubeLink
                      key={index + 1}
                      link={reading.link}
                      title={reading.title}
                    />
                  ) : (
                    <ReadingLink
                      key={index + 1}
                      link={reading.link}
                      title={reading.title}
                    />
                  )}
                  {isCoach && (
                    <Tooltip
                      title='Delete'
                      aria-label='delete'
                      sx={{ float: 'right' }}
                    >
                      <DeleteButton
                        onClick={(e) => handleDeleteClick(e, reading.id)}
                      >
                        <Icon
                          icon={DeleteOutlined}
                          width='20'
                          height='20'
                        />
                      </DeleteButton>
                    </Tooltip>
                  )}
                </Box>
              </Box>
            )
          );
        })}

      {materialResource &&
        materialResource.map((reading) => {
          if (reading.resource_context === 'session_recording') {
            return <></>;
          }
          return (
            reading.resource_type === 'file' && (
              <URLOpener
                key={reading.id}
                reading={reading}
                handleDeleteClick={handleDeleteClick}
                programId={currentProgram?.programId}
              />
            )
          );
        })}
    </>
  );
}

function URLOpener({ reading, handleDeleteClick, programId }) {
  const [resourceURL, setResourceURL] = useState(null);
  const userType = getUserType();
  const isCoach = userType === 'coach';

  useEffect(() => {
    async function fileHandler(id) {
      try {
        const { data } = await axios.get(openS3URL(id), {
          headers: getHeaders(programId),
        });
        setResourceURL(data.file);
      } catch (err) {
        console.log(err);
      }
    }
    fileHandler(reading.id);
  }, []);

  return (
    resourceURL && (
      <Box
        display='flex'
        alignItems='center'
        sx={{
          padding: '0.5rem',
          border: '1px solid rgb(202, 194, 194)',
          borderRadius: '0.8rem',
          marginTop: '0.5rem',
        }}
      >
        <Tooltip title='File'>
          <IconButton>
            <AttachFileSharp fontSize='medium' />
          </IconButton>
        </Tooltip>
        <TextC>
          <ResH5>{reading.title}</ResH5>
          <div style={{ marginBottom: '8px' }}>
            {reading.topics &&
              reading.topics.map((topic) => {
                return (
                  <span
                    key={topic.id}
                    style={{ marginRight: '5px' }}
                  >
                    <Chip
                      variant='outlined'
                      label={topic.name}
                    />
                  </span>
                );
              })}
          </div>
          {resourceURL && (
            <PrimaryBtn
              href={resourceURL}
              target='_blank'
              rel='noopener noreferrer'
              style={{
                textDecoration: 'none',
                width: 'auto',
                height: 'auto',
                marginTop: '1px',
              }}
            >
              view
            </PrimaryBtn>
          )}
        </TextC>
        {isCoach && (
          <Tooltip
            title='Delete'
            aria-label='delete'
          >
            <DeleteButton onClick={(e) => handleDeleteClick(e, reading.id)}>
              <Icon
                icon={DeleteOutlined}
                width='20'
                height='20'
              />
            </DeleteButton>
          </Tooltip>
        )}
      </Box>
    )
  );
}

export default ReadingMaterial;

// @ts-nocheck
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { BASE_URL, getHeaders } from '../../../apis/urls';
import CodingAssignmentsTable from './CodingAssignmentsTable';
import ErrorNotifier from '../../ToastNotifications/ErrorNotifier';
import SelectedCodingAssignment from './SelectedCodingAssignment';

const initialState = {
  status: 'pending',
};

const CodingAssignments = ({ category = '' }) => {
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState(initialState);
  const [assignments, setAssignments] = useState([]);
  const [selectedAssignment, setSelectedAssignment] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const currentProgram = useSelector((state) => state.programs.currentProgram);

  useEffect(() => {
    fetchCodingAssignments();
    return () => {};
  }, [filters.status]);

  const fetchCodingAssignments = () => {
    const params = [];
    Object.keys(filters).forEach((key) => {
      params.push(`${key}=${filters[key]}`);
    });

    if (category !== "") {
     params.push(`category=${category}`);
    }

    const url = `${BASE_URL}/api/programs/coding_assignments?${params.join(
      '&'
    )}`;

    setLoading(true);
    axios
      .get(url, {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((res) => {
        setAssignments(res.data ?? []);
      })
      .catch((err) => {
        console.log(err.response);
        setHasError(true);
        setErrorMessage(
          err?.response?.data?.message ||
            'An error occured while fetching the coding assignments.'
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <Stack
      spacing={2}
      padding={1}
    >
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}

      {selectedAssignment === null && (
        <>
          <Box
            display='flex'
            justifyContent='flex-end'
          >
            <FormControl
              size='small'
              sx={{
                width: 'fit-content',
                ml: '0.3rem',
                mr: '0.3rem',
              }}
            >
              <InputLabel id='assignment-status'>Status</InputLabel>
              <Select
                labelId='assignment-status'
                id='assignment-status'
                name='status'
                value={filters.status}
                label='Status'
                onChange={handleChange}
              >
                <MenuItem value='pending'>Pending</MenuItem>
                <MenuItem value='attempted'>Attempted</MenuItem>
                <MenuItem value='completed'>Completed</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <CodingAssignmentsTable
            assignments={assignments}
            loading={loading}
            setSelectedAssignment={setSelectedAssignment}
            filters={filters}
          />
        </>
      )}

      {selectedAssignment !== null && (
        <SelectedCodingAssignment
          selectedAssignment={selectedAssignment}
          setSelectedAssignment={setSelectedAssignment}
        />
      )}
    </Stack>
  );
};

export default CodingAssignments;

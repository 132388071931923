import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box } from '@material-ui/system';
import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

export default function PracticeTab() {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const v = tabsList[value].toLowerCase().split(' ').join('_');
    navigate(`${v}`);
    return () => {};
  }, [value]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant='scrollable'
          scrollButtons='auto'
        >
          <Tab label='Tasks' />
          <Tab label='Assignments' />
        </Tabs>
      </Box>
      <Outlet />
    </Box>
  );
}

const tabsList = ['Tasks', 'Assignments', 'Chats'];

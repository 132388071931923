import { Tab } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { Box } from '@material-ui/system';
import React, { useEffect, useState } from 'react';
import StudentResources from './StudentResources';
import SessionRecording from './SessionRecording';

const SingleSession = ({
  sessionData,
  getSessionResources,
  sessionChanged,
  setSessionChanged,
}) => {
  const [currTab, setCurrTab] = useState('1');

  useEffect(() => {
    if (sessionChanged) {
      setCurrTab('1');
      setSessionChanged(false);
    }
    return () => {};
  }, [sessionChanged]);

  const handleChange = (event, newCurrTab) => {
    setCurrTab(newCurrTab);
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1', paddingTop: '35px' }}>
      <TabContext value={currTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList
            onChange={handleChange}
            variant='fullWidth'
          >
            <Tab
              label='Resources'
              value='1'
            />
            <Tab
              label='Recordings'
              value='2'
            />
          </TabList>
        </Box>
        <TabPanel value='1'>
          <StudentResources
            sessionData={sessionData}
            getSessionResources={getSessionResources}
          />
        </TabPanel>
        <TabPanel value='2'>
          <SessionRecording />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default SingleSession;

import React from 'react';
import { useState, useEffect } from 'react';
import OnboardingPopup from './OnboardingPopup';
import Admission from './admission/Admission';

const OnboardingGettingStarted = () => {
  return (
    <div>
      <OnboardingPopup />
      <Admission />
    </div>
  );
};

export default OnboardingGettingStarted;

import React, { useEffect, useState } from 'react';
import path from '../../images/technique_saga.png';
import LevelOne from '../../images/unlock.png';
import LevelTwo from '../../images/LevelTwo.png';
import LevelThree from '../../images/Technique_3_1.png';
import LevelFour from '../../images/Technique_4.png';
import lock from '../../images/lock.png';
import { Box, Typography } from '@material-ui/core';
import Button from '@mui/material/Button';
import '../../tooltip.css';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { fetchTechniqueSaga, getHeaders } from 'src/apis/urls';
import { mechanismTopics } from '../utils/techniqueSagaTopics';
import BitManipulation from 'src/pages/BitManipulation';
import TwoPointerTechnique from 'src/pages/TwoPointerTechnique';
import Recursion from 'src/pages/Recursion';
import { shouldShowNewStudentLayout } from '../utils/common';
import DivideAndConquer from 'src/pages/DivideAndConquer';

const buttonStyle = {
  position: 'absolute',
  zIndex: 10,
  height: '4.5rem', // Button Size
};

const pointsConfig = [
  {
    position: { top: '7.4rem', left: '32.7rem' },
    tooltipOffsets: ['-1.9rem', '-13vw'],
    tooltipClass: 'arrow-right',
  },
  {
    position: { top: '20.7rem', left: '19.7rem' },
    tooltipOffsets: ['-1.58rem', '-13vw'],
    tooltipClass: 'arrow-right',
  },
  {
    position: { top: '27.8rem', left: '31rem' },
    tooltipOffsets: ['-9rem', '-3.5vw'],
    tooltipClass: 'arrow-down',
  },
  {
    position: { top: '47.95rem', left: '25.7rem' },
    tooltipOffsets: ['-1.58rem', '-13vw'],
    tooltipClass: 'arrow-right',
  },
  {
    position: { top: '61.3rem', left: '38.7rem' },
    tooltipOffsets: ['-3.3vh', '-13.7vw'],
    tooltipClass: 'arrow-right',
  },
  {
    position: { top: '68.3rem', left: '27.4rem' },
    tooltipOffsets: ['10vh', '-2.5vw'],
    tooltipClass: 'arrow-right',
  },
];

const levels = [
  {
    levelImage: LevelOne,
    levelName: 'Bit Manipulation',
    levelComponent: (
      setShowLevel,
      completedTopics,
      setCompletedTopics,
      lastCompletedTopic
    ) => {
      return (
        <BitManipulation
          setBitManipulation={setShowLevel}
          completedTopics={completedTopics}
          setCompletedTopics={setCompletedTopics}
          lastCompletedTopic={lastCompletedTopic}
        />
      );
    },
  },
  {
    levelImage: LevelTwo,
    levelName: 'Two Pointer',
    levelComponent: (
      setShowLevel,
      completedTopics,
      setCompletedTopics,
      lastCompletedTopic
    ) => {
      return (
        <TwoPointerTechnique
          setTwoPointer={setShowLevel}
          completedTopics={completedTopics}
          setCompletedTopics={setCompletedTopics}
          lastCompletedTopic={lastCompletedTopic}
        />
      );
    },
  },
  {
    levelImage: LevelThree,
    levelName: 'Recursion',
    levelComponent: (
      setShowLevel,
      completedTopics,
      setCompletedTopics,
      lastCompletedTopic
    ) => {
      return (
        <Recursion
          setRecursion={setShowLevel}
          completedTopics={completedTopics}
          setCompletedTopics={setCompletedTopics}
          lastCompletedTopic={lastCompletedTopic}
        />
      );
    },
  },
  {
    levelImage: LevelFour,
    levelName: 'Divide and Conquer',
    levelComponent: (
      setShowLevel,
      completedTopics,
      setCompletedTopics,
      lastCompletedTopic
    ) => {
      return (
        <DivideAndConquer
          setDivideAndConquer={setShowLevel}
          completedTopics={completedTopics}
          setCompletedTopics={setCompletedTopics}
          lastCompletedTopic={lastCompletedTopic}
        />
      );
    },
  },
];

const newLayoutPointConfigAdapter = (isNewLayout, positions) => {
  if (isNewLayout) return positions;
  return { top: positions.top, left: `calc(${positions.left} - 0.7rem)` };
};

function TechniqueSagaMainScreen() {
  const NO_OF_PAGES = 1;
  const [pages, setPages] = useState(NO_OF_PAGES); // Number of pages
  const [activeTooltip, setActiveTooltip] = useState(0);
  const [activeLevel, setActiveLevel] = useState(null);
  const currentProgram = useSelector((state) => state.programs.currentProgram);
  const [loading, setLoading] = useState(false);
  const [lastCompletedTopic, setLastCompletedTopic] = useState(1);
  const [completedTopics, setCompletedTopics] = useState([]);
  const [btnText, setBtnText] = useState('Start Learning');
  const [showLevel, setShowLevel] = useState(false);
  const [showNewLayout, setShowNewLayout] = useState(null);

  useEffect(() => {
    setLoading(true);
    setShowNewLayout(shouldShowNewStudentLayout(currentProgram.programId));
    axios
      .get(fetchTechniqueSaga(), {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((res) => {
        const data = res?.data ?? [];
        setLoading(false);
        setCompletedTopics(data.map((topic) => topic.topic_id));
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  }, [currentProgram?.programId]);

  let lastCompletedTopicId;

  const setLastCompletedTopicId = () => {
    if (completedTopics.length > 0) {
      // Calculate the total number of topics
      const totalTopics = mechanismTopics.reduce(
        (acc, curr) => acc + curr.topics.length,
        0
      );

      // Find the maximum completed topic ID
      const maxCompletedTopicId = Math.max(...completedTopics);

      // Check if the user has completed all topics
      if (completedTopics.length === totalTopics) {
        setLastCompletedTopic(1); // Reset to 1 if all topics completed
        setBtnText('Completed');
      } else {
        // Find the next topic ID to be completed
        setBtnText('Continue Learning');
        for (let i = 1; i <= totalTopics; i += 1) {
          if (!completedTopics.includes(i)) {
            setLastCompletedTopic(i);
            break;
          }
        }
        // If all topics before the maximum completed topic have been completed, then next topic is the maximum completed topic + 1
        if (lastCompletedTopicId > maxCompletedTopicId) {
          setLastCompletedTopic(maxCompletedTopicId + 1);
        }
      }
    } else {
      setLastCompletedTopic(1); // Default to 1 if no topics completed
    }
  };

  useEffect(() => {
    setLastCompletedTopicId();
  }, [completedTopics]);

  if (showLevel) {
    return (
      <>
        <div style={{ maxWidth: '80vw' }}>
          {activeLevel.levelComponent(
            setShowLevel,
            completedTopics,
            setCompletedTopics,
            lastCompletedTopic
          )}
        </div>
      </>
    );
  }

  return (
    <div
      style={{
        backgroundImage: `url('/static/technique-saga/map.svg')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh',
        position: 'relative',
        display: 'flex',
      }}
    >
      <div style={{ flexGrow: 3 }}>
        <Typography
          sx={{
            width: '700px',
            textAlign: 'left',
            height: '64px',
            top: '53px',
            left: '20px',
            opacity: '0px',
            fontWeight: 600,
            position: 'absolute',
            fontSize: '40px',
            zIndex: 1,
          }}
        >
          Welcome to Technique Saga!
        </Typography>
        <Typography
          sx={{
            width: '625px',
            textAlign: 'left',
            height: '64px',
            top: '135px',
            left: '20px',
            opacity: '0px',
            position: 'absolute',
            zIndex: 1,
          }}
        >
          Technique Saga will help you uncover the secrets behind effective
          problem-solving techniques. Dive deep into the mechanics of each
          method, gain valuable insights, and refine your problem-solving skills
          to perfection. Whether it's mastering algorithmic strategies,
          understanding design principles, or embracing innovative approaches,
          Technique Saga will help you with the knowledge and expertise to
          confidently navigate the complexities of problem-solving.
        </Typography>
      </div>
      <div
        style={{
          flexGrow: 2,
          overflow: 'auto',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
        }}
      >
        {/* Iterating to create divs for 'pages' */}
        {Array.from({ length: pages }).map((_, index) => (
          <div
            key={index}
            style={{
              height: '150vh',
              position: 'relative',
            }}
          >
            <img
              src={path}
              alt='Path image'
              style={{
                float: 'right',
                width: '25rem',
                marginRight: '5vw',
                height: '81rem',
              }}
            />
            {pointsConfig.map((pointConfig, idx) => {
              const pointIndex = index * 6 + idx + 1;
              const isLocked = pointIndex > levels.length;
              const levelInfo = !isLocked ? levels[pointIndex - 1] : null;
              return (
                <>
                  <img
                    src={isLocked ? lock : levelInfo['levelImage']}
                    style={{
                      ...newLayoutPointConfigAdapter(
                        showNewLayout,
                        pointConfig.position
                      ),
                      ...buttonStyle,
                    }}
                    onClick={() => {
                      if (activeTooltip === pointIndex || isLocked) {
                        setActiveTooltip(0);
                        return;
                      }
                      setActiveTooltip(pointIndex);
                      setActiveLevel(levels[pointIndex - 1]);
                    }}
                  />
                  {/* Tooltip */}
                  {!isLocked && (
                    <div
                      className='hover-box'
                      style={{
                        zIndex: 4,
                        top: `calc(${pointConfig.tooltipOffsets[0]} + ${pointConfig.position['top']})`,
                        left: `calc(${pointConfig.tooltipOffsets[1]} + ${pointConfig.position['left']})`,
                        display:
                          activeTooltip === pointIndex ? 'block' : 'none',
                      }}
                    >
                      <div>
                        <Box
                          display='flex'
                          flexDirection='column'
                          justifyContent='flex-start'
                        >
                          <Typography
                            sx={{ fontSize: '14px', fontWeight: 600 }}
                          >
                            Technique {pointIndex}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize:
                                levelInfo['levelName'].length > 15
                                  ? '18px'
                                  : '20px',
                              fontWeight: 700,
                            }}
                          >
                            {levelInfo['levelName']}
                          </Typography>
                        </Box>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '11px',
                          }}
                        >
                          <Button
                            variant='contained'
                            style={{ backgroundColor: 'rgba(25, 118, 210, 1)' }}
                            onClick={() => setShowLevel(true)}
                          >
                            {btnText}
                          </Button>
                        </div>
                      </div>
                      <div className={`${pointConfig.tooltipClass}`} />
                      {pointConfig.tooltipClass === 'arrow-down' && (
                        <div className='arrow-down-below' />
                      )}
                    </div>
                  )}
                </>
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
}

export default TechniqueSagaMainScreen;

import { Box, Divider, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';

export default function SessionsNav({
  sessionData,
  setCurrentIndex,
  currentIndex,
}) {
  return (
    <Box style={{ display: 'flex', height: '100%' }}>
      <Divider
        orientation='vertical'
        style={{ height: '80%' }}
        flexItem
      />
      <Box style={{ width: '100%', margin: '10px' }}>
        <Box style={{ margin: '10px', fontWeight: 700 }}>Session</Box>
        <Box>
          {sessionData.map((data, index) => {
            return (
              <button
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor:
                    index === currentIndex ? '#e5f5fd' : '#ffffff',
                  marginTop: '5px',
                  border: 'none',
                  borderRadius: '1000px',
                  minWidth: '100%',
                }}
                onClick={() => {
                  setCurrentIndex(index);
                }}
              >
                {data.completed ? (
                  <CheckCircleIcon
                    fontSize='small'
                    sx={{ margin: '10px', color: '#388e3c' }}
                  />
                ) : (
                  <CircleIcon
                    fontSize='small'
                    sx={{ margin: '10px', color: '#cccaca' }}
                  />
                )}
                <Box
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    textAlign: 'start',
                  }}
                >
                  {data.text}
                </Box>
              </button>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
}

import React from 'react';
import { InlineWidget } from 'react-calendly';

const Calendly = () => {
  return (
    <div>
      <InlineWidget url='https://calendly.com/heycoach_admission_team/30min?month=2024-08' />
    </div>
  );
};

export default Calendly;

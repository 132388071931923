// @ts-nocheck
import React from 'react';
import { Autocomplete, TextField, Checkbox } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

export default function LearnerDropdown({
  learners,
  selectedLearners,
  setSelectedLearners,
}) {
  const handleCheckboxChange = (_, newValue) => {
    setSelectedLearners(newValue);
  };
  const isSelected = (option) => {
    return selectedLearners.some(
      (item) =>
        item.chat_id === option.chat_id && item.user_id === option.user_id
    );
  };

  return (
    <Autocomplete
      loading
      multiple
      id='checkboxes-tags-demo'
      options={learners}
      disableCloseOnSelect
      getOptionLabel={(option) => option.user_name}
      value={selectedLearners}
      onChange={handleCheckboxChange}
      renderOption={(props, option) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            checked={isSelected(option)}
          />
          {option.user_name}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label='Select Learners'
        />
      )}
    />
  );
}

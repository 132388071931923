import { Box, Typography } from '@material-ui/core';
import React from 'react';
import moment from 'moment';
import Countdown from 'react-countdown';

export default function CodingTestCountdown({
  handleAutoSubmit,
  duration = 0,
  startedAt,
}) {
  const getDurationForCountdown = () => {
    const now = moment(startedAt);
    return now.add(duration, 'minutes');
  };

  return (
    <>
      <Box
        sx={{
          marginY: 'auto',
          width: 105,
        }}
      >
        <Typography variant='h6'>
          <Countdown
            onTick={handleAutoSubmit}
            date={getDurationForCountdown()}
          />
        </Typography>
      </Box>
    </>
  );
}

import React from 'react';
import LockIcon from '@mui/icons-material/Lock';

const LockIconComponent = () => {
  return (
    <>
      <LockIcon sx={{ color: '#D9D9D9', fontSize: '20px' }} />
    </>
  );
};

export default LockIconComponent;

// @ts-nocheck
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Stack, Typography } from '@material-ui/core';
import { BASE_URL, getHeaders } from '../../apis/urls';
import LoadingScreen from '../../pages/LoadingScreen';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';

const TGPBatchWiseRecord = () => {
  const [url, setUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${BASE_URL}/api/metabases/batch_wise_tgp_record`, {
        headers: getHeaders(),
      })
      .then((res) => {
        setUrl(res.data.url);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message ??
            'An error occured while finding the dashboard'
        );
      })
      .finally(() => {
        setLoading(false);
      });

    return () => {};
  }, []);

  return (
    <Stack
      alignContent='center'
      width='100%'
    >
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}

      <LoadingScreen loading={loading} />

      {url === '' && (
        <Typography
          variant='body1'
          component='p'
        >
          {loading ? 'Finding dashboard' : 'No dashboard found'}
        </Typography>
      )}

      <iframe
        title='TGP Batchwise record'
        src={url}
        frameBorder={0}
        width='inherit'
        height={900}
        allowTransparency
      />
    </Stack>
  );
};

export default TGPBatchWiseRecord;

import React from 'react';
import { Typography, Box } from '@mui/material';

const HeadingText = () => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '0 0 50px 0',
        }}
      >
        <Typography
          sx={{
            color: '#1976D2',
            fontSize: '42px',
            fontWeight: '800',
          }}
        >
          The Great Pyramid
        </Typography>
        <Typography
          sx={{
            width: '100%',
            textAlign: 'center',
            fontSize: '20px',
            fontWeight: '400',
            padding: '0 96px',
          }}
        >
          With The Great Pyramid, you'll embark on a path of continuous growth,
          advancing through each Level with determination and progress.
        </Typography>
      </Box>
    </>
  );
};

export default HeadingText;

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { NewSessions } from './NewSessions';
import { Sessions } from '../sessions/Sessions';
import { shouldShowNewSessionsUI } from '../utils/common';
import { ActivityProvider } from 'src/layouts/userActivityTracking/ActivityProvider';

function SessionsLayout() {
  const currentProgram = useSelector((state) => state.programs.currentProgram);
  const shouldShowNewUI = shouldShowNewSessionsUI(currentProgram?.programId);
  if (currentProgram?.programId === 2) {
    return (
      <ActivityProvider>
        {shouldShowNewUI ? <NewSessions /> : <Sessions />}
      </ActivityProvider>
    );
  }

  return shouldShowNewUI ? <NewSessions /> : <Sessions />;
}

export default SessionsLayout;

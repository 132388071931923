import React, { useState } from 'react';
import {
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Typography,
  Modal,
  Box,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
} from '@mui/material';
import axios from 'axios';
import { patchQuizQuestion, getHeaders } from '../../../../apis/urls';
import { validateQuestion } from './validateQuestion';

export default function EditQuestionModal({
  question,
  open,
  onClose,
  updateQuizQuestion,
  setHasError,
  setErrorMessage,
  setIsSuccess,
  setSuccessMessage,
}) {
  const [editedQuestion, setEditedQuestion] = useState(question);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditedQuestion({
      ...editedQuestion,
      [name]: value,
    });
  };

  const handleOptionChange = (index, optionName, optionValue) => {
    const updatedOptions = [...editedQuestion.quiz_question_options];
    updatedOptions[index] = {
      ...updatedOptions[index],
      [optionName]: optionValue,
    };
    setEditedQuestion({
      ...editedQuestion,
      quiz_question_options: updatedOptions,
    });
  };

  const handleSave = () => {
    const validationError = validateQuestion(editedQuestion);
    if (validationError) {
      setHasError(true);
      setErrorMessage(validationError);
      return;
    }

    const body = {};
    if (question.question !== editedQuestion.question) {
      body.question = editedQuestion.question;
    }
    if (question.question_type !== editedQuestion.question_type) {
      body.question_type = editedQuestion.question_type;
    }
    const mods = [];
    editedQuestion.quiz_question_options.forEach((opt, index) => {
      const edOption = question.quiz_question_options[index];
      if (opt.option !== edOption.option || opt.answer !== edOption.answer) {
        mods.push(opt);
      }
    });

    if (mods.length > 0) {
      body.quiz_question_options = mods;
    }

    axios
      .patch(patchQuizQuestion(question.id), body, {
        headers: getHeaders(),
      })
      .then((res) => {
        updateQuizQuestion(res?.data);
        setIsSuccess(true);
        setSuccessMessage('Question updated successfully');
        onClose();
      })
      .catch((e) => {
        console.log(e);
        setHasError(true);
        setErrorMessage(
          e.response?.data?.message ||
            'An error occurred while updating the question'
        );
      });
  };

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxWidth: 500,
            maxHeight: '90%',
            bgcolor: 'background.paper',
            p: 4,
          }}
        >
          <Typography variant='h5'>Edit Question</Typography>
          <TextField
            label='Question'
            name='question'
            value={editedQuestion.question}
            onChange={handleInputChange}
            fullWidth
            margin='normal'
            multiline
            minRows={1}
            maxRows={6}
          />
          <FormControl margin='normal'>
            <InputLabel>Question Type</InputLabel>
            <Select
              value={editedQuestion.question_type}
              onChange={handleInputChange}
              label='Question Type'
              name='question_type'
            >
              <MenuItem value='single'>Single Choice</MenuItem>
              <MenuItem value='multiple'>Multiple Choice</MenuItem>
            </Select>
          </FormControl>
          {editedQuestion.quiz_question_options.map((option, index) => (
            <Box
              display='flex'
              key={index}
            >
              <TextField
                label={`Option ${index + 1}`}
                name='option'
                margin='normal'
                value={option.option}
                onChange={(e) =>
                  handleOptionChange(index, 'option', e.target.value)
                }
              />
              <FormControlLabel
                sx={{ m: 1 }}
                control={
                  <Checkbox
                    checked={option.answer}
                    onChange={(e) =>
                      handleOptionChange(index, 'answer', e.target.checked)
                    }
                  />
                }
                label='Correct'
              />
            </Box>
          ))}
          <Box
            sx={{
              textAlign: 'center',
              mt: 2,
            }}
          >
            <Button
              onClick={handleSave}
              variant='contained'
              sx={{ px: 6 }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

import React, { useState, useEffect } from 'react';
import {
  Backdrop,
  Box,
  Modal,
  Fade,
  Button,
  Typography,
  TextField,
  Select,
  MenuItem,
  Autocomplete,
  Chip,
} from '@mui/material';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector } from 'react-redux';
import { addCompanyRole, getHeaders } from '../../../apis/urls';

const progressbarStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(0,0,0,0.3)',
  borderRadius: '10px',
};

const AddRoles = ({
  openAddRolesModal,
  handleCloseAddRolesModal,
  setIsSuccess,
  setSuccessMessage,
  setHasError,
  setErrorMessage,
  companies,
  roleData,
  setRoleData,
  techStacks,
}) => {
  const [showCircularProgressbar, setShowCircularProgressbar] = useState(false);
  const [techStackNames, setTechStackNames] = useState([]);
  const currentProgram = useSelector(
    (state) => state?.programs?.currentProgram
  );

  useEffect(() => {
    const names = techStacks.map((stack) => stack.name);
    setTechStackNames(names);
  }, [techStacks]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRoleData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAddRoles = async () => {
    setShowCircularProgressbar(true);
    await axios
      .post(addCompanyRole(), roleData, {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((res) => {
        if (res.data.message) {
          setHasError(true);
          setErrorMessage(res.data.message);
          handleCloseAddRolesModal();
          setRoleData(null);
        } else {
          setIsSuccess(true);
          setSuccessMessage('New company role added successfully');
          setShowCircularProgressbar(false);
          handleCloseAddRolesModal();
          setRoleData(null);
        }
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage('Error while adding a new company role');
        setShowCircularProgressbar(false);
        handleCloseAddRolesModal();
        setRoleData(null);
      });
  };

  return (
    <Modal
      open={openAddRolesModal}
      onClose={handleCloseAddRolesModal}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={openAddRolesModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '700px',
            height: '100%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 3,
            borderRadius: '10px',
            overflow: 'auto',
            scrollbarWidth: 'none',
            '-ms-overflow-style': 'none',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          {showCircularProgressbar && (
            <Box sx={progressbarStyle}>
              <CircularProgress color='inherit' />
            </Box>
          )}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'center',
              gap: '15px',
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: '30px',
                  fontWeight: '700',
                }}
              >
                Add Roles
              </Typography>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                gap: '35px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  textAlign: 'start',
                  width: '20%',
                }}
              >
                <Typography sx={{ fontSize: '15px', fontWeight: '700' }}>
                  Companies:
                </Typography>
              </Box>
              <Box width='100%'>
                <Select
                  sx={{
                    width: '100%',
                  }}
                  onChange={handleChange}
                  name='company_id'
                  value={roleData?.company}
                >
                  {companies &&
                    companies.map((company) => (
                      <MenuItem
                        key={company.id}
                        value={company.id}
                      >
                        {company.name}
                      </MenuItem>
                    ))}
                </Select>
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  textAlign: 'start',
                  width: '27%',
                }}
              >
                <Typography sx={{ fontSize: '15px', fontWeight: '700' }}>
                  Role title:
                </Typography>
              </Box>
              <Box
                sx={{
                  width: '100%',
                }}
              >
                <TextField
                  sx={{ width: '100%' }}
                  name='role_title'
                  onChange={handleChange}
                  value={roleData?.role_title}
                />
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  textAlign: 'start',
                  width: '27%',
                }}
              >
                <Typography sx={{ fontSize: '15px', fontWeight: '700' }}>
                  Salary range:
                </Typography>
              </Box>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  gap: '20px',
                }}
              >
                <TextField
                  type='number'
                  placeholder='LPA in INR'
                  name='min_ctc'
                  onChange={handleChange}
                  value={roleData?.min_ctc}
                  sx={{ width: '100%' }}
                />
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  <Typography>to</Typography>
                </Box>
                <TextField
                  name='max_ctc'
                  type='number'
                  onChange={handleChange}
                  value={roleData?.max_ctc}
                  placeholder='LPA in INR'
                  sx={{ width: '100%' }}
                />
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  textAlign: 'start',
                  width: '27%',
                }}
              >
                <Typography sx={{ fontSize: '15px', fontWeight: '700' }}>
                  Tech stacks:
                </Typography>
              </Box>
              <Box
                sx={{
                  width: '100%',
                }}
              >
                <Autocomplete
                  multiple
                  id='tech-stacks'
                  options={techStackNames}
                  getOptionLabel={(option) => option}
                  onChange={(event, value) => {
                    handleChange({
                      target: { name: 'tech_stacks', value },
                    });
                  }}
                  freeSolo
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        key={index}
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ width: '100%' }}
                      variant='outlined'
                    />
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.toLowerCase() === value.toLowerCase()
                  }
                />
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                gap: '35px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  textAlign: 'start',
                  width: '20%',
                }}
              >
                <Typography sx={{ fontSize: '15px', fontWeight: '700' }}>
                  Job type:
                </Typography>
              </Box>
              <Box width='100%'>
                <Select
                  sx={{
                    width: '100%',
                  }}
                  name='job_type'
                  onChange={handleChange}
                  value={roleData?.job_type}
                >
                  <MenuItem value='remote'>Remote</MenuItem>
                  <MenuItem value='onsite'>Onsite</MenuItem>
                </Select>
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  textAlign: 'start',
                  width: '27%',
                }}
              >
                <Typography sx={{ fontSize: '15px', fontWeight: '700' }}>
                  Location:
                </Typography>
              </Box>
              <Box
                sx={{
                  width: '100%',
                }}
              >
                <TextField
                  sx={{ width: '100%' }}
                  name='location'
                  onChange={handleChange}
                  value={roleData?.location}
                />
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  textAlign: 'start',
                  width: '27%',
                }}
              >
                <Typography sx={{ fontSize: '15px', fontWeight: '700' }}>
                  POC Name:
                </Typography>
              </Box>
              <Box
                sx={{
                  width: '100%',
                }}
              >
                <TextField
                  sx={{ width: '100%' }}
                  name='poc_name'
                  onChange={handleChange}
                  value={roleData?.poc_name}
                />
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  textAlign: 'start',
                  width: '27%',
                }}
              >
                <Typography sx={{ fontSize: '15px', fontWeight: '700' }}>
                  POC Email:
                </Typography>
              </Box>
              <Box
                sx={{
                  width: '100%',
                }}
              >
                <TextField
                  sx={{ width: '100%' }}
                  name='poc_email'
                  onChange={handleChange}
                  value={roleData?.poc_email}
                />
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  textAlign: 'start',
                  width: '27%',
                }}
              >
                <Typography sx={{ fontSize: '15px', fontWeight: '700' }}>
                  POC Contact:
                </Typography>
              </Box>
              <Box
                sx={{
                  width: '100%',
                }}
              >
                <TextField
                  sx={{ width: '100%' }}
                  name='poc_contact'
                  onChange={handleChange}
                  value={roleData?.poc_contact}
                />
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                textAlign: 'start',
                width: '20%',
              }}
            >
              <Typography sx={{ fontSize: '15px', fontWeight: '700' }}>
                Description:
              </Typography>
            </Box>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
            >
              <TextField
                name='description'
                onChange={handleChange}
                value={roleData?.description}
                multiline
                rows={4}
                sx={{
                  width: '100%',
                }}
              />
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'end',
              }}
            >
              <Button
                variant='contained'
                onClick={handleAddRoles}
              >
                Send for review
              </Button>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default AddRoles;

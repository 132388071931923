import React from 'react';
import { Box } from '@material-ui/system'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Typography } from '@material-ui/core';

export default function PreviousPage({ handlePrevPageClick }) {
  return (
    <Box 
      display="flex" 
      sx={{
        cursor: 'pointer'
      }} 
      onClick={handlePrevPageClick}
    >
      <ArrowBackIcon 
        fontSize='medium' 
        color="primary" 
      />
      
      <Typography 
        variant="body2"
      >
        Back
      </Typography>
    </Box>
  );
}
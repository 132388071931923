// @ts-nocheck
import ChallengesFacedDuringInterviewPrep from './ChallengesFacedDuringInterviewPrep';
import DailyHoursCommitment from './DailyHoursCommitment';
import DesiredSalaryRange from './DesiredSalaryRange';
import GoalSelection from './GoalSelection';
import ProfileEnhancementProgramAspects from './ProfileEnhancementProgramAspects';
import StepsTakenForInterviews from './StepsTakenForInterviews';

const conversationFlowQuestions = [
  {
    key: 'goal_selection',
    getComponent: (args) => <GoalSelection {...args} />,
  },
  {
    key: 'desired_salary_range',
    getComponent: (args) => <DesiredSalaryRange {...args} />,
  },
  {
    key: 'steps_taken_for_interviews',
    getComponent: (args) => <StepsTakenForInterviews {...args} />,
  },
  {
    key: 'challenges_faced_during_interview_prep',
    getComponent: (args) => <ChallengesFacedDuringInterviewPrep {...args} />,
  },
  {
    key: 'daily_hours_commitment',
    getComponent: (args) => <DailyHoursCommitment {...args} />,
  },
  {
    key: 'profile_enhancement_program_aspects',
    getComponent: (args) => <ProfileEnhancementProgramAspects {...args} />,
  },
];

export default conversationFlowQuestions;

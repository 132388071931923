// @ts-nocheck
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Stack, Typography } from '@material-ui/core';
import { Box } from '@material-ui/system';
import { addSessions } from '../../../redux-store/slices/sessionSlice';
import LoadingScreen from '../../../../pages/LoadingScreen';
import {
  fetchAllSessionTypesURL,
  fetchProgramEnrollmentsUrl,
  getHeaders,
  postBulkSessionsUrl,
} from '../../../../apis/urls';
import ErrorNotifier from '../../../ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../../ToastNotifications/SuccessNotifier';
import SessionsTable from './SessionsTable';
import NewSessionModal from './NewSessionModal';
import BulkCreateSessionsModal from './BulkCreateSessionsModal';
import RescheduleSessionsModal from './RescheduleSessionsModal';

export default function Sessions() {
  const dispatch = useDispatch();
  const currentProgram = useSelector((state) => state.programs.currentProgram);
  const sessions = useSelector((state) => state.sessions.sessions);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [overviewText, setOverviewText] = useState([]);
  const [coaches, setCoaches] = useState({});
  const [maxClasses, setMaxClasses] = useState('');
  const [openNewSessionModal, setOpenNewSessionModal] = useState(false);
  const [openBulkCreateSessionsModal, setOpenBulkCreateSessionsModal] =
    useState(false);
  const [sessionTypes, setSessionTypes] = useState([]);
  const [sessionIdToIndexMap, setSessionIdToIndexMap] = useState({});
  const [loading, setLoading] = useState(false);
  const [openRescheduleSessionsModal, setOpenRescheduleSessionsModal] =
    useState(false);
  const [selectedSession, setSelectedSession] = useState(null);

  useEffect(() => {
    if (currentProgram) {
      if (sessions?.length > 0) {
        getOverviewText();
      }
      getCoaches();
      getSessionTypes();
    }
    return () => {};
  }, [currentProgram, sessions]);

  function getSessionTypes() {
    axios
      .get(fetchAllSessionTypesURL(), {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((res) => {
        setSessionTypes(res.data);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(err.response?.data?.message || err.message);
      });
  }

  function getOverviewText() {
    let adhoc_sessions_count = 0;
    let conducted_sessions = 0;
    const total = sessions?.length;
    const tmpMap = {};
    sessions?.forEach((session, index) => {
      tmpMap[session.id] = index;
      adhoc_sessions_count += session?.session_type?.name === 'adhoc' ? 1 : 0;
      conducted_sessions +=
        session?.status !== 'created' && session?.status !== 'scheduled'
          ? 1
          : 0;
    });
    const tmpOverviewText = [
      `${total} sessions in total with ${adhoc_sessions_count} adhoc sessions included.`,
      `${conducted_sessions} conducted`,
      `${total - conducted_sessions} pending.`,
    ];
    setOverviewText(tmpOverviewText);
    setSessionIdToIndexMap(tmpMap);
  }

  function getCoaches() {
    axios
      .get(fetchProgramEnrollmentsUrl(), {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((res) => {
        const tmpCoaches = {};
        setMaxClasses(res.data[0].program.max_classes);
        res.data?.forEach((enrollment, idx) => {
          if (
            enrollment?.program_access !== 'allowed' ||
            enrollment?.user?.role !== 'coach'
          ) {
            return;
          }
          tmpCoaches[`${enrollment?.user.name} (${enrollment.user.email})`] =
            enrollment?.user?.id;
        });
        setCoaches(tmpCoaches);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message ||
            'Could not fetch the list of coaches enrolled in this program.'
        );
      });
  }

  const handleBulkSessionCreate = (body) => {
    setLoading(true);

    axios
      .post(postBulkSessionsUrl(), body, {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((res) => {
        dispatch(addSessions(res?.data ?? []));
        setIsSuccess(true);
        setSuccessMessage('Bulk sessions created successfully');
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || "Couldn't create the sessions."
        );
      })
      .finally(() => {
        setLoading(false);
        setOpenBulkCreateSessionsModal(false);
      });
  };

  return (
    <Stack>
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      {isSuccess && (
        <SuccessNotifier
          message={successMessage}
          setIsSuccess={setIsSuccess}
        />
      )}

      <Box>
        <LoadingScreen loading={loading} />

        <Box
          display='flex'
          justifyContent='flex-end'
          sx={{ m: 2 }}
        >
          <Button
            variant='contained'
            onClick={(e) => setOpenBulkCreateSessionsModal(true)}
            sx={{
              mr: 1,
            }}
          >
            Bulk create sessions
          </Button>
          <Button
            variant='contained'
            onClick={(e) => setOpenNewSessionModal(true)}
            sx={{ mr: 1 }}
          >
            Create session
          </Button>

          {/* <Button variant="contained">
            Create Adhoc session
          </Button> */}
        </Box>

        <Box sx={{ ml: 2, mb: 2 }}>
          {maxClasses && (
            <Typography variant='body2'>
              {`Max allowed sessions for this program are ${maxClasses}`}
            </Typography>
          )}
          {overviewText?.map((text) => {
            return <Typography variant='body2'>{text}</Typography>;
          })}
        </Box>
        {sessions?.length > 0 && (
          <SessionsTable
            coaches={coaches}
            sessions={sessions}
            setHasError={setHasError}
            setErrorMessage={setErrorMessage}
            setSuccessMessage={setSuccessMessage}
            setIsSuccess={setIsSuccess}
            programId={currentProgram?.programId}
            sessionIdToIndexMap={sessionIdToIndexMap}
            setOpenRescheduleSessionsModal={setOpenRescheduleSessionsModal}
            setSession={setSelectedSession}
          />
        )}
        {sessions.length === 0 && (
          <Typography
            variant='body1'
            textAlign='center'
            sx={{ mt: 2 }}
          >
            No sessions have been added.
          </Typography>
        )}
        {openNewSessionModal && (
          <NewSessionModal
            {...{
              setHasError,
              setErrorMessage,
              setIsSuccess,
              setSuccessMessage,
              sessionTypes,
              openNewSessionModal,
              setOpenNewSessionModal,
              programId: currentProgram?.programId,
            }}
          />
        )}
        {openRescheduleSessionsModal && (
          <RescheduleSessionsModal
            {...{
              openBulkCreateSessionsModal,
              setHasError,
              setErrorMessage,
              setIsSuccess,
              setSuccessMessage,
              openRescheduleSessionsModal,
              setOpenRescheduleSessionsModal,
              programId: currentProgram?.programId,
              selectedSession,
            }}
          />
        )}
        {openBulkCreateSessionsModal && (
          <BulkCreateSessionsModal
            {...{
              openBulkCreateSessionsModal,
              setOpenBulkCreateSessionsModal,
              setHasError,
              setErrorMessage,
              setLoading,
              loading,
              handleBulkSessionCreate,
            }}
          />
        )}
      </Box>
    </Stack>
  );
}

import React from 'react';
import './TicketCard.css';
import ScratchCardComponent from '../Atoms/ScratchCardComponent';
import { formatTimestamp, getOfferRate } from '../TAPCouponsUtil';
import { Tooltip } from '@material-ui/core';
import { Button } from '@mui/material';

const CouponDetail = ({ coupon }) => {
  return (
    <div className='ticket-detail-card'>
      <div className='ticket-detail-card__content'>
        <div className='ticket-detail-card__details'>
          <div>
            <div style={{ float: 'left', margin: '10px' }}>
              <img
                className='ticket-detail-card__image'
                src={coupon.logo_url}
                alt='Offer'
              />
            </div>
            <div style={{ marginTop: '10px', fontSize: '1rem' }}>
              <h3 style={{ fontSize: '1.5rem' }}>{coupon.company_name}</h3>
              <p style={{ fontSize: '0.8rem' }}>{coupon.description}</p>
              <div className='ticket-detail-card__price'>
                {getOfferRate(coupon)}
              </div>
            </div>
          </div>

          <div className='ticket-detail-card__info'>
            <div className='ticket-detail-card__offer-details'>
              <h4>Offer Details</h4>
              <ul style={{ color: 'grey', fontSize: '0.9rem' }}>
                {coupon.offer_details.split('\n').map((offer_detail) => {
                  return <li>{offer_detail}</li>;
                })}
              </ul>
              {coupon.valid_till && (
                <>
                  <h4>Offer Expiry</h4>
                  <p>Expires on {formatTimestamp(coupon.valid_till)}</p>
                </>
              )}
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <a
                  className='ticket-detail-card__button'
                  href={coupon.offer_site_url}
                >
                  Visit the website
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className='ticket-detail-card__divider'></div>
        <div className='ticket-detail-card__scratch'>
          <h4>Scratch the card below to reveal the unique code.</h4>
          <ScratchCardComponent
            couponCode={coupon.coupon_code}
            couponId={coupon.id}
            isScratched={coupon.scratched_on ? true : false}
          />
          <Tooltip title='For any issues regarding your coupons, please reach out to us at punit.suresh@heycoach.in'>
            <Button>I need help with the coupon</Button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default CouponDetail;

import React from 'react';
import { Typography } from '@mui/material';

const TypographyText = ({ levelNumber, text, stylingClass }) => {
  return (
    <>
      <Typography className={stylingClass}>
        {text}
        {levelNumber}
      </Typography>
    </>
  );
};

export default TypographyText;

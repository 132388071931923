import React, { useEffect, useState } from 'react';
import axios from 'axios';
// material
import {
  Avatar,
  Box,
  Container,
  Typography,
  Stack,
  Modal,
  Alert,
  Button,
} from '@material-ui/core';

import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
// components
import Page from '../components/Page';
import EditProfile from './EditProfile';
import {
  fetchUserProfileUrl,
  getHeaders,
  getEducation,
  getExperience,
  deleteExperience,
  deleteEducation,
  getUserLinks,
  deleteUserLinks,
} from '../apis/urls';
import { getAccessToken, getUserId } from '../configs/auth';
import AddExperience from '../components/UserProfile/AddExperience';
import EditExperience from '../components/UserProfile/EditExperience';
import AddEducation from '../components/UserProfile/AddEducation';
import EditEducation from '../components/UserProfile/EditEducation';
import AddUserLinks from '../components/UserProfile/AddUserLinks';
import moment from 'moment';

export default function UserAccount() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [experience, setExperience] = useState([]);
  const [education, setEducation] = useState([]);
  const [userLinks, setUserLinks] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [errors, setErrors] = useState(false);
  const [success, setSuccess] = useState(false);
  const [experienceModel, setExperienceModel] = useState(false);
  const [expEditModel, setExpEditModel] = useState(false);
  const [editId, setEditId] = useState('');
  const [educationModel, setEducationModel] = useState(false);
  const [editEducationModel, setEditEducationModel] = useState(false);
  const [addUserLinksModel, setAddUserLinksModel] = useState(false);

  const userId = getUserId();

  useEffect(() => {
    getProfileData();
    getExperienceData();
    getEducationdata();
    getUserLinksData();
  }, []);

  const handleDelete = (id) => {
    axios
      .delete(deleteExperience(id), {
        headers: getHeaders(),
      })
      .then((res) => {
        getExperienceData();
        setSuccess(true);
      })
      .then((err) => {
        console.log(err);
      });
  };

  const handleDeleteEduction = (id) => {
    axios
      .delete(deleteEducation(id), {
        headers: getHeaders(),
      })
      .then((res) => {
        getEducationdata();
        setSuccess(true);
      })
      .then((err) => {
        console.log(err);
      });
  };

  const handleDeleteUserLinks = (id) => {
    axios
      .delete(deleteUserLinks(id), {
        headers: getHeaders(),
      })
      .then((res) => {
        getUserLinksData();
        setSuccess(true);
      })
      .then((err) => {
        console.log(err);
      });
  };

  function getProfileData() {
    axios
      .get(fetchUserProfileUrl(), {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Access-Token': getAccessToken(),
        },
      })
      .then((res) => {
        const userData = res.data;
        console.log(res.data);
        setUsers(userData);
        setErrorMessage('');
        setErrors(false);
      })
      .catch((e) => {
        const connection_error = JSON.parse(JSON.stringify(e));
        if (connection_error.message === 'Network Error') {
          setErrors(true);
          setErrorMessage("Can't connect to the servers right now");
        } else if (e.response && e.response.data) {
          const { data } = e.response;
          setErrors(true);
          setErrorMessage(data.message);
        }
      });
  }

  const getExperienceData = () => {
    axios(getExperience(), {
      headers: getHeaders(),
    })
      .then((res) => {
        setExperience(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getEducationdata = () => {
    axios(getEducation(), {
      headers: getHeaders(),
    })
      .then((res) => {
        setEducation(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getUserLinksData = () => {
    axios(getUserLinks(), {
      headers: getHeaders(),
    })
      .then((res) => {
        setUserLinks(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleOpen = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleCloseExpModel = () => {
    setExperienceModel(false);
  };

  const closeError = () => {
    setErrors(false);
    setSuccess(false);
  };

  return (
    <Page title='Learning - HeyCoach'>
      <Container sx={{ marginTop: '32px' }}>
        <Stack>
          {errors && (
            <Alert
              onClose={closeError}
              severity='error'
              sx={{
                position: 'fixed',
                bottom: '20px',
                left: '20px',
                zIndex: 1500,
                backgroundColor: 'red',
                color: '#fff',
              }}
            >
              {' '}
              {errorMessage}
            </Alert>
          )}
          {success && (
            <Alert
              onClose={closeError}
              severity='success'
              sx={{
                position: 'fixed',
                bottom: '20px',
                left: '20px',
                zIndex: 1500,
                backgroundColor: '#006400',
                color: '#fff',
              }}
            >
              Profile Changes added Successfully
            </Alert>
          )}
        </Stack>

        <Box
          sx={{
            backgroundColor: 'white',
            border: '1px solid #d1d1d1',
            borderRadius: '14px',
            boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
          }}
        >
          <img
            alt='User background'
            src='/static/UserBackground.svg'
            style={{ borderRadius: '14px 14px 0px 0px' }}
          />
          <Avatar
            alt='User Avatar'
            src={users.image}
            sx={{
              zIndex: 1,
              marginTop: '-150px',
              marginLeft: '40px',
              width: '200px',
              height: '200px',
              border: '6px solid #fff',
            }}
          />
          <Box
            sx={{ display: 'flex', marginBottom: '20px', marginTop: '10px' }}
          >
            <Box sx={{ width: '94%' }}>
              <Typography
                variant='h4'
                gutterBottom
                sx={{ marginLeft: '40px' }}
              >
                {users.name}
              </Typography>

              {users.headline ? (
                <Typography
                  variant='caption'
                  gutterBottom
                  sx={{
                    marginLeft: '40px !important',
                    fontSize: '16px',
                    marginTop: '0px',
                    display: 'block',
                  }}
                >
                  {users.headline}
                </Typography>
              ) : (
                <Typography
                  variant='caption'
                  gutterBottom
                  sx={{
                    marginLeft: '40px',
                    fontSize: '16px',
                    color: 'gray',
                    display: 'block',
                    marginTop: '0px',
                  }}
                >
                  You have not added your headline here, add it !
                </Typography>
              )}
              {users.location && (
                <Typography
                  variant='caption'
                  gutterBottom
                  sx={{
                    marginLeft: '40px',
                    fontSize: '15px',
                    color: '#363636',
                    display: 'block',
                  }}
                >
                  {users.location}
                </Typography>
              )}
              <Box
                sx={{
                  display: 'grid',
                  marginTop: '8px',
                  gridTemplateColumns: 'repeat(4, 1fr)',
                  marginLeft: '40px',
                  gap: '10px 20px',
                }}
              >
                <Box>
                  <Typography
                    variant='subtitle2'
                    gutterBottom
                    sx={{
                      fontSize: '15px',
                      color: '#363636',
                      display: 'block',
                      marginTop: '5px',
                    }}
                  >
                    Email:
                  </Typography>
                  {users.email && (
                    <Typography
                      variant='caption'
                      gutterBottom
                      sx={{
                        fontSize: '15px',
                        color: '#363636',
                        display: 'block',
                        marginTop: '-7px',
                      }}
                    >
                      {users.email}
                    </Typography>
                  )}
                </Box>
                <Box>
                  <Typography
                    variant='subtitle2'
                    gutterBottom
                    sx={{
                      fontSize: '15px',
                      color: '#363636',
                      display: 'block',
                      marginTop: '5px',
                    }}
                  >
                    Phone:
                  </Typography>
                  {users.phone && (
                    <Typography
                      variant='caption'
                      gutterBottom
                      sx={{
                        fontSize: '15px',
                        color: '#363636',
                        display: 'block',
                        marginTop: '-7px',
                      }}
                    >
                      {users.phone}
                    </Typography>
                  )}
                </Box>

                <Box>
                  <Typography
                    variant='subtitle2'
                    gutterBottom
                    sx={{
                      fontSize: '15px',
                      color: '#363636',
                      display: 'block',
                      marginTop: '5px',
                    }}
                  >
                    Gender:
                  </Typography>
                  {users.gender && (
                    <Typography
                      variant='caption'
                      gutterBottom
                      sx={{
                        fontSize: '15px',
                        color: '#363636',
                        display: 'block',
                        marginTop: '-7px',
                      }}
                    >
                      {users.gender}
                    </Typography>
                  )}
                </Box>
                <Box>
                  <Typography
                    variant='subtitle2'
                    gutterBottom
                    sx={{
                      fontSize: '15px',
                      color: '#363636',
                      display: 'block',
                      marginTop: '5px',
                    }}
                  >
                    Birth Date:
                  </Typography>
                  {users.birth_date && (
                    <Typography
                      variant='caption'
                      gutterBottom
                      sx={{
                        fontSize: '15px',
                        color: '#363636',
                        display: 'block',
                        marginTop: '-7px',
                      }}
                    >
                      {moment(users.birth_date).format('MMMM Do YYYY')}
                    </Typography>
                  )}
                </Box>

                <Box>
                  <Typography
                    variant='subtitle2'
                    gutterBottom
                    sx={{
                      fontSize: '15px',
                      color: '#363636',
                      display: 'block',
                    }}
                  >
                    Leetcode Username:
                  </Typography>
                  {users.leetcode_username && (
                    <Typography
                      variant='caption'
                      gutterBottom
                      sx={{
                        fontSize: '15px',
                        color: '#363636',
                        display: 'block',
                        marginTop: '-7px',
                      }}
                    >
                      {users.leetcode_username}
                    </Typography>
                  )}
                </Box>
              </Box>
              <Box sx={{ marginTop: '20px' }}>
                <Typography
                  variant='h5'
                  gutterBottom
                  sx={{ marginLeft: '40px', fontSize: '20px' }}
                >
                  About
                </Typography>
                {users.about ? (
                  <Typography
                    variant='caption'
                    gutterBottom
                    sx={{
                      marginLeft: '40px',
                      fontSize: '16px',
                      color: '#363636',
                      display: 'block',
                      marginTop: '0px',
                      wordWrap: 'break-word',
                      overflowWrap: 'break-word',
                      whiteSpace: 'normal',
                      maxWidth: '100%',
                    }}
                  >
                    {users.about}
                  </Typography>
                ) : (
                  <Typography
                    variant='caption'
                    gutterBottom
                    sx={{
                      marginLeft: '40px',
                      fontSize: '16px',
                      color: 'gray',
                      display: 'block',
                      marginTop: '-8px',
                    }}
                  >
                    You have not added your Professional Information!
                  </Typography>
                )}
              </Box>
            </Box>
            <Box
              sx={{
                cursor: 'pointer',
                width: '40px',
                height: '40px',
                borderRadius: '50px',
                display: 'flex',
                alignItems: 'center',
                '&:hover': {
                  backgroundColor: '#d6e9ff',
                },
              }}
              onClick={handleOpen}
              color='secondary'
            >
              <EditIcon sx={{ margin: 'auto' }} />
            </Box>
          </Box>
          <Modal
            open={isModalOpen}
            onClose={handleClose}
          >
            <div>
              <EditProfile
                users={users}
                id={userId}
                handleModalClose={handleClose}
                setErrors={setErrors}
                setErrorMessage={setErrorMessage}
                updateUser={setUsers}
                setSuccess={setSuccess}
              />
            </div>
          </Modal>
        </Box>

        <Box
          sx={{
            backgroundColor: 'white',
            border: '1px solid #d1d1d1',
            borderRadius: '14px',
            boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
            marginTop: '10px',
          }}
        >
          <Box
            sx={{
              width: '94%',
              marginLeft: '40px',
              display: 'flex',
              marginTop: '20px',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              variant='h5'
              gutterBottom
              sx={{ fontSize: '20px', marginBottom: '26px', display: 'block' }}
            >
              Experience
            </Typography>
            <Box>
              <Button
                variant='outlined'
                onClick={() => setExperienceModel(true)}
                color='secondary'
                sx={{ height: '30px', width: '75px' }}
              >
                Add
              </Button>
            </Box>
          </Box>
          {experience.length ? (
            experience.map((e) => {
              return (
                e.user_id === getUserId() && (
                  <Box
                    sx={{
                      display: 'flex',
                      marginBottom: '24px',
                      marginLeft: '40px',
                    }}
                  >
                    <img
                      src='https://suesys.com/assets/website/images/company-logo-default.png'
                      alt='Organization Img'
                      style={{
                        width: '50px',
                        height: '50px',
                        transform: 'translateY(8px)',
                      }}
                    />
                    <Box sx={{ marginLeft: '20px', flexGrow: 1 }}>
                      <Typography
                        variant='h6'
                        gutterBottom
                        sx={{
                          fontSize: '16px',
                          marginBottom: '-4px',
                          display: 'block',
                        }}
                      >
                        {e.title}
                      </Typography>
                      <Typography
                        variant='caption'
                        gutterBottom
                        sx={{
                          fontSize: '14px',
                          marginBottom: '-3px',
                          display: 'block',
                        }}
                      >
                        {`${e.company_name} - ${e.employment_type}`}
                      </Typography>

                      <Typography
                        variant='caption'
                        gutterBottom
                        sx={{ fontSize: '14px', display: 'block' }}
                      >
                        {`${moment(e.start_date).format('MMM YYYY')}  -  ${
                          e.end_date
                            ? moment(e.end_date).format('MMM YYYY')
                            : 'Present'
                        }`}
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                        marginRight: '2%',
                      }}
                    >
                      <Box
                        sx={{
                          cursor: 'pointer',
                          width: '40px',
                          height: '40px',
                          borderRadius: '50px',
                          display: 'flex',
                          alignItems: 'center',
                          '&:hover': {
                            backgroundColor: '#d6e9ff',
                          },
                        }}
                        onClick={() => {
                          setEditId(e.id);
                          setExpEditModel(true);
                        }}
                        color='secondary'
                      >
                        <EditIcon sx={{ margin: 'auto' }} />
                      </Box>
                      <Box
                        sx={{
                          cursor: 'pointer',
                          width: '40px',
                          height: '40px',
                          borderRadius: '50px',
                          display: 'flex',
                          alignItems: 'center',
                          '&:hover': {
                            backgroundColor: '#d6e9ff',
                          },
                        }}
                        color='secondary'
                      >
                        <DeleteForeverIcon
                          sx={{ margin: 'auto', color: 'red' }}
                          onClick={() => handleDelete(e.id)}
                        />
                      </Box>
                    </Box>
                  </Box>
                )
              );
            })
          ) : (
            <Typography
              variant='caption'
              gutterBottom
              sx={{
                marginLeft: '40px',
                fontSize: '16px',
                color: 'gray',
                display: 'block',
                marginTop: '-8px',
                marginBottom: '20px',
              }}
            >
              You have not added your Professional Experience!
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            backgroundColor: 'white',
            border: '1px solid #d1d1d1',
            borderRadius: '14px',
            boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
            marginTop: '10px',
          }}
        >
          <Box
            sx={{
              width: '94%',
              marginLeft: '40px',
              display: 'flex',
              marginTop: '20px',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              variant='h5'
              gutterBottom
              sx={{ fontSize: '20px', marginBottom: '26px', display: 'block' }}
            >
              Education
            </Typography>
            <Box>
              <Button
                variant='outlined'
                color='secondary'
                sx={{ height: '30px', width: '75px' }}
                onClick={() => setEducationModel(true)}
              >
                Add
              </Button>
            </Box>
          </Box>
          {education.length ? (
            education.map((e) => {
              return (
                e.user_id === getUserId() && (
                  <Box
                    sx={{
                      display: 'flex',
                      marginBottom: '24px',
                      marginLeft: '40px',
                    }}
                  >
                    <img
                      src='/static/Institute.jpg'
                      alt='Organization Img'
                      style={{
                        width: '50px',
                        height: '50px',
                        transform: 'translateY(8px)',
                      }}
                    />
                    <Box sx={{ marginLeft: '20px', flexGrow: 1 }}>
                      <Typography
                        variant='h6'
                        gutterBottom
                        sx={{
                          fontSize: '16px',
                          marginBottom: '-4px',
                          display: 'block',
                        }}
                      >
                        {e.school}
                      </Typography>
                      <Typography
                        variant='caption'
                        gutterBottom
                        sx={{
                          fontSize: '14px',
                          marginBottom: '-3px',
                          display: 'block',
                        }}
                      >
                        {e.field_of_study}
                      </Typography>

                      <Typography
                        variant='caption'
                        gutterBottom
                        sx={{ fontSize: '14px', display: 'block' }}
                      >
                        {`${moment(e.start_date).format('MMM YYYY')}  -  ${
                          moment(e.end_date).format('MMM YYYY')
                            ? moment(e.end_date).format('MMM YYYY')
                            : 'Present'
                        }`}
                      </Typography>
                    </Box>

                    <Box
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                        marginRight: '2%',
                      }}
                    >
                      <Box
                        sx={{
                          cursor: 'pointer',
                          width: '40px',
                          height: '40px',
                          borderRadius: '50px',
                          display: 'flex',
                          alignItems: 'center',
                          '&:hover': {
                            backgroundColor: '#d6e9ff',
                          },
                        }}
                        onClick={() => {
                          setEditId(e.id);
                          setEditEducationModel(true);
                        }}
                        color='secondary'
                      >
                        <EditIcon sx={{ margin: 'auto' }} />
                      </Box>
                      <Box
                        sx={{
                          cursor: 'pointer',
                          width: '40px',
                          height: '40px',
                          borderRadius: '50px',
                          display: 'flex',
                          alignItems: 'center',
                          '&:hover': {
                            backgroundColor: '#d6e9ff',
                          },
                        }}
                        color='secondary'
                      >
                        <DeleteForeverIcon
                          sx={{ margin: 'auto', color: 'red' }}
                          onClick={() => handleDeleteEduction(e.id)}
                        />
                      </Box>
                    </Box>
                  </Box>
                )
              );
            })
          ) : (
            <Typography
              variant='caption'
              gutterBottom
              sx={{
                marginLeft: '40px',
                fontSize: '16px',
                color: 'gray',
                display: 'block',
                marginTop: '-8px',
                marginBottom: '20px',
              }}
            >
              You have not added your Professional Education!
            </Typography>
          )}
        </Box>

        <Box
          sx={{
            backgroundColor: 'white',
            border: '1px solid #d1d1d1',
            borderRadius: '14px',
            boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
            marginTop: '10px',
          }}
        >
          <Box
            sx={{
              width: '94%',
              marginLeft: '40px',
              display: 'flex',
              marginTop: '20px',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              variant='h5'
              gutterBottom
              sx={{ fontSize: '20px', marginBottom: '26px', display: 'block' }}
            >
              User Links
            </Typography>
            <Box>
              <Button
                variant='outlined'
                color='secondary'
                sx={{ height: '30px', width: '75px' }}
                onClick={() => setAddUserLinksModel(true)}
              >
                Add
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'grid',
              marginLeft: '40px',
              gridTemplateColumns: 'repeat(2, 1fr)',
              gap: '0px 10px',
              marginRight: '20px',
            }}
          >
            {userLinks.length ? (
              userLinks.map((e) => {
                return (
                  e.user_id === getUserId() && (
                    <Box
                      sx={{
                        display: 'flex',
                        marginBottom: '24px',
                        justifyContent: 'space-between',
                        backgroundColor: '#ebf2ff',
                        padding: '8px',
                        borderRadius: '8px',
                        border: '1px solid #c2d2f0',
                        alignItems: 'center',
                      }}
                    >
                      <Box>
                        <Typography
                          gutterBottom
                          sx={{
                            fontSize: '17px',
                            marginBottom: '-4px',
                            display: 'block',
                            fontWeight: '700',
                          }}
                        >
                          {e.platform_name}
                        </Typography>
                        <a
                          href={e.platform_link}
                          target='_blank'
                          rel='noreferrer'
                          style={{
                            fontSize: '15px',
                            color: 'blue',
                            display: 'block',
                            marginTop: '-4px',
                          }}
                        >
                          {e.platform_link}
                        </a>
                      </Box>

                      <Box
                        sx={{
                          cursor: 'pointer',
                          width: '40px',
                          height: '40px',
                          borderRadius: '50px',
                          display: 'flex',
                          alignItems: 'center',
                          '&:hover': {
                            backgroundColor: '#d6e9ff',
                          },
                        }}
                        color='secondary'
                      >
                        <DeleteForeverIcon
                          sx={{ margin: 'auto', color: 'red' }}
                          onClick={() => handleDeleteUserLinks(e.id)}
                        />
                      </Box>
                    </Box>
                  )
                );
              })
            ) : (
              <Typography
                variant='caption'
                gutterBottom
                sx={{
                  fontSize: '16px',
                  color: 'gray',
                  display: 'block',
                  marginTop: '-8px',
                  marginBottom: '20px',
                }}
              >
                You have not added your User Links ex:leetcode, HackerRank, etc.
              </Typography>
            )}
          </Box>
        </Box>

        <Modal
          open={experienceModel}
          onClose={handleCloseExpModel}
        >
          <div>
            <AddExperience
              users={users}
              id={userId}
              handleCloseExpModel={handleCloseExpModel}
              setErrors={setErrors}
              setErrorMessage={setErrorMessage}
              updateUser={setUsers}
              setSuccess={setSuccess}
              getExperienceData={getExperienceData}
            />
          </div>
        </Modal>

        <Modal
          open={expEditModel}
          onClose={() => setExpEditModel(false)}
        >
          <div>
            <EditExperience
              experience={experience}
              id={editId}
              setExpEditModel={setExpEditModel}
              setErrors={setErrors}
              setErrorMessage={setErrorMessage}
              setSuccess={setSuccess}
              getExperienceData={getExperienceData}
            />
          </div>
        </Modal>

        <Modal
          open={educationModel}
          onClose={() => setEducationModel(false)}
        >
          <div>
            <AddEducation
              id={editId}
              setEducationModel={setEducationModel}
              setErrors={setErrors}
              setErrorMessage={setErrorMessage}
              setSuccess={setSuccess}
              getEducationdata={getEducationdata}
            />
          </div>
        </Modal>

        <Modal
          open={editEducationModel}
          onClose={() => setEditEducationModel(false)}
        >
          <div>
            <EditEducation
              education={education}
              id={editId}
              setEditEducationModel={setEditEducationModel}
              setErrors={setErrors}
              setErrorMessage={setErrorMessage}
              setSuccess={setSuccess}
              getEducationdata={getEducationdata}
            />
          </div>
        </Modal>

        <Modal
          open={addUserLinksModel}
          onClose={() => setAddUserLinksModel(false)}
        >
          <div>
            <AddUserLinks
              id={editId}
              setAddUserLinksModel={setAddUserLinksModel}
              setErrors={setErrors}
              setErrorMessage={setErrorMessage}
              setSuccess={setSuccess}
              getUserLinksData={getUserLinksData}
            />
          </div>
        </Modal>
      </Container>
    </Page>
  );
}

import { Stack, Typography } from '@material-ui/core';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { getUserId } from '../../configs/auth';
import { fetchCoachSessionsURL, getHeaders } from '../../apis/urls';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../ToastNotifications/SuccessNotifier';
import CoachingLogsTable from './CoachingLogsTable';

function CoachingLogs() {
  const [coachSessions, setCoachSessions] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const userId = getUserId();

  useEffect(() => {
    getCoachSessions();
    return () => {};
  }, []);

  function getCoachSessions() {
    axios
      .get(fetchCoachSessionsURL(userId), {
        headers: getHeaders(),
      })
      .then((res) => {
        const pendingSessions = [];
        const otherSessions = [];

        (res.data.sessions ?? []).forEach((session) => {
          if (session.status === 'scheduled') {
            pendingSessions.push(session);
          } else {
            otherSessions.push(session);
          }
        });

        setCoachSessions([...pendingSessions, ...otherSessions]);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || 'Could not fetch coaching logs.'
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <Stack>
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      {isSuccess && (
        <SuccessNotifier
          message={successMessage}
          setIsSuccess={setIsSuccess}
        />
      )}
      <CoachingLogsTable
        coachSessions={coachSessions}
        setHasError={setHasError}
        setErrorMessage={setErrorMessage}
        setSuccessMessage={setSuccessMessage}
        setIsSuccess={setIsSuccess}
        setCoachSessions={setCoachSessions}
        loading={loading}
        setLoading={setLoading}
      />
    </Stack>
  );
}

export default CoachingLogs;

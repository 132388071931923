import axios from 'axios';
import moment from 'moment';
import React, { useState, createContext, useEffect } from 'react';
import { fetchUserEnrollmentsUrl, getHeaders } from '../apis/urls';
import ErrorNotifier from '../components/ToastNotifications/ErrorNotifier';
import { slugify } from '../components/utils/common';

export const StudentProgramContext = createContext();

export const StudentProgramProvider = (props) => {
  const [programs, setPrograms] = useState({});
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const today = moment();

  useEffect(() => {
    axios.get(fetchUserEnrollmentsUrl(), {
      headers: getHeaders()
    })
    .then((res) => {
      if (!res.data || res.data.length === 0) { return; }
      const storedAllPrograms = {};
      res?.data?.forEach((enrollmentObj) => {
        if ("program" in enrollmentObj) {
          const date = moment(enrollmentObj.program.start_date);
          const remDays = (date.isAfter(today) ? date.diff(today, 'days') + 10 : 10 - today.diff(date, 'days'));
          
          storedAllPrograms[slugify(enrollmentObj.program.name)] = {
            programId: enrollmentObj.program.id,
            name: enrollmentObj.program.name,
            programAccess: enrollmentObj?.program_access,
            remDays
          }
        }
      })
      setPrograms(storedAllPrograms);
    })
    .catch((e) => {
      console.log(e);
      setHasError(e);
      setErrorMessage(e.response?.data?.message || e.message);
    });
    
    return () => {};
  }, []);

  return (
    <StudentProgramContext.Provider value={{ programs, setPrograms }}>
      { hasError && <ErrorNotifier message={errorMessage} setHasError={setHasError} /> }
      {props.children}
    </StudentProgramContext.Provider>
  );
}

// @ts-nocheck
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import { Backdrop, Stack } from '@material-ui/core';
import {
  AppBar,
  Box,
  Button,
  Dialog,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Toolbar,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import * as XLSX from 'xlsx';
import CircularProgress from '@mui/material/CircularProgress';
import ErrorNotifier from '../../../ToastNotifications/ErrorNotifier';
import LearnerErrorNotifier from '../../../ToastNotifications/LearnerErrorNotifier';
import {
  getHeaders,
  calculateGoogleMeetAttendanceStatus,
  postBulkAttendance,
  BASE_URL,
} from '../../../../apis/urls';
import CalculatedAttendanceTable from './CalculatedAttendanceTable';
import Papa from 'papaparse';

const COACH_PI_EMAIL = 'coach-pi@hych.in';

export default function MarkAttendanceModal({
  openAttendanceModal,
  handleModalClose,
  allSessions,
  setIsSuccess,
  setSuccessMessage,
}) {
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [excelData, setExcelData] = useState([]);
  const [sessionId, setSessionId] = useState(null);
  const [filename, setFilename] = useState('');
  const [perPage, setPerPage] = useState(50);
  const [calculatedAttendancesList, setCalculatedAttendancesList] = useState(
    []
  );
  const [loading, setLoading] = useState(false);
  const currentProgram = useSelector((state) => state.programs.currentProgram);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [attendanceType, setAttendanceType] = useState('google_meet');

  const setCoachDetailsError = () => {
    const message = `An entry for 'coach-pi' is required.`;
    console.log(message); // intentional
    setHasError(true);
    setErrorMessage(message);
  };

  const handleFile = (e) => {
    const fileType = [
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx files
      'application/vnd.ms-excel', // .xls files
      'text/csv', // .csv files
    ];

    const selectedFile = e.target.files[0];
    setFilename(selectedFile?.name ?? '');

    const reader = new FileReader();
    if (selectedFile && fileType.includes(selectedFile.type)) {
      setLoading(true);

      if (selectedFile.type === 'text/csv') {
        reader.onload = (evt) => {
          const csvData = evt.target.result;

          Papa.parse(csvData, {
            header: true,
            skipEmptyLines: true,
            dynamicTyping: true,
            transformHeader: (header) => header.trim(),
            complete: (result) => {
              const fields = getAttendanceSheetFields(attendanceType);
              const data = result.data.map((row) => {
                const completeRow = fields.reduce((acc, field) => {
                  acc[field] = row[field] || '';
                  return acc;
                }, {});
                return completeRow;
              });

              setExcelData(data);
              setLoading(false);
            },
            error: (error) => {
              setHasError(true);
              setErrorMessage('Error parsing CSV file');
              setLoading(false);
            },
          });
        };
        reader.readAsText(selectedFile);
      } else {
        // Handle Excel files
        reader.onload = (evt) => {
          const binaryData = evt.target.result;
          const workbook = XLSX.read(binaryData, { type: 'binary' });
          const wsname = workbook.SheetNames[0];
          const ws = workbook.Sheets[wsname];
          const data = XLSX.utils.sheet_to_json(ws, {
            header: getAttendanceSheetFields(attendanceType),
            range: 1,
          });
          setExcelData(data);
          setLoading(false);
        };
        reader.readAsBinaryString(selectedFile);
      }
    } else {
      setHasError(true);
      setErrorMessage('Please select only Excel or CSV file types');
      handleRemoveFile();
    }
  };

  const submitGoogleMeetAttendance = (e) => {
    if (!sessionId) {
      setHasError(true);
      setErrorMessage('Please select a session');
      return;
    }

    const data = [];
    const rowsFound = excelData.length;
    const colsFound = Object.keys(excelData[0] ?? {}).length;

    if (rowsFound === 0 || colsFound === 0) {
      setHasError(true);
      setErrorMessage('Please upload an excel file.');
      return;
    }

    setLoading(true);

    let hasCoachPiEntry = false;
    for (let i = 0; i < rowsFound; i += 1) {
      const entry = excelData[i];
      const jsonRow = getAttendanceSheetFields(attendanceType);

      for (let j = 0; j < jsonRow.length; j += 1) {
        const col = jsonRow[j];
        if (!entry[col]) {
          entry[col] = '';
        }

        if (entry[col] === '' && col !== 'lname') {
          const message = `This is a required field. row = ${
            i + 2
          }, col = ${col}`;
          console.log(message); // intentional
          setHasError(true);
          setErrorMessage(message);
          handleRemoveFile();
          setLoading(false);
          return;
        }

        if (col === 'email' && entry[col] === COACH_PI_EMAIL) {
          hasCoachPiEntry = true;
        }
      }

      data.push(entry);
    }

    if (!hasCoachPiEntry) {
      setCoachDetailsError();
      handleRemoveFile();
      setLoading(false);
      return;
    }

    const postData = {
      csv_data: data,
      session_id: sessionId,
    };

    axios
      .post(calculateGoogleMeetAttendanceStatus(), postData, {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((res) => {
        setCalculatedAttendancesList(res.data);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || "Couldn't submit the file."
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const submitZoomAttendance = (e) => {
    if (!sessionId) {
      setHasError(true);
      setErrorMessage('Please select a session');
      return;
    }

    const rowsFound = excelData.length;
    const colsFound = Object.keys(excelData[0] ?? {}).length;

    if (rowsFound === 0 || colsFound === 0) {
      setHasError(true);
      setErrorMessage('Please upload a valid csv file.');
      return;
    }

    const data = excelData
      .filter((entry) => entry['Attended'] === 'Yes')
      .map((entry) => {
        return {
          fname: entry['First Name'],
          lname: entry['Last Name'],
          email: entry['Email'].toLowerCase(),
          duration: entry['Time in Session (minutes)'] * 60,
        };
      });

    if (data.length === 0) {
      setHasError(true);
      setErrorMessage('No attendance information found.');
      handleRemoveFile();
      setLoading(false);
      return;
    }

    setLoading(true);

    const postData = {
      csv_data: data,
      session_id: sessionId,
    };

    axios
      .post(`${BASE_URL}/api/attendances/calculate`, postData, {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((res) => {
        setCalculatedAttendancesList(res.data);
      })
      .catch((err) => {
        const errorMessages = Array.isArray(err.response?.data?.absentees)
          ? err.response.data.absentees
          : [err.response?.data?.absentees || "Couldn't submit the file."];
        setHasError(true);
        setErrorMessage(errorMessages);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const submitZohoAttendance = (e) => {
    if (!sessionId) {
      setHasError(true);
      setErrorMessage('Please select a session');
      return;
    }

    const data = [];
    const rowsFound = excelData.length;
    const colsFound = Object.keys(excelData[0] ?? {}).length;

    if (rowsFound === 0 || colsFound === 0) {
      setHasError(true);
      setErrorMessage('Please upload an excel file.');
      return;
    }

    let i = 0;
    // Ignore metadata present in initial rows of the zoho attendance sheet.
    for (; i < excelData.length; i += 1) {
      if (excelData[i]['First Name'] === 'First Name') {
        i += 1;
        break;
      }
    }

    for (; i < excelData.length; i += 1) {
      const entry = excelData[i];
      const jsonRow = getAttendanceSheetFields(attendanceType);

      let ignoreRow = false;
      for (let j = 0; j < jsonRow.length; j += 1) {
        const oldCol = jsonRow[j];
        let col = jsonRow[j];
        if (col === 'First Name') {
          col = 'fname';
        } else if (col === 'Last Name') {
          col = 'lname';
        } else if (col === 'Duration (min)') {
          col = 'duration';
        } else {
          col = col.toLowerCase().split(' ').join('_');
        }

        entry[col] = entry[oldCol];
        delete entry[oldCol];

        if (typeof entry[col] === 'undefined' || entry[col] === null) {
          entry[col] = '';
        }

        if (col === 'attendee_status' && entry[col].toLowerCase() === 'no') {
          ignoreRow = true;
          break;
        }

        if (entry[col] === '' && col !== 'lname') {
          const message = `This is a required field. row = ${
            i + 2
          }, col = ${col}`;
          console.log(message); // intentional
          setHasError(true);
          setErrorMessage(message);
          handleRemoveFile();
          setLoading(false);
          console.log(data, entry, col, oldCol); // intentional until zoho attendance stabilizes
          return;
        }

        if (col === 'joined_time' || col === 'registered_time') {
          entry[col] = excelDateToJSDate(parseInt(entry[col], 10)).utc();
        }
        if (col === 'duration') {
          entry[col] = parseInt(entry[col], 10) * 60; // converting into seconds.
        }
      }

      if (!ignoreRow) {
        data.push(entry);
      }
    }

    if (data.length === 0) {
      setHasError(true);
      setErrorMessage('No attendance information found.');
      handleRemoveFile();
      setLoading(false);
      return;
    }

    setLoading(true);

    const postData = {
      csv_data: data,
      session_id: sessionId,
    };

    axios
      .post(`${BASE_URL}/api/attendances/calculate`, postData, {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((res) => {
        setCalculatedAttendancesList(res.data);
      })
      .catch((err) => {
        const errorMessages = Array.isArray(err.response?.data?.absentees)
          ? err.response.data.absentees
          : [err.response?.data?.absentees || "Couldn't submit the file."];
        setHasError(true);
        setErrorMessage(errorMessages);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const attendanceActions = {
    google_meet: submitGoogleMeetAttendance,
    zoho: submitZohoAttendance,
    zoom: submitZoomAttendance,
  };

  const handleBulkMarkAttendance = () => {
    setLoading(true);

    const bulkAttendance = {
      session_id: sessionId,
    };

    const rowIds = new Set(selectedRowIds);
    bulkAttendance.data = calculatedAttendancesList.filter((row) =>
      rowIds.has(row.user_id)
    );

    axios
      .post(postBulkAttendance(), bulkAttendance, {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((res) => {
        const message = res.data?.message || 'Attendances successfully marked';
        setIsSuccess(true);
        setSuccessMessage(message);
        handleModalClose();
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(err.response?.data?.message || err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRemoveFile = () => {
    setFilename('');
    setExcelData([]);
    setSelectedRowIds([]);
  };

  return (
    <>
      {hasError && Array.isArray(errorMessage) && errorMessage.length > 0 ? (
        <LearnerErrorNotifier
          messages={errorMessage}
          setHasError={setHasError}
        />
      ) : hasError ? (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      ) : null}
      <Dialog
        fullScreen
        open={openAttendanceModal}
        onClose={handleModalClose}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge='start'
              color='inherit'
              onClick={handleModalClose}
            >
              <CloseIcon />
            </IconButton>
            <Typography
              sx={{
                ml: 2,
                flex: 1,
              }}
              variant='h6'
              component='div'
            >
              Bulk upload attendances
            </Typography>
          </Toolbar>
        </AppBar>

        <Backdrop
          sx={{
            color: '#fff',
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={loading}
        >
          <CircularProgress color='inherit' />
        </Backdrop>

        <Typography
          variant='body2'
          component='p'
          m='1em'
        >
          <b>
            *Note: If an Excel file contains multiple sheets, only the first
            sheet will be considered.
          </b>
        </Typography>

        <Stack
          direction='row'
          spacing={2}
          m={2}
        >
          <FormControl
            fullWidth
            size='small'
            sx={{
              width: '20%',
            }}
            disabled={filename !== ''}
          >
            <InputLabel id='attendance-type'>Attendance type</InputLabel>
            <Select
              type='text'
              label='Attendance type'
              labelId='attendance-type'
              value={attendanceType}
              onChange={(e) => setAttendanceType(e.target.value)}
            >
              <MenuItem value='google_meet'>Google Meet</MenuItem>
              <MenuItem value='zoho'>Zoho</MenuItem>
              <MenuItem value='zoom'>Zoom</MenuItem>
            </Select>
          </FormControl>

          <FormControl
            fullWidth
            size='small'
            sx={{
              width: '20%',
            }}
          >
            <InputLabel id='session'>Select Session</InputLabel>
            <Select
              type='text'
              label='Sessions'
              disabled={calculatedAttendancesList.length > 0}
              labelId='session'
              onChange={(e) => setSessionId(e.target.value)}
            >
              <MenuItem value=''>None</MenuItem>
              {Object.keys(allSessions).map((sessionId) => {
                return (
                  <MenuItem value={sessionId}>
                    {allSessions[sessionId].title}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <label htmlFor='contained-button-file'>
            <input
              style={{
                display: 'none',
              }}
              onChange={(e) => handleFile(e)}
              id='contained-button-file'
              type='file'
              value=''
            />
            <Button
              variant='contained'
              color='primary'
              component='span'
              disabled={
                sessionId === '' || (sessionId !== '' && filename !== '')
              }
            >
              {filename !== '' ? 'File uploaded' : 'Upload file'}
            </Button>
          </label>

          {excelData.length > 0 && (
            <>
              <Button
                variant='contained'
                color='primary'
                component='span'
                onClick={() => attendanceActions[attendanceType]?.()}
                disabled={calculatedAttendancesList.length > 0}
              >
                Calculate final attendances
              </Button>
              <Button
                variant='contained'
                color='primary'
                component='span'
                onClick={handleRemoveFile}
              >
                Remove file
              </Button>
            </>
          )}
        </Stack>

        {calculatedAttendancesList.length > 0 && (
          <Stack
            m={2}
            width='100%'
            spacing={1}
          >
            <Typography
              variant='body2'
              component='p'
            >
              *Please approve the attendances to make them permanent in the
              database.
            </Typography>

            <Button
              variant='outlined'
              onClick={handleBulkMarkAttendance}
              sx={{
                width: '12%',
                p: 0,
              }}
              disabled={selectedRowIds.length === 0}
            >
              Mark selected attendances
            </Button>

            <CalculatedAttendanceTable
              {...{
                calculatedAttendancesList,
                perPage,
                setPerPage,
                selectedRowIds,
                setSelectedRowIds,
              }}
            />
          </Stack>
        )}
      </Dialog>
    </>
  );
}

function excelDateToJSDate(serial) {
  const utcDays = Math.floor(serial - 25569);
  const utcValue = utcDays * 86400;
  const dateInfo = new Date(utcValue * 1000);
  const fractionalDay = serial - Math.floor(serial) + 0.0000001;
  let totalSeconds = Math.floor(86400 * fractionalDay);
  const seconds = totalSeconds % 60;
  totalSeconds -= seconds;
  const hours = Math.floor(totalSeconds / (60 * 60));
  const minutes = Math.floor(totalSeconds / 60) % 60;

  return moment(
    new Date(
      dateInfo.getFullYear(),
      dateInfo.getMonth(),
      dateInfo.getDate(),
      hours,
      minutes,
      seconds
    )
  ).utc();
}

function getAttendanceSheetFields(attendanceType) {
  switch (attendanceType) {
    case 'google_meet':
      return [
        'fname',
        'lname',
        'email',
        'duration',
        'time_joined',
        'time_exited',
      ];

    case 'zoom':
      return [
        'Attended',
        'User Name (Original Name)',
        'First Name',
        'Last Name',
        'Email',
        'Registration Time',
        'Approval Status',
        'Join Time',
        'Leave Time',
        'Time in Session (minutes)',
        'Is Guest',
        'Country/Region Name',
      ];

    default:
      return [
        'First Name',
        'Last Name',
        'Email',
        'Attendee Status',
        'Registration Approval Status',
        'Registered Time',
        'Joined Time',
        'Duration (min)',
      ];
  }
}

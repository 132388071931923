import React from 'react';
import { Box, Modal } from '@mui/material';
import TypographyText from '../Atoms/TypographyText';
import '../Templates/ApologyModal.css';

const ApologyModal = ({ openApologyModal, setOpenApologyModal }) => {
  const onCloseModal = () => {
    setOpenApologyModal(false);
  };
  const apologyText =
    'We are really sorry for the issue. We are working on it. Please use the help section to provide your information in order to get the issue resolved. The support team will get back to you on this.';
  return (
    <Modal
      open={openApologyModal}
      onClose={onCloseModal}
      className='modal-container'
    >
      <Box className='apology-modal'>
        <TypographyText
          text={apologyText}
          stylingClass='modal-text'
        />
      </Box>
    </Modal>
  );
};

export default ApologyModal;

import { Typography, Box } from '@mui/material';

const ProgressBox = ({ color, label }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'row',
      gap: '3px',
      alignItems: 'center',
    }}
  >
    <Box
      sx={{
        height: '22px',
        width: '22px',
        backgroundColor: color,
        borderRadius: '4px',
      }}
    />
    <Typography>{label}</Typography>
  </Box>
);

export default ProgressBox;

// @ts-nocheck
import React, { useEffect, useState } from 'react';
import SuccessScreen from '../eligibility-forms/SuccessScreen';
import { Box, Button, Dialog, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import OfferImage from '../../assets/popup_offer.png';
import ConfettiEffect from '../ConfettiEffect/ConfettiEffect';
import { useEfPipeline } from 'src/layouts/EfPipelineContext/EfPipelineContext';
import axios from 'axios';
import { BASE_URL, getHeaders } from 'src/apis/urls';

const FastrackCoupons = () => {
  const { showConfetti, setShowConfetti } = useEfPipeline();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const id = setTimeout(() => {
      setOpen(true);
      setShowConfetti(true);
    }, 1 * 1000); // 1 second

    return () => clearTimeout(id);
  }, []);

  const handleClosePopup = () => {
    setOpen(false);
  };

  const gotToMyCouponsPage = async () => {
    try {
      await axios.post(
        `${BASE_URL}/api/user_activities`,
        {
          status: 'view_my_coupons_click',
        },
        {
          headers: getHeaders(),
        }
      );
    } catch (err) {
      console.log(err);
    } finally {
      navigate('/coupons');
    }
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '10px',
          paddingBottom: '3rem',
          paddingTop: '2rem',
        }}
      >
        <SuccessScreen
          successMessage={
            'Your payment was successfully received. Please contact your POC for further steps.'
          }
        />
      </div>

      {open && showConfetti && <ConfettiEffect />}

      <Dialog
        maxWidth='sm'
        fullWidth
        open={open}
        onClose={handleClosePopup}
        PaperProps={{
          style: {
            maxWidth: '500px',
          },
        }}
      >
        <img
          style={{
            maxWidth: '400px',
            height: '400px',
            display: 'flex',
            alignSelf: 'center',
          }}
          src={OfferImage}
          alt='coupons-offer'
        />
        <Stack
          spacing={1.5}
          padding='1rem'
          maxWidth='inherit'
        >
          <Typography
            variant='h4'
            component='h4'
            sx={{
              fontFamily: 'Inter',
              flexWrap: 'wrap',
            }}
          >
            <b>Congratulations! You've won exclusive coupons</b> 🎉
          </Typography>

          <Typography
            variant='body1'
            component='p'
            sx={{
              fontFamily: 'Inter',
              flexWrap: 'wrap',
            }}
          >
            Special discounts and offers from our partner companies. You can
            find you coupons in <b>profile section</b>.
          </Typography>

          <Box
            display='flex'
            justifyContent='center'
          >
            <Button
              variant='contained'
              onClick={gotToMyCouponsPage}
              sx={{
                fontFamily: 'Inter',
                margin: '10px',
                padding: '11px 18px',
                textAlign: 'center',
                textTransform: 'uppercase',
                transition: '0.5s',
                backgroundSize: '200% auto',
                borderRadius: '5px',
                border: 0,
                fontWeight: 500,
                boxShadow: '0px 0px 14px -7px #f09819',
                backgroundImage:
                  'linear-gradient(45deg, #FBC704 0%, #FDDF71 38%, #FBC704 100%)',
                cursor: 'pointer',
                userSelect: 'none',
                WebkitUserSelect: 'none',
                color: 'black',
                touchAction: 'manipulation',
                '&:hover': {
                  backgroundPosition: 'right center',
                  color: 'black',
                  textDecoration: 'none',
                },
                '&:active': {
                  transform: 'scale(0.95)',
                },
              }}
            >
              View my coupons
            </Button>
          </Box>
        </Stack>
      </Dialog>
    </>
  );
};

export default FastrackCoupons;

import React, { useState } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Button,
  Box,
} from '@mui/material';
import { Stack } from '@material-ui/core';

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function LearnerDropdown({
  learners,
  selectedLearners,
  setSelectedLearners,
}) {
  const [displayedChips, setDisplayedChips] = useState([]);

  const handleChange = (event) => {
    const selectedValues = event.target.value;
    setSelectedLearners(selectedValues);
    updateDisplayedChips(selectedValues);
  };

  const updateDisplayedChips = (selectedValues) => {
    const updatedChips = selectedValues.map((value) => {
      const learner = learners.find((l) => l.id === value);
      return learner ? learner.name : '';
    });
    setDisplayedChips(updatedChips);
  };

  const handleClearSelection = () => {
    setSelectedLearners([]);
    setDisplayedChips([]);
  };

  return (
    <Box sx={{ textAlign: 'left' }}>
      <FormControl
        margin='normal'
        fullWidth
      >
        <InputLabel>Select Learners</InputLabel>
        <Select
          multiple
          value={selectedLearners}
          onChange={handleChange}
          label='Select Learners'
          MenuProps={MenuProps}
        >
          {learners.map((l) => (
            <MenuItem
              key={l.id}
              value={l.id}
            >
              {l.name} ({l.email})
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Stack
        mt={1}
        spacing={1}
        direction='row'
      >
        {displayedChips.map((chipText, index) => (
          <Chip
            key={index}
            label={chipText}
            onDelete={() => {
              const updatedSelected = selectedLearners.filter(
                (value) => value !== selectedLearners[index]
              );
              setSelectedLearners(updatedSelected);
              updateDisplayedChips(updatedSelected);
            }}
          />
        ))}
      </Stack>

      <Button
        variant='outlined'
        color='error'
        onClick={handleClearSelection}
        sx={{ mt: 2 }}
      >
        Clear Selection
      </Button>
    </Box>
  );
}

import React, { useEffect, useState } from 'react';
import { Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import moment from 'moment';
import axios from 'axios';
import { useSelector } from 'react-redux';
import {
  fetchQuizJoinNowURL,
  fetchQuizzesLearners,
  getHeaders,
} from '../../../apis/urls';
import ErrorNotifier from '../../ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../ToastNotifications/SuccessNotifier';
import QuizLayout from './QuizLayout';
import QuizCard from './QuizCard';
import LoadingScreen from '../../../pages/LoadingScreen';

export default function QuizStudentLayout() {
  const [selectedOption, setSelectedOption] = useState('practice');
  const [selectedQuizAttempt, setSelectedQuizAttempt] = useState(null);
  const [allQuizzes, setAllQuizzes] = useState({
    practice: [],
    exam: [],
  });
  const currentProgram = useSelector((state) => state.programs.currentProgram);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (currentProgram) {
      fetchAllQuizzes();
    }
    return () => {};
  }, [currentProgram, selectedQuizAttempt]);

  const fetchAllQuizzes = () => {
    axios
      .get(fetchQuizzesLearners(), {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((res) => {
        const tmpAllQuizzes = {
          practice: [],
          exam: [],
        };
        res.data?.forEach((quiz_attempt, index) => {
          const startTime = moment(quiz_attempt.opens_at);
          const endTime = moment(quiz_attempt.closes_at);

          const quizAttemptInfo = {
            id: quiz_attempt.id,
            quiz_id: quiz_attempt.quiz_id,
            name: quiz_attempt.quiz.title,
            hasJoined: quiz_attempt.has_joined,
            hasSubmitted: quiz_attempt.has_submitted,
            duration: quiz_attempt.duration,
            started_at: quiz_attempt.started_at,
            endTime,
            startTime,
            score: quiz_attempt.score,
            totalMarks: quiz_attempt.quiz.total_marks,
            showScore: quiz_attempt.show_scores,
            quiz_type: quiz_attempt.quiz_type,
          };

          if (quiz_attempt.quiz_type === 'practice') {
            tmpAllQuizzes.practice.push(quizAttemptInfo);
          } else if (quiz_attempt.quiz_type === 'exam') {
            tmpAllQuizzes.exam.push(quizAttemptInfo);
          }
        });
        setAllQuizzes(tmpAllQuizzes);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(err.response?.data?.message || err.message);
      });
  };

  const joinQuiz = (e, quizAttempt) => {
    setLoading(true);
    axios
      .post(
        fetchQuizJoinNowURL(quizAttempt.id),
        { id: quizAttempt.id },
        {
          headers: getHeaders(currentProgram?.programId),
        }
      )
      .then((res) => {
        quizAttempt.started_at = res?.data?.started_at;
        setIsSuccess(true);
        setSuccessMessage('You have successfully joined the quiz.');
        handleQuizClick(e, quizAttempt);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message ||
            'An error occured while joining the quiz'
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleQuizClick = (e, quizAttempt) => {
    setSelectedQuizAttempt(quizAttempt);
  };

  return (
    <>
      <LoadingScreen loading={loading} />
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      {isSuccess && (
        <SuccessNotifier
          message={successMessage}
          setIsSuccess={setIsSuccess}
        />
      )}
      {!selectedQuizAttempt ? (
        <>
          <Box sx={{ m: 2, textAlign: 'right' }}>
            <FormControl>
              <InputLabel>Select Quiz Type</InputLabel>
              <Select
                label='Select Quiz Type'
                value={selectedOption}
                onChange={(e) => setSelectedOption(e.target.value)}
                sx={{ width: '7rem', textAlign: 'left' }}
              >
                <MenuItem value='practice'>Practice</MenuItem>
                <MenuItem value='exam'>Exam</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box
            display='flex'
            flexWrap='wrap'
          >
            {allQuizzes[selectedOption].map((quizAttempt, index) => (
              <QuizCard
                key={quizAttempt?.id}
                selectedQuizAttempt={quizAttempt}
                handleQuizClick={handleQuizClick}
                joinQuiz={joinQuiz}
              />
            ))}
          </Box>
        </>
      ) : (
        <QuizLayout
          selectedQuizAttempt={selectedQuizAttempt}
          setSelectedQuizAttempt={setSelectedQuizAttempt}
        />
      )}
    </>
  );
}

import React from 'react';
import { DataGrid } from '@mui/x-data-grid';

function CalculatedAttendanceTable({
  calculatedAttendancesList,
  perPage,
  setPerPage,
  selectedRowIds,
  setSelectedRowIds,
}) {
  const columns = [
    {
      field: 'id',
      headerName: 'User id',
      width: 230,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => params.row.user_id || 'NA',
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 230,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => params.row.user_name || 'NA',
    },
    {
      field: 'email',
      headerName: 'Email id',
      width: 230,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => params.row.email || 'NA',
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 230,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => params.row.attendance_status || 'NA',
      sortable: true,
    },
  ];

  return (
    <>
      <DataGrid
        sx={{
          width: '55%',
        }}
        rows={calculatedAttendancesList}
        columns={columns}
        pageSize={perPage}
        onPageSizeChange={(pageSize) => setPerPage(pageSize)}
        rowsPerPageOptions={[50, 100]}
        getRowId={(row) => row.user_id}
        rowSelectionModel={selectedRowIds}
        onSelectionModelChange={(ids) => {
          setSelectedRowIds(ids);
        }}
        checkboxSelection
        autoHeight
        disableSelectionOnClick
      />
    </>
  );
}

export default CalculatedAttendanceTable;

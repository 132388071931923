import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import logoImage from '../../assets/full-logo.png';
import smallLogoImage from '../../assets/short-logo.png';

const HeyCoachLogo = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <img
      style={{
        height: !isMobile ? '35px' : '35px',
        cursor: 'pointer',
      }}
      src={!isMobile ? logoImage : smallLogoImage}
      alt='heycoach-logo'
    />
  );
};

export default HeyCoachLogo;

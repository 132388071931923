import React from 'react';
import { Grid, Typography, Button, Box } from '@mui/material';

export default function QuestionsSidebar({ quiz, answered, setOpen }) {
  const getQuestionsStatus = () => {
    return (
      <Grid
        container
        spacing={1}
        sx={{ mt: 4 }}
      >
        {quiz?.quiz_questions.map((question, index) => (
          <Grid
            item
            key={question.id}
          >
            <Button
              style={{
                p: '10px',
                textAlign: 'center',
                cursor: 'pointer',
              }}
              variant={
                answered.includes(question.id) ? 'contained' : 'outlined'
              }
            >
              <Typography variant='body1'>{index + 1}</Typography>
            </Button>
          </Grid>
        ))}
      </Grid>
    );
  };
  return (
    <>
      <Box
        width='30%'
        border='2px solid #b5e2ff'
        sx={{ p: 4 }}
      >
        <Box
          textAlign='center'
          border='2px solid #b5e2ff'
          sx={{ p: 2 }}
        >
          <Button
            variant='outlined'
            color='error'
            onClick={() => setOpen(true)}
          >
            End Quiz
          </Button>
        </Box>
        {getQuestionsStatus()}
        <Box
          sx={{ mt: 3 }}
          display='flex'
          flexDirection='column'
        >
          <Box>
            <Button
              display='flex'
              variant='outlined'
              sx={{
                width: '30px',
                height: '30px',
                borderColor: '#4060f5',
                mr: 2,
              }}
            />
            <Typography variant='subtitle'>Not Answered</Typography>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Button
              variant='contained'
              display='flex'
              sx={{
                width: '30px',
                height: '30px',
                backgroundColor: '#4060f5',
                mr: 2,
              }}
            />
            <Typography variant='subtitle'>Answered</Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
}

import { Dialog, DialogActions, Button, DialogTitle, DialogContent, DialogContentText, Stack, TextField } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getUserType } from '../../../configs/auth';
import { getHeaders, UpdateSubmission } from '../../../apis/urls';

export default function GradeAssignmentSubmission({
    submission,
    assignment_id, 
    setShouldGradeAssignment,
    shouldGradeAssignment,
    setHasError,
    setIsSuccess,
    setErrorMessage,
    setSuccessMessage
}) {
    const userType = getUserType();
    const [Score, setScore] = useState();
    const [Feedback, setFeedback] = useState();
    const currentProgram = useSelector(state => state.programs.currentProgram);

    const descriptionElementRef = React.useRef(null);

    useEffect(() => {
        if (shouldGradeAssignment) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [shouldGradeAssignment]);

    function SubmitGrading() {
        if (Score && Score >= 0 && Score <= 100) {
            fetch(UpdateSubmission(assignment_id, submission?.id), {
                method: 'PUT',
                headers: getHeaders(currentProgram.programId),
                body: JSON.stringify({
                    score: Score,
                    feedback: Feedback
                })
            })
            .then((resp) => resp.json())
            .then((data) => {
                if (data) {
                    setIsSuccess(true);
                    setSuccessMessage('Successfully Graded');
                    setShouldGradeAssignment(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setHasError(true);
                setErrorMessage(err.response?.data?.message || 'error grading assignment');
            });
        } else {
            setHasError(true);
            setErrorMessage('Score should be in the range 0 to 100');
        }
    }
    
    return (
        <>
            <Dialog
                open={shouldGradeAssignment}
                onClose={() => setShouldGradeAssignment(false)}
                scroll="paper"
                fullWidth
                maxWidth="md"
            >
                <DialogActions>
                    <Button onClick={() => setShouldGradeAssignment(false)}>Close</Button>
                </DialogActions>
                <DialogTitle >Grade submission</DialogTitle>
                
                <DialogContent dividers>
                    <DialogContentText
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        <Stack
                            style={{
                                margin: 10
                            }}
                        >
                            <TextField
                                fullWidth
                                label={submission?.score == null && 'Give Score In the range of 0 to 100'}
                                value={Score}
                                onChange={(e) => setScore(e.target.value)}
                                placeholder={submission?.score != null ? submission.score : ""}
                            />
                        </Stack>
                        <Stack
                            style={{
                                margin: 10
                            }}
                        >
                            <TextField
                                label={submission?.feedback == null && 'Give Feedback For this submission'}
                                onChange={(e) => setFeedback(e.target.value)}
                                multiline
                                rows={4}
                                fullWidth
                                placeholder={submission?.feedback != null ? submission?.feedback : ""}
                            />
                        </Stack>
                        <Button
                            style={{
                                alignSelf: 'center',
                                marginTop: 10
                            }}
                            fullWidth
                            onClick={() => SubmitGrading()}
                            variant="contained"
                        >
                            {submission?.feedback != null && submission?.score != null
                                ? 'Update Grade'
                                : 'Grade Assignment'}
                        </Button>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </>
    );
}
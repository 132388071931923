import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { fetchTaskCommentsURL, getHeaders, postTaskCommentURL } from '../../../apis/urls';
import SuccessNotifier from '../../ToastNotifications/SuccessNotifier';
import ErrorNotifier from '../../ToastNotifications/ErrorNotifier';
import Comments from '../../Comments/Comments';

export default function LearnerTaskDiscussions({ task }) {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const currentProgram = useSelector(state => state.programs.currentProgram);

  useEffect(() => {
    getComments();
    return () => {};
  }, []);

  function getComments() {
    axios.get(fetchTaskCommentsURL(task.id), {
      headers: getHeaders(currentProgram?.programId)
    })
    .then((res) => {
      setComments(res.data);
      setLoading(false);
    })
    .catch((err) => {
      console.log(err);
      setHasError(true);
      setErrorMessage(err?.response?.data?.message || err.message);
    })
  }

  const postComment = () => {
    axios.post(postTaskCommentURL(), {
      task_id: task.id,
      note: newComment
    }, {
      headers: getHeaders(currentProgram?.programId)
    })
    .then((res) => {
      setNewComment("");
      setIsSuccess(true);
      setSuccessMessage("Comment successfully created.");
      setComments([...comments, res.data]);
    })
    .catch((err) => {
      console.log(err);
      setHasError(true);
      setErrorMessage(err?.response?.data?.message || err.message);
    })
  }

  return (
    <div>
      {hasError && <ErrorNotifier message={errorMessage} setHasError={setHasError}/> }
      {isSuccess && <SuccessNotifier message={successMessage} setIsSuccess={setIsSuccess} />}
      <Comments {...{ comments, postComment, newComment, setNewComment, loading, setLoading }} />
    </div>
  );
}

import React, { useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { FiberManualRecord } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import VerifiedIcon from '@mui/icons-material/Verified';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import CustomTabPanel from '../CustomTabPanel';
import { CheckIthBitSetConstant } from '../../../utils/techniqueSagaConstant';
import TopicTitle from '../TopicTitle';
import ListRenderComponent from '../ListRenderComponent';
import CustomButton from '../../../practice/MindMaps/Atoms/CustomButton';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import SubArrayOne from '../../../../images/SubArrayOne.png';
import SubArrayTwo from '../../../../images/SubArrayTwo.png';
import SubArrayThree from '../../../../images/SubArrayThree.png';
import SubArrayFour from '../../../../images/SubArrayFour.png';
import SubArrayFive from '../../../../images/SubArrayFive.png';
import SubArraySix from '../../../../images/SubArraySix.png';
import SubArray from '../../../../images/SubArray.gif';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const MinimumSizeSubarraySum = ({
  handlePostCompletedTopic,
  hasMarkedCompleted,
  loading,
  topic_id,
  handleNext,
  handlePrevious,
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap='36px'
      paddingY={2}
      maxWidth='calc(100% - 300px)'
    >
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
      >
        <TopicTitle>4. Minimum Size Subarray Sum</TopicTitle>
        {hasMarkedCompleted(topic_id) && (
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              color: 'green',
              fontWeight: '500',
              border: '2px solid green',
              padding: '4px 10px',
              borderRadius: '20px',
              cursor: 'default',
            }}
          >
            Completed
          </Typography>
        )}
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        gap='24px'
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            width: 'fit-content',
            background: '#FEF7FF',
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
          >
            <Tab
              label='Problem Statement'
              {...a11yProps(0)}
            />
            <Tab
              label='Brute Force Approach'
              {...a11yProps(1)}
            />
            <Tab
              label='Two Pointer Approach'
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <CustomTabPanel
          value={value}
          index={0}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Typography>
              Given an array of positive integers nums and a positive integer s,
              find the minimal length of a contiguous subarray of which the sum
              is greater than or equal to s. If there is no such subarray,
              return 0 instead.
            </Typography>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={1}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Typography>
              Whenever we have to deal with finding a particular subarray, the
              brute force way of doing that would be to search all the possible
              sub-arrays and find the answer from that. In this it involves
              checking all possible subarrays and keeping track of the smallest
              one that meets the sum requirement.
            </Typography>

            <Box
              display='flex'
              flexDirection='column'
              gap='10px'
            >
              <List>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecord fontSize='8px' />
                  </ListItemIcon>
                  <ListItemText primary='Iterate over all starting points of subarrays.' />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecord fontSize='8px' />
                  </ListItemIcon>
                  <ListItemText primary='For each starting point, iterate over all ending points and compute the sum of the subarray. This gives you all the subarrays that are starting from a particular index.' />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecord fontSize='8px' />
                  </ListItemIcon>
                  <ListItemText primary='If the sum is greater than or equal to s, update the minimum length.' />
                </ListItem>
              </List>
            </Box>

            <Box
              sx={{
                backgroundColor: 'black',
                padding: '24px',
                borderRadius: '10px',
                width: '705px',
                overflowX: 'auto',
              }}
            >
              <pre style={{ color: 'white', margin: 0 }}>
                {`function minSubArrayLen(s, nums):
    n = length of nums
    minLength = INFINITY

    for i from 0 to n-1:
        currentSum = 0
        for j from i to n-1:
            currentSum = currentSum + nums[j]
            if currentSum >= s:
                minLength = minimum(minLength, j - i + 1)
                break

    if minLength == INFINITY:
        return 0
    else:
        return minLength
`}
              </pre>
            </Box>

            <Typography>
              Since we are checking all the possible subarrays in order to
              figure out the required subarray, it will be in the order of n^2
              and no extra space is being used.
            </Typography>

            <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>
              Time and Space Complexity
            </Typography>

            <Typography>Time Complexity : O(n^2)</Typography>

            <Typography>Space Complexity : O(1)</Typography>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={2}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Typography>
              The trick here is to read the description very carefully; it’s
              fine as long as you find a contiguous subarray equal OR greater
              than the target. Another very important point in the constraints,
              is that the nums will always be positive integers bigger than 0.
              Meaning the number will always increase as you move forward.
            </Typography>

            <Typography>
              Last important point is that the subarray can actually be 1,
              meaning 1 value is fine if it’s equal to or larger than our
              target.We start our two pointers at the very start of the array,
              and check if it’s larger than the target. Thereafter we’ll move
              the right pointer to the right until we get to a number equal to
              or larger than our target.
            </Typography>

            <Box
              display='flex'
              flexDirection='column'
              gap='10px'
            >
              <List>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecord fontSize='8px' />
                  </ListItemIcon>
                  <ListItemText primary='Use two pointers, left and right, to represent the current window.' />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecord fontSize='8px' />
                  </ListItemIcon>
                  <ListItemText primary='Expand the window by moving the right pointer and adding the current element to the sum.' />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecord fontSize='8px' />
                  </ListItemIcon>
                  <ListItemText primary='Once the sum is greater than or equal to s, update the minimum length and shrink the window by moving the left pointer.' />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecord fontSize='8px' />
                  </ListItemIcon>
                  <ListItemText primary='Continue until the right pointer reaches the end of the array.' />
                </ListItem>
              </List>
            </Box>

            <Typography>
              For example consider this array : {(2, 3, 1, 2, 4, 3)} and the
              target sum is 7. So you need to figure out the smallest subarray
              with a sum greater than or equal to 7.
            </Typography>

            <img
              src={SubArrayTwo}
              alt=''
              style={{ height: '70px', width: '400px', text: 'center' }}
            />

            <Typography>
              Initially, both pointers will be at the first element, and a
              single element being a subarray with a sum of 2, which is 2 &lt;
              7, so we will move the right pointer.
            </Typography>
            <img
              src={SubArrayTwo}
              alt=''
              style={{ height: '70px', width: '400px', text: 'center' }}
            />

            <Typography>
              Again, 2 + 3 = 5 &lt; 7, so we need to move the right pointer
              again forward to increase the sum.
            </Typography>

            <img
              src={SubArrayThree}
              alt=''
              style={{ height: '70px', width: '400px', text: 'center' }}
            />
            <Typography>
              Again, the sum comes out to be 6 &lt; 7, so we will increment the
              right pointer.
            </Typography>
            <img
              src={SubArrayFour}
              alt=''
              style={{ height: '70px', width: '400px', text: 'center' }}
            />
            <Typography>
              Now we have the required sum so we have an answer where the window
              size is now 4. To see if we can find a better answer we will
              increment the left pointer.
            </Typography>
            <img
              src={SubArrayFive}
              alt=''
              style={{ height: '70px', width: '400px', text: 'center' }}
            />

            <Typography>
              Now we again have a subarray with sum &lt; 7, so we will increment
              the right pointer. We will keep on doing this, and at the end, we
              will reach the below stage.
            </Typography>

            <img
              src={SubArraySix}
              alt=''
              style={{ height: '70px', width: '400px', text: 'center' }}
            />
            <Typography>
              We have the sum as 7 which is the required sum, additionally we
              also have figure out the smallest subarray with sum greater than
              or equal to 7. Below is an animated representation for it.
            </Typography>

            <img
              src={SubArray}
              alt=''
              style={{ height: '150px', width: '400px', text: 'center' }}
            />

            <Typography>
              Now let us look at the implementation of it.
            </Typography>

            <Box
              sx={{
                backgroundColor: 'black',
                padding: '24px',
                borderRadius: '10px',
                width: '583px',
              }}
            >
              <pre style={{ color: 'white', margin: 0 }}>
                {`function minSubArrayLen(s, nums):
    n = length of nums
    minLength = INFINITY
    left = 0
    currentSum = 0

    for right from 0 to n-1:
        currentSum = currentSum + nums[right]

        while currentSum >= s:
            minLength = minimum(minLength, right - left + 1)
            currentSum = currentSum - nums[left]
            left = left + 1

    if minLength == INFINITY:
        return 0
    else:
        return minLength
`}
              </pre>
            </Box>

            <Box>
              <Typography>
                The algorithm is an example of the sliding window using two
                pointers. The outer for loop is a loop that goes through each
                element in the nums array once. So, the operations inside this
                loop will be executed n times, where n is the number of elements
                in nums. Now talking about the inner while loop, in the
                worst-case scenario, the while loop (together with the for loop)
                will still only process each element in nums once -- adding it
                to cum_sum when increasing right, and subtracting it from
                cum_sum when increasing left. Therefore, this inner while loop
                does not multiply with the outer for loop when calculating time
                complexity, rather, it adds to it.
              </Typography>
            </Box>

            <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>
              Time and Space Complexity
            </Typography>

            <Box>
              <Typography>Time Complexity : O(n)</Typography>

              <Typography sx={{ marginTop: '20px' }}>
                Space Complexity : O(1)
              </Typography>
            </Box>
          </Box>
        </CustomTabPanel>
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        alignSelf='end'
        justifyContent='space-between'
        gap='12px'
      >
        {!hasMarkedCompleted(topic_id) && (
          <LoadingButton
            variant='contained'
            onClick={() => handlePostCompletedTopic(topic_id)}
            loading={loading}
            loadingPosition='center'
            children='Mark as completed'
            style={{
              width: '170px',
              // backgroundColor: 'transparent',
              borderRadius: '8px',
              border: '1px solid rgba(64, 96, 245, 0.5)',
            }}
          />
        )}

        <Box
          display='flex'
          alignItems='center'
          gap='8px'
        >
          <Button
            sx={{ gap: '4px' }}
            onClick={handlePrevious}
          >
            <WestIcon /> Previous
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default MinimumSizeSubarraySum;

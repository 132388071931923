import React from 'react';
import Box from '@mui/material/Box';

const RightArrowCircle = ({ rotateCircles }) => {
  return (
    <Box
      style={{
        position: 'absolute',
        top: '85%',
        left: '51%',
        transform: 'translateY(-50%)',
        display: 'inline-block',
        width: '50px',
        height: '50px',
        marginLeft: '25px',
        margin: '5px',
        cursor: 'pointer',
      }}
      onClick={() => rotateCircles('right')}
    >
      <svg
        width='45'
        height='45'
        viewBox='0 0 64 64'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <circle
          cx='32'
          cy='32'
          r='31.5'
          fill='white'
          stroke='#424242'
        />
        <path
          d='M48.7071 32.7071C49.0976 32.3166 49.0976 31.6834 48.7071 31.2929L42.3431 24.9289C41.9526 24.5384 41.3195 24.5384 40.9289 24.9289C40.5384 25.3195 40.5384 25.9526 40.9289 26.3431L46.5858 32L40.9289 37.6569C40.5384 38.0474 40.5384 38.6805 40.9289 39.0711C41.3195 39.4616 41.9526 39.4616 42.3431 39.0711L48.7071 32.7071ZM16 33H48V31H16V33Z'
          fill='#424242'
        />
      </svg>
    </Box>
  );
};

export default RightArrowCircle;

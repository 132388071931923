import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
  Typography,
} from '@material-ui/core';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Box, TextField } from '@mui/material';
import { useSelector } from 'react-redux';
import CodeMirrorEditor from '../../CodeMirrorEditor/CodeMirrorEditor';
import { LanguageSelect } from '../../practice/CodeEditor/LanguageSelect';
import {
  getHeaders,
  postCodeProblemSolution,
  postCodeRunURL,
  postCodeSkeleton,
  postCodingTestProblemSolutionURL,
  updateCodeProblemSolution,
  updateCodeSkeleton,
} from '../../../apis/urls';
import { getSubmissionStatusForClient } from '../../practice/CodingTest/DetailedTest/CodingTestCodeEditor';

const skeletonOptions = [
  'Header',
  'Define class',
  'Solution class',
  'Expected solution',
  'Driver code',
];
const initialSkeletonState = {
  id: -1,
  skeleton_header: '',
  skeleton_solution_class: '',
  skeleton_driver_code: '',
  skeleton_define_class: '',
  skeleton_class_end: '',
  skeleton_expected_solution: '',
};

export default function CodeSkeleton({
  allSkeletons,
  problemDetails,
  setLanguage,
  language,
  allSolutions,
  setAllSkeletons,
  problemId,
  setErrorMessage,
  setHasError,
  setSuccessMessage,
  setIsSuccess,
  openTestcase,
  setOpenTestcase,
}) {
  const [skeleton, setSkeleton] = useState(initialSkeletonState);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [updateSkeleton, setUpdateSkeleton] = useState(false);
  const [stdin, setStdin] = useState('');
  const [stdout, setStdout] = useState('');
  const [editorProps, setEditProps] = useState({
    content: '',
    languageType: language.languageName,
    handleContentChange: () => {},
  });
  const [classEndEditorProps, setClassEndEditorProps] = useState({
    content: '',
    languageType: language.languageName,
    handleContentChange: () => {},
  });
  const [newIdealSolution, setNewIdealSolution] = useState({
    content: ``,
    languageType: language.languageName,
    handleContentChange: () => {},
  });

  useEffect(() => {
    if (problemDetails.defaultTestcase) {
      setStdin(
        problemDetails.defaultTestcase.input
          ? problemDetails.defaultTestcase.input
          : ''
      );
    }
    setStdout('');
    return () => {};
  }, [problemDetails]);

  useEffect(() => {
    if (language.languageId in allSkeletons) {
      setSkeleton(allSkeletons[language.languageId]);
    } else {
      setSkeleton(initialSkeletonState);
    }
    return () => {};
  }, [language, allSkeletons, allSolutions]);

  useEffect(() => {
    const { customProps, customPropsClassEnd } = getCodeSkeletonEditorProps();
    setEditProps(customProps);
    setClassEndEditorProps(customPropsClassEnd);
    setNewIdealSolution({
      content: `${skeleton.skeleton_header ?? ''}\n\n${
        skeleton.skeleton_define_class ?? ''
      }\n${skeleton.skeleton_expected_solution ?? ''}\n${
        skeleton.skeleton_class_end ?? ''
      }\n\n${skeleton.skeleton_driver_code ?? ''}`,
      languageType: language.languageName,
    });
  }, [language, selectedIndex, skeleton, allSkeletons]);
  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleTestcaseChange = (event) => {
    setStdin(event.target.value);
  };

  function handleSkeletonUpdate(variable, language, skeleton, keyName) {
    variable.content = skeleton[keyName] ?? '';
    variable.handleContentChange = (value) =>
      setSkeleton({ ...skeleton, [keyName]: value });
    variable.languageType = language?.languageName;
  }
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const getCodeSkeletonEditorProps = () => {
    const customProps = {};
    const customPropsClassEnd = {};

    if (selectedIndex === 0) {
      handleSkeletonUpdate(customProps, language, skeleton, 'skeleton_header');
    } else if (selectedIndex === 1) {
      handleSkeletonUpdate(
        customProps,
        language,
        skeleton,
        'skeleton_define_class'
      );
      //  define class end
      handleSkeletonUpdate(
        customPropsClassEnd,
        language,
        skeleton,
        'skeleton_class_end'
      );
    } else if (selectedIndex === 2) {
      handleSkeletonUpdate(
        customProps,
        language,
        skeleton,
        'skeleton_solution_class'
      );
    } else if (selectedIndex === 3) {
      handleSkeletonUpdate(
        customProps,
        language,
        skeleton,
        'skeleton_expected_solution'
      );
    } else {
      handleSkeletonUpdate(
        customProps,
        language,
        skeleton,
        'skeleton_driver_code'
      );
    }
    return { customProps, customPropsClassEnd };
  };

  const handleSkeletonValidation = () => {
    if (
      !skeleton.skeleton_header &&
      !skeleton.skeleton_header.trim() &&
      language.languageId !== 71 &&
      language.languageId !== 63
    ) {
      setHasError(true);
      setErrorMessage('To save the solution, Skeleton Header cannot be empty');
      return false;
    }
    if (
      !skeleton.skeleton_define_class ||
      !skeleton.skeleton_define_class.trim()
    ) {
      setHasError(true);
      setErrorMessage(
        'To save the solution, Skeleton Define Class cannot be empty'
      );
      return false;
    }
    if (
      skeleton.skeleton_solution_class &&
      skeleton.skeleton_solution_class.trim()
    ) {
      setHasError(true);
      setErrorMessage(
        'To save or update a new solution, please empty the Solution Class'
      );
      return false;
    }
    if (
      !skeleton.skeleton_expected_solution ||
      !skeleton.skeleton_expected_solution.trim()
    ) {
      setHasError(true);
      setErrorMessage(
        ' To save the solution, Skeleton Expected Solution cannot be empty'
      );
      return false;
    }
    if (
      !skeleton.skeleton_driver_code ||
      !skeleton.skeleton_driver_code.trim()
    ) {
      setHasError(true);
      setErrorMessage(
        ' To save the solution, Skeleton Driver Code cannot be empty'
      );
      return false;
    }
    return true;
  };

  const handleSaveSkeleton = () => {
    if (!handleSkeletonValidation()) {
      return;
    }

    let response;
    const body = {
      problem_id: problemId,
      language_id: language.languageId,
      skeleton_header: skeleton.skeleton_header,
      skeleton_define_class: skeleton.skeleton_define_class,
      skeleton_class_end: skeleton.skeleton_class_end,
      skeleton_solution_class: skeleton.skeleton_solution_class,
      skeleton_driver_code: skeleton.skeleton_driver_code,
      skeleton_expected_solution: skeleton.skeleton_expected_solution,
    };
    if (!(language.languageId in allSkeletons)) {
      // post
      response = axios.post(postCodeSkeleton(), body, {
        headers: getHeaders(),
      });
    } else {
      response = axios.patch(updateCodeSkeleton(skeleton.id), body, {
        headers: getHeaders(),
      });
    }

    response
      .then((res) => {
        const data = { ...allSkeletons };
        data[language.languageId] = {
          id: res.data.id,
          skeleton_header: res.data.skeleton_header,
          skeleton_solution_class: res.data.skeleton_solution_class,
          skeleton_driver_code: res.data.skeleton_driver_code,
          skeleton_define_class: res.data.skeleton_define_class,
          skeleton_expected_solution: res.data.skeleton_expected_solution,
          skeleton_class_end: res.data.skeleton_class_end,
        };
        setAllSkeletons(data);
        setIsSuccess(true);
        setSuccessMessage('code skeleton successfully updated');
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(err.response?.data?.message || err.message);
      });
  };

  const handleCodeRun = () => {
    const body = {
      language_id: language.languageId,
      source_code: `${skeleton.skeleton_define_class ?? ''}\n${
        skeleton.skeleton_expected_solution ?? ''
      }\n${skeleton.skeleton_class_end ?? ''}`,
      stdin: stdin.length === 0 ? null : stdin,
    };

    body.problem_id = problemId;

    setStdout('Submitting...');
    setIsSuccess(true);
    setSuccessMessage('Submitting...');
    axios
      .post(postCodeRunURL(), body, {
        headers: getHeaders(),
      })
      .then((res) => {
        const status = getSubmissionStatusForClient(res.data);
        setStdout(status.message);

        if (status?.verdict) {
          setIsSuccess(true);
          setSuccessMessage(status?.description ?? 'Submitted');
        } else {
          setHasError(true);
          setErrorMessage(status?.description ?? 'Submitted');
        }
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(err.response?.data?.message || err.message);
        setStdout('Submitted');
      });
  };

  const handleUpdateSkeleton = (type) => {
    if (type === 'cancel') {
      if (!(language.languageId in allSkeletons)) {
        setSkeleton(initialSkeletonState);
      } else {
        setSkeleton(allSkeletons[language.languageId]);
      }
    } else if (type === 'save') {
      handleSaveSkeleton();
    }
    setUpdateSkeleton(!updateSkeleton);
  };

  return (
    <Stack
      spacing={2}
      sx={{ pt: 5 }}
    >
      <Stack
        direction='row'
        spacing={2}
        sx={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Typography variant='h3'>Code skeleton</Typography>

        <Stack
          direction='row'
          spacing={2}
          sx={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          {updateSkeleton && (
            <Button
              variant='contained'
              onClick={(e) => handleUpdateSkeleton('cancel')}
            >
              Cancel skeleton update
            </Button>
          )}
          <Button
            variant='contained'
            onClick={(e) =>
              handleUpdateSkeleton(updateSkeleton ? 'save' : 'update')
            }
          >
            {updateSkeleton ? 'Save skeleton' : 'Update skeleton'}
          </Button>

          <LanguageSelect
            setSelectedLanguage={setLanguage}
            selectedLanguage={language}
          />

          <ButtonGroup
            variant='contained'
            ref={anchorRef}
          >
            <Button>{skeletonOptions[selectedIndex]}</Button>
            <Button
              size='small'
              onClick={handleToggle}
            >
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
        </Stack>

        <Popper
          style={{
            zIndex: 1,
          }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    id='split-button-menu'
                    autoFocusItem
                  >
                    {skeletonOptions.map((option, index) => (
                      <MenuItem
                        key={option}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Stack>
      {selectedIndex === 2 && (
        <Typography
          variant='caption'
          fontSize='17px'
          color='#ff790d'
          fontWeight='500'
        >
          Caution: Your code edits will be instantly reflected on the learner's
          side. Write with care and precision.
        </Typography>
      )}
      {selectedIndex === 1 ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '310px',
          }}
        >
          <Box>
            <Typography
              variant='caption'
              fontSize='15px'
              color='#ff790d'
              fontWeight='500'
            >
              Note: Please add only the class definition without the closing
              braces or class end in this editor.
            </Typography>
            <CodeMirrorEditor
              {...editorProps}
              options={{
                readOnly: !updateSkeleton,
                editable: updateSkeleton,
                height: '140px',
                placeholder: 'Define your class here',
              }}
            />
          </Box>
          <Box>
            <Typography
              variant='caption'
              fontSize='15px'
              color='#ff790d'
              fontWeight='500'
            >
              Note: Please add only the end of your class definition in this
              editor. Include the closing braces and any concluding code for
              your class.
            </Typography>
            <CodeMirrorEditor
              {...classEndEditorProps}
              options={{
                readOnly: !updateSkeleton,
                editable: updateSkeleton,
                height: '80px',
                placeholder: 'End of your class definition',
              }}
            />
          </Box>
        </Box>
      ) : (
        <CodeMirrorEditor
          {...editorProps}
          options={{
            readOnly: !updateSkeleton,
            editable: updateSkeleton,
            height: '500px',
          }}
        />
      )}
      <Stack
        direction='row'
        spacing={2}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingTop: '40px',
        }}
      >
        <Typography variant='h3'>New Solution</Typography>
        <Box
          display='flex'
          justifyContent='flex-end'
          sx={{
            mb: 1,
          }}
          alignItems='center'
        >
          {Object.keys(allSkeletons).length > 0 && (
            <Button
              variant='contained'
              sx={{ marginRight: '20px' }}
              onClick={(e) => setOpenTestcase(true)}
            >
              Add Testcase
            </Button>
          )}
          <Button
            variant='contained'
            color='success'
            sx={{
              width: '8rem',
              height: 40,
              mr: 1,
            }}
            onClick={() => handleCodeRun()}
          >
            Run
          </Button>
        </Box>
      </Stack>
      <CodeMirrorEditor
        {...newIdealSolution}
        options={{
          readOnly: true,
          editable: false,
          height: '500px',
        }}
      />
      <Stack
        direction='row'
        spacing={2}
      >
        <Stack style={{ width: '50%' }}>
          <Typography
            variant='body2'
            pl='1em'
          >
            Stdin
          </Typography>
          <TextField
            minRows='10'
            maxRows='10'
            multiline
            defaultValue={stdin}
            style={{
              padding: 7,
            }}
            onChange={handleTestcaseChange}
          />
        </Stack>

        <Stack style={{ width: '50%' }}>
          <Typography
            variant='body2'
            pl='1em'
          >
            Stdout
          </Typography>
          <TextField
            minRows='10'
            maxRows='10'
            multiline
            defaultValue={stdout}
            style={{
              padding: 7,
            }}
            inputProps={{ readOnly: true }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}

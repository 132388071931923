import React from 'react';
import ReactDOM from 'react-dom';
import { Box, Card } from '@material-ui/core';

const MODAL_STYLES = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    zIndex: 1000,
    padding: '30px',
    backgroundColor: '#fff',
    transform: 'translate(-50%,-50%)'
};
const OVERLAY_STYLES = {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: 'rgba(0,0,0,0.7)',
    zIndex: 1000
};
function ModalAssignment({ children, isOpen }) {
    function handleClose() {
        isOpen(false);
    }
    return ReactDOM.createPortal(
        <>
            <Card
                onClick={() => isOpen(false)}
                sx={{
                    position: 'fixed',
                    top: '50%',
                    left: '50%',
                    zIndex: 10000,
                    padding: '30px',
                    backgroundColor: '#fff',
                    transform: 'translate(-50%,-50%)'
                }}
            />
            <div
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    backgroundColor: 'rgba(0,0,0,0.7)',
                    zIndex: 10000
                }}
            >
                {children}
            </div>
        </>,
        document.getElementById('portal')
    );
}

export default ModalAssignment;

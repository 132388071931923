import React from 'react';
import { Box, Divider } from '@mui/material';

const DividerComponent = () => {
  return (
    <Box
    // sx={{
    //   height: '',
    // }}
    >
      <Divider
        orientation='horizontal'
        sx={{
          backgroundColor: 'black',
          height: '100%',
        }}
      />
    </Box>
  );
};

export default DividerComponent;

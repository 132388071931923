// @ts-nocheck
import { Stack, Typography } from '@material-ui/core';
import { Box } from '@material-ui/system';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ErrorNotifier from '../../ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../ToastNotifications/SuccessNotifier';
import {
  fetchAllCodingTestsURL,
  fetchCodingTestJoinNowURL,
  getHeaders,
} from '../../../apis/urls';
import { TestFilters } from '../../_dashboard/Programs/CodingTests/CodingTests';
import ProgramNotStarted from '../../ProgramNotStarted';
import CodingTestCard from './CodingTestCard';
import DetailedTest from './DetailedTest/DetailedTest';

function CodingTestsList() {
  const currentProgram = useSelector((state) => state.programs.currentProgram);
  const [allCodingTests, setAllCodingTests] = useState({
    ongoing: [],
    upcoming: [],
    past: [],
  });
  const [selectedTest, setSelectedTest] = useState(null);
  const [testFilter, setTestFilter] = useState('ongoing');
  const [hasError, setHasError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    if (currentProgram) {
      fetchCodingTests();
    }
    return () => {};
  }, [isSuccess, selectedTest, currentProgram]);

  if (!currentProgram?.hasProgramStarted) {
    return <ProgramNotStarted />;
  }

  const fetchCodingTests = () => {
    axios
      .get(fetchAllCodingTestsURL(), {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((res) => {
        const tmpAllCodingTests = {
          ongoing: [],
          upcoming: [],
          past: [],
        };

        res.data?.forEach((test, index) => {
          console.log(test);
          if (!test?.start_time || test?.level_sets_id) {
            return;
          }
          console.log('test');
          const startTime = moment(test.start_time);
          const endTime = moment(test.end_time);
          const currTime = moment();

          const testInfo = {
            testId: test.id,
            name: test.name,
            hasJoined: test.has_joined,
            hasSubmitted: test.has_submitted,
            duration: test.duration,
            endTime,
            startTime,
          };

          if (currTime.isBetween(startTime, endTime)) {
            tmpAllCodingTests.ongoing.push(testInfo);
          } else if (startTime.isAfter(currTime)) {
            tmpAllCodingTests.upcoming.push(testInfo);
          } else if (endTime.isBefore(currTime)) {
            tmpAllCodingTests.past.push(testInfo);
          }
        });
        setAllCodingTests(tmpAllCodingTests);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(err.response?.data?.message || err.message);
      });
  };

  const joinTest = (e, testId) => {
    axios
      .post(
        fetchCodingTestJoinNowURL(testId),
        { id: testId },
        {
          headers: getHeaders(currentProgram?.programId),
        }
      )
      .then((res) => {
        setIsSuccess(true);
        setSuccessMessage('You have successfully joined the test.');
        handleTestClick(e, testId);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(err.response?.data?.message || err.message);
      });
  };

  const handleTestClick = (event, testId) => {
    const data = allCodingTests[testFilter].filter((p) => p.testId === testId);
    setSelectedTest(data[0]);
  };

  function displayCodingTests() {
    if (allCodingTests[testFilter]?.length > 0) {
      return (
        <Box
          display='flex'
          flexWrap='wrap'
        >
          {allCodingTests[testFilter].map((test, index) => (
            <CodingTestCard
              key={test?.testId}
              selectedTest={test}
              handleTestClick={handleTestClick}
              joinTest={joinTest}
            />
          ))}
        </Box>
      );
    }
    return (
      <Typography
        variant='body1'
        align='center'
        sx={{
          mt: 4,
        }}
      >
        No tests are available.
      </Typography>
    );
  }

  return (
    <Stack>
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      {isSuccess && (
        <SuccessNotifier
          message={successMessage}
          setIsSuccess={setIsSuccess}
        />
      )}

      {!selectedTest ? (
        <>
          <Box
            display='flex'
            justifyContent='flex-end'
            alignItems='center'
          >
            <TestFilters
              testFilter={testFilter}
              setTestFilter={setTestFilter}
            />
          </Box>
          <Typography
            variant='h4'
            sx={{
              m: 2,
            }}
          >
            {`${
              testFilter[0].toUpperCase() +
              testFilter.slice(1, testFilter.length)
            } Tests`}
          </Typography>
          {displayCodingTests()}
        </>
      ) : (
        <DetailedTest
          key={selectedTest?.testId}
          selectedTest={selectedTest}
          setSelectedTest={setSelectedTest}
        />
      )}
    </Stack>
  );
}

export default CodingTestsList;

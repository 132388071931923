import React from 'react';
import { Typography } from '@mui/material';
import { Stack } from '@material-ui/core';
import StudentScreen from '../screens/StudentScreen';

export default function EscalationMatrix() {
  return (
    <>
      <StudentScreen />
      <Stack spacing={2} m={2}>
        <Typography textAlign="center" variant='h3'>
          Escalation Matrix
        </Typography>
        <Typography component="ol" pl="2%">
          <Typography component="li">
            The initial point of contact for the program is the Program Management Point of Contact (POC). The POC will maintain regular communication with both you and the coach on a weekly basis to ensure a seamless learning process. It is important to share any feedback or requests with the POC via Discord, and they will prioritize resolving them promptly.
          </Typography>
          
          <Typography component="li">
            Furthermore, there will be a biweekly Batch-PGM POC Syncup, where you will have the opportunity to directly address any concerns related to the course with the PGM POC.
          </Typography>

          <Typography component="li">
            If your questions or feedbacks have not been adequately addressed by the team, you have the option to contact the PGM team leader at support@heycoach.co.in. This email address is closely monitored, and we guarantee that your issues will be resolved within one day or less.
          </Typography>
          
          <Typography component="li">
            In the event that the aforementioned channels do not yield satisfactory results, you may also reach out to Aditya, who oversees Education at HeyCoach, at (<a href="mailto:kamat@heycoach.in">kamat@heycoach.in</a>). Aditya is highly responsive to emails and will provide assistance if you are still experiencing difficulties.
          </Typography>
        </Typography>
      </Stack>
    </>
  );
}


// @ts-nocheck
import React, { useEffect, useRef, useState } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import BlockIcon from '@mui/icons-material/Block';
import moment from 'moment';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { isAdmin, isProgramManager } from './ChatDashboard';
import DeleteMessageConfirmation from './DeleteMessageConfirmation';
import AdminMessageOptions from './AdminMessageOptions';
import MessageEditingBox from './MessageEditingBox';
import NotifyByEmailConfirmation from './NotifyByEmailConfirmation';
import ReactionSelectionPopup from './ChatReactions/ReactionSelectionPopup';
import MessageReactionViews from './ChatReactions/MessageReactionViews';

const Message = ({
  message,
  onMouseEnter,
  onMouseLeave,
  isHovered,
  setUpdateMessageStatus,
  setDeleteMessageStatus,
  searchType,
  chat,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [shouldNotifyByEmail, setShouldNotifyByEmail] = useState(false);
  const [notifyByEmailStatus, setNotifyByEmailStatus] = useState('');
  const [hoverMessage, setHoverMessage] = useState(false);
  const messageRef = useRef(null);

  const processedMessage = message.message
    ? linkifyAndBreak(message.message)
    : null;

  const hasMessageReactions = () => {
    return (message?.chat_message_reactions ?? []).length > 0;
  };

  const hasMessageDeleted = () => {
    return !message?.message;
  };

  useEffect(() => {
    if (
      message.id === chat.last_message_id &&
      messageRef.current &&
      searchType === 'Messages'
    ) {
      messageRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      setHoverMessage(true);
      setTimeout(() => {
        setHoverMessage(false);
      }, 3000);
    }
  }, []);

  return (
    <Box
      display='flex'
      flexDirection='column'
    >
      <Box
        display='flex'
        flexDirection={message.from_self ? 'row-reverse' : 'row'}
        alignItems='flex-start'
        marginBottom={1}
        position='relative'
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        sx={{ backgroundColor: hoverMessage ? '#edfff4' : 'none' }}
      >
        <Paper
          elevation={3}
          style={{
            backgroundColor: message.from_self ? '#E8FFFF' : '#fff',
            padding: '10px',
            borderRadius: '8px',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '400px',
            wordWrap: 'break-word',
            overflow: 'auto',
          }}
          ref={messageRef}
        >
          {isEditing ? (
            <MessageEditingBox
              message={message}
              setIsEditing={setIsEditing}
              setUpdateMessageStatus={setUpdateMessageStatus}
            />
          ) : (
            <>
              {!message.from_self && (
                <Typography
                  variant='body1'
                  sx={{
                    color: 'blue',
                    fontWeight: '800',
                    fontSize: '12px',
                    display: 'block',
                  }}
                >
                  {message.sent_by_name}
                </Typography>
              )}
              {!message.message ? (
                <div>
                  <Typography
                    variant='body1'
                    style={{ color: 'grey' }}
                  >
                    <BlockIcon sx={{ color: 'grey', fontSize: '14px' }} />
                    This message has been deleted
                  </Typography>
                </div>
              ) : (
                <Typography
                  variant='body1'
                  pr={2}
                  dangerouslySetInnerHTML={{ __html: processedMessage }}
                  style={{
                    whiteSpace: 'pre-line',
                  }}
                />
              )}
            </>
          )}

          <Box
            sx={{
              textAlign: 'right',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <Typography
              fontSize={10}
              color='textSecondary'
            >
              {message.is_edited && !message.is_deleted && 'Edited '}
              {formatTime(message.created_at)}
            </Typography>

            {(isAdmin || isProgramManager) &&
              message.from_self &&
              !message.is_deleted && (
                <DoneAllIcon
                  color={message.read_by_other_user ? 'primary' : 'disabled'}
                  sx={{ fontSize: '20px', ml: 1 }}
                />
              )}
          </Box>

          {message.from_self &&
            (isAdmin || isProgramManager) &&
            !message.is_deleted &&
            isHovered &&
            !message.file_path && (
              <AdminMessageOptions
                setIsEditing={setIsEditing}
                setDeleteConfirmation={setDeleteConfirmation}
                isHovered={isHovered}
                setShouldNotifyByEmail={setShouldNotifyByEmail}
              />
            )}
        </Paper>

        <DeleteMessageConfirmation
          message={message}
          openConfirmationDialog={deleteConfirmation}
          setOpenConfirmationDialog={setDeleteConfirmation}
          setDeleteMessageStatus={setDeleteMessageStatus}
        />

        <NotifyByEmailConfirmation
          message={message}
          shouldNotifyByEmail={shouldNotifyByEmail}
          setShouldNotifyByEmail={setShouldNotifyByEmail}
          setNotifyByEmailStatus={setNotifyByEmailStatus}
        />

        {isHovered && !hasMessageDeleted() && (
          <ReactionSelectionPopup message={message} />
        )}
      </Box>

      {hasMessageReactions() && <MessageReactionViews message={message} />}
    </Box>
  );
};

export default Message;

function formatTime(timestamp) {
  const parsedTimestamp = moment(timestamp);
  return parsedTimestamp.format('hh:mm A');
}

function linkifyAndBreak(text) {
  if (text) {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const textWithLineBreaks = text.replace(/\\n/g, '<br />');
    const textWithLinks = textWithLineBreaks.replace(urlRegex, (url) => {
      return `${url}`;
    });
    return textWithLinks;
  }
}

// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Button, TextField, Box, Stack } from '@mui/material';
import axios from 'axios';
import AddQuestionForm from './AddQuestionForm';
import { fetchQuizDetails, getHeaders } from '../../../../apis/urls';
import PreviousPage from '../../../../pages/PreviousPage';
import ErrorNotifier from '../../../ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../../ToastNotifications/SuccessNotifier';
import QuizQuestions from './QuizQuestions';

const initialState = {
  title: '',
  internal_name: '',
  description: '',
};

export default function QuizDetails({ quizId, handleCloseDetails }) {
  const [quiz, setQuiz] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [formState, setFormState] = useState(initialState);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    fetchQuiz();
    return () => {};
  }, [quizId]);

  const fetchQuiz = () => {
    axios
      .get(fetchQuizDetails(quizId), {
        headers: getHeaders(),
      })
      .then((res) => {
        setQuiz(res?.data);
        setFormState(res?.data);
      })
      .catch((e) => {
        console.log(e);
        setHasError(true);
        setErrorMessage(
          e.response?.data?.message ||
            'An error occurred while fetching the quiz'
        );
      });
  };

  const handleSave = () => {
    const allowed_fields = ['title', 'description', 'internal_name'];
    const body = {};
    allowed_fields.forEach((key) => {
      if (formState[key] !== quiz[key]) {
        body[key] = formState[key];
      }
    });

    axios
      .patch(fetchQuizDetails(quizId), body, {
        headers: getHeaders(),
      })
      .then((res) => {
        setQuiz(res?.data);
        setFormState(res?.data);
        setIsSuccess(true);
        setSuccessMessage('Quiz updated successfully');
      })
      .catch((e) => {
        console.log(e);
        setHasError(true);
        setErrorMessage(
          e.response?.data?.message ||
            'An error occurred while updating the quiz'
        );
      });
    setIsEditing(false);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;

    setFormState((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };

  const handleCancel = () => {
    setIsEditing(false);
    setFormState(quiz);
  };

  const addQuestionToQuiz = (newQuestion) => {
    setFormState((prevState) => ({
      ...prevState,
      quiz_questions: [...prevState.quiz_questions, newQuestion],
    }));

    setQuiz((prevState) => ({
      ...prevState,
      quiz_questions: [...prevState.quiz_questions, newQuestion],
    }));
  };

  const updateQuizQuestion = (newQuestion) => {
    setFormState((prevState) => ({
      ...prevState,
      quiz_questions: prevState.quiz_questions.map((q) =>
        q.id === newQuestion.id ? newQuestion : q
      ),
    }));
    setQuiz((prevState) => ({
      ...prevState,
      quiz_questions: prevState.quiz_questions.map((q) =>
        q.id === newQuestion.id ? newQuestion : q
      ),
    }));
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      {isSuccess && (
        <SuccessNotifier
          message={successMessage}
          setIsSuccess={setIsSuccess}
        />
      )}
      <Box
        display='flex'
        justifyContent='space-between'
        m={3}
      >
        <PreviousPage handlePrevPageClick={handleCloseDetails} />
      </Box>
      <Stack
        gap='1em'
        width='90%'
        m={3}
        spacing={1}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '16px',
            width: '100%',
            flexWrap: 'wrap',
            '@media (max-width: 600px)': {
              flexDirection: 'column',
            },
          }}
        >
          <TextField
            label='Title'
            name='title'
            value={formState?.title}
            onChange={handleChange}
            disabled={!isEditing}
            InputLabelProps={{ shrink: true }}
            sx={{ flexGrow: 1, flexBasis: '22%' }}
          />
          <TextField
            label='Internal Name'
            name='internal_name'
            value={formState?.internal_name}
            onChange={handleChange}
            InputLabelProps={{ shrink: true }}
            disabled={!isEditing}
            sx={{ flexGrow: 1, flexBasis: '22%' }}
          />
        </Box>
        <TextField
          label='Description'
          name='description'
          value={formState?.description}
          onChange={handleChange}
          fullWidth
          multiline
          minRows={1}
          maxRows={3}
          disabled={!isEditing}
          InputLabelProps={{ shrink: true }}
        />
        <Box>
          {isEditing ? (
            <Box>
              <Button
                color='primary'
                variant='contained'
                onClick={handleSave}
              >
                Save
              </Button>
              <Button
                onClick={handleCancel}
                sx={{
                  ml: 4,
                }}
                color='error'
                variant='outlined'
                type='submit'
              >
                Cancel
              </Button>
            </Box>
          ) : (
            <Button
              color='primary'
              variant='contained'
              onClick={() => setIsEditing(true)}
            >
              Edit
            </Button>
          )}
        </Box>
        <AddQuestionForm
          quizId={quizId}
          addQuestionToQuiz={addQuestionToQuiz}
        />
        {formState.quiz_questions && (
          <QuizQuestions
            formState={formState}
            updateQuizQuestion={updateQuizQuestion}
          />
        )}
      </Stack>
    </>
  );
}

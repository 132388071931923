import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import { Box } from '@material-ui/system';
import { Button } from '@material-ui/core';
import axios from 'axios';
import CoachingLogModal from './CoachingLogModal';
import { getHeaders, updateSessionURL } from '../../apis/urls';

const timeFormat = 'MMM DD, YYYY, hh:mm A';

export default function CoachingLogsTable({
  coachSessions,
  setHasError,
  setErrorMessage,
  setIsSuccess,
  setSuccessMessage,
  setCoachSessions,
  loading,
  setLoading,
}) {
  const [selectedSession, setSelectedSession] = useState({}); // to show the session logs in a modal.
  const [openLogModal, setOpenLogModal] = useState(false);
  const [sessionIdToIndexMap, setSessionIdToIndexMap] = useState({});
  const [perPage, setPerPage] = useState(50);

  useEffect(() => {
    const tmpMap = {};
    coachSessions.forEach((session, index) => {
      tmpMap[session.id] = index;
    });
    setSessionIdToIndexMap(tmpMap);
    return () => {};
  }, [coachSessions]);

  function getEditRowButtonText(session) {
    let defaultText = 'Mark as conducted';
    if (session.status === 'conducted') {
      defaultText = 'Update session';
    }
    if (
      session.status === 'verified' ||
      session.status === 'payment_processed'
    ) {
      defaultText = 'View session';
    }
    if (openLogModal && session.id === selectedSession.id) {
      return 'Opened';
    }
    return defaultText;
  }

  const columns = [
    {
      field: 'id',
      headerName: 'Id',
      type: 'number',
      width: 70,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => sessionIdToIndexMap[params.row.id] + 1,
    },
    {
      field: 'program_name',
      headerName: 'Program name',
      width: 240,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => params.row.program?.name || '-',
    },
    {
      field: 'session_name',
      headerName: 'Session name',
      width: 300,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => params.row.title || '-',
    },
    {
      field: 'group_name',
      headerName: 'Group name',
      width: 400,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => params.row.group_name || '-',
    },
    {
      field: 'status',
      headerName: 'Status',
      type: 'string',
      width: 200,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => {
        if (!params.row.status) {
          return '-';
        }
        if (params.row.status === 'created') {
          return 'Created';
        }
        if (params.row.status === 'scheduled') {
          return 'Scheduled';
        }
        if (params.row.status === 'conducted') {
          return 'Conducted';
        }
        if (params.row.status === 'verified') {
          if (params.row.verified_by?.name) {
            return `Verified by ${params.row.verified_by.name}`;
          }
          return 'Verified';
        }
        return 'Payment processed';
      },
    },
    {
      field: 'start_time',
      headerName: 'Start time',
      width: 180,
      align: 'center',
      type: 'dateTime',
      headerAlign: 'center',
      valueGetter: (params) =>
        params.row?.start_time
          ? moment(params.row.start_time).format(timeFormat)
          : '-',
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      cellClassName: 'actions',
      getActions: ({ id, row }) => {
        return [
          <Button
            variant={
              row.status === 'verified' || row.status === 'payment_processed'
                ? 'text'
                : 'contained'
            }
            size='small'
            onClick={(e) => {
              setOpenLogModal(!openLogModal);
              setSelectedSession(row);
            }}
          >
            {getEditRowButtonText(row)}
          </Button>,
        ];
      },
    },
  ];

  const updateSessionLogApiCall = (sessionId, programId, body) => {
    const index = sessionIdToIndexMap[sessionId];
    const { status } = coachSessions[index];
    if (status === 'verified') {
      setHasError(true);
      setErrorMessage(
        'This session cannot be updated because it has already been verified.'
      );
      if (openLogModal) {
        setOpenLogModal(false);
      }
      return;
    }
    if (status === 'payment_processed') {
      setHasError(true);
      setErrorMessage(
        'This session cannot be updated because payment has been processed.'
      );
      if (openLogModal) {
        setOpenLogModal(false);
      }
      return;
    }

    setLoading(true);
    axios
      .patch(updateSessionURL(sessionId), body, {
        headers: getHeaders(programId),
      })
      .then((res) => {
        const tmpSessions = [...coachSessions];
        const index = sessionIdToIndexMap[sessionId];
        tmpSessions[index] = res.data;
        setIsSuccess(true);
        setSuccessMessage('Session log successfully updated.');
        setCoachSessions(tmpSessions);
        if (openLogModal) {
          setOpenLogModal(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || 'Could not update the session log.'
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Box
        sx={{
          width: 1550,
          height: '100%',
          mt: 5,
          alignSelf: 'center',
        }}
      >
        <DataGrid
          rows={coachSessions}
          columns={columns}
          pageSize={perPage}
          onPageSizeChange={(pageSize) => setPerPage(pageSize)}
          rowsPerPageOptions={[50, 100, 150]}
          autoHeight
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          onRowEditStart={(params, event) => {
            event.defaultMuiPrevented = true;
          }}
          onRowEditStop={(params, event) => {
            event.defaultMuiPrevented = true;
          }}
          loading={loading}
        />
      </Box>
      {openLogModal && (
        <CoachingLogModal
          {...{
            openLogModal,
            setOpenLogModal,
            session: selectedSession,
            updateLog: updateSessionLogApiCall,
            setErrorMessage,
            setHasError,
          }}
        />
      )}
    </>
  );
}

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { Box } from '@material-ui/system';
import { NoteAlt } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import SideBar from './SideBar';
import { Assignments } from '../performance/Assignment/Assignments';
import AssignmentSubmissions from '../performance/Assignment/AssignmentSubmissions';
import { slugify } from '../utils/common';
import ResourcesTab from './ResourcesTab';
import CodingAssignments from '../performance/CodingAssignments/CodingAssignments';
import LanguageBible from './LanguageBible';

const PreCourse = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const navigate = useNavigate();
  const currentProgram = useSelector((state) => state.programs.currentProgram);
  const programName = currentProgram.programSlug;

  const LeftBarContents = [
    {
      label: currentProgram?.programId === 2 ? 'New Resources' : 'Resources',
      Icon: NoteAlt,
      isFor: 'student',
      component: <ResourcesTab />,
    },
    {
      label: 'Foundation Questions',
      Icon: NoteAlt,
      isFor: 'student',
      component:
        currentProgram.programId >= 85 ? (
          <CodingAssignments category='pre_course' />
        ) : (
          <Assignments category='pre_course' />
        ),
    },
    {
      label: 'Language Bible',
      Icon: NoteAlt,
      isFor: 'student',
      component: <LanguageBible />,
    },
  ];

  useEffect(() => {
    setIndexOfDefaultSubTab();
    return () => {};
  }, []);

  useEffect(() => {
    const label = slugify(LeftBarContents[selectedIndex].label);
    navigate(`/student/${programName}/pre-course/${slugify(label)}`);
    return () => {};
  }, [selectedIndex]);

  function setIndexOfDefaultSubTab() {
    const tab = window.location.pathname.slice(1).split('/')[3] || '';
    for (let i = 0; i < LeftBarContents.length; i += 1) {
      const label = slugify(LeftBarContents[i].label);
      if (label === tab) {
        setSelectedIndex(i);
        break;
      }
    }
  }

  const handleListItemClick = (index) => {
    setSelectedIndex(index);
  };

  return (
    <Grid container>
      <Grid
        item
        lg={2}
      >
        <Box
          sx={{
            height: '55vh',
            padding: '0',
            flexGrow: 1,
            overflowY: 'auto',
            borderRight: '1px solid gray',
          }}
        >
          <div style={{ paddingTop: '10px' }}>
            {LeftBarContents?.map((content, index) => {
              return (
                <SideBar
                  key={index}
                  label={content.label}
                  Icon={content.Icon}
                  selectedIndex={selectedIndex}
                  handleListItemClick={handleListItemClick}
                  index={index}
                />
              );
            })}
          </div>
        </Box>
      </Grid>
      <Grid
        item
        lg={10}
      >
        {LeftBarContents &&
          LeftBarContents?.map((content, index) => {
            if (selectedIndex === index) {
              return content.component;
            }
            return <></>;
          })}
      </Grid>
    </Grid>
  );
};

export default PreCourse;

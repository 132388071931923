import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import TableView from './Modules/TableView';
import Filters from './Modules/Filters';
import axios from 'axios';
import { getHeaders, ticketUrl } from 'src/apis/urls';
import { setTickets } from '../redux-store/slices/ticketSlice';
import TicketOverview from './Atoms/TicketOverview';
import Pagination from './Atoms/Pagination';

function GlobalSearch({ columns, setErrorMessage, setHasError }) {
  const [sortField, setSortField] = useState('created_at');
  const [sortOrder, setSortOrder] = useState('desc'); // asc or desc
  const data = useSelector((state) => state.tickets?.tickets);
  const [page, setPage] = useState(1);
  const pageSize = 20;
  const [queryString, setQueryString] = useState('status=open');
  const dispatch = useDispatch();

  const [filteredData, setFilteredData] = useState(data);

  const allPrograms = useSelector((state) => state.programs.programs);

  const buildQueryString = (queryParams) => {
    const query = new URLSearchParams();

    if (queryParams.programId) {
      query.append('program_id', queryParams.programId);
    }
    if (queryParams.category) {
      query.append('category', queryParams.category);
    }
    if (queryParams.status) {
      query.append('status', queryParams.status);
    }
    if (queryParams.searchText) {
      query.append('search_text', queryParams.searchText);
    }
    if (queryParams.fromDate) {
      query.append('from_date', queryParams.fromDate);
    }
    if (queryParams.toDate) {
      query.append('to_date', queryParams.toDate);
    }
    if (queryParams.assigneeName) {
      query.append('assignee', queryParams.assigneeName);
    }

    query.append('sort_field', sortField);
    query.append('sort_order', sortOrder);
    return query.toString();
  };

  const handleApplyFilters = (queryParams) => {
    const queryString = buildQueryString(queryParams);
    setQueryString(queryString);
    setPage(1);
  };

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap={3}
    >
      <h1>Help Tickets</h1>
      <Filters
        allPrograms={allPrograms}
        onApplyFilters={handleApplyFilters}
        sortOrder={sortOrder}
        sortField={sortField}
      />
      <TicketOverview />
      <Pagination
        queryString={queryString}
        page={page}
        setPage={setPage}
        pageSize={pageSize}
      />
      {filteredData && (
        <>
          <TableView
            columns={columns}
            data={filteredData}
            setErrorMessage={setErrorMessage}
            setHasError={setHasError}
            setSortField={setSortField}
            setSortOrder={setSortOrder}
            sortField={sortField}
            sortOrder={sortOrder}
          />
        </>
      )}
    </Box>
  );
}

export default GlobalSearch;

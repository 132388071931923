// @ts-nocheck
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  IconButton,
  Collapse,
  Box,
} from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { BASE_URL, getHeaders } from '../../../apis/urls';
import LoadingScreen from '../../../pages/LoadingScreen';

const DetailedSubmission = ({
  selectedSubmission,
  setSelectedSubmission,
  setHasError,
  setErrorMessage,
}) => {
  const [loading, setLoading] = useState(false);
  const currentProgram = useSelector((state) => state.programs.currentProgram);
  const [executions, setExecutions] = useState([]);
  const [openRow, setOpenRow] = useState({});

  useEffect(() => {
    fetchTestcaseExecutions();
    return () => {};
  }, []);

  const fetchTestcaseExecutions = () => {
    setLoading(true);
    axios
      .get(
        `${BASE_URL}/api/code_submissions/${selectedSubmission?.code_submission.id}/executions`,
        {
          headers: getHeaders(currentProgram?.programId),
        }
      )
      .then((res) => {
        setExecutions(res.data);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err?.response?.data?.message ||
            'An error occurred while fetching the submission details'
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRowClick = (index) => {
    setOpenRow((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  return (
    <Dialog
      open
      onClose={(e) => setSelectedSubmission(null)}
      scroll='paper'
      fullWidth
      maxWidth='lg'
    >
      <DialogActions>
        <Button onClick={(e) => setSelectedSubmission(null)}>Close</Button>
      </DialogActions>

      <DialogTitle>Submission details</DialogTitle>

      <LoadingScreen loading={loading} />

      <DialogContent dividers>
        {executions.length > 0 && (
          <Stack spacing={2}>
            <TextField
              multiline
              defaultValue={selectedSubmission?.code_submission.code}
              sx={{
                width: '100%',
              }}
              inputProps={{ readOnly: true }}
            />

            <TableContainer component={Paper}>
              <Table
                sx={{
                  minWidth: 650,
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>Test case</TableCell>
                    <TableCell>Time(sec)</TableCell>
                    <TableCell align='center'>Memory(KB)</TableCell>
                    <TableCell align='center'>Status</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {executions.map((val, index) => (
                    <React.Fragment key={index + 1}>
                      <TableRow>
                        <TableCell>
                          {val?.testcase && (
                            <IconButton
                              aria-label='expand row'
                              size='small'
                              onClick={() => handleRowClick(index)}
                            >
                              {openRow[index] ? (
                                <KeyboardArrowUp />
                              ) : (
                                <KeyboardArrowDown />
                              )}
                            </IconButton>
                          )}
                        </TableCell>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{val.time_taken || '-'}</TableCell>
                        <TableCell align='center'>
                          {val.memory_taken || '-'}
                        </TableCell>
                        <TableCell align='center'>
                          {val?.submission_status ? (
                            <Typography
                              variant='body2'
                              sx={{
                                color:
                                  val.submission_status === 'accepted'
                                    ? 'green'
                                    : 'red',
                              }}
                            >
                              {val.submission_status}
                            </Typography>
                          ) : (
                            '-'
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          colSpan={6}
                        >
                          <Collapse
                            in={openRow[index]}
                            timeout='auto'
                            unmountOnExit
                          >
                            <Box margin={1}>
                              <Typography
                                variant='h6'
                                gutterBottom
                                component='div'
                              >
                                Test Input:
                              </Typography>
                              <Typography variant='body2'>
                                {val?.testcase?.input}
                              </Typography>
                              <Typography
                                variant='h6'
                                gutterBottom
                                component='div'
                              >
                                Expected output:
                              </Typography>
                              <Typography variant='body2'>
                                {val?.testcase?.expected_output}
                              </Typography>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default DetailedSubmission;

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Tab } from '@material-ui/core';
import { TabList } from '@material-ui/lab';
import { Box } from '@material-ui/system';
import { useNavigate } from 'react-router-dom';
import './onboarding.css';
import { onboardingTopBarContent } from 'src/components/AtomicComponents/StudentNavbarData';
import OnboardingChildTabLayout from './OnboardingChildTabLayout';
import { slugify } from 'src/components/utils/common';

const OnboardingMainTabLayout = ({
  setOpen,
  open,
  value,
  setValue,
  subTabValue,
  setSubTabValue,
}) => {
  const navigate = useNavigate();
  const handleAccordionToggle = (index) => {
    setOpen((prevState) => {
      const newState = { ...prevState };
      Object.keys(newState).forEach((key) => {
        newState[key] = false;
      });
      newState[index] = !prevState[index];
      return newState;
    });
  };

  const handleChange = (newValue) => {
    setValue(String(newValue));
    setSubTabValue('0');
    if (onboardingTopBarContent[newValue].children.length > 0) {
      navigate(
        `/onboarding/${slugify(
          onboardingTopBarContent[newValue].key
        )}/${slugify(onboardingTopBarContent[newValue].children[0].key)}`
      );
    } else {
      navigate(`/onboarding/${slugify(onboardingTopBarContent[newValue].key)}`);
    }
  };

  return (
    <Box className='main-tab-layout'>
      <TabList
        orientation='vertical'
        value={value}
        indicatorColor='none'
        textColor='none'
        sx={{
          padding: '0 10px',
        }}
      >
        {onboardingTopBarContent.map((tab, index) => {
          const { hideFromProgramTypes, hideFromRoles, key, label, children } =
            tab;
          const isSignatureFramework = label === 'Signature Frameworks';
          const hasChild = children && children.length > 0;

          return (
            <Box
              key={index}
              sx={{
                width: '220px',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
              }}
            >
              <Tab
                label={label}
                value={index}
                onClick={() => {
                  handleAccordionToggle(index);
                  handleChange(index);
                }}
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'start',
                  color: 'black',
                  borderRadius: '10px',
                  backgroundColor: 'transparent',
                }}
              />
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  padding: '0 0 0 20px',
                }}
              >
                {hasChild && (
                  <OnboardingChildTabLayout
                    open={open}
                    index={index}
                    value={value}
                    subTabValue={subTabValue}
                    children={children}
                    setSubTabValue={setSubTabValue}
                  />
                )}
              </Box>
            </Box>
          );
        })}
      </TabList>
    </Box>
  );
};

export default OnboardingMainTabLayout;

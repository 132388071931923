import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/system';
import moment from 'moment';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import CloseIcon from '@mui/icons-material/Close';
import ErrorNotifier from '../../../../ToastNotifications/ErrorNotifier';
import {
  fetchCodingTestProblemSubmissionsURL,
  getHeaders,
} from '../../../../../apis/urls';
import CodingTestProblemSubmissionDialog from './CodingTestProblemSubmissionDialog';

export default function CodingTestProblemSubmissions({ problemId, testId }) {
  const currentProgram = useSelector((state) => state.programs.currentProgram);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [submissions, setSubmissions] = useState(null);
  const [briefSubmissionDetails, setBriefSubmissionDetails] = useState(null);
  const [selectedSubmission, setSelectedSubmission] = useState(null);
  const [openSpecificSubmission, setOpenSpecificSubmission] = useState(false);

  useEffect(() => {
    axios
      .get(fetchCodingTestProblemSubmissionsURL(testId, problemId), {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((res) => {
        if (!res?.data) {
          return;
        }

        const format1 = 'DD-MM-YYYY, h:mm A';
        const tmpAllSubmissions = {};
        const tmpBriefSubmissionDetails = {};
        res.data.forEach((submission, index) => {
          const {
            id: submissionId,
            execution_service_status,
            submission_status,
            time_taken,
            memory_taken,
            coding_tests_problems_attempt,
            testcase,
          } = submission;

          const {
            id: codingTestsProblemAttemptId,
            created_at,
            language,
            code,
            test_input,
          } = coding_tests_problems_attempt;

          const { id: languageId, name: languageName } = language;

          if (!(codingTestsProblemAttemptId in tmpAllSubmissions)) {
            tmpAllSubmissions[codingTestsProblemAttemptId] = [];
            tmpBriefSubmissionDetails[codingTestsProblemAttemptId] = {
              createdAt: moment(created_at).format(format1),
              isAccepted: 1,
              languageId,
              languageName,
            };
          }

          tmpAllSubmissions[codingTestsProblemAttemptId].push({
            attemptId: codingTestsProblemAttemptId,
            executionId: submissionId,
            executionServiceStatus: execution_service_status,
            submissionStatus: submission_status,
            timeTaken: time_taken,
            memoryTaken: memory_taken,
            testcase,
            code,
            test_input,
          });

          const { isAccepted } =
            tmpBriefSubmissionDetails[codingTestsProblemAttemptId];
          const currTestStatus = submission_status === 'accepted';
          tmpBriefSubmissionDetails[codingTestsProblemAttemptId].isAccepted =
            isAccepted && currTestStatus;
        });
        setSubmissions(tmpAllSubmissions);
        setBriefSubmissionDetails(tmpBriefSubmissionDetails);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(err.response?.data?.message || err.message);
      });

    return () => {};
  }, []);

  const handleOpenSubmissionClick = (event, attemptNumber) => {
    setSelectedSubmission(submissions[attemptNumber]);
    setOpenSpecificSubmission(true);
  };

  return (
    <Box
      sx={{
        maxHeight: 500,
        height: 500,
        overflowY: 'scroll',
      }}
    >
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Time Submitted</TableCell>
              <TableCell>Language</TableCell>
              <TableCell>Status</TableCell>
              <TableCell align='center'>Attempt</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {Object.keys(briefSubmissionDetails ?? {}).length > 0 &&
              Object.keys(briefSubmissionDetails).map(
                (attemptNumber, index) => (
                  <TableRow key={index + 1}>
                    <TableCell>
                      {briefSubmissionDetails[attemptNumber].createdAt || '-'}
                    </TableCell>

                    <TableCell>
                      {briefSubmissionDetails[attemptNumber].languageName}
                    </TableCell>
                    <TableCell>
                      {briefSubmissionDetails[attemptNumber].isAccepted ? (
                        <DoneOutlineIcon color='success' />
                      ) : (
                        <CloseIcon sx={{ color: 'red' }} />
                      )}
                    </TableCell>
                    <TableCell
                      align='center'
                      onClick={(e) =>
                        handleOpenSubmissionClick(e, attemptNumber)
                      }
                      sx={{
                        cursor: 'pointer',
                        color: 'blue',
                        textDecoration: 'under-line',
                      }}
                    >
                      view submission
                    </TableCell>
                  </TableRow>
                )
              )}
          </TableBody>
        </Table>
      </TableContainer>
      {(!submissions || Object.keys(submissions).length === 0) && (
        <Typography
          variant='h6'
          display='flex'
          justifyContent='center'
          sx={{
            mt: 5,
          }}
        >
          No submissions available
        </Typography>
      )}
      {openSpecificSubmission && (
        <CodingTestProblemSubmissionDialog
          openSpecificSubmission={openSpecificSubmission}
          setOpenSpecificSubmission={setOpenSpecificSubmission}
          submission={selectedSubmission}
        />
      )}
    </Box>
  );
}

import React from 'react';
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import moment from 'moment';
import Cell from './AttendanceCell';
import { useStyles } from './AssignmentsReport';

function getDisciplinePercentage(joinedSessionsCount, totalSessionsConducted) {
  if (totalSessionsConducted === 0) {
    return 0;
  }
  const result = ((joinedSessionsCount * 1.0)/ totalSessionsConducted) * 100;
  return result.toFixed(2);
}

function getAttendanceCellColor(session, studentId) {
  if (session?.attendances[studentId] === undefined) {
    return "";
  }
  if (session?.attendances[studentId]?.status === "joined") {
    return "#00e676";
  }
  return "#ff1744";
}

export default function AttendanceReportDisplay({ learners, sessions, markAttendance, sessionsList, totalConductedSessions }) {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12}>  
        <TableContainer component={Paper}>
          <Table 
            sx={{ minWidth: 650 }} 
            size="medium" 
            stickyHeader
          >
            <Header 
              {...{
                sessions, 
                sessionsList, 
                classes
              }}
            />

            <Body 
              {...{
                learners, 
                classes, 
                totalConductedSessions, 
                sessionsList, 
                sessions, 
                markAttendance
              }}
            />
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

function Header({ sessions, sessionsList, classes }) {
  return (
    <TableHead>
      <TableRow>
        <TableCell 
          className={classes.sticky} 
          sx={{ 
            borderRight: 1, 
            borderBottom: 1
          }}
        > 
          Name 
        </TableCell>
        <TableCell 
          sx={{
            borderRight: 1, 
            borderBottom: 1
          }}
        > 
          Discipline 
        </TableCell>
        
        {sessionsList.map(([startTime, sid], index) => (
          <TableCell 
            align="center" 
            sx={{
              borderRight: 1, 
              borderBottom: 1
            }}
          >
            <Typography>
              {sessions[sid]?.title}
              <br />
              ({moment(startTime).utc().format("DD, MMM")})
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function Body({ learners, classes, totalConductedSessions, sessionsList, sessions, markAttendance }) {
  return (
    <TableBody>
      {Object.keys(learners)?.map((lid, index1) => (
        <TableRow key={index1}>
          <TableCell 
            className={classes.sticky} 
            sx={{
              borderRight: 1, 
              borderBottom: 1
            }}
          >
            {learners[lid].name}
          </TableCell>
          
          <TableCell 
            align="center" 
            sx={{
              borderRight: 1, 
              borderBottom: 1
            }}
          >
            {`${getDisciplinePercentage(learners[lid].discipline, totalConductedSessions)} %`}
          </TableCell>
          
          {sessionsList?.map(([startTime, sid], index2) => (
            <TableCell 
              align="center" 
              sx={{
                borderRight: 1, 
                borderBottom: 1,
                backgroundColor: (moment().isBefore(moment(startTime).utc())
                  ? "text.disabled"
                  : getAttendanceCellColor(sessions[sid], lid)
                )
              }}
            >
              <Cell
                key={index2} 
                studentId={lid} 
                sessionId={sid} 
                session={sessions[sid]} 
                markAttendance={markAttendance}
              />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  );
}
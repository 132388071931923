import React, { useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@material-ui/core';
import LoadingButton from '@mui/lab/LoadingButton';
import VerifiedIcon from '@mui/icons-material/Verified';
import { FiberManualRecord } from '@mui/icons-material';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import CustomTabPanel from '../CustomTabPanel';
import TopicTitle from '../TopicTitle';
import ListRenderComponent from '../ListRenderComponent';
import CustomButton from '../../../practice/MindMaps/Atoms/CustomButton';
import { IterateItConstants } from '../../../utils/techniqueSagaConstant';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const { Explanation } = IterateItConstants;
const { TimeComplexity } = IterateItConstants;
const { SpaceComplexity } = IterateItConstants;

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const IterateIt = ({
  handlePostCompletedTopic,
  hasMarkedCompleted,
  loading,
  topic_id,
  handleNext,
  handlePrevious,
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap='36px'
      paddingY={2}
      maxWidth='calc(100% - 300px)'
    >
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
      >
        <TopicTitle>7. Iterate It</TopicTitle>
        {hasMarkedCompleted(topic_id) && (
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              color: 'green',
              fontWeight: '500',
              border: '2px solid green',
              padding: '4px 10px',
              borderRadius: '20px',
              cursor: 'default',
            }}
          >
            Completed
          </Typography>
        )}
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        gap='24px'
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            width: 'fit-content',
            background: '#FEF7FF',
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
          >
            <Tab
              label='Problem Statement'
              {...a11yProps(0)}
            />
            <Tab
              label='Optimised Approach'
              {...a11yProps(1)}
            />
          </Tabs>
        </Box>
        <CustomTabPanel
          value={value}
          index={0}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Box
              display='flex'
              flexDirection='column'
              gap='16px'
            >
              <Typography sx={{ fontSize: '22px', fontWeight: 400, mb: '8px' }}>
                Description
              </Typography>
              <List>
                <ListItem sx={{ paddingY: '2px' }}>
                  <ListItemIcon>
                    <FiberManualRecord sx={{ width: '0.5em' }} />
                  </ListItemIcon>
                  <ListItemText primary="Consider the following pseudocode, run on an array  A=[a0 , a1, a2, a3……] of length 'n' :" />
                </ListItem>
              </List>

              <Box
                sx={{
                  backgroundColor: 'black',
                  padding: '24px',
                  borderRadius: '10px',
                  width: '575px',
                }}
              >
                <pre style={{ color: 'white', margin: 0 }}>
                  {`rep := 0
while A not empty:
    B := []
    for x in A, y in A:
        if x != y: append absolute_value(x - y) to B
    A := B
    rep := rep + 1`}
                </pre>
              </Box>

              <Typography sx={{ fontWeight: '600' }}>
                Given the values of 'n' and array 'A' , compute and print the
                final value of 'rep' after the pseudocode above terminates; if
                the loop will never terminate, print -1 instead.
              </Typography>
            </Box>

            <Box
              display='flex'
              flexDirection='column'
              gap='16px'
            >
              <Box
                display='flex'
                flexDirection='column'
                gap={1}
              >
                <Typography
                  sx={{ fontSize: '22px', fontWeight: 400, mb: '8px' }}
                >
                  Constraint
                </Typography>
                <ListRenderComponent data={IterateItConstants.Constraint01} />
              </Box>

              <Box
                display='flex'
                flexDirection='column'
                gap={1}
              >
                <Typography
                  sx={{ fontSize: '22px', fontWeight: 400, mb: '8px' }}
                >
                  Constraint Analysis
                </Typography>
                <Typography>
                  {`For the given constraints (1 <= n <= 10^5, 1 <= ai <= 5 * 10^4), the possible time complexities for the provided pseudocode could be:`}
                </Typography>
                <List>
                  {IterateItConstants.Constraint02.map((operator, index) => (
                    <>
                      <Typography>{operator.title}</Typography>
                      <ListItem
                        key={index}
                        sx={{ paddingY: '2px', alignItems: 'flex-start' }}
                      >
                        <ListItemIcon>
                          <FiberManualRecord sx={{ width: '0.5em' }} />
                        </ListItemIcon>
                        <ListItemText primary={operator.text} />
                      </ListItem>
                    </>
                  ))}
                </List>
              </Box>
            </Box>

            <Box
              display='flex'
              flexDirection='column'
              gap={1}
            >
              <Typography sx={{ fontSize: '22px', fontWeight: 400, mb: '8px' }}>
                Intuition
              </Typography>
              <ListRenderComponent data={IterateItConstants.Intuition01} />
            </Box>
          </Box>
        </CustomTabPanel>

        <CustomTabPanel
          value={value}
          index={1}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Box
              sx={{
                backgroundColor: 'black',
                padding: '24px',
                borderRadius: '10px',
                width: '720px',
                overflowX: 'auto',
              }}
            >
              <pre style={{ color: 'white', margin: 0 }}>
                {`Function findMinimumGroups():
    n = ReadInput() // Read the length of the array
    a = InitializeArray(n)
    c = InitializeBooleanArray(50009)
    
    // Input array elements and mark them as true in the boolean array
    For i from 0 to n-1:
        a[i] = ReadInput()
        c[a[i]] = true

    ret = 0
    // Continue the process until no elements are left in the boolean array      
    While true:
        v = InitializeEmptyArrayList()
        
        // Collect all elements from the boolean array and mark them as false    
        For i from 1 to 50000:
            If c[i] is true:
                Append i to v
                c[i] = false

        // If no elements are left, break from the loop
        If SizeOf(v) == 0:
            Break

        // Find the greatest common divisor (gcd) of elements in v
        g = v[0]
        For i from 1 to SizeOf(v) - 1:
            g = gcd(g, v[i])

        // Reduce elements in v by dividing them by the gcd
        For i from 0 to SizeOf(v) - 1:
            v[i] = v[i] / g

        // Check if the elements in v form a sequence
        flag = true
        For i from 0 to SizeOf(v) - 1:
            If v[i] != i + 1:
                flag = false
                Break

        // If the elements form a sequence, increment ret and break from the loop
        If flag is true:
            ret = ret + SizeOf(v)
            Break

        // Mark the differences between pairs of elements in v as true in the boolean array        
        For i from 0 to SizeOf(v) - 1:
            For j from i + 1 to SizeOf(v) - 1:
                c[v[j] - v[i]] = true

        ret = ret + 1

    Output ret
End Function

Function gcd(x, y):
    If y == 0:
        Return x
    Return gcd(y, x % y)
End Function
`}
              </pre>
            </Box>

            <Box
              display='flex'
              flexDirection='column'
              gap={2.5}
            >
              <Typography sx={{ fontSize: '22px', fontWeight: 400, mb: '8px' }}>
                Code Walkthrough
              </Typography>
              {Explanation.map((exp, index) => (
                <Box
                  display='flex'
                  flexDirection='column'
                  gap='16px'
                  key={index}
                >
                  <Typography sx={{ fontSize: '18px' }}>{exp.title}</Typography>
                  <ListRenderComponent data={exp.pointers} />
                </Box>
              ))}

              <Typography>
                In summary, this program processes a list of numbers, finds
                consecutive sequences, normalises them, and counts how many
                iterations are needed to find a sequence. The final count is
                then displayed.
              </Typography>
            </Box>

            <Box
              display='flex'
              flexDirection='column'
              gap={2.5}
            >
              <Typography sx={{ fontSize: '22px', fontWeight: 400, mb: '8px' }}>
                Time Complexity
              </Typography>
              {TimeComplexity.map((exp, index) => (
                <Box
                  display='flex'
                  flexDirection='column'
                  gap='16px'
                  key={index}
                >
                  <Typography sx={{ fontSize: '18px' }}>{exp.title}</Typography>
                  <ListRenderComponent data={exp.pointers} />
                </Box>
              ))}

              <Typography>
                In summary, this program processes a list of numbers, finds
                consecutive sequences, normalises them, and counts how many
                iterations are needed to find a sequence. The final count is
                then displayed.
              </Typography>
            </Box>

            <Box
              display='flex'
              flexDirection='column'
              gap={2.5}
            >
              <Typography sx={{ fontSize: '22px', fontWeight: 400, mb: '8px' }}>
                Space Complexity
              </Typography>
              <Typography>
                The space complexity of the provided pseudocode for the
                findMinimumGroups function is O(n), where 'n' is the length of
                the array
              </Typography>

              {SpaceComplexity.map((exp, index) => (
                <Box
                  display='flex'
                  flexDirection='column'
                  gap='16px'
                  key={index}
                >
                  <Typography sx={{ fontSize: '18px' }}>{exp.title}</Typography>
                  <ListRenderComponent data={exp.pointers} />
                </Box>
              ))}

              <Typography>
                In summary, this program processes a list of numbers, finds
                consecutive sequences, normalises them, and counts how many
                iterations are needed to find a sequence. The final count is
                then displayed.
              </Typography>
            </Box>
          </Box>
        </CustomTabPanel>
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        alignSelf='end'
        justifyContent='space-between'
        gap='12px'
      >
        {!hasMarkedCompleted(topic_id) && (
          <LoadingButton
            variant='contained'
            onClick={() => handlePostCompletedTopic(topic_id)}
            loading={loading}
            loadingPosition='center'
            children='Mark as completed'
            style={{
              width: '170px',
              // backgroundColor: 'transparent',
              borderRadius: '8px',
              border: '1px solid rgba(64, 96, 245, 0.5)',
            }}
          />
        )}

        <Box
          display='flex'
          alignItems='center'
          gap='8px'
        >
          <Button
            sx={{ gap: '4px' }}
            onClick={handlePrevious}
          >
            <WestIcon /> Previous
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default IterateIt;

export const emojis = [
  ':smile:',
  ':grin:',
  ':joy:',
  ':heart:',
  ':thumbsup:',
  ':thumbsdown:',
  ':fire:',
  ':rocket:',
  ':tada:',
  ':question:',
  ':sob:',
  ':angry:',
  ':heart_eyes:',
  ':smirk:',
];

import React from 'react';
import moment from 'moment';
import { Typography, Box } from '@mui/material';
import { Stack } from '@material-ui/core';
import { Alert } from '@mui/material';
import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';
import PhoneIcon from '@mui/icons-material/Phone';

export default function InteractionList({ interactions }) {
  return interactions.map((interaction) => {
    const timestamp = moment(interaction.created_at).format(
      'dddd, MMMM Do YYYY, h:mm:ss a'
    );
    return (
      <Stack>
        <Stack
          direction='row'
          justifyContent='space-between'
        >
          <Typography
            variant='subtitle2'
            component='strong'
          >
            {interaction.added_by.name}
          </Typography>

          <Typography
            variant='body2'
            color='#9e9e9e'
          >
            {timestamp}
          </Typography>
        </Stack>
        {interaction.interaction_type === 'dnp' ? (
          <Box
            sx={{
              backgroundColor: 'rgb(255, 166, 166)',
              padding: '10px',
              borderRadius: '10px',
              display: 'flex',
              width: '400px',
              margin: '10px 0',
            }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/phone_missed.png`}
              alt='Phone Callback'
              style={{ marginRight: '20px' }}
            />
            <Typography
              sx={{ color: 'black' }}
              variant='body2'
            >
              {interaction.notes}
            </Typography>
          </Box>
        ) : interaction.interaction_type === 'rcb' ? (
          <Box
            sx={{
              backgroundColor: 'rgb(123, 208, 255)',
              padding: '10px',
              borderRadius: '10px',
              display: 'flex',
              width: '400px',
              margin: '10px 0',
            }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/phone_callback.png`}
              alt='Phone Callback'
              style={{ marginRight: '20px' }}
            />

            <Typography
              sx={{ color: 'black' }}
              variant='body2'
            >
              {interaction.notes}
            </Typography>
          </Box>
        ) : (
          <Box
            sx={{
              backgroundColor: '#ebf8ff',
              borderRadius: '5px',
              padding: '10px',
              margin: '10px 0',
            }}
          >
            <Typography
              variant='body1'
              component='p'
            >
              {interaction.notes}
            </Typography>
          </Box>
        )}
      </Stack>
    );
  });
}

import { Button, Typography, Modal, Stack } from '@material-ui/core';
import { Box } from '@material-ui/system';
import React, { useState } from 'react';
import * as XLSX from 'xlsx';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 2,
};

export default function BulkCreateSessionsModal({ 
  openBulkCreateSessionsModal, 
  setOpenBulkCreateSessionsModal, 
  setHasError, 
  setErrorMessage,
  setLoading,
  loading,
  handleBulkSessionCreate
}) {

  const [excelData, setExcelData] = useState([]); 
  const [filename, setFilename] = useState("");

  const handleFile = (e) => {
    const fileType = [
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ];

    const selectedFile = e.target.files[0];
    setFilename(selectedFile?.name ?? "");
    
    const reader = new FileReader();
    if (selectedFile && fileType.includes(selectedFile.type)) {
      setLoading(true);
      reader.onload = (evt) => {
        const binaryData = evt.target.result;
        const workbook = XLSX.read(binaryData, { type:'binary' });
        const wsname = workbook.SheetNames[0];
        const ws = workbook.Sheets[wsname];

        // Columns must be in this order only
        const data = XLSX.utils.sheet_to_json(ws, {
          header: [                        
            "date",
            "month",
            "year", 
            "hour",
            "minute",
            "duration", 
            "title", 
            "group_name"
          ],
          range: 1
        });
        setExcelData(data);
        setLoading(false);
      };

      reader.readAsBinaryString(selectedFile);
    } else {
      setHasError(true);
      setErrorMessage('Please select only excel file types');
      handleRemoveFile();
    } 
  }
  
  const handleBulkCreateClick = (e) => {
    if (excelData.length === 0) {
      setHasError(true);
      setErrorMessage("Please upload an excel file.");
      return;
    }

    const validSessionDurations = [30, 60, 90, 120]; // in minutes
    for (let i = 0; i < excelData.length; i += 1) {
      if (!validSessionDurations.includes(excelData[i].duration)) {
        setHasError(true);
        setErrorMessage(`Invalid duration at row ${i + 1}. please try 30, 60, 90 or 120`);
        handleRemoveFile();
        return;
      }
    }

    const body = {
      data: excelData
    }
    
    handleBulkSessionCreate(body);
  }
  
  const handleRemoveFile = () => {
    setFilename("");
    setExcelData([]);
  }

  return (
    <Modal 
      open={openBulkCreateSessionsModal} 
      onClose={() => setOpenBulkCreateSessionsModal(false)} 
    >        
      <Box sx={style}>
        <Typography 
          sx={{ 
            ml: 2, 
            flex: 1 
          }} 
          variant="h6" 
          component="div"
        >
          Bulk create sessions
        </Typography>
        
        <Stack 
          spacing={4}
          m={2}
        >
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
          >
            {filename !== "" &&
              <Typography variant="body2">
                {filename === "" ? "unknown.xlsx" : filename}
              </Typography>
            }
            <label htmlFor="contained-button-file">
              <input
                style={{ 
                  display: "none" 
                }}
                onChange={(e) => handleFile(e)}
                id="contained-button-file"
                type="file"
                value=""
              />
              <Button 
                variant="contained" 
                color="primary"
                component="span"
                disabled={filename !== ""}
              >
                {filename !== "" ? "File uploaded" : "Upload file"}
              </Button>
            </label>
          </Stack>
          
          <Stack
            direction="row"
            spacing={1}
            justifyContent="flex-end"
            alignSelf="flex-end"
          >
            <Button 
              variant="contained" 
              color="primary" 
              component="span"
              onClick={handleBulkCreateClick}
              disabled={((excelData ?? []).length === 0) || loading}
            >
              Bulk create
            </Button>
            <Button 
              variant="contained" 
              color="primary"
              component="span"
              onClick={handleRemoveFile}
              disabled={(excelData ?? []).length === 0}
            >
              Remove file
            </Button>
          </Stack>
        </Stack>         
      </Box>
    </Modal>
  );
}

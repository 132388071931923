import { Chip, ListItem, Paper } from '@material-ui/core';
import React from 'react'

export default function TopicsList({ topics, readOnly=true, handleTopicDelete=() => {} }) {
  return (
    <Paper sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', p: 0.5 }}>
      {topics && topics.length > 0 &&
        topics.map((topic, index) => (
        readOnly 
          ? <Chip label={topic} sx={{mb: 1, mr: 0.5}} />
          : <Chip label={topic} onDelete={(e) => handleTopicDelete(topic)} sx={{mb: 1, mr: 0.5}}/>
      ))}
    </Paper>
  );
}

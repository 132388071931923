// @ts-nocheck
import { useNavigate } from 'react-router-dom';
import { Stack, TextField, Button, Typography, Dialog, Box} from '@material-ui/core';
import axios from 'axios';
import React, { useState } from 'react';
import { postReceipt, updateReceipt, getHeaders } from '../../../apis/urls';
import ErrorNotifier from '../../ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../ToastNotifications/SuccessNotifier';
import ProgramsListAutocompleteDropdown from '../../../pages/ProgramsListAutocompleteDropdown';

function GenerateReceipts() {
  const [filters, setFilters] = useState({});
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();

  const handleCreateOrUpdateReceipt = () => {
    let request;
    if (!filters.id) {
      request = axios.post(postReceipt(), filters, {
        headers: getHeaders()
      });
    } else {
      request = axios.patch(updateReceipt(filters.id), filters, {
        headers: getHeaders()
      });
    }
    
    request
    .then((res) => {
      setSuccessMessage(`Receipt ${filters.id ? "updated" : "created"} successfully`);
      setIsSuccess(true);
      setFilters({});
    })
    .catch((e) => {
      setHasError(true);
      setErrorMessage(e.response?.data?.message || `Couldn't ${filters.id ? "update" : "create"} receipt.`);
      
      if (e.response?.data?.receipt) {
        setFilters(e.response?.data?.receipt);
      }
    })
  }

  const closeModal = () => {
    navigate(-1);
  }

  const getTextField = (label, type, key) => {
    let props = {
      type,
      label,
      value: filters[key] ?? "",
      onChange: (e) => handleChange(key, e.target.value),
      size: "small",
      sx: {
        width: "49%"
      },
      placeholder: label
    }

    if (type === "date") {
      props = {
        ...props,
        InputLabelProps: { 
          shrink: true 
        }
      };
    }

    return (
      <TextField { ...props } />
    );
  }

  const handleChange = (key, value) => {
    const tmp = { ...filters };
    if ((value ?? "") === "") {
      delete tmp[key];
    } else {
      tmp[key] = value;
    }
    setFilters({ ...tmp });
  }

  return (
    <>
      {hasError && <ErrorNotifier {...{ message: errorMessage, setHasError }} /> }
      {isSuccess && <SuccessNotifier {...{ message: successMessage, setIsSuccess }} /> }
      <Dialog 
        open 
        fullWidth 
        maxWidth="md" 
        onClose={closeModal} 
      >
        <Box 
          display="flex" 
          justifyContent="flex-end"
        >
          <Button onClick={closeModal}>
            Close
          </Button>
        </Box>

        <Stack spacing={2} m={4}>
          {filters?.invoice_number &&
            <Typography variant="body2">
              *Receipt already exists. The invoice number is <b>{filters?.invoice_number}.</b>
            </Typography>
          }
          <Stack direction="row" justifyContent="space-between" space={1}>
            <ProgramsListAutocompleteDropdown 
              value={filters.program_id ?? ""}
              onChangeHandler={(e, v) => setFilters({ ...filters, program_id: v.value })}
              size="small"
              sx={{
                width: "49%"
              }}
            />
            
            {getTextField("Email", "email", "email")}
          </Stack>

          <Stack direction="row" justifyContent="space-between" space={1}>
            {getTextField("Date", "date", "date")}
            {getTextField("Payment plan", "text", "payment_plan")}
          </Stack>

          <Stack direction="row" justifyContent="space-between" space={1}>
            {getTextField("GST (in %)", "number", "gst")}
            {getTextField("Total amount", "number", "total_amount")}
          </Stack>

          <Button 
            variant="contained" 
            size='small' 
            onClick={handleCreateOrUpdateReceipt}
            disabled={Object.keys(filters).length < 6}
          >
            {filters?.id ? "Update" : "Create"} receipt
          </Button>
        </Stack>
      </Dialog> 
    </>
  );
}

export default GenerateReceipts;
import { Box, Container, Typography } from '@material-ui/core';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import React from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <Box
      display='flex'
      justifyContent='center'
      sx={{
        position: 'absolute',
        bottom: 0,
        width: '100%',
        backgroundColor: '#1960f5',
        color: 'white',
        pt: 2,
        pb: 2,
      }}
    >
      <Typography
        display='flex'
        alignItems='center'
        style={{
          fontWeight: 'bold',
          color: 'unset',
        }}
      >
        {/* <Box
          sx={{
            ml: '0.7em',
          }}
        >
          Need help? Please reach out to your program POC | &nbsp;
        </Box> */}
        <Link
          to='/escalation-matrix'
          target='_blank'
          style={{
            color: 'white',
            cursor: 'pointer',
          }}
        >
          Escalation framework
        </Link>
        &nbsp; | &nbsp;
        <a
          href='https://drive.google.com/file/d/1hPbq1nnNM93GEAQzatIDAXxbMb6MGwH9/view?usp=sharing'
          target='_blank'
          rel='noopener noreferrer'
          style={{
            color: 'white',
            cursor: 'pointer',
          }}
        >
          Placement assistance policy
        </a>
        &nbsp; | &nbsp;
        <a
          href='https://drive.google.com/file/d/1u_PkSzWo3WlKpmIuJ6puGHy80yUy88If/view?usp=sharing'
          target='_blank'
          rel='noopener noreferrer'
          style={{
            color: 'white',
            cursor: 'pointer',
          }}
        >
          Batch change policy
        </a>
      </Typography>
    </Box>
  );
}

// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { Box } from '@material-ui/system';
import { NoteAlt, Style, Menu, WidgetsSharp } from '@mui/icons-material';
import TaskIcon from '@mui/icons-material/Task';
import TimelineIcon from '@mui/icons-material/Timeline';
import { useNavigate, useParams } from 'react-router-dom';
import SideBar from './SideBar';
import { Assignments } from './Assignment/Assignments';
import { getUserType } from '../../configs/auth';
import AssignmentSubmissions from './Assignment/AssignmentSubmissions';
import { Tasks } from './Tasks/Tasks';
import { slugify } from '../utils/common';
import ProgramNotStarted from '../ProgramNotStarted';
import CodingAssignments from './CodingAssignments/CodingAssignments';
import GrowthChart from './GrowthChart/GrowthChart';

const Performance = () => {
  const userType = getUserType();
  const [selectedIndex, setSelectedIndex] = useState(
    userType === 'coach' ? 1 : 0
  );
  const navigate = useNavigate();
  const currentProgram = useSelector((state) => state.programs.currentProgram);
  const programName = currentProgram?.programSlug;

  useEffect(() => {
    setIndexOfDefaultSubTab();
    return () => {};
  }, []);

  useEffect(() => {
    const label = slugify(LeftBarContents[selectedIndex].label);
    navigate(`/student/${programName}/performance/${slugify(label)}`);
    return () => {};
  }, [selectedIndex]);

  function setIndexOfDefaultSubTab() {
    const tab = window.location.pathname.slice(1).split('/')[3] || '';
    for (let i = 0; i < LeftBarContents.length; i += 1) {
      const label = slugify(LeftBarContents[i].label);
      if (
        LeftBarContents[i].isFor === 'both' ||
        LeftBarContents[i].isFor === userType
      ) {
        // checks if the subtab can be accessed by current user type.
        if (label === tab) {
          setSelectedIndex(i);
          break;
        }
      }
    }
  }

  const handleListItemClick = (index) => {
    setSelectedIndex(index);
  };

  if (!currentProgram?.hasProgramStarted) {
    return <ProgramNotStarted />;
  }

  return (
    <Grid container>
      <Grid
        item
        lg={2}
      >
        <Box
          sx={{
            height: '55vh',
            padding: '0',
            flexGrow: 1,
            overflowY: 'auto',
            borderRight: '1px solid gray',
          }}
        >
          <div style={{ paddingTop: '10px' }}>
            {LeftBarContents?.map((content, index) => {
              if (content.isFor === 'both' || content.isFor === userType) {
                return (
                  <SideBar
                    key={index}
                    label={content.label}
                    Icon={content.Icon}
                    selectedIndex={selectedIndex}
                    handleListItemClick={handleListItemClick}
                    index={index}
                  />
                );
              }
              return <></>;
            })}
          </div>
        </Box>
      </Grid>

      <Grid
        item
        lg={10}
      >
        {LeftBarContents &&
          LeftBarContents?.map((content, index) => {
            if (
              (content.isFor === 'both' || content.isFor === userType) &&
              selectedIndex === index
            ) {
              return content.component;
            }
            return <></>;
          })}
      </Grid>
    </Grid>
  );
};

export default Performance;

// 3 states: 'both', 'student', 'coach'
const LeftBarContents = [
  // {
  //   label: 'Overview',
  //   Icon: Menu,
  //   isFor: 'student',
  //   component: <></>
  // },
  {
    label: 'Growth',
    Icon: TimelineIcon,
    isFor: 'student',
    component: <GrowthChart />,
  },
  {
    label: 'Tasks',
    Icon: TaskIcon,
    isFor: 'both',
    component: <Tasks />,
  },
  {
    label: 'Assignments',
    Icon: NoteAlt,
    isFor: 'both',
    component: <Assignments />,
  },
  {
    label: 'Coding Assignments',
    Icon: NoteAlt,
    isFor: 'student',
    component: <CodingAssignments />,
  },
  {
    label: 'Assignment submissions',
    Icon: NoteAlt,
    isFor: 'coach',
    component: <AssignmentSubmissions />,
  },
  // {
  //   label: 'Projects',
  //   Icon: WidgetsSharp,
  //   isFor: 'student',
  //   component: <></>
  // }
];

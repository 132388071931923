import { Typography } from '@material-ui/core';
import React from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/cjs/styles/prism';

function CodeBlockComponent({ code }) {
  return (
    <Typography
      component='div'
      sx={{ backgroundColor: '#282c34', padding: 2, borderRadius: 1 }}
    >
      <SyntaxHighlighter
        style={oneDark}
        wrapLongLines
      >
        {code}
      </SyntaxHighlighter>
    </Typography>
  );
}

export default CodeBlockComponent;

import React, { useEffect, useState } from 'react';
import { Stack, Typography } from '@material-ui/core';
import { Box } from '@material-ui/system';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import PreviousPage from '../../../pages/PreviousPage';
import { fetchCoachSessionsURL, getHeaders } from '../../../apis/urls';

const timeFormat = 'MMM DD, YYYY, hh:mm A';
export default function CoachPaymentsTable({
  setOpenCoachPayments,
  coach,
  setHasError,
  setErrorMessage,
}) {
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [perPage, setPerPage] = useState(50);

  useEffect(() => {
    getCoachSessionDetails();
    return () => {};
  }, []);

  function getCoachSessionDetails() {
    axios
      .get(fetchCoachSessionsURL(coach.id), {
        headers: getHeaders(),
      })
      .then((res) => {
        const tmpVerifiedArr = [];
        const tmpRemainingArr = [];
        res.data.mentor_sessions.forEach((val) => {
          const { status = '' } = val;
          if (status !== 'verified' && status !== 'payment_processed') {
            return;
          }
          if (status === 'verified') {
            tmpVerifiedArr.push(val);
          } else {
            tmpRemainingArr.push(val);
          }
        });
        res.data.sessions.forEach((val) => {
          const { status = '' } = val;
          if (status !== 'verified' && status !== 'payment_processed') {
            return;
          }
          if (status === 'verified') {
            tmpVerifiedArr.push(val);
          } else {
            tmpRemainingArr.push(val);
          }
        });
        setPaymentDetails([...tmpVerifiedArr, ...tmpRemainingArr]);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message ||
            `Could not fetch ${coach.name}'s session details.`
        );
      });
  }

  const columns = [
    {
      field: 'id',
      headerName: 'Id',
      type: 'number',
      width: 70,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'programName',
      headerName: 'Program name',
      width: 230,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => params.row?.program?.name || 'NA',
    },
    {
      field: 'title',
      headerName: 'Title',
      width: 380,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'start_time',
      headerName: 'Start time',
      width: 180,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) =>
        params.row?.start_time
          ? moment(params.row.start_time).format(timeFormat)
          : 'NA',
    },
    {
      field: 'status',
      headerName: 'status',
      width: 200,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'transaction_id',
      headerName: 'Transaction id',
      width: 200,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) =>
        params.row?.coach_payment?.transaction_id ?? 'NA',
    },
    {
      field: 'session_type',
      headerName: 'Session Type',
      width: 200,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) =>
        params.row?.session_type_id ? 'Session' : 'Mentor Session',
    },
    {
      field: 'duration',
      headerName: 'Duration (minutes)',
      width: 200,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => params.row?.duration ?? 'NA',
    },
    {
      field: 'coach_payment_id',
      headerName: 'Coach payment id',
      width: 180,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => params.row.coach_payment_id || 'NA',
    },
  ];

  return (
    <Stack
      sx={{ m: 1 }}
      spacing={2}
    >
      <Box display='flex'>
        <PreviousPage handlePrevPageClick={() => setOpenCoachPayments(false)} />
      </Box>
      {paymentDetails.length > 0 && (
        <Box sx={{ width: '100%' }}>
          <DataGrid
            rows={paymentDetails}
            columns={columns}
            pageSize={perPage}
            onPageSizeChange={(pageSize) => setPerPage(pageSize)}
            rowsPerPageOptions={[50, 100, 150]}
            autoHeight
            disableSelectionOnClick
          />
        </Box>
      )}
      {paymentDetails.length === 0 && (
        <Typography
          variant='body1'
          textAlign='center'
          sx={{ mt: 2 }}
        >
          No payment details found.
        </Typography>
      )}
    </Stack>
  );
}

// @ts-nocheck
import React from 'react';
import {
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  Stack,
  Typography,
  Box,
} from '@mui/material';

export function getCourseScaleQuestion(value, handleChange) {
  const numbers = [...Array(10).keys()].map((num) => num + 1);

  return (
    <FormControl
      component='fieldset'
      margin='normal'
    >
      <FormLabel component='legend'>
        Rate the course on a scale of 1-10
      </FormLabel>
      <RadioGroup
        value={value}
        onChange={handleChange}
        row
        name='course_rating'
      >
        {numbers.map((val, index) => (
          <FormControlLabel
            key={index}
            value={val}
            labelPlacement='bottom'
            sx={{ m: 1 }}
            control={<Radio required />}
            label={val}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

export function getRecommendationScaleQuestion(
  value,
  handleChange,
  isMobile = false
) {
  const numbers = [...Array(10).keys()].map((num) => num + 1);

  const getRatingColor = (num) => {
    if (num <= 6) {
      return '#ffcdd2';
    }
    if (num >= 9) {
      return '#c8e6c9';
    }
    return '#ffe0b2';
  };

  return (
    <Stack
      spacing={1}
      width='inherit'
    >
      <Typography
        variant='body1'
        component='p'
      >
        How likely are you to recommend this course to others?
      </Typography>

      <Stack
        direction='row'
        justifyContent='space-between'
      >
        {numbers.map((number) => {
          return (
            <Box
              sx={{
                backgroundColor: getRatingColor(number),
                width: '2rem',
                height: '2rem',
                ...(isMobile && {
                  width: '1.5rem',
                  height: '1.5rem',
                }),
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                borderRadius: '0.25rem',
                ...(value !== '' &&
                  value !== number && {
                    opacity: 0.5,
                  }),
                ...(value === number && {
                  border: '1px solid black',
                }),
              }}
              name='recommendation_scale'
              value={number}
              onClick={(e) => {
                e.target.name = 'recommendation_scale';
                e.target.value = number;
                handleChange(e);
              }}
            >
              <Typography
                sx={{
                  ...(isMobile && {
                    fontSize: '10px',
                  }),
                }}
              >
                {number}
              </Typography>
            </Box>
          );
        })}
      </Stack>

      <Stack
        width='100%'
        direction='row'
        justifyContent='space-between'
      >
        <Typography
          variant='subtitle'
          component='p'
          sx={{
            color: '#757575',
            fontWeight: 200,
            ...(isMobile && {
              fontSize: '12px',
            }),
          }}
        >
          Least Likely
        </Typography>

        <Typography
          variant='subtitle'
          component='p'
          sx={{
            color: '#757575',
            fontWeight: 200,
            ...(isMobile && {
              fontSize: '12px',
            }),
          }}
        >
          Most Likely
        </Typography>
      </Stack>
    </Stack>
  );
}

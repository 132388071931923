/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
  Stepper,
  Step,
  StepLabel,
  Stack,
  Divider,
} from '@mui/material';

import LockIcon from '@mui/icons-material/Lock';
import { styled } from '@mui/material/styles';
import StepConnector from '@mui/material/StepConnector';
import ScreeningTestWelcomeScreenExitPopup from 'src/components/ExitIntentPopups/ScreeningTestWelcomScreenExitPopup';
import acute from '../../../assets/acute_1.png';

const steps = ['Application form', 'Screening test', 'Offer letter'];

const CustomConnector = styled(StepConnector)(({ theme }) => ({
  [`& .MuiStepConnector-line`]: {
    borderColor: '#1976D2',
  },
}));

const CustomStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.grey[700],
  zIndex: 1,
  color: '#fff',
  width: 24,
  height: 24,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundColor: theme.palette.primary.main,
  }),
  ...(ownerState.completed && {
    backgroundColor: theme.palette.primary.main,
  }),
}));

function CustomStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    3: <LockIcon fontSize='inherit' />,
  };

  return (
    <CustomStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </CustomStepIconRoot>
  );
}

const YourAllSet = ({
  rescheduleTime,
  trackButtonClick,
  setShowInstructions,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const getRemainingTime = () => {
    const currentTime = new Date();

    const istRescheduleDate = new Date(
      new Date(rescheduleTime).toLocaleString('en-US', {
        timeZone: 'Asia/Kolkata',
      })
    );

    const timeDifference = istRescheduleDate - currentTime;

    if (timeDifference > 0) {
      const totalSeconds = Math.floor(timeDifference / 1000);

      if (totalSeconds >= 5940) {
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        return { hours, minutes, showHours: true };
      } else {
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        return { minutes, seconds, showHours: false };
      }
    } else {
      return { expired: true };
    }
  };

  const [timeLeft, setTimeLeft] = useState(getRemainingTime());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft(getRemainingTime());
    }, 1000);

    return () => clearInterval(intervalId);
  }, [rescheduleTime]);

  const hourDigits = String(timeLeft.hours || 0)
    .padStart(2, '0')
    .split('');
  const minDigits = String(timeLeft.minutes || 0)
    .padStart(2, '0')
    .split('');
  const secDigits = String(timeLeft.seconds || 0)
    .padStart(2, '0')
    .split('');
  const isHoursFormat = timeLeft.showHours;

  const startScreeningTest = ({
    source = 'your_all_set',
    eventName = 'start_the_test_now',
  }) => {
    trackButtonClick({
      source,
      eventName,
    });
    setShowInstructions(true);
  };
  return (
    <>
      <ScreeningTestWelcomeScreenExitPopup
        trackButtonClick={trackButtonClick}
        source={'all_set'}
      />
      <Stack
        spacing={3}
        alignItems='center'
        sx={{
          height: 'fit-content',
          width: {
            xs: '100%',
            sm: '100%',
            md: '85%',
            lg: '80%',
          },
          padding: {
            xs: '20px',
            sm: '30px',
            md: '2rem',
            lg: '3rem',
          },
          backgroundColor: 'white',
          borderRadius: '12px',
        }}
      >
        <Stack
          spacing={2}
          paddingTop='1rem'
          alignItems='center'
        >
          <Box
            sx={{ width: '100%', paddingBottom: '1rem', marginBottom: '30px' }}
          >
            <Stepper
              activeStep={1}
              alternativeLabel={isMobile}
              connector={<CustomConnector />}
            >
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel
                    sx={{
                      fontFamily: 'Inter',
                      '& .MuiStepLabel-label.Mui-completed': {
                        color: index < 2 ? '#1976D2' : 'inherit',
                      },
                      '& .MuiStepLabel-label.Mui-active': {
                        color: index < 2 ? '#1976D2' : 'inherit',
                      },
                    }}
                    StepIconComponent={index === 2 ? CustomStepIcon : undefined}
                  >
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>

          <Stack
            direction='row'
            spacing={1}
            alignItems='center'
          >
            <Typography
              variant='h1'
              sx={{
                textAlign: 'center',
                fontFamily: 'Inter',
                color: '#1976d2',
                fontWeight: '1000',
                fontSize: {
                  xs: '1.5rem',
                  sm: '1.7rem',
                  md: '2rem',
                  lg: '2.2rem',
                },
              }}
            >
              You're all set!
            </Typography>
          </Stack>

          <Typography
            variant='body1'
            sx={{
              textAlign: 'center',
              fontFamily: 'Inter',
              fontWeight: 400,
              fontSize: {
                xs: '1rem',
                sm: '1.2rem',
                md: '1.4rem',
                lg: '1.6rem',
              },
            }}
          >
            Your Fastrack Screening Test has been rescheduled.
          </Typography>
        </Stack>

        <Typography
          variant='caption'
          sx={{
            textAlign: 'center',
            fontFamily: 'Inter',
            fontSize: '1.2rem',
            marginTop: '1rem',
            color: '#666',
          }}
        >
          Start the test in
        </Typography>

        <Stack
          direction='row'
          spacing={1}
          alignItems='center'
          justifyContent='center'
        >
          {isHoursFormat ? (
            <>
              {hourDigits.map((digit, index) => (
                <Box
                  key={`hour-${index}`}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '30px',
                    height: '45px',
                    backgroundColor: '#FFEB3B',
                    borderRadius: '4px',
                    fontSize: '1.2rem',
                    fontWeight: 'bold',
                  }}
                >
                  {digit}
                </Box>
              ))}
              <Typography
                variant='h6'
                sx={{ mx: 1, fontWeight: 'bold' }}
              >
                :
              </Typography>
              {minDigits.map((digit, index) => (
                <Box
                  key={`min-${index}`}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '30px',
                    height: '45px',
                    backgroundColor: '#FFEB3B',
                    borderRadius: '4px',
                    fontSize: '1.2rem',
                    fontWeight: 'bold',
                  }}
                >
                  {digit}
                </Box>
              ))}
            </>
          ) : (
            <>
              {minDigits.map((digit, index) => (
                <Box
                  key={`min-${index}`}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '30px',
                    height: '45px',
                    backgroundColor: '#FFEB3B',
                    borderRadius: '4px',
                    fontSize: '1.2rem',
                    fontWeight: 'bold',
                  }}
                >
                  {digit}
                </Box>
              ))}
              <Typography
                variant='h6'
                sx={{ mx: 1, fontWeight: 'bold' }}
              >
                :
              </Typography>
              {secDigits.map((digit, index) => (
                <Box
                  key={`sec-${index}`}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '30px',
                    height: '45px',
                    backgroundColor: '#FFEB3B',
                    borderRadius: '4px',
                    fontSize: '1.2rem',
                    fontWeight: 'bold',
                  }}
                >
                  {digit}
                </Box>
              ))}
            </>
          )}
        </Stack>

        <Stack
          direction='row'
          spacing={3}
          justifyContent='center'
          style={{
            marginTop: '0px',
          }}
        >
          <Typography
            variant='body1'
            sx={{ textAlign: 'center', color: '#666' }}
          >
            {isHoursFormat ? 'Hours' : 'Minutes'}
          </Typography>
          {isHoursFormat && (
            <Typography
              variant='body1'
              sx={{ textAlign: 'center', color: '#666' }}
            >
              {isHoursFormat ? 'Minutes' : 'Seconds'}
            </Typography>
          )}

          {!isHoursFormat && (
            <Typography
              variant='body1'
              sx={{ textAlign: 'center', color: '#666' }}
            >
              {isHoursFormat ? 'Minutes' : 'Seconds'}
            </Typography>
          )}
        </Stack>

        <Divider
          sx={{ width: '40%', marginY: '1.5rem', borderColor: '#666' }}
        />

        <Typography
          variant='body2'
          sx={{
            textAlign: 'center',
            fontFamily: 'Inter',
            fontSize: '1rem',
            fontWeight: 500,
            color: '#333',
          }}
        >
          You still have the option to start the test now.
        </Typography>

        <Button
          variant='outlined'
          onClick={startScreeningTest}
          sx={{
            color: '#1976D2',
            paddingTop: '0.6rem',
            paddingBottom: '0.6rem',
            paddingLeft: '1.4rem',
            paddingRight: '1.4rem',
            fontSize: '0.8rem',
            marginTop: '1rem',
            fontWeight: '200',
          }}
          startIcon={
            <Box
              component='img'
              src={acute}
              alt='Priority'
              sx={{ width: 24, height: 24 }}
            />
          }
        >
          Start the test now
        </Button>
      </Stack>
    </>
  );
};

export default YourAllSet;

// @ts-nocheck
import { TextField } from '@material-ui/core';
import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import QuestionDescription from '../QuestionDescription';

const TextResponse = ({
  qid,
  type,
  maxCharacters,
  description,
  response,
  setResponse,
  setShouldAllowNext,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChange = (e) => {
    const { value } = e.target;
    if (value.length === 0) {
      setShouldAllowNext(false);
    } else {
      setShouldAllowNext(true);
    }

    setResponse((prev) => ({
      ...prev,
      [qid]: [value],
    }));
  };

  const getValue = () => {
    return !response[qid] ? '' : response[qid][0];
  };

  return (
    <Stack
      spacing={3}
      width='100%'
    >
      <QuestionDescription description={description} />

      <Stack spacing={1}>
        <TextField
          placeholder='Type your answer here...'
          multiline
          minRows={7}
          maxRows={7}
          value={getValue()}
          onChange={handleChange}
        />
      </Stack>
    </Stack>
  );
};

export default TextResponse;

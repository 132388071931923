import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/system';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Modal, Typography, Button, TextField } from '@material-ui/core';
import ErrorIcon from '@mui/icons-material/Error';
import {
  fetchMindMapCompletedProblems,
  fetchMindMapTopicData,
  getHeaders,
  postMindmapQuestionUrl,
  fetchUserProfileUrl,
} from '../../../../apis/urls';
import Topics from '../Organisms/Topics';
import Search from '../Molecules/Search';
import SubTopics from './SubTopics';
import { mindmapTopics } from '../mindmapTopics';
import SearchResult from '../Organisms/SearchResult';
import { getAccessToken } from '../../../../configs/auth';
import ErrorNotifier from '../../../../components/ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../../../components/ToastNotifications/SuccessNotifier';

const Mindmaps = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTopic, setSelectedTopic] = useState('');
  const [selectedSubTopic, setSelectedSubTopic] = useState('');
  const [problems, setProblems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState([]);
  const [completedProblemList, setCompletedProblemList] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [users, setUsers] = useState([]);
  const [showLeetcodeModal, setShowLeetcodeModal] = useState(false);
  const [leetcodeUsername, setLeetcodeUsername] = useState('');
  const currentProgram = useSelector((state) => state?.programs.currentProgram);
  const navigate = useNavigate();

  const { topic } = useParams();
  const location = useLocation();

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    borderRadius: '12px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    console.log('Search term=', event.target.value);
  };

  const handleUpdateLeetcodeUsername = (body) => {
    axios
      .put(fetchUserProfileUrl(), body, {
        headers: getHeaders(),
      })
      .then((res) => {
        setShowLeetcodeModal(false);
        setIsSuccess(true);
        setSuccessMessage('Leetcode username successfully updated.');
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message ||
            'Could not update the leetcode username.'
        );
      });
  };

  const handleLeetcodeSubmit = () => {
    const trimmedUsername = leetcodeUsername?.trim();

    if (!trimmedUsername) {
      setHasError(true);
      setErrorMessage('Leetcode username cannot be empty or just spaces.');
      return;
    }

    const body = {
      leetcode_username: trimmedUsername,
    };
    handleUpdateLeetcodeUsername(body);
  };

  useEffect(() => {
    getProfileData();
  }, []);

  function getProfileData() {
    setLoading(true);
    axios
      .get(fetchUserProfileUrl(), {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Access-Token': getAccessToken(),
        },
      })
      .then((res) => {
        const userData = res.data;
        setUsers(userData);
        setErrorMessage('');
        setHasError(false);
        setLoading(false);
      })
      .catch((e) => {
        const connection_error = JSON.parse(JSON.stringify(e));
        if (connection_error.message === 'Network Error') {
          setHasError(true);
          setErrorMessage("Can't connect to the servers right now");
        } else if (e.response && e.response.data) {
          const { data } = e.response;
          setHasError(true);
          setErrorMessage(data.message);
        }
        setLoading(false);
      });
  }

  const checkLeetcodeUsername = () => {
    if (users && users.leetcode_username === null) {
      setShowLeetcodeModal(true);
    }
  };

  useEffect(() => {
    checkLeetcodeUsername();
  }, [users]);

  const findProblemTopics = () => {
    setLoading(true);
    if (searchTerm.trim() === '') {
      return;
    }
    const postBody = {
      question: searchTerm.split('/description')[0],
    };

    const topicNamesArray = [
      'array',
      'string',
      'math',
      'searching and sorting',
      'divide and conquer',
      'linked list',
      'stack',
      'queue',
      'hash table',
      'bit manipulation',
      'recursion',
      'trees',
      'heap',
      'graphs',
      'trie',
      'greedy',
      'backtracking',
      'dynamic programming',
      'range query',
    ];
    axios
      .get(fetchMindMapCompletedProblems(), {
        headers: getHeaders(currentProgram.programId),
        params: { topics: topicNamesArray },
      })
      .then((response) => {
        setCompletedProblemList(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error('Error:', err);
        setLoading(false);
      });

    axios
      .post(postMindmapQuestionUrl(), postBody, {
        headers: getHeaders(currentProgram.programId),
      })
      .then((res) => {
        setResult(res.data);
        console.log('Result of search=', res.data);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data.message ||
            'An error occured while fetching the topics'
        );
      });
  };

  const handleFetchTopicDatas = (topic) => {
    axios
      .get(fetchMindMapTopicData(topic), {
        headers: getHeaders(currentProgram.programId),
      })
      .then((res) => {
        setProblems(res.data);
        setSelectedTopic(topic);
        navigate(`signature-frameworks/mindmap/${topic}`);
      });
  };

  const handleReset = () => {
    setSearchTerm('');
    setResult([]);
    setProblems([]);
    setSelectedTopic('');
    setSelectedSubTopic('');
  };

  return (
    <Box mt={1}>
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      {isSuccess && (
        <SuccessNotifier
          message={successMessage}
          setIsSuccess={setIsSuccess}
        />
      )}
      <Modal
        open={showLeetcodeModal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Box
            display='flex'
            justifyContent='flex-start'
            alignItems='center'
            gap={1}
          >
            <ErrorIcon sx={{ color: 'red', fontSize: '30px' }} />
            <Typography
              sx={{
                fontSize: '20px',
                fontWeight: 'bold',
              }}
            >
              Leetcode username not found!
            </Typography>
          </Box>
          <Typography
            id='modal-modal-description'
            sx={{ mt: 2 }}
          >
            Enter your leetcode username:
          </Typography>
          <TextField
            label='Leetcode Username'
            variant='outlined'
            fullWidth
            value={leetcodeUsername}
            onChange={(e) => setLeetcodeUsername(e.target.value)}
            sx={{ mt: 1 }}
            error={hasError}
            helperText={
              hasError
                ? 'Leetcode username cannot be empty or just spaces.'
                : ''
            }
          />
          <Typography
            id='modal-modal-description'
            sx={{ mt: 2, fontSize: '14px' }}
          >
            Note: The submission should be on the Leetcode platform in order to
            see the progress in the Mindmap.
          </Typography>
          <Box
            display='flex'
            justifyContent='center'
          >
            <Button
              variant='contained'
              color='primary'
              onClick={handleLeetcodeSubmit}
              sx={{
                mt: 2,
                alignSelf: 'center',
                padding: '8px 26px',
                borderRadius: '5px',
              }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Modal>
      {mindmapTopics.some((topics) => topics.name === topic) ? (
        <SubTopics
          selectedSubTopic={selectedSubTopic}
          setSelectedSubTopic={setSelectedSubTopic}
          problems={problems}
        />
      ) : (
        <Box>
          <Search
            searchTerm={searchTerm}
            handleSearchChange={handleSearchChange}
            findProblemTopics={findProblemTopics}
            handleReset={handleReset}
            result={result}
          />
          {result.length > 0 ? (
            <SearchResult
              loading={loading}
              result={result}
              completedProblemList={completedProblemList}
            />
          ) : (
            <Box
              display='flex'
              justifyContent='center'
            >
              <Topics
                setSelectedTopic={setSelectedTopic}
                handleFetchTopicDatas={handleFetchTopicDatas}
              />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default Mindmaps;

// @ts-nocheck
import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Box, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { getActiveLetter, getHeaders } from '../apis/urls';
import OfferLetterForm from '../components/offer letter/OfferLetterForm';
import LoadingScreen from './LoadingScreen';
import SuccessScreen from '../components/eligibility-forms/SuccessScreen';
import EfHeader from '../components/eligibility-forms/EfHeader';
import { getUserMail } from '../configs/auth';

// https://developer.phonepe.com/v1/reference/check-status-api-1/#:~:text=If%20the%20payment%20status%20is%20Pending%2C%20then%20Check%20Status%20API%20should%20be%20called%20in%20the%20following%20interval%3A
const retryIntervals = [
  20000, // First check at 20-25 seconds
  3000,
  3000,
  3000,
  3000,
  3000,
  3000,
  3000,
  3000,
  3000, // Every 3 seconds for the next 30 seconds
  6000,
  6000,
  6000,
  6000,
  6000,
  6000,
  6000,
  6000,
  6000,
  6000, // Every 6 seconds for the next 60 seconds
  10000,
  10000,
  10000,
  10000,
  10000,
  10000,
  10000,
  10000,
  10000,
  10000, // Every 10 seconds for the next 60 seconds
  30000,
  30000,
  30000,
  30000,
  30000,
  30000,
  30000,
  30000,
  30000,
  30000, // Every 30 seconds for the next 60 seconds
  60000,
  60000,
  60000,
  60000,
  60000,
  60000,
  60000,
  60000,
  60000,
  60000, // Every 1 minute until timeout
];

export default function VisitorHomePage() {
  const [loading, setLoading] = useState(false);
  const [leadFName, setLeadFName] = useState('');
  const [leadLName, setLeadLName] = useState('');
  const [leadId, setLeadId] = useState();
  const [message, setMessage] = useState('');
  const [activeOLInfo, setActiveOLInfo] = useState({});
  const [activeOLStatus, setActiveOLStatus] = useState('pending');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const pollingStartTime = useRef(-1);
  const pollingIntervalIndex = useRef(-1);
  const maxTimeout = 5 * 60 * 1000; // 5 minutes in milliseconds;

  useEffect(() => {
    fetchActiveOfferLetter();
  }, []);

  const fetchActiveOfferLetter = async () => {
    setLoading(true);
    try {
      const res = await axios.get(getActiveLetter(), {
        headers: getHeaders(),
      });

      const {
        status = null,
        message,
        offer_letter = {},
        is_fastrack = false,
      } = res.data;
      const { source } = offer_letter ?? {};
      if (!status) {
        navigate('/eligibility-forms');
        return;
      }

      setActiveOLStatus(status);
      setMessage(message);
      if (status !== 'success') {
        if (is_fastrack) {
          navigate('/eligibility-forms');
          return;
        }

        const { source = '' } = res.data?.offer_letter?.response ?? {};
        if (source === 'fastrack_flow') {
          navigate('/eligibility-forms');
          return;
        }
        setLeadFName(res?.data?.lead_fname);
        setLeadLName(res?.data?.lead_lname);
        setActiveOLInfo(res?.data?.offer_letter);
        setLeadId(res?.data?.offer_letter.lead_id);
      }

      if (status === 'processing') {
        if (pollingStartTime.current === -1) {
          pollingStartTime.current = Date.now();
        }
        pollingIntervalIndex.current += 1;
        pollForTransactionStatus();
      }
    } catch (e) {
      console.log(e);
      setErrorMessage(
        e?.response?.data?.message ?? 'Oops! Something went wrong.'
      );
    } finally {
      setLoading(false);
    }
  };

  const pollForTransactionStatus = () => {
    let totalTimeElapsed = Date.now() - pollingStartTime.current;

    if (
      pollingIntervalIndex.current >= retryIntervals.length ||
      totalTimeElapsed >= maxTimeout
    ) {
      console.log('Max timeout reached or all retries done.');
      setErrorMessage(
        "Couldn't get an update on your payment. Please contact your POC for more information."
      );
      return;
    }

    const delay = retryIntervals[pollingIntervalIndex.current];
    setTimeout(() => fetchActiveOfferLetter(), delay);
  };

  const expiryDate = moment(activeOLInfo?.expires_at);

  const renderComponent = () => {
    if (activeOLStatus === 'success') {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '10px',
            paddingBottom: '3rem',
            paddingTop: '2rem',
          }}
        >
          <SuccessScreen successMessage={message} />;
        </div>
      );
    }

    if (errorMessage !== '') {
      return <GeneralMessage message={errorMessage} />;
    }

    if (activeOLInfo) {
      return (
        <OfferLetterForm
          activeOLInfo={activeOLInfo}
          leadFName={leadFName}
          leadLName={leadLName}
          message={message}
          setMessage={setMessage}
          setActiveOLInfo={setActiveOLInfo}
          expiryDate={expiryDate}
          leadId={leadId}
          activeOLStatus={activeOLStatus}
        />
      );
    }

    return <></>;
  };

  return (
    <>
      {loading && <LoadingScreen loading={loading} />}
      <Stack
        sx={{
          width: '100vw',
          height: '100vh',
        }}
      >
        <EfHeader />

        <div
          style={{
            flexGrow: 1,
            overflow: 'auto',
            height: 'inherit',
            background: 'linear-gradient(45deg, #99ccff, #ffffcc, #ffffff)',
          }}
        >
          {renderComponent()}
        </div>
      </Stack>
    </>
  );
}

const GeneralMessage = ({ message }) => {
  const invitationMessage =
    'You need an invitation to apply to HeyCoach programs. If you are an existing learner, please check your login email id.';
  const isInvitationMessage =
    message.toLowerCase() === invitationMessage.toLowerCase();

  return (
    <Box
      sx={{
        width: '80%',
        maxWidth: 800,
        m: 'auto',
        p: 4,
      }}
    >
      <Typography
        color='#777'
        variant='h4'
        component='div'
        align='center'
      >
        {message}
      </Typography>

      {isInvitationMessage && (
        <>
          <br />
          <Typography
            color='#777'
            variant='body1'
            component='p'
            align='center'
          >
            You're currently logged in with {getUserMail()}.
          </Typography>
        </>
      )}
    </Box>
  );
};

// @ts-nocheck
import React, { useState } from 'react';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';
import { Box, Typography, Button } from '@mui/material';
import { useFastrackPipeline } from 'src/layouts/EfPipelineContext/FastrackPipelineContex';

const COLORS = ['#3da842', '#2070d1', '#ffd966', '#ff4d4f'];

const isMobileDevice = () => {
  return window.innerWidth <= 500;
};

const PersonalGrowthChart = ({ flowData, setFlowData, saveFlowData }) => {
  const { dailyCommitments, sleepHours, workHours } = flowData;
  const hoursPerDay = 24;

  const totalOccupiedHoursPerDay = workHours + sleepHours + dailyCommitments;
  let freeHoursPerDay = hoursPerDay - totalOccupiedHoursPerDay;

  freeHoursPerDay = freeHoursPerDay < 0 ? 0 : freeHoursPerDay;

  const data = [
    { name: 'Available learning hours', value: freeHoursPerDay },
    { name: 'Daily-commitment hours', value: dailyCommitments },
    { name: 'Sleep hours', value: sleepHours },
    { name: 'Working hours', value: workHours },
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const { setScreenSource, trackButtonClick } = useFastrackPipeline();
  const handleNext = () => {
    trackButtonClick({ source: 'screen_3d', eventName: 'lets_get_started' });
    saveFlowData({ screen: 'screen_3d' });
    freeHoursPerDay < 2
      ? setScreenSource('screen_3k')
      : setScreenSource('screen_3e');
  };

  return (
    <Box
      sx={{
        textAlign: 'center',
        borderRadius: '10px',
        margin: '0 auto',
        padding: {
          xs: 2,
          sm: 4,
          md: 6,
        },
        maxWidth: {
          xs: '100%',
          md: '800px',
          lg: '1000px',
        },
      }}
    >
      <Typography
        component='h1'
        sx={{
          color: '#1976d2',
          fontFamily: 'Inter',
          fontWeight: '700',
          fontSize: {
            xs: '1.6rem',
            sm: '2rem',
            md: '2.6rem',
          },
          marginBottom: {
            xs: 2,
          },
        }}
      >
        {freeHoursPerDay < 2 ? (
          <>
            Uh-oh, looks like we’ve hit a snag… <br />
            {`You have only ${freeHoursPerDay.toFixed(
              0
            )} hours left for your growth.`}
          </>
        ) : (
          `Awesome! You’ve unlocked ${freeHoursPerDay.toFixed(0)} hours`
        )}
      </Typography>

      {freeHoursPerDay >= 2 && (
        <Typography
          component='h1'
          sx={{
            color: '#1976d2',
            fontFamily: 'Inter',
            fontWeight: '700',
            fontSize: {
              xs: '1.6rem',
              sm: '2rem',
              md: '2.6rem',
            },
            marginBottom: {
              xs: 3,
            },
          }}
        >
          for your personal growth!
        </Typography>
      )}

      <Typography
        variant='body1'
        sx={{
          color: 'black',
          fontSize: {
            xs: '1rem',
            sm: '1.1rem',
            md: '1.3rem',
          },
          lineHeight: '1.6',
          marginBottom: {
            xs: 2,
          },
        }}
      >
        {freeHoursPerDay < 2
          ? `Based on your available time, you can spend only ${freeHoursPerDay.toFixed(
              0
            )} hour every day on your learning & personal growth.`
          : `With this time, you can easily fit in about ${freeHoursPerDay.toFixed(
              0
            )} hours of focused learning every day.`}
      </Typography>

      <Typography
        variant='body1'
        sx={{
          color: 'black',
          fontSize: {
            xs: '1rem',
            sm: '1.1rem',
            md: '1.3rem',
          },
          lineHeight: '1.6',
          marginBottom: {
            xs: 4,
          },
        }}
      >
        Ready to make it count and see how far you can go?
      </Typography>

      <Box
        display='flex'
        justifyContent='center'
        marginY={4}
      >
        <ResponsiveContainer
          width={isMobileDevice() ? '90%' : '100%'}
          height={isMobileDevice() ? 250 : 300}
        >
          <PieChart>
            <Pie
              data={data}
              cx='50%'
              cy='50%'
              outerRadius={isMobileDevice() ? 60 : 120}
              dataKey='value'
              nameKey='name'
              onClick={
                isMobileDevice() ? (data, index) => setActiveIndex(index) : null
              }
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index]}
                />
              ))}
            </Pie>
            <Tooltip active={activeIndex !== null} />
          </PieChart>
        </ResponsiveContainer>
      </Box>

      <Box
        display='flex'
        justifyContent='center'
        flexWrap='wrap'
        gap={2}
        marginBottom={4}
      >
        {data.map((entry, index) => (
          <Box
            key={`legend-${index}`}
            display='flex'
            alignItems='center'
            sx={{
              marginRight: 2,
            }}
          >
            <Box
              sx={{
                width: 16,
                height: 16,
                backgroundColor: COLORS[index],
                marginRight: 1,
              }}
            />
            <Typography variant='body2'>{entry.name}</Typography>
          </Box>
        ))}
      </Box>

      <Button
        variant='contained'
        sx={{
          backgroundColor: '#2070d1',
          color: '#fff',
          padding: {
            xs: '8px 12px',
            sm: '10px 20px',
          },
          fontSize: {
            xs: '0.9rem',
            sm: '1rem',
          },
        }}
        onClick={handleNext}
      >
        Let’s Get Started →
      </Button>
    </Box>
  );
};

export default PersonalGrowthChart;

import React, { useState } from 'react';
import { Button, Modal, Stack, TextField, Typography } from '@material-ui/core';
import { Box } from '@material-ui/system';
import axios from 'axios';
import { getHeaders, postCodeProblemTestcaseURL } from '../../../apis/urls';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  maxHeight: '630px',
  overflow: 'auto',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function AddTestcase({
  pid,
  setHasError,
  setErrorMessage,
  setIsSuccess,
  setSuccessMessage,
  openTestcase,
  setOpenTestcase,
}) {
  const [testcase, setTestcase] = useState('');
  const [updateModal, setUpdateModal] = useState(false);
  const [allResponse, setAllResponse] = useState();
  const [loadingStatus, setloadingStatus] = useState('');
  const [executionResMessage, setSxecutionResMessage] = useState('');

  const handleSubmit = (e) => {
    setloadingStatus('Loading...');
    e.preventDefault();
    axios
      .post(
        postCodeProblemTestcaseURL(pid),
        {
          stdin: testcase,
          language_ids: [54, 62, 71, 63],
        },
        {
          headers: getHeaders(),
        }
      )
      .then((res) => {
        setAllResponse(res.data.execution_response);
        if (res.data?.message) {
          setHasError(true);
          setSxecutionResMessage(res.data?.message);
          setErrorMessage(res.data?.message);
        } else {
          setIsSuccess(true);
          setSuccessMessage('Testcase successfully created.');
        }
        setUpdateModal(true);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(err.response?.data?.message || err.message);
      })
      .finally(() => {
        setloadingStatus('');
      });
  };

  const getSubmissionStatusForClient = (body) => {
    console.log(body);
    const message = [];

    if (body?.compile_output) {
      message.push(body?.compile_output);
    }
    if (body?.stderr) {
      message.push(body?.stderr);
    }
    if (body?.message) {
      message.push(body?.message);
    }
    if ((body?.stdout ?? '') !== '') {
      message.push(body?.stdout);
    }
    if (body?.status) {
      message.push(body?.status?.description);
    }
    return {
      message: message.join('\n'),
    };
  };
  return (
    <Modal
      open={openTestcase}
      onClose={() => setOpenTestcase(false)}
    >
      <form onSubmit={handleSubmit}>
        <Box sx={style}>
          <Stack spacing={3}>
            {executionResMessage && (
              <Typography
                variant='caption'
                fontSize='15px'
                fontWeight='500'
                color='red'
                marginBottom='6px'
                textAlign='center'
                display='block'
              >
                {executionResMessage}
              </Typography>
            )}
            <TextField
              fullWidth
              rows={10}
              id='input'
              label='Input'
              type='text'
              name='input'
              variant='outlined'
              value={loadingStatus || testcase}
              onChange={(e) => setTestcase(e.target.value)}
              multiline
              required
            />
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gap: '30px',
              }}
            >
              {updateModal &&
                Object.keys(allResponse).map((key) => {
                  return (
                    key && (
                      <Box>
                        <Typography
                          variant='caption'
                          fontSize='15px'
                          fontWeight='800'
                          marginBottom='6px'
                          textAlign='center'
                          display='block'
                        >
                          {key}
                        </Typography>
                        <TextField
                          fullWidth
                          rows={10}
                          id='output'
                          label='Output'
                          name='output'
                          variant='outlined'
                          value={
                            getSubmissionStatusForClient(allResponse[key])
                              .message
                          }
                          multiline
                          inputProps={{ readOnly: true }}
                        />
                      </Box>
                    )
                  );
                })}
            </Box>
            <Button
              size='large'
              type='submit'
              variant='contained'
              color='secondary'
              disabled={updateModal}
            >
              Add
            </Button>
          </Stack>
        </Box>
      </form>
    </Modal>
  );
}

import React, { useState } from 'react';
import { useTable, useSortBy } from 'react-table';
import {
  Box,
  Button,
  Modal,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Typography,
} from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import IndividualLearnerView from './IndividualLearnerView';
import SouthIcon from '@mui/icons-material/South';

function TableView({
  columns,
  data,
  setSortField,
  setSortOrder,
  sortField,
  sortOrder,
}) {
  const [visibleColumns, setVisibleColumns] = useState(columns);
  const [learnerView, setLearnerView] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState(
    columns.map((column) => column.Header)
  );

  const handleSelectChange = (event) => {
    const value = event.target.value;
    setSelectedColumns(value);
  };

  const defaultSorted = React.useMemo(
    () => [
      {
        id: 'lastCalled',
        desc: true,
      },
    ],
    []
  );

  const handleFilterClick = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleRowClick = (row) => {
    setSelectedUser(row.original);
    setLearnerView(true);
  };

  const handleLearnerClose = () => {
    setSelectedUser(null);
    setLearnerView(false);
  };

  const handleSubmit = () => {
    setVisibleColumns(
      columns.filter((column) => selectedColumns.includes(column.Header))
    );
    setShowPopup(false);
  };

  const getBackgroundColor = (value) => {
    const days = parseInt(value.split(' ')[0], 10);
    if (days >= 6) return '#ff393980';
    if (days >= 4) return '#ffffad';
    return '#bbffbb';
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns: visibleColumns,
        data,
      },
      useSortBy
    );

  return (
    <Box position='relative'>
      {learnerView ? (
        <Modal
          open={learnerView}
          onClose={handleLearnerClose}
        >
          <IndividualLearnerView
            handleLearnerClose={handleLearnerClose}
            user={selectedUser}
          />
        </Modal>
      ) : (
        <Box
          display='flex'
          flexDirection='column'
        >
          {showPopup && (
            <Modal
              open={showPopup}
              onClose={handleClosePopup}
              aria-labelledby='modal-modal-title'
              aria-describedby='modal-modal-description'
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Box
                sx={{
                  width: '900px',
                  height: '250px',
                  bgcolor: 'background.paper',
                  boxShadow: 24,
                  p: 3,
                  background: '#fff',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                  borderRadius: '8px',
                }}
              >
                <Button
                  onClick={handleClosePopup}
                  variant='outlined'
                >
                  Close
                </Button>
                <Box
                  display='flex'
                  gap={1}
                >
                  <Select
                    multiple
                    value={selectedColumns}
                    onChange={handleSelectChange}
                    renderValue={(selected) => selected.join(', ')}
                    style={{ width: '100%' }}
                  >
                    {columns.map((column, index) => (
                      <MenuItem
                        key={index}
                        value={column.Header}
                      >
                        <Checkbox
                          checked={selectedColumns.indexOf(column.Header) > -1}
                        />
                        <ListItemText primary={column.Header} />
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <Button
                  onClick={handleSubmit}
                  style={{ marginTop: '10px' }}
                  variant='contained'
                >
                  Submit
                </Button>
              </Box>
            </Modal>
          )}

          <Button
            sx={{ cursor: 'pointer', alignSelf: 'flex-end' }}
            onClick={handleFilterClick}
          >
            <FilterAltIcon />
          </Button>
          <div style={{ overflow: 'auto' }}>
            <table
              {...getTableProps()}
              style={{
                margin: '0 auto',
                fontSize: '15px',
                borderCollapse: 'collapse',
                width: '100%',
              }}
            >
              <thead>
                {headerGroups.map((headerGroup, i) => (
                  <tr
                    key={i}
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map((column, i) => (
                      <th
                        key={i}
                        {...column.getHeaderProps()}
                        style={{
                          border: 'solid 1px #ddd',
                          color: '#333',
                          fontWeight: 'bold',
                          padding: '10px',
                          cursor: 'pointer',
                          textAlign: 'center',
                        }}
                        onClick={() =>
                          handleSorting(
                            setSortField,
                            setSortOrder,
                            column,
                            sortField
                          )
                        }
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          <Typography variant='body1'>
                            {column.render('Header')}
                          </Typography>
                          {column.id === sortField ? (
                            <SouthIcon
                              style={{
                                fontSize: '16px',
                                transform:
                                  sortOrder === 'desc' ? 'rotate(180deg)' : '',
                                height: 'auto',
                              }}
                            />
                          ) : null}
                        </Box>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr
                      key={i}
                      {...row.getRowProps()}
                      style={{
                        background: '#fff',
                        cursor: 'pointer',
                      }}
                      onClick={() => handleRowClick(row)}
                    >
                      {row.cells.map((cell, i) => (
                        <td
                          key={i}
                          {...cell.getCellProps()}
                          style={{
                            padding: '10px',
                            border: 'solid 1px #ddd',
                            backgroundColor:
                              cell.column.id === 'lastCalled'
                                ? getBackgroundColor(cell.value)
                                : 'inherit',
                          }}
                        >
                          {cell.value === '0 days ago'
                            ? 'Today'
                            : cell.render('Cell')}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Box>
      )}
    </Box>
  );
}

const handleSorting = (setSortField, setSortOrder, column, sortField) => {
  setSortField(column.id);
  if (sortField === column.id) {
    setSortOrder('desc');
  } else {
    setSortOrder('asc');
  }
};

export default TableView;

import React from 'react';
import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import settings from '@iconify/icons-ant-design/setting-outline';
import containerFilled from '@iconify/icons-ant-design/container-filled';
import appstoreAddOutlined from '@iconify/icons-ant-design/appstore-add-outlined';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import HailRoundedIcon from '@mui/icons-material/HailRounded';
import AssessmentIcon from '@mui/icons-material/Assessment';
import EditIcon from '@mui/icons-material/Edit';
import StairsIcon from '@mui/icons-material/Stairs';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ManageSearchSharpIcon from '@mui/icons-material/ManageSearchSharp';
import LockIconComponent from 'src/components/TheGreatePyramid/Atoms/LockIconComponent';
import ticketingIcon from '../icons/confirmation_number.png';
import OpenedTickets from 'src/components/TicketingSystem/Atoms/OpenedTickets';

const icon = 'static/mock-images/resource-outlined.svg';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <Icon
    icon={name}
    width={22}
    height={22}
  />
);

const userRole = 'mentor';

// states: 'both', 'finance', 'admin'.
const sidebarConfig = [
  {
    title: 'Finances',
    path: `/${userRole}/finances`,
    icon: <CurrencyRupeeIcon />,
    isFor: ['finance'],
  },
  {
    title: 'Generate receipt',
    path: `/${userRole}/generate_receipt`,
    icon: <ReceiptIcon />,
    isFor: ['finance'],
  },
  {
    title: 'Programs',
    path: `/${userRole}/programs`,
    icon: getIcon(containerFilled),
    isFor: ['admin', 'program_manager', 'teaching_assistant'],
  },
  {
    title: 'Metabase TGP',
    path: `/${userRole}/metabase/batch_wise_tgp_record`,
    icon: getIcon(containerFilled),
    isFor: ['admin', 'program_manager'],
  },
  {
    title: 'Metabase Coding Assignment',
    path: `/${userRole}/metabase/coding_assignment_submissions`,
    icon: getIcon(containerFilled),
    isFor: ['admin', 'program_manager'],
  },
  {
    title: 'Metabase Session Test',
    path: `/${userRole}/metabase/session_test`,
    icon: getIcon(containerFilled),
    isFor: ['admin', 'program_manager'],
  },
  {
    title: 'Problems library',
    path: `/${userRole}/problems_library`,
    icon: <EditIcon />,
    isFor: ['admin', 'program_manager', 'problem_setter', 'teaching_assistant'],
  },
  // {
  //   title: 'My Profile',
  //   path: `/${userRole}/user/profile`,
  //   icon: getIcon(peopleFill)
  // },
  {
    title: 'Levels',
    path: `/${userRole}/levels`,
    icon: <StairsIcon />,
    isFor: ['admin', 'program_manager'],
  },
  {
    title: 'Placements',
    path: `/${userRole}/placements`,
    icon: <HailRoundedIcon />,
    isFor: ['admin', 'program_manager'],
  },
  {
    title: 'Reports',
    path: `/${userRole}/program_reports`,
    icon: <AssessmentIcon />,
    isFor: ['admin', 'program_manager'],
  },
  {
    title: 'My Profile',
    path: `/${userRole}/user/settings`,
    icon: getIcon(settings),
    isFor: ['admin', 'finance', 'program_manager'],
  },
  {
    title: 'Learner Search',
    path: `/${userRole}/learner_search`,
    icon: <ManageSearchSharpIcon />,
    isFor: ['admin', 'program_manager'],
  },
  {
    title: 'Help Tickets',
    path: `/${userRole}/tickets`,
    icon: <img src={ticketingIcon} />,
    isFor: ['admin', 'program_manager', 'teaching_assistant'],
    info: <OpenedTickets />,
  },
];

export default sidebarConfig;

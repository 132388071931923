// @ts-nocheck
import { Stack, Typography, useTheme } from '@mui/material';
import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import QuestionDescription from './QuestionDescription';

const options = [
  'Move from a service-based role to a product company.',
  'Move from a product company to a leading tech company.',
  'Move to a senior role in a rapidly growing unicorn startup.',
  'Other',
];

const GoalSelection = ({ id, response, setResponse, setShouldAllowNext }) => {
  const [showTextfield, setShowTextfield] = useState(false);
  const [otherReason, setOtherReason] = useState('');
  const theme = useTheme();

  const handleClick = (value) => {
    if (value === 'Other') {
      if (showTextfield) {
        setResponse((prev) => {
          const x = { ...prev };
          delete x[id];
          return x;
        });
        setOtherReason('');
      } else {
        setResponse((prev) => ({ ...prev, [id]: 'Other' }));
      }
      setShouldAllowNext(false);
      setShowTextfield((prev) => !prev);
      return;
    }

    if (showTextfield) {
      setShowTextfield((prev) => !prev);
    }

    if (response[id] === value) {
      setResponse((prev) => {
        const x = { ...prev };
        delete x[id];
        return x;
      });
      setShouldAllowNext(false);
    } else {
      setResponse((prev) => ({ ...prev, [id]: value }));
      setShouldAllowNext(true);
    }
  };

  const handleOtherFieldChange = (e) => {
    const { value } = e.target;
    setOtherReason(value);
    setResponse((prev) => ({ ...prev, [id]: `Other(${value})` }));
    if (value === '') {
      setShouldAllowNext(false);
    } else {
      setShouldAllowNext(true);
    }
  };

  const shouldHighlight = (val) => {
    return response[id] && response[id].includes(val);
  };

  return (
    <Stack spacing={2}>
      <>
        <QuestionDescription
          title="We are excited to see you're taking the first step towards transforming your tech career."
          subTitle="Let's start by selecting a goal."
        />
        <Typography
          variant='body1'
          component='p'
          sx={{
            fontWeight: 400,
            fontFamily: 'Inter',
          }}
        >
          <i>(single-select)</i>
        </Typography>
      </>

      <Stack spacing={1}>
        {options.map((opt, index) => {
          return (
            <Typography
              variant='body1'
              component='p'
              sx={{
                borderRadius: '10px',
                padding: '5px',
                background: 'white',
                width: 'fit-content',
                cursor: 'pointer',
                fontSize: '1.2rem',
                fontFamily: 'Inter',
                ...(shouldHighlight(opt) && {
                  border: '2px solid blue',
                }),
                [theme.breakpoints.down('sm')]: {
                  fontSize: '0.9rem',
                },
              }}
              onClick={() => handleClick(opt)}
            >
              {String.fromCharCode(65 + index)}. &nbsp; {opt}
            </Typography>
          );
        })}

        {showTextfield && (
          <TextField
            variant='outlined'
            value={otherReason}
            onChange={handleOtherFieldChange}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default GoalSelection;

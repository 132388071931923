// @ts-nocheck
import {
  Box,
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { LoadingButton } from '@material-ui/lab';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useEfPipeline } from '../../layouts/EfPipelineContext/EfPipelineContext';
import { BASE_URL, getHeaders } from '../../apis/urls';
import moment from 'moment';
import ScreeningTestStartsInCountdown from './ScreeningTestStartsInCountdown';
import LoadingScreen from 'src/pages/LoadingScreen';

const Instructions = ({
  setStartedAt,
  setShowInstructions,
  setScreeningTestStatus,
  setHasError,
  setErrorMessage,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [testStarting, setTestStarting] = useState(false);
  const { formId, setShowScreeningTest, setFormId, setMessage } =
    useEfPipeline();

  // Remove this useEffect if we want to show instruction screen.
  useEffect(() => {
    startScreeningTest();
  }, []);

  const startScreeningTest = async () => {
    try {
      setTestStarting(true);
      const res = await axios.post(
        `${BASE_URL}/api/eligibility-forms/${formId}/screening-test`,
        {
          action_type: 'start',
        },
        {
          headers: getHeaders(),
        }
      );

      const { message, started_at, status } = res.data;
      if (message && message.toLowerCase() !== 'ok') {
        setShowScreeningTest(false);
        setFormId(null);
        setMessage(message);
        return;
      }

      setShowInstructions(false);
      setScreeningTestStatus(status);
      setStartedAt(moment(started_at));
    } catch (err) {
      console.log(err);
      setHasError(true);
      setErrorMessage(
        err?.response?.data?.message ?? 'Oops! Something went wrong.'
      );
    } finally {
      setTestStarting(false);
    }
  };

  // Remove this statement if we want to show instruction screen.
  return <LoadingScreen loading />;

  return (
    <Stack
      alignItems='center'
      justifyContent='center'
      sx={{
        marginTop: 'clamp(1rem, 2rem, 3rem)',
        backgroundColor: 'white',
        borderRadius: '8px',
        height: 'fit-content',
        width: '60vw',
        paddingLeft: 'clamp(1rem, 1.5rem, 2rem)',
        paddingRight: 'clamp(1rem, 1.5rem, 2rem)',
        paddingTop: 'clamp(2rem, 3.5rem, 5rem)',
        paddingBottom: 'clamp(1.5rem, 2.5rem, 3rem)',
        [theme.breakpoints.down('md')]: {
          height: 'fit-content',
        },
        [theme.breakpoints.down('sm')]: {
          width: '95vw',
        },
        '@media (min-width: 600px) and (max-width: 1250px)': {
          width: '80vw',
        },
      }}
      spacing={2}
    >
      <Typography
        variant='h2'
        component='h2'
        sx={{
          color: '#1976d2',
          textAlign: 'center',
          fontFamily: 'Inter',
          [theme.breakpoints.down('md')]: {
            fontSize: '2.4rem',
          },
          [theme.breakpoints.down('sm')]: {
            fontSize: '2rem',
          },
          width: 'fit-content',
        }}
      >
        Fastrack Screening Test
      </Typography>

      <Typography
        variant='h4'
        component='h4'
        sx={{
          textAlign: 'center',
          textWrap: 'pretty',
          fontFamily: 'Inter',
          fontWeight: 400,
          [theme.breakpoints.down('sm')]: {
            fontSize: '1.1rem',
          },
        }}
      >
        Make the lead towards your dream career in just 15 minutes.
      </Typography>

      <Stack
        width='70%'
        alignSelf='center'
        sx={{
          width: '70%',
          [theme.breakpoints.down('sm')]: {
            width: '93%',
          },
        }}
      >
        <Typography
          variant='h6'
          component='h6'
          sx={{
            textWrap: 'pretty',
            fontFamily: 'Inter',
            fontWeight: 400,
            [theme.breakpoints.down('sm')]: {
              fontSize: '0.9rem',
            },
          }}
        >
          Before you begin:
        </Typography>
        <Typography component='ul'>
          <Typography
            variant='h6'
            component='li'
            sx={{
              textWrap: 'pretty',
              fontFamily: 'Inter',
              fontWeight: 400,
              [theme.breakpoints.down('sm')]: {
                fontSize: '0.9rem',
              },
            }}
          >
            The test consists of five questions to assess if you're fit for our
            program.
          </Typography>

          <Typography
            variant='h6'
            component='li'
            sx={{
              textWrap: 'pretty',
              fontFamily: 'Inter',
              fontWeight: 400,
              fontSize: '0.5rem',
              [theme.breakpoints.down('sm')]: {
                fontSize: '0.9rem',
              },
            }}
          >
            You'll have a total of 15 minutes, so do your best.
          </Typography>

          <Typography
            variant='h6'
            component='li'
            sx={{
              textWrap: 'pretty',
              fontFamily: 'Inter',
              fontWeight: 400,
              [theme.breakpoints.down('sm')]: {
                fontSize: '0.9rem',
              },
            }}
          >
            No preparation is needed; we want to know your current level.
          </Typography>
        </Typography>
      </Stack>

      <Box
        alignSelf='center'
        height='fit-content'
      >
        <ScreeningTestStartsInCountdown
          startScreeningTest={startScreeningTest}
        />
      </Box>
    </Stack>
  );
};

export default Instructions;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tickets: [],
  loading: false,
};

export const ticketSlice = createSlice({
  name: 'tickets',
  initialState,
  reducers: {
    setTickets: (state, action) => {
      state.tickets = action.payload.tickets.map((ticket, index) => {
        ticket.index = index + 1;
        return ticket;
      });
    },
    updateStatus: (state, action) => {
      const ticket_id = action.payload.ticket_id;
      const status = action.payload.status;
      const ticket = state.tickets.filter(
        (ticket) => ticket.id === ticket_id
      )[0];
      ticket.status = status;
    },
    updateInteraction: (state, action) => {
      const ticket_id = action.payload.ticket_id;
      const interaction = action.payload.interaction;
      state.tickets = state.tickets.map((ticket) => {
        if (ticket.id === ticket_id) {
          ticket.ticket_interactions.push(interaction);
        }
        return ticket;
      });
    },
  },
});

export const { setTickets, updateStatus, updateInteraction } =
  ticketSlice.actions;

export default ticketSlice.reducer;

// @ts-nocheck
import React, { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Collapse,
  IconButton,
  Divider,
} from '@mui/material';
import { Add, Close } from '@mui/icons-material';
import EastIcon from '@mui/icons-material/East';
import { SmallChangesList } from '..';
import { useFastrackPipeline } from 'src/layouts/EfPipelineContext/FastrackPipelineContex';

const SmallChangesBigResults = ({ flowData, setFlowData, saveFlowData }) => {
  const { setScreenSource, trackButtonClick } = useFastrackPipeline();
  const [expanded, setExpanded] = useState([false, false, false, false]);

  const toggleExpand = (index) => {
    const newExpanded = [...expanded];
    newExpanded[index] = !newExpanded[index];
    setExpanded(newExpanded);
  };

  const handleContinue = () => {
    trackButtonClick({
      source: 'screen_3n',
      eventName: 'make_it_work_for_you',
    });
    saveFlowData({ screen: 'screen_3n' });
    setScreenSource('screen_3o');
  };

  return (
    <Box
      sx={{
        padding: {
          xs: '1rem',
          sm: '1.5rem',
          md: '2rem',
        },
        textAlign: 'center',
        margin: 'auto',
        width: '100%',
        maxWidth: {
          xs: '95%',
          sm: '90%',
          md: '80%',
          lg: '70%',
          xl: '60%',
        },
      }}
    >
      <Typography
        variant='h1'
        sx={{
          fontWeight: 'bolder',
          color: '#0078F0',
          marginBottom: '1.5rem',
          fontSize: {
            xs: '1.4rem',
            sm: '1.8rem',
            md: '2rem',
            lg: '2.5rem',
            xl: '3rem',
          },
        }}
      >
        Small changes, big results
      </Typography>
      <Typography
        variant='body1'
        sx={{
          fontSize: {
            xs: '0.85rem',
            sm: '1rem',
            md: '1.1rem',
          },
          color: '#555',
          marginBottom: '2rem',
        }}
      >
        Here are the 4 things that Shreya did to make learning fit into her busy
        life
      </Typography>

      {SmallChangesList.map((item, index) => (
        <Box
          key={item.id}
          sx={{
            marginBottom: '1.5rem',
            width: '100%',
            maxWidth: {
              xs: '100%',
              sm: '100%',
              md: '750px',
            },
            margin: '0 auto',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              cursor: 'pointer',
              padding: {
                xs: '0.8rem',
                sm: '1rem',
                md: '1rem',
              },
              width: '100%',
              flexWrap: 'nowrap',
            }}
            onClick={() => toggleExpand(index)}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexGrow: 1,
                justifyContent: 'space-between',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              <Typography
                variant='body1'
                sx={{
                  fontWeight: 'bold',
                  color: 'gray',
                  marginRight: '0.5rem',
                  fontSize: {
                    xs: '0.75rem',
                    sm: '0.9rem',
                    md: '1.1rem',
                    lg: '1.3rem',
                  },
                }}
              >
                {`0${item.id}`}
              </Typography>
              <Typography
                variant='body1'
                sx={{
                  textAlign: 'left',
                  fontWeight: 'bold',
                  color: '#0078F0',
                  fontSize: {
                    xs: '0.85rem',
                    sm: '1rem',
                    md: '1.2rem',
                    lg: '1.4rem',
                  },
                  flexGrow: 1,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {item.title}
              </Typography>
            </Box>
            <IconButton
              sx={{
                backgroundColor: expanded[index] ? 'black' : '#f3f5f6',
                color: expanded[index] ? 'white' : 'inherit',
                marginLeft: '0.5rem',
                '&:hover': {
                  backgroundColor: 'white',
                  color: 'black',
                },
              }}
            >
              {expanded[index] ? <Close /> : <Add />}
            </IconButton>
          </Box>

          <Collapse
            in={expanded[index]}
            timeout='auto'
            unmountOnExit
          >
            <Box
              sx={{
                maxWidth: '100%',
                paddingLeft: {
                  xs: '1.5rem',
                  sm: '3rem',
                  md: '4rem',
                },
                paddingRight: '1rem',
              }}
            >
              <Typography
                variant='body2'
                sx={{
                  textAlign: 'left',
                  fontSize: '1rem',
                  color: '#555',
                }}
              >
                {item.description}
              </Typography>
            </Box>
          </Collapse>
          <Divider sx={{ marginTop: '1rem' }} />
        </Box>
      ))}

      <Button
        variant='contained'
        sx={{
          backgroundColor: '#0078F0',
          color: 'white',
          fontWeight: 'bold',
          marginTop: '2rem',
          padding: {
            xs: '0.4rem 1.5rem',
            sm: '0.5rem 2rem',
          },
          borderRadius: '8px',
          fontSize: {
            xs: '0.85rem',
            sm: '0.9rem',
            md: '1rem',
          },
        }}
        onClick={handleContinue}
        endIcon={<EastIcon />}
      >
        Make it work for you
      </Button>
    </Box>
  );
};

export default SmallChangesBigResults;

import { Chip } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { getHeaders, ticketsOverview } from 'src/apis/urls';

function OpenedTickets() {
  const [openTickets, setOpenTickets] = useState(0);

  useEffect(() => {
    axios
      .get(ticketsOverview(), { headers: getHeaders() })
      .then((res) => {
        setOpenTickets(res?.data?.count);
      })
      .catch((error) => {
        console.error('There was an error fetching the ticket data!', error);
      });
  }, []);

  return (
    <Chip
      label={openTickets}
      sx={{
        backgroundColor: '#e74c3c',
        color: 'white',
        transform: 'scale(0.8)',
      }}
    />
  );
}

export default OpenedTickets;

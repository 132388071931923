// @ts-nocheck
import {
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import React, { useEffect, useState } from 'react';
import { useFastrackPipeline } from 'src/layouts/EfPipelineContext/FastrackPipelineContex';

const SelectReasonForWorriedness = ({
  setFlowData,
  flowData,
  saveFlowData,
}) => {
  const { screenSource, setScreenSource, trackButtonClick } =
    useFastrackPipeline();
  const theme = useTheme();
  const isBelowMedium = useMediaQuery(theme.breakpoints.down('md'));

  const handleClick = (value) => {
    trackButtonClick({
      source: 'pcf_welcome_screen',
      eventName: value,
    });
    setFlowData((prev) => ({ ...prev, worriedness_reason: value }));
    saveFlowData({
      screen: 'pcf_welcome_screen',
      response: { worriedness_reason: value },
    });
    setScreenSource('ps_effectiveness_1');
  };

  return (
    <Stack
      spacing={4}
      paddingTop='1rem'
      alignItems='center'
      width='100%'
    >
      <Stack
        height='15rem'
        justifyContent='space-evenly'
        alignItems='center'
        width='100%'
        textAlign='center'
      >
        <Typography
          variant='h3'
          component='h3'
          sx={{
            color: '#1976d2',
            fontFamily: 'Inter',
            fontWeight: 'bolder',
            fontSize: {
              xs: '1.6rem',
              sm: '1.6rem',
              md: '1.8rem',
              lg: '1.8rem',
            },
            width: 'fit-content',
            textAlign: 'center',
            textWrap: 'stable',
          }}
        >
          We get you!
        </Typography>

        <Typography
          variant='body1'
          component='p'
          sx={{
            textAlign: 'center',
            fontFamily: 'Inter',
            fontWeight: 'normal',
            fontSize: {
              xs: '1rem',
              sm: '1.2rem',
              md: '1.1rem',
              lg: '1.3rem',
            },
            textWrap: 'balance',
          }}
        >
          Let's explore what makes you feel worried about not landing a job
          after the course.
        </Typography>

        <Typography
          variant='body1'
          component='p'
          sx={{
            textAlign: 'center',
            fontFamily: 'Inter',
            fontWeight: 'normal',
            fontSize: {
              xs: '1rem',
              sm: '1.2rem',
              md: '1.1rem',
              lg: '1.3rem',
            },
            textWrap: 'balance',
          }}
        >
          <b>Which of these reasons feels most relevant to you?</b>
        </Typography>
      </Stack>

      <Stack
        spacing={4}
        direction={isBelowMedium ? 'column' : 'row'}
        justifyContent='space-evenly'
        alignItems='center'
        width='100%'
      >
        <Button
          sx={{
            color: '#1976d2',
            width: {
              xs: '100%',
              sm: '80%',
              md: '40%',
              lg: '32%',
            },
            textTransform: 'none',
            fontFamily: 'Inter',
            fontSize: '1rem',
            fontWeight: 650,
            borderColor: '#1976d2',
          }}
          onClick={() =>
            handleClick('The course may not skill me for top tech companies')
          }
          variant='outlined'
        >
          The course may not skill me for top tech companies
        </Button>

        <Button
          sx={{
            color: '#1976d2',
            width: {
              xs: '100%',
              sm: '80%',
              md: '40%',
              lg: '32%',
            },
            textTransform: 'none',
            fontFamily: 'Inter',
            fontSize: '1rem',
            fontWeight: 650,
            borderColor: '#1976d2',
          }}
          onClick={() =>
            handleClick(
              "Placement support might not be effective after I'm skilled"
            )
          }
          variant='outlined'
        >
          Placement support might not be effective after I'm skilled
        </Button>
      </Stack>
    </Stack>
  );
};

export default SelectReasonForWorriedness;

// @ts-nocheck
import React, { useEffect, useState } from 'react';
import {
  Typography,
  Box,
  Modal,
  IconButton,
  TextField,
  Tabs,
  Tab,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import BroadcastMessageToLearners from './BroadcastMessageToLearners';
import BroadcastMessageToPrograms from './BroadcastMessageToPrograms';
import { fetchProgramsUrl, getHeaders } from '../../../apis/urls';

export default function SendMessageModal({
  open,
  handleClose,
  setHasError,
  setErrorMessage,
  setIsSuccess,
  setSuccessMessage,
}) {
  const [programs, setPrograms] = useState({});
  const [message, setMessage] = useState('');
  const [selectedTab, setSelectedTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const [sanitizedMessage, setSanitizedMessage] = useState('');
  const [emailFirstCheck, setEmailFirstCheck] = useState(false);
  const [emailSecondCheck, setEmailSecondCheck] = useState(false);

  useEffect(() => {
    getPrograms();
    return () => {};
  }, []);

  const getPrograms = () => {
    setLoading(true);
    axios
      .get(fetchProgramsUrl(), {
        headers: getHeaders(),
      })
      .then((res) => {
        const programData = res.data.map((p) => ({
          programId: p.id,
          internal_name: p.internal_name,
        }));
        setPrograms(programData);
      })
      .catch((e) => {
        console.log(e);
        setHasError(e);
        setErrorMessage(
          e.response?.data?.message || 'Could not fetch program details'
        );
      })
      .finally(() => setLoading(false));
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleMessageChange = (e) => {
    if (e.target.value.trim() === '') return;

    const unsanitizedMessage = e.target.value;
    const sanitizedMessage = e.target.value.replace(/\n/g, '\\n');

    setMessage(unsanitizedMessage);
    setSanitizedMessage(sanitizedMessage);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxWidth: 650,
            maxHeight: '90%',
            bgcolor: 'background.paper',
            p: 4,
            overflowY: 'auto',
          }}
        >
          <IconButton
            aria-label='Close'
            sx={{
              position: 'absolute',
              top: 8,
              right: 4,
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>

          <Typography variant='h5'>Send a new Message</Typography>
          <Box>
            <TextField
              variant='outlined'
              label='Message'
              fullWidth
              value={message}
              onChange={handleMessageChange}
              required
              multiline
              minRows={5}
              maxRows={6}
              margin='normal'
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={emailFirstCheck}
                  onChange={() => setEmailFirstCheck((prev) => !prev)}
                />
              }
              label='This is a last minute update on schedule change.'
            />

            {emailFirstCheck && (
              <Box>
                <Box
                  sx={{
                    padding: '4px 4px 4px 14px',
                    background: 'linear-gradient(to right, #f7eac6, #fffbed)',
                    borderRadius: '40px',
                    marginBottom: '16px',
                  }}
                >
                  <Typography sx={{ fontSize: '14px' }}>
                    {' '}
                    All the selected learners will be sent an email about the
                    changes.
                  </Typography>
                </Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={emailSecondCheck}
                      onChange={() => setEmailSecondCheck((prev) => !prev)}
                    />
                  }
                  label='This message requires immediate attention of the learner.'
                />
              </Box>
            )}
            <Typography variant='h6'>Send To</Typography>
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              indicatorColor='primary'
              textColor='primary'
            >
              <Tab label='Program' />
              <Tab label='Learners' />
            </Tabs>

            {selectedTab === 0 && (
              <BroadcastMessageToPrograms
                message={sanitizedMessage}
                programs={programs}
                setHasError={setHasError}
                setErrorMessage={setErrorMessage}
                setIsSuccess={setIsSuccess}
                setSuccessMessage={setSuccessMessage}
                handleClose={handleClose}
                notifyByEmail={emailSecondCheck}
                loading={loading}
              />
            )}

            {selectedTab === 1 && (
              <BroadcastMessageToLearners
                message={sanitizedMessage}
                programs={programs}
                setHasError={setHasError}
                setErrorMessage={setErrorMessage}
                setIsSuccess={setIsSuccess}
                setSuccessMessage={setSuccessMessage}
                handleClose={handleClose}
                notifyByEmail={emailSecondCheck}
              />
            )}
          </Box>
        </Box>
      </Modal>
    </>
  );
}

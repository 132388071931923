// @ts-nocheck
import React, { useState } from 'react';
// import GoogleLogin from 'react-google-login';
import { GoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { loginUrl } from '../../apis/urls';
import {
  setUserLoggedIn,
  getUserType,
  getUserOnboardingStatus,
} from '../../configs/auth';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';

export default function AuthSocial() {
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const onboarding = getUserOnboardingStatus();
  const responseGoogleSuccess = (response) => {
    const res = {
      credential: response.credential,
    };

    axios
      .post(loginUrl(), res)
      .then((res) => {
        setUserLoggedIn(res.data.user);
        if (getUserType() === 'student' && onboarding === 'ongoing') {
          navigate('/onboarding');
        }
        if (
          getUserType() === 'admin' ||
          getUserType() === 'program_manager' ||
          getUserType() === 'finance' ||
          getUserType() === 'problem_setter' ||
          getUserType() === 'teaching_assistant'
        ) {
          navigate('/mentor');
        } else if (getUserType() === 'visitor') {
          navigate('/offer-letters');
        } else if (getUserType() === 'visitor') {
          navigate('/eligibility-forms');
        } else {
          navigate('/student');
        }
      })
      .catch((err) => {
        console.log(err.toString());
        setHasError(true);
        setErrorMessage(`An unexpected error occured: ${err.message}`);
      });
  };

  const responseGoogleFailure = (response) => {
    console.log(response);
    setHasError(true);
    setErrorMessage(`Google OAuth Failed: ${response?.error}`);
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}

      <GoogleLogin
        onSuccess={(credentialResponse) => {
          responseGoogleSuccess(credentialResponse);
        }}
        onError={(e) => {
          responseGoogleFailure(e);
        }}
        shape='pill'
        theme='filled_blue'
        useOneTap
        use_fedcm_for_prompt
      />
    </>
  );
}

import { Divider, Paper, TextField, Typography } from '@material-ui/core';
import { Box } from '@material-ui/system';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MessageIcon from '@mui/icons-material/Message';
import React from 'react';
import LearnerTaskDiscussions from './LearnerTaskDiscussions';

export default function LearnerSelectedTask({ task, setOpenDetailedTask }) {
  return (
    <>
      <Box display="flex" justifyContent="flex-start">
        <Box onClick={() => setOpenDetailedTask(false)} display="flex" sx={{cursor: 'pointer', m: 1}}>
          <ArrowBackIcon fontSize='medium' color="primary" />
          <Typography variant="body2" color="primary">Back to all tasks</Typography>
        </Box>
      </Box>
      <Paper elevation={3} sx={{ml: 2}} variant="outlined">
        <Box sx={{ml: 1, mr: 1}}>
          <TextField
            multiline
            defaultValue={task.title}
            style={{width: "100%", padding: 10, margin: 2}}
            InputProps={{ readOnly: true, disableUnderline: true }}
            variant="filled"
          />
        </Box>
        <Box sx={{m: 2}}>
          <Divider />
          <Box display="flex" alignItems="center" sx={{height: 40}}>
            <MessageIcon sx={{mr: 1}}/>
            <Typography variant='body1'>
              Comments
            </Typography>
          </Box>
          <Divider />
          <LearnerTaskDiscussions task={task} />
        </Box>
      </Paper>
    </>
  );
}
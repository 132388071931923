import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import {
  Typography,
  Link,
  Box,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Button,
} from '@material-ui/core';
import CircularProgress from '@mui/material/CircularProgress';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { useParams, Link as RouterLink, useNavigate } from 'react-router-dom';
import { ReactComponent as TickIcon } from '../../../../images/tick_icon.svg';
import CardList from '../Organisms/CardList';
import { DSATopicsDescription } from '../DSATopicsDescription';
import LoadingScreen from '../../../../pages/LoadingScreen';
import {
  fetchDsaVisualizer,
  getHeaders,
  postDsaVisualizerMarkedasCompleted,
} from '../../../../apis/urls';
import { shouldShowNewStudentLayout } from 'src/components/utils/common';

const DSAVisualizerPage = () => {
  const { topic } = useParams(); // Assuming you have a route parameter named 'topic'
  const location = window.location.href;
  const encodedTopicName = location.split('dsa-visualization/')[1];
  const topicName = decodeURIComponent(encodedTopicName);
  const selectedTopic = DSATopicsDescription.find(
    (t) => t.catagory === topicName
  );
  const [completedSubtopics, setCompletedSubtopics] = useState({});
  const navigate = useNavigate();
  const [selectedSubtopic, setSelectedSubtopic] = useState(null);
  const [isCompleted, setIsCompleted] = useState(false);
  const currentProgram = useSelector((state) => state.programs.currentProgram);
  const [content, setContent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dsaVisualizerActivities, setDsaVisualizerActivities] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [expandedSubtopic, setExpandedSubtopic] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showNewLayout, setShowNewLayout] = useState(null);
  const isCardListPage =
    location.pathname === 'signature-frameworks/dsa-visualization';

  useEffect(() => {
    fetchDsaVisualizerData();
  }, [selectedTopic]);

  useEffect(() => {
    fetchContent();
  }, [isCompleted]);
  useEffect(() => {
    const newLayoutFlag = shouldShowNewStudentLayout(currentProgram.programId);
    setShowNewLayout(newLayoutFlag);
  }, [currentProgram]);

  const fetchContent = async () => {
    try {
      // Perform your API call to fetch content
      const response = await axios.get('api/dsa_visualizer');
      // Update state with fetched content
      setContent(response.data);
    } catch (error) {
      console.error('Error fetching content:', error);
    }
  };

  const scrollToElement = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const handleSubtopicClick = (subtopic) => {
    setSelectedSubtopic(subtopic);

    const subtopicElement = document.getElementById(subtopic.action);

    if (subtopicElement) {
      // Check if the element is already within the viewport
      const elementRect = subtopicElement.getBoundingClientRect();
      const isPartiallyVisible =
        (elementRect.top >= 0 && elementRect.top < window.innerHeight) ||
        (elementRect.bottom > 0 && elementRect.bottom <= window.innerHeight);

      if (isPartiallyVisible) {
        // Element is already partially visible, no need to scroll
        return;
      }

      // Efficient scrolling with options:
      subtopicElement.scrollIntoView({
        behavior: 'smooth', // Smooth scrolling animation
        block: 'start', // Align top of the element with top of the viewport
        inline: 'nearest', // Align the closest side of the element to the viewport
      });
    }
  };

  const handleMarkAsCompleted = async (index, selectedSubtopic) => {
    setLoading(true);
    try {
      // Check if a topic is selected
      if (!selectedTopic) {
        console.error('No topic selected');
        return;
      }
      // Check if the subtopic is found
      if (!selectedSubtopic) {
        console.error('Selected subtopic not found');
        return;
      }

      // Extract topic_id and subtopic_id from the selected subtopic
      const { categoryId: topic_id, subtopic_id } = selectedSubtopic;

      // Prepare the body for the API call
      const body = {
        topic_id,
        sub_topic_id: subtopic_id,
        // Add any additional data needed for marking as completed
      };

      // Sending a POST request using axios
      const response = await axios.post(
        postDsaVisualizerMarkedasCompleted(), // Endpoint URL
        body, // Request body
        {
          headers: getHeaders(currentProgram.programId), // Request headers
        }
      );

      // Check if the request was successful
      if (response) {
        setCompletedSubtopics((prev) => ({
          ...prev,
          [subtopic_id]: true, // Mark this subtopic as completed
        }));
      } else {
        console.error('Failed to mark content as completed');
      }
    } catch (error) {
      console.error('Error marking content as completed:', error);
    } finally {
      // Set loading state to false regardless of success or failure
      setLoading(false);
    }
  };

  const fetchDsaVisualizerData = async () => {
    setIsLoading(true);
    const topicId = selectedTopic?.categoryId;

    if (topicId) {
      try {
        const dsaVisualizerActivities = await axios.get(
          fetchDsaVisualizer(topicId),
          {
            headers: getHeaders(currentProgram.programId),
          }
        );
        const data = dsaVisualizerActivities?.data ?? [];
        setDsaVisualizerActivities(data);
        const updatedCompletedSubtopics = data.reduce((acc, item) => {
          acc[item.sub_topic_id] = true;
          return acc;
        }, {});
        setIsLoading(false);

        setCompletedSubtopics(updatedCompletedSubtopics);
      } catch (err) {
        // Assuming you have useState hooks for error handling
        setHasError(true);
        setErrorMessage(
          err.response?.data.message ||
            'An error occurred while fetching the DSA visualizer activities'
        );
      } finally {
        setIsLoading(false); // End loading
      }
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Smooth scrolling animation
    });
  };
  return (
    <Box
      display='flex'
      flexDirection='row-reverse'
      position='relative'
      justifyContent='flex-end'
    >
      {selectedTopic && (
        <Box
          bgcolor='white'
          width='300px'
          height={showNewLayout ? '75vh' : '100vh'}
          p={2}
          borderLeft='1px solid black'
          position='fixed'
          right={showNewLayout ? '1.5%' : '0'}
        >
          <Typography
            variant='h5'
            gutterBottom
          >
            Modules
          </Typography>
          <List>
            {DSATopicsDescription.filter(
              (subtopic) => subtopic.catagory === selectedTopic.catagory
            ).map((subtopic, index) => (
              <ListItem
                key={index}
                button
                onClick={() => handleSubtopicClick(subtopic)}
              >
                <IconButton>
                  <div
                    style={{
                      width: '14px',
                      height: '14px',
                      borderRadius: '50%',
                      background: completedSubtopics[subtopic.subtopic_id]
                        ? 'green'
                        : '#f0f0f0',
                      position: 'relative', // Set position relative to contain the tick icon
                    }}
                  >
                    {completedSubtopics[subtopic.subtopic_id] && ( // Render the tick icon conditionally
                      <TickIcon
                        style={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)', // Center the tick icon
                          fill: 'white',
                          width: '10px', // Adjust the width as needed
                          height: '10px',
                        }}
                      />
                    )}
                  </div>
                </IconButton>
                <ListItemText primary={subtopic.action} />
              </ListItem>
            ))}
          </List>
        </Box>
      )}

      <Box
        flex='1'
        p={2}
      >
        {/* Breadcrumb */}
        {!isCardListPage && (
          <Typography
            variant='body1'
            gutterBottom
          >
            {selectedTopic && (
              <>
                <Link
                  component={RouterLink}
                  to='signature-frameworks/dsa-visualization'
                  style={{
                    marginLeft: '20px',
                    marginTop: '20px',
                    textDecoration: 'none',
                  }}
                >
                  DSA Visualizer
                </Link>
                {' > '}
                {selectedTopic.action}
              </>
            )}
          </Typography>
        )}

        {selectedTopic ? (
          <div style={{ width: 'calc(100% - 300px)' }}>
            <LoadingScreen loading={isLoading} />
            <List>
              {DSATopicsDescription.filter(
                (subtopic) => subtopic.catagory === selectedTopic.catagory
              ).map((subtopic, index) => (
                <React.Fragment key={index}>
                  <ListItem>
                    <Typography
                      variant='h3'
                      id={subtopic.action}
                    >
                      {subtopic.action}
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant='body1'>
                      {subtopic.discription}
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <video
                      autoPlay
                      loop
                      muted
                      style={{
                        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 6px 16px',
                        borderRadius: '15px',
                        width: '70%',
                        margin: '20px auto',
                        marginLeft: '20px',
                        height: 'auto',
                      }}
                    >
                      <source
                        src={subtopic.gif}
                        type='video/mp4'
                      />
                      <track
                        kind='captions'
                        src='captions.vtt'
                        srcLang='en'
                        label='English'
                      />
                      Your browser does not support the video tag.
                    </video>
                  </ListItem>
                  <ListItem>
                    <Typography variant='body1'>
                      {subtopic.algorithm}
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={() => handleMarkAsCompleted(index, subtopic)}
                      style={{ marginTop: '10px', position: 'relative' }}
                      disabled={
                        completedSubtopics[subtopic.subtopic_id] || loading
                      }
                    >
                      {loading && (
                        <CircularProgress
                          size={24}
                          style={{ marginRight: '10px' }}
                        />
                      )}
                      {(() => {
                        let buttonText;
                        if (completedSubtopics[subtopic.subtopic_id]) {
                          buttonText = 'Completed';
                        } else if (loading) {
                          buttonText = null;
                        } else {
                          buttonText = 'Mark as Completed';
                        }
                        return buttonText;
                      })()}
                    </Button>
                  </ListItem>
                  {index !== DSATopicsDescription.length - 1 && (
                    <div style={{ marginBottom: '50px' }} />
                  )}
                </React.Fragment>
              ))}
            </List>
          </div>
        ) : (
          <div>
            <Typography
              variant='h2'
              component='h1'
              align='center'
              gutterBottom
              sx={{ color: '#1976D2' }}
            >
              DSA Visualizer
            </Typography>
            <Box
              display='flex'
              justifyContent='center'
            >
              <CardList />
            </Box>
          </div>
        )}
        {/* Scroll to top button */}
        <IconButton
          style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            zIndex: '1000',
            backgroundColor: '#1976D2',
            color: 'white',
          }}
          onClick={scrollToTop}
        >
          <KeyboardArrowUpIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default DSAVisualizerPage;

// @ts-nocheck
import {
  Typography,
  Stack,
  Button,
  Slider,
  Box,
  LinearProgress,
} from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import { styled } from '@mui/material/styles';
import { useFastrackPipeline } from 'src/layouts/EfPipelineContext/FastrackPipelineContex';
import { useState } from 'react';

const CustomSlider = styled(Slider)({
  color: '#1976d2',
  height: 4,
  '& .MuiSlider-track': {
    height: 4,
    borderRadius: 4,
  },
  '& .MuiSlider-rail': {
    color: '#bfbfbf',
    opacity: 1,
    height: 4,
    borderRadius: 4,
  },
  '& .MuiSlider-thumb': {
    height: 20,
    width: 20,
    backgroundColor: '#1976d2',
    border: '2px solid #fff',
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.15)',
    '&:focus, &:hover, &.Mui-active': {
      boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.2)',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#1976d2',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&::before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});

const sliderMarks = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 4,
    label: '4',
  },
];

const DailyCommitments = ({ flowData, setFlowData, saveFlowData }) => {
  const { setScreenSource, trackButtonClick } = useFastrackPipeline();

  const [dailyCommitments, setDailyCommitments] = useState(0);
  const [shouldAllowNext, setShouldAllowNext] = useState(false);

  const handleChange = (e, value) => {
    setFlowData((prev) => ({ ...prev, dailyCommitments: value }));
    setDailyCommitments(value);
    setShouldAllowNext(true);
  };

  const handleNext = () => {
    trackButtonClick({ source: 'screen_3c', eventName: 'next' });
    saveFlowData({
      screen: 'screen_3c',
      response: { dailyCommitments },
    });
    setScreenSource('screen_3d');
  };

  const currentStep = 3;
  const totalSteps = 3;

  return (
    <Stack
      spacing={3}
      alignItems='center'
      justifyContent='center'
      width={{
        xs: '100%',
        sm: '90%',
        md: '80%',
        lg: '60%',
      }}
      bgcolor='white'
      borderRadius='12px'
      padding='2rem'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 'auto',
      }}
    >
      <Typography
        component='p'
        sx={{
          color: '#1976d2',
          fontFamily: 'Inter',
          fontWeight: 'bolder',
          fontSize: {
            xs: '1.6rem',
            sm: '1.8rem',
            md: '2rem',
          },
          textAlign: 'center',
        }}
      >
        First, help us understand your schedule
      </Typography>

      <Typography
        variant='body1'
        sx={{
          fontFamily: 'Inter',
          fontSize: {
            xs: '1rem',
            sm: '1.2rem',
            md: '1.3rem',
          },
          color: '#555',
          maxWidth: '98%',
          textAlign: 'center',
        }}
      >
        We understand you have a busy schedule. Input the below-asked details,
        and we'll show you how our course can fit into your life.
      </Typography>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='center'
        width='100%'
        spacing={1}
      >
        <LinearProgress
          variant='determinate'
          value={(currentStep / totalSteps) * 100}
          sx={{
            width: '80%',
            height: 8,
            borderRadius: 5,
          }}
        />
        <Typography variant='body2'>
          {currentStep}/{totalSteps}
        </Typography>
      </Stack>
      <Box>
        <Typography
          variant='body1'
          component='p'
          sx={{
            fontFamily: 'Inter',
            fontSize: {
              xs: '1rem',
              sm: '1.2rem',
              md: '1.3rem',
            },
            width: 'fit-content',
            textAlign: 'center',
          }}
        >
          3. How much time do you spend on daily commitments?
          <Typography
            component='p'
            sx={{
              fontFamily: 'Inter',
              fontSize: {
                xs: '1rem',
                sm: '1.2rem',
                md: '1.3rem',
              },
              textAlign: 'center',
            }}
          >
            (commuting, meals, family time, etc.)
          </Typography>
        </Typography>
      </Box>
      <Stack
        width={{
          xs: '100%',
          sm: '100%',
          md: '90%',
          lg: '70%',
          xl: '50%',
        }}
        textAlign='center'
        alignItems='center'
      >
        <CustomSlider
          valueLabelDisplay='auto'
          marks={sliderMarks}
          min={0}
          max={4}
          onChangeCommitted={handleChange}
          defaultValue={0}
          sx={{ marginTop: '2rem' }}
        />
      </Stack>

      <Stack
        width='100%'
        alignItems='center'
        paddingTop='2rem'
      >
        <Button
          disableElevation
          endIcon={<EastIcon fontSize='large' />}
          variant='contained'
          sx={{
            fontFamily: 'Inter',
            textTransform: 'none',
            width: 'fit-content',
            padding: '0.5rem 2rem',
            fontSize: '1rem',
            backgroundColor: '#1976D2',
            borderRadius: '8px',
            '&:hover': {
              backgroundColor: '#1976D2',
            },
          }}
          disabled={!shouldAllowNext}
          onClick={handleNext}
        >
          Next
        </Button>
      </Stack>
    </Stack>
  );
};

export default DailyCommitments;

import React from 'react';
import { Box, TextField } from '@mui/material';
import { DateRangePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

function DateRangePickerInput({ value, setValue }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box
        display='flex'
        alignItems='center'
        sx={{ transform: 'scale(0.9)' }}
        gap={2}
      >
        <DateRangePicker
          startText='From'
          endText='To'
          value={value}
          onChange={(newValue) => setValue(newValue)}
          renderInput={(startProps, endProps) => (
            <>
              <TextField {...startProps} />
              <Box sx={{ mx: 2, fontSize: '0.9rem' }}> to </Box>
              <TextField {...endProps} />
            </>
          )}
        />
      </Box>
    </LocalizationProvider>
  );
}

export default DateRangePickerInput;

// @ts-nocheck
import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';

const FailureScreen = () => {
  const theme = useTheme();
  const isBelowTab = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Stack
      spacing={3}
      alignItems='center'
      justifyContent='center'
      sx={{
        '@media (min-width: 400px) and (max-width: 600px)': {
          marginTop: '3.5rem',
        },
        backgroundColor: 'white',
        borderRadius: '8px',
        height: 'fit-content',
        width: '60%',
        padding: '3rem',
        '@media (min-width: 700px) and (max-width: 800px)': {
          width: '80vw',
        },
        '@media (min-width: 800px) and (max-width: 1200px)': {
          width: '70vw',
        },
        '@media (max-width: 700px)': {
          width: '90vw',
          padding: '2.5rem',
        },
        '@media (max-width: 350px)': {
          width: '95vw',
        },
      }}
    >
      <Typography
        variant='h2'
        component='h2'
        sx={{
          textAlign: 'center',
          textWrap: 'balance',
          fontFamily: 'Inter',
          color: '#1976d2',
          fontSize: '2rem',
          [theme.breakpoints.down('sm')]: {
            fontSize: '1.5rem',
          },
        }}
      >
        Sorry! We could not evaluate your application
      </Typography>

      <Stack spacing={2}>
        <Typography
          variant='h4'
          component='h4'
          sx={{
            textAlign: 'center',
            textWrap: 'stable',
            fontFamily: 'Inter',
            fontWeight: 500,
            fontSize: '1rem',
            [theme.breakpoints.down('sm')]: {
              fontSize: '0.9rem',
            },
          }}
        >
          Based on your responses to the fastrack screening test, we could not
          make a decision to select or reject your application.
        </Typography>

        <Typography
          variant='h4'
          component='h4'
          sx={{
            textAlign: 'center',
            textWrap: 'stable',
            fontFamily: 'Inter',
            fontWeight: 500,
            fontSize: '1rem',
            [theme.breakpoints.down('sm')]: {
              fontSize: '0.9rem',
            },
          }}
        >
          Your application is still open but will now be reviewed manually by
          one of our career experts and you will be required to attend an online
          screening interview with the designated career expert.
        </Typography>

        <Typography
          variant='h4'
          component='h4'
          sx={{
            textAlign: 'center',
            textWrap: 'stable',
            fontFamily: 'Inter',
            fontWeight: 500,
            fontSize: '1rem',
            [theme.breakpoints.down('sm')]: {
              fontSize: '0.9rem',
            },
          }}
        >
          Please get in touch with your career counsellor to understand the
          details of the screening interview and request for the earliest
          available slot.
        </Typography>

        <Typography
          variant='h4'
          component='h4'
          sx={{
            textAlign: 'center',
            textWrap: 'stable',
            fontFamily: 'Inter',
            fontWeight: 500,
            fontSize: '1rem',
            [theme.breakpoints.down('sm')]: {
              fontSize: '0.9rem',
            },
          }}
        >
          All the best! We look forward to seeing you grow with HeyCoach!
        </Typography>
      </Stack>
    </Stack>
  );
};

export default FailureScreen;

import React, { useState } from 'react';
import { Box, Button, Tabs, Tab, Typography } from '@material-ui/core';
import LoadingButton from '@mui/lab/LoadingButton';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import CustomTabPanel from '../CustomTabPanel';
import TopicTitle from '../TopicTitle';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import ArraySeven from '../../../../images/ArraySeven.png';
import ArrayEight from '../../../../images/ArrayEight.png';
import ArrayGif from '../../../../images/ArrayGif.gif';
import grid from '../../../../images/grid.png';
import GridTwo from '../../../../images/GridTwo.png';
import GridThree from '../../../../images/GridThree.png';
import GridFour from '../../../../images/GridFour.png';
import GridFive from '../../../../images/GridFive.png';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Book = ({
  handlePostCompletedTopic,
  hasMarkedCompleted,
  loading,
  topic_id,
  handleNext,
  handlePrevious,
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap='36px'
      paddingY={2}
      maxWidth='calc(100% - 300px)'
    >
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
      >
        <TopicTitle>2. Book</TopicTitle>
        {hasMarkedCompleted(topic_id) && (
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              color: 'green',
              fontWeight: '500',
              border: '2px solid green',
              padding: '4px 10px',
              borderRadius: '20px',
              cursor: 'default',
            }}
          >
            Completed
          </Typography>
        )}
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        gap='24px'
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            width: '32rem',
            background: '#FEF7FF',
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
          >
            <Tab
              label='Problem Statement'
              {...a11yProps(0)}
            />
            <Tab
              label='Brute Force Approach'
              {...a11yProps(1)}
            />
            <Tab
              label='Two Pointer Approach'
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <CustomTabPanel
          value={value}
          index={0}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Typography></Typography>

            <Box
              display='flex'
              flexDirection='column'
              gap='8px'
            >
              <Typography>
                When Valera has got some free time, he goes to the library to
                read some books. Today he's got ‘t’ free minutes to read. That's
                why Valera took ‘n’ books in the library and for each book he
                estimated the time he is going to need to read it. Let's number
                the books by integers from 1 to n. Valera needs a[i] minutes to
                read the i-th book.
              </Typography>

              <Typography>
                Valera decided to choose an arbitrary book with number i and
                read the books one by one, starting from this book. In other
                words, he will first read book number i, then book number i + 1,
                then book number i + 2 and so on. He continues the process until
                he either runs out of the free time or finishes reading the n-th
                book. Valera reads each book up to the end, that is, he doesn't
                start reading the book if he doesn't have enough free time to
                finish reading it.
              </Typography>
              <Typography>
                Print the maximum number of books Valera can read.
              </Typography>
            </Box>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={1}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Typography>
              Since we effectively have to find the largest segment whose sum is
              less than or equal to the given &lsquo;t&rsquo;, we can list out
              all the subarrays which have the sum &le; t and figure out which
              is the maximum out of them.
            </Typography>

            <Typography>
              So, for each index we can start counting the time it takes to read
              that particular book and move ahead if we still have time left, at
              any point if we do not have enough time to read the next book,
              then we will keep a track of the number of books we managed to
              read. After that move on to the next index and do the same.
            </Typography>

            <Typography>
              There are two nested loops. The outer loop iterates through each
              element of the array with index i. The inner loop starts from the
              element next to i (index j = i) and goes until the end of the
              array. This ensures that every sub array is checked and the
              maximum size of subarray is stored.
            </Typography>

            <Box
              sx={{
                backgroundColor: 'black',
                padding: '24px',
                borderRadius: '10px',
                width: '900px',
              }}
            >
              <pre style={{ color: 'white', margin: 0 }}>
                {`funct solve(books, n, t):
    ans = 0

    for i from 0 to n-1:
        len = 0
        sum = 0

        for j from i to n-1:
            if sum + books[j] <= t:
                sum = sum + books[j]
                len = len + 1
            else:
                break

            if len > ans:
                ans = len

    return ans

`}
              </pre>
            </Box>

            <Typography>
              Since we are n iterations in the outer loop and inner loop we will
              be trying to explore all the subarrays starting from that
              particular index, the time complexity will n^2 and no space is
              being used by this algorithm.
            </Typography>

            <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>
              Time and Space Complexity
            </Typography>

            <Typography>Time Complexity : O(n^2)</Typography>

            <Typography>Space Complexity : O(1)</Typography>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={2}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Typography>
              We want to find the longest contiguous segment of books that can
              be read within t minutes.
            </Typography>

            <Typography>
              To accomplish this, we can define left and right to represent the
              beginning and end of the segment. Both will start at the beginning
              of the array. These numbers can be thought of as pointers, hence
              the name "two pointers."
            </Typography>

            <Typography>
              For every value of left in increasing order, let's increase right
              until the total time for the segment is maximized while being less
              than or equal to t.
            </Typography>

            <Typography>
              ans will store the maximum value of right−left+1 (segment size)
              that we have encountered so far.
            </Typography>

            <Typography>
              After incrementing left by one, the time used decreases, hence the
              right pointer never has to move leftwards. Thus:
            </Typography>

            <Typography>
              Since both pointers will move at most N times, the overall time
              complexity is O(N).
            </Typography>

            <Typography>
              Consider the input value of books : {(3, 1, 2, 1)} and the time is
              4. The red mark represents the position of left pointer and right
              mark represents the place of right pointer.
            </Typography>

            <img
              src={grid}
              alt=''
              style={{ height: '250px', width: '400px', text: 'center' }}
            />

            <Typography>
              Since still the current sum is less than the total time that we
              have (3&lt;4), we can move the right ptr ahead.
            </Typography>

            <img
              src={GridTwo}
              alt=''
              style={{ height: '250px', width: '400px', text: 'center' }}
            />

            <Typography>
              Now since we have reached the total time available to us which is
              4 in this case we cannot move the right ptr, so we will move the
              left ptr ahead.
            </Typography>

            <img
              src={GridThree}
              alt=''
              style={{ height: '250px', width: '400px', text: 'center' }}
            />

            <Typography>
              Again we are now eligible to move the right ptr because (1&lt;4).
            </Typography>

            <img
              src={GridFour}
              alt=''
              style={{ height: '250px', width: '400px', text: 'center' }}
            />

            <Typography>
              Since still the current sum is less than the total time that we
              have (3&lt;4) we can move the right ptr ahead.
            </Typography>

            <Typography>
              As soon as our right ptr reaches the end of the array, that means
              we have reached the max-length because now moving the left ptr is
              only going to be reducing the size of the subarray that we are
              going to be looking at.
            </Typography>

            <img
              src={GridFive}
              alt=''
              style={{ height: '250px', width: '400px', text: 'center' }}
            />

            <Typography>
              As soon as our right ptr reaches the end of the array, that means
              we have reached the max-length because now moving the left ptr is
              only going to be reducing the size of the subarray that we are
              going to be looking at.
            </Typography>

            <Typography>Code</Typography>

            <Box
              sx={{
                backgroundColor: 'black',
                padding: '24px',
                borderRadius: '10px',
                width: '900px',
              }}
            >
              <pre style={{ color: 'white', margin: 0 }}>
                {`function solve(books, n, t):
    ans = 0
    left = 0
    right = 0
    cur = 0

    while left < n and right < n:
        // Find the maximum 'right' for which 'cur' is less than or equal to 't'.
        while right < n:
            cur = cur + books[right]
            right = right + 1
        // If the current sum exceeds 't', adjust by moving 'right' back one step and subtracting the last book.
            if cur > t:
                right = right - 1
                cur = cur - books[right]
                break

        // Update the maximum number of books we can read.
        ans = max(ans, right - left)
        
        // Move 'left' pointer one step to the right and adjust the current sum.
        cur = cur - books[left]
        left = left + 1

    return ans
`}
              </pre>
            </Box>
            <Typography>
              The outer loop will run at most n times as the left pointer
              progresses from 0 to n-1. Each iteration of the outer loop
              processes the inner loop where the right pointer traverses the
              array once in total. Therefore, the combined time complexity of
              the nested loops is O(n). Since no extra space is being used,
              space complexity is O(1).
            </Typography>

            <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>
              Time and Space Complexity
            </Typography>

            <Typography>Time Complexity : O(n)</Typography>
            <Typography>Space Complexity : O(1)</Typography>
          </Box>
        </CustomTabPanel>
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        alignSelf='end'
        justifyContent='space-between'
        gap='12px'
      >
        {!hasMarkedCompleted(topic_id) && (
          <LoadingButton
            variant='contained'
            onClick={() => handlePostCompletedTopic(topic_id)}
            loading={loading}
            loadingPosition='center'
            children='Mark as completed'
            style={{
              width: '170px',
              // backgroundColor: 'transparent',
              borderRadius: '8px',
              border: '1px solid rgba(64, 96, 245, 0.5)',
            }}
          />
        )}

        <Box
          display='flex'
          alignItems='center'
          gap='8px'
        >
          <Button
            sx={{ gap: '4px' }}
            onClick={handlePrevious}
          >
            <WestIcon /> Previous
          </Button>

          <Button
            sx={{ gap: '4px' }}
            onClick={handleNext}
          >
            Next <EastIcon />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Book;

import {
  Card,
  CardContent,
  Typography,
  Box,
  Button,
  Tooltip,
  IconButton,
  Chip,
} from '@material-ui/core';
import { Icon } from '@iconify/react';
import CloseOutlined from '@iconify/icons-ant-design/close-outlined';
import EyeOutlined from '@iconify/icons-ant-design/eye-outlined';
import { makeStyles } from '@material-ui/styles';
import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { AssignmentContext } from '../../../configs/AssignmentContext';
import ModalAssignment from './ModalAssignment';
import UploadDocumentsModal from './UploadDocumentsModal';

const styles = makeStyles({
  card: {
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      transition: 'all 0.2s ease-in-out',
      transform: 'scale(1.05)',
      cursor: 'pointer'
    }
  }
});
const modalStyle = {
  position: 'absolute',
  top: '35%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minHeight: 200,
  width: 600,
  borderRadius: 6,
  bgcolor: 'background.paper',
  background: 'white',
  boxShadow: 24,
  padding: 32
};

const AssignmentCardStudent = ({ index }) => {
  const [assignments, setAssignments] = useContext(AssignmentContext);
  const [openUploadDocumentsModal, setOpenUploadDocumentsModal] = useState(false);
  const [openModalAssignment, setOpenModalAssignment] = useState(false);
  const [topicName, setTopicName] = useState('');
  const [topics, setTopics] = useState({});
  const storedTopics = useSelector(state => state.topics.topics);

  useEffect(() => {
    storedTopics.forEach((val) => {
      topics[val.id] = val.name;
    });
    setTopics(topics);
    setTopicName(topics[assignments[index].topic_id]);
  }, [topics, assignments, index]);

  const classes = styles();

  return (
    <>
      <Card className={classes.card}>
        <CardContent>
          <Typography
            sx={{ float: 'right' }}
            component="p"
            variant="subtitle2"
            color="textSecondary"
            gutterBottom
          >
            {assignments && assignments[index].created_at
              ? format(new Date(assignments[index].created_at), 'do MMMM yyyy')
              : ''}
          </Typography>
          <Typography variant="h5" component="h2">
            {assignments[index].title}
          </Typography>
          <Typography color="textSecondary" component="h5">
            {topicName}
          </Typography>
          <div
            style={{
              width: '70%',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Box>
              <div style={{ wordWrap: 'break-word' }}>
                {assignments[index].description &&
                  String(assignments[index].description)
                    .split('\n')
                    .map((val, i) => {
                      return (
                        i < 2 && (
                          <Typography variant="p" component="p">
                            {val}
                          </Typography>
                        )
                      );
                    })}
              </div>
            </Box>
          </div>
          <Box marginTop="10px" display="flex" justifyContent="space-between">
            <Tooltip title="Deadline" aria-label="deadline">
              <Chip
                label={
                  assignments && assignments[index].deadline
                    ? format(
                      new Date(assignments[index].deadline),
                      'do MMMM yyyy'
                    )
                    : ''
                }
                size="small"
                variant="outlined"
                color="error"
              />
            </Tooltip>
            <div>
              <Tooltip title="Expand" aria-label="edit">
                <Button
                  onClick={() => setOpenModalAssignment(true)}
                  sx={{ minWidth: '0px' }}
                  variant="contained"
                  color="secondary"
                >
                  <Icon
                    style={{
                      float: 'right',
                      cursor: 'pointer',
                      // padding: '4px',
                      alignSelf: 'flex-end'
                    }}
                    icon={EyeOutlined}
                    width="18"
                    height="18"
                  />
                </Button>
              </Tooltip>
              <Tooltip sx={{ marginLeft: '1rem' }} title="Edit" aria-label="edit">
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={() => {
                    setOpenUploadDocumentsModal(!openUploadDocumentsModal);
                  }}
                >
                  Mark As Complete
                </Button>
              </Tooltip>
            </div>
          </Box>
        </CardContent>
      </Card>
      {openModalAssignment && (
        <ModalAssignment isOpen={openModalAssignment}>
          <Box style={modalStyle}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="baseline"
            >
              <Typography variant="p" fontSize={28} mb={2}>
                {assignments[index].title}
              </Typography>
              <IconButton
                sx={{
                  width: '18',
                  height: '18'
                }}
              >
                <Icon onClick={() => setOpenModalAssignment(false)} icon={CloseOutlined} />
              </IconButton>
            </Box>
            <Box sx={{ paddingLeft: '10px' }}>
              <Typography
                sx={{ marginBottom: '0.6rem' }}
                color="textSecondary"
                variant="h4"
                component="h4"
              >
                {topicName}
              </Typography>
              {assignments[index].description &&
                String(assignments[index].description)
                  .split('\n')
                  .map((val, i) => {
                    return (
                      <Typography key={i} variant="p" component="p">
                        {val}
                      </Typography>
                    );
                  })}
            </Box>
          </Box>
        </ModalAssignment>
      )}
      {openUploadDocumentsModal && (
        <UploadDocumentsModal
          open={openUploadDocumentsModal}
          setOpen={setOpenUploadDocumentsModal}
          index={index}
        />
      )}
    </>
  );
};

export default AssignmentCardStudent;

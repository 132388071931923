import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Typography,
  Box,
  Grid,
} from '@mui/material';
import amazon_voucher from '../../../src/assets/amazon_voucher.png';
import { getHeaders, referralURL } from 'src/apis/urls';
import axios from 'axios';
import SuccessModal from './SuccessModal';
const ReferalModal = ({ open, onClose, currentProgram }) => {
  const [referrals, setReferrals] = useState(['', '', '', '']);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleReferralChange = (index, value) => {
    const newReferrals = [...referrals];
    newReferrals[index] = value;
    setReferrals(newReferrals);
  };

  const isSubmitDisabled = () => {
    // Check if first referral is complete
    const isFirstReferralComplete =
      referrals[0].trim() !== '' && referrals[1].trim().length === 10;
    // Check if second referral is partially filled
    const isSecondReferralPartial =
      referrals[2].trim() !== '' && referrals[3].trim().length !== 10;
    return !isFirstReferralComplete || isSecondReferralPartial;
  };

  const handleSubmit = () => {
    setIsSubmitting(true);

    const body = {
      referral: {
        response: JSON.stringify([
          {
            name: referrals[0],
            phone: referrals[1],
          },
          {
            name: referrals[2],
            phone: referrals[3],
          },
        ]),
      },
    };

    axios
      .post(referralURL(), body, {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((response) => {
        console.log('Referral submitted successfully:', response.data);
        setIsSubmitting(false);
        onClose();
        setShowSuccessModal(true);
      })
      .catch((error) => {
        console.error('Error submitting referral:', error);
      });
  };

  const handleSuccessModalClose = () => {
    setShowSuccessModal(false);
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={() => {}}
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle sx={{ paddingTop: '24px' }}>
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            width='100%'
            height='200px'
            overflow='hidden'
            borderRadius='8px'
            bgcolor='#f0f8ff'
          >
            <img
              src={amazon_voucher}
              alt='Rs. 5000 Amazon.com Gift Card'
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
                objectFit: 'contain',
              }}
            />
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              textAlign: 'left',
              maxWidth: '600px',
              margin: 'auto',
            }}
          >
            <Typography
              variant='h4'
              gutterBottom
              sx={{
                fontWeight: 'bold',
                mb: 2,
                letterSpacing: '0.01em',
              }}
            >
              Yay! You are eligible for an Amazon voucher
            </Typography>
            <Typography
              variant='body1'
              paragraph
              sx={{
                mt: 2,
              }}
            >
              Refer a friend and get a ₹5000 Amazon voucher when they enroll.
            </Typography>

            <Typography
              variant='body2'
              paragraph
              sx={{
                fontWeight: 'bold',
                color: 'black',
                fontSize: '1rem',
              }}
            >
              Hush-hush! Your referral bonus is a secret between us. 😉
            </Typography>
          </Box>
          <Box>
            <Typography
              variant='subtitle1'
              gutterBottom
            >
              Referral 1
            </Typography>
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={6}
              >
                <TextField
                  fullWidth
                  label='Enter Full Name'
                  variant='outlined'
                  value={referrals[0]}
                  onChange={(e) => handleReferralChange(0, e.target.value)}
                  margin='dense'
                  required
                />
              </Grid>
              <Grid
                item
                xs={6}
              >
                <TextField
                  fullWidth
                  label='Enter Phone Number'
                  variant='outlined'
                  value={referrals[1]}
                  onChange={(e) => handleReferralChange(1, e.target.value)}
                  margin='dense'
                  required
                  type='number'
                  inputProps={{
                    pattern: '[0-9]*',
                    inputMode: 'numeric',
                    maxLength: 10,
                  }}
                  helperText='Enter a 10-digit phone number'
                />
              </Grid>
            </Grid>
          </Box>
          <Box>
            <Typography
              variant='subtitle1'
              gutterBottom
            >
              Referral 2
            </Typography>
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={6}
              >
                <TextField
                  fullWidth
                  label='Enter Full Name'
                  variant='outlined'
                  value={referrals[2]}
                  onChange={(e) => handleReferralChange(2, e.target.value)}
                  margin='dense'
                />
              </Grid>
              <Grid
                item
                xs={6}
              >
                <TextField
                  fullWidth
                  label='Enter Phone Number'
                  variant='outlined'
                  value={referrals[3]}
                  onChange={(e) => handleReferralChange(3, e.target.value)}
                  margin='dense'
                  type='number'
                  inputProps={{
                    pattern: '[0-9]*',
                    inputMode: 'numeric',
                    maxLength: 10,
                  }}
                  helperText='Enter a 10-digit phone number'
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            paddingRight: '24px',
            paddingBottom: '24px',
          }}
        >
          <Button
            onClick={onClose}
            color='primary'
            sx={{
              mx: 1.25,
            }}
          >
            Maybe later
          </Button>
          <Button
            variant='contained'
            color='primary'
            disabled={isSubmitDisabled() || isSubmitting}
            onClick={handleSubmit}
          >
            {isSubmitting ? 'Submitting...' : 'Submit'}
          </Button>
        </DialogActions>
      </Dialog>
      <SuccessModal
        open={showSuccessModal}
        onClose={handleSuccessModalClose}
      />
    </>
  );
};

export default ReferalModal;

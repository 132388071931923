import PersonIcon from '@mui/icons-material/Person';
import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import ProfileForm from './ProfileForm';
import Calendly from './Calendly';

const generateExperinceRangeOptions = (length) => {
  return Array.from({ length }, (_, i) => {
    const start = i;
    const end = i + 1;
    return i === length - 1 ? `${end}+` : `${start}-${end}`;
  });
};

export const accordionData = [
  {
    icon: PersonIcon,
    title: 'Profile Details',
    content: <ProfileForm />,
  },
  {
    icon: AddIcCallIcon,
    title: 'Call with Admission',
    content: <Calendly />,
  },
];

export const formFields = [
  { label: 'Name', name: 'name', type: 'text' },
  {
    label: 'Registered Email',
    name: 'email',
    type: 'email',
  },
  {
    label: 'Mobile Number',
    name: 'phone',
    type: 'text',
  },
  {
    label: 'Gender',
    name: 'gender',
    type: 'select',
    required: true,
    options: ['Male', 'Female', 'Others'],
  },
  {
    label: 'Year of Birth',
    name: 'birthYear',
    type: 'select',
    required: true,
    options: Array.from({ length: 50 }, (_, i) => new Date().getFullYear() - i),
  },
  {
    label: 'Degree',
    name: 'degree',
    type: 'text',
    placeholder: 'Enter Here',
    required: true,
  },
  {
    label: 'Graduation Year',
    name: 'graduationYear',
    type: 'select',
    required: true,
    options: Array.from({ length: 50 }, (_, i) => new Date().getFullYear() - i),
  },
  {
    label: 'Post Graduation Degree(if applicable)',
    name: 'postGraduationDegree',
    type: 'text',
    placeholder: 'Enter Here',
  },
  {
    label: 'Post Graduation Year(if applicable)',
    name: 'postGraduationYear',
    type: 'select',
    options: Array.from({ length: 50 }, (_, i) => new Date().getFullYear() - i),
  },
  {
    label: 'Tech Stack/Programming Language',
    name: 'techStack',
    type: 'text',
    required: true,
    placeholder: 'Enter Here',
  },
  {
    label: 'Total Work Experience (In years)',
    name: 'workExperience',
    type: 'select',
    required: true,
    options: generateExperinceRangeOptions(10),
  },
  {
    label: 'Current Organization',
    name: 'currentOrganization',
    type: 'text',
    required: true,
    placeholder: 'Enter Here',
  },
  {
    label: 'Current Profile/Title (e.g, Software Engineer)',
    name: 'currentProfile',
    type: 'text',
    required: true,
    placeholder: 'Enter Here',
  },
  {
    label: 'Current CTC',
    name: 'currentCTC',
    type: 'text',
    required: true,
    placeholder: 'Enter Here',
  },
  {
    label: 'Current Location',
    name: 'currentLocation',
    type: 'text',
    required: true,
    placeholder: 'Enter Here',
  },
  {
    label: 'Notice Period (In days)',
    name: 'noticePeriod',
    type: 'text',
    required: true,
    placeholder: 'Enter Here',
  },
  {
    label: 'LinkedIn',
    name: 'linkedIn',
    type: 'text',
    required: true,
    placeholder: 'Enter Link Here',
  },
  {
    label: 'Leetcode Username',
    name: 'leetcodeUsername',
    type: 'text',
    required: true,
    placeholder: 'Enter Username Here',
  },
];

import { Stack } from '@material-ui/core';
import React, { useState } from 'react';
import ErrorNotifier from '../../../ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../../ToastNotifications/SuccessNotifier';
import NewLevelTestButton from './NewLevelTestButton';

const LevelTests = (props) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [hasError, setHasError] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  const setToastNotification = (type, message) => {
    if (type === 'error') {
      setHasError(true);
      setErrorMessage(message);
    } else {
      setIsSuccess(true);
      setSuccessMessage(message);
    }
  };

  return (
    <Stack direction='column' margin={2}>
      {hasError && (
        <ErrorNotifier
          {...{
            message: errorMessage,
            setHasError,
          }}
        />
      )}
      {isSuccess && (
        <SuccessNotifier
          {...{
            message: successMessage,
            setIsSuccess,
          }}
        />
      )}
      <Stack direction='row' justifyContent='flex-end'>
        <NewLevelTestButton setToastNotification={setToastNotification} />
      </Stack>
    </Stack>
  );
};

export default LevelTests;

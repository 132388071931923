import React from 'react';
import { Modal, Typography, Box, Button } from '@mui/material';
import { useState } from 'react';
import OnboardingStepper from './OnboardingStepper';
import { bold } from '@uiw/react-md-editor';

const OnboardingPopup = () => {
  const [open, setOpen] = useState(true);
  const handleClose = () => setOpen(false);

  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '62vw',
          height: 'auto',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: '20px',
        }}
      >
        <Typography
          fontSize='40px'
          fontWeight='bold'
          color='rgb(25, 118, 210)'
          padding='1%'
        >
          Welcome to HeyCoach Learning Platform
        </Typography>
        <Typography
          fontSize='22px'
          fontWeight='bold'
          padding='1%'
        >
          Get started with DSA & System Design Program
        </Typography>
        <Typography
          className='popupContent'
          fontSize='18px'
          padding='1%'
        >
          We are so excited to have vou here! <br />
          Over the next few months, we want to put you in the hot seat and make
          this journey your own! You've successfully unlocked our signature
          frameworks: The Great Pyramid, Mindmap, and DSA Visualizer. Next,
          let's complete a few quick steps to unlock full access to the program.
        </Typography>
        <OnboardingStepper />
        <Box
          sx={{
            marginTop: '20px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button
            onClick={handleClose}
            variant='contained'
            sx={{
              padding: '12px 24px',
              fontSize: '18px',
              borderRadius: '8px',
            }}
          >
            Get Started
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default OnboardingPopup;

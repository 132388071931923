// @ts-nocheck
import {
  Box,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import trophyLogoImage from '../../assets/screening-test-trophy-logo.png';
import React, { useEffect, useState } from 'react';
import FakeResponseAnalyzer from './FakeResponseAnalyzer';
import { useEfPipeline } from 'src/layouts/EfPipelineContext/EfPipelineContext';

const SuccessScreen = ({ token = 'JMOZSI' }) => {
  const [copied, setCopied] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { showFakeResponseAnalyzerForScreeningTest } = useEfPipeline();

  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 600);
    }

    return () => {};
  }, [copied]);

  const copyToken = () => {
    navigator.clipboard.writeText(token);
    setCopied(true);
  };

  if (showFakeResponseAnalyzerForScreeningTest) {
    return (
      <Box
        alignSelf='center'
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: 'inherit',
          alignItems: 'flex-start',
          paddingTop: '6rem',
          paddingBottom: '2rem',
          paddingLeft: '2rem',
          paddingRight: '2rem',
          rowGap: '2rem',
          overflowY: 'auto',
          overflowX: 'hidden',
          '@media (max-width: 500px)': {
            paddingTop: '1.5rem',
          },
          '@media (min-height: 700px)': {
            paddingTop: '3rem',
          },
        }}
      >
        <FakeResponseAnalyzer />
      </Box>
    );
  }

  return (
    <Stack
      spacing={isMobile ? 2 : 3}
      alignItems='center'
      justifyContent='center'
      sx={{
        mt: 'clamp(0, 1.5rem, 3rem)',
        backgroundColor: 'white',
        borderRadius: '8px',
        height: 'fit-content',
        width: '60%',
        padding: '3rem',
        '@media (min-width: 700px) and (max-width: 800px)': {
          width: '80vw',
        },
        '@media (max-width: 700px)': {
          width: '90vw',
          paddingLeft: '1rem',
          paddingRight: '1rem',
        },
        '@media (max-width: 350px)': {
          width: '95vw',
        },
      }}
    >
      <Stack
        direction='row'
        spacing={1}
      >
        <Box
          sx={{
            fontSize: '2em',
            [theme.breakpoints.down('md')]: {
              fontSize: '1.7em',
            },
            paddingLeft: '8px',
            paddingRight: '8px',
            '@media (min-width: 1670px)': {
              alignSelf: 'center',
            },
          }}
        >
          🌟
        </Box>

        <Typography
          variant='h2'
          component='h2'
          sx={{
            color: '#1976d2',
            textAlign: 'center',
            fontFamily: 'Inter',
            fontSize: '2.3rem',
            [theme.breakpoints.between('md', 'lg')]: {
              fontSize: '2.3rem',
            },
            [theme.breakpoints.down('md')]: {
              fontSize: '2rem',
            },
            [theme.breakpoints.down('sm')]: {
              fontSize: '1.7rem',
            },
            width: 'fit-content',
          }}
        >
          Congratulations, you've passed!
        </Typography>
        <Box
          sx={{
            fontSize: '2em',
            [theme.breakpoints.down('md')]: {
              fontSize: '1.7em',
            },
            paddingRight: '8px',
            '@media (min-width: 1670px)': {
              alignSelf: 'center',
            },
          }}
        >
          🌟
        </Box>
      </Stack>

      <Box>
        <img
          style={{
            height: !isMobile ? '100px' : '70px',
          }}
          src={trophyLogoImage}
          alt='trophy-logo'
        />
      </Box>

      <Typography
        variant='h5'
        component='h5'
        sx={{
          textAlign: 'center',
          textWrap: 'balance',
          fontFamily: 'Inter',
          fontWeight: 500,
        }}
      >
        Your dedication shines through, and you're ready for the challenges
        ahead.
      </Typography>

      <Typography
        variant='h5'
        component='h5'
        sx={{
          textAlign: 'center',
          textWrap: 'pretty',
          fontFamily: 'Inter',
          fontWeight: 500,
        }}
      >
        As a token of your achievement, here's your exclusive code to fastrack
        your process:{' '}
      </Typography>

      <Typography
        variant='h2'
        component='h2'
        sx={{
          textAlign: 'center',
          textWrap: 'pretty',
          fontFamily: 'Inter',
          color: '#1976d2',
        }}
      >
        <Tooltip
          title={copied ? 'Copied' : 'Click to copy'}
          onClick={copyToken}
        >
          <span
            style={{
              cursor: 'pointer',
            }}
          >
            {token}
          </span>
        </Tooltip>
      </Typography>

      <Typography
        variant='h5'
        component='h5'
        sx={{
          textAlign: 'center',
          fontFamily: 'Inter',
          fontWeight: 400,
        }}
      >
        Remember to mention this code to your counselor.
      </Typography>
    </Stack>
  );
};

export default SuccessScreen;

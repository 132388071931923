import React from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { deleteEnrollmentUrl, getHeaders } from '../../../apis/urls';

export default function DeleteEnrollment({
  enrollments,
  setEnrollments,
  id,
  setHasError,
  setErrorMessage,
  setIsSuccess,
  setSuccessMessage,
}) {
  const currentProgram = useSelector(
    (state) => state?.programs?.currentProgram
  );

  const handleConfirmDelete = () => {
    axios
      .delete(deleteEnrollmentUrl(id), {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((res) => {
        setIsSuccess(true);
        setSuccessMessage(
          res?.data?.message || 'Enrollment deleted successfully.'
        );
        const updatedEnrollments = { ...enrollments };
        if (updatedEnrollments[id]) {
          delete updatedEnrollments[id];
          setEnrollments(updatedEnrollments);
        }
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(err.response?.data?.message || err.message);
      });
  };

  return (
    <>
      <Button
        onClick={() => {
          const result = window.confirm(
            'This will be a permanent change and cannot be undone. Are you sure you want to delete this enrollment?'
          );
          if (result) {
            handleConfirmDelete();
          }
        }}
        variant='outlined'
        color='error'
      >
        delete
      </Button>
    </>
  );
}

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
//
import axios from 'axios';
import DashboardNavbar from '../shared/DashboardNavbar';
import {
  setSessions,
  unsetSessions,
} from '../../components/redux-store/slices/sessionSlice';
import {
  setPrograms,
  unsetCurrentProgram,
} from '../../components/redux-store/slices/programSlice';
import { AssignmentProvider } from '../../configs/AssignmentContext';
import {
  fetchProgramSessionsUrl,
  fetchProgramsUrl,
  getHeaders,
} from '../../apis/urls';
import { getUserType } from '../../configs/auth';
import SidebarSelection from './SidebarSelection';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const dispatch = useDispatch();
  const userType = getUserType();
  const currentProgram = useSelector((state) => state.programs.currentProgram);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!currentProgram) {
      return;
    }

    if (userType === 'finance' || userType === 'problem_setter') {
      return;
    }

    if (
      (userType === 'student' || userType === 'coach') &&
      !currentProgram?.hasProgramStarted
    ) {
      return;
    }
    fetchSessions();
    return () => {};
  }, [currentProgram]);

  useEffect(() => {
    if (userType === 'problem_setter') {
      return;
    }
    getPrograms();
    return () => {};
  }, []);

  const fetchSessions = () => {
    axios
      .get(fetchProgramSessionsUrl(), {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((res) => {
        dispatch(setSessions({ sessions: res?.data ?? [] }));
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(err.response?.data?.message || err.message);
      });
  };

  const getPrograms = () => {
    axios
      .get(fetchProgramsUrl(), {
        headers: getHeaders(),
      })
      .then((res) => {
        dispatch(setPrograms(res.data ?? []));
      })
      .catch((e) => {
        console.log(e);
        setHasError(e);
        setErrorMessage(
          e.response?.data?.message || 'Could not fetch program details'
        );
      });
  };

  return (
    <AssignmentProvider>
      <RootStyle>
        <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
        <SidebarSelection
          open={open}
          setOpen={setOpen}
        />
        <MainStyle>
          <Outlet />
        </MainStyle>
      </RootStyle>
    </AssignmentProvider>
  );
}

// @ts-nocheck
import React, { useState } from 'react';
import axios from 'axios';
import { Button, Box } from '@mui/material';
import { getHeaders, sendMessageProgramsUrl } from '../../../apis/urls';
import SendMessageConfirmation from './SendMessageConfirmation';
import MultiProgramSelectionForBroadcastMessage from './MultiProgramSelectionForBroadcastMessage';

export default function BroadcastMessageToPrograms({
  programs,
  message,
  setHasError,
  setErrorMessage,
  setIsSuccess,
  setSuccessMessage,
  handleClose,
  loading,
  notifyByEmail,
}) {
  const [selectedPrograms, setSelectedPrograms] = useState([]);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  const handleSendMessage = () => {
    const body = {
      message,
      program_ids: selectedPrograms,
      notify_by_email: notifyByEmail,
    };
    axios
      .post(sendMessageProgramsUrl(), body, {
        headers: getHeaders(),
      })
      .then((res) => {
        setIsSuccess(true);
        setSuccessMessage('Message successfully sent to learners');
      })
      .catch((e) => {
        console.log(e);
        setHasError(true);
        setErrorMessage(
          e.response?.data?.message ||
            'An error occurred while broadcasting the message.'
        );
      });
    handleClose();
  };

  return (
    <>
      <MultiProgramSelectionForBroadcastMessage
        programs={programs}
        selectedPrograms={selectedPrograms}
        setSelectedPrograms={setSelectedPrograms}
        loading={loading}
      />
      <Box
        sx={{
          textAlign: 'center',
          mt: 2,
        }}
      >
        <Button
          disabled={!message || selectedPrograms.length === 0}
          variant='contained'
          onClick={() => setOpenConfirmationDialog(true)}
        >
          Send as DM
        </Button>
      </Box>

      {openConfirmationDialog && (
        <SendMessageConfirmation
          openConfirmationDialog={openConfirmationDialog}
          setOpenConfirmationDialog={setOpenConfirmationDialog}
          sendMessage={handleSendMessage}
        />
      )}
    </>
  );
}

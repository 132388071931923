import React, { useState } from 'react';
import {
  Backdrop,
  Box,
  Modal,
  Fade,
  Button,
  Typography,
  TextField,
} from '@mui/material';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector } from 'react-redux';
import { addCompany, getHeaders } from '../../../apis/urls';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '10px',
};

const containerStyle = {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  height: '200px',
  padding: '20px',
  borderRadius: '10px',
  border: '2px dashed #555',
  color: '#444',
  cursor: 'pointer',
  transition: 'background 0.2s ease-in-out, border 0.2s ease-in-out',
};

const dropTitleStyle = {
  color: '#444',
  fontSize: '20px',
  fontWeight: 'bold',
  textAlign: 'center',
  transition: 'color 0.2s ease-in-out',
};

const selectedImageStyle = {
  maxWidth: '100%',
  maxHeight: '100%',
  width: '100%',
  height: '100%',
};

const progressbarStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(0,0,0,0.3)',
  borderRadius: '10px',
};

const AddCompany = ({
  openAddCompanyModal,
  handleCloseAddCompanyModal,
  setSelectedImageSrc,
  setIsImageVisible,
  isImageVisible,
  selectedImageSrc,
  setIsSuccess,
  setSuccessMessage,
  setHasError,
  setErrorMessage,
  emailError,
  setEmailError,
}) => {
  const selectedImageContainerStyle = {
    marginTop: '10px',
    display: isImageVisible ? 'block' : 'none',
    width: '100%',
    height: '100px',
  };
  const [companyData, setCompanyData] = useState(null);
  const [showCircularProgressbar, setShowCircularProgressbar] = useState(false);
  const currentProgram = useSelector(
    (state) => state?.programs?.currentProgram
  );
  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImageSrc(imageUrl);
      setIsImageVisible(true);
    } else {
      setSelectedImageSrc('');
      setIsImageVisible(false);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];

    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImageSrc(imageUrl);
      setIsImageVisible(true);
    }
  };

  const handleAddCompany = async () => {
    setShowCircularProgressbar(true);
    await axios
      .post(addCompany(), companyData, {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((res) => {
        setIsSuccess(true);
        setSuccessMessage('New company added successfully');
        setShowCircularProgressbar(false);
        handleCloseAddCompanyModal();
        setCompanyData(null);
      })
      .catch((err) => {
        setHasError(true);
        setErrorMessage('Error while adding new company');
        console.log(err);
        setShowCircularProgressbar(false);
        handleCloseAddCompanyModal();
        setCompanyData(null);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'email') {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      setEmailError(emailRegex.test(value) ? '' : 'Please enter a valid email');
    }
    setCompanyData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <Modal
      open={openAddCompanyModal}
      onClose={handleCloseAddCompanyModal}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={openAddCompanyModal}>
        <Box sx={modalStyle}>
          {showCircularProgressbar && (
            <Box sx={progressbarStyle}>
              <CircularProgress color='inherit' />
            </Box>
          )}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'center',
              gap: '15px',
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: '30px',
                  fontWeight: '700',
                }}
              >
                Add Company
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  textAlign: 'start',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '15px',
                    fontWeight: '700',
                  }}
                >
                  Name:
                </Typography>
              </Box>
              <Box>
                <TextField
                  name='name'
                  type='text'
                  onChange={handleChange}
                  value={companyData?.name}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  textAlign: 'center',
                }}
              >
                <Typography sx={{ fontSize: '15px', fontWeight: '700' }}>
                  Email:
                </Typography>
              </Box>
              <Box>
                <TextField
                  error={emailError}
                  name='email'
                  type='email'
                  onChange={handleChange}
                  value={companyData?.email}
                  helperText={emailError}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'start',
              }}
            >
              <Typography sx={{ fontSize: '15px', fontWeight: '700' }}>
                Upload Logo:
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <div
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                style={{
                  width: '100%',
                }}
              >
                <label
                  htmlFor='images'
                  style={containerStyle}
                >
                  <span style={dropTitleStyle}>Drop files here</span>
                  <input
                    type='file'
                    id='images'
                    accept='image/*'
                    required
                    onChange={handleImageChange}
                    style={{ position: 'absolute', top: '-9999em' }}
                  />
                  {isImageVisible && (
                    <div style={selectedImageContainerStyle}>
                      <Box
                        component='img'
                        id='selected-image'
                        src={selectedImageSrc}
                        alt='Selected Image'
                        style={selectedImageStyle}
                      />
                    </div>
                  )}
                </label>
              </div>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'end',
              }}
            >
              <Button
                variant='contained'
                onClick={handleAddCompany}
                disabled={emailError}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default AddCompany;

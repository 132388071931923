import call from './Icons/call.png';
import details from './Icons/details.png';
import Hello from './Icons/Hello.png';
import sf from './Icons/sf.png';
import unlock from './Icons/unlock.png';
import React, { useMemo } from 'react';
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  Grid,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import './onboarding.css';

const iconMapping = {
  call: call,
  details: details,
  hello: Hello,
  sf: sf,
  unlock: unlock,
};

const steps = [
  { label: 'Welcome Aboard', icon: 'hello' },
  { label: 'Access Signature Frameworks', icon: 'sf' },
  { label: 'Fill your profile details', icon: 'details' },
  { label: 'Book a call with the admissions', icon: 'call' },
  { label: 'Unlock live sessions', icon: 'unlock' },
];

const CustomConnector = styled(StepConnector)({
  '& .MuiStepConnector-line': {
    borderWidth: 3,
    borderColor: 'rgb(25, 118, 210)',
  },
});

const StepIcon = styled('div')({
  display: 'flex',
  height: 25,
  alignItems: 'center',
  justifyContent: 'center',
  '& .QontoStepIcon-circle': {
    width: 15,
    height: 15,
    borderRadius: '50%',
    backgroundColor: 'rgb(25, 118, 210)',
  },
});

const IconComponent = ({ src }) => (
  <div className='stepperImage'>
    <img
      src={src}
      alt=''
    />
  </div>
);

const OnboardingStepper = () => {
  const memoizedIcons = useMemo(
    () =>
      steps.map((step, index) => (
        <Grid
          item
          key={index}
        >
          <IconComponent src={iconMapping[step.icon]} />
        </Grid>
      )),
    []
  );

  return (
    <Box sx={{ width: '100%', marginTop: '30px' }}>
      <Grid
        container
        justifyContent='space-around'
        spacing={2}
      >
        {memoizedIcons}
      </Grid>
      <Stepper
        alternativeLabel
        connector={<CustomConnector />}
        sx={{ mt: 2 }}
      >
        {steps.map((_, index) => (
          <Step key={index}>
            <StepLabel
              StepIconComponent={() => (
                <StepIcon>
                  <div className='QontoStepIcon-circle' />
                </StepIcon>
              )}
            />
          </Step>
        ))}
      </Stepper>
      <Grid
        container
        justifyContent='space-between'
        sx={{ mt: 2 }}
      >
        {steps.map((step, index) => (
          <Grid
            item
            key={index}
            xs={12}
            sm={2}
          >
            <Box sx={{ display: 'flex', textAlign: 'center' }}>
              <Typography
                fontWeight='bold'
                fontSize={15}
              >
                {step.label}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default OnboardingStepper;

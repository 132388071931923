// @ts-nocheck
import React, { useRef, useState, useEffect } from 'react';
import {
  Box,
  Stack,
  useMediaQuery,
  useTheme,
  Grid,
  Stepper,
  Step,
  StepLabel,
} from '@mui/material';
import PaymentModesScreen from './PaymentModesScreen';
import { styled } from '@mui/material/styles';
import StepConnector from '@mui/material/StepConnector';
import { useFastrackPipeline } from 'src/layouts/EfPipelineContext/FastrackPipelineContex';
import JoinWaitList from './JoinWaitList';

const steps = ['Application form', 'Screening test', 'Offer letter'];
const CustomConnector = styled(StepConnector)(({ theme }) => ({
  [`& .MuiStepConnector-line`]: {
    borderColor: '#1976D2',
  },
}));
const Screen3 = () => {
  const { hasJoinedWaitlist } = useFastrackPipeline();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTab = useMediaQuery('(max-width: 1200px)');

  return (
    <>
      {hasJoinedWaitlist ? (
        <JoinWaitList />
      ) : (
        <Box
          alignSelf='center'
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: '#e3f2fd',
            paddingTop: '6rem',
            paddingBottom: '2rem',
            paddingLeft: '2rem',
            paddingRight: '2rem',
            overflowY: 'auto',
            overflowX: 'hidden',
            '@media (max-width: 500px)': {
              paddingTop: '1.5rem',
              paddingLeft: '2rem',
            },
            '@media (min-height: 700px)': {
              paddingTop: '3rem',
            },
          }}
        >
          <Grid
            container
            spacing={4}
            sx={{
              marginLeft: 0,
              backgroundColor: 'white',
              borderRadius: '12px',
              width: {
                xs: '80vw',
                sm: '80vw',
                md: '84vw',
                lg: '80vw',
                xl: '80vw',
              },
              height: 'fit-content',
              '@media (max-width: 1200px)': {
                height: 'fit-content',
                width: {
                  xs: '95vw',
                  sm: '85vw',
                  md: '80vw',
                },
              },
              paddingTop: '3rem',
              paddingBottom: '3rem',
            }}
          >
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
            >
              <Box
                sx={{
                  width: {
                    xs: '100%',
                    sm: '75%',
                    md: '60%',
                    lg: '50%',
                  },
                  maxWidth: '90%',
                  marginLeft: isMobile ? '0' : 'auto',
                  marginRight: isMobile ? '2.5rem' : 'auto',
                }}
              >
                <Stepper
                  activeStep={2}
                  alternativeLabel={isMobile}
                  connector={<CustomConnector />}
                >
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel
                        sx={{
                          fontFamily: 'Inter',
                          color: '#1976d2',
                        }}
                      >
                        {label}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              container
              direction='column'
              height='100%'
              style={{
                paddingLeft: 0,
              }}
            >
              <PaymentModesScreen />
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default Screen3;

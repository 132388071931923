import React from 'react';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, IconButton, Typography } from '@mui/material';

function Pagination({ onPageChange, page, hasNextPage, totalPages }) {
  return (
    <Box
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      width='100%'
      mt={2}
    >
      {page > 0 && (
        <IconButton
          disabled={page === 1}
          onClick={() => onPageChange(page - 1)}
        >
          <ArrowBackIosNewIcon />
        </IconButton>
      )}
      <Typography variant='body1'>
        Page {page} / {totalPages}
      </Typography>
      {hasNextPage && (
        <IconButton
          disabled={!hasNextPage}
          onClick={() => onPageChange(page + 1)}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      )}
    </Box>
  );
}

export default Pagination;

// @ts-nocheck
import React, { useState } from 'react';
import HelpSharpIcon from '@mui/icons-material/HelpSharp';
import { Box } from '@material-ui/core';
import HelpPopover from './HelpPopover';
import styles from './HelpSection.module.css';

const HelpButton = () => {
  const [showHelpOptionModal, setShowHelpOptionModal] = useState(false);

  const handleHelpClick = () => {
    setShowHelpOptionModal(true);
  };

  const handleHelpCloseClick = (e) => {
    e.stopPropagation();
    setShowHelpOptionModal(false);
  };

  return (
    <Box
      className={styles.helpBtn}
      onClick={handleHelpClick}
    >
      <HelpSharpIcon sx={{ fontSize: '20px', color: '#ffffff' }} />
      Help
      {showHelpOptionModal && (
        <HelpPopover
          isOpen={showHelpOptionModal}
          closeHandler={handleHelpCloseClick}
        />
      )}
    </Box>
  );
};

export default HelpButton;

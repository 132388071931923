import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import TimerIcon from '@mui/icons-material/Timer';
import { useSelector } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import LoadingButton from '@mui/lab/LoadingButton';
import { addCodingTestData, getHeaders } from '../../../apis/urls';
import DividerComponent from '../Atoms/DividerComponent';
import TypographyText from '../Atoms/TypographyText';
import AttemptStatsInDrawer from './AttemptedStatsInDrawer';
import AttemptDataInDrawer from './AttemptDataInDrawer';
import ErrorNotifier from '../../ToastNotifications/ErrorNotifier';
import tgplevelsInfo from '../../../tgpLevelsInfo.json';

const levelIdToNameMap = {
  2: 'C1',
  3: 'C2',
  4: 'C3',
  5: 'C4',
  6: 'C5',
  7: 'C6',
  8: 'C7',
  9: 'C8',
  10: 'C9',
  11: 'C10',
};

const DrawerComponent = ({
  setSelectedTest,
  setOpenTest,
  selectedTest,
  selectedLevel,
}) => {
  const currentProgram = useSelector((state) => state.programs.currentProgram);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [remainingCooldownTime, setRemainingCooldownTime] = useState(0);

  useEffect(() => {
    if (!selectedTest || selectedTest.hasCleared || selectedTest.isRunning) {
      return;
    }

    const cooldownEndsAt = selectedTest.endTime.clone().add(12, 'hours');
    const diff = cooldownEndsAt.diff(moment(), 'seconds');
    let intervalId;

    if (diff > 0) {
      setRemainingCooldownTime(diff);
      intervalId = setInterval(() => {
        setRemainingCooldownTime((prevTime) => Math.max(0, prevTime - 1));
      }, 1000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, []);

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours}h:${minutes}m:${remainingSeconds}s`;
  };

  const getAttemptButtonText = () => {
    if (!selectedTest) {
      return 'Attempt';
    }
    if (selectedTest.hasCleared) {
      return 'Submitted';
    }

    if (!selectedTest.hasSubmitted && selectedTest.isRunning) {
      return 'Resume';
    }

    return 'Re-Attempt';
  };

  const shouldDisable = () => {
    if (!selectedTest) {
      return false;
    }

    if (selectedTest.hasCleared) {
      return true;
    }

    if (!selectedTest.hasSubmitted && selectedTest.isRunning) {
      return false;
    }

    return shouldEnforceCoolDown();
  };

  const shouldEnforceCoolDown = () => {
    if (!selectedTest) {
      return false;
    }

    const cooldownEndsAt = selectedTest.endTime.clone().add(12, 'hours');
    return moment().isBefore(cooldownEndsAt);
  };

  const testAttemptButtonClick = () => {
    if (selectedTest && selectedTest.isRunning) {
      setOpenTest(true);
      return;
    }

    const body = {
      past_level_set_id: selectedTest?.levelSetId ?? null,
      selected_level_name: selectedLevel,
    };
    setLoading(true);
    axios
      .post(addCodingTestData(), body, {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((res) => {
        const test = res.data;
        const startTime = moment(test.start_time);
        const endTime = moment(test.end_time);
        const startedAt = moment(test.started_at);
        const submittedAt = moment(test.submitted_at);

        const levelName = levelIdToNameMap[test.level_set.level_id];
        const isRunning = true;

        setSelectedTest({
          testId: test.id,
          levelName,
          name: test.name,
          hasJoined: test.has_joined,
          score: test.test_score,
          hasCleared: test.has_cleared,
          isRunning,
          hasSubmitted: test.has_submitted,
          duration: test.duration,
          levelId: test.level_set.level_id,
          levelSetId: test.level_set.id,
          startedAt,
          endTime,
          startTime,
          submittedAt,
          totalAttempts: test.total_level_attempts,
        });
        setOpenTest(true);
        setLoading(false);
      })
      .catch((err) => {
        setHasError(true);
        setErrorMessage(err.response?.data?.message ?? 'Something went wrong.');
        console.log(err);
      });
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      <TypographyText
        text={`The Great Pyramid Level C-${selectedLevel.substring(1)}`}
        stylingClass='drawer-title'
      />
      <DividerComponent dividerStyleClass='drawer-divider' />
      <Box className='drawer-global-box'>
        <Box className='drawer-box'>
          <TypographyText
            text={
              tgplevelsInfo[selectedLevel.substring(1)].description ||
              'Description not available'
            }
            stylingClass='drawer-stats desc-data'
          />
          <Box className='info-row'>
            <TypographyText
              text='No. of questions'
              stylingClass='drawer-stats'
            />
            <TypographyText
              text={tgplevelsInfo[selectedLevel.substring(1)].noOfQuestions}
              stylingClass='drawer-stats'
            />
          </Box>
          <Box className='info-row'>
            <TypographyText
              text='Time Duration'
              stylingClass='drawer-stats'
            />
            <TypographyText
              text='2 hours'
              stylingClass='drawer-stats'
            />
          </Box>
          <Box className='info-row'>
            <TypographyText
              text='Status'
              stylingClass='drawer-stats'
            />
            {!selectedTest?.hasSubmitted && (
              <TypographyText
                text='Not yet attempted'
                stylingClass='status-text'
              />
            )}
            {selectedTest?.hasSubmitted && (
              <AttemptDataInDrawer selectedTest={selectedTest} />
            )}
          </Box>
        </Box>
      </Box>
      <Box className='drawer-button-box'>
        <LoadingButton
          variant='contained'
          className='attemptButton'
          onClick={testAttemptButtonClick}
          disabled={shouldDisable()}
          loading={loading}
        >
          {getAttemptButtonText()}
        </LoadingButton>
        {remainingCooldownTime > 0 && (
          <Box className='timer-box'>
            <TimerIcon className='timer-icon' />
            <TypographyText
              text={formatTime(remainingCooldownTime)}
              stylingClass='timer-text'
            />
          </Box>
        )}
      </Box>
    </>
  );
};

export default DrawerComponent;

import React, { useEffect, useState } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import moment from 'moment';
import VideocamIcon from '@mui/icons-material/Videocam';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Button from './Button';
import { fetchLiveSessionLinks, getHeaders } from '../../apis/urls';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';
import NewSessionRecording from './NewSessionRecording';
import NewReadingMaterial from './NewReadingMaterial';
import SuccessNotifier from '../ToastNotifications/SuccessNotifier';
import SelectedCodingAssignment from '../performance/CodingAssignments/SelectedCodingAssignment';

export default function SessionItemDetails({
  details,
  moduleName,
  setCurrentIndex,
  text,
}) {
  const currentProgram = useSelector((state) => state.programs.currentProgram);
  const [driveAndMeetLinks, setDriveAndMeetLinks] = useState({});
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [selectedAssignment, setSelectedAssignment] = useState(null);
  const [type, setType] = useState('');

  useEffect(() => {
    if (details.resource_type !== undefined) {
      setType('preRead');
    } else if (details.code_problem_id !== undefined) {
      setType('assignment');
    } else {
      setType('session');
    }
  });

  useEffect(() => {
    if (currentProgram?.hasProgramStarted) {
      axios
        .get(fetchLiveSessionLinks(), {
          headers: getHeaders(currentProgram?.programId),
        })
        .then((res) => {
          setDriveAndMeetLinks(res.data?.links);
        })
        .catch((err) => {
          console.log(err);
          setHasError(true);
          setErrorMessage(err.response?.data?.message || err.message);
        });
    }
    return () => {};
  }, []);

  const handleBack = () => {
    window.history.back();
  };

  const handletext = () => {
    if (type === 'session') {
      const sessionStartDate = moment(details.start).format(
        'YYYY-MM-DD hh:mm A'
      );

      if (details.status === 'scheduled') {
        return `scheduled date: ${sessionStartDate}`;
      }
      if (details.status === 'created') {
        return `Expected date: ${sessionStartDate}`;
      }
      return `Conducted on: ${sessionStartDate}`;
    }

    if (type === 'preRead') {
      return `Pre - Reads - ${details.title}`;
    }

    if (type === 'assignment') {
      const opensAt = moment(details.opens_at).format('YYYY-MM-DD hh:mm A');
      const deadline = moment(details.deadline).format('YYYY-MM-DD hh:mm A');
      return `Opens At: ${opensAt} and Deadline is: ${deadline}`;
    }
  };

  const handleSessionRecording = () => {
    if (details.status === 'scheduled') {
      return (
        <Typography
          fontSize='18px'
          textAlign='center'
        >
          The recording will be available after the live class is conducted.
        </Typography>
      );
    }
    if (details.status === 'created') {
      return (
        <Typography
          fontSize='18px'
          textAlign='center'
        >
          The class has not been scheduled yet please contact to your POC.
        </Typography>
      );
    }
    return <NewSessionRecording details={details} />;
  };

  return (
    <Box sx={{ margin: '20px 10px 10px 30px', minHeight: '100%' }}>
      <Box>
        {hasError && (
          <ErrorNotifier
            message={errorMessage}
            setHasError={setHasError}
          />
        )}
        {isSuccess && (
          <SuccessNotifier
            message={successMessage}
            setIsSuccess={setIsSuccess}
          />
        )}
        <Box sx={{ display: 'flex' }}>
          <Typography
            sx={{
              color: '#1976D2',
              fontWeight: '600',
              cursor: 'pointer',
            }}
            onClick={handleBack}
          >
            {moduleName}
          </Typography>
          <Typography
            sx={{
              color: 'black',
              fontWeight: '600',
              marginLeft: '6px',
            }}
          >
            {`> ${text}`}
          </Typography>
        </Box>

        <Box
          sx={{
            marginTop: '28px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box>
            <Typography
              sx={{
                fontWeight: '700',
                fontSize: '30px',
              }}
            >
              {text}
            </Typography>
            <Typography
              sx={{
                color: '#545454',
                marginBottom: '10px',
              }}
            >
              {handletext()}
            </Typography>
          </Box>
          {type === 'session' && details.status === 'scheduled' && (
            <Button
              fontSize='13px'
              margin='0px 10px 0px 0px'
              href={`${
                driveAndMeetLinks?.google_meet_link?.includes('https://')
                  ? ''
                  : 'https://'
              }${driveAndMeetLinks?.google_meet_link}`}
            >
              {' '}
              <VideocamIcon />
              Join Live Class
            </Button>
          )}
        </Box>
        <Divider style={{ margin: 'auto', backgroundColor: '#e8e8e8' }} />

        <Box sx={{ marginTop: '50px' }}>
          {type === 'session' && handleSessionRecording()}
          {type === 'preRead' && (
            <NewReadingMaterial
              materialResource={details}
              setHasError={setHasError}
              setIsSuccess={setIsSuccess}
              setErrorMessage={setErrorMessage}
              setSuccessMessage={setSuccessMessage}
              setCurrentIndex={setCurrentIndex}
            />
          )}
          {type === 'assignment' && (
            <SelectedCodingAssignment
              selectedAssignment={details}
              setSelectedAssignment={setSelectedAssignment}
              shouldShowBackButton={false}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}

import React from 'react';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { topBarContents } from './StudentNavbarData';

const TabRender = ({ value, subTabValue }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        padding: '0 20px',
      }}
    >
      {topBarContents[value].children.length > 0 ? (
        <>{topBarContents[value].children[subTabValue].component}</>
      ) : (
        <Outlet />
      )}
    </Box>
  );
};

export default TabRender;

/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Container, Grid, Paper, Stack, Typography } from '@mui/material';
import './Style.css';
import { Modal } from '@material-ui/core';
import axios from 'axios';
import { getCoupons, getHeaders } from 'src/apis/urls';
import { getOfferRate, isExpired } from './TAPCouponsUtil';
import CouponDetail from './Molecules/CouponDetail';
import LoadingScreen from 'src/pages/LoadingScreen';
import { getUserType } from 'src/configs/auth';
import EfHeader from '../eligibility-forms/EfHeader';

const MyCoupons = () => {
  const [showDetails, setShowDetails] = useState(false);
  const [coupons, setCoupons] = useState([]);
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (getUserType() === 'student') {
      return;
    }

    setLoading(true);
    axios
      .get(getCoupons(), { headers: getHeaders() })
      .then((res) => {
        setCoupons(res.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Stack
      sx={{
        width: '100vw',
        height: '100vh',
      }}
    >
      <EfHeader />
      <LoadingScreen loading={loading} />
      <Modal
        open={showDetails}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%',
        }}
        onClose={() => setShowDetails(false)}
      >
        <CouponDetail coupon={selectedCoupon} />
      </Modal>

      <div
        style={{
          background: 'linear-gradient(45deg, #99ccff, #ffffcc, #ffffff)',
          height: 'inherit',
          overflow: 'auto',
        }}
      >
        <Container
          style={{
            marginTop: '2rem',
            background: 'white',
            borderRadius: '1rem',
            padding: 'clamp(1rem, 1.5rem, 3rem)',
            height: '90%',
            overflowY: 'auto',
          }}
        >
          <Typography
            variant='h3'
            sx={{
              fontFamily: 'Inter',
              color: '#1374d0',
              textAlign: 'center',
              fontWeight: 'bolder',
            }}
            gutterBottom
          >
            My Coupons
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Inter',
            }}
            gutterBottom
          >
            Congratulations on earning exclusive coupons! You can now enjoy a
            variety of discounts from our partnered brands. Simply click on the
            coupons to find your unique coupon code. Enjoy your rewards and
            happy saving!
          </Typography>
          {/* Add the coupons-container div here */}
          <div className='coupons-container'>
            <Grid
              container
              justifyContent='center'
            >
              {coupons.map((coupon) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  key={coupon.id}
                  display='flex'
                  justifyContent='center'
                >
                  <div className='ticket-card'>
                    {isExpired(coupon) && (
                      <img
                        src='/static/expired.png'
                        style={{
                          position: 'absolute',
                          width: '60px',
                          top: '2px',
                          right: '5px',
                        }}
                      />
                    )}
                    <div
                      className='ticket-card__body'
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div className='ticket-card__image'>
                        <img
                          src={coupon.logo_url}
                          alt='Offer'
                        />
                      </div>
                      <div className='ticket-card__divider'></div>
                      <div className='ticket-card__details'>
                        <h3>{coupon.company_name}</h3>
                        <p className='ellipsis-2-lines'>{coupon.description}</p>
                        <div className='ticket-card__price-details'>
                          <span className='ticket-card__price'>
                            {getOfferRate(coupon)}
                          </span>
                          {!isExpired(coupon) && (
                            <a
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                setShowDetails(true);
                                setSelectedCoupon(coupon);
                              }}
                              className='ticket-card__link'
                            >
                              View details
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
              ))}
            </Grid>
          </div>
        </Container>
      </div>
    </Stack>
  );
};

export default MyCoupons;

// @ts-nocheck
import { Stack, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import ErrorNotifier from '../../ToastNotifications/ErrorNotifier';
import LoadingScreen from '../../../pages/LoadingScreen';
import { BASE_URL, getHeaders } from '../../../apis/urls';
import SessionTestTable from './SessionTestTable';

function SessionTests() {
  const currentProgram = useSelector((state) => state.programs.currentProgram);
  const [allTests, setAllTests] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getAllSessionTests();
    return () => {};
  }, []);

  const getAllSessionTests = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/session_tests`, {
        headers: getHeaders(currentProgram?.programId),
      });

      const sessionTests = (response.data ?? []).map((test) => {
        const startTime = moment(test.start_time);
        const endTime = moment(test.end_time);
        const startedAt = moment(test.started_at);

        return {
          testId: test.id,
          name: test.name,
          hasJoined: test.has_joined,
          hasSubmitted: test.has_submitted,
          isLateAttempt: test.is_late_attempt,
          duration: test.duration,
          startedAt,
          endTime,
          startTime,
        };
      });

      setAllTests(sessionTests ?? []);
    } catch (err) {
      console.log(err);
      setHasError(true);
      setErrorMessage(err.response?.data?.message || err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Stack
      mt={2}
      sx={{ width: '70vw' }}
    >
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}

      <LoadingScreen loading={loading} />

      {allTests.length > 0 ? (
        <SessionTestTable
          allTests={allTests}
          setErrorMessage={setErrorMessage}
          setHasError={setHasError}
        />
      ) : (
        <Typography
          variant='body2'
          component='p'
          textAlign='center'
          mt={5}
        >
          No session tests found
        </Typography>
      )}
    </Stack>
  );
}

export default SessionTests;

import React, { useEffect, useState } from 'react';
import { getHeaders, ticketUrl } from 'src/apis/urls';
import axios from 'axios';
import { setTickets } from 'src/components/redux-store/slices/ticketSlice';
import { useDispatch } from 'react-redux';
import { Button } from '@mui/material';

function Pagination({ queryString, page, setPage, pageSize }) {
  const [totalPages, setTotalPages] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    axios
      .get(ticketUrl(queryString, page, pageSize), { headers: getHeaders() })
      .then((res) => {
        if (res.data) {
          dispatch(setTickets({ tickets: res?.data?.tickets }));
          setTotalPages(Math.ceil(res?.data?.total_count / pageSize));
        }
      })
      .catch((e) => {
        console.error("couldn't fetch tickets data :(");
      });
  }, [page, queryString, pageSize, dispatch]);

  return (
    <div style={{ display: 'flex', justifyContent: 'end' }}>
      <div style={{ justifyContent: 'center', marginTop: '10px' }}>
        {page > 1 && (
          <Button
            variant='contained'
            onClick={() => setPage((prev) => prev - 1)}
          >
            Prev
          </Button>
        )}
        &nbsp;&nbsp;{page}/{totalPages}&nbsp;&nbsp;
        {page < totalPages && (
          <Button
            variant='contained'
            onClick={() => setPage((prev) => prev + 1)}
          >
            Next
          </Button>
        )}
      </div>
    </div>
  );
}

export default Pagination;

import React, { createContext, useState, useContext } from 'react';

const SessionContext = createContext();

function SessionAPI({ children }) {
    const [materialResource, setMaterialResource] = useState([]);
    return (
        <SessionContext.Provider value={{ materialResource, setMaterialResource }}>
            {children}
        </SessionContext.Provider>
    );
}

export default SessionAPI;
export const useSessionContext = () => useContext(SessionContext);

import React, { useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Menu, MenuItem } from '@material-ui/core';
import moment from 'moment';

export default function Cell({ studentId, sessionId, session, markAttendance }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const currTime = moment(); // in IST. It defaults to the local time zone. (i.e., automatically adds +05:30 to the UTC time.)
  const startTime = moment(session?.startTime).utc(); // moment adds +05:30, but .utc() -05:30. Hence, startTime remains same but now it is a moment object.

  const handleClick = (event, sid, lid) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e) => {
    setAnchorEl(null);
  };

  const selectedAttendanceType = (sessionId, studentId, status) => {
    setAnchorEl(null);
    markAttendance(sessionId, studentId, status);
  };

  return (
    <Box>
      <IconButton
        onClick={handleClick}
        size="medium"
        aria-controls={open ? '' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        disabled={currTime.isBefore(startTime)}
      >
        {(() => {
          switch (true) {
            case (session?.attendances[studentId] === undefined):
              return (
                <Box 
                  sx={{
                    height: "30px",
                    width: "30px",
                    borderStyle: "solid",
                    color: "black",
                    borderWidth: "thin"
                  }}
                />
              );
            case (session?.attendances[studentId]?.status === "joined"):
              return (
                <Box 
                  sx={{
                    width: "30px",
                    borderStyle: "solid",
                    color: "black",
                    borderWidth: "thin",
                    backgroundColor: "#00e676"
                  }}
                >
                  <CheckIcon fontSize='small'/>
                </Box>
              );
            default:
              return (
                <Box 
                  sx={{
                    width: "30px",
                    borderStyle: "solid",
                    color: "black",
                    borderWidth: "thin",
                    backgroundColor: "#ff1744"
                  }}
                >
                  <CloseIcon fontSize='small'/>
                </Box>
              );
            }
        })()}
      </IconButton>

      <Menu
        id="attendance-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => selectedAttendanceType(sessionId, studentId, "joined")}>Present</MenuItem>
        <MenuItem onClick={() => selectedAttendanceType(sessionId, studentId, "skipped")}>Absent</MenuItem>
        <MenuItem onClick={() => selectedAttendanceType(sessionId, studentId, "clear")}>Clear</MenuItem>
      </Menu>
    </Box>
  );
}
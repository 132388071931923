import React, { useState, useEffect } from 'react';
import { Box, Button, MenuItem, Select } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { useSelector } from 'react-redux';
import AddRoles from '../ModalScreens/AddRoles';
import AddCompany from '../ModalScreens/AddCompany';
import { bulkUpdateCompanyRoles, getHeaders } from '../../../apis/urls';

const columns = [
  {
    field: 'role_title',
    headerName: 'Role title',
    width: 160,
    align: 'center',
    headerAlign: 'center',
    type: 'number',
    paddingLeft: '25px',
  },
  {
    field: 'location',
    headerName: 'Location',
    width: 160,
    align: 'center',
    headerAlign: 'center',
    paddingLeft: '25px',
  },
  {
    field: 'companyName',
    headerName: 'Company name',
    width: 160,
    align: 'center',
    headerAlign: 'center',
    valueGetter: (params) => params.row.company?.name || '',
  },
  {
    field: 'min_ctc',
    headerName: 'Min CTC(LPA)',
    width: 160,
    align: 'center',
    headerAlign: 'center',
    type: 'singleSelect',
    editable: true,
  },
  {
    field: 'max_ctc',
    headerName: 'Max CTC(LPA)',
    width: 160,
    align: 'center',
    headerAlign: 'center',
  },
];

const Roles = ({
  SuccessNotifier,
  ErrorNotifier,
  companyRoles,
  companies,
  techStacks,
  loading,
}) => {
  const [openAddCompanyModal, setOpenAddCompanyModal] = useState(false);
  const [openAddRolesModal, setOpenAddRolesModal] = useState(false);
  const [roleData, setRoleData] = useState(null);
  const [selectedImageSrc, setSelectedImageSrc] = useState('');
  const [isImageVisible, setIsImageVisible] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [editedRows, setEditedRows] = useState([]);
  const [updatedStatus, setUpdatedStatus] = useState({});
  const [perPage, setPerPage] = useState(50);
  const [emailError, setEmailError] = useState('');
  const [showLoaderInButton, setShowLoaderInButton] = useState(false);
  const currentProgram = useSelector(
    (state) => state?.programs?.currentProgram
  );

  const handleOpenAddCompanyModal = () => {
    setOpenAddCompanyModal(true);
  };

  const handleCloseAddCompanyModal = () => {
    setOpenAddCompanyModal(false);
    setEmailError('');
  };

  const handleOpenAddRolesModal = () => {
    setOpenAddRolesModal(true);
  };

  const handleCloseAddRolesModal = () => {
    setOpenAddRolesModal(false);
    setRoleData(null);
    setEmailError('');
  };

  const handleJobStatusAndApprovalStatusUpdate = (row, field, value) => {
    setUpdatedStatus((prev) => {
      const id = companyRoles[row.id - 1]?.id;

      const updatedData = {
        id,
        [field]: value,
      };

      if (!Array.isArray(prev)) {
        prev = [];
      }

      let existingIndex = -1;

      for (let i = 0; i < Object.keys(prev).length; i += 1) {
        if (prev[i].id === id) {
          existingIndex = i;
          break;
        }
      }

      if (existingIndex !== -1) {
        prev[existingIndex] = {
          ...prev[existingIndex],
          [field]: value,
        };
      } else {
        prev.push(updatedData);
      }

      return [...prev];
    });

    row[field] = value;
  };

  const handleSave = async () => {
    setShowLoaderInButton(true);
    try {
      await axios.patch(bulkUpdateCompanyRoles(), updatedStatus, {
        headers: getHeaders(currentProgram?.programId),
      });
      setIsSuccess(true);
      setSuccessMessage('Roles updated successfully');
      setEditedRows([]);
      setUpdatedStatus({});
      setShowLoaderInButton(false);
    } catch (error) {
      setHasError(true);
      setErrorMessage('Error updating roles');
      console.error(error);
      setShowLoaderInButton(false);
    }
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      {isSuccess && (
        <SuccessNotifier
          message={successMessage}
          setIsSuccess={setIsSuccess}
        />
      )}
      <Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              padding: '0 0 0 10px',
            }}
          >
            <LoadingButton
              loading={showLoaderInButton}
              variant='contained'
              onClick={handleSave}
              disabled={Object.keys(updatedStatus).length === 0}
            >
              Save
            </LoadingButton>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '10px',
              width: '100%',
              padding: '10px',
              justifyContent: 'end',
            }}
          >
            <Button
              variant='contained'
              onClick={handleOpenAddCompanyModal}
            >
              Add Company
            </Button>
            <Button
              variant='contained'
              onClick={handleOpenAddRolesModal}
            >
              Add Roles
            </Button>
          </Box>
        </Box>

        <Box>
          <DataGrid
            rows={companyRoles}
            columns={[
              ...columns,
              {
                field: 'job_status',
                headerName: 'Job status',
                width: 160,
                align: 'center',
                headerAlign: 'center',

                renderCell: (params) => {
                  return (
                    <Select
                      value={params.value}
                      sx={{
                        height: '40px',
                        width: '110px',
                      }}
                      onChange={(e) =>
                        handleJobStatusAndApprovalStatusUpdate(
                          params.row,
                          'job_status',
                          e.target.value
                        )
                      }
                    >
                      <MenuItem value='hidden'>hidden</MenuItem>
                      <MenuItem value='live'>live</MenuItem>
                      <MenuItem value='expired'>expired</MenuItem>
                    </Select>
                  );
                },
              },
              {
                field: 'approval_status',
                headerName: 'Approval status',
                width: 160,
                align: 'center',
                headerAlign: 'center',
                renderCell: (params) => {
                  return (
                    <Select
                      value={params.value}
                      sx={{
                        height: '40px',
                        width: '120px',
                      }}
                      onChange={(e) => {
                        handleJobStatusAndApprovalStatusUpdate(
                          params.row,
                          'approval_status',
                          e.target.value
                        );
                      }}
                    >
                      <MenuItem value='pending'>pending</MenuItem>
                      <MenuItem value='rejected'>rejected</MenuItem>
                      <MenuItem value='approved'>approved</MenuItem>
                    </Select>
                  );
                },
              },
            ]}
            checkboxSelection
            isRowSelectable={(params) => params.row.quantity > 50000}
            rowsPerPageOptions={[50, 100]}
            onPageSizeChange={(page) => setPerPage(page)}
            pagination
            loading={loading}
            autoHeight
            disableSelectionOnClick
          />
        </Box>

        <AddCompany
          openAddCompanyModal={openAddCompanyModal}
          handleCloseAddCompanyModal={handleCloseAddCompanyModal}
          selectedImageSrc={selectedImageSrc}
          setSelectedImageSrc={setSelectedImageSrc}
          isImageVisible={isImageVisible}
          setIsImageVisible={setIsImageVisible}
          setIsSuccess={setIsSuccess}
          setSuccessMessage={setSuccessMessage}
          setHasError={setHasError}
          setErrorMessage={setErrorMessage}
          emailError={emailError}
          setEmailError={setEmailError}
        />
        <AddRoles
          openAddRolesModal={openAddRolesModal}
          handleCloseAddRolesModal={handleCloseAddRolesModal}
          setIsSuccess={setIsSuccess}
          setSuccessMessage={setSuccessMessage}
          setHasError={setHasError}
          setErrorMessage={setErrorMessage}
          companies={companies}
          companyRoles={companyRoles}
          roleData={roleData}
          setRoleData={setRoleData}
          techStacks={techStacks}
        />
      </Box>
    </>
  );
};

export default Roles;

import React, { useState } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from '@mui/material';
import { leadgenURL, leadUpdateURL } from 'src/apis/urls';
import { useLocation } from 'react-router-dom';

const SignupForm = ({ onSuccess }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    workExperience: '',
    preferredLanguage: '',
    companyName: '',
    role: '',
    graduationYear: '',
    branch: '',
  });
  const [leadId, setLeadId] = useState('');
  const [errors, setErrors] = useState({});
  const location = useLocation();
  const URLParams = new URLSearchParams(location.search);
  const utmSource = URLParams.get('utm_source');
  const utmMedium = URLParams.get('utm_medium');
  const utmCampaign = URLParams.get('utm_campaign');

  const validate = () => {
    let tempErrors = {};
    if (!formValues.name) tempErrors.name = 'Name is required';
    if (!formValues.email) {
      tempErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formValues.email)) {
      tempErrors.email = 'Email is not valid';
    }
    if (!formValues.phoneNumber) {
      tempErrors.phoneNumber = 'Phone Number is required';
    } else if (!/^\d{10}$/.test(formValues.phoneNumber)) {
      tempErrors.phoneNumber = 'Phone Number must be 10 digits';
    }
    if (activeStep === 1) {
      if (!formValues.companyName)
        tempErrors.companyName = 'Company Name is required';
      if (!formValues.role) tempErrors.role = 'Role is required';
      if (!formValues.graduationYear)
        tempErrors.graduationYear = 'Graduation Year is required';
      if (!formValues.branch) tempErrors.branch = 'Branch is required';
    }
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };
  const getUtmData = () => {
    return {
      utm_source: utmSource,
      utm_medium: utmMedium,
      utm_campaign: utmCampaign,
    };
  };
  const getLeadgenReqPayload = () => {
    const finalUtmData = getUtmData();
    return {
      email: formValues.email,
      name: formValues.name,
      mobile_number: formValues.phoneNumber,
      category: formValues.workExperience,
      tech_stack: formValues.preferredLanguage,
      ...finalUtmData,
    };
  };
  const handleRegister = async () => {
    const url = leadgenURL();
    const reqPayload = getLeadgenReqPayload();
    const response = await axios.post(url, reqPayload);
    setLeadId(response.data.sales_Id);
  };
  const handleNext = () => {
    if (validate()) {
      //Make the first POST request to sales for lead generation
      handleRegister();
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  const getLeadUpdateReqPayload = () => {
    return {
      graduation_year: formValues.graduationYear,
      company: formValues.companyName,
      role: formValues.role,
      branch: formValues.branch,
      lead_id: leadId,
    };
  };
  const handleUpdate = async () => {
    const url = leadUpdateURL();
    const reqPayload = getLeadUpdateReqPayload();
    const response = await axios.post(url, reqPayload);
  };
  const handleSubmit = () => {
    if (validate()) {
      //Make the second POST request to update the lead generated from first API call
      handleUpdate();
      onSuccess();
    }
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        width: isSmallScreen ? '90%' : '70%',
        margin: 'auto',
        mt: isSmallScreen ? 1 : 1,
        mb: isSmallScreen ? 1 : 1,
      }}
    >
      <Typography
        variant='h4'
        align='center'
        gutterBottom
      >
        Career Consultation
      </Typography>
      <Box sx={{ mt: 1, mb: 1 }}>
        {activeStep === 0 && (
          <Box>
            <TextField
              label='Name'
              name='name'
              value={formValues.name}
              onChange={handleChange}
              error={!!errors.name}
              helperText={errors.name}
              fullWidth
              margin='normal'
              autoFocus
            />
            <TextField
              label='Email'
              name='email'
              value={formValues.email}
              onChange={handleChange}
              error={!!errors.email}
              helperText={errors.email}
              fullWidth
              margin='normal'
            />
            <TextField
              label='Phone Number'
              name='phoneNumber'
              value={formValues.phoneNumber}
              onChange={handleChange}
              error={!!errors.phoneNumber}
              helperText={errors.phoneNumber}
              fullWidth
              margin='normal'
            />
            <FormControl
              fullWidth
              margin='normal'
              error={!!errors.workExperience}
            >
              <InputLabel id='work-experience-label'>
                Work Experience
              </InputLabel>
              <Select
                labelId='work-experience-label'
                label='Work Experience'
                name='workExperience'
                value={formValues.workExperience}
                onChange={handleChange}
              >
                <MenuItem value='WORKING PROFESSIONAL'>
                  Working Professional with 0-2 yr exp
                </MenuItem>
                <MenuItem value='WORKING PROFESSIONAL 2-5 EXP'>
                  Working Professional with 2-5 yrs exp
                </MenuItem>
                <MenuItem value='WORKING PROFESSIONAL 5+ EXP'>
                  Working Profesisonal with 5+ yrs exp
                </MenuItem>
                <MenuItem value='GRADUATED'>Graduated</MenuItem>
                <MenuItem value='STUDENT'>Student</MenuItem>
              </Select>
              {errors.workExperience && (
                <Typography
                  variant='body2'
                  color='error'
                >
                  {errors.workExperience}
                </Typography>
              )}
            </FormControl>
            <TextField
              label='Preferred Programming Language'
              name='preferredLanguage'
              value={formValues.preferredLanguage}
              onChange={handleChange}
              error={!!errors.preferredLanguage}
              helperText={errors.preferredLanguage}
              fullWidth
              margin='normal'
            />
          </Box>
        )}
        {activeStep === 1 && (
          <Box>
            <TextField
              label='Company Name'
              name='companyName'
              value={formValues.companyName}
              onChange={handleChange}
              error={!!errors.companyName}
              helperText={errors.companyName}
              fullWidth
              margin='normal'
            />
            <TextField
              label='Role'
              name='role'
              value={formValues.role}
              onChange={handleChange}
              error={!!errors.role}
              helperText={errors.role}
              fullWidth
              margin='normal'
            />
            <TextField
              label='Graduation Year'
              name='graduationYear'
              value={formValues.graduationYear}
              onChange={handleChange}
              error={!!errors.graduationYear}
              helperText={errors.graduationYear}
              fullWidth
              margin='normal'
            />
            <TextField
              label='Branch'
              name='branch'
              value={formValues.branch}
              onChange={handleChange}
              error={!!errors.branch}
              helperText={errors.branch}
              fullWidth
              margin='normal'
            />
          </Box>
        )}
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        {activeStep < 1 ? (
          <Button
            onClick={handleNext}
            variant='contained'
            fullWidth
            sx={{
              background: 'linear-gradient(45deg, #1565c0,  #99ccff, #1565c0)',
              color: '#fff',
              padding: '10px 20px',
              fontSize: '16px',
              borderRadius: '8px',
              '&:hover': {
                background: 'linear-gradient(45deg, #090979, #99ccff, #090979)',
              },
            }}
          >
            Register Now
          </Button>
        ) : (
          <Button
            onClick={handleSubmit}
            variant='contained'
            fullWidth
            sx={{
              background: 'linear-gradient(45deg, #1565c0,  #99ccff, #1565c0)',
              color: '#fff',
              padding: '10px 20px',
              fontSize: '16px',
              borderRadius: '8px',
              '&:hover': {
                background: 'linear-gradient(45deg, #090979, #99ccff, #090979)',
              },
            }}
          >
            Submit
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default SignupForm;

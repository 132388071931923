import React from 'react';
import GlobalSearch from '../components/LearnerModule/GlobalSearch';

const LearnerModule = () => {
  const columns = [
    {
      Header: 'Learner',
      accessor: 'learner',
    },
    {
      Header: 'Program Name',
      accessor: 'programName',
    },
    {
      Header: 'Mobile Number',
      accessor: 'mobileNumber',
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Last Discussed',
      accessor: 'lastCalled',
    },
    {
      Header: 'Attendance %',
      accessor: 'attendance',
    },
    {
      Header: 'Assignment %',
      accessor: 'assignment',
    },
    {
      Header: 'Cleared TGP Level',
      accessor: 'tqpLevel',
    },
    {
      Header: 'Last Activity',
      accessor: 'lastActivity',
    },
  ];

  return (
    <div>
      <GlobalSearch columns={columns} />
    </div>
  );
};

export default LearnerModule;

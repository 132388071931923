import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  enrollments_levels: [],
};

export const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUserEnrollmentsLevels: (state, action) => {
      state.enrollments_levels = action.payload;
    },
  },
});

export const { setUserEnrollmentsLevels } = userSlice.actions;

export default userSlice.reducer;

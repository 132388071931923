import { Button, FormControl, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { Box } from '@material-ui/system';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import ErrorNotifier from '../../ToastNotifications/ErrorNotifier';
import { fetchAssignmentSubmissions, fetchProgramAssignmentsUrl, fetchProgramEnrollmentsUrl, getHeaders } from '../../../apis/urls';
import StudentSubmissions from './StudentSubmissions';

export default function AssignmentSubmissions() {
    const [assignmentNames, setAssignmentNames] = useState({});
    const [assignments, setAssignments] = useState([]);
    const [studentNames, setStudentNames] = useState({});
    const [studentIdToName, setStudentIdToName] = useState({});
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [selectedAssignment, setSelectedAssignment] = useState("");
    const [selectedStudent, setSelectedStudent] = useState("");
    const [openRow, setOpenRow] = useState(false);
    const handleOpen = () => setOpenRow(true);
    const handleClose = () => setOpenRow(false);
    const [selectedAssignmentId, setSelectedAssignmentId] = useState(null);
    const [selectedStudentId, setSelectedStudentId] = useState(null);
    const currentProgram = useSelector(state => state.programs.currentProgram);

    useEffect(() => {
      if (currentProgram) {
        fetchStudents();
        fetchAssignments();
      }
      return () => {};
    }, [currentProgram]);

    function fetchStudents() {
        axios.get(fetchProgramEnrollmentsUrl(), {
            headers: getHeaders(currentProgram.programId)
        })
        .then((res) => {
            if (!res?.data) {
                setHasError(true);
                setErrorMessage("Could not fetch the learners");
                return;
            }
            const tmpStudentNames = {};
            res.data?.forEach((enrollment, index) => {
                if (enrollment?.user && enrollment?.user?.role === "student") {
                    if (!(enrollment.user?.name in tmpStudentNames)) {
                        tmpStudentNames[enrollment?.user?.name] = [];
                    }
                    tmpStudentNames[enrollment?.user?.name].push(enrollment?.user?.id);
                    studentIdToName[enrollment?.user?.id] = enrollment?.user?.name;
                }
            });
            setStudentIdToName(studentIdToName);
            setStudentNames(tmpStudentNames);
        })
        .catch((err) => {
            console.log(err);
            setHasError(true);
            setErrorMessage(err.response?.data?.message || err.message);
        })
    }

    function fetchAssignments() {
        axios.get(fetchProgramAssignmentsUrl(), {
            headers: getHeaders(currentProgram.programId)
        })
        .then((res) => {
            if (!(res?.data?.assignments)) {
                setHasError(true);
                setErrorMessage("Could not fetch the assignments");
                return;
            }

            const tmpAssignmentNames = {};
            res.data.assignments.forEach((assignment) => {
                if (!(assignment.title in tmpAssignmentNames)) {
                    tmpAssignmentNames[assignment.title] = [];
                }
                tmpAssignmentNames[assignment.title].push(assignment.id);
                assignments[assignment.id] = assignment;
            });
            setAssignmentNames(tmpAssignmentNames);
            setAssignments(assignments);
        })
        .catch((err) => {
            console.log(err);
            setHasError(true);
            setErrorMessage(err.response?.data?.message || err.message);
        })
    }

    const handleStudentSelect = (event) => {
        setSelectedStudent(event.target.value);
    }

    const handleAssignmentSelect = (event) => {
        setSelectedAssignment(event.target.value);
    }

    const handleTableRowClick = (aid, sid, event) => {
        setSelectedStudentId(parseInt(sid, 10));
        setSelectedAssignmentId(aid);
        setOpenRow(true);
    }

    const collectAssignments = (assignName) => {
        const aids = assignmentNames[assignName];
        const results = aids.map((aid, index) => assignments[aid]);
        return results;
    }

    const collectStudents = (stuName) => {
        const sids = studentNames[stuName];
        const results = {};
        sids.forEach((sid, index) => results[sid] = stuName);
        return results
    }

    return (
        <>
            {hasError
            ? <ErrorNotifier message={errorMessage} setHasError={setHasError}/>
            : ""
            }
            <Box sx={{ minWidth: 120, display: 'flex', justifyContent: 'flex-end'}}>
                <FormControl sx={{marginRight: "1rem"}}>
                    <InputLabel id="assignment-name">Assignment Name</InputLabel>
                    <Select
                        labelId='assignment-name'
                        id='assignment-name'
                        value={selectedAssignment}
                        label="Assignment name"
                        onChange={handleAssignmentSelect}
                        sx={{
                            width: "20rem"
                        }}
                    >
                        <MenuItem value="">None</MenuItem>
                        {
                            Object.keys(assignmentNames).map((keyName, index) => (
                                <MenuItem value={keyName} key={index+1}>{keyName}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                
                <FormControl>
                    <InputLabel id="student-name">Student Name</InputLabel>
                    <Select
                        labelId='student-name'
                        id='student-name'
                        value={selectedStudent}
                        label="Student name"
                        onChange={handleStudentSelect}
                        sx={{
                            width: "20rem"
                        }}
                    >
                        <MenuItem value="">None</MenuItem>
                        {
                            Object.keys(studentNames).map((keyName, index) => (
                                <MenuItem value={keyName} key={index+1}>{keyName}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </Box>
            {selectedAssignment === "" && selectedStudent === "" && 
                <TableStructure 
                    assignments={assignments}
                    studentIdToName={studentIdToName}
                    handleTableRowClick={handleTableRowClick}
                />
            }
            {selectedAssignment !== "" && selectedStudent === "" && 
                <TableStructure 
                    assignments={collectAssignments(selectedAssignment)}
                    studentIdToName={studentIdToName}
                    handleTableRowClick={handleTableRowClick}
                />
            }
            {selectedAssignment === "" && selectedStudent !== "" && 
                <TableStructure 
                    assignments={assignments}
                    studentIdToName={collectStudents(selectedStudent)}
                    handleTableRowClick={handleTableRowClick}
                />
            }
            {selectedAssignment !== "" && selectedStudent !== "" && 
                <TableStructure 
                    assignments={collectAssignments(selectedAssignment)}
                    studentIdToName={collectStudents(selectedStudent)}
                    handleTableRowClick={handleTableRowClick}
                />
            }
            {openRow &&
                <StudentSubmissions 
                    openRow={openRow}
                    handleClose={handleClose}
                    selectedAssignmentId={selectedAssignmentId}
                    selectedStudentId={selectedStudentId}
                />
            }
        </>
    );
}

function TableStructure({assignments, studentIdToName, handleTableRowClick}) {
    const totalStudents = Object.keys(studentIdToName).length;
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650, marginBlockStart: "1rem" }}>
                <TableHead>
                    <TableRow>
                        <TableCell>Assignment Name</TableCell>
                        <TableCell >Student Name</TableCell>
                        <TableCell >Deadline</TableCell>
                        <TableCell >Submission</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        assignments.map((assignment, index1) => (
                            <>
                                {
                                    Object.keys(studentIdToName).map((sid, index2) => (
                                        <TableRow
                                            key={index1*totalStudents + index2 + 1}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell>
                                                {assignment.title}
                                            </TableCell>
                                            <TableCell>
                                                {studentIdToName[sid]}
                                            </TableCell>
                                            <TableCell>
                                                {moment(assignment.deadline).format("dddd, MMMM Do YYYY")}
                                            </TableCell>
                                            <TableCell>
                                                <Button onClick={(event) => handleTableRowClick(assignment.id, sid, event)}>
                                                    View
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </>
                        ))
                    }

                </TableBody>
            </Table>
        </TableContainer>
    );
}

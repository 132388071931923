// @ts-nocheck
import { Box } from '@mui/material';
import React from 'react';
import AccountPopover from '../../layouts/shared/AccountPopover';
import HeyCoachLogo from './HeyCoachLogo';

const EfHeader = () => {
  return (
    <Box
      position='static'
      sx={{
        boxShadow: '0',
        width: '100vw',
        padding: 2,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <HeyCoachLogo />
      <AccountPopover />
    </Box>
  );
};

export default EfHeader;

import React from 'react';
import moment from 'moment';
import TypographyText from '../Atoms/TypographyText';

const AttemptDataInDrawer = ({ selectedTest }) => {
  const formattedSubmittedAt = moment(selectedTest.submittedAt).format(
    'MMMM Do, h:mm A'
  );

  return (
    <>
      <TypographyText
        text={formattedSubmittedAt}
        stylingClass='drawer-stats'
      />
      <TypographyText
        text={`${selectedTest.score}`}
        stylingClass='drawer-stats'
      />
      <TypographyText
        text={selectedTest.hasCleared ? 'Pass' : 'Failed'}
        stylingClass={
          selectedTest.hasCleared ? 'pass-result-text' : 'failed-result-text'
        }
      />

      <TypographyText
        text={selectedTest.totalAttempts}
        stylingClass='drawer-stats'
      />
    </>
  );
};

export default AttemptDataInDrawer;

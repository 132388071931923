import {
  MenuItem,
  InputLabel,
  Select,
  FormControl,
  Typography,
  Box,
} from '@mui/material';

export default function SelectFieldComponent({
  field,
  formData,
  handleChange,
  error,
}) {
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl
        fullWidth
        required={field.required}
        error={!!error}
      >
        <InputLabel id={`${field.name}-label`}>{field.label}</InputLabel>
        <Select
          labelId={`${field.name}-label`}
          id={`${field.name}-select`}
          name={field.name}
          value={formData[field.name] || ''}
          onChange={handleChange}
          label={field.label}
        >
          <MenuItem value=''>
            <em>Select {field.label}</em>
          </MenuItem>
          {field.options.map((option, i) => (
            <MenuItem
              key={i}
              value={option}
            >
              {option}
            </MenuItem>
          ))}
        </Select>
        {error && (
          <Typography
            sx={{
              fontSize: '0.75rem',
              marginTop: '3px',
              marginRight: '14px',
              marginLeft: '14px',
              color: '#FF4842',
            }}
          >
            {error}
          </Typography>
        )}
      </FormControl>
    </Box>
  );
}

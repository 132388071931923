// @ts-nocheck
import { Stack, Typography, useTheme } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import TextField from '@mui/material/TextField';
import QuestionDescription from './QuestionDescription';

const options = {
  yes: [
    "I don't know how to get started.",
    'Managing time effectively.',
    'Finding reliable study materials.',
    'Understanding complex technical concepts. ',
    'Unable to get interview calls.',
    'Other',
  ],
  no: [
    'Too busy with other commitments.',
    'No challenges, just need to prioritize it and get started.',
    'Not sure how to start.',
    'Lack of confidence from past tries. ',
    'No access to structured study resources.',
    'Other',
  ],
};

const quest = {
  yes: 'What were the biggest challenges you faced during your interview preparation? ',
  no: ' What were the challenges preventing you from starting your interview preparation?',
};

const ChallengesFacedDuringInterviewPrep = ({
  id,
  response,
  setResponse,
  setShouldAllowNext,
}) => {
  const [showTextfield, setShowTextfield] = useState(false);
  const [otherReason, setOtherReason] = useState('');
  const index = useRef(-1);
  const theme = useTheme();

  useEffect(() => {
    if (!(id in response) || response[id].length === 0) {
      setShouldAllowNext(false);
    }

    if (showTextfield && otherReason === '') {
      setShouldAllowNext(false);
    } else if ((response[id] ?? []).length > 0) {
      setShouldAllowNext(true);
    }
    return () => {};
  }, [response, showTextfield, setOtherReason]);

  const hasTakenStepsToPrepare = (
    response.steps_taken_for_interviews ?? 'Yes'
  ).toLowerCase();

  /**
   * TODO (Lokesh): The logic to handle `setAllowNext` is a bit complex. Find a better way to do the exact same thing.
   */
  const handleClick = (value) => {
    if (value === 'Other') {
      if (showTextfield) {
        setResponse((prev) => {
          const np = prev[id].filter((p) => p !== `Other(${otherReason})`);
          return {
            ...prev,
            [id]: np,
          };
        });
        setOtherReason('');
        index.current = -1;
      } else {
        index.current = (response[id] ?? []).length;
      }
      setShowTextfield((prev) => !prev);
      return;
    }

    if (!(id in response)) {
      setResponse((prev) => ({ ...prev, [id]: [value] }));
    } else if (response[id].includes(value)) {
      setResponse((prev) => {
        const np = prev[id].filter((p) => p !== value);
        return {
          ...prev,
          [id]: np,
        };
      });
    } else {
      setResponse((prev) => {
        const x = { ...prev };
        x[id].push(value);
        return x;
      });
    }
    setShouldAllowNext(true);
  };

  const handleOtherFieldChange = (e) => {
    const { value } = e.target;
    setOtherReason(value);
    setResponse((prev) => {
      const x = { ...prev };
      if (!(id in x)) {
        x[id] = [];
      }

      x[id][index.current] = `Other(${value})`;
      return x;
    });
    setShouldAllowNext(true);
  };

  const shouldHighlight = (val) => {
    if (val === 'Other' && showTextfield) {
      return true;
    }

    return response[id] && response[id].includes(val);
  };

  return (
    <Stack spacing={2}>
      <>
        <QuestionDescription title={quest[hasTakenStepsToPrepare]} />
        <Typography
          variant='body1'
          component='p'
          sx={{
            fontWeight: 400,
            fontFamily: 'Inter',
          }}
        >
          <i>(multi-select)</i>
        </Typography>
      </>

      <Stack spacing={1}>
        {options[hasTakenStepsToPrepare].map((opt, idx) => {
          return (
            <Typography
              variant='body1'
              component='p'
              sx={{
                borderRadius: '10px',
                padding: '5px',
                background: 'white',
                width: 'fit-content',
                cursor: 'pointer',
                fontSize: '1.2rem',
                fontFamily: 'Inter',
                ...(shouldHighlight(opt) && {
                  border: '2px solid blue',
                }),
                [theme.breakpoints.down('sm')]: {
                  fontSize: '0.9rem',
                },
              }}
              onClick={() => handleClick(opt)}
            >
              {String.fromCharCode(65 + idx)}. &nbsp; {opt}
            </Typography>
          );
        })}

        {showTextfield && (
          <TextField
            variant='outlined'
            value={otherReason}
            onChange={handleOtherFieldChange}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default ChallengesFacedDuringInterviewPrep;

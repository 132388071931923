import React from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import LoadingButton from '@mui/lab/LoadingButton';
import EastIcon from '@mui/icons-material/East';
import {
  recursionExample,
  stackFrameInformation,
} from '../../utils/techniqueSagaConstant';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

// components
import ParagraphBox from '../BitManipulation/ParagraphBox';
import ListRenderComponent from '../BitManipulation/ListRenderComponent';
import CodeBlockComponent from './CodeBlockComponent';

const Introduction = ({
  handlePostCompletedTopic,
  hasMarkedCompleted,
  loading,
  topic_id,
  handleNext,
}) => {
  return (
    <Box
      display='flex'
      flexDirection='column'
      gap='20px'
      paddingY={2}
      maxWidth='calc(100% - 300px)'
    >
      <Box>
        <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
          TECHNIQUE 3
        </Typography>

        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='center'
        >
          <Typography
            sx={{
              fontSize: '48px',
              fontWeight: 600,
              lineHeight: '58px',
              letterSpacing: '0.01em',
              textAlign: 'left',
            }}
          >
            Recursion
          </Typography>
          {hasMarkedCompleted(topic_id) && (
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '4px',
                color: 'green',
                fontWeight: '500',
                border: '2px solid green',
                padding: '4px 10px',
                borderRadius: '20px',
                cursor: 'default',
              }}
            >
              Completed
            </Typography>
          )}
        </Box>
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        gap='24px'
      >
        <Typography sx={{ fontSize: '22px', fontWeight: 400 }}>
          Introduction
        </Typography>
        <ParagraphBox>
          <Typography>
            Recursion is a process where a function calls itself, either
            directly or indirectly. A function that uses this approach is called
            a recursive function. Recursion is especially useful for solving
            problems that can be broken down into smaller, similar subproblems.
            However, simply thinking of recursion as "a function calling itself"
            isn't the most helpful way to understand its purpose.
          </Typography>
          <Typography>
            A recursive function works by solving a smaller version of the
            original problem with each call. These smaller problems keep getting
            reduced until they reach a point where the solution is simple and
            direct. This point is called the base case. The base case is crucial
            because it provides a stopping condition for the recursive process,
            preventing it from continuing indefinitely.
          </Typography>
        </ParagraphBox>
        <ParagraphBox>
          <Typography>
            Let’s explore this with an example: Suppose you're asked to find the
            factorial of a number n.
          </Typography>
          <ListRenderComponent data={recursionExample} />
          <Typography>
            The base case here is when n equals 1 (or 0), where the answer is
            already known.
          </Typography>
        </ParagraphBox>
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        gap='20px'
      >
        <Typography>
          Let us see how we can implement the approach that we talked about.
        </Typography>
        <CodeBlockComponent
          code={`factorial(n):
    if n is 0 or 1:      //Base Case
        return 1
    else:
        return n * factorial(n - 1)      //Solve for smaller subproblem
`}
        />
      </Box>

      <ParagraphBox>
        <Typography sx={{ fontSize: '22px', fontWeight: 400 }}>
          Need of Recursion
        </Typography>
        <Box
          display='flex'
          flexDirection='column'
          gap='10px'
        >
          <Typography>
            The problem that we discussed earlier about finding the factorial of
            a number, for that problem we could have done this with an iterative
            approach as well using loops. However, there are reasons why
            recursion is still valuable even when an iterative approach exists.
            Let's explore these points:
          </Typography>
        </Box>
        <Typography
          sx={{
            fontSize: '18px',
            fontWeight: '600',
          }}
        >
          1. Key Role in Simplifying Complex Problems
        </Typography>
        <Typography>
          Recursion provides an intuitive way to solve complex problems by
          breaking them down into smaller, more manageable subproblems. By
          working on these simpler cases first, recursion allows you to
          gradually build up the solution to the entire problem. This
          step-by-step decomposition often makes complex tasks easier to reason
          about and solve. Recursion supports the "divide-and-conquer" approach,
          where a problem is split into smaller parts, solved independently, and
          then combined to form the final solution.
        </Typography>

        <Typography
          sx={{
            fontSize: '18px',
            fontWeight: '600',
          }}
        >
          2. Improving code readability
        </Typography>
        <Typography>
          While iterative solutions may involve managing complex loops and
          auxiliary data structures, recursion can often reduce the amount of
          boilerplate code. The recursive function manages the state
          automatically through function calls, keeping the solution clean and
          less error-prone.
        </Typography>
        <Typography>
          Recursion, although not always the most efficient in terms of memory,
          can offer elegance and clarity in problem-solving that iterative
          solutions may lack in certain scenarios.
        </Typography>

        <Typography
          sx={{
            fontSize: '18px',
            fontWeight: '600',
          }}
        >
          3. Alignment with Problem Structure
        </Typography>
        <Typography>
          Certain problems, like those involving trees, graphs, or other
          recursive data structures, are more naturally solved using recursion.
          In such cases, a recursive solution closely mirrors the structure of
          the problem, making it easier to design and implement.
        </Typography>
      </ParagraphBox>

      <ParagraphBox>
        <Typography
          sx={{ fontSize: '22px', fontWeight: 400, marginTop: '20px' }}
        >
          Recursion Stack
        </Typography>

        <Typography>
          In any recursive function, when a function calls itself, the system
          needs to remember where it left off in the current call so that it can
          resume execution once the recursive call finishes. This "memory" is
          managed through a data structure known as the recursion stack.
        </Typography>
        <Typography>
          Each time a function is called, the system pushes a new stack frame
          onto the stack. This frame contains information such as:
        </Typography>
        <ListRenderComponent data={stackFrameInformation} />
      </ParagraphBox>

      <ParagraphBox>
        <Typography
          sx={{ fontSize: '22px', fontWeight: 400, marginTop: '20px' }}
        >
          How it Works
        </Typography>
        <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>
          1. Push Stack Frame :
        </Typography>
        <Typography>
          When a recursive function is called, a new frame with the current
          function's state is pushed onto the stack.
        </Typography>

        <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>
          2. Pop Stack Frame :
        </Typography>
        <Typography>
          Once the recursive function finishes execution (i.e., reaches the base
          case or a return statement), the function pops its stack frame off the
          stack, and the control returns to the previous function call.
        </Typography>
      </ParagraphBox>

      <ParagraphBox>
        <Typography>
          Although recursive solutions are cleaner and easier to read but they
          can also be tricky to understand and write. A great way to approach a
          recursive solution is to define very straightforward steps to the
          solution, let us take an example: Given a number ‘n’ print all the
          natural numbers from 1 to n but in reverse order.
        </Typography>
        <Typography>
          So what you will do essentially is count till N and then start
          printing from there until you reach 0. Now let us start putting that
          in code.
        </Typography>

        <CodeBlockComponent
          code={`function natural_numbers(int n, int i){
   
    //Stop when counting reaches n    
    if(i>n){ 
        return;
    }
    
    natural_numbers(n,i+1); //Count numbers
    print(i); //Print the number
}
`}
        />
        <Typography>
          Let us see its call stack to understand better: let’s say the value of
          n was 4 then our stack would look something like this.
        </Typography>
        <img src='/static/technique-saga/img1.png' />
        <Typography>
          Since we counted first and then we printed we started printing from n
          all the way to 1. Output would be 4 3 2 1.
        </Typography>
      </ParagraphBox>

      <ParagraphBox>
        <Typography
          sx={{ fontSize: '22px', fontWeight: 400, marginTop: '20px' }}
        >
          Stack Overflow
        </Typography>
        <Typography>
          Stack overflow errors in recursion occur when the recursive function
          calls consume more stack space than is available. This typically
          happens when the base case of the recursion is not reached or not
          properly defined, leading to an infinite recursion loop. That’s why
          it's very important to define good base cases.
        </Typography>
        <Typography
          sx={{ fontSize: '22px', fontWeight: 400, marginTop: '20px' }}
        >
          Steps to write a recursive code
        </Typography>
        <Typography>
          The algorithmic steps for implementing recursion in a function are as
          follows:
        </Typography>
        <Typography>
          <b>Step 1: </b>Define a base case: Identify the simplest case for
          which the solution is known or trivial. This is the stopping condition
          for the recursion, as it prevents the function from infinitely calling
          itself.
        </Typography>
        <Typography>
          <b>Step 2: </b>Define a recursive case: Define the problem in terms of
          smaller subproblems. Break the problem down into smaller versions of
          itself, and call the function recursively to solve each subproblem.
        </Typography>
        <Typography>
          <b>Step 3: </b>Ensure the recursion terminates: Make sure that the
          recursive function eventually reaches the base case, and does not
          enter an infinite loop.
        </Typography>
        <Typography>
          <b>Step 4: </b>Combine the solutions: Combine the solutions of the
          subproblems to solve the original problem.
        </Typography>
        <Typography sx={{ marginTop: '10px' }}>
          Let us try to understand it with a classic example
        </Typography>
      </ParagraphBox>

      <ParagraphBox>
        <Typography
          sx={{ fontSize: '22px', fontWeight: 400, marginTop: '20px' }}
        >
          Find the nth fibonacci number
        </Typography>
        <Typography>
          Given a number ‘n’, find the nth number in the fibonacci series. A
          fibonacci series is formed by adding the previous two numbers where
          the series starts with the first two numbers as 1 Below is a fibonacci
          series:
        </Typography>
        <Typography>1, 1, 2, 3, 5, 8....</Typography>
        <Typography>
          Let us try to solve this problem recursively using the steps that we
          discussed:
        </Typography>

        <Typography
          sx={{ fontSize: '18px', fontWeight: 600, marginTop: '10px' }}
        >
          Step 1: Identify the Recursive Relationship
        </Typography>
        <Typography>
          We need to identify how the solution to the bigger problem (nth
          Fibonacci number) depends on smaller subproblems. The Fibonacci number
          at position n is formed by adding the previous two Fibonacci numbers:
        </Typography>
        <Typography>
          nth element = (n-1)th element + (n-2)th element , where n&gt;2.
        </Typography>

        <Typography
          sx={{ fontSize: '18px', fontWeight: 600, marginTop: '10px' }}
        >
          Step 2: Define the Base Case
        </Typography>
        <Typography>
          The base case is the simplest form of the problem where the solution
          is known. In this case, the 1st and 2nd Fibonacci numbers are both 1,
          so we can write the code as follows:
        </Typography>
        <CodeBlockComponent
          code={`Func fib(int n){

    //Base case defined
    if(n==1 OR n==2){
        Return 1;
    }
}

`}
        />

        <Typography
          sx={{ fontSize: '18px', fontWeight: 600, marginTop: '10px' }}
        >
          Step 3: Solve the Smaller Subproblems
        </Typography>
        <Typography>
          Once we have the base case, the next step is to solve the smaller
          subproblems. We need to call the function recursively to find the
          (n-1)th and (n-2)th Fibonacci numbers:
        </Typography>
        <CodeBlockComponent
          code={`
Func fib(int n){

    //Base case defined
    if(n==1 OR n==2){
        Return 1;
    }

    //Solution to the smaller sub-problem
    Int smaller_output1 = fib(n-1)
    Int smaller_output2 = fib(n-2)
}

`}
        />
        <Typography>
          In this case we needed the solution to two smaller subproblems so the
          function is called twice.
        </Typography>

        <Typography
          sx={{ fontSize: '18px', fontWeight: 600, marginTop: '10px' }}
        >
          Step 4: Combine the Solutions of Smaller Subproblems
        </Typography>
        <Typography>
          Now that we have the solution to the smaller subproblems, we combine
          them to find the larger solution, i.e., the nth Fibonacci number:
        </Typography>
        <CodeBlockComponent
          code={`Func fib(int n){

    //Base case defined
    if(n==1 OR n==2){
        Return 1;
    }

    //Solution to the smaller sub-problem
    Int smaller_output1 = fib(n-1)
    Int smaller_output2 = fib(n-2)

    //Combine the solution of smaller sub-problems
    Int larger_output = smaller_output1 + smaller_output2
    Return larger_output
}

`}
        />
        <Typography>
          This is a simple example of recursion where we repeatedly break down
          the problem into smaller subproblems, solve them, and use the
          solutions to solve the original problem
        </Typography>
      </ParagraphBox>

      <ParagraphBox>
        <Typography
          sx={{ fontSize: '18px', fontWeight: 400, marginTop: '10px' }}
        >
          Time Complexity
        </Typography>
        <Typography>
          The time complexity of the provided code is exponential, specifically
          O(2^n). This is because the recursive function fib makes two recursive
          calls for each call, leading to an exponential growth in the number of
          function calls.
        </Typography>
        <Typography>
          To understand it better take a look at this recursion tree. A
          recursion tree is a graphical representation used to visualize the
          execution of a recursive algorithm. It helps to understand how the
          recursive function calls are made and how the subproblems are broken
          down at each level of recursion.
        </Typography>
        <Typography>
          To understand this, let's consider the recursion tree for fib(6)
        </Typography>
        <img src='/static/technique-saga/img2.png' />
      </ParagraphBox>

      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        alignSelf='end'
        justifyContent='space-between'
        gap='12px'
      >
        {!hasMarkedCompleted(topic_id) && (
          <LoadingButton
            variant='contained'
            onClick={() => handlePostCompletedTopic(topic_id)}
            loading={loading}
            loadingPosition='center'
            children='Mark as completed'
            style={{
              width: '170px',
              // backgroundColor: 'transparent',
              borderRadius: '8px',
              border: '1px solid rgba(64, 96, 245, 0.5)',
            }}
          />
        )}

        <Box
          display='flex'
          alignItems='center'
          gap='8px'
        >
          <Button
            sx={{ gap: '4px' }}
            onClick={handleNext}
          >
            Next <EastIcon />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Introduction;

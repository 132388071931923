import React, { useState } from 'react';
import { Box, Button, Typography, Tabs, Tab } from '@material-ui/core';
import LoadingButton from '@mui/lab/LoadingButton';
import VerifiedIcon from '@mui/icons-material/Verified';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import CustomTabPanel from '../CustomTabPanel';
import TopicTitle from '../TopicTitle';
import ListRenderComponent from '../ListRenderComponent';
import CustomButton from '../../../practice/MindMaps/Atoms/CustomButton';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import PointerArrayOne from '../../../../images/PointerArrayOne.png';
import PointerArrayTwo from '../../../../images/PointerArrayTwo.png';
import PointerArrayThree from '../../../../images/PointerArrayThree.png';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const TwoSum = ({
  handlePostCompletedTopic,
  hasMarkedCompleted,
  loading,
  topic_id,
  handleNext,
  handlePrevious,
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap='36px'
      paddingY={2}
      maxWidth='calc(100% - 300px)'
    >
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
      >
        <TopicTitle>3. Two Sum</TopicTitle>
        {hasMarkedCompleted(topic_id) && (
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              color: 'green',
              fontWeight: '500',
              border: '2px solid green',
              padding: '4px 10px',
              borderRadius: '20px',
              cursor: 'default',
            }}
          >
            Completed
          </Typography>
        )}
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        gap='24px'
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            width: 'fit-content',
            background: '#FEF7FF',
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
          >
            <Tab
              label='Problem Statement'
              {...a11yProps(0)}
            />
            <Tab
              label='Brute Force Approach'
              {...a11yProps(1)}
            />
            <Tab
              label='Two Pointer Approach'
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <CustomTabPanel
          value={value}
          index={0}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Typography>
              Given a 1-indexed array of integers numbers that is already sorted
              in non-decreasing order, find two numbers such that they add up to
              a specific target, if it is not present return -1.
            </Typography>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={1}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Typography>
              A straightforward way of finding that out would be to check every
              possible pair that is present in the array. We can have two nested
              loops. The outer loop iterates through each element of the array
              with index i. The inner loop starts from the element next to i
              (index j = i + 1) and goes until the end of the array. This
              ensures that every combination of two different elements in the
              array is checked.
            </Typography>

            <Box
              sx={{
                backgroundColor: 'black',
                padding: '24px',
                borderRadius: '10px',
                width: '705px',
                overflowX: 'auto',
              }}
            >
              <pre style={{ color: 'white', margin: 0 }}>
                {`function twoSum_bf(numbers, target):
    len = length of numbers
    for i from 0 to len - 1:
        for j from i + 1 to len - 1:
            if numbers[i] + numbers[j] equals target:
                return true

    return false
`}
              </pre>
            </Box>

            <Typography>
              Since we are checking every possible pair for which we have two
              nested loops and in the worst case we will have a time complexity
              of n^2 and no extra space is required for this.
            </Typography>

            <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>
              Time and Space Complexity
            </Typography>

            <Typography>Time Complexity : O(n^2)</Typography>

            <Typography>Time Complexity : O(1)</Typography>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={2}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Typography>
              For this problem one pointer will start at the beginning, called
              left, and another at the far end, called right. The trick here is
              using the fact that the array is sorted, meaning if the sum of
              arr[left] + arr[right]is bigger than the target, we’ll move the
              right pointer one step to the left, and a smaller number. If it’s
              smaller than the target we move the left pointer to the right, and
              a bigger number.
            </Typography>

            <Typography>
              For example : Consider the array {(2, 5, 7, 11)} and suppose we
              want to find the sum as 12. We can place the left pointer at the
              first index that is at and the right pointer at the last index
              that is 11.
            </Typography>

            <img
              src={PointerArrayOne}
              alt=''
              style={{ height: '70px', width: '400px', text: 'center' }}
            />
            <Typography>
              Since 2 + 11 = 13, where 13 &gt; 12, thus meaning we need to
              reduce the sum so we will move the right pointer.
            </Typography>
            <img
              src={PointerArrayTwo}
              alt=''
              style={{ height: '70px', width: '400px', text: 'center' }}
            />
            <Typography>
              Now, 2 + 7 = 9, where 9 &lt; 12 meaning we need to increase our
              sum so this time we will increase our left pointer.
            </Typography>
            <img
              src={PointerArrayThree}
              alt=''
              style={{ height: '70px', width: '400px', text: 'center' }}
            />
            <Typography>
              As you can see, 5 + 7 = 12, and thus we found our sum.
            </Typography>

            <Typography>
              You will observe that both the pointers move in their respective
              directions and will never go back meaning we are never repeating
              ourselves which leads to an optimal complexity.
            </Typography>

            <Box
              sx={{
                backgroundColor: 'black',
                padding: '24px',
                borderRadius: '10px',
                width: '583px',
              }}
            >
              <pre style={{ color: 'white', margin: 0 }}>
                {`left = 0
right = length of arr - 1

while left <= right:
    if arr[left] + arr[right] equals target:
        return [left + 1, right + 1]
    else if arr[left] + arr[right] > target:
        decrease right by 1
    else:
        increase left by 1

return False
`}
              </pre>
            </Box>

            <Box>
              <Typography>
                Your while is the one that is majorly contributing to the time
                complexity of the code. If you observe both right and left
                pointers will keep on moving in the same direction meaning they
                never repeat themselves. Also, in the worst case one of these
                two pointers will have to traverse through the whole array to
                find the required element or no pair of elements at all which
                will be in order of n. Again no extra space is being used.
              </Typography>

              <Typography
                sx={{ fontSize: '18px', fontWeight: 600, marginTop: '20px' }}
              >
                Time and Space Complexity
              </Typography>

              <Typography sx={{ margin: '10px 0' }}>
                Time Complexity : O(n)
              </Typography>

              <Typography>Space Complexity : O(1)</Typography>
            </Box>
          </Box>
        </CustomTabPanel>
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        alignSelf='end'
        justifyContent='space-between'
        gap='12px'
      >
        {!hasMarkedCompleted(topic_id) && (
          <LoadingButton
            variant='contained'
            onClick={() => handlePostCompletedTopic(topic_id)}
            loading={loading}
            loadingPosition='center'
            children='Mark as completed'
            style={{
              width: '170px',
              // backgroundColor: 'transparent',
              borderRadius: '8px',
              border: '1px solid rgba(64, 96, 245, 0.5)',
            }}
          />
        )}

        <Box
          display='flex'
          alignItems='center'
          gap='8px'
        >
          <Button
            sx={{ gap: '4px' }}
            onClick={handlePrevious}
          >
            <WestIcon /> Previous
          </Button>

          <Button
            sx={{ gap: '4px' }}
            onClick={handleNext}
          >
            Next <EastIcon />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default TwoSum;

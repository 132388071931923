import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const CircularProgressWithLabel = ({ value }) => {
  return (
    <Box
      sx={{ position: 'relative', display: 'inline-flex', marginLeft: 'auto' }}
    >
      <CircularProgress
        variant='determinate'
        value={100}
        size={50}
        thickness={4}
        sx={{
          '& .MuiCircularProgress-circle': {
            color: '#a09e9e',
          },
          top: 0,
          right: 0,
          position: 'absolute',
          strock: '#a09e93',
        }}
      />
      <CircularProgress
        variant='determinate'
        value={value}
        size={50}
        thickness={4}
        sx={{
          '& .MuiCircularProgress-circle': {
            backgroundColor: 'blue',
          },
        }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant='caption'
          component='div'
          color='inherit'
        >
          {!Number.isNaN(value) ? `${Math.round(value)}%` : '0%'}
        </Typography>
      </Box>
    </Box>
  );
};

export default CircularProgressWithLabel;

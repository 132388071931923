// @ts-nocheck
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { Icon } from '@iconify/react';
import VideocamIcon from '@mui/icons-material/Videocam';
import AddToDriveIcon from '@mui/icons-material/AddToDrive';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import ChatIcon from '@mui/icons-material/Chat';
import { Badge } from '@mui/material';
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  Chip,
} from '@material-ui/core';
import AccountPopover from '../shared/AccountPopover';
import ErrorNotifier from '../../components/ToastNotifications/ErrorNotifier';

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  position: 'sticky', // Make AppBar sticky
  top: 0,
  borderRadius: '20px',
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(1, 5, 1, 5),
  },
}));

function OnboardingDashboardNavbar({ onOpenSidebar }) {
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  return (
    <RootStyle>
      <ToolbarStyle>
        {hasError ? (
          <ErrorNotifier
            message={errorMessage}
            setHasError={setHasError}
          />
        ) : (
          ''
        )}
        <Box sx={{ flexGrow: 1 }} />
        <Stack
          direction='row'
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}

export default OnboardingDashboardNavbar;

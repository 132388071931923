// @ts-nocheck
import { Stack, Typography } from '@material-ui/core';
import React from 'react';
import MarkdownEditor from '../../../MarkdownEditor/MarkdownEditor';
import { Box } from '@mui/material';

export default function ProblemDescription({ selectedProblem }) {
  return (
    <Stack spacing={1}>
      <Typography variant='h4'>{selectedProblem.title}</Typography>
      <Box>
        <MarkdownEditor
          content={selectedProblem.description}
          setContent={() => {}}
        />
      </Box>
    </Stack>
  );
}

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import axios from 'axios';
import { getHeaders, fetchLearnersOnFilters } from '../../apis/urls';
import LoadingScreen from '../../pages/LoadingScreen';
import TableView from './Modules/TableView';
import Filters from './Modules/Filters';
import Pagination from './Atoms/Pagination';
import { transformLearnerData } from './LearnerModuleUtil';

function GlobalSearch({ columns }) {
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage] = useState(10); // Fixed number of entries per page
  const [queryParams, setQueryParams] = useState([]);
  const [totalEntries, setTotalEntries] = useState(0);
  const [sortField, setSortField] = useState('lastCalled');
  const [sortOrder, setSortOrder] = useState('desc'); // asc or desc
  const [totalPages, setTotalPages] = useState(0);

  const allPrograms = useSelector((state) => state.programs.programs);

  const fetchLearners = (params, nextPage = 1) => {
    setLoading(true);
    const query = [...params, `per_page=${perPage}`, `page=${nextPage}`];

    axios
      .get(fetchLearnersOnFilters(query), {
        headers: getHeaders(),
      })
      .then((res) => {
        const transformedData = transformLearnerData(res.data.data);
        setFilteredData(transformedData);
        setLoading(false);
        setTotalEntries(res.data.total);
        setTotalPages(Math.ceil(res.data.total / perPage));
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleApplyFilters = (filters) => {
    setQueryParams(filters);
    setPage(1); // Reset to the first page when filters are applied
    fetchLearners(filters);
  };

  const handleClearFilters = () => {
    setQueryParams([]);
    setFilteredData([]);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
    fetchLearners(queryParams, newPage); // Re-apply filters with the new page
  };

  const total_pages = Math.ceil(totalEntries / perPage);
  const hasNextPage = page < total_pages;

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap={3}
    >
      <LoadingScreen loading={loading} />
      <Filters
        allPrograms={allPrograms}
        onApplyFilters={handleApplyFilters}
        onClearFilters={handleClearFilters}
        sortField={sortField}
        sortOrder={sortOrder}
      />
      {filteredData.length === 0 ? (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
        >
          <Typography
            sx={{
              fontSize: '26px',
              fontWeight: '600',
              lineHeight: '48px',
            }}
          >
            Please select a program from the filters to view its learners.
          </Typography>
        </Box>
      ) : (
        <TableView
          columns={columns}
          data={filteredData}
          setSortField={setSortField}
          setSortOrder={setSortOrder}
          sortField={sortField}
          sortOrder={sortOrder}
        />
      )}
      <Pagination
        page={page}
        onPageChange={handlePageChange}
        hasNextPage={hasNextPage}
        totalPages={totalPages}
      />
    </Box>
  );
}

export default GlobalSearch;

import React, { useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import CodingTestProblemList from './CodingTestProblemList';
import CodingTestDetailedProblem from './CodingTestDetailedProblem';

export default function CodingTestDetails({
  allContestProblems,
  selectedTest,
  isTestInProgress,
}) {
  const [showDetailedProblem, setShowDetailedProblem] = useState(false);
  const [selectedProblem, setSelectedProblem] = useState(null);

  const handleProblemSelect = (event, problem) => {
    setSelectedProblem({
      ...problem,
      testId: selectedTest?.testId,
    });
    setShowDetailedProblem(true);
  };

  if (!allContestProblems?.length) {
    return (
      <Typography
        variant='h5'
        textAlign='center'
      >
        No problems have been added.
      </Typography>
    );
  }

  return (
    <Box>
      {!showDetailedProblem && (
        <CodingTestProblemList
          allContestProblems={allContestProblems}
          handleProblemSelect={handleProblemSelect}
        />
      )}

      {showDetailedProblem && Object.keys(selectedProblem).length > 0 && (
        <CodingTestDetailedProblem
          selectedProblem={selectedProblem}
          setShowDetailedProblem={setShowDetailedProblem}
          selectedTest={selectedTest}
          isTestInProgress={isTestInProgress}
        />
      )}
    </Box>
  );
}

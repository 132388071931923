import { List, ListItemButton, ListItemIcon, ListItemText } from '@material-ui/core'
import React from 'react'

const SideBar = ({
  label,
  Icon,
  selectedIndex,
  handleListItemClick,
  index
}) => {
    return (
      <div style={{ padding: 2 }}>
        <List component="nav" aria-label="main mailbox folders">
          <ListItemButton
            selected={selectedIndex === index}
            onClick={() => handleListItemClick(index)}
          >
            {Icon && (
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
            )}
            <ListItemText >
                {label}
            </ListItemText>
          </ListItemButton>
        </List>
      </div>
    )
}

export default SideBar
import React, { useState, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import axios from 'axios';
import { useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import {
  fetchAllPlacementRequests,
  fetchAllRoleTechStacks,
  getHeaders,
} from '../../../apis/urls';
import ErrorNotifier from '../../ToastNotifications/ErrorNotifier';

const ContactDetailsRequested = ({ selectedFilter }) => {
  const [roleTechStacks, setRoleTechSTacks] = useState([]);
  const [allCompaniesData, setAllCompaniesData] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isGettingData, setIsGettingData] = useState(false);
  const currentProgram = useSelector(
    (state) => state?.programs?.currentProgram
  );

  useEffect(() => {
    getAllCompanyDetailsDetailsFromPlacementRequests();
    getAllRoleTechStacks();
    return () => {};
  }, [selectedFilter]);

  const getAllRoleTechStacks = () => {
    axios
      .get(fetchAllRoleTechStacks(), {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((res) => {
        if (!res.data) {
          return;
        }
        setRoleTechSTacks(res?.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getAllCompanyDetailsDetailsFromPlacementRequests = () => {
    setIsGettingData(true);
    axios
      .get(fetchAllPlacementRequests(), {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((res) => {
        const liveCompanies = res?.data?.filter(
          (request) =>
            request.company_role.job_status === 'live' &&
            request.company_role.approval_status === 'approved' &&
            request.status === 'requested'
        );
        setAllCompaniesData(liveCompanies || []);
        setIsGettingData(false);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage('Error while getting companies');
        setIsGettingData(false);
      });
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      <Box
        sx={{
          padding: '30px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            padding: '0 10px',
            gap: '20px',
            flexWrap: 'wrap',
            position: 'relative',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              textAlign: 'center',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            {isGettingData && <CircularProgress />}
          </Box>
          {!isGettingData && allCompaniesData.length === 0 ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                textAlign: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography sx={{ fontSize: '40px', color: '#b0b0b0' }}>
                No details requested
              </Typography>
            </Box>
          ) : (
            allCompaniesData.map((company) => (
              <Box
                sx={{
                  boxShadow: 3,
                  borderRadius: '10px',
                  width: '430px',
                  border: '1px solid #D2D2D2',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '15px',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row-reverse',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Box
                      component='img'
                      alt='logo'
                      src={company?.logo}
                      sx={{
                        width: '94px',
                        height: '74px',
                        backgroundColor: '#D2D2D2',
                        borderRadius: '5px',
                      }}
                    />
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        textAlign: 'center',
                        alignItems: 'start',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'start',
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: '28px',
                            fontWeight: '600',
                            color: '#4F4F4F',
                          }}
                        >
                          {company.company_role.role_title}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'start',
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: '22px',
                            fontWeight: '500',
                            color: '#4F4F4F',
                          }}
                        >
                          {company.company_role.company.name}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'start',
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: '18px',
                            fontWeight: '400',
                            color: '#4F4F4F',
                          }}
                        >
                          {company.company_role.location} |{' '}
                          {company.company_role.job_type}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '20px',
                      padding: '20px 0 10px 0',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontWeight: '900px',
                        color: '#4F4F4F',
                      }}
                    >
                      Tech Stack
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '10px',
                    }}
                    key={company.id}
                  >
                    {roleTechStacks
                      .filter(
                        (techStack) => techStack.company_role_id === company.id
                      )
                      .slice(0, 5)
                      .map((filteredTechStack, index) => (
                        <Typography
                          key={filteredTechStack.id}
                          sx={{
                            backgroundColor: '#D2E7FF',
                            padding: '7px',
                            fontSize: '13px',
                            borderRadius: '30px',
                            width: 'fit-content',
                          }}
                        >
                          {filteredTechStack.tech_stack.name}
                        </Typography>
                      ))}
                    {roleTechStacks
                      .filter(
                        (techStack) => techStack.company_role_id === company.id
                      )
                      .slice(5).length > 0 && (
                      <Typography
                        sx={{
                          fontSize: '13px',
                          borderRadius: '30px',
                          width: 'fit-content',
                          fontWeight: '700',
                          color: '#424242',
                          justifyContent: 'center',
                          textAlign: 'center',
                          alignItems: 'center',
                          display: 'flex',
                          flexDirection: 'row',
                        }}
                      >
                        +
                        {
                          roleTechStacks
                            .filter(
                              (techStack) =>
                                techStack.company_role_id === company.id
                            )
                            .slice(5).length
                        }{' '}
                        more
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    padding: '0 20px 20px 0',
                  }}
                >
                  <Box
                    sx={{
                      padding: '5px 10px',
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '20px',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '18px',
                        fontWeight: '900px',
                        color: '#1976D2',
                      }}
                    >
                      <strong>
                        CTC {company.company_role.min_ctc} -{' '}
                        {company.company_role.max_ctc} LPA
                      </strong>
                    </Typography>
                  </Box>

                  <Button
                    sx={{
                      backgroundColor: '#1976D2',
                    }}
                    variant='contained'
                    disabled
                  >
                    Details requested
                  </Button>
                </Box>
              </Box>
            ))
          )}
        </Box>
      </Box>
    </>
  );
};

export default ContactDetailsRequested;

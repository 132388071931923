// @ts-nocheck
import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import ChatList from './ChatList';
import { getUserType } from '../../configs/auth';
import IndividualChat from './IndividualChat';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';

export const isStudent = getUserType() === 'student';
export const isAdmin = getUserType() === 'admin';
export const isProgramManager = getUserType() === 'program_manager';
export const shouldShowChatList = isAdmin || isProgramManager;
export const shouldShowFilters = isAdmin || isProgramManager;

const RenderChatContent = ({ chatList, selectedChat, searchType }) => {
  if (isStudent && chatList.length > 1) {
    return (
      <Box
        display='flex'
        justifyContent='center'
        mt={10}
      >
        <Typography variant='h4'>
          Multiple chats were detected, please reach out to the program
          management team.
        </Typography>
      </Box>
    );
  }

  if (selectedChat) {
    return (
      <IndividualChat
        chat={selectedChat}
        searchType={searchType}
      />
    );
  }

  if (isStudent) {
    return <></>;
  }

  return (
    <Box
      display='flex'
      justifyContent='center'
      mt={10}
    >
      <Typography variant='h4'>Select a chat to begin</Typography>
    </Box>
  );
};

function ChatDashboard() {
  const [selectedChat, setSelectedChat] = useState(null);
  const [chatList, setChatList] = useState([]);
  const [searchType, setSearchType] = useState('chats');

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100vh',
        }}
      >
        <Box
          display='flex'
          sx={{
            height: '95vh',
            boxShadow: '5',
            borderRadius: '5px',
            width: '90%',
          }}
        >
          <Box
            {...(shouldShowChatList && {
              sx: {
                flexGrow: 1,
                flexBasis: '30%',
                borderRight: '5px solid #f0f0f0',
              },
            })}
          >
            <ChatList
              chatList={chatList}
              setChatList={setChatList}
              selectedChat={selectedChat}
              setSelectedChat={setSelectedChat}
              setSearchType={setSearchType}
              searchType={searchType}
            />
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              flexBasis: '90%',
              borderRadious: '20px',
            }}
          >
            <RenderChatContent
              chatList={chatList}
              selectedChat={selectedChat}
              searchType={searchType}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default ChatDashboard;

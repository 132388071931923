import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@material-ui/core';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  deleteEnrollmentUrl,
  fetchProgramEnrollmentsUrl,
  getHeaders,
  postNewEnrollmentURL,
  updateEnrollmentUrl,
} from '../../../apis/urls';
import ErrorNotifier from '../../ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../ToastNotifications/SuccessNotifier';
import LoadingScreen from '../../../pages/LoadingScreen';
import DeleteEnrollment from './DeleteEnrollment';

const isValidEmail = (email) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ // eslint-disable-line
  );
};

export default function Enrollments() {
  const currentProgram = useSelector((state) => state.programs.currentProgram);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [enrollmentsData, setEnrollmentsData] = useState({});
  const [newEnrollment, setNewEnrollment] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (currentProgram) {
      fetchEnrollmentsData();
    }
    return () => {};
  }, [currentProgram]);

  function fetchEnrollmentsData() {
    setLoading(true);
    axios
      .get(fetchProgramEnrollmentsUrl(), {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((res) => {
        setLoading(false);
        const tmpEnrollmentsData = {};
        res.data?.forEach((enrollment, index) => {
          const info = {
            id: enrollment?.id,
            user_id: enrollment?.user?.id,
            name: enrollment?.user?.name,
            email: enrollment?.user?.email,
            role: enrollment?.user?.role,
            program_access: enrollment?.program_access,
            phone:
              enrollment?.user?.phone != null
                ? enrollment?.user?.phone
                : 'Not available',
          };
          tmpEnrollmentsData[enrollment?.id] = info;
        });
        setEnrollmentsData(tmpEnrollmentsData);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setHasError(true);
        setErrorMessage(err.response?.data?.message || err.message);
      });
  }

  const handleChange = (e) => {
    setNewEnrollment(e.target.value);
  };

  const addNewEnrollment = (e) => {
    if (newEnrollment === '' || !isValidEmail(newEnrollment)) {
      setHasError(true);
      setErrorMessage('Please enter a valid email.');
      return;
    }
    axios
      .post(
        postNewEnrollmentURL(),
        {
          email: newEnrollment,
        },
        {
          headers: getHeaders(currentProgram?.programId),
        }
      )
      .then((res) => {
        setNewEnrollment('');
        setIsSuccess(true);
        setSuccessMessage('New enrollment successfully created.');
        fetchEnrollmentsData();
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(err.response?.data?.message || err.message);
        setNewEnrollment('');
      });
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      {isSuccess && (
        <SuccessNotifier
          message={successMessage}
          setIsSuccess={setIsSuccess}
        />
      )}
      <LoadingScreen loading={loading} />

      <Box
        display='flex'
        justifyContent='flex-end'
        sx={{ mb: 2 }}
      >
        <TextField
          label='Enroll user by email'
          variant='standard'
          placeholder='Add email'
          value={newEnrollment}
          onChange={handleChange}
          sx={{
            width: '15rem',
            height: 40,
          }}
        />
        <Button
          variant='contained'
          onClick={addNewEnrollment}
          sx={{ m: 2 }}
        >
          Enroll
        </Button>
      </Box>
      {enrollmentsData && Object.keys(enrollmentsData).length > 0 && (
        <EnrollmentsTableStructure
          enrollments={enrollmentsData}
          setEnrollments={setEnrollmentsData}
          setErrorMessage={setErrorMessage}
          setHasError={setHasError}
          setIsSuccess={setIsSuccess}
          setSuccessMessage={setSuccessMessage}
        />
      )}
    </>
  );
}

function EnrollmentsTableStructure({
  enrollments,
  setEnrollments,
  setHasError,
  setErrorMessage,
  setIsSuccess,
  setSuccessMessage,
}) {
  const currentProgram = useSelector((state) => state.programs.currentProgram);

  const handleChange = (id, filters) => {
    const body = {
      ...filters,
      program_id: currentProgram?.programId,
      user_id: enrollments[id]?.user_id,
    };

    axios
      .patch(updateEnrollmentUrl(id), body, {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((res) => {
        setIsSuccess(true);
        setSuccessMessage(`updated successfully.`);
        setEnrollments({
          ...enrollments,
          [id]: {
            ...enrollments[id],
            ...filters,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(err.response?.data?.message || err.message);
      });
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell align='center'>Enrollment Id</TableCell>
            <TableCell align='center'>Name</TableCell>
            <TableCell align='center'>Email Id</TableCell>
            <TableCell align='center'>Contact</TableCell>
            <TableCell align='center'>Role</TableCell>
            <TableCell align='center'>Program Access</TableCell>
            <TableCell align='center'>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(enrollments).map((id, index) => (
            <TableRow key={index + 1}>
              <TableCell align='center'>{enrollments[id]?.id}</TableCell>
              <TableCell align='center'>
                {enrollments[id]?.name || 'Not available'}
              </TableCell>
              <TableCell align='center'>
                {enrollments[id]?.email || 'Not available'}
              </TableCell>
              <TableCell align='center'>
                {enrollments[id]?.phone || 'Not available'}
              </TableCell>
              <TableCell align='center'>
                <ToggleButtonGroup
                  color='primary'
                  value={enrollments[id].role}
                  exclusive
                  // onChange={(e) => handleChange(id, { role: e.target.value })}
                >
                  <ToggleButton value='student'>Student</ToggleButton>
                  <ToggleButton value='coach'>Coach</ToggleButton>
                  <ToggleButton
                    value='admin'
                    disabled
                  >
                    Admin
                  </ToggleButton>
                  <ToggleButton value='finance'>Finance</ToggleButton>
                  <ToggleButton value='problem_setter'>
                    Problem Setter
                  </ToggleButton>
                  <ToggleButton value='teaching_assistant'>
                    Teaching Assistant
                  </ToggleButton>
                  <ToggleButton value='program_manager'>
                    Program Manager
                  </ToggleButton>
                </ToggleButtonGroup>
              </TableCell>
              <TableCell align='center'>
                <ToggleButtonGroup
                  color='primary'
                  value={enrollments[id].program_access}
                  exclusive
                  onChange={(e) =>
                    handleChange(id, { program_access: e.target.value })
                  }
                >
                  <ToggleButton value='allowed'>Allowed</ToggleButton>
                  <ToggleButton value='disabled'>Disabled</ToggleButton>
                  <ToggleButton value='expired'>Expired</ToggleButton>
                </ToggleButtonGroup>
              </TableCell>
              <TableCell align='center'>
                <DeleteEnrollment
                  {...{
                    enrollments,
                    setEnrollments,
                    id,
                    setHasError,
                    setErrorMessage,
                    setIsSuccess,
                    setSuccessMessage,
                  }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

import React, { useState } from 'react';
import { Box, Button, Tabs, Tab, Typography } from '@material-ui/core';
import LoadingButton from '@mui/lab/LoadingButton';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import CustomTabPanel from '../CustomTabPanel';
import TopicTitle from '../TopicTitle';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import ArraySeven from '../../../../images/ArraySeven.png';
import ArrayEight from '../../../../images/ArrayEight.png';
import ArrayGif from '../../../../images/ArrayGif.gif';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const MoveZeros = ({
  handlePostCompletedTopic,
  hasMarkedCompleted,
  loading,
  topic_id,
  handleNext,
  handlePrevious,
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap='36px'
      paddingY={2}
      maxWidth='calc(100% - 300px)'
    >
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
      >
        <TopicTitle>1. Move Zeros</TopicTitle>
        {hasMarkedCompleted(topic_id) && (
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              color: 'green',
              fontWeight: '500',
              border: '2px solid green',
              padding: '4px 10px',
              borderRadius: '20px',
              cursor: 'default',
            }}
          >
            Completed
          </Typography>
        )}
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        gap='24px'
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            width: '32rem',
            background: '#FEF7FF',
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
          >
            <Tab
              label='Problem Statement'
              {...a11yProps(0)}
            />
            <Tab
              label='Brute Force Approach'
              {...a11yProps(1)}
            />
            <Tab
              label='Two Pointer Approach'
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <CustomTabPanel
          value={value}
          index={0}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Typography></Typography>

            <Box
              display='flex'
              flexDirection='column'
              gap='8px'
            >
              <Typography>
                Given an integer array move all the zeros to the end of the
                array. while maintaining the relative order of the non-zero
                elements. Do this without using any extra space.
              </Typography>

              <Typography>
                For example: arr = [9, 3, 0, 2, 0, 4, 5, 0]
              </Typography>
              <Typography>
                So your array should look like this : [9, 3, 2, 4, 5, 0, 0, 0],
                note that the relative ordering of the other elements is still
                maintained.
              </Typography>
            </Box>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={1}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Typography>
              One straightforward way of doing this would be to pick every
              element that is zero and then shift all the elements after that by
              1 left and then put the element at the last. Do this operation for
              every element that is zero and eventually you will have all the
              zeros at the last. It works in the same way as deletion of
              elements works on arrays.
            </Typography>

            <Typography>
              For example consider the array : {(1, 0, 2, 4, 0, 6)}
            </Typography>

            <Typography>
              Now, according to the brute force way you will one by one send all
              the zeroes to the end
            </Typography>

            <Typography>
              The initial subarray contains the values 1, 3 and 2 whose sum is
              6:
            </Typography>
            <img
              src={ArraySeven}
              alt=''
              style={{ height: '40px', width: '600px', text: 'center' }}
            />
            <Typography>
              Now you will place this zero at the end and move all the other
              elements to the left ( Remember that we have to maintain the
              relative ordering as well.
            </Typography>
            <img
              src={ArrayEight}
              alt=''
              style={{ height: '40px', width: '600px', text: 'center' }}
            />
            <Typography>
              In a similar manner you will do for every zero and eventually you
              will have all the zeroes at the end.
            </Typography>

            <Typography>The code for it is given below:</Typography>

            <Box
              sx={{
                backgroundColor: 'black',
                padding: '24px',
                borderRadius: '10px',
                width: '900px',
              }}
            >
              <pre style={{ color: 'white', margin: 0 }}>
                {`function moveZeroes(nums):
    n = length of nums  
    i = 0
    while i < n:
        if nums[i] == 0:  // Find a zero element
            for j from i+1 to n-1:
                nums[j-1] = nums[j]
            nums[n-1] = 0  // Place zero at the end of the array
            n = n - 1  // Reduce the size of the array to ignore the moved zero
        else:
            i = i + 1  // Increment i only if nums[i] is not zero
`}
              </pre>
            </Box>

            <Typography>
              Since in the worst case we might encounter a number of zeroes at
              the start of the array in which case we will have to shift ‘n’
              elements for all the zeroes which in the worst case could be n.
            </Typography>

            <Typography>
              Thus we are doing ‘n’ amount of operation ‘n’ times so the time
              complexity will O(n^2) and remember that we are doing this in
              place so no extra space is used.
            </Typography>

            <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>
              Time and Space Complexity
            </Typography>

            <Typography>Time Complexity : O(n^2)</Typography>

            <Typography>Space Complexity : O(1)</Typography>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={2}
        >
          <Box
            display='flex'
            flexDirection='column'
          >
            <Typography>
              One very easy way of solving this problem would be to make another
              array and then copy every element except for the zeros and then
              fill all the last values with zero. However, we are not allowed to
              use any extra space and thus have to do this in place. Also, we
              need to maintain the relative ordering of the elements. One way of
              doing that would be to declare two pointers ‘slow’ and ‘fast’.
              initialize the fast and slow pointer, to the same position, the
              first index. The trick here is to let the slow pointer move if and
              only if it’s not a 0, and the fast pointer will move 1 step each
              loop. This way, the slow will only ever walk as far as there are
              non-zeros in the array, and the fast will walk all the way to the
              end.
            </Typography>

            <img
              src={ArrayGif}
              alt=''
              style={{ height: '200px', width: '600px', text: 'center' }}
            />

            <Typography>
              Initialize a pointer to keep track of the position to insert
              non-zero elements. Iterate through the array, if the element is
              non-zero, place it at the current i position and increment i.
            </Typography>

            <Typography>
              Once all non-zero elements are placed at the beginning, fill the
              remaining positions from i to the end of the array with zeros.
            </Typography>

            <Typography sx={{ margin: '20px 0' }}>
              Below is the implementation for it.
            </Typography>

            <Box
              sx={{
                backgroundColor: 'black',
                padding: '24px',
                borderRadius: '10px',
                width: '583px',
              }}
            >
              <pre style={{ color: 'white', margin: 0 }}>
                {`function movezeroes(nums):

   slow = 0
    for fast from 0 to len(nums) - 1:
        if nums[fast] not equal to 0:
            swap nums[slow] and nums[fast]
            slow += 1
`}
              </pre>
            </Box>
            <Typography sx={{ margin: '20px 0' }}>
              Both your fast and slow pointers move in the forward direction.
              Your fast pointer goes all the way to the end of the array and
              never goes back. Similarly your slow pointer too moves in the
              forward direction. So, in the worst case it will go for the length
              of the array and no extra space is utilized.
            </Typography>

            <Typography
              sx={{ fontSize: '18px', fontWeight: 600, marginBottom: '20px' }}
            >
              Time and Space Complexity
            </Typography>

            <Typography>Time Complexity : O(n)</Typography>
            <Typography sx={{ margin: '20px 0' }}>
              Space Complexity : O(1)
            </Typography>
          </Box>
        </CustomTabPanel>
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        alignSelf='end'
        justifyContent='space-between'
        gap='12px'
      >
        {!hasMarkedCompleted(topic_id) && (
          <LoadingButton
            variant='contained'
            onClick={() => handlePostCompletedTopic(topic_id)}
            loading={loading}
            loadingPosition='center'
            children='Mark as completed'
            style={{
              width: '170px',
              // backgroundColor: 'transparent',
              borderRadius: '8px',
              border: '1px solid rgba(64, 96, 245, 0.5)',
            }}
          />
        )}

        <Box
          display='flex'
          alignItems='center'
          gap='8px'
        >
          <Button
            sx={{ gap: '4px' }}
            onClick={handlePrevious}
          >
            <WestIcon /> Previous
          </Button>

          <Button
            sx={{ gap: '4px' }}
            onClick={handleNext}
          >
            Next <EastIcon />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default MoveZeros;

import React, { useEffect, useState } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import CreateQuizModal from './CreateQuizModal';
import { getHeaders, getQuizzes } from '../../../apis/urls';
import ErrorNotifier from '../../ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../ToastNotifications/SuccessNotifier';
import QuizDetails from './DetailedQuiz/QuizDetails';

export default function QuizLayoutAdmin() {
  const [open, setOpen] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [quizzes, setQuizzes] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [selectedQuizId, setSelectedQuizId] = useState(null);
  const [perPage, setPerPage] = useState(50);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchQuizzes();
    return () => {};
  }, [perPage, page]);

  const fetchQuizzes = () => {
    setLoading(true);
    const offset = Math.max(0, page * perPage - perPage);
    const queryParams = [`limit=${perPage}`, `offset=${offset}`];

    axios
      .get(getQuizzes(queryParams), {
        headers: getHeaders(),
      })
      .then((res) => {
        setQuizzes(res?.data?.data);
        setTotalRows(res?.data?.total);
      })
      .catch((e) => {
        console.log(e);
        setHasError(true);
        setErrorMessage(
          e.response?.data?.message ||
            'An error occurred while fetching the quizzes'
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleViewDetails = (quiz) => {
    setSelectedQuizId(quiz.id);
    setOpenDetails(true);
  };

  const handleCloseDetails = () => {
    setOpenDetails(false);
    setSelectedQuizId(null);
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePageSizeChange = (size) => {
    setPerPage(size);
  };

  const columns = [
    {
      field: 'id',
      headerName: 'Quiz id',
      flex: 1,
      maxWidth: 500,
      align: 'center',
      headerAlign: 'center',
      type: 'number',
      sortable: false,
      renderCell: (params) => {
        if (params.row.id) {
          return <Typography variant='body2'>{params.row.id}</Typography>;
        }
      },
    },
    {
      field: 'title',
      headerName: 'Quiz name',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      type: 'string',
      sortable: false,
      renderCell: (params) => {
        if (params.row.title) {
          return (
            <Typography variant='body2'>{params.row.internal_name}</Typography>
          );
        }
      },
    },
  ];

  return (
    <>
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      {isSuccess && (
        <SuccessNotifier
          message={successMessage}
          setIsSuccess={setIsSuccess}
        />
      )}
      {openDetails ? (
        <QuizDetails
          quizId={selectedQuizId}
          handleCloseDetails={handleCloseDetails}
        />
      ) : (
        <>
          <Stack
            m='1rem'
            direction='row-reverse'
            justifyContent='space-between'
          >
            <Button
              variant='contained'
              onClick={() => setOpen(true)}
              sx={{
                height: '2.5em',
              }}
            >
              Add Quiz
            </Button>
            <Typography variant='h4'>Quizzes</Typography>
          </Stack>
          <DataGrid
            rows={quizzes}
            columns={columns}
            components={{
              NoRowsOverlay: () => (
                <Stack
                  height='100%'
                  alignItems='center'
                  justifyContent='center'
                >
                  No quizzes have been added.
                </Stack>
              ),
            }}
            rowsPerPageOptions={[50, 100]} // 100 is the max page size in non-pro version
            page={page - 1}
            pageSize={perPage}
            onPageChange={(newPage) => handlePageChange(newPage + 1)}
            onPageSizeChange={(page) => handlePageSizeChange(page)}
            rowCount={totalRows}
            pagination
            paginationMode='server'
            autoHeight
            disableSelectionOnClick
            onRowClick={handleViewDetails}
            sx={{
              cursor: 'pointer',
              '& .MuiDataGrid-row:hover': {
                backgroundColor: '#e3f2fd',
              },
              '& .MuiDataGrid-cell': {
                paddingLeft: '25px',
              },
              '& .MuiDataGrid-columnHeaderTitleContainer': {
                paddingLeft: '15px',
              },
            }}
            loading={loading}
          />
        </>
      )}
      {open && (
        <CreateQuizModal
          open={open}
          onClose={() => setOpen(false)}
          setQuizzes={setQuizzes}
          setHasError={setHasError}
          setErrorMessage={setErrorMessage}
          setIsSuccess={setIsSuccess}
          setSuccessMessage={setSuccessMessage}
        />
      )}
    </>
  );
}

import React, { useRef, useEffect } from 'react';
import { 
  Button, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogContentText, 
  DialogTitle 
} from '@material-ui/core';
import CodingTestSubmissionTestcaseDetails from './CodingTestSubmissionTestcaseDetails';

function CodingTestProblemSubmissionDialog({ 
  submission, 
  openSpecificSubmission, 
  setOpenSpecificSubmission 
}) {
  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (openSpecificSubmission) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openSpecificSubmission]);

  return (
    <Dialog
      open={openSpecificSubmission}
      onClose={(e) => setOpenSpecificSubmission(false)}
      scroll="paper"
      fullWidth
      maxWidth="lg"
    >
      <DialogActions>
        <Button 
          onClick={(e) => setOpenSpecificSubmission(false)}
        >
          Close
        </Button>
      </DialogActions>

      <DialogTitle>Submission details</DialogTitle>
      
      <DialogContent dividers>
        <DialogContentText 
          ref={descriptionElementRef} 
          tabIndex={-1}
        >
          <CodingTestSubmissionTestcaseDetails 
            submission={submission}
          />
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

export default CodingTestProblemSubmissionDialog
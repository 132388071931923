// @ts-nocheck
// material
import { Box, Typography } from '@material-ui/core';
import Button from '@mui/material/Button';
import '../tooltip.css';

// components
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { mechanismTopics } from '../components/utils/techniqueSagaTopics';
import LoadingScreen from './LoadingScreen';
import BitManipulation from './BitManipulation';
import { fetchTechniqueSaga, getHeaders } from '../apis/urls';
import lock from '../images/lock.png';
import Technique_3_Lock from '../images/Technique_3_Lock.png';
import path from '../images/path.png';
import unlock from '../images/unlock.png';
import { shouldShowNewStudentLayout } from 'src/components/utils/common';
// ----------------------------------------------------------------------

export default function TechniqueSaga() {
  const currentProgram = useSelector((state) => state.programs.currentProgram);
  const [isBitManipulation, setBitManipulation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [lastCompletedTopic, setLastCompletedTopic] = useState(1);
  const [completedTopics, setCompletedTopics] = useState([]);
  const [btnText, setBtnText] = useState('Start Learning');
  const [maxWidth, setMaxWidth] = useState('1202px');
  const [showNewLayout, setShowNewLayout] = useState(null);

  const handleClick = () => {
    setBitManipulation(true);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1440) {
        setMaxWidth('1080px');
      } else {
        setMaxWidth('1202px');
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setLoading(true);
    axios
      .get(fetchTechniqueSaga(), {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((res) => {
        const data = res?.data ?? [];
        setLoading(false);
        setCompletedTopics(data.map((topic) => topic.topic_id));
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
    const newLyoutFlag = shouldShowNewStudentLayout(currentProgram.programId);
    setShowNewLayout(newLyoutFlag);
  }, [currentProgram?.programId]);

  let lastCompletedTopicId;

  const setLastCompletedTopicId = () => {
    if (completedTopics.length > 0) {
      // Calculate the total number of topics
      const totalTopics = mechanismTopics.reduce(
        (acc, curr) => acc + curr.topics.length,
        0
      );

      // Find the maximum completed topic ID
      const maxCompletedTopicId = Math.max(...completedTopics);

      // Check if the user has completed all topics
      if (completedTopics.length === totalTopics) {
        setLastCompletedTopic(1); // Reset to 1 if all topics completed
        setBtnText('Completed');
      } else {
        // Find the next topic ID to be completed
        setBtnText('Continue Learning');
        for (let i = 1; i <= totalTopics; i += 1) {
          if (!completedTopics.includes(i)) {
            setLastCompletedTopic(i);
            break;
          }
        }
        // If all topics before the maximum completed topic have been completed, then next topic is the maximum completed topic + 1
        if (lastCompletedTopicId > maxCompletedTopicId) {
          setLastCompletedTopic(maxCompletedTopicId + 1);
        }
      }
    } else {
      setLastCompletedTopic(1); // Default to 1 if no topics completed
    }
  };

  useEffect(() => {
    setLastCompletedTopicId();
  }, [completedTopics]);

  return (
    <>
      {isBitManipulation ? (
        <BitManipulation
          setBitManipulation={setBitManipulation}
          completedTopics={completedTopics}
          setCompletedTopics={setCompletedTopics}
          lastCompletedTopic={lastCompletedTopic}
        />
      ) : (
        <div
          style={{
            // position:'relative',
            position: showNewLayout ? 'absolute' : 'relative',
            // width: '100%',
            width: showNewLayout ? '75vw' : '100%',
            maxWidth: maxWidth,
            // height: '600px',
            height: showNewLayout ? '750px' : '600px',
            overflowX: 'hidden',
            overflowY: 'hidden',
          }}
        >
          <LoadingScreen loading={loading} />
          <svg
            width='1511'
            height='905'
            viewBox='0 0 1511 905'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <g opacity='0.04'>
              <g clipPath='url(#clip0_3833_3252)'>
                <path
                  d='M-3.12188 -0.273493H1511V1513.87H-3.12188V-0.273493Z'
                  fill='black'
                />
                <path
                  d='M992.584 1195.58C993.642 1190.36 1098.68 672.421 1137.62 501.62C1166.9 373.168 1264.54 -12.1347 1265.52 -16.012L1256.73 -18.2393C1255.75 -14.362 1158.08 371.046 1128.78 499.605C1089.82 670.514 984.757 1188.56 983.7 1193.78L992.584 1195.58Z'
                  fill='white'
                />
                <path
                  d='M1232.51 1232.61L1233.74 1232.07C1235.32 1231.37 1392.64 1162.11 1446.02 1142.52C1508.34 1119.66 1700.64 1062.6 1702.58 1062.02L1700 1053.33C1698.06 1053.9 1505.49 1111.05 1442.9 1134.01C1391.77 1152.76 1246.25 1216.66 1231.32 1223.23C1200.98 1218.32 673.485 1133.35 493.758 1124.67C383.437 1119.33 56.6137 1139.98 36.8317 1141.23L-1.6383 1127.93L-4.59998 1136.5L35.5957 1150.4L36.5084 1150.34C39.9383 1150.12 380.614 1128.28 493.326 1133.72C676.967 1142.6 1225.67 1231.49 1231.19 1232.39L1232.51 1232.61Z'
                  fill='white'
                />
                <path
                  d='M1549.14 2251.28L1557.37 2247.47C1555.44 2243.3 1363.57 1829.23 1265.07 1656.32C1170.22 1489.83 981.071 1199.16 883.097 1055.12C815.849 956.264 703.001 796.258 603.438 655.086C537.012 560.898 474.269 471.931 429.773 407.867C356.822 302.83 140.353 -16.4594 138.177 -19.6719L130.677 -14.5827C132.856 -11.3703 349.349 307.961 422.327 413.038C466.841 477.129 529.596 566.108 596.031 660.311C695.57 801.456 808.394 961.425 875.603 1060.23C973.494 1204.14 1162.47 1494.53 1257.19 1660.8C1355.51 1833.39 1547.22 2247.12 1549.14 2251.28Z'
                  fill='white'
                />
                <path
                  d='M616.239 679.647L1129.7 150.331L1082.16 -18.354L1073.43 -15.8971L1119.56 147.765L609.733 673.333L616.239 679.647Z'
                  fill='white'
                />
                <path
                  d='M540.149 2384.92L547.661 2379.84C546.283 2377.8 409.329 2175.4 355.918 2114.42C276.017 2023.19 2.64061 1780.01 -0.11243 1777.57L-6.13227 1784.34C-3.38527 1786.79 269.568 2029.59 349.104 2120.39C402.14 2180.95 538.777 2382.89 540.149 2384.92Z'
                  fill='white'
                />
                <path
                  d='M891.777 63.5536L1195.5 249.818L1190.76 257.547L887.037 71.2825L891.777 63.5536Z'
                  fill='white'
                />
                <path
                  d='M471.701 1130.26L480.135 1126.93L332.568 752.351L386.405 519.046L449.806 442.446L442.822 436.664L378.055 514.917L323.1 753.055L471.701 1130.26Z'
                  fill='white'
                />
                <path
                  d='M425.823 469.024L581.499 234.477L881.795 79.0465L882.052 78.8289L993.11 -14.8154L989.215 -19.4362L878.413 73.9876L577.331 229.829L576.896 230.482L420.789 465.679L425.823 469.024Z'
                  fill='white'
                />
                <path
                  d='M1015.56 261.573L1019.68 257.157L928.449 171.809L982.344 125.517L978.409 120.929L919.398 171.618L1015.56 261.573Z'
                  fill='white'
                />
                <path
                  d='M567.066 251.492L789.18 446.728L785.189 451.268L563.076 256.031L567.066 251.492Z'
                  fill='white'
                />
                <path
                  d='M644.474 196.936L759.422 312.049L753.007 318.455L638.059 203.342L644.474 196.936Z'
                  fill='white'
                />
                <path
                  d='M695.966 369.318L885.349 205.828L795.166 119.382L790.981 123.746L876.37 205.595L692.016 364.742L695.966 369.318Z'
                  fill='white'
                />
                <path
                  d='M935.164 93.9945L938.553 98.9996L838.987 166.404L835.599 161.398L935.164 93.9945Z'
                  fill='white'
                />
                <path
                  d='M865.398 82.7407L906.582 115.923L902.79 120.63L861.606 87.4473L865.398 82.7407Z'
                  fill='white'
                />
                <path
                  d='M827.395 102.709L868.266 142.194L864.067 146.541L823.196 107.056L827.395 102.709Z'
                  fill='white'
                />
                <path
                  d='M922.073 169.323L925.817 174.067L882.752 208.063L879.007 203.319L922.073 169.323Z'
                  fill='white'
                />
                <path
                  d='M698.425 257.07L702.356 261.661L637.097 317.556L633.165 312.965L698.425 257.07Z'
                  fill='white'
                />
                <path
                  d='M668.224 226.494L671.771 231.387L598.86 284.252L595.312 279.359L668.224 226.494Z'
                  fill='white'
                />
                <path
                  d='M978.145 298.601L1162.16 381.684L1159.67 387.192L975.658 304.109L978.145 298.601Z'
                  fill='white'
                />
                <path
                  d='M932.401 810.577L1081.05 348L1075.3 346.151L928.288 803.623L852.722 789.024L851.574 794.956L932.401 810.577Z'
                  fill='white'
                />
                <path
                  d='M1049.63 434.762L1217.83 468.276L1216.65 474.203L1048.45 440.689L1049.63 434.762Z'
                  fill='white'
                />
                <path
                  d='M1037.39 473.157L1097.81 487.567L1096.41 493.446L1035.99 479.036L1037.39 473.157Z'
                  fill='white'
                />
                <path
                  d='M1017.48 535.162L1073.07 548.036L1071.71 553.924L1016.11 541.05L1017.48 535.162Z'
                  fill='white'
                />
                <path
                  d='M1002.09 583.518L1151.78 624.19L1150.19 630.023L1000.51 589.35L1002.09 583.518Z'
                  fill='white'
                />
                <path
                  d='M984.186 639.223L1135.72 681.974L1134.08 687.791L982.545 645.04L984.186 639.223Z'
                  fill='white'
                />
                <path
                  d='M899.309 381.07L1031.68 497.803L1025.68 504.603L893.312 387.87L899.309 381.07Z'
                  fill='white'
                />
                <path
                  d='M833.395 480.96L994.808 609.993L991.034 614.714L829.621 485.681L833.395 480.96Z'
                  fill='white'
                />
                <path
                  d='M965.613 694.935L969.753 686.869L855.035 627.954L764.689 520.082L626.124 386.54L619.833 393.068L758.222 526.44L849.263 635.183L965.613 694.935Z'
                  fill='white'
                />
                <path
                  d='M855.55 634.566L923.907 557.056L991.745 468.193L984.538 462.693L916.799 551.426L848.75 628.57L855.55 634.566Z'
                  fill='white'
                />
                <path
                  d='M1133.1 798.495L1152.96 780.904L1148.95 776.379L1131.28 792.031L1072.91 781.704L946.544 757.818L848.958 682.502L708.039 577.93L704.44 582.784L845.313 687.323L943.997 763.49L944.71 763.623L1071.82 787.649L1133.1 798.495Z'
                  fill='white'
                />
                <path
                  d='M1109.81 910.147L1135.14 795.916L1129.24 794.608L1105.2 903L1050.24 891.434L1049 897.348L1109.81 910.147Z'
                  fill='white'
                />
                <path
                  d='M1119.09 853.846L1120.6 847.992L1062.47 833.036L930.993 804.148L929.697 810.05L1061.07 838.917L1119.09 853.846Z'
                  fill='white'
                />
                <path
                  d='M817.822 748.791L849.804 686.271L875.398 643.735L870.218 640.62L844.522 683.336L812.44 746.038L817.822 748.791Z'
                  fill='white'
                />
                <path
                  d='M813.592 585.767L817.726 590.176L773.929 631.243L769.794 626.834L813.592 585.767Z'
                  fill='white'
                />
                <path
                  d='M481.945 381.632L626.549 566.843L619.401 572.424L474.797 387.213L481.945 381.632Z'
                  fill='white'
                />
                <path
                  d='M523.886 348.102L670.898 495.388L664.481 501.793L517.469 354.508L523.886 348.102Z'
                  fill='white'
                />
                <path
                  d='M745.604 412.439L752.422 418.414L706.885 470.37L700.068 464.395L745.604 412.439Z'
                  fill='white'
                />
                <path
                  d='M707.928 379.201L712.355 383.315L666.97 432.149L662.542 428.034L707.928 379.201Z'
                  fill='white'
                />
                <path
                  d='M665.483 341.923L669.957 345.983L596.447 426.999L591.973 422.939L665.483 341.923Z'
                  fill='white'
                />
                <path
                  d='M515.834 538.552L566.909 501.034L627.724 459.275L624.3 454.294L563.409 496.108L512.259 533.684L515.834 538.552Z'
                  fill='white'
                />
                <path
                  d='M472.027 476.09L520.811 438.846L570.212 401.478L566.564 396.658L517.154 434.035L468.361 471.285L472.027 476.09Z'
                  fill='white'
                />
                <path
                  d='M1358.16 1172.75L1366.88 1170.27L1331.74 1046.29L1231.45 1023.93L857.371 1020.96L857.301 1030.03L1230.89 1032.99L1324.49 1053.96L1358.16 1172.75Z'
                  fill='white'
                />
                <path
                  d='M1009.9 1085.61L1131.35 1092.39L1131.01 1098.42L1009.56 1091.64L1009.9 1085.61Z'
                  fill='white'
                />
                <path
                  d='M1303.44 1196.11L1383.95 1310.84L1379 1314.31L1298.5 1199.58L1303.44 1196.11Z'
                  fill='white'
                />
                <path
                  d='M1337.38 1248.48L1339.62 1254.1L1254.22 1288.2L1251.98 1282.58L1337.38 1248.48Z'
                  fill='white'
                />
                <path
                  d='M1284.6 613.941L1319.8 557.129V415.395H1313.76V555.41L1279.46 610.756L1284.6 613.941Z'
                  fill='white'
                />
                <path
                  d='M1161.54 495.615L1317.41 528.363L1316.16 534.278L1160.3 501.53L1161.54 495.615Z'
                  fill='white'
                />
                <path
                  d='M1148.16 545.579L1302.47 577.421L1301.25 583.341L1146.94 551.499L1148.16 545.579Z'
                  fill='white'
                />
                <path
                  d='M677.739 610.706L767.042 753.337L761.919 756.545L672.616 613.913L677.739 610.706Z'
                  fill='white'
                />
                <path
                  d='M736.632 709.772L739.187 715.25L664.268 750.184L661.714 744.706L736.632 709.772Z'
                  fill='white'
                />
                <path
                  d='M477.073 847.627L688.308 786.981L686.636 781.173L479.623 840.61L354.957 656.202L541.302 575.903L538.912 570.351L345.861 653.539L477.073 847.627Z'
                  fill='white'
                />
                <path
                  d='M536.845 787.918L651.981 732.672L649.367 727.223L538.876 780.239L480.443 696.017L590.063 644.917L587.506 639.438L471.389 693.572L536.845 787.918Z'
                  fill='white'
                />
                <path
                  d='M516.416 752.265L518.897 757.776L442.555 792.155L440.073 786.644L516.416 752.265Z'
                  fill='white'
                />
                <path
                  d='M481.96 770.432L523.182 830.243L518.205 833.673L476.984 773.862L481.96 770.432Z'
                  fill='white'
                />
                <path
                  d='M420.088 623.9L478.219 692.864L473.597 696.759L415.467 627.795L420.088 623.9Z'
                  fill='white'
                />
                <path
                  d='M540.269 782.305L565.705 817.958L560.782 821.471L535.346 785.817L540.269 782.305Z'
                  fill='white'
                />
                <path
                  d='M384.383 514.98L466.783 603.639L462.356 607.754L379.956 519.095L384.383 514.98Z'
                  fill='white'
                />
                <path
                  d='M494.855 508.659L498.262 513.651L425.024 563.633L421.617 558.64L494.855 508.659Z'
                  fill='white'
                />
                <path
                  d='M622.296 949.206L623.535 955.122L423.463 997.025L422.224 991.109L622.296 949.206Z'
                  fill='white'
                />
                <path
                  d='M373.917 1128.6H379.961V1090.91L636.988 1009.43L635.166 1003.67L373.917 1086.48V1128.6Z'
                  fill='white'
                />
                <path
                  d='M424.751 763.216L426.375 769.035L343.042 792.291L341.418 786.472L424.751 763.216Z'
                  fill='white'
                />
                <path
                  d='M477.428 841.174L479.31 846.917L377.898 880.149L376.016 874.406L477.428 841.174Z'
                  fill='white'
                />
                <path
                  d='M557.16 264.897L561.191 260.397L351.87 72.7576L166.984 26.8557L165.524 32.7245L348.953 78.2608L557.16 264.897Z'
                  fill='white'
                />
                <path
                  d='M577.374 45.3161L581.9 49.3216L466.572 179.653L462.046 175.648L577.374 45.3161Z'
                  fill='white'
                />
                <path
                  d='M378.393 132.721L398.502 118.907L536.942 -14.9545L532.739 -19.2971L394.482 114.386L374.981 127.735L378.393 132.721Z'
                  fill='white'
                />
                <path
                  d='M466.387 48.881L527.567 106.328L523.43 110.734L462.249 53.2872L466.387 48.881Z'
                  fill='white'
                />
                <path
                  d='M451.617 164.357L453.75 170.013L300.124 227.937L297.992 222.282L451.617 164.357Z'
                  fill='white'
                />
                <path
                  d='M510.292 217.039L512.56 222.642L343.34 291.16L341.072 285.557L510.292 217.039Z'
                  fill='white'
                />
                <path
                  d='M380.184 100.75L382.983 106.107L260.995 169.827L258.196 164.47L380.184 100.75Z'
                  fill='white'
                />
                <path
                  d='M194.604 270.865L314.692 250.279L313.671 244.32L199.149 263.954L160.885 106.299L214.435 103.525L214.12 97.4901L153.296 100.639L194.604 270.865Z'
                  fill='white'
                />
                <path
                  d='M281.813 197.116L282.233 203.145L182.42 210.099L182 204.069L281.813 197.116Z'
                  fill='white'
                />
                <path
                  d='M210.904 206.533L221.781 263.079L215.843 264.221L204.966 207.676L210.904 206.533Z'
                  fill='white'
                />
                <path
                  d='M253.24 201.557L267.752 254.966L261.919 256.551L247.408 203.142L253.24 201.557Z'
                  fill='white'
                />
                <path
                  d='M330.028 1531.19L689.667 1150.5L837.833 996.146L833.472 991.96L685.292 1146.34L325.637 1527.04L330.028 1531.19Z'
                  fill='white'
                />
                <path
                  d='M329.728 1373.79L411.008 1440.86L407.159 1445.52L325.879 1378.45L329.728 1373.79Z'
                  fill='white'
                />
                <path
                  d='M456.602 1265.86L525.662 1319.32L521.961 1324.1L452.901 1270.65L456.602 1265.86Z'
                  fill='white'
                />
                <path
                  d='M379.791 1363.21L384.633 1366.83L273.832 1514.92L268.99 1511.3L379.791 1363.21Z'
                  fill='white'
                />
                <path
                  d='M35.0275 1812.99L161.123 1138.51L145.865 1091.15L140.108 1093L154.904 1138.91L29.0831 1811.88L35.0275 1812.99Z'
                  fill='white'
                />
                <path
                  d='M-1.29592 1566L159.853 1690.9L156.152 1695.67L-4.99666 1570.77L-1.29592 1566Z'
                  fill='white'
                />
                <path
                  d='M-1.38951 1699.9L96.7578 1772.44L93.1635 1777.3L-4.98382 1704.76L-1.38951 1699.9Z'
                  fill='white'
                />
                <path
                  d='M-1.46384 1444.9L90.3789 1506.79L87.0032 1511.8L-4.83953 1449.91L-1.46384 1444.9Z'
                  fill='white'
                />
                <path
                  d='M246.389 1275.38L251.163 1279.09L97.4375 1477.37L92.6633 1473.67L246.389 1275.38Z'
                  fill='white'
                />
                <path
                  d='M781.972 1651.31L1129.67 1441.55L1126.55 1436.37L781.298 1644.66L591.16 1577.25L590.084 1577.7L467.191 1629.08L469.521 1634.65L591.338 1583.73L781.972 1651.31Z'
                  fill='white'
                />
                <path
                  d='M1180.75 1691.37L1244.62 1650.53L1241.37 1645.44L1182.34 1683.18L1044.44 1489.17L1039.52 1492.67L1180.75 1691.37Z'
                  fill='white'
                />
                <path
                  d='M1164.85 1497.62L1167.86 1502.86L1084.31 1550.95L1081.29 1545.71L1164.85 1497.62Z'
                  fill='white'
                />
                <path
                  d='M1194.32 1545.75L1197.35 1550.98L1117.2 1597.32L1114.18 1592.09L1194.32 1545.75Z'
                  fill='white'
                />
                <path
                  d='M1224.14 1595L1227.01 1600.32L1149.19 1642.4L1146.32 1637.09L1224.14 1595Z'
                  fill='white'
                />
                <path
                  d='M263.908 2030.33L264.705 2024.34L158.576 2010.17L-2.4061 1970.93L-3.84157 1976.8L157.615 2016.15L263.908 2030.33Z'
                  fill='white'
                />
                <path
                  d='M-3.12217 2049.92H75.4437L164.369 1987.42L160.891 1982.47L73.5308 2043.87H-3.12217V2049.92Z'
                  fill='white'
                />
                <path
                  d='M-1.41147 1887.86L40.9544 1919.58L37.3299 1924.42L-5.03601 1892.7L-1.41147 1887.86Z'
                  fill='white'
                />
                <path
                  d='M356.517 614.95L359.59 609.746L233.277 535.165L364.268 318.42L359.098 315.296L224.948 537.268L356.517 614.95Z'
                  fill='white'
                />
                <path
                  d='M313.68 396.575L419.758 471.181L416.281 476.125L310.202 401.519L313.68 396.575Z'
                  fill='white'
                />
                <path
                  d='M400.081 375.584L405.534 378.184L374.795 442.655L369.342 440.055L400.081 375.584Z'
                  fill='white'
                />
                <path
                  d='M-1.35499 451.436L47.952 415.857L328.261 375.861L327.405 369.88L45.6219 410.085L-4.89077 446.534L-1.35499 451.436Z'
                  fill='white'
                />
                <path
                  d='M-1.0797 364.46L78.0517 443.537L73.7792 447.812L-5.35221 368.735L-1.0797 364.46Z'
                  fill='white'
                />
                <path
                  d='M-6.05572 453.887L49.3533 474.107L47.2814 479.785L-8.1276 459.565L-6.05572 453.887Z'
                  fill='white'
                />
                <path
                  d='M165.146 395.652H171.19V473.687H165.146V395.652Z'
                  fill='white'
                />
                <path
                  d='M152.787 526.371L229.348 533.274L228.805 539.294L152.245 532.391L152.787 526.371Z'
                  fill='white'
                />
                <path
                  d='M-2.90074 516.652L69.1673 520.673L68.8307 526.707L-3.23739 522.686L-2.90074 516.652Z'
                  fill='white'
                />
                <path
                  d='M238.535 841.003L243.868 838.171L217.568 788.667L225.117 641.644L178.463 530.236L172.885 532.572L219.009 642.711L211.448 790.024L238.535 841.003Z'
                  fill='white'
                />
                <path
                  d='M52.7108 599.874L355.591 631.085L354.971 637.097L52.0913 605.886L52.7108 599.874Z'
                  fill='white'
                />
                <path
                  d='M102.591 548.407L106.906 552.643L-0.91866 662.486L-5.23383 658.25L102.591 548.407Z'
                  fill='white'
                />
                <path
                  d='M17.2594 636.954L221.074 665.897L220.224 671.881L16.4096 642.938L17.2594 636.954Z'
                  fill='white'
                />
                <path
                  d='M217.667 698.394L220.491 703.738L97.5989 768.695L94.7746 763.352L217.667 698.394Z'
                  fill='white'
                />
                <path
                  d='M213.834 786.353L215.15 792.25L158.231 804.957L156.915 799.061L213.834 786.353Z'
                  fill='white'
                />
                <path
                  d='M169.347 659.232L172.398 664.45L6.96779 761.165L3.91739 755.947L169.347 659.232Z'
                  fill='white'
                />
                <path
                  d='M-2.98031 738.497L25.7138 794.348L20.3377 797.11L-8.35645 741.259L-2.98031 738.497Z'
                  fill='white'
                />
                <path
                  d='M54.8801 643.312L94.0849 706.54L88.9507 709.723L49.7458 646.496L54.8801 643.312Z'
                  fill='white'
                />
                <path
                  d='M140.205 680.125L193.659 794.429L188.182 796.991L134.728 682.686L140.205 680.125Z'
                  fill='white'
                />
                <path
                  d='M123.347 552.808L129.383 553.103L124.411 654.872L118.375 654.577L123.347 552.808Z'
                  fill='white'
                />
                <path
                  d='M329.617 741.525L329.881 747.563L216.711 752.513L216.447 746.475L329.617 741.525Z'
                  fill='white'
                />
                <path
                  d='M-1.86825 937.026L200.585 844.609L303.233 842.554L358.363 831.687L357.194 825.754L302.323 836.57L199.216 838.589L-4.37647 931.526L-1.86825 937.026Z'
                  fill='white'
                />
                <path
                  d='M410.967 963.14L412.68 968.936L238.524 1020.38L236.812 1014.59L410.967 963.14Z'
                  fill='white'
                />
                <path
                  d='M151.563 864.084L199.857 1011.4L194.114 1013.29L145.82 865.966L151.563 864.084Z'
                  fill='white'
                />
                <path
                  d='M100.465 887.663L138.763 1016.08L130.075 1018.67L91.777 890.254L100.465 887.663Z'
                  fill='white'
                />
                <path
                  d='M42.3979 913.96L75.8069 1035.19L69.9798 1036.79L36.5709 915.565L42.3979 913.96Z'
                  fill='white'
                />
                <path
                  d='M-4.72383 996.947L57.1304 978.304L116.35 956.001L114.219 950.347L55.1934 972.583L-6.47055 991.163L-4.72383 996.947Z'
                  fill='white'
                />
                <path
                  d='M211.364 840.57L263.234 1009.83L257.456 1011.6L205.585 842.341L211.364 840.57Z'
                  fill='white'
                />
                <path
                  d='M305.532 838.803L348.168 984.815L342.366 986.509L299.73 840.497L305.532 838.803Z'
                  fill='white'
                />
                <path
                  d='M45.5776 1200.72L46.538 1206.68L-5.09049 1214.99L-6.05088 1209.03L45.5776 1200.72Z'
                  fill='white'
                />
                <path
                  d='M1500.2 444.403L1518.27 445.988L1517.74 452.009L1499.67 450.424L1500.2 444.403Z'
                  fill='white'
                />
                <path
                  d='M1462.43 447.136L1462.96 441.116L1425.72 437.843L1425.19 443.863L1462.43 447.136ZM1387.95 440.59L1388.48 434.57L1351.24 431.297L1350.71 437.317L1387.95 440.59ZM1313.47 434.044L1314 428.024L1276.76 424.751L1276.23 430.771L1313.47 434.044ZM1239 427.501L1239.52 421.481L1202.28 418.209L1201.76 424.228L1239 427.501ZM1164.52 420.956L1165.04 414.936L1127.8 411.663L1127.28 417.683L1164.52 420.956ZM1090.04 414.41L1090.56 408.39L1053.32 405.117L1052.8 411.137L1090.04 414.41ZM1016.25 401.478L1020.4 397.084L993.213 371.424L989.064 375.818L1016.25 401.478ZM961.878 350.155L966.027 345.761L938.842 320.101L934.692 324.495L961.878 350.155ZM907.509 298.834L911.659 294.44L884.473 268.78L880.323 273.174L907.509 298.834ZM853.138 247.514L857.287 243.12L830.101 217.46L825.952 221.854L853.138 247.514ZM798.766 196.193L802.915 191.799L775.729 166.139L771.58 170.533L798.766 196.193Z'
                  fill='white'
                />
                <path
                  d='M705.818 149.977L732.865 133.987L744.394 144.872L748.543 140.478L733.702 126.471L706.661 142.455L695.147 131.557L690.992 135.945L705.818 149.977Z'
                  fill='white'
                />
                <path
                  d='M666.849 113.09L671.005 108.702L646.859 85.846L642.704 90.234L666.849 113.09ZM618.561 67.3813L622.716 62.9932L598.57 40.1375L594.415 44.5255L618.561 67.3813Z'
                  fill='white'
                />
                <path
                  d='M561.285 4.77289L574.45 17.2407L570.294 21.6293L557.129 9.16152L561.285 4.77289Z'
                  fill='white'
                />
                <path
                  d='M516.175 1705.01L547.782 1687.23L544.82 1681.97L513.213 1699.75L516.175 1705.01ZM579.389 1669.46L610.996 1651.68L608.035 1646.41L576.427 1664.19L579.389 1669.46ZM620.682 1615.03L627.4 1579.4L621.461 1578.27L614.743 1613.91L620.682 1615.03ZM634.114 1543.76L639.521 1515.07L628.055 1510.89L625.988 1516.57L632.631 1518.99L628.173 1542.64L634.114 1543.76ZM430.746 1540.58L459.373 1528.76L457.065 1523.17L431.42 1533.77L427.023 1530.83L423.665 1535.85L430.746 1540.58ZM393.518 1515.7L396.875 1510.67L366.725 1490.52L363.367 1495.54L393.518 1515.7ZM492.887 1514.91L526.404 1501.05L524.096 1495.47L490.579 1509.32L492.887 1514.91ZM591.909 1504.18L593.976 1498.5L559.897 1486.11L557.83 1491.79L591.909 1504.18ZM333.223 1475.39L336.58 1470.37L306.43 1450.21L303.072 1455.23L333.223 1475.39ZM272.925 1435.08L276.283 1430.05L252.799 1414.36L253.974 1412.34L248.749 1409.3L244.712 1416.22L272.925 1435.08ZM272.245 1381.02L275.4 1375.6L246.86 1354.61L243.276 1359.48L267.443 1377.25L267.02 1377.97L272.245 1381.02ZM214.062 1337.99L217.647 1333.12L188.433 1311.64L184.849 1316.5L214.062 1337.99ZM155.638 1295.02L159.222 1290.15L130.009 1268.66L126.424 1273.53L155.638 1295.02ZM104.092 1244.49L109.58 1241.96L94.4276 1209.01L88.9396 1211.54L104.092 1244.49ZM73.7873 1178.59L79.2753 1176.06L64.1199 1143.12L58.632 1145.65L73.7873 1178.59ZM43.4827 1112.7L48.9706 1110.17L33.8153 1077.22L28.3274 1079.75L43.4827 1112.7ZM13.1721 1046.8L18.66 1044.28L5.71077 1016.12L0.222791 1018.65L13.1721 1046.8Z'
                  fill='white'
                />
              </g>
            </g>
            <defs>
              <clipPath id='clip0_3833_3252'>
                <path
                  d='M1511 0H0V875C0 891.569 13.4315 905 30 905H1481C1497.57 905 1511 891.569 1511 875V0Z'
                  fill='white'
                />
              </clipPath>
            </defs>
          </svg>
          <Typography
            sx={{
              width: '700px',
              textAlign: 'left',
              height: '64px',
              top: '53px',
              left: '20px',
              opacity: '0px',
              fontWeight: 600,
              position: 'absolute',
              fontSize: '40px',
              zIndex: 1,
            }}
          >
            Welcome to Technique Saga!
          </Typography>
          <Typography
            sx={{
              width: '625px',
              textAlign: 'left',
              height: '64px',
              top: '135px',
              left: '20px',
              opacity: '0px',
              position: 'absolute',
              zIndex: 1,
            }}
          >
            Technique Saga will help you uncover the secrets behind effective
            problem-solving techniques. Dive deep into the mechanics of each
            method, gain valuable insights, and refine your problem-solving
            skills to perfection. Whether it's mastering algorithmic strategies,
            understanding design principles, or embracing innovative approaches,
            Technique Saga will help you with the knowledge and expertise to
            confidently navigate the complexities of problem-solving.
          </Typography>
          <div>
            <img
              src={Technique_3_Lock}
              alt='thll.png'
              style={{
                position: 'absolute',
                float: 'right',
                top: 114.5,
                height: '66px',
                left: '805.5px',
                zIndex: 2,
              }}
            />
            <img
              src={lock}
              alt='thll.png'
              style={{
                position: 'absolute',
                float: 'right',
                top: 309.5,
                height: '66px',
                left: '62.55rem',
                zIndex: 2,
              }}
            />

            <img
              src={unlock}
              alt=''
              style={{
                position: 'absolute',

                float: 'right',
                top: '413px',
                height: '66px',
                left: '51.95rem',
                zIndex: 2,
              }}
            />

            <div
              className='hover-box'
              style={{
                zIndex: 4,
                top: '267px',
                left: '48.4rem',
              }}
            >
              <div>
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='flex-start'
                >
                  <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
                    Technique 1
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '20px',
                      fontWeight: 700,
                    }}
                  >
                    Bit Manipulation
                  </Typography>
                </Box>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '11px',
                  }}
                >
                  <Button
                    variant='contained'
                    style={{ backgroundColor: 'rgba(25, 118, 210, 1)' }}
                    onClick={handleClick}
                  >
                    {btnText}
                  </Button>
                </div>
              </div>
              <div className='arrow-down' />
              <div className='arrow-down-below' />
            </div>

            <div
              className='hover-box'
              style={{
                zIndex: 3,
                top: '274px',
                left: '48rem',
                backgroundColor: 'rgba(25, 118, 210, 1)',
                border: 0,
              }}
            >
              <div />
            </div>
          </div>

          <img
            src={path}
            alt=''
            style={{
              position: 'absolute',
              float: 'right',
              top: 0,
              left: '45rem',
              zIndex: 1,
              //
              height: '599px',
            }}
          />
        </div>
      )}
    </>
  );
}

import { Box } from '@mui/material';
import React, { memo } from 'react';

function PDFViewer({ pdfBytes }) {
  return (
    <>
      {pdfBytes && (
        <Box
          width='60%'
          sx={{
            mx: 'auto',
          }}
        >
          <embed
            src={
              pdfBytes
                ? `${URL.createObjectURL(
                    new Blob([pdfBytes], { type: 'application/pdf' })
                  )}#toolbar=0`
                : null
            }
            width='100%'
            title='Terms and Conditions PDF'
            height='600px'
          />
        </Box>
      )}
    </>
  );
}

export default memo(PDFViewer);

import React, { useState } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Paper,
} from '@material-ui/core';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const DetailedTableView = ({ rows }) => {
  const [copiedRow, setCopiedRow] = useState(null); // State to track which row was copied

  const copyToClipboard = (text, rowField) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log('Text copied to clipboard:', text);
        setCopiedRow(rowField); // Set the copied row
        setTimeout(() => setCopiedRow(null), 1000); // Reset the copied row after 1 second
      })
      .catch((error) => {
        console.error('Failed to copy text to clipboard:', error);
      });
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.field}>
              <TableCell
                sx={{
                  padding: '8px 16px',
                  borderBottom: '2px solid rgba(241, 243, 244, 1)',
                  borderRight: '2px solid rgba(241, 243, 244, 1)',
                }}
              >
                {row.field}
              </TableCell>
              <TableCell sx={{ padding: '8px 16px', minWidth: '30%' }}>
                <Box display='flex'>
                  <Typography>{row.value}</Typography>
                  {row.isCopied && row.value && row.value !== '' ? (
                    <ContentCopyIcon
                      style={{
                        marginLeft: '20px',
                        color: copiedRow === row.field ? '#4caf50' : '#9e9e9e',
                        cursor: 'pointer',
                        transition: 'color 0.3s',
                      }}
                      onClick={() => copyToClipboard(row.value, row.field)}
                    />
                  ) : null}
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DetailedTableView;

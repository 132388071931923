import React, { useState, createContext } from 'react';

export const AssignmentContext = createContext();

export const AssignmentProvider = (props) => {

  const [assignments, setAssignments] = useState([]);

  return (
    <AssignmentContext.Provider value={[assignments, setAssignments]}>
      {props.children}
    </AssignmentContext.Provider>
  );
}
// @ts-nocheck
import React from 'react';
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
  Stepper,
  Step,
  StepLabel,
  Stack,
} from '@mui/material';
import actue from '../../../assets/acute.png';

import LockIcon from '@mui/icons-material/Lock';
import { styled } from '@mui/material/styles';
import StepConnector from '@mui/material/StepConnector';

const steps = ['Application form', 'Screening test', 'Offer letter'];

const CustomConnector = styled(StepConnector)(({ theme }) => ({
  [`& .MuiStepConnector-line`]: {
    borderColor: '#1976D2',
  },
}));

const CustomStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.grey[700],
  zIndex: 1,
  color: '#fff',
  width: 24,
  height: 24,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundColor: theme.palette.primary.main,
  }),
  ...(ownerState.completed && {
    backgroundColor: theme.palette.primary.main,
  }),
}));

function CustomStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    3: <LockIcon fontSize='inherit' />,
  };

  return (
    <CustomStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </CustomStepIconRoot>
  );
}

const WelcomeBack = ({ trackButtonClick, setShowInstructions }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const startScreeningTest = ({
    source = 'welcome_back_screen',
    eventName = 'start_the_test_now',
  }) => {
    trackButtonClick({
      source,
      eventName,
    });
    setShowInstructions(true);
  };

  return (
    <>
      <Stack
        spacing={3}
        alignItems='center'
        sx={{
          height: 'fit-content',
          width: {
            xs: '100%',
            sm: '100%',
            md: '85%',
            lg: '80%',
          },
          padding: {
            xs: '20px',
            sm: '30px',
            md: '2rem',
            lg: '3rem',
          },
          backgroundColor: 'white',
          borderRadius: '12px',
        }}
      >
        <Stack
          spacing={2}
          paddingTop='1rem'
          alignItems='center'
        >
          <Box
            sx={{ width: '100%', paddingBottom: '1rem', marginBottom: '30px' }}
          >
            <Stepper
              activeStep={1}
              alternativeLabel={isMobile}
              connector={<CustomConnector />}
            >
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel
                    sx={{
                      fontFamily: 'Inter',
                      '& .MuiStepLabel-label.Mui-completed': {
                        color: index < 2 ? '#1976D2' : 'inherit',
                      },
                      '& .MuiStepLabel-label.Mui-active': {
                        color: index < 2 ? '#1976D2' : 'inherit',
                      },
                    }}
                    StepIconComponent={index === 2 ? CustomStepIcon : undefined}
                  >
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>

          <Box
            sx={{
              textAlign: 'center',
              maxWidth: '800px',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            style={{ marginTop: '4.3rem' }}
          >
            <Typography
              variant='h1'
              sx={{
                fontFamily: 'Inter',
                color: '#1976d2',
                fontWeight: '1000',
                fontSize: {
                  xs: '1.5rem',
                  sm: '1.7rem',
                  md: '2rem',
                  lg: '2.2rem',
                },
              }}
            >
              Welcome Back!
            </Typography>

            <Typography
              variant='body1'
              sx={{
                fontFamily: 'Inter',
                fontWeight: 400,
                fontSize: {
                  xs: '0.8rem',
                  sm: '1rem',
                  md: '1.2rem',
                  lg: '1.4rem',
                },
                marginTop: '1rem',
              }}
            >
              Your Fastrack Screening Test is now ready to begin. Let's take the
              next step toward achieving your goals!
            </Typography>
          </Box>
        </Stack>

        <Button
          variant='contained'
          sx={{
            fontSize: '0.8rem',
            marginTop: '1rem',
            fontWeight: '200',
            boxShadow: 'none',
            borderRadius: '0.5rem',
            background: 'linear-gradient(90deg, #186FC5 0%, #0E4173 100%)',
            ':hover': {
              background: 'linear-gradient(90deg, #186FC5 0%, #0E4173 100%)',
            },
          }}
          style={{
            marginBottom: '6.3rem',
            paddingTop: '0.6rem',
            paddingBottom: '0.6rem',
            paddingLeft: '1.4rem',
            paddingRight: '1.4rem',
          }}
          startIcon={
            <Box
              component='img'
              src={actue}
              alt='Priority'
              sx={{ width: 24, height: 24 }}
            />
          }
          onClick={startScreeningTest}
        >
          Start the test now
        </Button>
      </Stack>
    </>
  );
};

export default WelcomeBack;

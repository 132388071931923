// @ts-nocheck
import React, { useState } from 'react';
import { TextField, Button, Typography, Grid, Modal, Box } from '@mui/material';
import axios from 'axios';
import { getHeaders, getQuizzes } from '../../../apis/urls';

const initialState = {
  title: '',
  internal_name: '',
  description: '',
};

export default function CreateQuizModal({
  open,
  onClose,
  setQuizzes,
  setHasError,
  setErrorMessage,
  setIsSuccess,
  setSuccessMessage,
}) {
  const [formState, setFormState] = useState(initialState);

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCreateQuiz = () => {
    const body = { ...formState };
    axios
      .post(getQuizzes(), body, {
        headers: getHeaders(),
      })
      .then((res) => {
        setQuizzes((prev) => [...prev, res?.data]);
        setIsSuccess(true);
        setSuccessMessage('Quiz created successfully');
      })
      .catch((e) => {
        console.log(e);
        setHasError(true);
        setErrorMessage(
          e.response?.data?.message ||
            'An error occurred while creating the quiz'
        );
      });
    setFormState(initialState);
    onClose();
  };

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxWidth: 500,
            maxHeight: '90%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            overflowY: 'auto',
          }}
        >
          <Typography variant='h5'>Create a Quiz</Typography>
          <Grid
            container
            spacing={2}
            mt={1}
          >
            <Grid
              item
              xs={12}
              sm={6}
            >
              <TextField
                label='Title'
                name='title'
                value={formState.title}
                onChange={handleFormChange}
                fullWidth
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
            >
              <TextField
                label='Internal Name'
                name='internal_name'
                value={formState.internal_name}
                onChange={handleFormChange}
                fullWidth
              />
            </Grid>

            <Grid
              item
              xs={12}
            >
              <TextField
                label='Description'
                name='description'
                value={formState.description}
                onChange={handleFormChange}
                fullWidth
                multiline
                minRows={1}
                maxRows={3}
              />
            </Grid>

            <Grid
              item
              xs={12}
            >
              <Button
                onClick={handleCreateQuiz}
                variant='contained'
                color='primary'
              >
                Create Quiz
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}

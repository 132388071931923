import React from 'react';
import DSAVisualizerPage from './Pages/DSAVisualizerPage';

const DSAVisualizer = () => {
  return (
    <div>
      <DSAVisualizerPage />
    </div>
  );
};

export default DSAVisualizer;

// @ts-nocheck
import React, { useState } from 'react';
import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  IconButton,
  Collapse,
  Box,
} from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';

function CodingTestSubmissionTestcaseDetails({ submission }) {
  const [openRow, setOpenRow] = useState({});

  const handleRowClick = (index) => {
    setOpenRow((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  return (
    <Stack spacing={2}>
      <TextField
        multiline
        defaultValue={submission[0]?.code}
        sx={{
          width: '100%',
        }}
        inputProps={{ readOnly: true }}
      />

      <TableContainer component={Paper}>
        <Table
          sx={{
            minWidth: 650,
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Test case</TableCell>
              <TableCell>Time(sec)</TableCell>
              <TableCell align='center'>Memory(KB)</TableCell>
              <TableCell align='center'>Status</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {submission.map((val, index) => (
              <React.Fragment key={index + 1}>
                <TableRow>
                  <TableCell>
                    {val?.testcase && (
                      <IconButton
                        aria-label='expand row'
                        size='small'
                        onClick={() => handleRowClick(index)}
                      >
                        {openRow[index] ? (
                          <KeyboardArrowUp />
                        ) : (
                          <KeyboardArrowDown />
                        )}
                      </IconButton>
                    )}
                  </TableCell>
                  <TableCell
                    style={{ maxWidth: '300px', wordWrap: 'break-word' }}
                  >
                    {index + 1}
                  </TableCell>
                  <TableCell>{val.timeTaken || '-'}</TableCell>
                  <TableCell align='center'>{val.memoryTaken || '-'}</TableCell>
                  <TableCell align='center'>
                    {val?.submissionStatus ? (
                      <Typography
                        variant='body2'
                        sx={{
                          color:
                            val.submissionStatus === 'accepted'
                              ? 'green'
                              : 'red',
                        }}
                      >
                        {val.submissionStatus}
                      </Typography>
                    ) : (
                      '-'
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={6}
                  >
                    <Collapse
                      in={openRow[index]}
                      timeout='auto'
                      unmountOnExit
                    >
                      <Box margin={1}>
                        <Typography
                          variant='h6'
                          gutterBottom
                          component='div'
                        >
                          Test Input:
                        </Typography>
                        <Typography variant='body2'>
                          {val?.testcase?.input}
                        </Typography>
                        <Typography
                          variant='h6'
                          gutterBottom
                          component='div'
                        >
                          Expected output:
                        </Typography>
                        <Typography variant='body2'>
                          {val?.testcase?.expected_output}
                        </Typography>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
}

export default CodingTestSubmissionTestcaseDetails;

import React, { useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, TextField, Stack } from '@material-ui/core';
import { getHeaders, UpdateSubmission } from '../../../apis/urls';
import { getUserType } from '../../../configs/auth';

function Gradesubmission({ 
    submission, 
    assignment_id,
    setHasError,
    setIsSuccess,
    setErrorMessage,
    setSuccessMessage,
    setShowGradeCard
}) {
    const userType = getUserType();
    const [Score, setScore] = useState();
    const [Feedback, setFeedback] = useState();
    const currentProgram = useSelector(state => state.programs.currentProgram);

    function SubmitGrading() {
        if (Score && Score >= 0 && Score <= 100) {
            fetch(UpdateSubmission(assignment_id, submission?.id), {
                method: 'PUT',
                headers: getHeaders(currentProgram.programId),
                body: JSON.stringify({
                    score: Score,
                    feedback: Feedback
                })
            })
                .then((resp) => resp.json())
                .then((data) => {
                    console.log(data);
                    if (data) {
                        setIsSuccess(true);
                        setSuccessMessage('Successfully Graded');
                    }
                    else {
                        setHasError(true);
                        setErrorMessage("Assignment could not be graded.")
                    }
                    setShowGradeCard(false);
                })
                .catch((err) => {
                    console.log(err);
                    setHasError(true);
                    setErrorMessage(err.response?.data?.message || err.message);
                });
        } else {
            setHasError(true);
            setErrorMessage('score should be in the range 0 to 100');
        }
    }

    return (
        <div>
            <Box>
                <Stack
                    style={{
                        margin: 10
                    }}
                >
                    <TextField
                        fullWidth
                        label={submission?.score == null && 'Give Score In the range of 0 to 100'}
                        value={Score}
                        onChange={(e) => setScore(e.target.value)}
                        placeholder={submission?.score && submission?.score}
                    />
                </Stack>
                <Stack
                    style={{
                        margin: 10
                    }}
                >
                    <TextField
                        label={submission?.feedback == null && 'Give Feedback For this submission'}
                        onChange={(e) => setFeedback(e.target.value)}
                        multiline
                        rows={4}
                        fullWidth
                        placeholder={submission?.feedback && submission?.feedback}
                    />
                </Stack>
                <Button
                    style={{
                        alignSelf: 'center',
                        marginTop: 10
                    }}
                    fullWidth
                    onClick={() => SubmitGrading()}
                    variant="contained"
                >
                    {submission?.feedback != null && submission?.score != null
                        ? 'Update Grade'
                        : 'Grade Assignment'}
                </Button>
            </Box>
        </div>
    );
}

export default Gradesubmission;

import React from 'react';
import { 
  Box, 
  Button, 
  Card, 
  CardContent, 
  Typography 
} from '@material-ui/core';
import moment from 'moment';

function CodingTestCard({ 
  selectedTest, 
  handleTestClick, 
  joinTest 
}) {
  const format1 = "DD-MM-YYYY, hh:mm A";
  const currTime = moment();
  
  return (
    <Card 
      sx={{ 
        minWidth: 200, 
        minHeight: 300, 
        cursor: 'pointer', 
        m: 2 
      }} 
    >
      <CardContent>
        <Typography 
          variant="h5" 
          textAlign="center" 
          sx={{
            mt: 2
          }}
        >
          {selectedTest?.name}
        </Typography>

        <Box 
          display="flex" 
          flexDirection="column" 
          justifyContent="center" 
          alignItems="center" 
          sx={{mt: 9}}
        >
          <Typography variant="body1">
              {`Start time: ${moment(selectedTest?.startTime).format(format1)} IST`}
          </Typography>
          <Typography variant="body1">
              {`End time: ${moment(selectedTest?.endTime).format(format1)} IST`}
          </Typography>

          {(() => {
            switch (true) {
              case currTime.isBefore(moment(selectedTest?.startTime)):
                return (
                  <Button 
                    sx={{
                      width: "100%", 
                      mt: 2
                    }} 
                    variant="contained" 
                    disabled
                  > 
                    Scheduled
                  </Button>
                );
              case currTime.isAfter(moment(selectedTest?.endTime)):
                return (
                  <Button 
                    sx={{
                      width: "100%", 
                      mt: 2
                    }} 
                    variant="contained"
                    onClick={(e) => handleTestClick(e, selectedTest?.testId)}
                  > 
                    View
                  </Button>
                );
              case selectedTest?.hasSubmitted:
                return (
                  <Button 
                    sx={{
                      width: "100%", 
                      mt: 2
                    }} 
                    variant="contained" 
                    disabled
                  > 
                    Submitted
                  </Button>
                );
              case selectedTest?.hasJoined:
                return (
                  <Button 
                    sx={{
                      width: "100%", 
                      mt: 2
                    }} 
                    variant="contained" 
                    onClick={(e) => handleTestClick(e, selectedTest?.testId)}
                  > 
                    Resume
                  </Button>
                );
              default:
                return (
                  <Button 
                    sx={{
                      width: "100%", 
                      mt: 2
                    }} 
                    variant="contained" 
                    onClick={(e) => joinTest(e, selectedTest?.testId)}
                  > 
                    Start
                  </Button>
                );
            }
          })()}
        </Box>
      </CardContent>
    </Card>
  );
}

export default CodingTestCard;
// @ts-nocheck
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import MentorDashboardLayout from './layouts/mentorDashboard';
import Login from './pages/Login';
import Assignments from './views/Assignments';
import UserProfile from './pages/UserProfile';
import EscalationMatrix from './pages/EscalationMatrix';
import BatchChangePolicy from './pages/BatchChangePolicy';
import MentorResource from './components/_dashboard/resource/MentorResource';
import DailyProblem from './components/_dashboard/dailyProblems/dailyProblem';
import MyLearnings from './components/MyLearnings/MyLearnings';
import Programs from './components/_dashboard/Programs/Programs';
import Enrollments from './components/_dashboard/Enrollments/Enrollments';
import ProblemsLibrary from './components/_dashboard/Programs/ProblemsLibrary';
import StudentAssignmentSubmission from './components/_dashboard/assignments/StudentAssignmentSubmission';
import Levels from './components/_dashboard/Levels/Levels';
import LearnerLevels from './components/_dashboard/Programs/LearnerLevels';
import Reports from './components/_dashboard/Programs/Reports/Reports';
import Sessions from './components/_dashboard/Programs/Sessions/Sessions';
import Finances from './components/_dashboard/Finances/Finances';
import GenerateReceipts from './components/_dashboard/Finances/GenerateReceipts';
import Tasks from './components/_dashboard/Programs/Tasks/Tasks';
import Overview from './components/Overview/Overview';
import SessionAPI from './components/sessions/SessionContext';
import { Sessions as StudentSessions } from './components/sessions/Sessions';
import Practice from './components/practice/Practice';
import CodeEditor from './components/practice/CodeEditor/CodeEditor';
import Performance from './components/performance/Performance';
import { Tasks as StudentTasks } from './components/performance/Tasks/Tasks';
import AssignmentSubmissions from './components/performance/Assignment/AssignmentSubmissions';
import { Assignments as AssignmentContextSelection } from './components/performance/Assignment/Assignments';
import LearnTab from './components/_dashboard/Programs/LearnTab';
import PracticeTab from './components/_dashboard/Programs/PracticeTab';
import EvaluateTab from './components/_dashboard/Programs/EvaluateTab';
import ProgramReports from './components/_dashboard/ProgramReports/ProgramReports';
import Interactions from './components/_dashboard/Programs/Interactions/Interactions';
import PreCourse from './components/PreCourse/PreCourse';
import ResourcesTab from './components/PreCourse/ResourcesTab';
import EligibilityFormLayout from './layouts/eligibilityForm/EligibilityFormLayout';
import LevelTests from './components/_dashboard/Programs/LevelTests/LevelTests';
import TermsAndCondtitionsLayout from './layouts/termsAndConditions/TermsAndConditionsLayout';
import ProgramSettings from './components/_dashboard/Programs/ProgramSettings/ProgramSettings';
import QuizLayoutAdmin from './components/_dashboard/QuizLibrary/QuizLayoutAdmin';
import QuizStudentLayout from './components/practice/Quizzes/QuizStudentLayout';
import LearnerLevelTests from './components/practice/LevelTests/LearnerLevelTests';
import LevelTestAttempts from './components/practice/LevelTests/LevelTestAttempts';
import VisitorHomePage from './pages/VisitorHomePage';
import ProgramQuizAttempts from './components/_dashboard/Programs/Quizzes/ProgramQuizAttempts';
// import DSAVisualization from './components/practice/DSAVisualization/DSAVisualization';
import ChatDashboard from './components/ChatDashboard/ChatDashboard';
import UserAccount from './pages/UserAccount';
import TGPBatchWiseRecord from './components/Metabase/TGPBatchWiseRecord';
import CodingAssignments from './components/performance/CodingAssignments/CodingAssignments';
import MainPlacementsPage from './components/PlacementsTabForAdmins/AdminPlacementsMainPage';
import LearnersPlacementsMainPage from './components/PlacementsTabForLearners/LearnersPlacementsMainPage';
import LearnerHome from './components/PlacementsTabForLearners/LearnerPlacementComponents/LearnerHome';
import LearnerJobs from './components/PlacementsTabForLearners/LearnerPlacementComponents/LearnerJobs';
import CompaniesInContact from './components/PlacementsTabForLearners/LearnerPlacementComponents/CompaniesInContact';
import SessionTests from './components/practice/SessionTests/SessionTests';
import CoadingAssignmentSubmissions from './components/Metabase/CoadingAssignmentSubmissions';
import SessionAssignment from './components/Metabase/SessionAssignment';
import TeachingStaff from './components/Overview/TeachingStaff';
import GrowthChart from './components/performance/GrowthChart/GrowthChart';
import DSAVisualizer from './components/practice/DSAVisualizer/DSAVisualizer';
import DSATopicDescription from './components/practice/DSAVisualizer/DSATopicDescription';
import TGPMainScreen from './components/TheGreatePyramid/TGPMainScreen';
import TechniqueSaga from './pages/TechniqueSaga';
import BitManipulation from './pages/BitManipulation';
import LanguageBible from './components/PreCourse/LanguageBible';
import Mindmaps from './components/practice/MindMaps/Pages/Mindmaps';
import SubTopics from './components/practice/MindMaps/Pages/SubTopics';
import SubTopicsList from './components/practice/MindMaps/Organisms/SubTopicsList';
import ErrorPage from './pages/ErrorPage';
import { EfPipelineProvider } from './layouts/EfPipelineContext/EfPipelineContext';
import SessionItemDetailsLayout from './components/sessionsNew/SessionItemDetailsLayout';
import SessionsLayout from './components/sessionsNew/SessionsLayout';
import TicketingSystem from './pages/TicketingSystem';
import LearnerModule from './pages/LearnerModule';
import MyCoupons from './components/TAPCoupons/MyCoupons';
import StudentScreenWrapper from './screens/StudentScreenWrapper';
import TabLayoutWrapper from './components/AtomicComponents/TabLayoutWrapper';
import { Onboarding } from './layouts/onboardingDashboard/Onboarding';
import OnboardingTabLayout from './layouts/onboardingDashboard/OnboardingTabLayout';
import { element } from 'prop-types';
import OnboardingGettingStarted from './layouts/onboardingDashboard/OnboardingGettingStarted';
import MentorSession from './components/_dashboard/Mentor/MentorSession';
import { Signup } from './pages/Signup';

const dashboardRoutes = {
  admin: '/mentor/programs',
  program_manager: '/mentor/programs',
  teaching_assistant: '/mentor/programs',
  finance: '/mentor/finances',
  problem_setter: '/mentor/problems_library',
};

const routes = (isUserLoggedIn, userType) => [
  {
    path: 'student',
    element:
      isUserLoggedIn && (userType === 'coach' || userType === 'student') ? (
        <StudentScreenWrapper />
      ) : (
        <Navigate
          to='/'
          replace
        />
      ),
    children: [
      {
        path: '',
        element: (
          <Navigate
            to='/student/my-courses'
            replace
          />
        ),
      },
      {
        path: 'my-courses',
        element: <MyLearnings />,
      },
      {
        path: 'user/settings',
        element: <UserAccount />,
      },
      {
        path: ':programName',
        element: <TabLayoutWrapper />,
        children: [
          {
            path: 'overview',
            element: <Overview />,
            children: [],
          },
          {
            path: 'sessions',
            element: (
              <SessionAPI>
                <SessionsLayout />
              </SessionAPI>
            ),
          },
          {
            path: 'coupons',
            element: <MyCoupons />,
          },
          {
            path: 'sessions/*',
            element: <SessionItemDetailsLayout />,
          },
          {
            path: 'signature-frameworks',
            element: <Practice />,
            children: [
              { path: 'mindmap', element: <Mindmaps /> },
              { path: 'mindmap/:topic', element: <SubTopics /> },
              { path: 'mindmap/:topic/:subTopic', element: <SubTopicsList /> },
              {
                path: 'dsa-visualization',
                element: <DSAVisualizer />,
              },
              {
                path: 'dsa-visualization/:topic',
                element: <DSATopicDescription />,
              },
              { path: 'code-editor', element: <CodeEditor /> },
              { path: 'session-tests', element: <SessionTests /> },
              { path: 'level-tests', element: <LearnerLevelTests /> },
              { path: 'level-tests-attempts', element: <LevelTestAttempts /> },
              { path: 'Technique-Saga', element: <TechniqueSaga /> },
              { path: 'quizzes', element: <QuizStudentLayout /> },
              { path: 'the-great-pyramid', element: <TGPMainScreen /> },
            ],
          },
          {
            path: 'pre-course',
            element: <PreCourse />,
            children: [
              { path: 'resources', element: <ResourcesTab /> },
              {
                path: 'foundation-questions',
                element: <AssignmentContextSelection />,
              },
              {
                path: 'language-bible',
                element: <LanguageBible />,
              },
            ],
          },
          {
            path: 'performance',
            element: <Performance />,
            children: [
              { path: 'growth', element: <GrowthChart /> },
              { path: 'assignments', element: <AssignmentContextSelection /> },
              { path: 'coding-assignments', element: <CodingAssignments /> },
              {
                path: 'assignment-submissions',
                element: <AssignmentSubmissions />,
              },
              { path: 'tasks', element: <StudentTasks /> },
            ],
          },
          {
            path: 'placements',
            element: <LearnersPlacementsMainPage />,
            children: [
              { path: 'home', element: <LearnerHome /> },
              { path: 'jobs', element: <LearnerJobs /> },
              { path: 'companies-in-contact', element: <CompaniesInContact /> },
            ],
          },
        ],
      },
    ],
  },
  {
    path: 'onboarding',
    element: isUserLoggedIn ? <Onboarding /> : <Login />,
    children: [
      {
        path: '',
        element: (
          <Navigate
            to='/onboarding/unlock-live-sessions'
            replace
          />
        ),
      },
      {
        path: 'unlock-live-sessions',
        element: <OnboardingGettingStarted />,
      },
      {
        path: 'signature-frameworks',
      },
    ],
  },
  {
    path: 'mentor',
    element:
      isUserLoggedIn &&
      (userType === 'admin' ||
        userType === 'program_manager' ||
        userType === 'finance' ||
        userType === 'problem_setter' ||
        userType === 'teaching_assistant') ? (
        <MentorDashboardLayout />
      ) : (
        <Navigate
          to='/'
          replace
        />
      ),
    children: [
      {
        path: '',
        element: (
          <Navigate
            to={dashboardRoutes[userType]}
            replace
          />
        ),
      },

      { path: 'programs', element: <Programs /> },
      { path: 'problems_library', element: <ProblemsLibrary /> },
      { path: 'levels', element: <Levels /> },
      { path: 'quiz_library', element: <QuizLayoutAdmin /> },
      { path: 'placements', element: <MainPlacementsPage /> },
      { path: 'program_reports', element: <ProgramReports /> },
      { path: 'learner_search', element: <LearnerModule /> },
      {
        path: 'metabase/batch_wise_tgp_record',
        element: <TGPBatchWiseRecord />,
      },
      {
        path: 'metabase/coding_assignment_submissions',
        element: <CoadingAssignmentSubmissions />,
      },
      {
        path: 'metabase/session_test',
        element: <SessionAssignment />,
      },

      {
        path: 'user/profile',
        element: <UserProfile />,
      },
      {
        path: 'user/settings',
        element: <UserAccount />,
      },
      {
        path: 'programs/:programName/enrollments',
        element: <Enrollments />,
      },

      {
        path: 'programs/:programName/learn',
        element: <LearnTab />,
        children: [
          {
            path: 'sessions',
            element: <Sessions />,
          },
          {
            path: 'resources',
            element: <MentorResource />,
          },
          {
            path: 'mentor_sessions',
            element: <MentorSession />,
          },
        ],
      },

      {
        path: 'programs/:programName/practice',
        element: <PracticeTab />,
        children: [
          { path: 'tasks', element: <Tasks /> },
          { path: 'assignments', element: <Assignments /> },
          { path: 'daily_problems', element: <DailyProblem /> },
        ],
      },

      {
        path: 'programs/:programName/evaluate',
        element: <EvaluateTab />,
        children: [
          { path: 'coding_tests', element: <LevelTests /> },
          { path: 'learner_levels', element: <LearnerLevels /> },
          { path: 'quizzes', element: <ProgramQuizAttempts /> },
        ],
      },
      { path: 'programs/:programName/reports', element: <Reports /> },
      { path: 'programs/:programName/interactions', element: <Interactions /> },
      { path: 'programs/:programName/settings', element: <ProgramSettings /> },
      {
        path: 'programs/:programName/reports/assignments/:assignmentId/students/:studentId/submissions',
        element: <StudentAssignmentSubmission />,
      },

      {
        path: 'finances',
        element: <Finances />,
      },
      {
        path: 'generate_receipt',
        element: <GenerateReceipts />,
      },
      {
        path: 'tickets',
        element: <TicketingSystem />,
      },
    ],
  },
  {
    path: 'offer-letters',
    element:
      isUserLoggedIn && userType === 'visitor' ? (
        <VisitorHomePage />
      ) : (
        <Navigate
          to='/'
          replace
        />
      ),
  },
  {
    path: 'coupons',
    element:
      isUserLoggedIn && userType === 'visitor' ? (
        <MyCoupons /> // Component to render when conditions are met
      ) : (
        <Navigate to='/' /> // Redirect if conditions are not met
      ),
  },
  {
    path: 'terms-and-conditions',
    element:
      isUserLoggedIn && userType === 'visitor' ? (
        <TermsAndCondtitionsLayout />
      ) : (
        <Navigate
          to='/'
          replace
        />
      ),
  },
  {
    path: 'eligibility-forms',
    element:
      isUserLoggedIn && userType === 'visitor' ? (
        <EfPipelineProvider>
          <EligibilityFormLayout />
        </EfPipelineProvider>
      ) : (
        <Navigate
          to='/'
          replace
        />
      ),
  },
  {
    path: 'chat',
    element:
      isUserLoggedIn &&
      (userType === 'admin' ||
        userType === 'program_manager' ||
        userType === 'student') ? (
        <ChatDashboard />
      ) : (
        <Navigate
          to='/'
          replace
        />
      ),
  },
  {
    path: '/',
    element:
      isUserLoggedIn && userType === 'student' ? (
        <Navigate
          to='/student'
          replace
        />
      ) : (
        <Login />
      ),
    children: [{ path: 'login', element: <Login /> }],
  },
  {
    path: '/escalation-matrix',
    element: isUserLoggedIn ? <EscalationMatrix /> : <Login />,
  },
  {
    path: '/batch-change-policy',
    element: isUserLoggedIn ? <BatchChangePolicy /> : <Login />,
  },
  {
    path: '/sign-up',
    element: <Signup />,
  },
  {
    path: '*',
    element: <ErrorPage />,
  },
];

export default routes;
